import Paywall from './paywall'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type PaywallStore = {
  currentPaywall: Paywall | null
  allowedKeys: string[]
  setCurrentPaywall: (paywall: Paywall | null) => void
  addNewKey: (key: string) => void
}

const usePaywallStore = create<PaywallStore>()(
  persist(
    (set) => ({
      currentPaywall: null,
      allowedKeys: [],
      setCurrentPaywall: (paywall) => set({ currentPaywall: paywall }),
      addNewKey: (key) =>
        set((state) => ({ allowedKeys: [...state.allowedKeys, key] })),
    }),
    {
      partialize: (state) => ({ allowedKeys: state.allowedKeys }),
      name: 'paywall',
    }
  )
)

export default usePaywallStore
