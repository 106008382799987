import React, { useState } from 'react'
import { TwoColumnAccordionRecord } from '../../generated/graphql'
import Section from '../../components/section'
import Plus from '../../icons/plus'
import AccordionItem from '../../components/accordion/accordion-item'
import { Image } from 'react-datocms'
import CircledCheck from '../../icons/circled-check'
import CtaLink from '../../components/cta-link'

type Props = {
  block: TwoColumnAccordionRecord
}

const TwoColumnAccordion = ({ block }: Props) => {
  const [currIndex, setCurrIndex] = useState<number | null>(null)

  const toggleAccordion = (index: number) => {
    if (currIndex === index) {
      setCurrIndex(null)
    } else {
      setCurrIndex(index)
    }
  }

  return (
    <Section
      classOverride={'my-9 md:my-11'}
      classOverrideInnerGrid={'py-[0] md:py-[0] md:gap-0 gap-0'}
    >
      <div className="col-span-full mb-[60px] flex w-full flex-col gap-[16px]">
        <h2 className="text-header-2">{block.headline}</h2>
        <p className="text-base">{block.headlineDescription}</p>
      </div>
      <div className="col-span-full">
        {block.contents.length > 0 &&
          block.contents.map((content, index) => {
            return (
              <div
                key={content.id}
                className="relative w-full overflow-hidden"
                style={{
                  borderBottom:
                    currIndex === index
                      ? '1px solid #292862'
                      : '1px solid #fff',
                }}
              >
                <button
                  className="relative flex w-full flex-row items-center py-3"
                  style={{
                    borderBottom:
                      currIndex === index
                        ? '1px solid #fff'
                        : '1px solid #cfcfcf',
                  }}
                  onClick={() => {
                    toggleAccordion(index)
                  }}
                >
                  <div className="flex w-full items-center justify-between overflow-hidden">
                    <h3 className="header-3 text-[20px] font-medium">
                      {content.mainTitle}
                    </h3>
                    {currIndex === index ? (
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <rect
                          x="11.5"
                          y="5.26367"
                          width="1"
                          height="11"
                          transform="rotate(90 11.5 5.26367)"
                          fill={'currentColor'}
                        />
                      </svg>
                    ) : (
                      <Plus />
                    )}
                  </div>
                </button>
                <AccordionItem height={index === currIndex ? 'auto' : 0}>
                  <div className="mb-[24px] flex w-full flex-col-reverse justify-between gap-[24px] lg:flex-row">
                    {content.image && (
                      <Image
                        style={{
                          width: '485px',
                        }}
                        className="h-[500px] w-[485px] min-w-full sm:h-[550px] sm:min-w-[485px]"
                        objectFit="cover"
                        data={content.image?.responsiveImage!}
                      />
                    )}

                    <div className="max-w-[660px]">
                      {content.points.length > 0 && (
                        <div>
                          {content.points?.map((point) => {
                            return (
                              <div key={point.id} className="mb-[32px]">
                                {point.title && (
                                  <h4 className="mb-[8px] font-display text-[18px]">
                                    {point.title}
                                  </h4>
                                )}
                                {point.description && (
                                  <p className="text-base mb-[24px]">
                                    {point.description}
                                  </p>
                                )}
                                {point.bulletPoints.length > 0 &&
                                  point.bulletPoints?.map((bulletPoint) => {
                                    return (
                                      <div
                                        key={bulletPoint.id}
                                        className="text-base flex items-start gap-[16px]"
                                      >
                                        <div>
                                          <CircledCheck className="mt-[4px]" />
                                        </div>
                                        <p className="text-base">
                                          {bulletPoint.bulletPoint}
                                        </p>
                                      </div>
                                    )
                                  })}
                              </div>
                            )
                          })}
                        </div>
                      )}
                      <CtaLink cta={content.cta ? content.cta : block.cta[0]} />
                    </div>
                  </div>
                </AccordionItem>
              </div>
            )
          })}
      </div>
    </Section>
  )
}

export default TwoColumnAccordion
