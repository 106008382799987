import SearchResult from '../types/SearchResult'

const searchSite = async (queryString: string) =>
  await fetch('https://site-api.datocms.com/search-results?q=' + queryString, {
    method: 'GET',
    headers: {
      Authorization: 'API-Token 11824eb2082a6d9a14bacdb8344d98',
      Accept: 'application/json',
      'X-Api-Version': '3',
    },
  })
    .then((res) => res.json())
    .then((res) => res.data as SearchResult[])

export default searchSite
