import { gsap } from '../../lib/gsap'
import { RefObject } from 'react'

function animateStarField(stage: RefObject<HTMLDivElement>) {
  const selector = gsap.utils.selector(stage)

  gsap.to(selector('.circle'), {
    scrollTrigger: {
      trigger: stage.current,
      scrub: true,
      start: 'top top',
    },
    opacity: 0.1,
    x: (i, el) => {
      const centerX = window.innerWidth / 2
      const elX = el.offsetLeft
      return `-=${2 * (centerX - elX)}`
    },
    y: (i, el) => {
      const centerY = window.innerHeight / 3
      const elY = el.offsetTop
      return `-=${1.3 * (centerY - elY)}`
    },
  })
}

export default animateStarField
