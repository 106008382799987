import { useRouter } from 'next/router'
import { useEffect } from 'react'
import productDemo from '../../public/html/product-demo'


function SugarMarketProductDemoFrom() {
  const router = useRouter()
  const message = router.query.message

  useEffect(() => {
    const textarea = document?.getElementsByName('message')
    if (textarea[0] && message) {
      textarea[0].innerText = message as string
    }

  }, [message])

  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: productDemo }} />
    </>
  )
}

export default SugarMarketProductDemoFrom
