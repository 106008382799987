import { CountUpStatisticsModuleRecord } from '../../generated/graphql'
import Section from '../../components/section'
import { useRef } from 'react'
import CountUpBg from './count-up-bg'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import animateDigits from './animate-digits'
import Link from 'next/link'

type Props = {
  block: CountUpStatisticsModuleRecord
}

function getDigitsFromNumber(_number: number) {
  const digits = []
  const stringNumber = _number.toString()

  for (var i = 0, len = stringNumber.length; i < len; i += 1) {
    digits.push(+stringNumber.charAt(i))
  }

  return digits
}

function CountUpStatistics({ block }: Props) {
  const stage = useRef<HTMLDivElement>(null)
  const blocks = useRef<{ el: HTMLDivElement | null; digit: number }[][]>([
    [],
    [],
    [],
    [],
  ])

  useIsomorphicLayoutEffect(() => {
    animateDigits(stage, blocks)
  })

  return (
    <Section
      classOverride={'h-screen relative overflow-x-hidden w-full bg-greys-1'}
    >
      <div
        style={{ top: 0, left: 0 }}
        className="absolute ml-[-50%] flex h-full w-[200%] items-center  md:ml-0 md:w-full"
        ref={stage}
      >
        <CountUpBg />
      </div>

      {block.metrics.map((metric, index) => (
        <div
          key={metric.id}
          className={
            'col-span-2 my-auto flex flex-col items-center text-[4em] md:text-display-1'
          }
        >
          <div className="relative mb-2 flex h-[75px] justify-center overflow-hidden md:mb-0 md:h-[1em]">
            <>
              {getDigitsFromNumber(metric.figure).map((digit, i) => (
                <div
                  key={i}
                  className="h-[75px] w-[0.7em] text-center md:h-[1em]"
                >
                  <div
                    ref={(el) => {
                      blocks.current[index][i] = {
                        el,
                        digit,
                      }
                    }}
                  >
                    {i === 0 ? (
                      <>
                        {[...Array(11 - digit).keys()].map((_digit, j) => (
                          <div
                            key={j}
                            className="h-[75px] w-[0.7em] text-center md:h-[1em]"
                          ></div>
                        ))}

                        {[...Array(digit).keys()].map((_digit, j) => (
                          <div
                            key={j}
                            className="h-[75px] w-[0.7em] text-center md:h-[1em] "
                          >
                            {j + 1}
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em] ">
                          0
                        </div>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em]">
                          1
                        </div>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em]">
                          2
                        </div>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em]">
                          3
                        </div>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em]">
                          5
                        </div>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em]">
                          6
                        </div>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em]">
                          4
                        </div>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em]">
                          7
                        </div>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em]">
                          8
                        </div>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em]">
                          9
                        </div>
                        <div className="h-[75px] w-[0.7em] text-center md:h-[1em]">
                          {digit}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </>
          </div>

          <p className={'z-10 text-text-small'}>
            {metric?.link ? (
              <Link href={'/'}>{metric.label}</Link>
            ) : (
              metric.label
            )}
          </p>
        </div>
      ))}
    </Section>
  )
}

export default CountUpStatistics
