import { isDirectUploadOrExternalLinkInsightRecord } from './get-solution-releated-insight-type'
import { SolutionRelatedInsightModelRelatedInsightsField } from '../../generated/graphql'

function getSecureInfoFromDirectUploadOrExternalLink(
  insight: SolutionRelatedInsightModelRelatedInsightsField
) {
  if (!isDirectUploadOrExternalLinkInsightRecord(insight)) {
    return null
  }

  return {
    isSecure: insight.isSecure,
    sugarmarketPopupLink: insight.sugarmarketPopupLink,
  }
}

export default getSecureInfoFromDirectUploadOrExternalLink
