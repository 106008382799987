import { ImageVideoRecord } from '../generated/graphql'
import VideoComponent from '../components/video-component'
import { Image } from 'react-datocms'

type Props = {
  block: ImageVideoRecord
}

function ImageVideoBlock({ block }: Props) {
  if (block.externalVideoLink) {
    return <VideoComponent src={block.externalVideoLink} />
  }

  if (block.image?.responsiveImage) {
    return (
      <Image
        style={{ maxWidth: 'none' }}
        className="my-4 object-cover drop-shadow-sm"
        data={block.image.responsiveImage}
      />
    )
  }

  return <>Missing image or video</>
}

export default ImageVideoBlock
