const ALL_ENERGY_TYPES = `
{
    allEnergyTypes {
      id
      __typename
      slug
      name
      tagName
    }
  }
`

export default ALL_ENERGY_TYPES
