import { CtaLinkRecord } from '../generated/graphql'
import Button from './button'
import ArrowRightButton from './arrow-right-button'

type Props = {
  cta: CtaLinkRecord
  onClick?: () => void
}

function CtaLink({ cta, onClick }: Props) {
  const link = cta?.link
  const hasAnchor = link?.startsWith('#')

  if (!link) return null

  if (cta.buttonStyle === 'Filled') {
    return (
      <Button
        target={cta.shouldOpenNewTab ? '_blank' : '_self'}
        to={link ?? '#'}
        onClick={onClick}
        text={cta.ctaLabel ?? ''}
        variant={'primary'}
        anchor={hasAnchor}
      />
    )
  }

  if (cta.buttonStyle === 'Filled White') {
    return (
      <Button
        target={cta.shouldOpenNewTab ? '_blank' : '_self'}
        to={link ?? '#'}
        onClick={onClick}
        text={cta.ctaLabel ?? ''}
        variant={'white'}
        anchor={hasAnchor}
      />
    )
  }

  if (cta.buttonStyle === 'Outlined') {
    return (
      <Button
        target={cta.shouldOpenNewTab ? '_blank' : '_self'}
        to={link ?? '#'}
        onClick={onClick}
        text={cta.ctaLabel ?? ''}
        variant={'current-outlined'}
        anchor={hasAnchor}
      />
    )
  }

  if (cta.buttonStyle === 'Small Outlined') {
    return (
      <Button
        target={cta.shouldOpenNewTab ? '_blank' : '_self'}
        isSmall
        to={link ?? '#'}
        onClick={onClick}
        text={cta.ctaLabel ?? ''}
        variant={'primary-outlined'}
        anchor={hasAnchor}
      />
    )
  }

  if (cta.buttonStyle === 'White Outlined') {
    return (
      <Button
        target={cta.shouldOpenNewTab ? '_blank' : '_self'}
        to={link ?? '#'}
        onClick={onClick}
        text={cta.ctaLabel ?? ''}
        variant={'white-outlined'}
        anchor={hasAnchor}
      />
    )
  }

  if (cta.buttonStyle === 'Arrow') {
    return (
      <ArrowRightButton
        target={cta.shouldOpenNewTab ? '_blank' : '_self'}
        link={link ?? '#'}
        variant={'primary'}
      >
        {cta.ctaLabel}{' '}
      </ArrowRightButton>
    )
  }

  if (cta.buttonStyle === 'Arrow White') {
    return (
      <ArrowRightButton
        target={cta.shouldOpenNewTab ? '_blank' : '_self'}
        link={cta.link ?? '#'}
        variant={'white'}
      >
        {cta.ctaLabel}{' '}
      </ArrowRightButton>
    )
  }

  return <a href={link ?? '#'}>{cta.ctaLabel}</a>
}

export default CtaLink
