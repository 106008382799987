function areCoordinatesFartherThan(
  x1: number,
  x2: number,
  y1: number,
  y2: number,
  distance: number
): boolean {
  return Math.abs(x1 - x2) > distance || Math.abs(y1 - y2) > distance
}

export default areCoordinatesFartherThan
