type Props = {
  size?: number
}

const DEFAULT_SIZE = 14

function Hamburger({ size }: Props) {
  return (
    <svg
      width={size ?? DEFAULT_SIZE}
      height={size ?? DEFAULT_SIZE}
      viewBox="0 0 14 14"
      fill="none"
    >
      <line y1="1" x2="16" y2="1" stroke="currentColor" />
      <line y1="7" x2="16" y2="7" stroke="currentColor" />
      <line y1="13" x2="16" y2="13" stroke="currentColor" />
    </svg>
  )
}

export default Hamburger
