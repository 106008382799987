import Link from 'next/link'

type Variant =
  | 'primary'
  | 'white'
  | 'primary-outlined'
  | 'current-outlined'
  | 'white-outlined'

const variants: Record<Variant, string> = {
  primary:
    'bg-primary-rystad-blue text-white hover:bg-primary-rystad-blue-light-tint transition',
  white: 'bg-white text-primary-rystad-blue',
  'primary-outlined':
    'whitespace-nowrap border border-l-primary-rystad-blue text-text-small text-primary-rystad-blue hover:bg-primary-rystad-blue hover:text-white transition',
  'current-outlined':
    'whitespace-nowrap border border-l-current text-text-small text-current hover:bg-primary-rystad-blue hover:text-white transition',
  'white-outlined':
    'whitespace-nowrap border border-l-greys-0 text-text-small text-greys-0 hover:bg-white hover:text-primary-rystad-blue transition',
}

type Props = {
  text: String
  to?: string
  onClick?: () => void
  target?: string
  variant?: Variant
  isSmall?: boolean
  className?: string
  anchor?: boolean
}

function FilledButton({
  text,
  to,
  variant,
  target,
  isSmall,
  onClick,
  className,
  anchor = false,
}: Props) {
  const variantClasses = variants[variant ?? 'primary']

  if (onClick) {
    return (
      <button
        type="button"
        onClick={onClick}
        className={`${className} base flex w-auto cursor-pointer justify-center rounded-large text-center ${
          isSmall ? 'px-3 py-1 text-text-small' : 'px-5 py-2 text-text-base'
        } ${variantClasses}`}
      >
        {text}
      </button>
    )
  } else if (!onClick && anchor) {
    return (
      <a
        href={to ?? '#'}
        target={target}
        className={`base flex w-auto cursor-pointer justify-center rounded-large text-center ${
          isSmall ? 'px-3 py-1 text-text-small' : 'px-5 py-2 text-text-base'
        } ${variantClasses}`}
      >
        {text}
      </a>
    )
  }

  return (
    <Link scroll={false} href={to ?? '#'}>
      <a
        target={target}
        className={`base flex w-auto cursor-pointer justify-center rounded-large text-center ${
          isSmall ? 'px-3 py-1 text-text-small' : 'px-5 py-2 text-text-base'
        } ${variantClasses}`}
      >
        {text}
      </a>
    </Link>
  )
}

export default FilledButton
