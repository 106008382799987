import { CareersOverviewPageJobPostingGridRecord } from '../generated/graphql'
import Section from '../components/section'
import useWorkable from '../hooks/use-workable'
import { useEffect, useMemo, useState } from 'react'
import { Job } from '../types/WorkableTypes'
import debounce from 'lodash.debounce'

type Props = {
  block: CareersOverviewPageJobPostingGridRecord
}

function CareersOverviewPageJobPostingGrid({ block }: Props) {
  const workableData = useWorkable()

  const orderByFilters = ['Oldest', 'Newest']

  const locationFilters = useMemo(() => {
    if (!workableData?.data?.jobs) return null

    const { jobs } = workableData.data
    const publishedJobs = jobs.filter((job) => job.state === 'published')

    const sortedLocations = publishedJobs
      .map((job) => job.location.location_str)
      .filter((location) => location)
      .sort((a, b) => a.localeCompare(b))

    return ['All', ...sortedLocations]
  }, [workableData])

  const departmentFilters = useMemo(() => {
    if (!workableData?.data?.jobs) return null

    const { jobs } = workableData.data
    const publishedJobs = jobs.filter((job) => job.state === 'published')

    const sortedDepartments = publishedJobs
      .map((job) => job.department)
      .filter((department) => department)
      .sort((a, b) => a.localeCompare(b))

    return ['All', ...sortedDepartments]
  }, [workableData])

  const defaultFilters = {
    location: locationFilters ? locationFilters[0] : 'All',
    department: departmentFilters ? departmentFilters[0] : 'All',
    orderBy: orderByFilters[0],
    search: '',
  }

  const [locationFilter, setLocationFilter] = useState(defaultFilters.location)
  const [departmentFilter, setDepartmentFilter] = useState(
    defaultFilters.department
  )
  const [orderBy, setOrderBy] = useState(defaultFilters.orderBy)
  const [search, setSearch] = useState(defaultFilters.search)

  const grouppedJobs = useMemo(() => {
    if (!workableData?.data?.jobs) return null

    let { jobs } = workableData.data

    // filter by location
    if (locationFilter !== 'All') {
      jobs = jobs.filter(
        (job) =>
          job.location.location_str.toLowerCase().trim() ===
          locationFilter.toLowerCase().trim()
      )
    }

    // filter by department
    if (departmentFilter !== 'All') {
      jobs = jobs.filter(
        (job) =>
          job.department.toLowerCase().trim() ===
          departmentFilter.toLowerCase().trim()
      )
    }

    // filter by search
    if (search.trim()) {
      jobs = jobs.filter((job) =>
        job.title.toLowerCase().includes(search.toLowerCase().trim())
      )
    }

    // order by date
    if (orderBy.toLowerCase() === 'oldest') {
      jobs = jobs.sort(
        (a, b) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      )
    } else {
      jobs = jobs.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
    }

    const groupJobs: { [key: string]: Job[] } = {}

    jobs.forEach((job) => {
      if (
        !groupJobs[job.department] &&
        job.state === 'published' &&
        !job.sample
      ) {
        groupJobs[job.department] = []
      }
    })

    jobs.forEach((job) => {
      if (job.state === 'published' && !job.sample) {
        groupJobs[job.department].push(job)
      }
    })

    return groupJobs
  }, [workableData, locationFilter, search, departmentFilter, orderBy])

  return (
    <Section id={block?.slug ?? ''}>
      <div className={'col-span-8 max-w-none'}>
        <h2 className={'mb-6 text-header-2 font-bold'}>Open Positions</h2>
        <div className="flex flex-col gap-2 sm:gap-2 md:gap-4">
          {/* <div className='flex' >
            <input placeholder='Search' type="search" className='rounded-md flex-1 border py-1 px-2' name="q" id="" onChange={(e) => setSearch(e.target.value)} />
          </div>
          <div className='flex justify-between gap-2 lg:flex-row md:flex-col sm:flex-col' >
            <select className='border rouded-sm py-1 px-2 rounded-md flex-1'  onChange={(e) => setOrderBy(e.target.value)} name="" id="">
              {orderByFilters && orderByFilters.map((o) => <option className='p-8' >{o}</option>)}
            </select>
            <select className='border rouded-sm py-1 px-2 rounded-md flex-1' onChange={(e) => setLocationFilter(e.target.value)} name="" id="">
              {locationFilters && locationFilters.map((l) => <option>{l}</option>)}
            </select>
            <select className='border rouded-sm py-1 px-2 rounded-md flex-1' onChange={(e) => setDepartmentFilter(e.target.value)} name="" id="">
              {departmentFilters && departmentFilters.map((d) => <option>{d}</option>)}
            </select>
          </div> */}
        </div>
        <ul className={'list-none'}>
          {grouppedJobs ? (
            Object.keys(grouppedJobs).map((department) => (
              <li key={department}>
                <div className={'mb-2 mt-6 text-text-small text-greys-3'}>
                  {department}
                </div>
                <ul className={'list-none'}>
                  {grouppedJobs[department].map((job) => (
                    <li
                      key={job.id}
                      className={
                        'flex flex-col items-start justify-between gap-2 border-t border-greys-1 py-3 md:flex-row md:items-center'
                      }
                    >
                      <a
                        target={'_blank'}
                        rel="noreferrer"
                        className={'apply-circle-on-hover text-header-3'}
                        href={job.shortlink}
                      >
                        {job.title}
                      </a>
                      <span className="text-right text-text-base text-primary-rystad-blue">
                        {job.location.location_str}
                      </span>
                    </li>
                  ))}
                </ul>
              </li>
            ))
          ) : (
            <div className="py-8">Loading...</div>
          )}
        </ul>
      </div>
    </Section>
  )
}

export default CareersOverviewPageJobPostingGrid
