import { gql, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import LoadMore from '../../components/load-more-button'
import Section from '../../components/section'
import {
  EnergyTypeRecord,
  InsightRecord,
  InsightsGridRecord,
  InsightsTypeTagRecord,
} from '../../generated/graphql'
import ALL_ENERGY_TYPES from '../../lib/queries/all-energy-types'
import ALL_INSIGHT_FILTER from '../../lib/queries/all-insight-filter'
import ALL_INSIGHTS_TYPES from '../../lib/queries/all-insights-types'
import InsightFilterContainer from './insight-filter-container'
import InsightGridContainer from './insight-grid-container'
import { groupInsightsByMonths } from './utils/groupInsightsByMonths'
import { getIdForEnergyTag, getIdForInsightTag } from './utils/handleTags'
import useSiteSearch from '../../hooks/use-site-search'

type Props = {
  block: InsightsGridRecord
}
function InsightsGridRecord({ block }: Props) {
  const [filter, setFilter] = useState({})
  const router = useRouter()
  const selectedEnergy = router.query.energy?.toString() ?? ''
  const selectedInsight = router.query.insight?.toString() ?? ''
  const searchValue = router.query.s?.toString() ?? ''
  const LIMIT = 15

  const { data: allEnergyTypes } = useQuery<{
    allEnergyTypes: EnergyTypeRecord[]
  }>(
    gql`
      ${ALL_ENERGY_TYPES}
    `
  )

  const { data: allInsightsTypeTags } = useQuery<{
    allInsightsTypeTags: InsightsTypeTagRecord[]
  }>(
    gql`
      ${ALL_INSIGHTS_TYPES}
    `
  )

  const { data, fetchMore, refetch } = useQuery<{
    allInsights: InsightRecord[]
    _allInsightsMeta: {
      count: number
    }
  }>(ALL_INSIGHT_FILTER, {
    variables: {
      limit: LIMIT,
      offset: 0,
      filter: filter,
    },
  })

  const { data: searchData, isError } = useSiteSearch(searchValue)

  const searchResultSlugs = useMemo(() => {
    return (
      searchData
        ?.filter((foundNews) => foundNews.attributes.url.includes('/insights'))
        .map((news) => news.attributes.url.split('/').pop()!) ?? []
    )
  }, [searchData])

  const monthlyData: Record<string, InsightRecord[]> = useMemo(() => {
    return groupInsightsByMonths(data)
  }, [data])

  useEffect(() => {
    const _filter = {}

    if (selectedEnergy) {
      Object.assign(_filter, {
        energyType: {
          eq: getIdForEnergyTag(selectedEnergy, allEnergyTypes?.allEnergyTypes),
        },
      })
    }

    if (selectedInsight) {
      Object.assign(_filter, {
        tag: {
          eq: getIdForInsightTag(
            selectedInsight,
            allInsightsTypeTags?.allInsightsTypeTags
          ),
        },
      })
    }

    if (searchValue.length > 0) {
      Object.assign(_filter, {
        slug: { in: searchResultSlugs },
      })
    }

    setFilter(_filter)
  }, [selectedEnergy, selectedInsight, searchResultSlugs])

  useEffect(() => {
    refetch()
  }, [filter])

  const handleLoadMore = async () => {
    await fetchMore({
      variables: {
        limit: LIMIT,
        offset: data?.allInsights.length,
      },
    })
  }

  return (
    <Section>
      <InsightGridContainer monthlyData={monthlyData} />
      <InsightFilterContainer
        allEnergyTypes={allEnergyTypes?.allEnergyTypes}
        allInsightTypes={allInsightsTypeTags?.allInsightsTypeTags}
        monthlyData={monthlyData}
      />
      <LoadMore
        onClick={handleLoadMore}
        variant="dark"
        disabled={data?._allInsightsMeta.count === data?.allInsights.length}
      />
    </Section>
  )
}

export default InsightsGridRecord
