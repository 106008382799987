import MenuSolutionItems from './menu-solution-items'
import { ServiceBlock } from '../../../types/service-menu-type'

type Props = {
  items: ServiceBlock[]
}

function AllSolutionBlock({ items }: Props) {
  return (
    <div className="col-span-full overflow-y-auto custom-scrollbar col-start-3 flex flex-row justify-evenly gap-4 max-h-[70vh]">
      {items.map((item, index) => (
        <div key={index} className="flex flex-col gap-2">
          <div className="mt-2 text-text-base font-semibold">{item.name}</div>
          <MenuSolutionItems items={item.solutions} />
        </div>
      ))}
    </div>
  )
}

export default AllSolutionBlock
