import { gql, useQuery } from '@apollo/client'
import Section from '../../components/section'
import {
  EnergyTypeRecord,
  PressReleaseOverviewNewsGridRecord,
  PressReleaseRecord,
} from '../../generated/graphql'
import ALL_NEWS from '../../lib/queries/all-news'

import { useEffect, useMemo, useState } from 'react'
import mapPressreleasesToMonths from '../../utils/map-pressreleases-to-months'
import LoadMore from '../../components/load-more-button'
import SearchForNews from './search-for-news'
import { useRouter } from 'next/router'
import usePressReleaseURLSearch from './search-for-news/use-press-release-url-search'
import useSiteSearch from '../../hooks/use-site-search'
import useFilterRouter from '../industry-energy-page-solution-grid-record/use-filter-router'
import ALL_ENERGY_TYPES from '../../lib/queries/all-energy-types'
import Accordion from '../../components/accordion'
import DownArrow from '../../icons/down-arrow'
import PressReleaseThumbnail from '../press-release-thumbnail'
import NewsFilterItem from './news-filter-item'

type Props = {
  block: PressReleaseOverviewNewsGridRecord
}

const LIMIT = 16

function PressReleaseOverviewNewsGrid({ block }: Props) {
  const router = useRouter()
  const [search, setSearch] = usePressReleaseURLSearch()
  const [filter, setFilter] = useState({})
  const { energy } = useFilterRouter()

  const { data: allEnergyType } = useQuery<{
    allEnergyTypes: EnergyTypeRecord[]
  }>(
    gql`
      ${ALL_ENERGY_TYPES}
    `
  )

  const { data, fetchMore, refetch } = useQuery<{
    allPressReleases: PressReleaseRecord[]
    _allPressReleasesMeta: {
      count: number
    }
  }>(ALL_NEWS, {
    variables: {
      limit: LIMIT,
      offset: 0,
      filter,
    },
  })

  const { data: searchData, isError } = useSiteSearch(
    router.query.s?.toString()
  )

  const searchResultSlugs = useMemo(() => {
    return (
      searchData
        ?.filter((foundNews) => foundNews.attributes.url.includes('/news'))
        .map((news) => news.attributes.url.split('/').pop()!) ?? []
    )
  }, [searchData])

  const energyTypeNames = allEnergyType?.allEnergyTypes?.map(
    (energyType: EnergyTypeRecord) => energyType?.tagName ?? ''
  )
  const energyTypeFilterNames = energyTypeNames ?? []

  const filteredEnergyType = allEnergyType?.allEnergyTypes.find(
    (energy) =>
      energy?.tagName?.length && energy?.tagName === router.query.energy
  )
  const filteredEnergyTypeId = filteredEnergyType?.id ?? ''

  function handleSelectFilterItem(type: string, value: string) {
    router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          [type]: router.query[type] === value ? '' : value,
        },
      },
      undefined,
      { shallow: true, scroll: false }
    )
  }

  const accordionItems = [
    {
      id: '0',
      label: 'Filter by energy type',
      content: (
        <NewsFilterItem
          selected={energy}
          type="energy"
          selectItem={handleSelectFilterItem}
          items={energyTypeFilterNames}
        />
      ),
    },
  ]

  useEffect(() => {
    if (searchResultSlugs.length && filteredEnergyTypeId) {
      setFilter({
        slug: { in: searchResultSlugs },
        energyType: { eq: filteredEnergyTypeId },
      })
    } else if (searchResultSlugs.length) {
      setFilter({
        slug: { in: searchResultSlugs },
      })
    } else if (filteredEnergyTypeId) {
      setFilter({
        energyType: { eq: filteredEnergyTypeId },
      })
    } else {
      setFilter({})
    }
  }, [searchResultSlugs, searchData, filteredEnergyTypeId])

  useEffect(() => {
    refetch()
  }, [filter, filteredEnergyTypeId, searchResultSlugs])

  const handleLoadMore = async () => {
    await fetchMore({
      variables: {
        limit: LIMIT,
        offset: data?.allPressReleases.length,
      },
    })
  }

  const monthlyData: Record<string, PressReleaseRecord[]> = useMemo(() => {
    return mapPressreleasesToMonths(data)
  }, [data])

  return (
    <Section>
      <div
        className={
          'bottom-1 z-10 col-span-8 block justify-between text-text-base text-white sm:col-span-2 md:hidden'
        }
      >
        <SearchForNews change={setSearch} value={search} />
        <div className="text-text-small text-white md:bottom-[0] md:mt-auto">
          <Accordion
            variant="press-release"
            accordionItems={accordionItems}
            openIcon={<DownArrow />}
            closedIcon={<DownArrow />}
          />
        </div>
      </div>

      {((searchData && searchData.length) || !searchData) &&
        Object.keys(monthlyData).map((month) => (
          <div className={'relative z-10 col-span-8'} key={month}>
            <div
              className={
                'top-4 col-span-6 hidden text-header-2-5 text-white sm:col-span-2 md:sticky md:block'
              }
            >
              <h2 className="relative top-10">{month}</h2>
            </div>
            <div
              className={
                'col-span-6 mb-3 grid grid-cols-1 gap-5 md:grid-cols-2 md:pl-[26%]'
              }
            >
              {monthlyData?.[month].map((pressRelease) => (
                <PressReleaseThumbnail
                  key={pressRelease.slug}
                  news={pressRelease}
                />
              ))}
            </div>
          </div>
        ))}
      <div
        className={
          'bottom-1 z-10 col-span-6 mt-6 hidden justify-between bg-dusty-dark-blue text-text-base text-white sm:col-span-2 md:sticky md:block'
        }
      >
        <SearchForNews change={setSearch} value={search} />
        <div className="text-text-small text-white md:bottom-[0] md:mt-auto">
          <Accordion
            variant="press-release"
            accordionItems={accordionItems}
            openIcon={<DownArrow />}
            closedIcon={<DownArrow />}
          />
        </div>
      </div>

      {searchData && !searchData.length && (
        <div
          className={
            'z-10 col-span-6 grid h-[20vw] grid-cols-1 gap-5 md:grid-cols-2'
          }
        >
          <h2 className="text-white">{"We couldn't find any results"}</h2>
        </div>
      )}

      {filteredEnergyTypeId && !data?.allPressReleases.length && (
        <div
          className={
            'z-10 col-span-6 grid h-[20vw] grid-cols-1 gap-5 md:grid-cols-2'
          }
        >
          <h2 className="text-white">{"We couldn't find any results"}</h2>
        </div>
      )}

      {((searchData && searchData.length) ||
        (!searchData &&
          data?.allPressReleases &&
          data.allPressReleases.length > 0)) && (
        <LoadMore
          onClick={handleLoadMore}
          variant="light"
          disabled={
            data?._allPressReleasesMeta.count === data?.allPressReleases.length
          }
        />
      )}
    </Section>
  )
}

export default PressReleaseOverviewNewsGrid
