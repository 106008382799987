import { Image } from 'react-datocms'
import Section from '../components/section'
import { CareersAboutModuleRecord } from '../generated/graphql'
import CtaLink from '../components/cta-link'

type Props = {
  block: CareersAboutModuleRecord
}

type CmsDesignType = { [key: string]: string }

const IMAGE_POSITIONS: CmsDesignType = {
  left: 'lg:flex-row',
  right: 'lg:flex-row-reverse',
}

function CareersAboutModule({ block }: Props) {
  const imagePosition = block?.imageWithPosition[0]?.imagePosition
  let direction = ''

  const imagePositionParam = `${imagePosition}`

  if (imagePosition) {
    direction = IMAGE_POSITIONS[imagePositionParam]
  }

  return (
    <div id={block?.slug || ''}>
      <div
        className={`col-span-2 mt-3 flex w-full flex-col p-[-4] md:col-span-8 ${direction} md:hidden`}
      >
        {block.imageWithPosition[0]?.image?.responsiveImage && (
          <div className="md:w-1/2">
            <Image
              style={{ maxWidth: 'none' }}
              data={block.imageWithPosition[0]?.image?.responsiveImage!}
            />
          </div>
        )}

        <div
          className=" col-span-8 flex min-h-[320px] flex-col items-center justify-center px-2 py-3 pt-5 md:w-1/2 md:gap-5 md:py-0 md:px-10"
          style={{
            backgroundColor: block.backgroundColorHex?.hex ?? 'white',
          }}
        >
          <div className="flex max-w-xl flex-col gap-4">
            <h2 className="text-header-1 text-primary-rystad-blue">
              {block.headline}
            </h2>

            <p className="font-light text-primary-rystad-blue">
              {block.description}
            </p>

            <div className="mt-3 flex flex-col gap-3">
              {block.cta.map((action) => (
                <CtaLink key={action.id} cta={action} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <span className="hidden md:block">
        <Section>
          <div
            className={`col-span-2 mt-3 mb-3 flex w-full flex-col p-[-4] md:col-span-8 ${direction}`}
          >
            {block.imageWithPosition[0]?.image?.responsiveImage && (
              <div className="lg:w-1/2">
                <Image
                  objectFit="cover"
                  style={{ maxWidth: 'none', height: '100%' }}
                  data={block.imageWithPosition[0]?.image?.responsiveImage!}
                />
              </div>
            )}

            <div
              className="col-span-8 flex min-h-[320px] flex-col items-center justify-center px-2 py-3 sm:pt-5 md:gap-5 md:py-0 md:px-10 lg:w-1/2"
              style={{
                backgroundColor: block.backgroundColorHex?.hex ?? 'white',
              }}
            >
              <div className="flex max-w-xl flex-col gap-3">
                <h2 className="text-header-1">{block.headline}</h2>

                <p className="font-light">{block.description}</p>

                <div className="mt-5 w-fit flex gap-3 flex-col">
                  {block.cta.map((action) => (
                    <CtaLink key={action.id} cta={action} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Section>
      </span>
    </div>
  )
}

export default CareersAboutModule
