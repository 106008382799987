import { SolutionsIndustryModuleRecord } from '../../generated/graphql'
import Section from '../../components/section'
import StructuredText from '../../components/structured-text'
import Link from 'next/link'
import CtaLink from '../../components/cta-link'
import ArrowRightButton from '../../components/arrow-right-button'

type Props = {
  block: SolutionsIndustryModuleRecord
}

function SolutionsIndustryModule({ block }: Props) {
  if (!block) {
    return null
  }

  return (
    <Section
      classOverrideInnerGrid={
        'md:grid-cols-2 md:gap-2 md:p-2 lg:grid-cols-8 lg:gap-5 lg:p-5'
      }
    >
      <div className="col-span-3 flex flex-col gap-2">
        <h2
          style={{ scrollMarginTop: 120 }}
          id={block.slug ?? ''}
          className={' text-header-2'}
        >
          {block.headline}
        </h2>
        {block.shortDescription && (
          <p className="mb-2">{block.shortDescription}</p>
        )}
        {block.ctas.map((cta) => (
          <div key={cta.id} className="flex flex-row">
            <CtaLink cta={cta} />
          </div>
        ))}
      </div>
      <div
        className={
          'col-span-5 grid grid-cols-1 gap-x-4 gap-y-3 md:grid-cols-2 md:py-1'
        }
      >
        {block.industryModuleContent.map((industry) => (
          <div key={industry.id} className={'border-t border-t-greys-1 pt-3'}>
            <img
              className="mb-3 h-4"
              width={30}
              src={industry.industryIcon?.url}
              alt={industry.industryIcon?.alt!}
            />
            <h3 className={'mb-2 text-header-3'}>{industry.headline}</h3>
            <StructuredText data={industry.industryExcerpt?.value} />
            {industry.ctas && industry.ctas.length > 0 && (
              <div className={'flex flex-col gap-1'}>
                {industry.ctas.map((cta) => {
                  return (
                    <div key={cta.id}>
                      <ArrowRightButton link={cta.href} variant={'primary'}>
                        {cta.text}
                      </ArrowRightButton>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    </Section>
  )
}

export default SolutionsIndustryModule
