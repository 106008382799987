import ArrowRightButton from '../../components/arrow-right-button'
import { Solution } from '../../types/service-menu-type'

type Props = {
  solutions: Solution[]
}

function ServiceBlock({ solutions }: Props) {
  return (
    <div className={`grid gap-5 md:grid-cols-3`}>
      {solutions.map((item: Solution, index) => (
        <div
          className={`group relative min-h-[212px]  overflow-x-hidden pt-3`}
          key={item.slug}
        >
          <div
            className={
              'absolute top-[0px] hidden h-[1px] w-full bg-greys-1 md:block'
            }
          />
          <div
            className={
              'absolute top-[0px] hidden h-[1px] w-full -translate-x-full bg-primary-rystad-blue transition-transform duration-700 group-hover:translate-x-[0] md:block'
            }
          />
          <div className="flex h-full flex-col justify-between gap-3">
            <div className="flex flex-col gap-1">
              <h2 className="text-header-4 font-medium">{item.name}</h2>
              <p>{item.excerpt}</p>
            </div>
            <ArrowRightButton link={`/services/${item.slug}`}>
              Read more
            </ArrowRightButton>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ServiceBlock
