import MainMenu from './main-menu'
import { MenuStatusProvider } from './menu-context'
import { MenuRecord } from '../../generated/graphql'
import ColorConfig from '../../types/ColorConfig'

type Props = {
  block: MenuRecord
  colorConfig: ColorConfig
}

function Index({ block, colorConfig }: Props) {
  return (
    <MenuStatusProvider colorConfig={colorConfig} block={block}>
      <MainMenu colorConfig={colorConfig} />
    </MenuStatusProvider>
  )
}

export default Index
