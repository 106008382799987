import Section from '../../components/section'
import { CareersOverviewPageJoinUsWidgetRecord } from '../../generated/graphql'

type Props = {
  block: CareersOverviewPageJoinUsWidgetRecord
}

function CareersOverviewPageJoinUsWidgetRecord({ block }: Props) {
  return (
    <Section classOverride="pt-9 pb-7 md:pb-4" id={block?.slug || ''}>
      <div className="col-span-2 flex flex-col gap-2">
        <h2 className="text-header-1">{block.headline}</h2>
        <h2 className="font-body text-text-base">{block.description}</h2>
      </div>
    </Section>
  )
}

export default CareersOverviewPageJoinUsWidgetRecord
