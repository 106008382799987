import { CtaLinkRecord, SolutionSubMenuRecord } from '../../generated/graphql'
import CtaLink from '../../components/cta-link'
import Link from 'next/link'

type Props = {
  block: SolutionSubMenuRecord
}

export function isCtaLinkRecord(
  record: CtaLinkRecord
): record is CtaLinkRecord {
  return record?.__typename === 'CtaLinkRecord'
}

function SolutionSubmenu({ block }: Props) {
  return (
    <div className={'xl:mx-auto xl:max-w-fullbleed'}>
      <div
        className={
          'no-scrollbar flex snap-x scroll-pl-2 flex-row items-center gap-2 overflow-x-scroll p-2 md:gap-4 md:p-2'
        }
      >
        {block?.anchorPoints?.map((anchor) => {
          return (
            <Link
              key={anchor.id}
              className={'block shrink-0 snap-start text-text-small'}
              href={anchor.link ?? ''}
            >
              {anchor.ctaLabel}
            </Link>
          )
        })}

        <div className={'ml-auto'}>
          {block?.rightHandSideLink?.map(
            (link) =>
              isCtaLinkRecord(link) && <CtaLink key={link.id} cta={link} />
          )}
        </div>
      </div>
    </div>
  )
}

export default SolutionSubmenu
