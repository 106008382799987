import Link from 'next/link'
import MenuLeftArrow from '../../../icons/menu-left-arrow'
import { ServiceBlock } from '../../../types/service-menu-type'
import { useMenuStatus } from '../context'

type Props = {
  items: ServiceBlock[]
}
function MobileAllSolutionBlock({ items }: Props) {
  const { dispatch } = useMenuStatus()
  return (
    <>
      <div
        className={
          'col-span-full mb-3 flex flex-row items-center justify-between'
        }
      >
        <button
          onClick={() => {
            dispatch({
              type: 'reset',
            })
          }}
          className={'flex flex-row items-center gap-2 text-text-base'}
        >
          <MenuLeftArrow />
          Back
        </button>
        <Link scroll={false} href="/services">
          <a>All solutions</a>
        </Link>
      </div>
      <div className="col-span-full flex flex-col items-start gap-4 pl-3">
        {items.map((item, index) => (
          <div key={index} className="mb-2">
            <div className="ext-text-base pb-2">{item.name}</div>
            <div className="flex flex-col gap-1">
              {item.solutions.map((solution, index) => (
                <Link
                  scroll={false}
                  key={index}
                  href={`/services/${solution.slug}`}
                >
                  <a>
                    <span className="text-text-small">{solution.name}</span>
                  </a>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default MobileAllSolutionBlock
