import Link from 'next/link'
import { Image } from 'react-datocms'
import ArrowRightButton from '../../components/arrow-right-button'
import { Solution } from '../../types/service-menu-type'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { Mousewheel, Navigation } from 'swiper'
import { useCallback, useRef, useState } from 'react'
import ArrowLeft from '../../icons/arrow-left'
import ArrowRight from '../../icons/arrow-right'

type Props = {
  solutions: Solution[]
}
function SolutionCardList({ solutions }: Props) {
  const prevButton = useRef(null)
  const nextButton = useRef(null)
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev()
  }, [swiperRef])

  const handleNext = useCallback(() => {
    swiperRef?.slideNext()
  }, [swiperRef])

  return (
    <>
      <Swiper
        className="w-full"
        modules={[Mousewheel, Navigation]}
        spaceBetween={36}
        slidesPerView="auto"
        onSwiper={setSwiperRef}
        mousewheel={{ forceToAxis: true }}
        centeredSlides={false}
        direction={'horizontal'}
        navigation={{
          prevEl: prevButton.current,
          nextEl: nextButton.current,
        }}
        breakpoints={{
          768: {
            spaceBetween: 56,
          },
          1024: {
            spaceBetween: 72,
          },
        }}
      >
        {solutions.map((solution, index) => (
          <SwiperSlide
            key={index}
            className="group z-30 flex aspect-square shrink-0 snap-start flex-col overflow-hidden rounded-bl-lg rounded-br-lg rounded-tl-lg rounded-tr-lg shadow-md"
          >
            <Link href={`/services/${solution.slug}`}>
              <a className="h-full w-full ">
                {solution.coverImage ? (
                  <Image
                    className="rounded-tl-lg rounded-tr-lg"
                    data={solution.coverImage}
                  />
                ) : (
                  <div className="w-full bg-primary-rystad-blue-light-tint"></div>
                )}
                <div className="flex h-full w-full flex-col gap-1 bg-white p-3">
                  <h3 className="text-text-base font-medium">
                    {solution.name}
                  </h3>
                  <p className="text-text-small line-clamp-3">
                    {solution.excerpt}
                  </p>
                  <div className="block w-fit text-text-small">
                    <ArrowRightButton link={`/services/${solution.slug}`}>
                      Learn more
                    </ArrowRightButton>
                  </div>
                </div>
              </a>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="absolute -bottom-4 right-3 mr-[16px] hidden justify-end gap-[47px] md:mr-[48px] md:flex">
        <button
          ref={prevButton}
          className="cursor-pointer disabled:opacity-20"
          onClick={handlePrevious}
        >
          <ArrowLeft />
        </button>
        <button
          ref={nextButton}
          className="cursor-pointer disabled:opacity-20"
          onClick={handleNext}
        >
          <ArrowRight />
        </button>
      </div>
    </>
  )
}

export default SolutionCardList
