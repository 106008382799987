import { Image } from 'react-datocms'
import AutoplayVideo from '../../components/autoplay-video'
import CtaLink from '../../components/cta-link'
import { SubpageHeroRecord } from '../../generated/graphql'

type Props = {
  block: SubpageHeroRecord
}

function SubpageHero({ block }: Props) {
  return (
    <section className="h-[600px]" id={block?.slug || ''}>
      <div className="absolute left-1/2 grid h-full h-auto w-full max-w-fullbleed -translate-x-1/2 grid-cols-2 gap-2 px-2 md:grid-cols-8 md:gap-5 md:px-5">
        <div className="col-span-full col-start-1 flex h-full w-full flex-col justify-center gap-6 p-2 md:col-span-6 lg:col-span-4">
          <div className="mt-[150px] flex flex-col gap-2">
            <h2 className="text-text-small text-greys-0">{block?.label}</h2>
            <h1 className="text-header-1 text-greys-0">{block?.headline}</h1>
            <p className="text-text-base text-greys-0">{block?.description}</p>
          </div>
          <div className="flex max-w-[70%] flex-col">
            {block.cta.map((cta) => (
              <div
                key={cta.id}
                className={'group relative overflow-hidden py-3'}
              >
                <div
                  className={'absolute top-[-1px] h-[2px] w-full bg-white/20'}
                />

                <div
                  className={
                    'absolute top-[-1px] h-[2px] w-full -translate-x-full bg-white transition-transform duration-300 group-hover:translate-x-[0]'
                  }
                />
                <span className="h-[1px] w-full -translate-x-full  transition-transform duration-1000 group-hover:translate-x-[0]" />
                <CtaLink cta={cta} />
              </div>
            ))}
          </div>
        </div>
      </div>

      {block.backgroundVideoLink && (
        <div className="relative z-[-1] min-h-full w-auto min-w-full object-cover">
          <AutoplayVideo
            videoSrc={block.backgroundVideoLink!}
            fallBackImage={block.backgroundImage?.responsiveImage!}
          />
        </div>
      )}
      {block.backgroundImage && (
        <div className="relative z-[-1] col-span-full brightness-[0.6]">
          <Image
            style={{ minWidth: '100vw' }}
            className="h-[600px] min-w-full"
            objectFit="cover"
            data={block.backgroundImage.responsiveImage!}
          />
        </div>
      )}
    </section>
  )
}

export default SubpageHero
