import { gsap, ScrollTrigger } from '../../lib/gsap'
import { RefObject } from 'react'

function animateDigits(
  stage: RefObject<HTMLDivElement>,
  blocks: RefObject<{ el: HTMLDivElement | null; digit: number }[][]>
) {
  ScrollTrigger.matchMedia({
    '(min-width: 0px)': function () {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: stage.current,
          start: 'top center',
        },
      })

      const allRefs: any = []

      blocks.current?.forEach((block: any) => {
        block.map((digit: any) => {
          allRefs.push(digit.el)
        })
      })

      tl.fromTo(
        allRefs,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
        }
      )

      blocks.current?.forEach((block: any) => {
        block.map((digit: any, i: number) => {
          const digitNumber = digit.digit
          let counter = 1
          let duration = 6

          if (i === 0) {
            counter = 1
            duration = 2
          } else if (i === 1) {
            counter = 2
            duration = 1
          } else if (i === 2) {
            counter = 5
            duration = 0.6
          } else if (i === 3) {
            counter = 14
            duration = 0.3
          } else {
            counter = 22
            duration = 0.2
          }

          if (i === 0) {
            tl.fromTo(
              digit.el,
              {
                y: '0',
                ease: 'linear',
              },
              {
                y: '-91%',
                ease: 'linear',
                duration: duration,
              },
              '<='
            )
          } else
            tl.to(
              digit.el,
              {
                y: '-91%',
                repeat: counter,
                ease: 'linear',
                duration: duration,
              },
              '<='
            )
        })
      })
    },
  })
}

export default animateDigits
