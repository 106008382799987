import _ from 'lodash'
import { useRouter } from 'next/router'
import Accordion from '../../components/accordion'
import {
  EnergyTypeRecord,
  InsightRecord,
  InsightsTypeTagRecord,
} from '../../generated/graphql'
import DownArrow from '../../icons/down-arrow'
import NewsFilterItem from '../press-release-overview-news-grid/news-filter-item'
import InsightSearch from './insight-search'
import { getAllTagLabel } from './utils/handleTags'

type Props = {
  allEnergyTypes?: EnergyTypeRecord[]
  allInsightTypes?: InsightsTypeTagRecord[]
  monthlyData: Record<string, InsightRecord[]>
}

function InsightFilterContainer({
  allEnergyTypes,
  allInsightTypes,
  monthlyData,
}: Props) {
  const energyTypes = getAllTagLabel(allEnergyTypes ?? [])
  const insightTypes = getAllTagLabel(allInsightTypes ?? [])
  const router = useRouter()
  const selectedEnergy = router.query.energy?.toString() ?? ''
  const selectedInsight = router.query.insight?.toString() ?? ''

  function handleSelectFilterItem(type: string, value: string) {
    router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          [type]: router.query[type] === value ? '' : value,
        },
      },
      undefined,
      { shallow: true, scroll: false }
    )
  }

  const accordionItems = [
    {
      id: '0',
      label: 'Filter by insight type',
      content: (
        <NewsFilterItem
          selected={selectedInsight}
          type="insight"
          selectItem={handleSelectFilterItem}
          items={insightTypes}
        />
      ),
    },
    {
      id: '1',
      label: 'Filter by energy type',
      content: (
        <NewsFilterItem
          selected={selectedEnergy}
          type="energy"
          selectItem={handleSelectFilterItem}
          items={energyTypes}
        />
      ),
    },
  ]
  return (
    <>
      <div
        className={
          'bottom-1 z-10 col-span-6 row-start-1 mt-6 block justify-between bg-lights-sand text-text-base text-current sm:col-span-2 md:sticky md:row-auto md:block'
        }
      >
        <InsightSearch />
        <div className="text-text-small text-current md:bottom-[0] md:mt-auto">
          <Accordion
            accordionItems={accordionItems}
            openIcon={<DownArrow />}
            closedIcon={<DownArrow />}
            variant="insight"
          />
        </div>
      </div>
      {_.isEmpty(monthlyData) && (
        <div
          className={
            'z-10 col-span-6 grid h-[20vw] grid-cols-1 gap-5 md:grid-cols-2'
          }
        >
          <h2 className="text-current">{"We couldn't find any results"}</h2>
        </div>
      )}
    </>
  )
}

export default InsightFilterContainer
