import CtaLink from '../../components/cta-link'
import { LinksArrayRecord } from '../../generated/graphql'

type Props = {
  block: LinksArrayRecord
}

const LinksArrayRecord = ({ block }: Props) => {
  const { links } = block
  return (
    <div className="w-full">
      <div className="mx-auto grid w-full max-w-fullbleed grid-cols-12 px-2 py-8 lg:px-5">
        <div className="prose- col-span-12 md:col-span-7 lg:col-span-5 xl:col-span-4">
          {links.map((link) => (
            <div key={link.id} className="border-t py-3">
              <CtaLink cta={link} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LinksArrayRecord
