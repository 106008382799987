import React from 'react'

type Props = {
  placeholder?: string
  disabled?: boolean
  error?: string
  name?: string
  label?: string
  className?: string
  type: string
  autofocus?: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Input = ({
  disabled,
  placeholder,
  label,
  error,
  name,
  type,
  className,
  autofocus,
  onChange,
}: Props) => {
  return (
    <label className={'flex w-full flex-1 flex-col items-start justify-start'}>
      {label && label.length > 0 && (
        <span className="text-base flex w-full flex-row  items-center justify-between text-[15px] text-[#292862]">
          {label}
        </span>
      )}
      <div className="relative mx-0 my-1 w-full">
        <input
          disabled={disabled}
          autoFocus={autofocus}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          name={name}
          className={` text-base
          relative flex h-auto w-full items-center justify-between rounded rounded-md border border-[#d3d2e2] bg-white px-[12px] py-[10px] text-[15px] placeholder-greys-4 outline-0 transition-all duration-200 ease-in-out focus:border-[#292862] focus:bg-white focus:outline-0 active:border-[#292862] active:bg-white active:outline-0 ${className}`}
        />
      </div>
      {error && error.length > 0 && (
        <span className="text-base text-[11px] text-[#b02a37]">{error}</span>
      )}
    </label>
  )
}

export default Input
