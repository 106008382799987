import { PeopleCareerVideoImageModuleRecord } from '../../generated/graphql'
import useMediaQuery from '../../hooks/use-media-query'
import DesktopPeopleVideoModule from './desktop-people-video-module'
import MobilePeopleVideoModule from './mobile-people-video-module'

type Props = {
  block: PeopleCareerVideoImageModuleRecord
}

function PeopleVideoModule({ block }: Props) {
  const isSmallScreen = useMediaQuery('(max-width: 768px)')

  if (isSmallScreen) {
    return <MobilePeopleVideoModule block={block} />
  }
  return <DesktopPeopleVideoModule block={block} />
}

export default PeopleVideoModule
