import { StructuredText as DatoStructuredText } from 'react-datocms'
import { ThreeColumnsRichTextWidgetRecord } from '../../generated/graphql'

type Props = {
  block: ThreeColumnsRichTextWidgetRecord
}

const ThreeColumnsRichTextWidget = ({ block }: Props) => {
  const { firstColumn, secondColumn, thirdColumn, slug } = block

  return (
    <div
      className="w-full py-2 md:py-8"
      style={{ scrollMarginTop: 80 }}
      id={slug || ''}
    >
      <div className="mx-auto grid max-w-fullbleed gap-3 px-2 md:grid-cols-8 md:gap-8 md:px-5">
        <div className="prose-headings:text-header-2 prose-p:mb-3 prose-p:text-text-manchet md:col-span-2 md:col-start-1">
          <DatoStructuredText data={firstColumn?.value} />
        </div>
        <div className="prose-headings:text-header-2 prose-p:mb-3 prose-p:text-text-manchet md:col-span-3 md:col-start-3">
          <DatoStructuredText data={secondColumn?.value} />
        </div>
        <div className="prose-headings:text-header-2 prose-p:mb-3 prose-p:text-text-manchet md:col-span-3 md:col-start-6">
          <DatoStructuredText data={thirdColumn?.value} />
        </div>
      </div>
    </div>
  )
}

export default ThreeColumnsRichTextWidget
