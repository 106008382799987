import { createContext, ReactNode, useContext, useReducer } from 'react'
import { MenuRecord } from '../../generated/graphql'
import Block from '../../types/Block'
import ColorConfig from '../../types/ColorConfig'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import { useRouter } from 'next/router'

export type Action =
  | { type: 'toggleMobileMenuOpen'; payload: boolean }
  | { type: 'atTheTop' }
  | { type: 'toggleMobileIsSearching'; payload: boolean }
  | { type: 'updateScrollState'; payload: ScrollState }
  | { type: 'setMobileMenuItemBlocks'; payload: Block[] | null }
  | { type: 'setDesktopMenuItemBlocks'; payload: Block[] | null }

export enum ScrollState {
  'transparent',
  'hidden',
  'white',
}

type Dispatch = (action: Action) => void
type State = {
  scrollState: ScrollState
  isMobileMenuOpen: boolean
  isMobileSearching: boolean
  mobileMenuItemBlocks: null | Block[]
  desktopMenuItemBlocks: null | Block[]
  colorConfig: ColorConfig
  block: MenuRecord
}

type Props = {
  children: ReactNode
  block: MenuRecord
  colorConfig: ColorConfig
}

const MainMenuStateContext = createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

function updateMenuStatus(state: State, action: Action) {
  switch (action.type) {
    case 'setMobileMenuItemBlocks':
      return {
        ...state,
        mobileMenuItemBlocks: action.payload,
      }
    case 'setDesktopMenuItemBlocks':
      return {
        ...state,
        scrollState: ScrollState.white,
        desktopMenuItemBlocks: action.payload,
      }
    case 'toggleMobileMenuOpen': {
      return {
        ...state,
        mobileMenuItemBlocks: null,
        isMobileMenuOpen: action.payload,
      }
    }
    case 'updateScrollState':
      return {
        ...state,
        scrollState: action.payload,
      }
    case 'toggleMobileIsSearching':
      return {
        ...state,
        isMobileSearching: action.payload,
      }
    case 'atTheTop':
      return {
        ...state,
        scrollState: state.desktopMenuItemBlocks?.length
          ? ScrollState.white
          : ScrollState.transparent,
      }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

function MenuStatusProvider({ children, block, colorConfig }: Props) {
  const [state, dispatch] = useReducer(updateMenuStatus, {
    isMobileMenuOpen: false,
    isMobileSearching: false,
    mobileMenuItemBlocks: null,
    desktopMenuItemBlocks: null,
    colorConfig: colorConfig,
    block,
    scrollState: ScrollState.transparent,
  })

  const router = useRouter()

  useIsomorphicLayoutEffect(() => {
    router.events.on('routeChangeStart', () => {
      dispatch({
        type: 'setDesktopMenuItemBlocks',
        payload: null,
      })
      dispatch({
        type: 'setMobileMenuItemBlocks',
        payload: null,
      })
      dispatch({
        type: 'toggleMobileMenuOpen',
        payload: false,
      })
      dispatch({
        type: 'updateScrollState',
        payload: ScrollState.transparent,
      })
    })
  }, [router.events])

  const value = { state, dispatch }
  return (
    <MainMenuStateContext.Provider value={value}>
      {children}
    </MainMenuStateContext.Provider>
  )
}

function useMenuContext() {
  const context = useContext(MainMenuStateContext)
  if (context === undefined) {
    throw new Error('useMenuStatus must be used within a MenuStatusProvider')
  }
  return context
}

export { MenuStatusProvider, useMenuContext }
