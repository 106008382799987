import Link from 'next/link'
import { Image } from 'react-datocms'
import { CardGridCardRecord } from '../../../generated/graphql'

type Props = {
  card: CardGridCardRecord
}

function DesktopCard({ card }: Props) {
  return (
    <Link scroll={false} key={card.id} href={card.link}>
      <div className="group isolate hidden h-full cursor-pointer flex-col overflow-hidden rounded-bl-lg rounded-br-lg rounded-tl-lg rounded-tr-lg shadow-2xl sm:flex">
        <div className="relative flex aspect-[1.77] flex-col items-center justify-center overflow-hidden group-hover:bg-primary-rystad-blue">
          <div className="absolute isolate z-10 overflow-hidden">
            <p className="translate-y-[2em] text-text-small text-white transition-all duration-500 group-hover:translate-y-[0]">
              Explore
            </p>
          </div>
          <div className="absolute h-full w-full transition-all duration-500 group-hover:scale-110 group-hover:opacity-90">
            <Image
              layout="fill"
              objectFit="cover"
              objectPosition={'center'}
              className="rounded-tl-lg rounded-tr-lg"
              data={card.image?.responsiveImage!}
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-[4px] bg-white p-3">
          <h2 className="text-text-base font-medium">{card.title}</h2>
          <h2 className="text-text-small">{card.shortExcerpt}</h2>
        </div>
      </div>
    </Link>
  )
}

export default DesktopCard
