import { gql } from '@apollo/client'
import responsiveImageFragment from '../fragments/responsive-image'
import tag from '../fragments/tag'

const ALL_INSIGHT_FILTER = gql`
query AllInsights($limit: IntType, $offset: IntType, $filter: InsightModelFilter) {
  allInsights(first: $limit, skip: $offset, filter: $filter, orderBy: publicationDate_DESC) {
    id
    publicationDate
    slug
    image {
      responsiveImage {
        ...responsiveImageFragment
      }
    }
    energyType {
      tagName
      tagDotColor{
        hex
      }
    }
    ${tag}
    title
  }
  _allInsightsMeta {
    count
  }
}
${responsiveImageFragment}
`

export default ALL_INSIGHT_FILTER
