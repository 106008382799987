import React, { useRef, useState, useCallback, useEffect } from 'react'
import { SelectedInsightRecord } from '../../generated/graphql'
import { Swiper, SwiperSlide } from 'swiper/react'
import Section from '../../components/section'
import InsightGridItem from '../insights-grid-record/insight-grid-item'
import styles from './selected-insights.module.css'
import { Swiper as SwiperClass } from 'swiper/types'
import { Navigation } from 'swiper'
import ArrowLeft from '../../icons/arrow-left'
import ArrowRight from '../../icons/arrow-right'

type Props = {
  block: SelectedInsightRecord
}

const SelectedInsights = ({ block }: Props) => {
  const prevButton = useRef(null)
  const nextButton = useRef(null)
  const [disablePrevButton, setDisablePrevButton] = useState(true)
  const [disableNextButton, setDisableNextButton] = useState(false)
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()

  const checkSlideMovement = useCallback(() => {
    if (swiperRef) {
      setDisableNextButton(swiperRef?.isEnd)
      setDisablePrevButton(swiperRef?.isBeginning)
    }
  }, [swiperRef])

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev()
    setDisablePrevButton(swiperRef && swiperRef?.snapIndex === 0 ? true : false)
    setDisableNextButton(false)
  }, [swiperRef])

  const handleNext = useCallback(() => {
    swiperRef?.slideNext()
    setDisablePrevButton(swiperRef && swiperRef?.snapIndex > 0 ? false : true)
  }, [swiperRef])

  return (
    <Section
      classOverride={'my-7'}
      classOverrideInnerGrid={
        'py-[0] md:py-[0] md:gap-0 gap-0 grid-cols-1 md:pr-0 pr-0'
      }
    >
      {block.title && (
        <h2 className="col-span-full mb-[32px] text-header-2">{block.title}</h2>
      )}
      {block.insights.length > 0 && (
        <div className="col-span-full">
          <div className="mb-[24px] mr-[16px] flex justify-end gap-[47px] md:mr-[48px]">
            <button
              ref={prevButton}
              onClick={handlePrevious}
              className="cursor-pointer disabled:opacity-20"
              disabled={disablePrevButton}
            >
              <ArrowLeft />
            </button>
            <button
              ref={nextButton}
              onClick={handleNext}
              className="cursor-pointer disabled:opacity-20"
              disabled={disableNextButton}
            >
              <ArrowRight />
            </button>
          </div>
          <Swiper
            modules={[Navigation]}
            className={`${styles.Swiper} no-scrollbar`}
            onSwiper={setSwiperRef}
            spaceBetween={40}
            slidesPerView={1.5}
            direction={'horizontal'}
            onReachEnd={() => setDisableNextButton(true)}
            onSliderMove={() => checkSlideMovement()}
            breakpoints={{
              768: {
                slidesPerView: 2.7,
                spaceBetween: 20,
              },
            }}
          >
            {block.insights?.map((insight) => {
              return (
                <SwiperSlide key={insight.id}>
                  <InsightGridItem insight={insight} />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      )}
    </Section>
  )
}

export default SelectedInsights
