import { useRouter } from 'next/router'
import { SolutionFilterTypes } from '../../enums/solution-filter-types'
import { SolutionFilters } from '../../types/SolutionFilters'
import { SolutionQueryProps } from '../../types/SolutionQueryProps'
import { SolutionQueryTypeAndValue } from '../../types/SolutionQueryTypeAndValue'

function useMultipleFilterClear() {
  const router = useRouter()
  let resultQuery: SolutionQueryProps = {}

  return (
    queryTypeAndValue: SolutionQueryTypeAndValue,
    filters: SolutionFilters
  ) => {
    const copyRouterQuery = { ...router.query }
    const filter = filters[queryTypeAndValue.type]
    const { industry, energy, energyTheme, ...queryWithoutField } =
      copyRouterQuery

    delete copyRouterQuery['filter']

    if (filter === queryTypeAndValue.value) {
      if (queryTypeAndValue.type === SolutionFilterTypes.INDUSTRY) {
        delete copyRouterQuery[SolutionFilterTypes.INDUSTRY]
      }
      if (queryTypeAndValue.type === SolutionFilterTypes.ENERGY) {
        delete copyRouterQuery[SolutionFilterTypes.ENERGY]
      }
      if (queryTypeAndValue.type === SolutionFilterTypes.ENERGY_THEME) {
        delete copyRouterQuery[SolutionFilterTypes.ENERGY_THEME]
      }
    }

    resultQuery = copyRouterQuery

    if (filter !== queryTypeAndValue.value) {
      resultQuery[queryTypeAndValue.type] = queryTypeAndValue.value
    }

    if (
      Object.keys(resultQuery).length === 1 &&
      Object.keys(resultQuery)[0] === 'slug'
    ) {
      resultQuery['filter'] = ''
    }

    router.replace({
      pathname: router.pathname,
      query: {
        ...resultQuery,
      },
    })

    return resultQuery
  }
}

export default useMultipleFilterClear
