import Link from 'next/link'
import CtaLink from '../../components/cta-link'
import MultilineParagraph from '../../components/multiline-paragraph'
import Section from '../../components/section'
import { RichTextWidgetWithBreadcrumbRecord } from '../../generated/graphql'

type Props = {
  block: RichTextWidgetWithBreadcrumbRecord
}

function RichTextWidgetWithBreadcrumbRecord({ block }: Props) {
  return (
    <Section
      backgroundColor={block.backgroundColorHex}
      classOverride={'py-9 md:py-11'}
      classOverrideInnerGrid={'py-[0] md:py-[0]'}
    >
      <p className="col-span-full col-start-1 text-left text-header-2 lg:col-span-2 lg:inline-block">
        {block.breadcrumb.map((breadcrumb, index) =>
          breadcrumb.link ? (
            <Link key={breadcrumb.id} href={breadcrumb.link}>
              <a
                href={breadcrumb.link}
                className="text-text-base font-medium hover:underline"
              >
                {(index > 0 ? ' / ' : '') + breadcrumb.label}
              </a>
            </Link>
          ) : (
            <span
              key={breadcrumb.id}
              className="text-text-base font-thin text-greys-3"
            >
              {(index > 0 ? ' / ' : '') + breadcrumb.label}
            </span>
          )
        )}
      </p>
      <div
        className={`
        col-span-full flex flex-col items-start
        lg:col-span-full lg:col-start-4`}
      >
        <MultilineParagraph content={block.description!} />
      </div>
      {block.ctaButton.length > 0 && (
        <div className="col-span-full col-start-1 mt-6 flex flex-col gap-2 lg:col-start-4 lg:mt-4">
          {block.ctaButton.map((cta) => (
            <div key={cta.id} className="flex flex-row">
              <CtaLink cta={cta} />
            </div>
          ))}
        </div>
      )}
    </Section>
  )
}

export default RichTextWidgetWithBreadcrumbRecord
