import { RefObject } from 'react'
import { gsap } from '../../lib/gsap'

function animateTextReveal(
  stage: RefObject<HTMLDivElement>,
  refs: RefObject<(HTMLDivElement | null)[]>
) {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: stage.current,
      scrub: true,
      start: 'top top',
      end: '200%',
      pin: true,
      pinSpacing: true,
      refreshPriority: 100,
    },
  })
  refs.current?.forEach((ref, index) => {
    if (index !== 0) {
      tl.from(ref, {
        opacity: 0,
        yPercent: 300,
        ease: 'none',
      })
    }

    if (refs.current?.[index - 1]) {
      tl.to(
        refs.current[index - 1],
        {
          opacity: 0,
          yPercent: -300,
          ease: 'none',
        },
        '<'
      )
    }
  })
}

export default animateTextReveal
