import { ContactPageBusinessAreaRecord } from '../../generated/graphql'
import ContactBlock from './contact-block'

type Props = {
  business: ContactPageBusinessAreaRecord
}

function ContactBusinessAreaModel({ business }: Props) {
  return (
    <>
      <h3 className="col-span-1 col-start-1 mt-9 text-header-3 font-medium md:mt-[0]">
        {business.headline}
      </h3>
      <>
        <h4 className="col-span-2 col-start-1 mt-4 text-header-4 font-medium md:col-start-5 md:mt-[0]">
          {business.mondayToFriday}
        </h4>
        <ContactBlock
          asiaPacific={business.asiaPacific!}
          americas={business.americas!}
          europe={business.europe!}
        />
        <div className="col-span-2 col-start-1 flex flex-col gap-1 md:col-start-5">
          <span className="text-header-4 font-medium">
            {business.sunday09001700Gst}
          </span>
          <span>{business.global}</span>
        </div>
      </>
    </>
  )
}

export default ContactBusinessAreaModel
