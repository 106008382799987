import { ReactNode, useEffect, useRef } from 'react'
import { gsap } from '../../lib/gsap'

type AccordionItemProps = {
  children: ReactNode
  height: number | string
}

function AccordionItem({ children, height }: AccordionItemProps) {
  const ref = useRef(null)

  useEffect(() => {
    gsap.to(ref.current, {
      height,
      duration: 0.2,
    })
  }, [height])

  return (
    <div className={'flex flex-row overflow-hidden'} ref={ref}>
      {children}
    </div>
  )
}

export default AccordionItem
