type Props = {
  size?: number | string
}

function SharpArrow({ size }: Props) {
  return (
    <svg
      width={size ?? 19}
      height={size ?? 19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8994 14.4492L5.62091 5.17071"
        stroke="white"
        strokeWidth="1.23017"
      />
      <path
        d="M4.99957 12.75V4.54888H13.2007"
        stroke="white"
        strokeWidth="1.23017"
      />
    </svg>
  )
}

export default SharpArrow
