import { RefObject } from 'react'
import { gsap } from '../../lib/gsap'
import tw from '../../../tailwind.config'

function animateBackground(stage: RefObject<HTMLDivElement>) {
  const colors = tw.theme?.colors as any
  gsap.to(stage.current, {
    scrollTrigger: {
      trigger: stage.current,
      scrub: true,
    },
    backgroundColor: colors.primary.orange,
  })
}

export default animateBackground
