import Link from 'next/link'
import { Image } from 'react-datocms'
import { InsightRecord } from '../../generated/graphql'
import formatDate from '../../utils/format-date'
import { formatDateLocale } from '../../utils/format-date'
import Dynamic from '../../components/dynamic'

type Props = {
  insight: any | InsightRecord
  customTitle?: string
  customTag?: string
  customDate?: Date
  customDateLanguage?: string
}

function InsightGridItem({
  insight,
  customTitle,
  customDate,
  customTag,
  customDateLanguage,
}: Props) {
  return (
    <Link scroll={false} key={insight.id} href={`/insights/${insight.slug}`}>
      <div
        key={insight.id}
        className="group relative flex flex-row justify-between overflow-hidden hover:cursor-pointer"
      >
        <div
          className={
            'absolute top-[-1px] h-[2px] w-full bg-primary-rystad-blue-light-tint-2'
          }
        />

        <div
          className={
            'absolute top-[-1px] h-[2px] w-full -translate-x-full bg-primary-rystad-blue transition-transform duration-300 group-hover:translate-x-[0]'
          }
        />

        <div className="mt-5 flex flex-col gap-3">
          <h2 className="text-header-3 font-normal text-current line-clamp-3">
            {customTitle ? customTitle : insight.title}
          </h2>

          <div className="flex flex-row items-center justify-start gap-2 text-text-x-small">
            <div className="flex flex-row items-center gap-1">
              <div
                style={{
                  backgroundColor: insight.tag?.dotColor?.hex,
                }}
                className="h-1 w-1 shrink-0 rounded-full sm:block"
              ></div>
              <h2 className="text-current transition-all duration-300">
                {customTag ? customTag : insight?.tag?.tagLabel}
              </h2>
            </div>

            <h2 className="text-current md:text-current">
              <Dynamic>
                {customDate && customDateLanguage
                  ? formatDateLocale(customDate, customDateLanguage)
                  : formatDate(insight?.publicationDate, 'full')}
              </Dynamic>
            </h2>
          </div>
        </div>

        {insight && insight.image?.responsiveImage && (
          <div className="relative mt-5 ml-2 aspect-[0.7] w-1/3 shrink-0 sm:w-1/5">
            <Image
              data={insight.image.responsiveImage}
              layout="fill"
              objectFit="cover"
            />
          </div>
        )}
      </div>
    </Link>
  )
}

export default InsightGridItem
