import Link from 'next/link'
import { Solution } from '../../../types/service-menu-type'

type Props = {
  items: Solution[]
}

function MenuSolutionItems({ items }: Props) {
  return (
    <div className="flex flex-col gap-1">
      {items.map((item, index) => (
        <Link
          scroll={false}
          href={`/services/${item.slug}`}
          className="flex flex-col gap-1"
          key={index}
        >
          <a>
            <span className="text-text-small">{item.name}</span>
          </a>
        </Link>
      ))}
    </div>
  )
}

export default MenuSolutionItems
