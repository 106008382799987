import React from 'react'
import { SignUpFreeTrialRecord } from '../../generated/graphql'
import CtaLink from '../cta-link'
import Button from '../button'
import Check from '../../icons/check'

type Props = {
  block: SignUpFreeTrialRecord
  setFormCurrentStep: (step: string) => void
}

const FormLastStep = ({
  block,
  setFormCurrentStep,
}: Props) => {

  const handleSubmit = () => {
    setFormCurrentStep('firstStep')
  }

  return (
    <form className="flex flex lg:justify-between gap-[32px] lg:flex-row flex-col-reverse">
      <div className="flex flex-col">
        <h2 className="mb-2 text-header-2 text-[26px]">
          {block.completeHeadline}
        </h2>
        <p className="text-base text-[18px]">{block.completeDescription}</p>
        <div className="mt-5 flex w-fit flex-col gap-[24px] lg:flex-row lg:items-center">
          <Button
            className="w-fit"
            text={block.completeCta[0].ctaLabel ?? ''}
            onClick={() => handleSubmit()}
          />
          <CtaLink cta={block.completeCta[1]} />
        </div>
      </div>
      <button
        onClick={() => setFormCurrentStep('firstStep')}
        className="flex items-center h-fit gap-[5px]"
      >
        <Check color={'text-greys-4'} />
        <p className="text-base text-[11px] text-greys-4">Complete</p>
      </button>
    </form>
  )
}

export default FormLastStep
