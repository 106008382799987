import React from 'react'
import * as Select from '@radix-ui/react-select'
import Check from '../icons/check'

type TSelectItem = {
  children: React.ReactNode
  value: string
  className?: string
}

// eslint-disable-next-line react/display-name
export const SelectItem: React.FC<TSelectItem> = React.forwardRef(
  (
    { children, className, ...props },
    forwardedRef: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Select.Item
        className={`${className} text-base relative w-full p-1 text-[15px] text-[#b1b1c0] outline-none hover:text-[#292862]`}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute right-[20px] inline-flex items-center justify-center">
          <Check color={'fill-[#b1b1c0]'} />
        </Select.ItemIndicator>
      </Select.Item>
    )
  }
)
