import CtaLink from '../../../components/cta-link'
import DotWithLabel from '../../../components/dot-with-label'
import { KeyNewsPublicationRecord } from '../../../generated/graphql'
import { getPressReleaseArticleRecordFromBlocks } from '../utils/get-press-release-article-from-blocks'

type Props = {
  publication: KeyNewsPublicationRecord
}

function ArticleExcerpt({ publication }: Props) {
  const selectedPublication = publication?.publication

  const limitChars = (_string: string) => {
    let str = _string

    if (str.length > 350) {
      str = str.substring(0, 350) + '...'
    }

    return str
  }

  return (
    <div className="col-span-2 col-start-1 flex flex-col justify-center md:col-span-full md:col-start-1 md:my-[0] md:px-[1vw] lg:col-span-4 lg:col-start-5 lg:px-[6vw]">
      <div className="flex flex-col items-start justify-between gap-1 lg:min-h-[300px]">
        <div className="flex flex-col gap-2">
          <div className="flex-start flex flex-row gap-2">
            <DotWithLabel
              dotColor={selectedPublication?.tag?.dotColor?.hex}
              label={selectedPublication?.tag?.tagLabel}
            />
            <div className="text-text-x-small text-greys-3">
              {selectedPublication?.energyType?.tagName}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h1 className="text-header-2-5 font-semibold xl:text-header-2 xl:font-medium">
              {selectedPublication?.__typename === 'PressReleaseRecord' &&
                getPressReleaseArticleRecordFromBlocks(selectedPublication)
                  ?.headline}
              {selectedPublication?.__typename === 'InsightRecord' &&
                selectedPublication.title}
            </h1>
            <p className="text-text-base">
              {selectedPublication?.__typename === 'InsightRecord' &&
                limitChars(selectedPublication.excerpt || '')}
              {selectedPublication?.__typename === 'PressReleaseRecord' &&
                limitChars(selectedPublication.excrept || '')}
            </p>
          </div>
        </div>
        <div className="mt-6 xl:mt-3">
          {publication?.ctaLink?.map((ctaLink) => (
            <CtaLink key={ctaLink.id} cta={ctaLink} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ArticleExcerpt
