import { Image } from 'react-datocms'
import Section from '../../components/section'
import { LogoModuleRecord } from '../../generated/graphql'

type Props = {
  block: LogoModuleRecord
}

function LogoModuleRecord({ block }: Props) {
  return (
    <Section id={block.slug ?? ''}>
      <h4 className="col-span-2 col-start-1 mb-9 md:col-start-2">
        {block.label}
      </h4>
      <div className="col-span-full col-start-1 mb-11 grid grid-cols-3 items-center gap-6 md:col-span-6 md:col-start-2 md:gap-11 lg:grid-cols-5">
        {block.clientLogos
          .filter((image) => !!image.responsiveImage)
          .map((image) => (
            <Image
              className="col-span-1 m-auto"
              key={image.id}
              data={image.responsiveImage!}
            />
          ))}
      </div>
    </Section>
  )
}

export default LogoModuleRecord
