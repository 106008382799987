import Link from 'next/link'

type Props = {
  preview: boolean
}

function PreviewAlert({ preview }: Props) {
  if (!preview) return null

  return (
    <div className={'bg-primary-orange text-white fixed top-[20%] rounded-tl-xl rounded-tr-xl shadow-2xl right-[0] -rotate-90 origin-[100%_100%] z-10'}>
      <div className="text-slate-800 text-sm py-1 px-2 text-center">
        <Link scroll={false} href={'/api/exit-preview'}>
          <a className="underline transition-colors no-underline font-bold duration-200 hover:text-white">
            Exit preview
          </a>
        </Link>{' '}
      </div>
    </div>
  )
}

export default PreviewAlert
