import { ReactNode, useState } from 'react'
import AccordionItem from './accordion-item'

type AccordionItemType = {
  id: string
  label: string
  content: ReactNode
}

type Props = {
  accordionItems: AccordionItemType[]
  openIcon: ReactNode
  closedIcon: ReactNode
  hasCounter?: boolean
  variant?: Variant
}

type Variant = 'press-release' | 'insight' | 'default'
type AnimationColors = {
  default: string
  hover: string
}

const variants: Record<Variant, AnimationColors> = {
  'press-release': {
    default: 'top-[-1px] bg-greys-6',
    hover: 'top-[-1px] bg-greys-1',
  },
  insight: {
    default: 'top-[-1px] bg-primary-rystad-blue-light-tint-2',
    hover: 'top-[-1px] bg-primary-rystad-blue',
  },
  default: {
    default: 'bottom-[-1px] bg-greys-1',
    hover: 'bottom-[-1px] bg-primary-rystad-blue',
  },
}

function Accordion({
  accordionItems,
  openIcon,
  closedIcon,
  hasCounter,
  variant,
}: Props) {
  const [currIndex, setCurrIndex] = useState<number | null>(null)

  const toggleAccordion = (index: number) => {
    if (currIndex === index) {
      setCurrIndex(null)
    } else {
      setCurrIndex(index)
    }
  }

  return (
    <div>
      {accordionItems.map((acc, index) => (
        <div key={acc.id} className="group relative overflow-hidden ">
          <button
            className="relative flex w-full flex-row items-center justify-between py-3"
            onClick={() => {
              toggleAccordion(index)
            }}
          >
            <div className={'flex flex-row items-center gap-1'}>
              {hasCounter && (
                <div
                  className={'relative top-[1.5px] w-[3ch] text-text-x-small'}
                >
                  0{index + 1}
                </div>
              )}
              <h3 className={'text-left'}>{acc.label}</h3>
            </div>
            {currIndex == index ? closedIcon : openIcon}
          </button>
          <AccordionItem height={index === currIndex ? 'auto' : 0}>
            <div onClick={() => setCurrIndex(null)}>{acc.content}</div>
          </AccordionItem>
          <div>
            <div
              className={`absolute ${
                variants[variant ?? 'default'].default
              } h-[2px] w-full`}
            />
            <div
              className={`absolute ${
                variants[variant ?? 'default'].hover
              } h-[2px] w-full -translate-x-full  transition-transform duration-1000 group-hover:translate-x-[0]`}
            />
          </div>
        </div>
      ))}
    </div>
  )
}

export default Accordion
