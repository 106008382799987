import { twMerge } from 'tailwind-merge'

type Props = {
  link: string
  label: string
  overrideClasses?: string
}

function UnderlinedLink({ link, label, overrideClasses }: Props) {
  return (
    <a
      className={twMerge(
        'text-text-base underline decoration-primary-rystad-blue decoration-dashed decoration-1 underline-offset-2',
        overrideClasses
      )}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {label}
    </a>
  )
}

export default UnderlinedLink
