import _ from 'lodash'
import { useRouter } from 'next/router'
import Accordion from '../../components/accordion'
import DownArrow from '../../icons/down-arrow'
import NewsFilterItem from '../press-release-overview-news-grid/news-filter-item'
import { useContext } from 'react'
import EventContext from './event-context'

function EventsFilterContainer() {
  const { filterData } = useContext(EventContext)

  const eventRegions = filterData?.salesRegions ?? []
  const eventIndustries = filterData?.industries ?? []
  const eventThemes = filterData?.energyThemes ?? []

  const router = useRouter()
  const selectedIndustry = router.query.industry?.toString() ?? ''
  const selectedEnergyTheme = router.query['energy-theme']?.toString() ?? ''
  const selectedSalesRegion = router.query['sales-region']?.toString() ?? ''

  function clearFilters() {
    const { type } = router.query
    const query: any = {}
    if (type) {
      query['type'] = type
    }

    router.replace(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true, scroll: false }
    )
  }

  function handleSelectFilterItem(type: string, value: string) {
    router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          [type]: router.query[type] === value ? '' : value,
        },
      },
      undefined,
      { shallow: true, scroll: false }
    )
  }

  const accordionItems = [
    {
      id: '0',
      label: 'Filter by region',
      content: (
        <NewsFilterItem
          selected={selectedSalesRegion}
          type="sales-region"
          selectItem={handleSelectFilterItem}
          items={eventRegions}
        />
      ),
    },
    {
      id: '1',
      label: 'Filter by industries',
      content: (
        <NewsFilterItem
          selected={selectedIndustry}
          type="industry"
          selectItem={handleSelectFilterItem}
          items={eventIndustries}
        />
      ),
    },
    {
      id: '2',
      label: 'Filter by energy themes',
      content: (
        <NewsFilterItem
          selected={selectedEnergyTheme}
          type="energy-theme"
          selectItem={handleSelectFilterItem}
          items={eventThemes}
        />
      ),
    },
  ]
  return (
    <>
      <div
        className={
          'bottom-1 z-10 col-span-6 row-start-1 mt-6 block justify-between bg-white bg-opacity-90 text-text-base text-current sm:col-span-2 md:sticky md:row-auto md:block'
        }
      >
        <div className="text-text-small text-current md:bottom-[0] md:mt-auto">
          {selectedEnergyTheme || selectedIndustry || selectedSalesRegion ? (
            <button onClick={clearFilters} className="cursor-pointer pb-4">
              Clear filters
            </button>
          ) : null}
          <Accordion
            accordionItems={accordionItems}
            openIcon={<DownArrow />}
            closedIcon={<DownArrow />}
            variant="insight"
          />
        </div>
      </div>
    </>
  )
}

export default EventsFilterContainer
