import { SolutionPageImageVideoHeaderRecord } from '../../generated/graphql'
import ArrowRightButton from '../../components/arrow-right-button'
import { Image } from 'react-datocms'
import AutoplayVideo from '../../components/autoplay-video'

type Props = {
  block: SolutionPageImageVideoHeaderRecord
}

function SolutionPageImageVideoHeader({ block }: Props) {
  const cta = block.cta?.[0]

  const isVideo = block.backgroundVideo
  const isImage =
    block.backgroundImage?.responsiveImage && !block.backgroundVideo

  return (
    <div className={'relative'} id={block.slug ?? ''}>
      <div
        className={
          'absolute left-1/2 z-10 mx-auto h-full w-full max-w-fullbleed -translate-x-1/2 transform'
        }
      >
        <div
          className={
            'grid h-full grid-cols-2 gap-2 p-2 md:grid-cols-8 md:gap-5 md:p-5'
          }
        >
          <div
            className={
              'col-span-4 flex h-full flex-col justify-center text-white'
            }
          >
            <h1 className={'mb-2 text-display-2'}>{block.solutionTitle}</h1>
            <p className={'text-text-large line-clamp-4'}>
              {block.description}
            </p>
            {cta && (
              <div className={'mt-4 md:mt-6'}>
                <ArrowRightButton link={cta.link!} variant={'white'}>
                  {cta.ctaLabel}
                </ArrowRightButton>
              </div>
            )}
          </div>
        </div>
      </div>
      {isVideo && (
        <div className="h-[85vh] w-auto min-w-full object-cover">
          <AutoplayVideo
            videoSrc={block.backgroundVideo!}
            fallBackImage={block.backgroundImage?.responsiveImage!}
          />
        </div>
      )}
      {isImage && (
        <div className="h-[85vh] w-auto min-w-full object-cover">
          <Image
            objectFit={'cover'}
            style={{ maxWidth: 'none', height: '100%' }}
            data={block.backgroundImage?.responsiveImage!}
          />
        </div>
      )}
    </div>
  )
}

export default SolutionPageImageVideoHeader
