import React from 'react'

export type Action =
  | { type: 'updateMenuItemIndex'; index: number }
  | { type: 'updateFirstBlockIndex'; index: number }
  | { type: 'updateSecondBlockIndex'; index: number }
  | { type: 'reset' }
  | { type: 'updateMenuItemSelected'; selected: boolean }
type Dispatch = (action: Action) => void
type State = {
  menuItem: number
  firstBlock: number
  secondBlock: number
  menuSelected: boolean
}
type Props = {
  children: React.ReactNode
}

const CountStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

function updateMenuStatus(state: State, action: Action) {
  switch (action.type) {
    case 'reset': {
      return {
        firstBlock: -1,
        secondBlock: -1,
        menuItem: 0,
        menuSelected: false,
      }
    }
    case 'updateMenuItemSelected': {
      return { ...state, menuSelected: action.selected }
    }
    case 'updateMenuItemIndex': {
      return { ...state, menuItem: action.index }
    }
    case 'updateFirstBlockIndex': {
      return { ...state, firstBlock: action.index }
    }
    case 'updateSecondBlockIndex': {
      return { ...state, secondBlock: action.index }
    }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

function MenuStatusProvider({ children }: Props) {
  const [state, dispatch] = React.useReducer(updateMenuStatus, {
    menuSelected: false,
    menuItem: 0,
    firstBlock: -1,
    secondBlock: -1,
  })
  const value = { state, dispatch }
  return (
    <CountStateContext.Provider value={value}>
      {children}
    </CountStateContext.Provider>
  )
}

function useMenuStatus() {
  const context = React.useContext(CountStateContext)
  if (context === undefined) {
    throw new Error('useMenuStatus must be used within a MenuStatusProvider')
  }
  return context
}

export { MenuStatusProvider, useMenuStatus }
