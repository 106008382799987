import React from 'react'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import ImageSliderAnimation from './image-slider-animation'
import { useRef } from 'react'
import { SlidingPicturesWithHeadlineRecord } from '../../generated/graphql'
import CtaLink from '../../components/cta-link'

type Props = {
  block: SlidingPicturesWithHeadlineRecord
}

const SlidingPicturesWithHeadline = ({ block }: Props) => {
  const labelRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const imagesRef = useRef<(HTMLImageElement | null)[]>([])

  useIsomorphicLayoutEffect(() => {
    ImageSliderAnimation(labelRef, titleRef, imagesRef)
  })

  return (
    <div
      className="bg-primary-orange pt-2 pb-6 md:pt-8 md:pb-8"
      style={{
        backgroundColor: block.backgroundColorHex?.hex ?? '#ffa768',
        color: block.textColorHex?.hex ?? 'white',
      }}
    >
      <div className="pl-2 md:pl-8">
        {block.label && (
          <div className="pb-5" ref={labelRef}>
            {block.label}
          </div>
        )}
        <div
          className="text-display-1"
          ref={titleRef}
          style={{
            paddingBottom: block.cta.length > 0 ? '24px' : '80px',
          }}
        >
          {block.title}
        </div>
        {block.cta.length > 0 && (
          <div className="w-fit pb-5">
            <CtaLink cta={block.cta[0]} />
          </div>
        )}
      </div>

      <div className="flex overflow-hidden">
        {block?.images?.map((image, i) => (
          <img
            ref={(el) => (imagesRef.current[i] = el)}
            key={image.url}
            className="h-[16em] max-w-none px-1 md:max-w-full lg:h-[22em]"
            src={image.url}
          />
        ))}
      </div>
    </div>
  )
}

export default SlidingPicturesWithHeadline
