import Link from 'next/link'
import CtaLink from '../../components/cta-link'
import Section from '../../components/section'
import {
  OtherSpotlightsModuleRecord,
  SpotlightArticleMainModuleRecord,
  SpotlightArticlePageModelEmployeeField,
} from '../../generated/graphql'
import formatDate from '../../utils/format-date'
import Dynamic from '../../components/dynamic'

type Props = {
  block: OtherSpotlightsModuleRecord
}

export function isSpotlightArticleMainModuleRecord(
  record: SpotlightArticlePageModelEmployeeField
): record is SpotlightArticleMainModuleRecord {
  return record?.__typename === 'SpotlightArticleMainModuleRecord'
}

function OtherSpotlightsModuleRecord({ block }: Props) {
  const articles = block.otherSpotlightArticles
    .map((article) => article.employee.filter((employee) => employee.id)[0])
    .filter(isSpotlightArticleMainModuleRecord)

  return (
    <>
      <Section>
        <div className="col-span-2 mt-2 mb-5 overflow-hidden sm:col-span-8 sm:-mb-1">
          <h2 className="py-1 text-header-2">
            Other spotlights
            <sup className="font-light">{articles.length}</sup>
          </h2>

          <div className="no-scrollbar mt-3 flex snap-x snap-mandatory flex-col gap-6 overflow-x-scroll sm:mt-4 sm:flex-row">
            {articles.map((article) => (
              <Link
                scroll={false}
                key={article.id}
                href={`/spotlights/${article.slug}`}
              >
                <div
                  key={article.id}
                  className=" group relative flex shrink-0 cursor-pointer snap-start flex-row overflow-hidden sm:w-[80vw] sm:gap-2 lg:w-[30vw]"
                >
                  <div>
                    <div
                      className={
                        'absolute top-[-1px] h-[2px] w-full bg-greys-1'
                      }
                    />

                    <div
                      className={
                        'absolute top-[-1px] h-[2px] w-full -translate-x-full bg-primary-rystad-blue transition-transform duration-500 group-hover:translate-x-[0]'
                      }
                    />
                  </div>

                  <div className="mt-5 flex flex-col justify-between gap-4">
                    <h2 className="text-header-3">{article.headline}</h2>

                    <div className="flex flex-row-reverse items-center justify-end gap-2 sm:flex-row sm:justify-start">
                      <div className="hidden h-1 w-1 rounded-full bg-primary-orange sm:block"></div>
                      <h2 className="text-text-small text-greys-2">
                        Talent Spotlight
                      </h2>
                      <h2 className="text-text-small">
                        <Dynamic>
                          {formatDate(article._publishedAt, 'full')}
                        </Dynamic>
                      </h2>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </Section>
      <Section>
        <div className="col-span-5 mb-5 -mt-3 border-t-2 border-primary-rystad-blue pt-3 sm:mb-auto sm:mt-auto">
          {block?.ctaLink?.map((cta) => (
            <div key={cta.id} className="flex flex-col gap-3 md:flex-row">
              <CtaLink cta={cta} key={cta.id} />
            </div>
          ))}
        </div>
      </Section>
    </>
  )
}

export default OtherSpotlightsModuleRecord
