import React, { useState, useMemo } from 'react'
import { SignUpFreeTrialRecord } from '../../generated/graphql'
import FormFirstStep from '../../components/form/form-first-step'
import FormSecondStep from '../../components/form/form-second-step'
import FormLastStep from '../../components/form/form-last-step'

type Props = {
  block: SignUpFreeTrialRecord
}

function SignUpFreeTrialModule({ block }: Props) {
  const initialFormInfo = {
    solution: [],
    title: '',
    firstName: '',
    lastName: '',
    businessEmail: '',
    division: null,
    role: null,
    country: null,
    city: null,
    phone: '',
    privacyPolicy: false,
  }
  const [formInfo, setFormInfo] = useState(initialFormInfo)
  const [formCurrentStep, setFormCurrentStep] = useState('firstStep')

  const formStepsMap: { [key: string]: JSX.Element } = {
    firstStep: (
      <FormFirstStep
        block={block}
        setFormInfo={setFormInfo}
        formInfo={formInfo}
        setFormCurrentStep={setFormCurrentStep}
      />
    ),
    secondStep: (
      <FormSecondStep
        block={block}
        setFormInfo={setFormInfo}
        formInfo={formInfo}
        setFormCurrentStep={setFormCurrentStep}
        initialFormInfo={initialFormInfo}
      />
    ),
    lastStep: (
      <FormLastStep block={block} setFormCurrentStep={setFormCurrentStep} />
    ),
  }

  return (
    <div
      className="flex flex-col gap-[50px] p-2 text-[#292862] md:flex-row md:p-5 xl:gap-[156px]"
      id="free-trial-form"
    >
      <div className="text-primary max-w-[420px]">
        <h2 className="mb-4 text-header-2 text-[26px]">{block.headline}</h2>
        <p className="text-base text-[18px]">{block.headlineDescription}</p>
      </div>
      <div className="flex-1">
        <div className="flex w-full justify-end">
          {formCurrentStep !== 'lastStep' && (
            <p className="text-base align-end mb-[32px] text-[11px] text-greys-4">{`Information (${
              formCurrentStep === 'firstStep' ? 1 : 2
            }/2)`}</p>
          )}
        </div>
        {formStepsMap[formCurrentStep]}
      </div>
    </div>
  )
}

export default SignUpFreeTrialModule
