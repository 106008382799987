import debounce from 'lodash.debounce'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import useDebouncedValue from '../../hooks/use-debounced-value'
import useURLSearch from '../../hooks/use-URL-search'
import SearchIcon from '../../icons/search-icon'

const InsightSearch = () => {
  const [searchInput, setSearchInput] = useState('')
  const router = useRouter()

  const debouncedEventHandler = useCallback(
    debounce((query) => {
      if (!router.isReady) return
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            s: query,
          },
        },
        undefined,
        { shallow: true, scroll: false }
      )
    }, 800),
    [router.query]
  )

  useEffect(() => {
    debouncedEventHandler(searchInput)
  }, [searchInput])

  return (
    <div className="flex cursor-pointer flex-row items-center justify-between py-2">
      <input
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        autoFocus
        className={'bg-transparent placeholder-primary-rystad-blue outline-0'}
        type="text"
        placeholder="Search for insights"
      />
      <SearchIcon />
    </div>
  )
}

export default InsightSearch
