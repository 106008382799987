import monthlyNewsLetter from '../../public/html/monthly-newsletter'

function SugarMarketNewsletterForm() {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: monthlyNewsLetter }} />
    </>
  )
}

export default SugarMarketNewsletterForm
