import CtaLink from '../../components/cta-link'
import MultilineParagraph from '../../components/multiline-paragraph'
import Section from '../../components/section'
import type { RichTextWidgetRecord as RichTextWidgetRecordType } from '../../generated/graphql'

type Props = {
  block: RichTextWidgetRecordType
}

function RichTextWidgetRecord({ block }: Props) {
  return (
    <Section
      backgroundColor={block.backgroundColorHex}
      classOverride={'py-9 md:py-11'}
      classOverrideInnerGrid={'py-[0] md:py-[0]'}
      id={block?.slug || ''}
    >
      <h3
        className="col-span-full col-start-1 text-left text-header-2 lg:col-span-2 lg:inline-block"
        style={{ scrollMarginTop: 120 }}
        id={block.slug || ''}
      >
        {block.headline}
      </h3>
      <div
        className={`
        col-span-full flex flex-col items-start 
        lg:col-span-full lg:col-start-4`}
      >
        <MultilineParagraph content={block.description!} />
      </div>
      {block.ctaButton.length > 0 && (
        <div className="col-span-full col-start-1 mt-6 flex flex-col gap-2 lg:col-start-4 lg:mt-4">
          {block.ctaButton.map((cta) => (
            <div key={cta.id} className="flex flex-row">
              <CtaLink cta={cta} />
            </div>
          ))}
        </div>
      )}
    </Section>
  )
}

export default RichTextWidgetRecord
