type Props = {
  size?: number
}

function MenuRightArrow({ size }: Props) {
  return (
    <svg
      width={size ?? 7}
      height={size ?? 10}
      viewBox="0 0 7 10"
      fill="none"
    >
      <path
        d="M0.757289 9.24264L4.99993 5L0.757289 0.757359"
        stroke="currentcolor"
      />
    </svg>
  )
}

export default MenuRightArrow
