import Section from '../../../components/section'
import { IndustryGridRecord } from '../../../generated/graphql'
import { Fragment } from 'react'
import DesktopCard from '../desktop-card'
import MobileCard from '../mobile-card'

type Props = {
  block: IndustryGridRecord
}

function IndustryGridRecord({ block }: Props) {
  const industries = block.industries ?? []

  return (
    <Section>
      <div className="col-span-2 grid grid-cols-1 gap-2 py-[104px] sm:col-span-8 sm:grid-cols-2 sm:gap-4 md:py-8 lg:grid-cols-4">
        {industries.map((industry) => (
          <Fragment key={industry.id}>
            <DesktopCard item={industry} />
            <MobileCard item={industry} />
          </Fragment>
        ))}
      </div>
    </Section>
  )
}

export default IndustryGridRecord
