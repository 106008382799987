const preventMobileScroll = (e: any) => {
  e.preventDefault()
}

function updateBodyScroll(enable: boolean) {
  if (enable) {
    document.body.style.overflow = 'visible'
  } else {
    document.body.style.overflow = 'hidden'
  }
}

export default updateBodyScroll
