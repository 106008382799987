import {
  FrontPageKeyNewsElementRecord,
  KeyNewsPublicationModelPublicationField,
  Maybe,
} from '../../../generated/graphql'
import useMediaQuery from '../../../hooks/use-media-query'
import { useDisplayedPublication } from '../context/use-displayed-publication'
import { getPressReleaseArticleRecordFromBlocks } from '../utils/get-press-release-article-from-blocks'
import ArticleExcerpt from './article-excerpt'
import ArticleThumbnail from './article-thumbnail'
import MobileDividerAnimation from './mobile-divider-animation'

type Props = {
  block: FrontPageKeyNewsElementRecord| any
}

function UpperDisplayedArticle({ block }: Props) {
  const { state } = useDisplayedPublication()
  const publication = block?.publications?.[state?.index] || {}
  const isSmallScreen = useMediaQuery('(max-width: 768px)')

  function getResponsiveImage(
    publication?: Maybe<KeyNewsPublicationModelPublicationField>
  ) {
    if (publication?.__typename === 'InsightRecord') {
      return publication.image?.responsiveImage
    } else if (publication?.__typename === 'PressReleaseRecord') {
      return getPressReleaseArticleRecordFromBlocks(publication)?.image
        ?.responsiveImage
    }
  }

  return (
    <>
      <ArticleThumbnail
        videoLink={publication?.publication?.externalVideoLink}
        image={getResponsiveImage(publication?.publication)}
      />
      {isSmallScreen && block?.publications?.length > 1 && (
        <MobileDividerAnimation maxLength={block?.publications?.length - 1} />
      )}
      <ArticleExcerpt publication={publication} />
    </>
  )
}

export default UpperDisplayedArticle
