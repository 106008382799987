import { PressReleaseRecord } from '../generated/graphql'
import { format } from 'date-fns'
import _ from 'lodash'

function mapPressreleasesToMonths(data?: {
  allPressReleases: PressReleaseRecord[]
}): Record<string, PressReleaseRecord[]> {
  if (!data) return {}
  const _data: Record<string, PressReleaseRecord[]> = {}

  const pressReleasesWithModifiedMonths = data?.allPressReleases.map(
    (pressRelease) => {
      const trimmedStartDate = pressRelease?.publicationDate.slice(0, 7)
      return {
        ...pressRelease,
        trimmedStartDate,
      }
    }
  )

  const pressReleasesSlicedByMonths = _.chain(pressReleasesWithModifiedMonths)
    .groupBy('trimmedStartDate')
    .map((key: any, value: any) => ({
      date: value,
      items: key,
    }))
    .value()

  pressReleasesSlicedByMonths.forEach((pressRelease) => {
    const month = format(new Date(pressRelease.date), 'MMMM YYY')
    _data[month] = pressRelease.items
  })

  return _data
}

export default mapPressreleasesToMonths
