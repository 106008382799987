import Section from '../components/section'
import { PageHeaderRecord } from '../generated/graphql'

type Props = {
  block: PageHeaderRecord
}

function PageHeader({ block }: Props) {
  return (
    <Section>
      <h2
        className="z-10 col-span-2 mt-[60%] text-text-small md:mt-10"
        style={{
          color: block.textColorHex?.hex,
        }}
      >
        {block.label}
      </h2>

      <h1
        className="z-10 col-span-4 mb-[70%] mt-1 max-w-xl overflow-hidden text-display-2 md:mt-10 md:mb-11"
        style={{
          color: block.textColorHex?.hex,
        }}
      >
        {block.headline}
      </h1>
    </Section>
  )
}

export default PageHeader
