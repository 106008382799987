type Props = {
  color?: string
  size?: number | string
}

function ArrowRight({ color, size }: Props) {
  return (
    <svg
      className={color}
      width={size ?? 15}
      height={size ?? 15}
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M15.5 7.5L7.73837 0L6.25581 1.42724L11.5756 6.43657L0.5 6.43657L0.5 8.56343L11.5756 8.56343L6.25581 13.5728L7.73837 15L15.5 7.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ArrowRight
