import { gql } from '@apollo/client'

const ALL_SOLUTION = gql`
  query QueryAllSolutions {
    allSolutionPages(first: "100") {
      id
      slug
      uuid
      title
      excerpt
      position
      featuredSolution
      energyTypes {
        __typename
        name
        slug
      }
      industries {
        __typename
        name
        slug
      }
      energyTheme {
        __typename
        tagLabel
      }
    }
  }
`

export default ALL_SOLUTION
