import { useRef, useState } from 'react'
import { Image } from 'react-datocms'
import LinkIcon from '../../icons/link-icon'
import toggleVisibility from '../../utils/toggle-visibility'
import { PressReleaseSocialMediaButtonRecord } from '../../generated/graphql'

type Variant = 'primary' | 'white'

const variants: Record<Variant, string> = {
  primary:
    'border border-primary-rystad-blue bg-white text-primary-rystad-blue',
  white: 'border border-white',
}

type Props = {
  text: String
  variant: Variant
  icons: Array<PressReleaseSocialMediaButtonRecord>
  mobileColumn: boolean
}

const SHARE_LINKS = {
  Facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  Twitter: 'https://twitter.com/intent/tweet?url=',
  LinkedIn: 'https://www.linkedin.com/sharing/share-offsite/?url=',
}

function ShareButton({ text, variant, icons, mobileColumn }: Props) {
  const iconsRef = useRef<HTMLDivElement>(null)
  const triggerRef = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const variantClasses = variants[variant ?? 'primary']
  const textColor =
    variant === 'white' ? 'text-greys-0' : 'text-primary-rystad-blue'

  const toggleClick = () => {
    toggleVisibility(
      isOpen ? triggerRef : iconsRef,
      isOpen ? iconsRef : triggerRef,
      () => setIsOpen(!isOpen)
    )
  }

  const copyUrl = () => {
    navigator.clipboard.writeText(window.location.href)
    setIsCopied(true)

    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  const openSharePopup = (url: string) => {
    const urlWithLocation = url + window.location.href
    window.open(
      urlWithLocation,
      'popup',
      'width=600,height=600,scrollbars=no,resizable=no'
    )
    return false
  }

  return (
    <div>
      <div
        ref={triggerRef}
        className={`right-[0] transition-opacity duration-500 ease-out`}
      >
        <button
          className={`${
            variant === 'white' ? 'text-white' : ''
          } ${variantClasses} rounded-full px-4 py-1`}
          onClick={toggleClick}
        >
          <h2>{text}</h2>
        </button>
      </div>

      <div
        ref={iconsRef}
        className={`flex hidden ${
          mobileColumn ? 'flex-col' : 'flex-row'
        } gap-1 sm:flex-row`}
      >
        <button
          className={`${
            variant === 'white' ? 'bg-white' : ''
          } ${variantClasses} rounded-full px-2 py-1`}
          onClick={toggleClick}
        >
          X
        </button>
        {icons.map((icon) => {
          type ShareLinksKey = keyof typeof SHARE_LINKS
          const backgroundColorParam = `${icon?.name}` as ShareLinksKey

          return (
            <button
              key={icon.id}
              onClick={() =>
                openSharePopup(SHARE_LINKS[backgroundColorParam])
              }
            >
              {icon.icon?.responsiveImage && (
                <Image data={icon.icon.responsiveImage} />
              )}
            </button>
          )
        })}
        <button className={'relative'} onClick={copyUrl}>
          <LinkIcon />
          {isCopied && (
            <h2
              className={`${textColor} flex-start absolute right-[0] mt-1 mr-3 flex justify-center whitespace-nowrap text-center sm:justify-end`}
            >
              Link copied!
            </h2>
          )}
        </button>
      </div>
    </div>
  )
}

export default ShareButton
