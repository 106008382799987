import { TopModuleWithIntroDescriptionAndVideoImageModuleRecord } from '../../generated/graphql'
import OverVideoIntroDescriptionRecord from '../intro-description-record/over-video-intro-description-record'
import PeopleVideoModule from '../people-video-module'
import TopModuleWithIntroDescriptionAndVideoImageContainer from './top-module-with-intro-description-and-video-image-container'

type Props = {
  block: TopModuleWithIntroDescriptionAndVideoImageModuleRecord
}
function TopModuleWithIntroDescriptionAndVideoImageModuleRecord({
  block,
}: Props) {
  return (
    <div className="relative flex flex-col justify-center md:static">
      {block.introDescriptionBlockAndVideoImageModule.map((record) => (
        <TopModuleWithIntroDescriptionAndVideoImageContainer
          block={record}
          key={record.id}
        />
      ))}
    </div>
  )
}

export default TopModuleWithIntroDescriptionAndVideoImageModuleRecord
