import MenuRightArrow from '../../../icons/menu-right-arrow'

type Props = {
  text: string
  onMouseEnter: () => void
}

function MenuSelectListItem({ text, onMouseEnter }: Props) {
  return (
    <li className="flex flex-row items-center gap-2 text-text-base">
      <span onFocus={onMouseEnter} onMouseEnter={onMouseEnter}>
        {text}
      </span>
      <div className={'w-[27px] shrink-0'}>
        <MenuRightArrow />
      </div>
    </li>
  )
}

export default MenuSelectListItem
