import { Image } from 'react-datocms'
import Section from '../../../components/section'
import VideoComponent from '../../../components/video-component'
import { PeopleCareerVideoImageModuleRecord } from '../../../generated/graphql'

type Props = {
  block: PeopleCareerVideoImageModuleRecord
}

function DesktopPeopleVideoModule({ block }: Props) {
  return (
    <Section id={block?.slug || ''}>
      <div className="col-span-full flex flex-col">
        {block.externalVideoLink ? (
          <VideoComponent
            src={block.externalVideoLink!}
            poster={block.image?.responsiveImage!}
          />
        ) : (
          <Image data={block.image?.responsiveImage!} />
        )}
      </div>
    </Section>
  )
}

export default DesktopPeopleVideoModule
