import MultilineParagraph from '../../components/multiline-paragraph'

type Props = {
  asiaPacific: string
  americas: string
  europe: string
}

function ContactBlock({ asiaPacific, americas, europe }: Props) {
  return (
    <div className="col-span-full col-start-1 flex flex-col items-start gap-4 md:col-span-3 md:col-start-5 md:flex-row md:flex-wrap md:gap-y-5">
      {asiaPacific && (
        <MultilineParagraph
          content={asiaPacific}
          classOverride={'text-text-base'}
        />
      )}
      {europe && (
        <MultilineParagraph content={europe} classOverride={'text-text-base'} />
      )}
      {americas && (
        <MultilineParagraph
          content={americas}
          classOverride={'text-text-base'}
        />
      )}
    </div>
  )
}

export default ContactBlock
