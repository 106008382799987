import { PageNotFoundRecord } from '../generated/graphql'

type Props = {
  block: PageNotFoundRecord
}

function PageNotFound({ block }: Props) {
  return (
    <div>
      <div
        className={
          'flex h-screen items-center justify-center bg-primary-rystad-blue'
        }
      >
        <h1 className={'text-white'}>{block.headline}</h1>
      </div>
    </div>
  )
}

export default PageNotFound
