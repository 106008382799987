type Props = {
  size?: number
}

function Minus({ size }: Props) {
  return (
    <svg
      width={size ?? '32'}
      height={size ?? '32'}
      viewBox="0 0 18 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="23"
        y="9"
        width="1"
        height="11"
        transform="rotate(90 23 9)"
        fill="#292862"
      />
    </svg>
  )
}
export default Minus
