import {
  MouseEvent,
  ReactNode,
  RefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react'
import { gsap } from '../lib/gsap'
import useMediaQuery from '../hooks/use-media-query'

const PLAY_BUTTON_WIDTH = 104
const PLAY_BUTTON_HEIGHT = 104
const IGNORED_CLASS = 'disable-special-hover'

type Props = {
  ref?: RefObject<HTMLDivElement>
  classes?: string
  isCursorHidden: boolean
  cursorLabel: string
  children: ReactNode
  onAreaClick?: () => void
  hideOnBottom?: boolean
}

type PlayButtonState = {
  x: null | number
  y: null | number
  hidden: boolean
}

function AreaWithCursor({
  cursorLabel,
  children,
  onAreaClick,
  classes,
  hideOnBottom = false,
}: Props) {
  const containerRef = createRef<HTMLDivElement>()

  const isTouchScreen = useMediaQuery('(pointer:coarse)')
  const cursor = useRef<HTMLButtonElement>(null)
  const [buttonState, setButtonState] = useState<PlayButtonState>({
    x: 0,
    y: 0,
    hidden: true,
  })

  function onMouseLeave() {
    setButtonState((prev) => ({
      ...prev,
      hidden: true,
    }))
  }

  function onMouseEnter(e: MouseEvent) {
    setButtonState((prev) => ({
      ...prev,
      hidden: false,
    }))
  }

  function onMouseMove(e: MouseEvent<HTMLImageElement>) {
    const target = e.target as Element

    if (target.classList.contains(IGNORED_CLASS)) {
      setButtonState((prev) => ({
        ...prev,
        hidden: true,
      }))

      return
    }

    if (hideOnBottom) {
      const container = containerRef?.current
      if (!container) return

      const containerRect = container.getBoundingClientRect()
      const relativeY = e.clientY - containerRect.top

      // Check if the cursor is in the bottom 40px of the container
      if (containerRect.height - relativeY <= 40) {
        setButtonState((prev) => ({
          ...prev,
          hidden: true,
        }))
        return
      }
    }

    const scrollTop =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop

    const y = e.pageY - PLAY_BUTTON_HEIGHT / 2 - scrollTop

    setButtonState((prev) => ({
      ...prev,
      hidden: false,
      x: e.pageX - PLAY_BUTTON_WIDTH / 2,
      y,
    }))
  }

  useEffect(() => {
    if (!cursor.current) return
    gsap.to(cursor.current, {
      scale: buttonState.hidden ? 0 : 1,
      rotate: buttonState.hidden ? 15 : 0,
      duration: 0.4,
    })
  }, [buttonState.hidden])

  if (isTouchScreen)
    return (
      <div className={classes} onClick={onAreaClick}>
        {children}
      </div>
    )

  return (
    <div
      onClick={onAreaClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      onMouseMove={onMouseMove}
      ref={containerRef}
      className={classes}
    >
      {!!buttonState.x && !!buttonState.y && (
        <button
          ref={cursor}
          className={`pointer-events-none fixed z-40 h-[104px] w-[104px] cursor-none rounded-full bg-primary-orange text-[14px] text-white`}
          style={{
            top: `${buttonState.y - 20}px`,
            left: `${buttonState.x}px`,
          }}
        >
          {cursorLabel}
        </button>
      )}
      {children}
    </div>
  )
}

export default AreaWithCursor
