import { useRef, useState } from 'react'
import AreaWithCursor from '../../components/area-with-cursor'
import ArrowRightButton from '../../components/arrow-right-button'
import { LinksListWithHoverEffectRecord } from '../../generated/graphql'

type Props = {
  block: LinksListWithHoverEffectRecord
}

const ListWithHoverEffect = ({ block }: Props) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const refs = useRef<(HTMLDivElement | null)[]>([])

  const handleMouseEnter = (refIndex: number) => {
    setHoveredIndex(refIndex + 1)
  }

  const handleMouseLeave = (refIndex: number) => {
    setHoveredIndex(null)
  }

  return (
    <div className="bg-lights-sand text-header-4">
      <div className="grid grid-cols-8 py-[10em]">
        <div className="col-span-8 md:col-span-4 md:col-start-3">
          {block.links.map((link, i) => (
            <div
              key={link.text}
              ref={(e) => (refs.current[i] = e)}
              className={`px-2 md:px-0  ${
                hoveredIndex ? (hoveredIndex != i + 1 ? 'opacity-50' : '') : ''
              }`}
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={() => handleMouseLeave(i)}
            >
              <div className="w-full border-t border-t-greys-3 ">
                <ArrowRightButton link={link.href || ''}>
                  <div className="py-3">{link.text}</div>
                </ArrowRightButton>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ListWithHoverEffect
