import useSWR from 'swr'
import searchSite from '../lib/search-site'
import SearchResult from '../types/SearchResult'

function useSiteSearch(query?: string) {
  const { data, error } = useSWR<SearchResult[]>(
    query ? query : null,
    searchSite
  )

  return {
    isError: !!error,
    data,
    isLoading: !error && !data,
  }
}

export default useSiteSearch
