const shareButton = `
shareButtonLink {
  __typename
  text
  socialMediaButtons {
    id
    link
    name
    icon {
      responsiveImage(imgixParams: {fm: png, h: 40, ar: "40:40", fit: crop}) {
        ...responsiveImageFragment
      }
    }
  }
}
`

export default shareButton
