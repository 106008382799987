import {
  PressReleaseArticleRecord,
  PressReleaseModelBlocksField,
  PressReleaseRecord,
} from '../../generated/graphql'
import formatDate from '../../utils/format-date'
import { Image } from 'react-datocms'
import Link from 'next/link'
import Dynamic from '../../components/dynamic'

type Props = {
  news: PressReleaseRecord
}

export function isPressReleaseArticleRecord(
  record: PressReleaseModelBlocksField
): record is PressReleaseArticleRecord {
  return record?.__typename === 'PressReleaseArticleRecord'
}

function PressReleaseThumbnail({ news }: Props) {
  const article = news.blocks.find((block) =>
    isPressReleaseArticleRecord(block)
  )

  return (
    <Link scroll={false} key={news.id} href={`/news/${news.slug}`}>
      <div
        key={news.id}
        className="group relative flex flex-row justify-between overflow-hidden hover:cursor-pointer"
      >
        <div className={'absolute top-[-1px] h-[2px] w-full bg-greys-6'} />

        <div
          className={
            'absolute top-[-1px] h-[2px] w-full -translate-x-full bg-greys-0 transition-transform duration-300 group-hover:translate-x-[0]'
          }
        />

        <div className="mt-5 flex flex-col gap-3">
          <h2 className="text-header-3 font-normal text-greys-0 line-clamp-3">
            {article &&
              isPressReleaseArticleRecord(article) &&
              article.headline}
          </h2>

          <div className="flex flex-row items-center justify-start gap-2 text-text-x-small">
            <div className="flex flex-row items-center gap-1">
              <div
                style={{
                  backgroundColor: news.energyType?.tagDotColor?.hex,
                }}
                className="h-1 w-1 shrink-0 rounded-full sm:block"
              ></div>
              <h2 className="text-greys-5 transition-all duration-300">
                {news?.energyType?.tagName || news?.energyType?.name}
              </h2>
            </div>

            <h2 className="text-greys-5 md:text-greys-0">
              <Dynamic>{formatDate(news?.publicationDate, 'full')}</Dynamic>
            </h2>
          </div>
        </div>

        {article &&
          isPressReleaseArticleRecord(article) &&
          article?.image?.responsiveImage && (
            <div
              className="mt-5 ml-2 w-1/3 shrink-0 sm:w-1/5"
              key={`image_${news.id}`}
            >
              <Image data={article.image.responsiveImage} />
            </div>
          )}
      </div>
    </Link>
  )
}

export default PressReleaseThumbnail
