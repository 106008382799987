import MenuRightArrow from '../../../icons/menu-right-arrow'
import { useMenuStatus } from '../context'
import { MenuItemType } from '../../../types/service-menu-type'
import Link from 'next/link'

type Props = {
  items: MenuItemType[]
  selectColor?: string
}

function MenuItemsBlock({ items, selectColor }: Props) {
  const { dispatch, state } = useMenuStatus()

  function handleMenuItemHover(index: number) {
    dispatch({ type: 'reset' })
    dispatch({ type: 'updateMenuItemIndex', index })
  }

  return (
    <div className="col-span-2 col-start-1 flex flex-col">
      {items.map((item, index) => (
        <div
          className={`flex flex-row items-center justify-between gap-2 p-3`}
          style={{
            backgroundColor:
              index === state.menuItem ? selectColor : 'transparent',
          }}
          onMouseEnter={() => handleMenuItemHover(index)}
          key={index}
        >
          <Link scroll={false} href={item.link === '' ? '#' : item.link!}>
            <a className="flex flex-col gap-[4px]">
              <span className="text-text-base font-medium">{item.name}</span>
              <span className="hidden text-text-small lg:inline-block">
                {item.description}
              </span>
            </a>
          </Link>
          {item.type && <MenuRightArrow size={10} />}
        </div>
      ))}
    </div>
  )
}

export default MenuItemsBlock
