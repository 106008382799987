import { gsap, ScrollTrigger } from '../../lib/gsap'
import { RefObject } from 'react'

function animateMap(map: RefObject<HTMLDivElement>) {
  const select = gsap.utils.selector(map)

  ScrollTrigger.matchMedia({
    '(max-width: 640px)': function () {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: map.current,
          scrub: true,
          pin: true,
          pinSpacing: true,
          start: 'top top',
        },
      })

      tl.from(select('.location'), {
        opacity: 0,
        stagger: {
          each: 0.05,
          from: 'random',
          ease: 'power2.inOut',
        },
      })
        .from(select('.map-base-dots'), {
          opacity: 0,
          duration: 3,
        })
        .to(select('svg'), {
          attr: {
            viewBox: '0 0 1359 692',
          },
          duration: 3,
        })
        .to(select('svg'), {
          padding: '12px',
          duration: 1,
        })
    },
    '(min-width: 640px)': function () {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: map.current,
          scrub: true,
          pin: true,
          pinSpacing: true,
          start: 'top top',
        },
      })

      tl.from(select('.location'), {
        opacity: 0,
        stagger: {
          each: 0.05,
          from: 'random',
          ease: 'power2.inOut',
        },
      })
        .from(select('.map-grp-1'), {
          opacity: 0,
          duration: 3,
        })
        .from(
          select('.map-grp-2'),
          {
            opacity: 0,
            duration: 3,
          },
          '<=+1'
        )
        .from(
          select('g:not(.location) > path:not(.map-grp)'),
          {
            opacity: 0,
            duration: 3,
          },
          '<=+1'
        )
    },
  })
}

export default animateMap
