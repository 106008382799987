import MenuLeftArrow from '../../../icons/menu-left-arrow'
import MenuRightArrow from '../../../icons/menu-right-arrow'
import { useMenuStatus } from '../context'
import { ServiceBlock } from '../../../types/service-menu-type'

type Props = {
  items: ServiceBlock[]
  selectedText: string
}
function MobileSecondBlock({ items, selectedText }: Props) {
  const { dispatch } = useMenuStatus()
  return (
    <>
      <div
        className={
          'col-span-full mb-3 flex flex-row items-center justify-between'
        }
      >
        <button
          onClick={() => dispatch({ type: 'updateFirstBlockIndex', index: -1 })}
          className={'flex flex-row items-center gap-1 text-text-base'}
        >
          <MenuLeftArrow />
          Back
        </button>
        <span className={'text-text-base'}>{selectedText}</span>
      </div>
      <div className="col-span-1 flex flex-col gap-2 md:col-span-3">
        {items?.map((item, index) => (
          <button
            onClick={() => dispatch({ type: 'updateSecondBlockIndex', index })}
            className="flex flex-row items-center gap-1 text-text-large"
            key={index}
          >
            <span className="max-w-max text-left">{item.name}</span>
            <MenuRightArrow />
          </button>
        ))}
      </div>
    </>
  )
}

export default MobileSecondBlock
