import { FrontPageImageSliderRecord } from '../../generated/graphql'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import { gsap } from './../../lib/gsap'
import { Image } from 'react-datocms'
import ArrowRightButton from '../../components/arrow-right-button'

type Props = {
  block: FrontPageImageSliderRecord
}

function FrontPageImageSlider({ block }: Props) {
  useIsomorphicLayoutEffect(() => {
    gsap.to('#scroller', {
      scrollTrigger: {
        trigger: '#trigger',
        pin: true,
        pinSpacing: true,
        scrub: true,
      },
      xPercent: -(block.imageSlider.length - 1.5) * (2 / 3) * 100,
    })
  }, [])

  return (
    <div
      id={'trigger'}
      className={
        'left-aligned-fullbleed isolate grid grid-cols-2 gap-2 overflow-x-hidden p-2 pt-9 md:grid-cols-8 md:gap-5 md:p-5'
      }
    >
      <div className={'col-span-2 md:col-span-3 mb-5 flex flex-col justify-center'}>
        <span className={'mb-3 text-text-small'}>{block.label}</span>
        <h2 className={'mb-5 text-header-2 font-medium md:text-header-1'}>
          {block.headline}
        </h2>
        <div className={'flex flex-col gap-2'}>
          {block.callToAction.map((cta) => (
            <ArrowRightButton key={cta.id} link={cta.link!}>
              {cta.ctaLabel}
            </ArrowRightButton>
          ))}
        </div>
      </div>
      <div className={'z-40 col-span-2 md:col-span-4 md:col-start-5 h-full'}>
        <div id={'scroller'} className={'flex h-full flex-row'}>
          {block.imageSlider.map((image) => (
            <div key={image.id} className={'w-2/3 shrink-0 bg-white pr-2'}>
              {image.responsiveImage && (
                <Image
                  style={{ maxWidth: 'none' }}
                  objectFit={'cover'}
                  className={'h-full'}
                  data={image.responsiveImage}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FrontPageImageSlider
