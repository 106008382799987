import { useMenuStatus } from '../context'
import MenuSelectListItem from './menu-select-list-item'
import { ServiceBlock } from '../../../types/service-menu-type'

type Props = {
  items: ServiceBlock[]
  selectColor?: string
  unselectColor?: string
}

function SecondBlock({ items, selectColor, unselectColor }: Props) {
  const { dispatch, state } = useMenuStatus()
  return (
    <div className={`col-span-1 col-start-4 row-start-1 flex-col items-start`}>
      {items?.map((item, index) => (
        <div
          className={`mt-1`}
          style={{
            color: index === state.secondBlock ? selectColor : unselectColor,
          }}
          key={index}
        >
          <MenuSelectListItem
            text={item.name}
            onMouseEnter={() =>
              dispatch({
                type: 'updateSecondBlockIndex',
                index,
              })
            }
          />
        </div>
      ))}
    </div>
  )
}

export default SecondBlock
