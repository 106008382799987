import DotWithLabel from '../../../components/dot-with-label'
import { KeyNewsPublicationRecord } from '../../../generated/graphql'
import { useDisplayedPublication } from '../context/use-displayed-publication'
import { getPressReleaseArticleRecordFromBlocks } from '../utils/get-press-release-article-from-blocks'

type Props = {
  publications: Array<KeyNewsPublicationRecord|any>
}
function BottomArticleList({ publications }: Props) {
  const { state, dispatch } = useDisplayedPublication()
  const updateDisplayedArticle = (index: number | null) => {
    if (index === null && state.index < publications.length - 1) {
      dispatch({
        type: 'updateDisplayedPublicationIndex',
        index: state.index + 1,
      })
    } else if (index === null && state.index === publications.length - 1) {
      dispatch({ type: 'updateDisplayedPublicationIndex', index: 0 })
    } else if (index !== null) {
      dispatch({ type: 'updateDisplayedPublicationIndex', index: index })
    }
  }
  return (
    <div className="col-span-full hidden grid-cols-3 gap-6 md:grid">
      {publications.map((publication, index) => (
        <div
          key={publication.id}
          onClick={() => updateDisplayedArticle(index)}
          className="group relative col-span-1 flex animate-[width_2s] cursor-pointer flex-col justify-between gap-3"
        >
          <div className="absolute top-[1px] h-[1px] w-full bg-greys-1" />
          <div
            onAnimationIteration={() => updateDisplayedArticle(null)}
            className={`group-hover:animation-pause absolute top-[1px] h-[1px] w-[0] bg-current ${
              state.index === index && 'animate-width-animation'
            }`}
          />
          <span className="text-large pt-3 font-body md:pr-[0] lg:pr-[108px]">
            {publication.publication?.__typename === 'PressReleaseRecord' &&
              getPressReleaseArticleRecordFromBlocks(publication.publication)
                ?.headline}
            {publication.publication?.__typename === 'InsightRecord' &&
              publication.publication.title}
          </span>
          <div className="flex-start flex flex-row gap-2">
            <DotWithLabel
              dotColor={publication?.publication?.tag?.dotColor?.hex}
              label={publication.publication?.tag?.tagLabel}
            />
            <div className="text-text-x-small text-greys-3">
              {publication.publication?.energyType?.tagName}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default BottomArticleList
