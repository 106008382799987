import Link from 'next/link'
import CtaLink from '../../../../components/cta-link'
import Section from '../../../../components/section'
import { ColorField, CtaLinkRecord } from '../../../../generated/graphql'

type Props = {
  copyrightText: string
  items: CtaLinkRecord[]
  backgroundColor?: ColorField
}

function CopyRightFooter({ copyrightText, items, backgroundColor }: Props) {
  return (
    <Section backgroundColor={backgroundColor} classOverride="text-white">
      <div className="col-span-full hidden flex-row items-center justify-between border-t border-r-white py-2 md:flex">
        <h4>{copyrightText}</h4>
        <div className="flex flex-row gap-4">
          {items.map((item) => (
            <Link
              href={item.link && item.link.length ? item.link : '#'}
              key={item.id}
            >
              <a>{item.ctaLabel}</a>
            </Link>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default CopyRightFooter
