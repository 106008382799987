import { AnimationTextBlockRecord } from '../../../generated/graphql'

type Props = {
  services: AnimationTextBlockRecord[]
}

function AnimatingDots({ services }: Props) {
  return (
    <div className={'relative'}>
      {services.map((service, index) => (
        <div
          key={service.id}
          className={`absolute top-2 left-2 flex flex-row items-center gap-1 uppercase label-${
            index + 1
          }`}
        >
          <div className={'h-[7px] w-[7px] rounded-full bg-primary-orange'} />
          <div className={' text-text-tagline text-primary-orange'}>
            {service.label}
          </div>
        </div>
      ))}
      <svg viewBox="0 0 839 800" fill="none">
        <circle
          className={'particle'}
          cx="454.211"
          cy="459.939"
          r="3"
          transform="rotate(-52 454.211 459.939)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          opacity="0.79"
          cx="431.866"
          cy="357.233"
          r="2.36"
          transform="rotate(-101 431.866 357.233)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="443.252"
          cy="343.014"
          r="1.92"
          transform="rotate(-92 443.252 343.014)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          opacity="0.92"
          cx="456.74"
          cy="345.631"
          r="2.22"
          transform="rotate(-176 456.74 345.631)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="458.881"
          cy="284.24"
          r="2.28"
          transform="rotate(91 458.881 284.24)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          opacity="0.91"
          cx="471.087"
          cy="319.918"
          r="1.82"
          transform="rotate(-171 471.087 319.918)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          opacity="0.79"
          cx="442.057"
          cy="385.713"
          r="2.3"
          transform="rotate(-25 442.057 385.713)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="438.744"
          cy="380.473"
          r="2.04"
          transform="rotate(158 438.744 380.473)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          cx="455.283"
          cy="435.3"
          r="2.61"
          transform="rotate(88 455.283 435.3)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          opacity="0.97"
          cx="467.083"
          cy="406.973"
          r="1.52"
          transform="rotate(125 467.083 406.973)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="469.572"
          cy="404.021"
          r="1.86"
          transform="rotate(68 469.572 404.021)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          cx="434.91"
          cy="533.98"
          r="2.13"
          transform="rotate(-30 434.91 533.98)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          opacity="0.91"
          cx="442.246"
          cy="548.456"
          r="1.96"
          transform="rotate(174 442.246 548.456)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          cx="453.157"
          cy="541.838"
          r="3.18"
          transform="rotate(81 453.157 541.838)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          cx="460.2"
          cy="491.933"
          r="2.64"
          transform="rotate(45 460.2 491.933)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          opacity="0.94"
          cx="479.779"
          cy="466.492"
          r="1.62"
          transform="rotate(-63 479.779 466.492)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          cx="437.995"
          cy="623.688"
          r="2.16"
          transform="rotate(26 437.995 623.688)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="442.225"
          cy="557.031"
          r="1.38"
          transform="rotate(-110 442.225 557.031)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          cx="453.722"
          cy="642.251"
          r="3.27"
          transform="rotate(-13 453.722 642.251)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          cx="471.393"
          cy="613.351"
          r="2.28"
          transform="rotate(-53 471.393 613.351)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="474.12"
          cy="585.373"
          r="1.8"
          transform="rotate(122 474.12 585.373)"
          fill="#5C75FF"
        />
        <circle
          className={'particle'}
          cx="432.784"
          cy="704.641"
          r="3.12"
          transform="rotate(61 432.784 704.641)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          cx="443.278"
          cy="654.597"
          r="2.16"
          transform="rotate(89 443.278 654.597)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          cx="459.176"
          cy="696.312"
          r="1.8"
          transform="rotate(-24 459.176 696.312)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          cx="471.314"
          cy="715.481"
          r="3.21"
          transform="rotate(-70 471.314 715.481)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          opacity="0.72"
          cx="477.759"
          cy="686.998"
          r="1.82"
          transform="rotate(-78 477.759 686.998)"
          fill="#5C75FF"
        />

        <circle
          className={'particle'}
          cx="362.211"
          cy="459.939"
          r="3"
          transform="rotate(-52 362.211 459.939)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          opacity="0.79"
          cx="339.866"
          cy="357.233"
          r="2.36"
          transform="rotate(-101 339.866 357.233)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="351.252"
          cy="343.014"
          r="1.92"
          transform="rotate(-92 351.252 343.014)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          opacity="0.92"
          cx="364.74"
          cy="345.631"
          r="2.22"
          transform="rotate(-176 364.74 345.631)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="366.881"
          cy="284.24"
          r="2.28"
          transform="rotate(91 366.881 284.24)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          opacity="0.91"
          cx="379.087"
          cy="319.918"
          r="1.82"
          transform="rotate(-171 379.087 319.918)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          opacity="0.79"
          cx="350.057"
          cy="385.713"
          r="2.3"
          transform="rotate(-25 350.057 385.713)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="346.744"
          cy="380.473"
          r="2.04"
          transform="rotate(158 346.744 380.473)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          cx="363.283"
          cy="435.3"
          r="2.61"
          transform="rotate(88 363.283 435.3)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          opacity="0.97"
          cx="375.083"
          cy="406.973"
          r="1.52"
          transform="rotate(125 375.083 406.973)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="377.572"
          cy="404.021"
          r="1.86"
          transform="rotate(68 377.572 404.021)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          cx="342.91"
          cy="533.98"
          r="2.13"
          transform="rotate(-30 342.91 533.98)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          opacity="0.91"
          cx="350.246"
          cy="548.456"
          r="1.96"
          transform="rotate(174 350.246 548.456)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          cx="361.157"
          cy="541.838"
          r="3.18"
          transform="rotate(81 361.157 541.838)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          cx="368.2"
          cy="491.933"
          r="2.64"
          transform="rotate(45 368.2 491.933)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          opacity="0.94"
          cx="387.779"
          cy="466.492"
          r="1.62"
          transform="rotate(-63 387.779 466.492)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          cx="345.995"
          cy="623.688"
          r="2.16"
          transform="rotate(26 345.995 623.688)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="350.225"
          cy="557.031"
          r="1.38"
          transform="rotate(-110 350.225 557.031)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          cx="361.722"
          cy="642.251"
          r="3.27"
          transform="rotate(-13 361.722 642.251)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          cx="379.393"
          cy="613.351"
          r="2.28"
          transform="rotate(-53 379.393 613.351)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="382.12"
          cy="585.373"
          r="1.8"
          transform="rotate(122 382.12 585.373)"
          fill="#484893"
        />
        <circle
          className={'particle'}
          cx="340.784"
          cy="704.641"
          r="3.12"
          transform="rotate(61 340.784 704.641)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          cx="351.278"
          cy="654.597"
          r="2.16"
          transform="rotate(89 351.278 654.597)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          cx="367.176"
          cy="696.312"
          r="1.8"
          transform="rotate(-24 367.176 696.312)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          cx="379.314"
          cy="715.481"
          r="3.21"
          transform="rotate(-70 379.314 715.481)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          opacity="0.72"
          cx="385.759"
          cy="686.998"
          r="1.82"
          transform="rotate(-78 385.759 686.998)"
          fill="#484893"
        />

        <circle
          className={'particle'}
          cx="545.211"
          cy="459.939"
          r="3"
          transform="rotate(-52 545.211 459.939)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          opacity="0.79"
          cx="522.866"
          cy="357.233"
          r="2.36"
          transform="rotate(-101 522.866 357.233)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="534.252"
          cy="343.014"
          r="1.92"
          transform="rotate(-92 534.252 343.014)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          opacity="0.92"
          cx="547.74"
          cy="345.631"
          r="2.22"
          transform="rotate(-176 547.74 345.631)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="549.881"
          cy="284.24"
          r="2.28"
          transform="rotate(91 549.881 284.24)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          opacity="0.91"
          cx="562.087"
          cy="319.918"
          r="1.82"
          transform="rotate(-171 562.087 319.918)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          opacity="0.79"
          cx="533.057"
          cy="385.713"
          r="2.3"
          transform="rotate(-25 533.057 385.713)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="529.744"
          cy="380.473"
          r="2.04"
          transform="rotate(158 529.744 380.473)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          cx="546.283"
          cy="435.3"
          r="2.61"
          transform="rotate(88 546.283 435.3)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          opacity="0.97"
          cx="558.083"
          cy="406.973"
          r="1.52"
          transform="rotate(125 558.083 406.973)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="560.572"
          cy="404.021"
          r="1.86"
          transform="rotate(68 560.572 404.021)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          cx="525.91"
          cy="533.98"
          r="2.13"
          transform="rotate(-30 525.91 533.98)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          opacity="0.91"
          cx="533.246"
          cy="548.456"
          r="1.96"
          transform="rotate(174 533.246 548.456)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          cx="544.157"
          cy="541.838"
          r="3.18"
          transform="rotate(81 544.157 541.838)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          cx="551.2"
          cy="491.933"
          r="2.64"
          transform="rotate(45 551.2 491.933)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          opacity="0.94"
          cx="570.779"
          cy="466.492"
          r="1.62"
          transform="rotate(-63 570.779 466.492)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          cx="528.995"
          cy="623.688"
          r="2.16"
          transform="rotate(26 528.995 623.688)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="533.225"
          cy="557.031"
          r="1.38"
          transform="rotate(-110 533.225 557.031)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          cx="544.722"
          cy="642.251"
          r="3.27"
          transform="rotate(-13 544.722 642.251)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          cx="562.393"
          cy="613.351"
          r="2.28"
          transform="rotate(-53 562.393 613.351)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="565.12"
          cy="585.373"
          r="1.8"
          transform="rotate(122 565.12 585.373)"
          fill="#C869B1"
        />
        <circle
          className={'particle'}
          cx="523.784"
          cy="704.641"
          r="3.12"
          transform="rotate(61 523.784 704.641)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          cx="534.278"
          cy="654.597"
          r="2.16"
          transform="rotate(89 534.278 654.597)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          cx="550.177"
          cy="696.312"
          r="1.8"
          transform="rotate(-24 550.177 696.312)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          cx="562.314"
          cy="715.481"
          r="3.21"
          transform="rotate(-70 562.314 715.481)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          opacity="0.72"
          cx="568.759"
          cy="686.998"
          r="1.82"
          transform="rotate(-78 568.759 686.998)"
          fill="#C869B1"
        />

        <circle
          className={'particle'}
          cx="641.211"
          cy="459.939"
          r="3"
          transform="rotate(-52 641.211 459.939)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.79"
          cx="618.866"
          cy="357.233"
          r="2.36"
          transform="rotate(-101 618.866 357.233)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="630.252"
          cy="343.014"
          r="1.92"
          transform="rotate(-92 630.252 343.014)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.92"
          cx="643.74"
          cy="345.631"
          r="2.22"
          transform="rotate(-176 643.74 345.631)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="645.881"
          cy="284.24"
          r="2.28"
          transform="rotate(91 645.881 284.24)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.91"
          cx="658.087"
          cy="319.918"
          r="1.82"
          transform="rotate(-171 658.087 319.918)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.79"
          cx="629.057"
          cy="385.713"
          r="2.3"
          transform="rotate(-25 629.057 385.713)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="625.744"
          cy="380.473"
          r="2.04"
          transform="rotate(158 625.744 380.473)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="642.283"
          cy="435.3"
          r="2.61"
          transform="rotate(88 642.283 435.3)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.97"
          cx="654.083"
          cy="406.973"
          r="1.52"
          transform="rotate(125 654.083 406.973)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="656.572"
          cy="404.021"
          r="1.86"
          transform="rotate(68 656.572 404.021)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="621.91"
          cy="533.98"
          r="2.13"
          transform="rotate(-30 621.91 533.98)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.91"
          cx="629.246"
          cy="548.456"
          r="1.96"
          transform="rotate(174 629.246 548.456)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="640.157"
          cy="541.838"
          r="3.18"
          transform="rotate(81 640.157 541.838)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="647.2"
          cy="491.933"
          r="2.64"
          transform="rotate(45 647.2 491.933)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.94"
          cx="666.779"
          cy="466.492"
          r="1.62"
          transform="rotate(-63 666.779 466.492)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="624.995"
          cy="623.688"
          r="2.16"
          transform="rotate(26 624.995 623.688)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="629.225"
          cy="557.031"
          r="1.38"
          transform="rotate(-110 629.225 557.031)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="640.722"
          cy="642.251"
          r="3.27"
          transform="rotate(-13 640.722 642.251)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="658.393"
          cy="613.351"
          r="2.28"
          transform="rotate(-53 658.393 613.351)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="661.12"
          cy="585.373"
          r="1.8"
          transform="rotate(122 661.12 585.373)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="619.784"
          cy="704.641"
          r="3.12"
          transform="rotate(61 619.784 704.641)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="630.278"
          cy="654.597"
          r="2.16"
          transform="rotate(89 630.278 654.597)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="646.177"
          cy="696.312"
          r="1.8"
          transform="rotate(-24 646.177 696.312)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="658.314"
          cy="715.481"
          r="3.21"
          transform="rotate(-70 658.314 715.481)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.72"
          cx="664.759"
          cy="686.998"
          r="1.82"
          transform="rotate(-78 664.759 686.998)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="641.211"
          cy="227.939"
          r="3"
          transform="rotate(-52 641.211 227.939)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.79"
          cx="618.866"
          cy="125.233"
          r="2.36"
          transform="rotate(-101 618.866 125.233)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="630.252"
          cy="111.014"
          r="1.92"
          transform="rotate(-92 630.252 111.014)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.92"
          cx="643.74"
          cy="113.631"
          r="2.22"
          transform="rotate(-176 643.74 113.631)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="645.881"
          cy="52.2399"
          r="2.28"
          transform="rotate(91 645.881 52.2399)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.91"
          cx="658.087"
          cy="87.9177"
          r="1.82"
          transform="rotate(-171 658.087 87.9177)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.79"
          cx="629.057"
          cy="153.713"
          r="2.3"
          transform="rotate(-25 629.057 153.713)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="625.744"
          cy="148.473"
          r="2.04"
          transform="rotate(158 625.744 148.473)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="642.283"
          cy="203.3"
          r="2.61"
          transform="rotate(88 642.283 203.3)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.97"
          cx="654.083"
          cy="174.973"
          r="1.52"
          transform="rotate(125 654.083 174.973)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="656.572"
          cy="172.021"
          r="1.86"
          transform="rotate(68 656.572 172.021)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="621.91"
          cy="301.98"
          r="2.13"
          transform="rotate(-30 621.91 301.98)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.91"
          cx="629.246"
          cy="316.456"
          r="1.96"
          transform="rotate(174 629.246 316.456)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="640.157"
          cy="309.838"
          r="3.18"
          transform="rotate(81 640.157 309.838)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="647.2"
          cy="259.933"
          r="2.64"
          transform="rotate(45 647.2 259.933)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.94"
          cx="666.779"
          cy="234.492"
          r="1.62"
          transform="rotate(-63 666.779 234.492)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="624.995"
          cy="391.688"
          r="2.16"
          transform="rotate(26 624.995 391.688)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="629.225"
          cy="325.031"
          r="1.38"
          transform="rotate(-110 629.225 325.031)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="640.722"
          cy="410.251"
          r="3.27"
          transform="rotate(-13 640.722 410.251)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="658.393"
          cy="381.351"
          r="2.28"
          transform="rotate(-53 658.393 381.351)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="661.12"
          cy="353.373"
          r="1.8"
          transform="rotate(122 661.12 353.373)"
          fill="#9576E0"
        />
        <circle
          className={'particle'}
          cx="619.784"
          cy="472.641"
          r="3.12"
          transform="rotate(61 619.784 472.641)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="630.278"
          cy="422.597"
          r="2.16"
          transform="rotate(89 630.278 422.597)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="646.177"
          cy="464.312"
          r="1.8"
          transform="rotate(-24 646.177 464.312)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          cx="658.314"
          cy="483.481"
          r="3.21"
          transform="rotate(-70 658.314 483.481)"
          fill="#9576E0"
        />

        <circle
          className={'particle'}
          opacity="0.72"
          cx="664.759"
          cy="454.998"
          r="1.82"
          transform="rotate(-78 664.759 454.998)"
          fill="#9576E0"
        />

        <circle
          className={'column particle'}
          cx="638.422"
          cy="715.004"
          r="6"
          transform="rotate(-52 638.422 715.004)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="715.004"
          r="6"
          transform="rotate(-52 546.422 715.004)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="715.004"
          r="6"
          transform="rotate(-52 454.422 715.004)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="715.004"
          r="6"
          transform="rotate(-52 178.422 715.004)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="715.004"
          r="6"
          transform="rotate(-52 270.422 715.004)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="715.004"
          r="6"
          transform="rotate(-52 362.422 715.004)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="497.176"
          r="6"
          transform="rotate(-52 638.422 497.176)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="497.176"
          r="6"
          transform="rotate(-52 546.422 497.176)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="497.176"
          r="6"
          transform="rotate(-52 454.422 497.176)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="497.176"
          r="6"
          transform="rotate(-52 178.422 497.176)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="497.176"
          r="6"
          transform="rotate(-52 270.422 497.176)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="497.176"
          r="6"
          transform="rotate(-52 362.422 497.176)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="279.348"
          r="6"
          transform="rotate(-52 638.422 279.348)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="279.348"
          r="6"
          transform="rotate(-52 546.422 279.348)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="606.09"
          r="6"
          transform="rotate(-52 638.422 606.09)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="606.09"
          r="6"
          transform="rotate(-52 546.422 606.09)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="606.09"
          r="6"
          transform="rotate(-52 454.422 606.09)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="606.09"
          r="6"
          transform="rotate(-52 178.422 606.09)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="606.09"
          r="6"
          transform="rotate(-52 270.422 606.09)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="606.09"
          r="6"
          transform="rotate(-52 362.422 606.09)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="388.262"
          r="6"
          transform="rotate(-52 638.422 388.262)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="388.262"
          r="6"
          transform="rotate(-52 546.422 388.262)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="388.262"
          r="6"
          transform="rotate(-52 454.422 388.262)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="388.262"
          r="6"
          transform="rotate(-52 178.422 388.262)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="388.262"
          r="6"
          transform="rotate(-52 270.422 388.262)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="388.262"
          r="6"
          transform="rotate(-52 362.422 388.262)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="251.955"
          r="6"
          transform="rotate(-52 362.422 251.955)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="170.434"
          r="6"
          transform="rotate(-52 638.422 170.434)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="660.547"
          r="6"
          transform="rotate(-52 638.422 660.547)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="660.547"
          r="6"
          transform="rotate(-52 546.422 660.547)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="660.547"
          r="6"
          transform="rotate(-52 454.422 660.547)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="660.547"
          r="6"
          transform="rotate(-52 178.422 660.547)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="660.547"
          r="6"
          transform="rotate(-52 270.422 660.547)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="660.547"
          r="6"
          transform="rotate(-52 362.422 660.547)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="442.719"
          r="6"
          transform="rotate(-52 638.422 442.719)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="442.719"
          r="6"
          transform="rotate(-52 546.422 442.719)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="442.719"
          r="6"
          transform="rotate(-52 454.422 442.719)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="442.719"
          r="6"
          transform="rotate(-52 178.422 442.719)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="442.719"
          r="6"
          transform="rotate(-52 270.422 442.719)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="442.719"
          r="6"
          transform="rotate(-52 362.422 442.719)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="224.891"
          r="6"
          transform="rotate(-52 638.422 224.891)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="224.891"
          r="6"
          transform="rotate(-52 546.422 224.891)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="551.633"
          r="6"
          transform="rotate(-52 638.422 551.633)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="551.633"
          r="6"
          transform="rotate(-52 546.422 551.633)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="551.633"
          r="6"
          transform="rotate(-52 454.422 551.633)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="551.633"
          r="6"
          transform="rotate(-52 178.422 551.633)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="551.633"
          r="6"
          transform="rotate(-52 270.422 551.633)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="551.633"
          r="6"
          transform="rotate(-52 362.422 551.633)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="333.805"
          r="6"
          transform="rotate(-52 638.422 333.805)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="333.805"
          r="6"
          transform="rotate(-52 546.422 333.805)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="333.805"
          r="6"
          transform="rotate(-52 454.422 333.805)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="333.805"
          r="6"
          transform="rotate(-52 178.422 333.805)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="333.805"
          r="6"
          transform="rotate(-52 362.422 333.805)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="197.498"
          r="6"
          transform="rotate(-52 362.422 197.498)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="115.977"
          r="6"
          transform="rotate(-52 638.422 115.977)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="687.775"
          r="6"
          transform="rotate(-52 638.422 687.775)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="687.775"
          r="6"
          transform="rotate(-52 546.422 687.775)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="687.775"
          r="6"
          transform="rotate(-52 454.422 687.775)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="687.775"
          r="6"
          transform="rotate(-52 178.422 687.775)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="687.775"
          r="6"
          transform="rotate(-52 270.422 687.775)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="687.775"
          r="6"
          transform="rotate(-52 362.422 687.775)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="469.947"
          r="6"
          transform="rotate(-52 638.422 469.947)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="469.947"
          r="6"
          transform="rotate(-52 546.422 469.947)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="469.947"
          r="6"
          transform="rotate(-52 454.422 469.947)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="469.947"
          r="6"
          transform="rotate(-52 178.422 469.947)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="469.947"
          r="6"
          transform="rotate(-52 270.422 469.947)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="469.947"
          r="6"
          transform="rotate(-52 362.422 469.947)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="252.119"
          r="6"
          transform="rotate(-52 638.422 252.119)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="252.119"
          r="6"
          transform="rotate(-52 546.422 252.119)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="578.861"
          r="6"
          transform="rotate(-52 638.422 578.861)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="578.861"
          r="6"
          transform="rotate(-52 546.422 578.861)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="578.861"
          r="6"
          transform="rotate(-52 454.422 578.861)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="578.861"
          r="6"
          transform="rotate(-52 178.422 578.861)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="578.861"
          r="6"
          transform="rotate(-52 270.422 578.861)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="578.861"
          r="6"
          transform="rotate(-52 362.422 578.861)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="361.033"
          r="6"
          transform="rotate(-52 638.422 361.033)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="361.033"
          r="6"
          transform="rotate(-52 546.422 361.033)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="361.033"
          r="6"
          transform="rotate(-52 454.422 361.033)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="361.033"
          r="6"
          transform="rotate(-52 178.422 361.033)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="361.033"
          r="6"
          transform="rotate(-52 362.422 361.033)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="224.727"
          r="6"
          transform="rotate(-52 362.422 224.727)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="143.205"
          r="6"
          transform="rotate(-52 638.422 143.205)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="633.318"
          r="6"
          transform="rotate(-52 638.422 633.318)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="633.318"
          r="6"
          transform="rotate(-52 546.422 633.318)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="633.318"
          r="6"
          transform="rotate(-52 454.422 633.318)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="633.318"
          r="6"
          transform="rotate(-52 178.422 633.318)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="633.318"
          r="6"
          transform="rotate(-52 270.422 633.318)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="633.318"
          r="6"
          transform="rotate(-52 362.422 633.318)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="415.49"
          r="6"
          transform="rotate(-52 638.422 415.49)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="415.49"
          r="6"
          transform="rotate(-52 546.422 415.49)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="415.49"
          r="6"
          transform="rotate(-52 178.422 415.49)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="415.49"
          r="6"
          transform="rotate(-52 270.422 415.49)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="415.49"
          r="6"
          transform="rotate(-52 362.422 415.49)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="279.184"
          r="6"
          transform="rotate(-52 362.422 279.184)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="197.662"
          r="6"
          transform="rotate(-52 638.422 197.662)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="197.662"
          r="6"
          transform="rotate(-52 546.422 197.662)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="524.404"
          r="6"
          transform="rotate(-52 638.422 524.404)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="524.404"
          r="6"
          transform="rotate(-52 546.422 524.404)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="524.404"
          r="6"
          transform="rotate(-52 454.422 524.404)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="524.404"
          r="6"
          transform="rotate(-52 178.422 524.404)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="270.422"
          cy="524.404"
          r="6"
          transform="rotate(-52 270.422 524.404)"
          fill="#FFA768"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="524.404"
          r="6"
          transform="rotate(-52 362.422 524.404)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="306.576"
          r="6"
          transform="rotate(-52 638.422 306.576)"
          fill="#B08AFF"
        />
        <circle
          className={'column particle'}
          cx="546.422"
          cy="306.576"
          r="6"
          transform="rotate(-52 546.422 306.576)"
          fill="#F079C5"
        />
        <circle
          className={'column particle'}
          cx="454.422"
          cy="306.576"
          r="6"
          transform="rotate(-52 454.422 306.576)"
          fill="#5970F2"
        />
        <circle
          className={'column particle'}
          cx="178.422"
          cy="306.576"
          r="6"
          transform="rotate(-52 178.422 306.576)"
          fill="#BBCBC8"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="306.576"
          r="6"
          transform="rotate(-52 362.422 306.576)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="362.422"
          cy="170.27"
          r="6"
          transform="rotate(-52 362.422 170.27)"
          fill="#50509F"
        />
        <circle
          className={'column particle'}
          cx="638.422"
          cy="88.7481"
          r="6"
          transform="rotate(-52 638.422 88.7481)"
          fill="#B08AFF"
        />
        <circle
          className={'particle'}
          cx="269.211"
          cy="483.939"
          r="3"
          transform="rotate(-52 269.211 483.939)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          opacity="0.79"
          cx="246.866"
          cy="381.233"
          r="2.36"
          transform="rotate(-101 246.866 381.233)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="258.252"
          cy="367.014"
          r="1.92"
          transform="rotate(-92 258.252 367.014)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          opacity="0.92"
          cx="271.74"
          cy="369.631"
          r="2.22"
          transform="rotate(-176 271.74 369.631)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="273.881"
          cy="308.24"
          r="2.28"
          transform="rotate(91 273.881 308.24)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          opacity="0.91"
          cx="286.087"
          cy="343.918"
          r="1.82"
          transform="rotate(-171 286.087 343.918)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          opacity="0.79"
          cx="257.057"
          cy="409.713"
          r="2.3"
          transform="rotate(-25 257.057 409.713)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          opacity="0.98"
          cx="253.744"
          cy="404.473"
          r="2.04"
          transform="rotate(158 253.744 404.473)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          cx="270.283"
          cy="459.3"
          r="2.61"
          transform="rotate(88 270.283 459.3)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          opacity="0.97"
          cx="282.083"
          cy="430.973"
          r="1.52"
          transform="rotate(125 282.083 430.973)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          opacity="0.94"
          cx="284.572"
          cy="428.021"
          r="1.86"
          transform="rotate(68 284.572 428.021)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          cx="249.91"
          cy="557.98"
          r="2.13"
          transform="rotate(-30 249.91 557.98)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          opacity="0.91"
          cx="257.246"
          cy="572.456"
          r="1.96"
          transform="rotate(174 257.246 572.456)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          cx="268.157"
          cy="565.838"
          r="3.18"
          transform="rotate(81 268.157 565.838)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          cx="275.2"
          cy="515.933"
          r="2.64"
          transform="rotate(45 275.2 515.933)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          opacity="0.94"
          cx="294.779"
          cy="490.492"
          r="1.62"
          transform="rotate(-63 294.779 490.492)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          cx="252.995"
          cy="647.688"
          r="2.16"
          transform="rotate(26 252.995 647.688)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="257.225"
          cy="581.031"
          r="1.38"
          transform="rotate(-110 257.225 581.031)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          cx="268.722"
          cy="666.251"
          r="3.27"
          transform="rotate(-13 268.722 666.251)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          cx="286.393"
          cy="637.351"
          r="2.28"
          transform="rotate(-53 286.393 637.351)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          opacity="0.7"
          cx="289.12"
          cy="609.373"
          r="1.8"
          transform="rotate(122 289.12 609.373)"
          fill="#FFA768"
        />
        <circle
          className={'particle'}
          cx="247.784"
          cy="728.641"
          r="3.12"
          transform="rotate(61 247.784 728.641)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          cx="258.278"
          cy="678.597"
          r="2.16"
          transform="rotate(89 258.278 678.597)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          cx="274.176"
          cy="720.312"
          r="1.8"
          transform="rotate(-24 274.176 720.312)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          cx="286.314"
          cy="739.481"
          r="3.21"
          transform="rotate(-70 286.314 739.481)"
          fill="#FFA768"
        />

        <circle
          className={'particle'}
          opacity="0.72"
          cx="292.759"
          cy="710.998"
          r="1.82"
          transform="rotate(-78 292.759 710.998)"
          fill="#FFA768"
        />

        <circle
          className={'particle enlarge-layer-2'}
          cx="454.422"
          cy="415.49"
          r="6"
          fill="#5970f28f"
        />
        <circle
          className={'particle enlarge-layer-1'}
          cx="454.422"
          cy="415.49"
          r="6"
          fill="#5970f2c2"
        />
        <circle
          className={'particle enlarging-dot'}
          cx="454.422"
          cy="415.49"
          r="6"
          fill="#5970F2"
        />
        <circle
          className={'inner-circle'}
          cx="464.798"
          cy="415.662"
          r="1.67997"
          fill="#5970F2"
        />
        <circle
          className={'inner-circle'}
          cx="444.358"
          cy="415.662"
          r="1.67997"
          fill="#5970F2"
        />
        <circle
          className={'inner-circle'}
          cx="454.579"
          cy="425.882"
          r="1.67997"
          fill="#5970F2"
        />
        <circle
          className={'inner-circle'}
          cx="454.579"
          cy="405.442"
          r="1.67997"
          fill="#5970F2"
        />
        <circle
          className={'inner-circle'}
          cx="461.806"
          cy="422.888"
          r="1.67997"
          fill="#5970F2"
        />
        <circle
          className={'inner-circle'}
          cx="447.353"
          cy="408.435"
          r="1.67997"
          fill="#5970F2"
        />
        <circle
          className={'inner-circle'}
          cx="447.352"
          cy="422.888"
          r="1.67997"
          fill="#5970F2"
        />
        <circle
          className={'inner-circle'}
          cx="461.805"
          cy="408.435"
          r="1.67997"
          fill="#5970F2"
        />
        <circle
          className={'outer-circle'}
          cx="454.999"
          cy="470.438"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="454.999"
          cy="361.729"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="400.646"
          cy="416.085"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="509.355"
          cy="416.085"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="416.566"
          cy="377.65"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="493.434"
          cy="454.517"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="493.434"
          cy="377.65"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="416.567"
          cy="454.518"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="504.899"
          cy="394.531"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="405.102"
          cy="437.636"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="505.248"
          cy="436.807"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="404.752"
          cy="395.36"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="475.001"
          cy="466.623"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="435.001"
          cy="365.545"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="433.73"
          cy="466.105"
          r="4.64567"
          fill="#B08AFF"
        />
        <circle
          className={'outer-circle'}
          cx="476.271"
          cy="366.064"
          r="4.64567"
          fill="#B08AFF"
        />
      </svg>
    </div>
  )
}

export default AnimatingDots
