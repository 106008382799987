import SearchResult from '../../types/SearchResult'
import Link from 'next/link'
import { useState } from 'react'
import classNames from 'classnames'

type Props = {
  data: SearchResult[]
}

type Tab = {
  label: string
  path: string
}

const tabs: Tab[] = [
  { label: 'All', path: 'http' },
  { label: 'Solutions', path: '/services/' },
  { label: 'News', path: '/news/' },
]

function SearchResultList({ data }: Props) {
  const [selectedTab, setSelectedTab] = useState<Tab>(tabs[0])

  const filteredData = data?.filter(
    (item) => item?.attributes?.highlight?.body?.length > 0
  )
  const resultDescriptions = filteredData?.filter((item) =>
    item?.attributes?.url?.includes(selectedTab?.path)
  )

  const tabCount = filteredData.reduce<{ [index: string]: number }>(
    (accumulator, { attributes: { url } }) => {
      tabs.forEach((tab) => {
        if (url.includes(tab.path)) {
          accumulator[tab.path] += 1
        }
      })
      return accumulator
    },
    {
      http: 0,
      '/services/': 0,
      '/news/': 0,
      '/insights/': 0,
    }
  )

  return (
    <div>
      <div
        className={
          'no-scrollbar flex flex-row overflow-x-scroll border-greys-1 md:gap-1 md:overflow-x-visible md:border-b'
        }
      >
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={classNames(
              'relative shrink-0 border-b py-1 px-2 md:border-none md:py-2 md:px-3',
              {
                'border-greys-1': tab.path !== selectedTab.path,
              }
            )}
            onClick={() => setSelectedTab(tab)}
          >
            {tab.label} ({tabCount[tab.path]})
            {tab.path === selectedTab.path && (
              <div
                className={
                  'absolute bottom-[-1px] left-[0px] h-[1px] w-full bg-primary-rystad-blue'
                }
              />
            )}
          </button>
        ))}
      </div>
      {resultDescriptions?.map((item) => (
        <div key={item.id} className={'border-b border-greys-1 pb-5 pt-4'}>
          <Link scroll={false} href={item.attributes.url}>
            <a className={'mb-1 font-display text-text-base'}>
              {item.attributes.title}
            </a>
          </Link>
          <p className={'mb-2 text-text-base'}>
            {item?.attributes?.highlight?.body.length > 2
              ? item?.attributes?.highlight?.body
                  .slice(1, -1)
                  .join('. ')
                  .replace(/\[h\]/g, '')
                  .replace(/\[\/h\]/g, '')
              : item?.attributes?.highlight?.body
                  .join('. ')
                  .replace(/\[h\]/g, '')
                  .replace(/\[\/h\]/g, '')}
          </p>
          <div className={'flex flex-row gap-2 text-text-small text-greys-3'}>
            <span>Page</span>
            <Link scroll={false} href={item.attributes.url}>
              <a>{item.attributes.url}</a>
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default SearchResultList
