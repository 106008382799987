import { DirectUploadOrExternalLinkInsightModelInsightField } from '../../generated/graphql'

function getFileLinkFromDirectUploadOrExternalLinkField(
  field: DirectUploadOrExternalLinkInsightModelInsightField
) {
  if (field.__typename === 'DirectUploadRecord') {
    return field.uploadedInsight?.url ?? ''
  }
  if (field.__typename === 'ExternalSugarMarketLinkRecord') {
    return field.externalLink ?? ''
  }

  return null
}

export default getFileLinkFromDirectUploadOrExternalLinkField
