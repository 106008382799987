import classNames from 'classnames'
import { Image } from 'react-datocms'
import StructuredText from '../components/structured-text'
import Section from '../components/section'
import ShareButton from '../components/share-button/share-button'
import {
  PressReleaseArticleRecord,
  EmployeeCharacteristicRecord,
} from '../generated/graphql'
import { isEmployeeCharacteristicRecord } from './team-grid/team-grid'

type Props = {
  block: PressReleaseArticleRecord
}

function PressReleaseArticle({ block }: Props) {
  let authors: EmployeeCharacteristicRecord[] = []

  if (block?.authorS[0]?.pressReleaseSolutionContactOrAuthor) {
    authors = block.authorS[0].pressReleaseSolutionContactOrAuthor
      .map((author) => author.contentLayout[0]!)
      .filter(isEmployeeCharacteristicRecord)
  }

  return (
    <Section>
      <div className="col-span-full md:mt-7 lg:col-span-4">
        <h1 className="text-header-1">{block.headline}</h1>
        {block.image && (
          <div className="mt-0 relative -mx-2 mt-5 block lg:mt-6 lg:hidden">
            {block.image && block.image.responsiveImage && (
              <Image
                style={{ maxWidth: 'none' }}
                data={block.image.responsiveImage}
              />
            )}

            <div className="absolute bottom-[1px] mt-6 flex w-full flex-col border-t-2 border-greys-0 border-opacity-20 bg-dusty-dark-blue-border bg-opacity-40 px-2 pt-3 lg:hidden lg:flex-row">
              <div
                className={`relative flex flex-row items-center pb-3 ${
                  authors?.length ? '' : 'pt-4'
                }`}
              >
                {authors &&
                  authors?.map((author, index) => (
                    <div
                      key={author.id}
                      title={`${author.name}`}
                      className={classNames('', {
                        '-ml-1': index !== 0,
                        'z-40': index === 0,
                        [`z-${index * 10}`]: index !== 0,
                      })}
                    >
                      {author.profilePicture?.responsiveImage && (
                        <Image
                          className="rounded-full border-2 border-transparent"
                          data={author.profilePicture?.responsiveImage!}
                        />
                      )}
                    </div>
                  ))}

                <div className="ml-2 flex flex-col">
                  {authors.map((author) => (
                    <h2
                      key={author.id}
                      className="text-text-x-small text-greys-0"
                    >
                      {author.name}
                    </h2>
                  ))}
                </div>
              </div>

              <div
                className={`absolute bottom-1 ${
                  authors?.length ? 'right-1' : 'left-1'
                } mt-3 pb-2 lg:mt-auto lg:ml-auto`}
              >
                <ShareButton
                  mobileColumn={!!authors.length}
                  text={block?.shareButtonLink?.text!}
                  variant="white"
                  icons={block.shareButtonLink?.socialMediaButtons!}
                />
              </div>
            </div>
          </div>
        )}

        <div
          className={`mt-6 ${
            block.image ? 'hidden' : ''
          } relative flex-col border-t-2 border-greys-1 pt-3 lg:flex lg:flex-row`}
        >
          <div
            className={`relative flex flex-row ${
              authors?.length ? '' : 'pt-5'
            }`}
          >
            {authors.map((author, index) => (
              <div
                key={author.id}
                title={`${author.name}`}
                className={classNames('', {
                  '-ml-1': index !== 0,
                  'z-30': index === 0,
                  [`z-${index * 10}`]: index !== 0,
                })}
              >
                {author.profilePicture?.responsiveImage && (
                  <Image
                    className="rounded-full border-2 border-greys-0"
                    data={author.profilePicture?.responsiveImage!}
                  />
                )}
              </div>
            ))}

            <div className="ml-2 flex flex-col justify-center">
              {authors.map((author) => (
                <div key={author.id}>
                  <h2 className="text-text-x-small text-primary-rystad-blue">
                    {author.name}
                  </h2>
                </div>
              ))}
            </div>
          </div>

          <div
            className={`absolute ${
              authors?.length ? 'right-1' : 'left-1'
            } bottom-1 mt-3 lg:mt-auto lg:ml-auto`}
          >
            <ShareButton
              mobileColumn={true}
              text={block?.shareButtonLink?.text!}
              variant="primary"
              icons={block.shareButtonLink?.socialMediaButtons!}
            />
          </div>
        </div>

        <div className="mt-9">
          <StructuredText data={block.articleText} />
        </div>
      </div>

      <div className="col-span-3 col-start-6 mt-1 hidden lg:block">
        {block.image && block.image.responsiveImage && (
          <Image
            style={{ maxWidth: 'none' }}
            data={block.image.responsiveImage}
          />
        )}
      </div>
    </Section>
  )
}

export default PressReleaseArticle
