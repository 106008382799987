import { useRouter } from 'next/router'
import { createRef, useEffect, useState } from 'react'
import { ResponsiveImageType } from 'react-datocms'
import useFullscreen from '../hooks/use-fullscreen'
import useMediaQuery from '../hooks/use-media-query'
import PlayCircle from '../icons/play-circle'
import { isIphone } from '../utils/check-platform'
import AreaWithCursor from './area-with-cursor'

type Variant = 'bottom' | 'center'

const textPlacement: Record<Variant, string> = {
  bottom: 'items-end justify-start pb-11 pl-2',
  center: 'items-center justify-center',
}

type Props = {
  src: string
  poster?: ResponsiveImageType
  playText?: string
  textPosition?: Variant
}

function VideoComponent({ src, playText, poster, textPosition }: Props) {
  const router = useRouter()
  const isFullscreen = useFullscreen()
  const videoRef = createRef<HTMLVideoElement>()
  const isSmallScreen = useMediaQuery('(max-width: 768px)')
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  useEffect(() => {
    if (!isFullscreen && isSmallScreen) {
      if (videoRef) {
        videoRef.current?.load()
      }
    }
  }, [isFullscreen, isSmallScreen, videoRef])

  function onClickVideo() {
    setIsPlaying((prev) => !prev)

    if (isPlaying) {
      videoRef.current?.pause()
    } else {
      videoRef.current?.play()
      videoRef.current!.muted = false
    }
  }

  function requestFullscreen() {
    if (!document.fullscreenElement) {
      if (document.fullscreenEnabled) {
        videoRef.current && videoRef.current.requestFullscreen()
      } else if (document.webkitFullscreenEnabled) {
        videoRef.current && videoRef.current.webkitRequestFullscreen()
      }
    }

    if (isIphone()) {
      router.push(src)
    }
  }

  if (isSmallScreen) {
    return (
      <div className="relative h-full w-full">
        <div
          onClick={requestFullscreen}
          className={`absolute inset-[0] z-30 flex cursor-pointer flex-row gap-2 font-medium text-white ${
            textPlacement[textPosition ?? 'center']
          }`}
        >
          <div className="flex flex-row items-center gap-2">
            <PlayCircle />
            <span>{playText}</span>
          </div>
        </div>
        <video
          className="h-full w-full object-cover"
          poster={poster?.src!}
          controls={isPlaying}
          ref={videoRef}
          onPause={() => setIsPlaying(false)}
        >
          <source src={src} type="video/mp4" />
        </video>
      </div>
    )
  } else {
    return (
      <AreaWithCursor
        hideOnBottom={isPlaying}
        isCursorHidden={false}
        cursorLabel={isPlaying ? 'Pause' : 'Play'}
        classes="relative pt-[calc(100%*798/1440)] h-auto"
      >
        <video
          onClick={onClickVideo}
          className="absolute inset-[0] h-full w-full cursor-none object-cover"
          ref={videoRef}
          poster={poster?.src!}
          controls={isPlaying}
          onPause={() => setIsPlaying(false)}
        >
          <source src={src} type="video/mp4" />
        </video>
      </AreaWithCursor>
    )
  }
}

export default VideoComponent
