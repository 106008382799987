import CtaLink from '../../../../components/cta-link'
import { ColorField, CtaLinkRecord } from '../../../../generated/graphql'
import useMediaQuery from '../../../../hooks/use-media-query'
import BrandBottom from './brand-bottom'
import CopyRightFooter from './copy-right-footer'

type Props = {
  copyrightText: string
  items: CtaLinkRecord[]
  backgroundColor?: ColorField
}

function FooterBottom({ copyrightText, items, backgroundColor }: Props) {
  const isSmallScreen = useMediaQuery('(max-width: 768px)')

  if (isSmallScreen) {
    return <BrandBottom />
  }
  return (
    <CopyRightFooter
      backgroundColor={backgroundColor}
      items={items}
      copyrightText={copyrightText}
    />
  )
}

export default FooterBottom
