import { AnimationTextBlockRecord } from '../../../generated/graphql'
import ServiceLabel from './service-label'

type Props = {
  services: AnimationTextBlockRecord[]
}

function ServiceLabels({ services }: Props) {
  return (
    <div className={'absolute grid h-full h-full w-full grid-cols-2 px-5'}>
      <div
        className={
          'relative col-span-1 h-full overflow-hidden border-l border-l-primary-rystad-blue-light-tint'
        }
      >
        <div className={'labels grid h-[130%] translate-y-[20%] transform'}>
          <div className={'.inner h-100%'}>
            {services.map((service, index) => (
              <ServiceLabel key={service.id} service={service} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceLabels
