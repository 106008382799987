import {
  FourColumnModuleRecord,
  FourColumnModuleContentRecord,
} from '../../generated/graphql'
import Section from '../../components/section'

type Props = {
  block: FourColumnModuleRecord
}

function FourColumnModule({ block }: Props) {
  if (!block) {
    return null
  }

  return (
    <Section>
      <div className="col-span-full grid grid-cols-1 gap-[40px] md:grid-cols-4">
        {block.fourColumnModuleContent?.map(
          (column: FourColumnModuleContentRecord) => (
            <div key={column.id} className="text-[15px] text-[#292862]">
              <img
                className="mb-3 h-6"
                width={'auto'}
                src={column.icon?.url}
                alt={column.icon?.alt!}
              />
              <h4 className="mb-1 text-header-4 text-[15px] font-medium">
                {column.headline}
              </h4>
              <p className="text-base">{column.description}</p>
            </div>
          )
        )}
      </div>
    </Section>
  )
}

export default FourColumnModule
