import Section from '../../../components/section'
import { ColorField, FooterRecord } from '../../../generated/graphql'
import FooterBottom from './footer-bottom'
import FooterCtaList from './footer-cta-list'
import NewsLetterRegisterButton from './news-letter-register-button'

type Props = {
  footer: FooterRecord
  backgroundColorHex: ColorField
}

function Footer({ footer, backgroundColorHex }: Props) {
  return (
    <>
      <Section
        classOverride={'text-white'}
        classOverrideInnerGrid={'md:grid-cols-2 lg:grid-cols-8'}
        backgroundColor={backgroundColorHex}
      >
        <div className="col-span-2 col-start-1">
          <h4 className="text-text-small text-primary-rystad-blue-light-tint-2">
            {footer?.preTitle}
          </h4>
          <div className="mt-3 flex flex-col gap-4 lg:mt-5">
            <h2 className="text-header-2">{footer?.headline}</h2>
            <div className="flex">
              <NewsLetterRegisterButton
                label={footer?.newsletterCta[0].ctaLabel!}
                link={footer?.newsletterCta[0].link!}
              />
            </div>
          </div>
        </div>
        <div className="col-span-2 col-start-1 mt-5 flex md:col-span-1 md:mt-[0] lg:col-span-2 lg:col-start-3 lg:justify-center">
          <FooterCtaList items={footer?.ctaS!} />
        </div>
        <div className="col-span-2 col-start-1 my-4 flex md:col-span-1 md:col-start-2 md:my-[0] lg:col-span-2 lg:col-start-5 lg:justify-center">
          <FooterCtaList
            items={footer?.smallCtaS!}
            linkedInLink={footer?.linkedInLink}
            variant="small"
          />
        </div>
        <a
          href="#"
          className="col-span-1 col-start-8 hidden justify-end text-end text-text-x-small lg:block"
        >
          {footer?.toTheTop}
        </a>
      </Section>
      <FooterBottom
        backgroundColor={backgroundColorHex!}
        copyrightText={footer?.copyrightText!}
        items={footer?.privacyPolicyCookiesTermsAndConditions!}
      />
    </>
  )
}

export default Footer
