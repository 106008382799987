import Section from '../../components/section'
import { CategoryPageIntroDescriptionRecord } from '../../generated/graphql'
import CtaLink from '../../components/cta-link'

type Props = {
  block: CategoryPageIntroDescriptionRecord
}

function IntroDescriptionRecord({ block }: Props) {
  return (
    <Section id={block?.slug || ''}>
      <div className="col-span-2 flex flex-row gap-1">
        {block.industryOverviewPageLinkForSubpageBreadcrumb?.map((cta) => (
          <div key={cta.id} className="text-greys-3">
            <CtaLink cta={cta} />
          </div>
        ))}
        {block.industryOverviewPageLinkForSubpageBreadcrumb?.length > 0 && (
          <div className="text-greys-3">/</div>
        )}
        <h3 id={block.slug ?? ''} className={`md:inline-block`}>
          {block.label}
        </h3>
      </div>
      <div className="col-span-full flex flex-col items-start gap-3 md:col-span-4 md:col-start-5">
        <h1 className={`text-header-2`}>{block.description}</h1>
        <div className="flex flex-row flex-wrap items-center gap-3">
          {block?.cta?.map((cta, index) => (
            <CtaLink key={index} cta={cta} />
          ))}
        </div>
      </div>
    </Section>
  )
}

export default IntroDescriptionRecord
