import { ServiceBlock } from '../../types/service-menu-type'
import FilledButton from '../../components/button'

type Props = {
  block: ServiceBlock[]
  selectService: (index: number) => void
}
function ServiceBlockList({ block, selectService }: Props) {
  return (
    <div className="flex flex-row flex-wrap gap-1">
      {block.map((service, index) => (
        <FilledButton
          variant={'primary-outlined'}
          key={service.name}
          onClick={() => selectService(index)}
          text={service.name}
          isSmall
          className="rounded-large border border-r-primary-rystad-blue px-2 py-[12px] text-text-small"
        />
      ))}
    </div>
  )
}

export default ServiceBlockList
