import { useRef } from 'react'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import paragraphScrollingAnimation from './paragraph-scrolling-animation'
import Logo from './logo'
import { ScrollableParagraphsWithLogoRecord } from '../../generated/graphql'

type Props = {
  block: ScrollableParagraphsWithLogoRecord
}

const ScrollableParagraphsWithLogo = ({ block }: Props) => {
  const stage = useRef<HTMLDivElement>(null)
  const paragraphsRef = useRef<(HTMLDivElement | null)[]>([])

  useIsomorphicLayoutEffect(() => {
    paragraphScrollingAnimation(stage, paragraphsRef)
  }, [])

  return (
    <div
      className="relative h-screen items-center justify-center overflow-hidden bg-greys-1 md:flex"
      ref={stage}
    >
      <div className="grid h-11 w-full grid-cols-8 md:-mt-[120px] xl:-mt-11">
        <div className="col-span-8 flex items-center justify-center pt-11 pb-[200px] md:col-span-3 md:col-start-2 md:items-start md:justify-start md:pt-0 md:pb-0">
          <div className="h-[150px] w-[150px] md:h-[230px] md:w-[230px] md:pl-4 lg:h-[260px] lg:w-[260px]">
            <Logo />
          </div>
        </div>
        <div className="relative col-span-8 flex w-full flex-col items-center justify-center px-3 md:col-span-3 md:col-start-5 md:px-0">
          {block.paragraphs?.map((block, i) => (
            <div
              key={block.label}
              ref={(el) => (paragraphsRef.current[i] = el)}
              className="absolute top-0 left-0 w-full px-2 md:px-0"
            >
              <span className="mb-3 block text-text-base">{block.label}</span>
              <p className="text-header-2 xl:text-header-1">
                {block.paragraph}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ScrollableParagraphsWithLogo
