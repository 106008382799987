import { SolutionVideoImageModuleWithSideCaptionRecord } from '../../generated/graphql'
import VideoComponent from '../../components/video-component'
import Section from '../../components/section'
import { Image } from 'react-datocms'

type Props = {
  block: SolutionVideoImageModuleWithSideCaptionRecord
}

function SolutionVideoImageModuleWithSideCaption({ block }: Props) {
  return (
    <Section id={block.slug || ''} classOverrideInnerGrid="grid-flow-dense">
      <div
        className={`col-span-6 ${
          block.alignment ? 'md:col-start-1' : 'md:col-start-3'
        }`}
        id={block.slug ?? ''}
      >
        {block.externalVideoLink ? (
          <VideoComponent
            playText={block.videoHeadline!}
            src={block.externalVideoLink!}
            poster={block.image?.responsiveImage!}
          />
        ) : (
          <Image
            style={{ maxWidth: 'none' }}
            data={block.image?.responsiveImage!}
          />
        )}
      </div>

      <div
        className={`${
          block.alignment ? 'md:col-start-7' : 'md:col-start-1'
        } col-span-2 flex h-full flex-col justify-end`}
      >
        <h3 className={'mb-1 text-header-2'}>{block?.videoHeadline}</h3>
        <p className={'text-text-base'}>{block?.videoDescription}</p>
      </div>
    </Section>
  )
}

export default SolutionVideoImageModuleWithSideCaption
