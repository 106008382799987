type Props = {
  size?: number | string
}

function PlayCircle({ size }: Props) {
  return (
    <svg
      width={size ?? '20'}
      height={size ?? '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M10 18.3125C7.79539 18.3125 5.68107 17.4367 4.12217 15.8778C2.56328 14.3189 1.6875 12.2046 1.6875 10C1.6875 7.79539 2.56328 5.68107 4.12217 4.12217C5.68107 2.56328 7.79539 1.6875 10 1.6875C12.2046 1.6875 14.3189 2.56328 15.8778 4.12217C17.4367 5.68107 18.3125 7.79539 18.3125 10C18.3125 12.2046 17.4367 14.3189 15.8778 15.8778C14.3189 17.4367 12.2046 18.3125 10 18.3125ZM10 19.5C12.5196 19.5 14.9359 18.4991 16.7175 16.7175C18.4991 14.9359 19.5 12.5196 19.5 10C19.5 7.48044 18.4991 5.06408 16.7175 3.28249C14.9359 1.50089 12.5196 0.5 10 0.5C7.48044 0.5 5.06408 1.50089 3.28249 3.28249C1.50089 5.06408 0.5 7.48044 0.5 10C0.5 12.5196 1.50089 14.9359 3.28249 16.7175C5.06408 18.4991 7.48044 19.5 10 19.5Z"
          fill="white"
        />
        <path
          d="M7.94681 6.50283C8.04393 6.45282 8.15296 6.43057 8.26191 6.43853C8.37086 6.44649 8.4755 6.48435 8.56431 6.54795L12.7206 9.5167C12.7975 9.57163 12.8603 9.64414 12.9036 9.7282C12.9468 9.81227 12.9694 9.90546 12.9694 10C12.9694 10.0946 12.9468 10.1878 12.9036 10.2718C12.8603 10.3559 12.7975 10.4284 12.7206 10.4833L8.56431 13.4521C8.47553 13.5156 8.37094 13.5535 8.26205 13.5614C8.15316 13.5694 8.04418 13.5472 7.94709 13.4972C7.85001 13.4473 7.76858 13.3715 7.71175 13.2783C7.65493 13.185 7.62491 13.0779 7.625 12.9688V7.03126C7.62489 6.92211 7.65486 6.81504 7.71164 6.72182C7.76841 6.62859 7.84978 6.55282 7.94681 6.50283Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3199_64054">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PlayCircle
