import { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import { useRouter } from 'next/router'

function usePressReleaseSearchDebouncedValue(
  defaultInputValue?: string,
  isEnabled?: boolean
): [string, (value: string) => void] {
  const router = useRouter()
  const [inputValue, setInputValue] = useState<string>(defaultInputValue ?? '')

  const debouncedEventHandler = useCallback(
    debounce((query) => {
      if (!isEnabled) return
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            s: query,
          },
        },
        undefined,
        { shallow: true, scroll: false }
      )
    }, 800),
    [router.query]
  )

  useEffect(() => {
    if (isEnabled) {
      setInputValue(defaultInputValue ?? '')
    }
  }, [isEnabled])

  useEffect(() => {
    if (!isEnabled) return
    debouncedEventHandler(inputValue)
  }, [inputValue, isEnabled])

  return [inputValue, setInputValue]
}

export default usePressReleaseSearchDebouncedValue
