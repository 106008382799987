import { format } from 'date-fns'
import { enUS, ko, ja, zhCN } from 'date-fns/locale'

const FORMATS = {
  full: 'dd LLLL yyyy',
  fullreverse: 'yyyy LLLL dd',
  fullcomma: 'dd LLLL yyyy,',
  monthyear: 'LLLL yyyy',
}

function formatDate(date: Date, type: 'full' | 'month-year' | 'full-comma') {
  if (type === 'full') {
    return format(new Date(date), FORMATS.full, {
      locale: enUS,
    })
  } else if (type === 'month-year') {
    return format(new Date(date), FORMATS.monthyear, {
      locale: enUS,
    })
  } else if (type === 'full-comma') {
    return format(new Date(date), FORMATS.fullcomma, {
      locale: enUS,
    })
  }
}

export default formatDate

export const formatDateLocale = (date: Date, locale: string) => {
  if (locale === 'ko') {
    return format(new Date(date), FORMATS.fullreverse, {
      locale: ko,
    })
  } else if (locale === 'ja') {
    return format(new Date(date), FORMATS.fullreverse, {
      locale: ja,
    })
  } else if (locale === 'zh') {
    return format(new Date(date), FORMATS.fullreverse, {
      locale: zhCN,
    })
  } else {
    return format(new Date(date), FORMATS.fullreverse, {
      locale: enUS,
    })
  }
}

export const eventFormatDate = (event: any) => {
  // If there are 1 or 2 event sessions, it should use specific date from each session
  // If there are 3 or more event sessions, it should use the global start and end date
  const eventSessionsCount = event?.eventSessions?.length

  if (eventSessionsCount === 1) {
    if (
      formatDate(event?.eventSessions[0]?.startDate, 'full-comma') ===
      formatDate(event?.eventSessions[0]?.endDate, 'full-comma')
    ) {
      return formatDate(event?.eventSessions[0]?.startDate, 'full-comma')
    } else {
      return `${formatDate(
        event?.eventSessions[0]?.startDate,
        'full'
      )} - ${formatDate(event?.eventSessions[0]?.endDate, 'full')}`
    }
  } else if (eventSessionsCount === 2) {
    return null
  } else if (eventSessionsCount >= 3) {
    if (
      formatDate(event?.startDate, 'full-comma') ===
      formatDate(event?.startDate, 'full-comma')
    ) {
      return formatDate(event?.startDate, 'full-comma')
    } else {
      return `${formatDate(event?.startDate, 'full')} - ${formatDate(
        event?.endDate,
        'full'
      )}`
    }
  } else {
    return null
  }
}
