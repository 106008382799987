import { useEffect, useRef } from 'react'
import animateTextReveal from './animate-text-reveal'
import RevealingMap from './revealing-map'
import animateMap from './animate-map'
import animateBackground from './animate-background'
import { CompanyValuesWithMapRecord } from '../../generated/graphql'

type Props = {
  block: CompanyValuesWithMapRecord
}

function ValuesAndRevealingMap({ block }: Props) {
  const container = useRef<HTMLDivElement>(null)
  const stage = useRef<HTMLDivElement>(null)
  const refs = useRef<(HTMLDivElement | null)[]>([])
  const map = useRef<HTMLDivElement>(null)

  const values = block.values?.split(', ') || []

  useEffect(() => {
    animateTextReveal(stage, refs)
    animateMap(map)
    animateBackground(container)
  }, [])

  return (
    <div ref={container} className={'bg-lights-sand'}>
      <div
        ref={stage}
        className={
          'relative flex min-h-screen items-center justify-center text-display-1'
        }
      >
        <span
          className={
            'absolute top-1/3 -translate-y-1/2 font-display text-text-base'
          }
        >
          {block.caption}
        </span>
        <div className={'relative h-[2em] w-full overflow-hidden'}>
          {values.map((value, index) => (
            <p
              key={value}
              ref={(el) => (refs.current[index] = el)}
              className={
                'absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform text-center leading-8'
              }
            >
              {value}
            </p>
          ))}
        </div>
      </div>
      <div
        ref={map}
        className={'h-screen pb-4 pt-11 text-white md:px-2 md:pb-11'}
      >
        <RevealingMap />
      </div>
    </div>
  )
}

export default ValuesAndRevealingMap
