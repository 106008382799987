import Link from 'next/link'
import FancyUnderlinedLabel from '../../../components/fancy-underlined-label'
import { ServiceMenuCustomFieldRecord } from '../../../generated/graphql'

type Props = {
  pages: ServiceMenuCustomFieldRecord[]
  displayProductDemo?: boolean
  underlineColor?: string
}

function CustomPagesBlock({ pages, underlineColor, displayProductDemo }: Props) {

  let filteredPages = pages
  if(!displayProductDemo) {
      filteredPages = pages.filter((item) => !item.pageLink?.title?.includes('demo'))
  }

  return (
    <div
      className="col-span-full col-start-5 grid grid-cols-2 gap-x-5 gap-y-5 border-l-[1px] p-4"
      style={{ borderColor: underlineColor }}
    >
      {filteredPages?.map((item, index) => (
        <Link
          scroll={false}
          href={`${item.link}`}
          className="flex flex-col "
          key={index}
        >
          <a>
            <FancyUnderlinedLabel underlineColor={underlineColor}>
              {item.pageLink?.title}
            </FancyUnderlinedLabel>
            <p className="pt-1 text-text-small">{item.pageLink?.excerpt}</p>
          </a>
        </Link>
      ))}
    </div>
  )
}

export default CustomPagesBlock
