import { useDisplayedPublication } from '../context/use-displayed-publication'

type Props = {
  maxLength: number
}
function MobileDividerAnimation({ maxLength }: Props) {
  const { state, dispatch } = useDisplayedPublication()
  return (
    <div className="relative col-span-full my-5 w-full">
      <div className="absolute top-[1px] h-[1px] w-full bg-greys-1" />
      <div
        onAnimationIteration={() =>
          dispatch({
            type: 'updateDisplayedPublicationIndex',
            index: state.index < maxLength ? state.index + 1 : 0,
          })
        }
        className={
          'group-hover:animation-pause absolute top-[1px] h-[1px] w-[0] animate-width-animation bg-current'
        }
      />
    </div>
  )
}

export default MobileDividerAnimation
