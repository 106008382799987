import React, { useState, useRef, useEffect } from 'react'
import ChevronDown from '../icons/chevron-down'
import * as Select from '@radix-ui/react-select'
import { SelectItem } from './dropdown-select-item'
import * as ScrollArea from '@radix-ui/react-scroll-area'

type Props = {
  label?: string
  error?: string
  placeholder: string
  data: Array<any>
  disabled?: boolean
  onSelect: (value: string) => void
  resetKey?: any
}

const DropdownSelect = ({
  label,
  placeholder,
  onSelect,
  data,
  error,
  disabled,
  resetKey,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState('')
  const TriggerRef = useRef<any>(null)
  const [buttonWidth, setButtonWidth] = useState(0)

  useEffect(() => {
    setButtonWidth(
      TriggerRef && TriggerRef.current && TriggerRef.current.offsetWidth
    )
    const windowResize = () => {
      setButtonWidth(
        TriggerRef && TriggerRef.current && TriggerRef.current.offsetWidth
      )
    }
    window.addEventListener('resize', windowResize, true)
    return () => {
      window.removeEventListener('resize', windowResize, true)
    }
  }, [buttonWidth])

  return (
    <div className="flex w-full flex-col">
      <Select.Root
        key={resetKey}
        disabled={disabled}
        onValueChange={(value) => {
          setSelectedValue(value)
          onSelect(value)
        }}
      >
        {label && label.length > 0 && (
          <span className="text-base flex w-full flex-row  items-center justify-between text-[15px] text-[#292862]">
            {label}
          </span>
        )}
        <Select.Trigger
          ref={TriggerRef}
          className={`${disabled ? 'bg-greys-0' : 'bg-white'} ${
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          } ${
            selectedValue.length > 0 ? 'text-[#292862]' : 'text-greys-4'
          } text-base relative mx-0 my-1 flex h-auto w-[${buttonWidth}px] items-center justify-between rounded rounded-md border border-[#d3d2e2] px-[12px] py-[10px] text-[15px] outline-0 transition-all duration-200 ease-in-out focus:border-[#292862] focus:bg-white focus:outline-0 active:border-[#292862] active:bg-white active:outline-0`}
          aria-label={label}
        >
          <Select.Value placeholder={placeholder} className="text-[#b1b1c0]" />
          <Select.Icon className="text-violet11">
            <ChevronDown />
          </Select.Icon>
        </Select.Trigger>
        {error && error.length > 0 && (
          <span className="text-base text-[11px] text-[#b02a37]">{error}</span>
        )}
        <Select.Portal className="w-full">
          <Select.Content
            style={{ width: `${buttonWidth}px` }}
            alignOffset={0}
            sideOffset={5}
            side={'bottom'}
            align={'start'}
            position={'popper'}
            avoidCollisions={false}
            className="cursor-pointer rounded-md rounded border border-[#d3d2e2] bg-white"
          >
            <ScrollArea.Root type="auto">
              <Select.Viewport asChild className="max-h-[200px]">
                <ScrollArea.Viewport>
                  {data?.map((item) => (
                    <div key={`${item.name}-${item.id}`}>
                      <SelectItem
                        value={item.id.toString()}
                        className={
                          selectedValue === item.id.toString()
                            ? 'text-[#292862]'
                            : 'text-greys-4'
                        }
                      >
                        {item.state &&
                        item.country &&
                        (item.country.name === 'United States' ||
                          item.country.name === 'Canada')
                          ? `${item.name}, ${item.state.name}`
                          : item.name ?? placeholder}
                      </SelectItem>
                    </div>
                  ))}
                </ScrollArea.Viewport>
              </Select.Viewport>
              <ScrollArea.Scrollbar
                orientation="vertical"
                className="bg-text-greys-4"
                style={{
                  width: '6px',
                  backgroundColor: '#c8c8cf',
                  padding: '1px',
                }}
              >
                <ScrollArea.Thumb
                  style={{
                    borderRadius: '5px',
                    backgroundColor: '#292862',
                  }}
                />
              </ScrollArea.Scrollbar>
            </ScrollArea.Root>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  )
}

export default DropdownSelect
