import {
  DirectUploadOrExternalLinkInsightRecord,
  InsightRecord,
  PressReleaseRecord,
  SolutionRelatedInsightModelRelatedInsightsField,
} from '../../generated/graphql'

export function isInsightRecord(
  insight: SolutionRelatedInsightModelRelatedInsightsField
): insight is InsightRecord {
  return insight?.__typename === 'InsightRecord'
}

export function isPressReleaseRecord(
  record: SolutionRelatedInsightModelRelatedInsightsField
): record is PressReleaseRecord {
  return record?.__typename === 'PressReleaseRecord'
}

export function isDirectUploadOrExternalLinkInsightRecord(
  record:
    | DirectUploadOrExternalLinkInsightRecord
    | SolutionRelatedInsightModelRelatedInsightsField
): record is DirectUploadOrExternalLinkInsightRecord {
  return record?.__typename === 'DirectUploadOrExternalLinkInsightRecord'
}
