import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useMemo } from 'react'
import Accordion from '../../components/accordion'
import Section from '../../components/section'
import { SolutionFilterTypes } from '../../enums/solution-filter-types'
import { IndustryEnergyPageSolutionGridRecord } from '../../generated/graphql'
import DownArrow from '../../icons/down-arrow'
import ALL_SOLUTION from '../../lib/queries/all-solutions'
import { AllService } from '../../types/service-menu-type'
import { SolutionFilters } from '../../types/SolutionFilters'
import { SolutionQueryProps } from '../../types/SolutionQueryProps'
import { SolutionQueryTypeAndValue } from '../../types/SolutionQueryTypeAndValue'
import { filterServices, getAllServices } from '../../utils/filter-service-menu'
import FilterItem from './filter-item'
import ServiceBlock from './service-block'
import useFilterRouter from './use-filter-router'
import useMultipleFilterClear from './use-multiple-filter-clear'
import useServicesCounter from './use-services-counter'

type Props = {
  block: IndustryEnergyPageSolutionGridRecord
}

function handleFirstSelectedQuery(
  query: ParsedUrlQuery,
  queryTypeAndValue: SolutionQueryTypeAndValue,
  filters: SolutionFilters
) {
  const filter = filters[queryTypeAndValue.type]

  if (filter === queryTypeAndValue.value) {
    const { industry, energy, energyTheme, ...queryWithoutField } = query

    return Object.keys(queryWithoutField).length
      ? queryWithoutField
      : { filter: '' }
  } else {
    const filteredQuery = { ...query }
    delete filteredQuery['filter']

    if (queryTypeAndValue.type !== SolutionFilterTypes.INDUSTRY)
      delete filteredQuery[SolutionFilterTypes.INDUSTRY]
    if (queryTypeAndValue.type !== SolutionFilterTypes.ENERGY)
      delete filteredQuery[SolutionFilterTypes.ENERGY]
    if (queryTypeAndValue.type !== SolutionFilterTypes.ENERGY_THEME)
      delete filteredQuery[SolutionFilterTypes.ENERGY_THEME]

    let resultQuery: SolutionQueryProps = filteredQuery
    resultQuery[queryTypeAndValue.type] = queryTypeAndValue.value
    return resultQuery
  }
}

function IndustryEnergyPageSolutionGridRecord({ block }: Props) {
  const router = useRouter()
  const { loading, error, data } = useQuery(ALL_SOLUTION)
  const { firstSelected, ...filters } = useFilterRouter()
  const counter = useServicesCounter(data?.allSolutionPages)
  const multipleFilterClear = useMultipleFilterClear()

  function handleSelectFilterItem(type: SolutionFilterTypes, value: string) {
    const queryTypeAndValue: SolutionQueryTypeAndValue = {
      type,
      value,
    }

    if (firstSelected === type) {
      const query: SolutionQueryProps = handleFirstSelectedQuery(
        router.query,
        queryTypeAndValue,
        filters
      )

      router.replace({
        pathname: router.pathname,
        query: {
          ...query,
        },
      })
    } else if (firstSelected !== '' && firstSelected !== type) {
      multipleFilterClear(queryTypeAndValue, filters)
    }

    router.replace(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          [type]: value,
        },
      },
      undefined,
      { shallow: true, scroll: false }
    )
  }

  const filteredData: AllService = useMemo(() => {
    if (!data)
      return {
        solutions: [],
        availableEnergies: [],
        availableIndustries: [],
        availableEnergyThemes: [],
      }

    return filterServices({
      services: getAllServices(data?.allSolutionPages).solutions,
      industry: filters[SolutionFilterTypes.INDUSTRY],
      energy: filters[SolutionFilterTypes.ENERGY],
      energyTheme: filters[SolutionFilterTypes.ENERGY_THEME],
      firstSelected: firstSelected ?? '',
    })
  }, [data, filters, firstSelected])

  const accordionItems = [
    {
      id: '0',
      label: 'All industries',
      content: (
        <FilterItem
          selected={filters[SolutionFilterTypes.INDUSTRY]}
          type={SolutionFilterTypes.INDUSTRY}
          items={filteredData.availableIndustries}
          selectItem={handleSelectFilterItem}
        />
      ),
    },
    {
      id: '1',
      label: 'All energy types',
      content: (
        <FilterItem
          selected={filters[SolutionFilterTypes.ENERGY]}
          type={SolutionFilterTypes.ENERGY}
          selectItem={handleSelectFilterItem}
          items={filteredData.availableEnergies}
        />
      ),
    },
    {
      id: '2',
      label: 'All energy themes',
      content: (
        <FilterItem
          selected={filters[SolutionFilterTypes.ENERGY_THEME]}
          selectItem={handleSelectFilterItem}
          type={SolutionFilterTypes.ENERGY_THEME}
          items={filteredData.availableEnergyThemes}
        />
      ),
    },
  ]

  const pageName = router.pathname.split('/')[2]
  const filteredAccordionItem = accordionItems.filter((item) => {
    if (pageName === 'industry') {
      return item.label === 'All energy types'
    } else if (pageName === 'energytype') {
      return item.label === 'All industries'
    }
    return true
  })

  return (
    <Section>
      <div className="col-span-full col-start-1 flex flex-col lg:col-span-2">
        <div className="mt-1 flex flex-row gap-1">
          <h1 className="text-header-1">{block.headline}</h1>
          {counter!! > 0 && (
            <sup className="text-[0.9rem] text-current">{counter}</sup>
          )}
        </div>
        <div className="pt-5 lg:sticky lg:bottom-[0] lg:mt-auto">
          <Accordion
            accordionItems={filteredAccordionItem}
            openIcon={<DownArrow />}
            closedIcon={<DownArrow />}
          />
        </div>
      </div>
      <div className="col-span-full lg:col-start-3">
        {loading && <div>Loading...</div>}
        <ServiceBlock solutions={filteredData.solutions} />
      </div>
    </Section>
  )
}

export default IndustryEnergyPageSolutionGridRecord
