import Section from '../../components/section'
import { ContactPageContactInformationPerBusinessAreaModuleRecord } from '../../generated/graphql'
import ContactBusinessAreaModel from './contact-business-area-model'

type Props = {
  block: ContactPageContactInformationPerBusinessAreaModuleRecord
}

function ContactPageContactInformationPerBusinessAreaModule({ block }: Props) {
  return (
    <Section>
      <>
        {block.businessAreas.map((business) => (
          <ContactBusinessAreaModel key={business.id} business={business} />
        ))}
      </>
    </Section>
  )
}

export default ContactPageContactInformationPerBusinessAreaModule
