import useIsomorphicLayoutEffect from './use-isomorphic-layout-effect'
import { useRef } from 'react'
import throttle from 'lodash.throttle'

const DEFAULT_THROTTLE_IN_MS = 1000

function useThrottledBodyScroll(
  cb: (dir: 'up' | 'down', isAtTheTop: boolean) => void,
  throttleInMs?: number
) {
  const scrollY = useRef<number>(0)

  useIsomorphicLayoutEffect(() => {
    addEventListener(
      'scroll',
      throttle(function () {
        const isUp = window.scrollY - scrollY.current < 0
        cb(isUp ? 'up' : 'down', window.scrollY === 0)
        scrollY.current = window.scrollY
      }, throttleInMs ?? DEFAULT_THROTTLE_IN_MS)
    )
  }, [])
}

export default useThrottledBodyScroll
