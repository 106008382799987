import { StructuredText as DatoStructuredText } from 'react-datocms'
import ImageVideoBlock from '../blocks/image-video-block'
import isImageVideoRecord from '../types/is-image-video-block'

type Props = {
  data: any
}

function StructuredText({ data }: Props) {
  return (
    <div
      className={
        'prose:text-current prose-a:text-primary-current prose max-w-none prose-headings:my-1 prose-headings:font-display prose-headings:text-current prose-p:mb-3 prose-p:text-text-base prose-p:text-current prose-strong:text-current prose-li:-mt-2 prose-li:marker:text-current prose-img:my-0 prose-video:mt-[0]'
      }
    >
      <DatoStructuredText
        data={data}
        renderBlock={({ record }) => {
          if (isImageVideoRecord(record)) {
            return <ImageVideoBlock block={record} />
          }

          return null
        }}
      />
    </div>
  )
}

export default StructuredText
