import Link from 'next/link'
import MenuLeftArrow from '../../../icons/menu-left-arrow'
import { useMenuStatus } from '../context'
import { Solution } from '../../../types/service-menu-type'

type Props = {
  items?: Solution[]
  selectedText: string
}
function MobileSolutionsBlock({ items, selectedText }: Props) {
  const { dispatch } = useMenuStatus()
  return (
    <>
      <div
        className={
          'col-span-full mb-3 flex flex-row items-center justify-between'
        }
      >
        <button
          onClick={() =>
            dispatch({ type: 'updateSecondBlockIndex', index: -1 })
          }
          className={'flex flex-row items-center gap-1 text-text-base'}
        >
          <MenuLeftArrow />
          Back
        </button>
        <span className={'text-text-base'}>{selectedText}</span>
      </div>
      <div className="col-span-full flex flex-col gap-1 md:col-span-4">
        <div className="mb-1 px-2 text-text-small text-greys-3">Solutions</div>
        {items?.map((item, index) => (
          <Link scroll={false} key={index} href={`/services/${item.slug}`}>
            <a className="flex flex-col p-2 hover:bg-greys-1">
              <span className="text-text-base">{item.name}</span>
              <p className="text-text-small">{item.excerpt}</p>
            </a>
          </Link>
        ))}
      </div>
    </>
  )
}

export default MobileSolutionsBlock
