import { TopModuleWithIntroDescriptionAndVideoImageModuleModelIntroDescriptionBlockAndVideoImageModuleField } from '../../generated/graphql'
import OverVideoIntroDescriptionRecord from '../intro-description-record/over-video-intro-description-record'
import PeopleVideoModule from '../people-video-module'

type Props = {
  block: TopModuleWithIntroDescriptionAndVideoImageModuleModelIntroDescriptionBlockAndVideoImageModuleField
}

function TopModuleWithIntroDescriptionAndVideoImageContainer({ block }: Props) {
  if (block.__typename == 'CategoryPageIntroDescriptionRecord') {
    return <OverVideoIntroDescriptionRecord block={block} />
  }
  if (block.__typename == 'PeopleCareerVideoImageModuleRecord') {
    return <PeopleVideoModule block={block} />
  }

  return <></>
}

export default TopModuleWithIntroDescriptionAndVideoImageContainer
