import Section from '../../components/section'
import StructuredText from '../../components/structured-text'
import { InsightArticleRecord } from '../../generated/graphql'
import useIsPaywallKeyUnlockedAllowed from '../../hooks/use-is-paywall-key-unlocked'
import {
  useInsightIsSecure,
  useInsightKey,
} from '../../contexts/insight-is-secure-context'

type Props = {
  block: InsightArticleRecord
}
function InsightArticleRecord({ block }: Props) {
  const isSecure = useInsightIsSecure()
  const insightKey = useInsightKey()
  const isVisible = useIsPaywallKeyUnlockedAllowed(insightKey)

  if (isSecure && !isVisible) {
    return null
  }

  return (
    <Section>
      <div className="col-span-full items-start pb-11 md:col-span-5">
        <StructuredText data={block.articleText} />
      </div>
    </Section>
  )
}

export default InsightArticleRecord
