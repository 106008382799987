import { useEffect, useRef, useState } from 'react'
import { Image, ResponsiveImageType } from 'react-datocms'

type Props = {
  videoSrc: string
  fallBackImage: ResponsiveImageType
}
function AutoplayVideo({ videoSrc, fallBackImage }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isAutoPlayFailed, setIsAutoPlayFailed] = useState(false)
  useEffect(() => {
    videoRef.current?.play().catch((e) => setIsAutoPlayFailed(true))
  }, [])

  return (
    <>
      {isAutoPlayFailed ? (
        <Image
          style={{ maxWidth: 'none' }}
          className="h-full w-full"
          objectFit="cover"
          data={fallBackImage}
        />
      ) : (
        <video
          ref={videoRef}
          playsInline
          muted
          loop
          className="h-full w-full object-cover"
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      )}
    </>
  )
}

export default AutoplayVideo
