import { TwoColumnsRichTextWidgetRecord } from '../../generated/graphql'
import { StructuredText as DatoStructuredText } from 'react-datocms'
import CtaLink from '../../components/cta-link'

type Props = {
  block: TwoColumnsRichTextWidgetRecord
}

const TwoColumnsRichTextWidget = ({ block }: Props) => {
  // imageColumn  TRUE = image left, FALSE = image right
  const { image, content, imageColumn, links, slug } = block

  return (
    <div className="w-full py-8" id={slug || ''}>
      <div className="mx-auto grid min-h-screen max-w-fullbleed grid-flow-dense gap-4 px-2 md:grid-cols-2 md:px-5">
        <div className={`${imageColumn ? 'md:col-start-1' : 'md:col-start-2'}`}>
          <img src={image?.url} alt="" className="w-full py-4" />
        </div>
        <div
          className={`${
            imageColumn ? 'md:col-start-2' : 'md:col-start-1'
          } col-span-1 flex flex-col justify-center md:py-5 md:px-5`}
        >
          <div
            className={`pb-3 prose-headings:pb-3 prose-headings:text-header-1 prose-h6:text-text-manchet prose-p:pb-2 prose-p:text-text-base`}
          >
            <DatoStructuredText data={content?.value} />
          </div>
          <div>
            {links.map((link) => (
              <div key={link.id} className="mb-2">
                <CtaLink cta={link} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TwoColumnsRichTextWidget
