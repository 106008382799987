import Section from '../../components/section'
import { FrontPageKeyNewsElementRecord } from '../../generated/graphql'
import BottomArticleList from './bottom-article-list/bottom-article-list'
import UpperDisplayedArticle from './upper-displayed-artice'

type Props = {
  block: FrontPageKeyNewsElementRecord|any
}

function FrontPageKeyNewsElementContainer({ block }: Props) {
  return (
    <Section classOverride="py-7 md:py-4">
      <UpperDisplayedArticle block={block} />
      {block.publications?.length > 1 && (
        <BottomArticleList publications={block.publications} />
      )}
    </Section>
  )
}

export default FrontPageKeyNewsElementContainer
