import { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'

function useDebouncedValue(
  cb: (value: string) => void,
  defaultInputValue?: string,
  isEnabled?: boolean
): [string, (value: string) => void] {
  const [inputValue, setInputValue] = useState<string>(defaultInputValue ?? '')

  const debouncedEventHandler = useCallback(
    debounce((query) => {
      if (!isEnabled) return
      cb(query)
    }, 800),
    []
  )

  useEffect(() => {
    if (isEnabled) {
      setInputValue(defaultInputValue ?? '')
    }
  }, [isEnabled])

  useEffect(() => {
    if (!isEnabled) return
    debouncedEventHandler(inputValue)
  }, [inputValue, isEnabled])

  return [inputValue, setInputValue]
}

export default useDebouncedValue
