import { format } from 'date-fns'
import EventListItem from '../types/EventListItem'
import _ from 'lodash'

function mapEventsToMonths(
  events: EventListItem[]
): Record<string, EventListItem[]> {
  if (!events) return {}
  const _data: Record<string, EventListItem[]> = {}

  const eventsWithModifiedMonths = events.map((event) => {
    const trimmedStartDate = format(new Date(event.startDate), 'MMMM yyyy')
    return {
      ...event,
      trimmedStartDate,
    }
  })

  const eventsSlicedByMonths = _.chain(eventsWithModifiedMonths)
    .groupBy('trimmedStartDate')
    .map((key: any, value: any) => ({
      date: value,
      items: key,
    }))
    .value()

  eventsSlicedByMonths.forEach((event) => {
    _data[event.date] = event.items
  })

  return _data
}

export default mapEventsToMonths
