import _ from 'lodash'
import { InsightRecord } from '../../generated/graphql'
import InsightGridItem from './insight-grid-item'

type Props = {
  monthlyData: Record<string, InsightRecord[]>
}

function InsightGridContainer({ monthlyData }: Props) {
  return (
    <>
      {Object.keys(monthlyData).map((month) => (
        <div className={'relative z-10 col-span-8'} key={month}>
          <div
            className={
              'top-4 col-span-6 hidden text-header-2-5 text-current sm:col-span-2 md:sticky md:block'
            }
          >
            <h2 className="relative top-10">{month}</h2>
          </div>
          <div
            className={
              'col-span-6 mb-3 grid grid-cols-1 gap-5 md:grid-cols-2 md:pl-[26%]'
            }
          >
            {!_.isEmpty(monthlyData) &&
              monthlyData?.[month].map((insight) => (
                <InsightGridItem key={insight.id} insight={insight} />
              ))}
          </div>
        </div>
      ))}
    </>
  )
}

export default InsightGridContainer
