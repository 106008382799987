import { AnimationTextBlockRecord } from '../../../generated/graphql'

type Props = {
  services: AnimationTextBlockRecord[]
}

function ServiceLabels({ services }: Props) {
  return (
    <div className={'relative min-h-[10rem] text-white'}>
      <div className={'mb-3 flex gap-2 px-2 text-text-x-small'}>
        <div className={'stage-1'}>.01</div>
        <div className={'stage-2'}>.02</div>
        <div className={'stage-3'}>.03</div>
        <div className={'stage-4'}>.04</div>
      </div>
      {services.map((service, index) => (
        <div
          key={service.id}
          className={`col-span-2s absolute px-2 label-${index + 1}`}
        >
          <h3 className={'mb-1 text-text-base font-bold'}>
            {service.headline}
          </h3>
          <p className={'text-text-base'}>{service.description}</p>
        </div>
      ))}
    </div>
  )
}

export default ServiceLabels
