import getRandomInt from '../../utils/get-random-int'
import getRandomNumberBetween from '../../utils/get-random-number-between'

const TW_COLORS = ['bg-white', 'bg-primary-orange', 'bg-primary-rystad-blue']

function getDots(numberOfDots: number) {
  const emptyArray = new Array(numberOfDots).fill(0)

  return emptyArray.map(() => ({
    x: getRandomNumberBetween(0, window.innerWidth),
    y: getRandomNumberBetween(0, window.innerHeight) - 10,
    opacity: getRandomNumberBetween(0.5, 1),
    scale: getRandomNumberBetween(0.5, 1),
    color: TW_COLORS[getRandomInt(2)],
  }))
}

export default getDots
