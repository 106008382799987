import { useRef, useState } from 'react'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import getDots from './get-dots'
import animateStarField from './animate-star-field'
import animateCursorFollowing from './animate-cursor-following'
import { UniverseAnimationHeroRecord } from '../../generated/graphql'
import useMediaQuery from '../../hooks/use-media-query'
import useEventListener from '../../hooks/use-event-listener'
import Dot from '../../types/Dot'

type Props = {
  block: UniverseAnimationHeroRecord
}

function UniverseAnimationHero({ block }: Props) {
  const stage = useRef<HTMLDivElement>(null)
  const [dots, setDots] = useState<Dot[]>([])
  const isSmallScreen = useMediaQuery('(max-width: 600px)')

  useEventListener('resize', (e) => {
    setDots(getDots(isSmallScreen ? 40 : 100))
  })

  useIsomorphicLayoutEffect(() => {
    setDots(getDots(isSmallScreen ? 40 : 100))
  }, [setDots, isSmallScreen])

  useIsomorphicLayoutEffect(() => {
    if (!dots.length) return
    animateStarField(stage)
  }, [dots])

  return (
    <div
      ref={stage}
      onMouseMove={(event) => {
        animateCursorFollowing(stage, dots, event)
      }}
      className={'relative min-h-screen overflow-hidden bg-primary-rystad-blue'}
    >
      <div className={'mx-auto flex h-screen items-center justify-center'}>
        <div className={'max-w-[30em]'}>
          <p className={'mb-6 text-center text-text-base text-white'}>
            {block.preTitle}
          </p>
          <h1 className={'text-center text-display-1 text-white'}>
            {block.title}
          </h1>
        </div>
      </div>

      {dots.map((dot) => (
        <div
          key={dot.x + ' ' + dot.y}
          style={{
            left: dot.x,
            top: dot.y,
            opacity: dot.opacity,
            transform: `scale(${dot.scale})`,
          }}
          className={`circle absolute h-1 w-1 transform rounded-full ${dot.color}`}
        />
      ))}
    </div>
  )
}

export default UniverseAnimationHero
