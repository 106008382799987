import { gsap, ScrollTrigger } from '../../lib/gsap'
import { RefObject } from 'react'

function animateSentences(
  stage: RefObject<HTMLDivElement>,
  sentenceRefs: RefObject<(HTMLSpanElement | null)[]>
) {
  ScrollTrigger.matchMedia({
    '(min-width: 600px)': function () {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: stage.current,
          start: 'top top',
          end: '200%',
          scrub: true,
          pin: true,
          refreshPriority: 1000,
          anticipatePin: 1,
        },
      })

      sentenceRefs.current?.forEach((sentence, index) => {
        tl.to(sentence, {
          opacity: 1,
        }).to(
          sentenceRefs.current?.[index - 1] || sentence,
          {
            opacity: 0.4,
          },
          '<'
        )
      })
    },

    '(max-width: 599px)': function () {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: stage.current,
          start: 'top top',
          end: '230%',
          scrub: true,
          pin: true,
          refreshPriority: 1000,
          anticipatePin: 1,
        },
      })

      sentenceRefs.current?.forEach((sentence, index) => {
        tl.to(sentence, {
          opacity: 1,
        }).to(
          sentenceRefs.current?.[index - 1] || sentence,
          {
            opacity: 0.4,
          },
          '<'
        )
      })
    },
  })
}

export default animateSentences
