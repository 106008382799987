function AnimatingDots() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 1440 800" fill="none">
      <circle
        className={'particle'}
        cx="195.688"
        cy="8.97439"
        r="2.38"
        fill="#6233C4"
      />
      <circle
        className={'particle'}
        opacity="0.84"
        cx="307.553"
        cy="76.371"
        r="1.914"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.72"
        cx="519.772"
        cy="65.3654"
        r="1.92"
        fill="#B08AFF"
      />
      <circle
        className={'particle'}
        opacity="0.89"
        cx="645.697"
        cy="3.14968"
        r="2.0532"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="985.277"
        cy="56.1502"
        r="2.304"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.96"
        cx="1150.83"
        cy="111.569"
        r="2.808"
        fill="#B08AFF"
      />
      <circle
        className={'particle'}
        cx="1253.71"
        cy="53.8549"
        r="3.6096"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.8"
        cx="49.3195"
        cy="146.354"
        r="1.2006"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.73"
        cx="292.217"
        cy="203.021"
        r="1.0788"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="500.996"
        cy="118.877"
        r="1.5138"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.95"
        cx="705.749"
        cy="137.506"
        r="1.9"
        fill="#504F9F"
      />
      <circle
        className={'particle'}
        opacity="0.71"
        cx="973.862"
        cy="213.775"
        r="1.7748"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.83"
        cx="1135.55"
        cy="173.29"
        r="1.584"
        fill="#B08AFF"
      />
      <circle
        className={'particle'}
        opacity="0.86"
        cx="1428.09"
        cy="220.432"
        r="1.9836"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="197.692"
        cy="295.569"
        r="3.264"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.84"
        cx="209.54"
        cy="238.72"
        r="2.001"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="615.038"
        cy="288.023"
        r="4.5312"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.88"
        cx="645.229"
        cy="270.723"
        r="1.653"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.9"
        cx="910.514"
        cy="249.378"
        r="1.752"
        fill="#B08AFF"
      />
      <circle
        className={'particle'}
        cx="1233.75"
        cy="272.506"
        r="3.648"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1421.93"
        cy="240.747"
        r="2.044"
        fill="#6233C4"
      />
      <circle
        className={'particle'}
        cx="3.80692"
        cy="350.249"
        r="3.8016"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="339.374"
        cy="396.767"
        r="4.3008"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.74"
        cx="539.099"
        cy="378.984"
        r="1.425"
        fill="#504F9F"
      />
      <circle
        className={'particle'}
        opacity="0.74"
        cx="746.74"
        cy="428.104"
        r="2.185"
        fill="#504F9F"
      />
      <circle
        className={'particle'}
        opacity="0.77"
        cx="845.79"
        cy="386.996"
        r="1.4964"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.97"
        cx="1120.61"
        cy="393.953"
        r="1.482"
        fill="#504F9F"
      />
      <circle
        className={'particle'}
        opacity="0.75"
        cx="1430.47"
        cy="382.905"
        r="1.482"
        fill="#504F9F"
      />
      <circle
        className={'particle'}
        cx="98.1377"
        cy="565.863"
        r="4.1472"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="375.375"
        cy="539.721"
        r="2.3808"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="557.034"
        cy="514.021"
        r="3.84"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="665.817"
        cy="552.804"
        r="3.304"
        fill="#6233C4"
      />
      <circle
        className={'particle'}
        opacity="0.82"
        cx="1004.71"
        cy="538.43"
        r="2.352"
        fill="#B08AFF"
      />
      <circle
        className={'particle'}
        opacity="0.99"
        cx="1112.93"
        cy="457.742"
        r="1.7574"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1280.74"
        cy="464.464"
        r="1.672"
        fill="#504F9F"
      />
      <circle
        className={'particle'}
        cx="55.6373"
        cy="595.931"
        r="1.708"
        fill="#6233C4"
      />
      <circle
        className={'particle'}
        cx="259.476"
        cy="664.849"
        r="2.548"
        fill="#6233C4"
      />
      <circle
        className={'particle'}
        opacity="0.85"
        cx="495.406"
        cy="642.98"
        r="1.2354"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="709.177"
        cy="647.844"
        r="3.4176"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.9"
        cx="881.572"
        cy="600.512"
        r="2.109"
        fill="#504F9F"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1052.48"
        cy="627.61"
        r="1.2876"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1299.27"
        cy="642.48"
        r="2.5728"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.99"
        cx="41.723"
        cy="723.999"
        r="2.016"
        fill="#B08AFF"
      />
      <circle
        className={'particle'}
        cx="403.601"
        cy="762.474"
        r="1.824"
        fill="#504F9F"
      />
      <circle
        className={'particle'}
        cx="451.505"
        cy="772.135"
        r="3.7632"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.72"
        cx="802.291"
        cy="763.825"
        r="1.4964"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.84"
        cx="988.626"
        cy="698.005"
        r="2.16"
        fill="#B08AFF"
      />
      <circle
        className={'particle'}
        cx="1163.13"
        cy="717.131"
        r="3.108"
        fill="#6233C4"
      />
      <circle
        className={'particle'}
        opacity="0.84"
        cx="1347.48"
        cy="764.91"
        r="2.064"
        fill="#B08AFF"
      />
      <circle
        className={'particle'}
        cx="1132.21"
        cy="457.939"
        r="3"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="1109.87"
        cy="355.233"
        r="2.36"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1121.25"
        cy="341.014"
        r="1.92"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.92"
        cx="1134.74"
        cy="343.631"
        r="2.22"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="1136.88"
        cy="282.24"
        r="2.28"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1149.09"
        cy="317.918"
        r="1.82"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="1120.06"
        cy="383.713"
        r="2.3"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="1116.74"
        cy="378.473"
        r="2.04"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1133.28"
        cy="433.3"
        r="2.61"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.97"
        cx="1145.08"
        cy="404.973"
        r="1.52"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1147.57"
        cy="402.021"
        r="1.86"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1112.91"
        cy="531.98"
        r="2.13"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1120.25"
        cy="546.456"
        r="1.96"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1131.16"
        cy="539.838"
        r="3.18"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1138.2"
        cy="489.933"
        r="2.64"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1157.78"
        cy="464.492"
        r="1.62"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1115.99"
        cy="621.688"
        r="2.16"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="1120.22"
        cy="555.031"
        r="1.38"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1131.72"
        cy="640.251"
        r="3.27"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1149.39"
        cy="611.351"
        r="2.28"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="1152.12"
        cy="583.373"
        r="1.8"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1110.78"
        cy="702.641"
        r="3.12"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1121.28"
        cy="652.597"
        r="2.16"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1137.18"
        cy="694.312"
        r="1.8"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1149.31"
        cy="713.481"
        r="3.21"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        opacity="0.72"
        cx="1155.76"
        cy="684.998"
        r="1.82"
        fill="#5C75FF"
      />
      <circle
        className={'particle'}
        cx="1040.21"
        cy="457.939"
        r="3"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="1017.87"
        cy="355.233"
        r="2.36"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1029.25"
        cy="341.014"
        r="1.92"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.92"
        cx="1042.74"
        cy="343.631"
        r="2.22"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="1044.88"
        cy="282.24"
        r="2.28"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1057.09"
        cy="317.918"
        r="1.82"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="1028.06"
        cy="383.713"
        r="2.3"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="1024.74"
        cy="378.473"
        r="2.04"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1041.28"
        cy="433.3"
        r="2.61"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.97"
        cx="1053.08"
        cy="404.973"
        r="1.52"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1055.57"
        cy="402.021"
        r="1.86"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1020.91"
        cy="531.98"
        r="2.13"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1028.25"
        cy="546.456"
        r="1.96"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1039.16"
        cy="539.838"
        r="3.18"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1046.2"
        cy="489.933"
        r="2.64"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1065.78"
        cy="464.492"
        r="1.62"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1023.99"
        cy="621.688"
        r="2.16"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="1028.22"
        cy="555.031"
        r="1.38"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1039.72"
        cy="640.251"
        r="3.27"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1057.39"
        cy="611.351"
        r="2.28"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="1060.12"
        cy="583.373"
        r="1.8"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1018.78"
        cy="702.641"
        r="3.12"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1029.28"
        cy="652.597"
        r="2.16"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1045.18"
        cy="694.312"
        r="1.8"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1057.31"
        cy="713.481"
        r="3.21"
        fill="#484893"
      />
      <circle
        className={'particle'}
        opacity="0.72"
        cx="1063.76"
        cy="684.998"
        r="1.82"
        fill="#484893"
      />
      <circle
        className={'particle'}
        cx="1223.21"
        cy="457.939"
        r="3"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="1200.87"
        cy="355.233"
        r="2.36"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1212.25"
        cy="341.014"
        r="1.92"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.92"
        cx="1225.74"
        cy="343.631"
        r="2.22"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="1227.88"
        cy="282.24"
        r="2.28"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1240.09"
        cy="317.918"
        r="1.82"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="1211.06"
        cy="383.713"
        r="2.3"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="1207.74"
        cy="378.473"
        r="2.04"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1224.28"
        cy="433.3"
        r="2.61"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.97"
        cx="1236.08"
        cy="404.973"
        r="1.52"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1238.57"
        cy="402.021"
        r="1.86"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1203.91"
        cy="531.98"
        r="2.13"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1211.25"
        cy="546.456"
        r="1.96"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1222.16"
        cy="539.838"
        r="3.18"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1229.2"
        cy="489.933"
        r="2.64"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1248.78"
        cy="464.492"
        r="1.62"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1206.99"
        cy="621.688"
        r="2.16"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="1211.22"
        cy="555.031"
        r="1.38"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1222.72"
        cy="640.251"
        r="3.27"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1240.39"
        cy="611.351"
        r="2.28"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="1243.12"
        cy="583.373"
        r="1.8"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1201.78"
        cy="702.641"
        r="3.12"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1212.28"
        cy="652.597"
        r="2.16"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1228.18"
        cy="694.312"
        r="1.8"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1240.31"
        cy="713.481"
        r="3.21"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        opacity="0.72"
        cx="1246.76"
        cy="684.998"
        r="1.82"
        fill="#C869B1"
      />
      <circle
        className={'particle'}
        cx="1319.21"
        cy="457.939"
        r="3"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="1296.87"
        cy="355.233"
        r="2.36"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1308.25"
        cy="341.014"
        r="1.92"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.92"
        cx="1321.74"
        cy="343.631"
        r="2.22"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="1323.88"
        cy="282.24"
        r="2.28"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1336.09"
        cy="317.918"
        r="1.82"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="1307.06"
        cy="383.713"
        r="2.3"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="1303.74"
        cy="378.473"
        r="2.04"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1320.28"
        cy="433.3"
        r="2.61"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.97"
        cx="1332.08"
        cy="404.973"
        r="1.52"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1334.57"
        cy="402.021"
        r="1.86"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1299.91"
        cy="531.98"
        r="2.13"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1307.25"
        cy="546.456"
        r="1.96"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1318.16"
        cy="539.838"
        r="3.18"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1325.2"
        cy="489.933"
        r="2.64"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1344.78"
        cy="464.492"
        r="1.62"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1302.99"
        cy="621.688"
        r="2.16"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="1307.22"
        cy="555.031"
        r="1.38"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1318.72"
        cy="640.251"
        r="3.27"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1336.39"
        cy="611.351"
        r="2.28"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="1339.12"
        cy="583.373"
        r="1.8"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1297.78"
        cy="702.641"
        r="3.12"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1308.28"
        cy="652.597"
        r="2.16"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1324.18"
        cy="694.312"
        r="1.8"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1336.31"
        cy="713.481"
        r="3.21"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.72"
        cx="1342.76"
        cy="684.998"
        r="1.82"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1319.21"
        cy="225.939"
        r="3"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="1296.87"
        cy="123.233"
        r="2.36"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1308.25"
        cy="109.014"
        r="1.92"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.92"
        cx="1321.74"
        cy="111.631"
        r="2.22"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="1323.88"
        cy="50.2399"
        r="2.28"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1336.09"
        cy="85.9177"
        r="1.82"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="1307.06"
        cy="151.713"
        r="2.3"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="1303.74"
        cy="146.473"
        r="2.04"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1320.28"
        cy="201.3"
        r="2.61"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.97"
        cx="1332.08"
        cy="172.973"
        r="1.52"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1334.57"
        cy="170.021"
        r="1.86"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1299.91"
        cy="299.98"
        r="2.13"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="1307.25"
        cy="314.456"
        r="1.96"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1318.16"
        cy="307.838"
        r="3.18"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1325.2"
        cy="257.933"
        r="2.64"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="1344.78"
        cy="232.492"
        r="1.62"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1302.99"
        cy="389.688"
        r="2.16"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="1307.22"
        cy="323.031"
        r="1.38"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1318.72"
        cy="408.251"
        r="3.27"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1336.39"
        cy="379.351"
        r="2.28"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="1339.12"
        cy="351.373"
        r="1.8"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1297.78"
        cy="470.641"
        r="3.12"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1308.28"
        cy="420.597"
        r="2.16"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1324.18"
        cy="462.312"
        r="1.8"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        cx="1336.31"
        cy="481.481"
        r="3.21"
        fill="#9576E0"
      />
      <circle
        className={'particle'}
        opacity="0.72"
        cx="1342.76"
        cy="452.998"
        r="1.82"
        fill="#9576E0"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="713.004"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="713.004"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="713.004"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="713.004"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="713.004"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="713.004"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="495.176"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="495.176"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="495.176"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="495.176"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="495.176"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="495.176"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="277.348"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="277.348"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="604.09"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="604.09"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="604.09"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="604.09"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="604.09"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="604.09"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="386.262"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="386.262"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="386.262"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="386.262"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="386.262"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="386.262"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="249.955"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="168.434"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="658.547"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="658.547"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="658.547"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="658.547"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="658.547"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="658.547"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="440.719"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="440.719"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="440.719"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="440.719"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="440.719"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="222.891"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="222.891"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="549.633"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="549.633"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="549.633"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="549.633"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="549.633"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="549.633"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="331.805"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="331.805"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="331.805"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="331.805"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="331.805"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="195.498"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="113.977"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="685.775"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="685.775"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="685.775"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="685.775"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="685.775"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="685.775"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="467.947"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="467.947"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="467.947"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="467.947"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="467.947"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="467.947"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="250.119"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="250.119"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="576.861"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="576.861"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="576.861"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="576.861"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="576.861"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="576.861"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="359.033"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="359.033"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="359.033"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="359.033"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="359.033"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="222.727"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="141.205"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="631.318"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="631.318"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="631.318"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="631.318"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="631.318"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="631.318"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="413.49"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="413.49"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="413.49"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="413.49"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="413.49"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="413.49"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="277.184"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="195.662"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="195.662"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="522.404"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="522.404"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="522.404"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="522.404"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="948.422"
        cy="522.404"
        r="6"
        fill="#FFA768"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="522.404"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="304.576"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'column particle'}
        cx="1224.42"
        cy="304.576"
        r="6"
        fill="#F079C5"
      />
      <circle
        className={'column particle'}
        cx="1132.42"
        cy="304.576"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'particle column'}
        cx="856.422"
        cy="304.576"
        r="6"
        fill="#BBCBC8"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="304.576"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1040.42"
        cy="168.27"
        r="6"
        fill="#50509F"
      />
      <circle
        className={'column particle'}
        cx="1316.42"
        cy="86.7481"
        r="6"
        fill="#B08AFF"
      />
      <circle
        className={'particle'}
        cx="947.211"
        cy="481.939"
        r="3"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="924.866"
        cy="379.233"
        r="2.36"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="936.252"
        cy="365.014"
        r="1.92"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.92"
        cx="949.74"
        cy="367.631"
        r="2.22"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="951.881"
        cy="306.24"
        r="2.28"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="964.087"
        cy="341.918"
        r="1.82"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.79"
        cx="935.057"
        cy="407.713"
        r="2.3"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.98"
        cx="931.744"
        cy="402.473"
        r="2.04"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="948.283"
        cy="457.3"
        r="2.61"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.97"
        cx="960.083"
        cy="428.973"
        r="1.52"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="962.572"
        cy="426.021"
        r="1.86"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="927.91"
        cy="555.98"
        r="2.13"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.91"
        cx="935.246"
        cy="570.456"
        r="1.96"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="946.157"
        cy="563.838"
        r="3.18"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="953.2"
        cy="513.933"
        r="2.64"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.94"
        cx="972.779"
        cy="488.492"
        r="1.62"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="930.995"
        cy="645.688"
        r="2.16"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="935.225"
        cy="579.031"
        r="1.38"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="946.722"
        cy="664.251"
        r="3.27"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="964.393"
        cy="635.351"
        r="2.28"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.7"
        cx="967.12"
        cy="607.373"
        r="1.8"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="925.784"
        cy="726.641"
        r="3.12"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="936.278"
        cy="676.597"
        r="2.16"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="952.177"
        cy="718.312"
        r="1.8"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        cx="964.314"
        cy="737.481"
        r="3.21"
        fill="#FFA768"
      />
      <circle
        className={'particle'}
        opacity="0.72"
        cx="970.759"
        cy="708.998"
        r="1.82"
        fill="#FFA768"
      />
      <circle
        className={'particle enlarge-layer-2'}
        cx="1132.42"
        cy="440.719"
        r="6"
        fill="#5970f28f"
      />
      <circle
        className={'particle enlarge-layer-1'}
        cx="1132.42"
        cy="440.719"
        r="6"
        fill="#5970f2c2"
      />
      <circle
        className={'particle enlarging-dot'}
        cx="1132.42"
        cy="440.719"
        r="6"
        fill="#5970F2"
      />
      <circle
        className={'outer-circle'}
        cx="1132"
        cy="494.354"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1132"
        cy="385.645"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1077.65"
        cy="440.001"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1186.35"
        cy="440.001"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1093.57"
        cy="401.566"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1170.43"
        cy="478.433"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1170.43"
        cy="401.566"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1093.57"
        cy="478.434"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1181.9"
        cy="418.447"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1082.1"
        cy="461.552"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1182.25"
        cy="460.723"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1081.75"
        cy="419.276"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1152"
        cy="490.539"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1112"
        cy="389.461"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1110.73"
        cy="490.021"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'outer-circle'}
        cx="1153.27"
        cy="389.98"
        r="4.64567"
        fill="#B08AFF"
      />
      <circle
        className={'inner-circle'}
        cx="1142.8"
        cy="440.578"
        r="1.67997"
        fill="#5970F2"
      />
      <circle
        className={'inner-circle'}
        cx="1122.36"
        cy="440.578"
        r="1.67997"
        fill="#5970F2"
      />
      <circle
        className={'inner-circle'}
        cx="1132.58"
        cy="450.798"
        r="1.67997"
        fill="#5970F2"
      />
      <circle
        className={'inner-circle'}
        cx="1132.58"
        cy="430.358"
        r="1.67997"
        fill="#5970F2"
      />
      <circle
        className={'inner-circle'}
        cx="1139.81"
        cy="447.805"
        r="1.67997"
        fill="#5970F2"
      />
      <circle
        className={'inner-circle'}
        cx="1125.35"
        cy="433.351"
        r="1.67997"
        fill="#5970F2"
      />
      <circle
        className={'inner-circle'}
        cx="1125.35"
        cy="447.804"
        r="1.67997"
        fill="#5970F2"
      />
      <circle
        className={'inner-circle'}
        cx="1139.8"
        cy="433.351"
        r="1.67997"
        fill="#5970F2"
      />
    </svg>
  )
}

export default AnimatingDots
