import useSWR from 'swr'
import { SWRResponse } from 'swr/dist/types'

function useSolution(): SWRResponse<any, any> {
  return useSWR<any>('/api/solution', () =>
    fetch('/api/solution', {
      method: 'GET',
    }).then((res) => res.json())
  )
}

export default useSolution
