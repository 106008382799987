import DesktopMenu from './desktop-menu'
import classNames from 'classnames'
import MobileMenu from './mobile-menu'
import { ScrollState, useMenuContext } from './menu-context'
import useMediaQuery from '../../hooks/use-media-query'
import useThrottledBodyScroll from '../../hooks/use-throttled-body-scroll'
import { useState } from 'react'
import ColorConfig from '../../types/ColorConfig'
import MenuLayover from './menu-layover'
import { useMenuVisibility } from '../../contexts/menu-visibility-context'

type Props = {
  colorConfig: ColorConfig
}

function MainMenu({ colorConfig }: Props) {
  const {
    dispatch,
    state: { isMobileMenuOpen, scrollState },
  } = useMenuContext()
  const isSmallScreen = useMediaQuery('(max-width: 1100px)')
  const [isMenuShown, setIsMenuShown] = useMenuVisibility()

  useThrottledBodyScroll((dir, isAtTheTop: boolean) => {
    if (isAtTheTop) {
      setIsMenuShown(true)
      dispatch({
        type: 'atTheTop',
      })
    } else if (dir === 'down') {
      setIsMenuShown(false)
      dispatch({
        type: 'toggleMobileMenuOpen',
        payload: false,
      })
      dispatch({
        type: 'toggleMobileIsSearching',
        payload: false,
      })
    } else if (dir === 'up') {
      setIsMenuShown(true)
      dispatch({
        type: 'updateScrollState',
        payload: ScrollState.white,
      })
    }
  }, 300)

  return (
    <>
      <MenuLayover />
      {colorConfig.isAnExtraTopPaddingRequired && (
        <div className={'order-[-1] h-10'} />
      )}
      <nav
        data-datocms-noindex
        onMouseEnter={() => {
          dispatch({
            type: 'updateScrollState',
            payload: ScrollState.white,
          })
        }}
        onMouseLeave={() => {
          dispatch({
            type: 'toggleMobileMenuOpen',
            payload: false,
          })
          dispatch({
            type: 'setDesktopMenuItemBlocks',
            payload: null,
          })
        }}
        style={{
          color:
            scrollState === ScrollState.white
              ? colorConfig.scrollingUpTextColorHex?.hex
              : colorConfig.transparentBackgroundTextColorHex?.hex,
          backgroundColor:
            scrollState === ScrollState.white
              ? colorConfig.scrollingUpBackgroundColorHex?.hex
              : 'transparent',
        }}
        className={classNames(
          'fixed top-[0] z-40 w-full transform transition-transform duration-700',
          {
            'h-full': isMobileMenuOpen,
            '-translate-y-full': !isMenuShown,
            'translate-[0px]': isMenuShown,
          }
        )}
      >
        {isSmallScreen ? <MobileMenu /> : <DesktopMenu />}
      </nav>
    </>
  )
}

export default MainMenu
