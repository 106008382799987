import { useState } from 'react'
import CtaLink from '../../components/cta-link'
import Section from '../../components/section'
import { FrontpageFindTheRightSolutionRecord } from '../../generated/graphql'
import useMediaQuery from '../../hooks/use-media-query'
import ArrowRight from '../../icons/arrow-right'
import { ServiceBlock, Solution } from '../../types/service-menu-type'
import { createServices } from '../../utils/filter-service-menu'
import ServiceBlockList from './service-block-list'
import SolutionCardList from './solution-card-list'

type Services = {
  block: ServiceBlock[]
  solutions: Solution[]
  index: number
}

type Props = {
  block: FrontpageFindTheRightSolutionRecord
}

function FrontPageFindTheRightSolutionRecord({ block }: Props) {
  const [services, setServices] = useState<Services>({
    block: createServices(block.solutions)[0],
    index: 1,
    solutions: [],
  })

  function handleServiceSelect(index: number) {
    if (services.block[index].type === 'industry') {
      const newBlock = services.block[index].nextBlock

      setServices((prev) => ({
        ...prev,
        index: 2,
        block: newBlock,
      }))
    } else {
      const solutions = services.block[index].solutions
      setServices((prev) => ({
        ...prev,
        solutions: solutions,
      }))
    }
  }

  const handleStepBack = () => {
    if (services.solutions.length > 0) {
      setServices((oldServices) => ({
        ...oldServices,
        solutions: [],
      }))
    } else if (services.index > 1) {
      setServices((oldServices) => ({
        ...oldServices,
        index: 1,
        block: createServices(block.solutions)[0],
      }))
    }
  }

  const isSmallScreen = useMediaQuery('(max-width: 1024px)')

  return (
    <Section
      classOverride={'py-[120px]'}
      classOverrideInnerGrid={'py-[0] md:py-[0] pr-[0px] md:pr-[0px]'}
      backgroundColor={block.backgroundColorHex}
      id={block?.slug || ''}
    >
      <div className="col-span-full col-start-1 flex flex-col gap-3 lg:col-span-3">
        <h2 className="text-display-2">{block.headline}</h2>
        <p className="text-text-large">{block.description}</p>
        {!isSmallScreen && (
          <div className="col-start-1 mt-4 flex flex-col gap-2">
            {block.cta.map((cta) => (
              <div key={cta.id} className="flex">
                <CtaLink key={cta.id} cta={cta} />
              </div>
            ))}
            {services.index > 1 && (
              <div className={'group relative w-fit'}>
                <a
                  onClick={handleStepBack}
                  className={`primary group cursor-pointer border-none text-greys-4 transition-all`}
                >
                  <div className="relative z-30 flex items-center gap-1 md:gap-3">
                    <div className="w-[16px] overflow-hidden">
                      <div className="flex w-[32px] translate-x-[-16px] flex-row gap-1 transition-transform duration-300 group-hover:translate-x-[4px]">
                        <ArrowRight size={'16px'} />
                        <ArrowRight size={'16px'} />
                      </div>
                    </div>
                    <p className="font-medium transition-transform duration-300">
                      Go back
                    </p>
                  </div>
                </a>
                <span
                  className={`absolute bottom-0 left-0 h-[1px] w-0 origin-left transform bg-primary-rystad-blue transition-all duration-300 group-hover:w-full`}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="relative col-span-full row-start-2 mt-4 flex h-[380px] flex-col md:mt-[0] lg:col-start-5 lg:row-start-1">
        {!services.solutions.length ? (
          <>
            <div>
              <h3 className="mb-3 font-medium">{`Select ${services.block[0].type} (${services.index}/2)`}</h3>
              <ServiceBlockList
                block={services.block}
                selectService={handleServiceSelect}
              />
            </div>
          </>
        ) : (
          <SolutionCardList solutions={services.solutions} />
        )}
      </div>
      {isSmallScreen && (
        <div className="col-span-full col-start-1 mr-auto mt-9 mb-9 flex flex-col gap-2 lg:col-span-2">
          {block.cta.map((cta) => (
            <CtaLink key={cta.id} cta={cta} />
          ))}
          {services.index > 1 && (
            <div className={'group relative w-fit'}>
              <a
                onClick={handleStepBack}
                className={`primary group cursor-pointer border-none text-greys-4 transition-all`}
              >
                <div className="relative z-30 flex items-center gap-1 md:gap-3">
                  <div className="w-[16px] overflow-hidden">
                    <div className="flex w-[32px] translate-x-[-16px] flex-row gap-1 transition-transform duration-300 group-hover:translate-x-[4px]">
                      <ArrowRight size={'16px'} />
                      <ArrowRight size={'16px'} />
                    </div>
                  </div>
                  <p className="font-medium transition-transform duration-300">
                    Go back
                  </p>
                </div>
              </a>
              <span
                className={`absolute bottom-0 left-0 h-[1px] w-0 origin-left transform bg-primary-rystad-blue transition-all duration-300 group-hover:w-full`}
              />
            </div>
          )}
        </div>
      )}
    </Section>
  )
}

export default FrontPageFindTheRightSolutionRecord
