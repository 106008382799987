import Section from '../../components/section'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import { useRef } from 'react'
import classNames from 'classnames'
import animateSentences from './animate-sentences'
import { SequencingSentencesAnimationRecord } from '../../generated/graphql'

type Props = {
  block: SequencingSentencesAnimationRecord
}

function SequencingSentences({ block }: Props) {
  const stage = useRef<HTMLDivElement>(null)
  const sentenceRefs = useRef<(HTMLSpanElement | null)[]>([])
  const sentences =
    block.sequencingSentences
      ?.split('.')
      .filter((sentence) => !!sentence)
      .map((sentence) => sentence + '.') || []

  useIsomorphicLayoutEffect(() => {
    animateSentences(stage, sentenceRefs)
  }, [])

  return (
    <div
      ref={stage}
      className={
        'flex min-h-screen items-center justify-center bg-primary-rystad-blue'
      }
    >
      <Section>
        <div className={'col-span-full md:col-span-1'}>
          <p className={'font-display text-text-base text-white md:text-right'}>
            {block.caption}
          </p>
        </div>
        <div className={'col-span-full md:col-span-6 md:px-2'}>
          {sentences.map((sentence, index) => (
            <span
              ref={(el) => (sentenceRefs.current[index] = el)}
              className={classNames(
                'text-text-manchet text-white md:text-header-1',
                {
                  'opacity-40': index !== 0,
                }
              )}
              key={sentence}
            >
              {sentence}
            </span>
          ))}
        </div>
      </Section>
    </div>
  )
}

export default SequencingSentences
