import Section from '../../../components/section'
import { useMenuStatus } from '../context'
import { MenuItemType, ServiceBlock } from '../../../types/service-menu-type'
import MobileAllSolutionBlock from './mobile-all-solution-block'
import MobileFirstBlock from './mobile-first-block'
import MobileMenuItemsBlock from './mobile-menu-items-block'
import MobileSecondBlock from './mobile-second-block'
import MobileSolutionsBlock from './mobile-solutions-block'

type Props = {
  menuItems: MenuItemType[]
  services: ServiceBlock[][]
}
function MobileServiceMenu({ menuItems, services }: Props) {
  const { state } = useMenuStatus()
  const isMenuItemNotSet = !state.menuSelected
  const isMenuItemSelected = state.menuSelected && state.firstBlock === -1
  const isFirstBlockSelected =
    state.firstBlock !== -1 && state.secondBlock === -1
  const isSecondBlockSelected = state.secondBlock !== -1
  const isAllSolutionSelected = state.menuItem === 2

  return (
    <Section>
      {isAllSolutionSelected ? (
        <MobileAllSolutionBlock items={services[state.menuItem]} />
      ) : (
        <>
          {isMenuItemNotSet && <MobileMenuItemsBlock items={menuItems} />}
          {isMenuItemSelected && (
            <MobileFirstBlock
              items={services[state.menuItem]}
              selectedText={menuItems[state.menuItem].name}
            />
          )}
          {isFirstBlockSelected && (
            <MobileSecondBlock
              selectedText={services[state.menuItem][state.firstBlock].name}
              items={services[state.menuItem][state.firstBlock]?.nextBlock}
            />
          )}
          {isSecondBlockSelected && (
            <MobileSolutionsBlock
              selectedText={services[state.menuItem][state.firstBlock].name}
              items={
                services[state.menuItem][state.firstBlock]?.nextBlock[
                  state.secondBlock
                ]?.solutions
              }
            />
          )}
        </>
      )}
    </Section>
  )
}

export default MobileServiceMenu
