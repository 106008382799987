import { useEffect, useMemo, useRef, useState } from 'react'
import { ContactPopupRecord } from '../../generated/graphql'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import { gsap } from '../../lib/gsap'
import { Image } from 'react-datocms'
import Close from '../../icons/close'
import { isEmployeeCharacteristicRecord } from '../team-grid/team-grid'
import CtaLink from '../../components/cta-link'
import SharpArrow from '../../icons/sharp-arrow'
import useOutsideClick from '../../hooks/use-outside-click'

type Props = {
  block: ContactPopupRecord
}

function ContactPopup({ block }: Props) {
  const [popupOpen, setPopupOpen] = useState<boolean>(false)
  const box = useOutsideClick(() => setPopupOpen(false))
  const wrapper = useRef<HTMLDivElement | null>(null)
  const bar = useRef<HTMLDivElement | null>(null)
  const tl = useMemo(() => gsap.timeline({ paused: true }), [])
  const {
    barColour,
    boxColour,
    headline,
    subheadline,
    records,
    boxTitle,
    links,
  } = block

  const filteredRecords = records
    .map((record) => record.contentLayout[0])
    .filter(isEmployeeCharacteristicRecord)

  useIsomorphicLayoutEffect(() => {
    tl.to(wrapper.current, {
      display: 'flex',
      duration: 0,
    })
      .to(bar.current, {
        duration: 0.2,
        opacity: 0,
        transform: 'scale(1.3)',
        y: -40,
        x: -40,
        ease: 'power2',
      })
      .to(
        box.current,
        {
          transform: '',
          opacity: 1,
          duration: 0.2,
          ease: 'power2',
        },
        '<='
      )
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  useEffect(() => {
    if (popupOpen) {
      tl.play()
      document.body.style.overflow = 'hidden'
    } else {
      tl.reverse()
      document.body.style.overflow = 'unset'
    }
  }, [popupOpen])

  return (
    <>
      <div
        ref={wrapper}
        className="fixed inset-0 z-50 hidden justify-end md:mb-2 md:items-end"
      >
        <div className="mx-auto grid max-w-fullbleed grid-cols-8 gap-5 md:px-5">
          <div
            style={{
              opacity: 0,
              backgroundColor: boxColour?.hex,
              transform:
                'scaleX(0.34) scaleY(0.12) translateX(0%) translateY(0%)',
              transformOrigin: 'bottom right',
            }}
            className="relative col-span-8 col-start-1 overflow-y-auto px-2 py-4 md:h-auto md:rounded-lg md:px-5 md:py-6 lg:col-span-7 lg:col-start-2 lg:px-8 lg:py-7 xl:col-span-6 xl:col-start-3 xl:px-10 xl:py-9"
            ref={box}
          >
            <div
              className="fixed top-4 right-2 cursor-pointer md:absolute md:left-3"
              onClick={() => setPopupOpen(false)}
            >
              <Close />
            </div>

            <div className="grid w-full grid-cols-1 gap-5 md:grid-cols-2">
              <div className="flex flex-col justify-between pr-3 md:pr-0 lg:pr-5">
                <div className="mt-3 md:mt-0">
                  <div className="mb-2 text-header-2 md:mb-3">{headline}</div>
                  <div>{subheadline}</div>
                </div>
                <div className="hidden prose-a:text-white md:block">
                  {links.map((link) => (
                    <div key={link.id} className="mb-1">
                      <CtaLink cta={link} />
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={`grid grid-cols-${filteredRecords.length} gap-${
                  filteredRecords.length > 2 ? 2 : 4
                }`}
              >
                {filteredRecords.map(
                  (record, i) =>
                    record.profilePicture?.responsiveImage && (
                      <div key={i}>
                        <div className="pb-3">
                          <Image
                            lazyLoad={false}
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                            }}
                            data={record.profilePicture?.responsiveImage}
                          />
                        </div>
                        <div>
                          <div className="mb-1 text-text-small">
                            {record.name}
                          </div>

                          <div className="mb-[10px] text-text-x-small ">
                            {record.jobTitle}
                          </div>

                          <div className="mb-[12px] text-text-x-small text-greys-3">
                            <div className="underline decoration-greys-3 decoration-dashed decoration-2 underline-offset-4">
                              {record.phoneNumber}
                            </div>
                          </div>

                          <div className="w-full truncate text-text-x-small text-greys-3">
                            <div className="truncate underline decoration-greys-3 decoration-dashed decoration-2 underline-offset-4">
                              <a href={`mailto:${record.eMailAddress}`}>
                                {record.eMailAddress}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>

              <div className="block prose-a:text-white md:hidden">
                {links.map((link) => (
                  <div key={link.id} className="mb-1">
                    <CtaLink cta={link} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        ref={bar}
        style={{
          transformOrigin: 'bottom right',
        }}
        className="pointer-events-none fixed bottom-0 left-0 right-0 z-50 mb-2 mr-2 md:mr-0"
      >
        <div className="mx-auto flex max-w-fullbleed items-end justify-end md:px-5">
          <div
            className="pointer-events-auto flex cursor-pointer items-center rounded-full py-[2px]"
            onClick={() => setPopupOpen(true)}
            style={{
              backgroundColor: barColour?.hex,
            }}
          >
            <div
              className={`${
                filteredRecords.length > 1 ? '-mr-[19px]' : 'mr-[0]'
              } flex`}
            >
              {filteredRecords.map(
                (record, i) =>
                  record.profilePicture?.responsiveImage && (
                    <div
                      className={`${
                        i === 1
                          ? 'translate-x-[-17px]'
                          : i === 2
                          ? 'translate-x-[-34px]'
                          : ''
                      }`}
                      key={i}
                      style={{
                        zIndex: i === 0 ? '15' : i === 1 ? '10' : '5',
                      }}
                    >
                      <div
                        className="relative h-[45px] w-[45px] overflow-hidden rounded-full border-4"
                        style={{ borderColor: barColour?.hex }}
                      >
                        <Image
                          lazyLoad={false}
                          style={{
                            objectFit: 'fill',
                          }}
                          objectFit={'cover'}
                          data={record.profilePicture?.responsiveImage}
                        />
                      </div>
                    </div>
                  )
              )}
            </div>
            <div className="px-1">
              <div>
                <SharpArrow />
              </div>
            </div>
            <div className="whitespace-nowrap pr-3 text-center text-text-base">
              {boxTitle}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactPopup
