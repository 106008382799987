import { createContext, ReactNode, useContext } from 'react'

type Props = {
  insightKey: string
  isSecure: boolean
}

const InsightIsSecureContext = createContext<Props | null>(null)

export const useInsightIsSecure = (): boolean => {
  const ctx = useContext(InsightIsSecureContext)

  if (!ctx) {
    throw new Error(
      'useInsightIsSecure must be used within a InsightIsSecureProvider'
    )
  }

  return ctx.isSecure
}

export const useInsightKey = (): string => {
  const ctx = useContext(InsightIsSecureContext)

  if (!ctx) {
    throw new Error(
      'useInsightIsSecure must be used within a InsightIsSecureProvider'
    )
  }

  return ctx.insightKey
}

const InsightIsSecureProvider = ({
  insightKey,
  isSecure,
  children,
}: {
  insightKey: string
  isSecure: boolean
  children: ReactNode
}) => {
  const value = {
    insightKey,
    isSecure,
  }
  return (
    <InsightIsSecureContext.Provider value={value}>
      {children}
    </InsightIsSecureContext.Provider>
  )
}

export default InsightIsSecureProvider
