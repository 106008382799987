import React from 'react'
import { ReportArticleRecord } from '../generated/graphql'
import Section from '../components/section'
import StructuredText from '../components/structured-text'

type Props = {
  block: ReportArticleRecord
}

const ReportArticle = ({ block }: Props) => {

  return (
    <div className="w-full">
      {block.articles.map((article) => {
        return (
          <div
            key={article.id}
            className="grid justify-center first:pt-2 last:pb-2 first:md:pt-5 last:md:pb-5"
            style={{ backgroundColor: article.backgroundColor?.hex ?? '' }}
          >
            <div className="col-span-full grid max-w-fullbleed grid-cols-12 p-2 text-primary-rystad-blue md:p-5 ">
              <h2
                id={article.anchorText ?? ''}
                className="col-span-1 col-start-1 grid text-[32px] md:col-start-2"
              >
                {article.number}
              </h2>
              <div className="col-span-8 col-start-3 grid md:col-span-8">
                <StructuredText key={article.id} data={article.articleText} />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ReportArticle
