import { SolutionFrontpageEnergyTypeModuleRecord } from '../../generated/graphql'
import Section from '../../components/section'
import Accordion from '../../components/accordion/accordion'
import { Image } from 'react-datocms'
import EnergyTypeContent from './energy-type-content'
import Button from '../../components/button'
import Plus from '../../icons/plus'
import Minus from '../../icons/minus'

type Props = {
  block: SolutionFrontpageEnergyTypeModuleRecord
}

function SolutionsEnergyTypesModule({ block }: Props) {
  return (
    <div id={block?.slug || ''}>
      <div
        className={
          'col-span-8 block w-full items-center gap-y-2 md:col-span-4 md:hidden'
        }
      >
        {block.image && (
          <Image
            style={{ maxWidth: 'none' }}
            data={block.image?.responsiveImage!}
          />
        )}
      </div>

      <Section>
        <div
          className={
            'col-span-8 hidden w-full items-center gap-y-2 md:col-span-4 md:block'
          }
        >
          {block.image && (
            <Image
              style={{ maxWidth: 'none' }}
              data={block.image?.responsiveImage!}
            />
          )}
        </div>
        <div
          id={block.slug ?? ''}
          className={
            'col-span-8 flex flex-col justify-center gap-2 md:col-span-4'
          }
        >
          <div className="flex flex-col gap-3">
            <p className={'text-text-base'}>{block.description}</p>
            <h2 className={'text-header-2'}>{block.headline}</h2>
          </div>
          <div className="font-sans text-header-4">
            <Accordion
              openIcon={<Plus />}
              closedIcon={<Minus />}
              hasCounter
              accordionItems={
                block.energyTypes?.map((item) => ({
                  label: item.headline!,
                  content: (
                    <EnergyTypeContent
                      cta={item.cta[0]}
                      description={item.description}
                    />
                  ),
                  id: item.id,
                })) ?? []
              }
            />
          </div>
          {block.cta?.[0] && (
            <div className={'mt-3 self-start'}>
              <Button
                text={block.cta?.[0]?.ctaLabel ?? ''}
                to={block.cta?.[0]?.link ?? ''}
                variant={'primary'}
              />
            </div>
          )}
        </div>
      </Section>
    </div>
  )
}

export default SolutionsEnergyTypesModule
