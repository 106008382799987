import ArrowRightButton from '../../components/arrow-right-button'
import Section from '../../components/section'
import { CtaLinkRecord } from '../../generated/graphql'

type Props = {
  block: CtaLinkRecord
}

function CtaLinkRecord({ block }: Props) {
  return (
    <Section>
      <div className="col-span-full border-t-2 border-primary-rystad-blue pt-3 md:col-start-5">
        <ArrowRightButton link={block.link!}>{block.ctaLabel}</ArrowRightButton>
      </div>
    </Section>
  )
}

export default CtaLinkRecord
