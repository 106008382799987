import Section from '../../components/section'
import { VideoModuleRecord } from '../../generated/graphql'
import dynamic from 'next/dynamic'
const ReactPlayer = dynamic(() => import('react-player'), { ssr: false })

type Props = {
  block: VideoModuleRecord
}

const VideoModuleRecord = ({ block }: Props) => {
  return block.externalVideoLink ? (
    <Section id={block?.slug || ''} classOverrideInnerGrid="p-0 py-4">
      <div className="col-span-full flex flex-col py-4">
        <div className="max-h-[95vh]">
          <ReactPlayer
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  poster: block?.image?.responsiveImage?.src,
                },
              },
            }}
            width="100%"
            height="100%"
            style={{ objectFit: 'contain' }}
            controls
            url={block.externalVideoLink}
          />
        </div>
      </div>
    </Section>
  ) : null
}

export default VideoModuleRecord
