import { createServices } from '../../utils/filter-service-menu'
import { ServicesMenuRecord } from '../../generated/graphql'
import MobileServiceMenu from './mobile-service-menu'
import DesktopServiceMenu from './desktop-service-menu'
import useMediaQuery from '../../hooks/use-media-query'

type Props = {
  block: ServicesMenuRecord
}

function ServiceMenuContainer({ block }: Props) {
  const menuItems = block.industryEnergyTypeAllSolutionsMenuField.map(
    (item) => ({
      name: item.headline!,
      description: item.text!,
      link: item.link!,
      displayProductDemo : item.displayProductDemo
    })
  )

  const services = createServices(block.solutions)
  const isSmallScreen = useMediaQuery('(max-width: 1100px)')

  return (
    <>
      {isSmallScreen ? (
        <MobileServiceMenu menuItems={menuItems} services={services} />
      ) : (
        <DesktopServiceMenu
          services={services}
          menuItems={menuItems}
          customPages={block.linkedCustomPages}
        />
      )}
    </>
  )
}

export default ServiceMenuContainer
