import Section from '../../components/section'
import StructuredText from '../../components/structured-text'
import { TxtBlockRecord } from '../../generated/graphql'

type Props = {
  block: TxtBlockRecord
}

function TxtBlockRecord({ block }: Props) {
  return (
    <Section classOverride='gap-10'>
      <h1 className="col-span-4 text-display-2">{block.headline}</h1>

      <div className="col-span-4">
        <StructuredText data={block.pageText} />
      </div>
    </Section>
  )
}

export default TxtBlockRecord
