import DeletableTextInput from '../../../components/deletable-text-input'
import useOutsideClick from '../../../hooks/use-outside-click'
import useURLSearch from '../../../hooks/use-URL-search'
import { useMenuContext } from '../menu-context'

function SearchBar() {
  const [inputValue, setInputValue] = useURLSearch()
  const {
    dispatch,
    state: { isMobileSearching },
  } = useMenuContext()

  const toggleSearchBar = () => {
    dispatch({
      type: 'toggleMobileIsSearching',
      payload: !isMobileSearching,
    })
  }

  const divRef = useOutsideClick(toggleSearchBar)

  return (
    <div ref={divRef} className={'my-2 px-2'}>
      <DeletableTextInput setValue={setInputValue} value={inputValue} />
    </div>
  )
}

export default SearchBar
