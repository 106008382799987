type Props = {
  color?: string
  size?: number | string
}

function CheckCircle({ color, size }: Props) {
  return (
    <svg
      className={color}
      width={size ?? 17}
      height={size ?? 17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8.65234"
        cy="8.47375"
        r="8"
        fill="#F6F8FA"
        fillOpacity="0.2"
      />
      <path
        d="M5.65005 7.65574L8.12492 10.1306L11.3069 6.94863"
        stroke="white"
      />
    </svg>
  )
}

export default CheckCircle
