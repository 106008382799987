import React, { useState, useEffect } from 'react'
import { SugarMarketFormRecord } from '../../generated/graphql'
import Section from '../../components/section'

type Props = {
  block: any
  classOverrideInnerGrid?: string
}

const SugarMarketForm = ({ block, classOverrideInnerGrid }: Props) => {
  const [htmlContent, setHtmlContent] = useState('')

  useEffect(() => {
    async function fetchHtmlFile() {
      try {
        const response = await fetch(`${block.htmlFile?.url}`)
        if (response.ok) {
          const html = await response.text()
          setHtmlContent(html)
        } else {
          console.error('Failed to fetch HTML file')
        }
      } catch (error) {
        console.error('Error fetching HTML file:', error)
      }
    }

    fetchHtmlFile()
  }, [block.htmlFile?.url])

  useEffect(() => {
    const extractScriptContent = () => {
      const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
      const scriptTags = htmlContent.match(scriptRegex)
      if (scriptTags) {
        const scriptContent = scriptTags.map((tag) => {
          const contentRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/i
          const match = tag.match(contentRegex)
          return match ? match[1] : ''
        })
        addScriptToDOM(scriptContent)
      }
    }
    extractScriptContent()
  }, [htmlContent])

  const addScriptToDOM = (scriptContent: string[]) => {
    scriptContent.forEach((content) => {
      const script = document.createElement('script')
      script.innerHTML = content
      document.body.appendChild(script)
    })
  }

  return (
    <Section
      id={block?.slug || ''}
      classOverrideInnerGrid={`${classOverrideInnerGrid} grid-cols-1 md:grid-cols-1`}
    >
      {block.headline && (
        <h3 className="text-center text-header-3">{block.headline}</h3>
      )}
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </Section>
  )
}

export default SugarMarketForm
