import React, { useState, useCallback, useMemo, useEffect } from 'react'
import DropdownSelect from '../dropdown-select'
import Button from '../button'
import { SignUpFreeTrialRecord } from '../../generated/graphql'
import * as Checkbox from '@radix-ui/react-checkbox'
import FilledCheck from '../../icons/filled-check'
import FormInfo from '../../types/Form'
import useCity from '../../hooks/use-city'
import useDivision from '../../hooks/use-division'
import useCountry from '../../hooks/use-country'
import useRole from '../../hooks/use-role'
import Link from 'next/link'

type Props = {
  block: SignUpFreeTrialRecord
  setFormInfo: (value: any) => void
  formInfo: FormInfo
  setFormCurrentStep: (step: string) => void
  initialFormInfo: FormInfo
}

const FormSecondStep = ({
  block,
  setFormInfo,
  formInfo,
  setFormCurrentStep,
  initialFormInfo,
}: Props) => {
  const [privacyPolicyError, setPrivacyPolicyError] = useState('')
  const [cityError, setCityError] = useState('')
  const [countryError, setCountryError] = useState('')
  const [divisionError, setDivisionError] = useState('')
  const [roleError, setRoleError] = useState('')
  const [generalError, setGeneralError] = useState(false)

  const divisionData = useDivision()
  const countryData = useCountry()
  const roleData = useRole()
  const cityData = useCity(formInfo.country)

  const divisions = useMemo(() => {
    if (!divisionData?.data) return null
    const { divisions } = divisionData.data
    return divisions
  }, [divisionData])

  const countries = useMemo(() => {
    if (!countryData?.data) return null
    const { countries } = countryData.data
    return countries
  }, [countryData])

  const roles = useMemo(() => {
    if (!roleData?.data) return null
    const { roles } = roleData.data
    return roles
  }, [roleData])

  const cities = useMemo(() => {
    if (!cityData?.data) return null
    const { cities } = cityData.data
    return cities
  }, [cityData])

  const [resetItem, setResetItems] = useState(cities)

  const handleToggleItems = () => {
    setResetItems((prev: any) => (prev === cities ? cities : cities))
  }

  const validate = useCallback(async () => {
    setGeneralError(false)
    setPrivacyPolicyError(
      formInfo.privacyPolicy ? '' : 'Please check here to proceed'
    )
    setDivisionError(formInfo.division ? '' : 'This field is required')
    setRoleError(formInfo.role ? '' : 'This field is required')
    setCountryError(formInfo.country ? '' : 'This field is required')
    setCityError(formInfo.city ? '' : 'This field is required')

    const postData = {
      firstName: formInfo.firstName,
      lastName: formInfo.lastName,
      title: formInfo.title,
      email: formInfo.businessEmail,
      division: formInfo.division,
      role: formInfo.role,
      country: formInfo.country,
      city: formInfo.city,
      products: formInfo.solution,
      phone: formInfo.phone,
    }

    if (
      formInfo.privacyPolicy &&
      formInfo.division &&
      formInfo.role &&
      formInfo.country &&
      formInfo.city
    ) {
      try {
        const res = await fetch('/api/free-trial-post', {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        })

        if (!res.ok) {
          const message = `An error has occured: ${res.status} - ${res.statusText}`
          setGeneralError(true)
          throw new Error(message)
        }

        setFormInfo(initialFormInfo)
        setFormCurrentStep('lastStep')
      } catch (err: any) {
        console.log(err)
      }
    }
  }, [formInfo, setFormCurrentStep, initialFormInfo, setFormInfo])

  return (
    <form className="flex flex-col gap-[15px] lg:gap-[32px]">
      <div className="flex flex-col gap-[15px] lg:flex-row lg:gap-[35px]">
        <DropdownSelect
          label="Division *"
          placeholder="Choose division"
          data={divisions ?? []}
          error={divisionError}
          onSelect={(value) => {
            setFormInfo({ ...formInfo, division: Number(value) })
            setDivisionError('')
          }}
        />
        <DropdownSelect
          label="Role *"
          placeholder="Choose role"
          data={roles ?? []}
          error={roleError}
          onSelect={(value) => {
            setFormInfo({ ...formInfo, role: Number(value) })
            setRoleError('')
          }}
        />
      </div>
      <div className="flex flex-col gap-[15px] lg:flex-row lg:gap-[35px]">
        <DropdownSelect
          label="Country *"
          placeholder="Choose country"
          data={countries ?? []}
          error={countryError}
          onSelect={(value) => {
            setFormInfo({ ...formInfo, country: Number(value), city: '' })
            setCountryError('')
            handleToggleItems()
          }}
        />
        <DropdownSelect
          label="City *"
          placeholder="Choose city"
          data={formInfo.country ? cities : []}
          error={cityError}
          disabled={formInfo.country ? false : true}
          onSelect={(value) => {
            setFormInfo({ ...formInfo, city: Number(value) })
            setCityError('')
          }}
          resetKey={resetItem && resetItem[0]?.name}
        />
      </div>

      <p className="text-base text-[11px] text-greys-4">
        {block.privacyPolicyDescription}
      </p>

      <div className="flex items-center gap-[12px]">
        <Checkbox.Root
          className="hover:bg-violet3 flex h-[23px] w-[23px] appearance-none items-center justify-center bg-white shadow-[0_0_0_1px] shadow-greys-3 outline-none"
          id="checkbox"
          onCheckedChange={(value) => {
            setFormInfo({ ...formInfo, privacyPolicy: value })
            setPrivacyPolicyError('')
          }}
        >
          <Checkbox.Indicator className="text-white">
            <FilledCheck />
          </Checkbox.Indicator>
        </Checkbox.Root>
        <label className="text-base text-[15px]" htmlFor="checkbox">
          {`${block.privacyPolicyText}`}
        </label>
      </div>
      {privacyPolicyError && privacyPolicyError.length > 0 && (
        <span className="text-base text-[11px] text-[#b02a37]">
          {privacyPolicyError}
        </span>
      )}
      {generalError && (
        <span className="text-base text-[11px] text-[#b02a37]">
          We don’t have you in our system – please read our{' '}
          <Link href="/free-trial-policy">
            <a className="underline">policies</a>
          </Link>
          , or try another email
        </span>
      )}
      <Button
        className="mt-[17px] w-fit lg:mt-0"
        text={block.secondStepButtonText ?? ''}
        onClick={validate}
      />
    </form>
  )
}

export default FormSecondStep
