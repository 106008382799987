const tag = `
tag{
    id
    tagLabel
    dotColor{
      hex
    }
}
`

export default tag
