import ArrowRight from '../../icons/arrow-right'

type Variant = 'dark' | 'light'
type Props = {
  onClick: () => void
  disabled: boolean
  variant: Variant
}

const variants: Record<Variant, string> = {
  dark: 'text-primary-rystad-blue',
  light: 'text-greys-0',
}

const underlineVariants: Record<Variant, string> = {
  dark: 'bg-primary-rystad-blue',
  light: 'bg-greys-0',
}

function LoadMore({ onClick, disabled, variant }: Props) {
  return (
    <div className="col-span-6 col-start-1 sm:col-start-3">
      <div className="relative z-30 gap-1 border-t-[0] border-current md:gap-3 md:border-t">
        <div className={'group relative w-fit'}>
          <button
            disabled={disabled}
            className={`group flex flex-row items-center gap-2 pt-3 text-text-base md:text-text-small ${
              variants[variant ?? 'light']
            } transition-all`}
            onClick={onClick}
            type="button"
          >
            <div className="w-[16px] overflow-hidden">
              <div className="flex w-[32px] translate-x-[-16px] flex-row gap-1 transition-transform duration-300 group-hover:translate-x-[4px]">
                <ArrowRight size={'16px'} />
                <ArrowRight size={'16px'} />
              </div>
            </div>
            <p className="transition-transform duration-300">Load more</p>
          </button>
          <span
            className={`${
              underlineVariants[variant ?? 'primary']
            } absolute bottom-0 left-0 h-[1px] w-0 origin-left transform transition-all duration-300 group-hover:w-full`}
          />
        </div>
      </div>
    </div>
  )
}

export default LoadMore
