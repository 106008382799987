type Props = {
  size?: number
}

function Plus({ size }: Props) {
  return (
    <svg width={size ?? 12} height={size ?? 12} viewBox="0 0 12 12" fill="none">
      <rect x="5.5" y="0.263672" width="1" height="11" fill="currentColor" />
      <rect
        x="11.5"
        y="5.26367"
        width="1"
        height="11"
        transform="rotate(90 11.5 5.26367)"
        fill={'currentColor'}
      />
    </svg>
  )
}

export default Plus
