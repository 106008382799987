import { AnimationTextBlockRecord } from '../../../generated/graphql'

type Props = {
  index: number
  service: AnimationTextBlockRecord
}

function ServiceLabel({ service, index }: Props) {
  return (
    <div
      key={service.id}
      className={`label-${index + 1} flex h-1/4 flex-col gap-2`}
    >
      <div className={'pl-4 uppercase text-primary-orange'}>
        {service.label}
      </div>
      <div
        className={'border-l-2 border-l-white pl-4 text-display-2 text-white'}
      >
        {service.headline}
      </div>
      <div className={'pl-4 text-text-large text-white'}>
        {service.description}
      </div>
    </div>
  )
}

export default ServiceLabel
