import Link from 'next/link'
import { useState } from 'react'
import { Solution } from '../../../types/service-menu-type'

type Props = {
  items: Solution[]
  underlineColor?: string
  hoverColor?: string
}
function SolutionBlock({ items, underlineColor, hoverColor }: Props) {
  const [hoveredItem, setHoveredItem] = useState(-1)

  return (
    <div
      className="custom-scrollbar col-span-full col-start-5 grid max-h-[70vh] grid-cols-2 grid-rows-[minmax(max-content,max-content)] gap-1 overflow-y-auto border-l-[1px] p-4 pt-2"
      style={{ borderColor: underlineColor }}
    >
      {items?.map((item, index) => (
        <Link
          scroll={false}
          href={`/services/${item.slug}`}
          className="flex flex-col "
          key={item.slug}
        >
          <a
            className="p-2"
            onMouseEnter={() => setHoveredItem(index)}
            onMouseLeave={() => setHoveredItem(-1)}
            style={{
              backgroundColor:
                hoveredItem === index ? hoverColor : 'transparent',
            }}
          >
            <span
              className="border-b-[1px]  text-text-base font-medium"
              style={{ borderColor: underlineColor }}
            >
              {item.name}
            </span>
            <p className="pt-1 text-text-small">{item.excerpt}</p>
          </a>
        </Link>
      ))}
    </div>
  )
}

export default SolutionBlock
