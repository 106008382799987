type Props = {
  size?: number
}

function DownArrow({ size }: Props) {
  return (
    <svg
      width={size ?? 23}
      height={size ?? 23}
      viewBox={`0 0 ${size ?? 23} ${size ?? 23}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M11.2637 4.29248V18.2925" stroke="currentColor" />
        <path
          d="M4.1926 11.3635L11.2637 18.4346L18.3347 11.3635"
          stroke="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_727_28694">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(22.627 11.3135) rotate(135)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DownArrow
