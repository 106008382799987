import MultilineParagraph from '../../components/multiline-paragraph'
import Section from '../../components/section'
import { CareersHorizontalScrollRecord } from '../../generated/graphql'

type Props = {
  block: CareersHorizontalScrollRecord
}

function CareersHorizontalScrollRecord({ block }: Props) {
  const horizontalScrollElements = block.horizontalScrollElements

  return (
    <Section backgroundColor={block.backgroundColorHex} id={block?.slug || ''}>
      <div className="col-span-2 col-start-1 mb-11 pb-5 sm:col-span-8 sm:mb-5 md:col-start-2 md:pt-[0] md:pl-3">
        <div className="no-scrollbar col-start-2 flex snap-x snap-mandatory flex-row gap-5 overflow-x-scroll md:gap-11">
          {horizontalScrollElements.map((element, index) => (
            <div
              className="group relative flex w-[85vw] shrink-0 snap-start flex-col items-end gap-3 overflow-hidden sm:w-[80vw] sm:gap-6 lg:w-[60vw]"
              key={element.id}
            >
              <div className="grid w-full grid-cols-[auto_minmax(1px,1fr)] gap-x-3 gap-y-2 md:gap-x-6 md:gap-y-3">
                <h2 className="col-start-1 self-end text-text-small md:self-auto md:text-display-2">
                  0{index + 1}.
                </h2>

                <h2 className="col-start-2 text-display-2">
                  {element.headline}
                </h2>
                <div className="col-start-2">
                  {element.descriptionField && (
                    <MultilineParagraph
                      content={element.descriptionField}
                      classOverride={
                        'prose-ul:grid-cols-1 prose-ul:gap-y-2 prose-ul:pl-3 prose-li:m-[0] prose-li:marker:text-primary-rystad-blue prose-ul:md:grid-cols-2 prose-p:first:mt-3'
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className="w-[85vw] shrink-0 sm:w-[80vw] lg:w-[60vw]"></div>
        </div>
      </div>
    </Section>
  )
}

export default CareersHorizontalScrollRecord
