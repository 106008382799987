/** @type {import('tailwindcss').Config} */

module.exports = {
  content: [
    './src/components/**/*.{js,ts,jsx,tsx}',
    './src/blocks/**/*.{js,ts,jsx,tsx}',
    './src/pages/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    extend: {
      maxWidth: {
        fullbleed: '1770px',
      },
      borderRadius: {
        large: '40px',
      },
      boxShadow: {
        sm: '0px 1px 3px rgba(0, 0, 0, 0.25)',
        '2xl': [
          '0px 33px 60px rgba(41, 40, 98, 0.08)',
          '0px 16.7062px 26.1562px rgba(41, 40, 98, 0.054)',
          '0px 6.6px 9.75px rgba(41, 40, 98, 0.04)',
          '0px 1.44375px 3.46875px rgba(41, 40, 98, 0.026)',
        ],
      },
      fontFamily: {
        sans: ['studio6-regular'],
        display: ['studio6-medium'],
        body: ['studio6-regular'],
      },
      typography: ({ theme }) => ({
        DEFAULT: {
          css: {
            '--tw-prose-body': 'currentColor',
            '--tw-prose-headings': 'currentColor',
            '--tw-prose-lead': 'currentColor',
            '--tw-prose-links': 'currentColor',
            '--tw-prose-bold': 'currentColor',
            '--tw-prose-counters': 'currentColor',
            '--tw-prose-bullets': 'currentColor',
            '--tw-prose-quotes': 'currentColor',
            '--tw-prose-quote-borders': 'currentColor',
            '--tw-prose-captions': 'currentColor',
            '--tw-prose-code': 'currentColor',
            '--tw-prose-pre-code': 'currentColor',
          },
        },
      }),
    },
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      primary: {
        'rystad-blue': '#292862',
        'rystad-blue-light-tint': '#3B3A84',
        'rystad-blue-light-tint-2': '#B5B4DE',
        orange: '#FFA768',
      },
      brand: {
        pink: '#FF7ECC',
        'data-blue': '#5C75FF',
        'data-blue-light': '#7489FF',
      },
      ines: {
        green: '#DEE69C',
        orange: '#FFC796',
        purple: '#DCC0DA',
        blue: '#9ACAE9',
      },
      greys: [
        '#F6F8FA',
        '#EFEFF6',
        '#D3D2E2',
        '#B1B1C0',
        '#9392AD',
        '#707079',
        '#2B2B2B',
      ],
      lights: {
        sand: '#FFF7E9',
        'sand-2': '#F4F3F1',
        'baby-blue': '#D2E0F2',
        teal: '#D2DBD9',
        pink: '#EDD2E2',
        'teal-border': '#C1D0CD',
      },
      white: '#FFFFFF',
      'dusty-dark-blue': '#1E1E1F',
      'dusty-dark-blue-border': '#313134',
      overlay: 'rgba(20,20,40,0.4)',
      'overlay-menu': 'rgba(6,35,56,0.1)',
    },
    spacing: {
      0: '0px',
      0.5: '4px',
      1: '8px',
      1.5: '12px',
      2: '16px',
      3: '24px',
      4: '32px',
      5: '48px',
      6: '56px',
      7: '64px',
      8: '72px',
      9: '80px',
      10: '88px',
      11: '96px',
    },
    fontSize: {
      'display-1': ['clamp(2.625rem, 2.3147rem + 1.3793vw, 4.125rem)', 1.1],
      'display-2': ['clamp(2.375rem, 2.1422rem + 1.0345vw, 3.5rem)', 1.1],
      'header-1': ['clamp(2.25rem, 2.1983rem + 0.2299vw, 2.5rem)', 1.3],
      'header-2': ['clamp(1.5rem, 1.4741rem + 0.1149vw, 1.625rem)', 1.1],
      'header-2-5': ['clamp(1.375rem, 1.3491rem + 0.1149vw, 1.5rem)', 1.1],
      'header-3': ['clamp(1.125rem, 1.0991rem + 0.1149vw, 1.25rem)', 1.3],
      'header-4': ['clamp(1rem, 0.9741rem + 0.1149vw, 1.125rem)', 1.3],
      'text-large': ['clamp(1.125rem, 1.0991rem + 0.1149vw, 1.25rem)', 1.3],
      'text-base': ['clamp(1rem, 1rem + 0vw, 1rem)', 1.3],
      'text-small': ['clamp(0.75rem, 0.6983rem + 0.2299vw, 1rem)', 1.3],
      'text-x-small': ['clamp(0.6875rem, 0.6487rem + 0.1724vw, 0.875rem)', 1.3],
      'text-manchet': ['clamp(1.25rem, 1.2241rem + 0.1149vw, 1.375rem)', 1.5],
      'text-tagline': ['clamp(0.625rem, 0.5991rem + 0.1149vw, 0.75rem)', 1.5],
    },
    keyframes: {
      widthAnimation: {
        from: { width: '0%' },
        to: { width: '100%' },
      },
    },
    animation: {
      'width-animation': 'widthAnimation 5s infinite',
    },
  },
  plugins: [
    require('@tailwindcss/typography'),
    require('@tailwindcss/line-clamp'),
  ],
}
