type Props = {
  size?: number
}

function MenuLeftArrow({ size }: Props) {
  return (
    <svg
      width={size ?? 7}
      height={size ?? 10}
      viewBox="0 0 8 13"
      fill="none"
    >
      <path
        d="M6.65687 1.00001L1.00001 6.65686L6.65687 12.3137"
        stroke="currentColor"
      />
    </svg>
  )
}

export default MenuLeftArrow
