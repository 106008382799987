import Section from '../../components/section'
import { ClientTestimonialRecord } from '../../generated/graphql'

type Props = {
  block: ClientTestimonialRecord
}

function ClientTestimonialRecord({ block }: Props) {
  return (
    <Section id={block.slug ?? ''} backgroundColor={block.backgroundColorHex!}>
      <div className="col-span-full col-start-1 my-10 flex flex-col gap-4 px-4 md:col-span-6 md:col-start-2 md:my-[160px]">
        <h4>{block.label}</h4>
        <blockquote className="text-header-1 font-medium">
          {block.text}
        </blockquote>
        <span className="text-text-manchet">{block.clientNameAndTitle}</span>
      </div>
    </Section>
  )
}

export default ClientTestimonialRecord
