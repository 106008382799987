const ALL_INSIGHTS_TYPES = `
{
    allInsightsTypeTags {
      id
      __typename
      tagLabel
      dotColor {
        hex
      }
    }
}
`

export default ALL_INSIGHTS_TYPES
