import { useRef } from 'react'
import useIsomorphicEffect from '../../../hooks/use-isomorphic-layout-effect'
import animateTimeline from './animate-timeline'
import AnimatingDots from './animating-dots'
import ServiceLabels from './service-labels'
import { FrontPageServiceScrollAnimationRecord } from '../../../generated/graphql'

type Props = {
  block: FrontPageServiceScrollAnimationRecord
}

function DesktopServicesScrollAnimation({ block }: Props) {
  const trigger = useRef<HTMLDivElement>(null)

  useIsomorphicEffect(() => {
    animateTimeline(trigger)
  }, [])

  return (
    <div ref={trigger} className={'relative'}>
      <ServiceLabels services={block.animationSections} />
      <AnimatingDots />
    </div>
  )
}

export default DesktopServicesScrollAnimation
