import { useEffect, useRef } from 'react'

function useOutsideClick(callback: () => void) {
  const ref = useRef<any>(null)

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (
        ref.current &&
        event.target instanceof HTMLElement &&
        !ref.current.contains(event.target)
      ) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return ref
}

export default useOutsideClick
