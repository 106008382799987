import { DividedFeaturesBlockRecord } from '../generated/graphql'
import Section from '../components/section'
import UnderlinedLink from '../components/underline-link'
import Link from 'next/link'
import { useMenuContext } from './main-menu/menu-context'
import MenuLeftArrow from '../icons/menu-left-arrow'
import FancyUnderlinedLabel from '../components/fancy-underlined-label'

type Props = {
  block: DividedFeaturesBlockRecord
}

function DividedFeatures({ block }: Props) {
  const { dispatch, state } = useMenuContext()

  return (
    <Section>
      <div
        className={
          'col-span-2 flex flex-row items-center justify-between md:col-span-8 lg:hidden'
        }
      >
        <button
          onClick={() => {
            dispatch({
              type: 'setMobileMenuItemBlocks',
              payload: null,
            })
          }}
          className={'flex flex-row items-center gap-1'}
        >
          <MenuLeftArrow />
          Back
        </button>
        <span className={'text-text-base'}>{block.label}</span>
      </div>
      <div
        style={{
          borderColor: state.colorConfig.textUnderlineColor?.hex,
        }}
        className={
          'col-span-2 mt-2 grid grid-cols-1 gap-4 border-b pb-5 pl-2 pr-4 md:col-span-8 md:grid-cols-2 lg:col-span-4 lg:mb-[0] lg:border-b-0 lg:border-r-[1px] lg:pl-[0px]'
        }
      >
        {block.leftSideElements.map((el) => (
          <Link scroll={false} key={el.id} href={el.linkToPage ?? ''}>
            <a>
              <FancyUnderlinedLabel
                underlineColor={state.colorConfig.textUnderlineColor?.hex!}
              >
                {el.title}
              </FancyUnderlinedLabel>
              <p className={'pt-1 text-text-small'}>{el.description}</p>
            </a>
          </Link>
        ))}
      </div>
      <div
        className={
          'col-span-3 flex items-center pt-5 pl-2 lg:col-span-3 lg:col-start-5 lg:flex lg:pt-[0] lg:pl-[0]'
        }
      >
        {block.rightSideElement.map((el) => (
          <div key={el.id}>
            <p className={'text-text-small text-greys-3 lg:mb-3'}>{el.label}</p>
            <h2 className={'mb-5 text-header-2-5'}>{el.headline}</h2>
            <p className={'mb-5 text-text-base'}>{el.description}</p>
            {el.ctas.map((cta) => (
              <div key={cta.id} className={'mb-2'}>
                <UnderlinedLink
                  overrideClasses={'decoration-greys-3'}
                  link={cta.link ?? ''}
                  label={cta.ctaLabel ?? ''}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Section>
  )
}

export default DividedFeatures
