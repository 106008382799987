import Link from 'next/link'
import ArrowRight from '../../../icons/arrow-right'

type Props = {
  link: string
  label: string
}

function NewsLetterRegisterButton({ link, label }: Props) {
  return (
    <Link scroll={false} target={'_blank'} href={link}>
      <a className="rounder-large base group flex cursor-pointer flex-row items-center gap-2 rounded-large bg-primary-rystad-blue-light-tint px-5 py-2 transition hover:bg-white hover:text-primary-rystad-blue-light-tint">
        <span>{label}</span>
        <ArrowRight color={'group-hover:fill-primary-rystad-blue-light-tint'} />
      </a>
    </Link>
  )
}

export default NewsLetterRegisterButton
