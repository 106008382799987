import { twMerge } from 'tailwind-merge'

type Props = {
  content: string
  classOverride?: string
}
function MultilineParagraph({ content, classOverride }: Props) {
  return (
    <div
      className={twMerge(
        'prose max-w-none text-text-manchet prose-headings:text-current prose-headings:first:mt-[0] prose-p:text-current prose-p:first:mt-[0] prose-strong:text-current',
        classOverride
      )}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

export default MultilineParagraph
