import { Image } from 'react-datocms'
import { EmployeeCharacteristicRecord } from '../generated/graphql'

type Props = {
  person: EmployeeCharacteristicRecord
}

function Employee({ person }: Props) {
  return (
    <div className="flex flex-col items-start gap-1">
      {person.profilePicture ? (
        <Image
          style={{ maxWidth: 'none' }}
          data={person.profilePicture?.responsiveImage!}
        />
      ) : (
        <div className="w-full bg-primary-orange pt-[calc(100%*367/310)]"></div>
      )}
      <div className="disable-special-hover flex flex-col gap-1">
        <h3 className="disable-special-hover text-text-manchet">
          {person.name}
        </h3>
        <p className="disable-special-hover text-text-small">
          {person.jobTitle}
        </p>
      </div>
    </div>
  )
}

export default Employee
