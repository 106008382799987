import Link from 'next/link'
import { NewsBannerRecord } from '../../generated/graphql'

type Props = {
  block: NewsBannerRecord
}

const NewsBannerRecord = ({ block }: Props) => {
  const {
    bulletText,
    isLink,
    marker,
    markerColour,
    markerText,
    ribbonColour,
    ribbonTextColour,
    target,
    text,
  } = block

  const isAnchorLink = target?.startsWith('#')

  return (
    <div style={{ backgroundColor: ribbonColour?.hex }}>
      <div className="mx-auto flex max-w-fullbleed items-center gap-2 px-2 py-1 text-text-x-small leading-normal md:px-5">
        <div
          className="h-1 w-1 rounded-full"
          style={{ backgroundColor: markerColour?.hex }}
        ></div>
        <div style={{ color: markerColour?.hex }}>{bulletText}</div>

        {isLink && !isAnchorLink &&
          <Link href={target || '/'}>
            <a>
              <div style={{ color: ribbonTextColour?.hex }}> {text} </div>
              {marker && <Marker isLink markerColor={markerColour?.hex} ribbonTextColor={ribbonTextColour?.hex} text={markerText || ''} />}
            </a>
          </Link>
          } 
          { !isLink && <>
            <div style={{ color: ribbonTextColour?.hex }}> {text} </div>
            {marker && <Marker markerColor={markerColour?.hex} ribbonTextColor={ribbonTextColour?.hex} text={markerText || ''} />}
          </>
        }
        { (isLink && isAnchorLink) && <>
            <a href={target || ''} role={isAnchorLink ? 'button' : ''} style={{ color: ribbonTextColour?.hex }}> {text} </a>
            {marker && <Marker markerColor={markerColour?.hex} ribbonTextColor={ribbonTextColour?.hex} text={markerText || ''} />}
          </>
        }
      </div>
    </div>
  )
}

interface IMarker {
  isLink ? : boolean
  ribbonTextColor? : string
  markerColor? : string
  text? : string
}

const Marker = ({isLink = false, ribbonTextColor, markerColor, text }: IMarker) => (
  <div
    className={`${isLink ? 'cursor-pointer' : ''}  rounded-md px-1 py-[0.5px]`}
    style={{
      backgroundColor: ribbonTextColor,
      color: markerColor,
    }}
  >
    {text}
</div>
)

export default NewsBannerRecord
