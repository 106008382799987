type Props = {
  size?: number
}

const DEFAULT_SIZE = 14

function Close({ size }: Props) {
  return (
    <svg
      width={size ?? DEFAULT_SIZE}
      height={size ?? DEFAULT_SIZE}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10.9497 1.05017L1.05021 10.9497"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9498 10.9497L1.05029 1.05017"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Close
