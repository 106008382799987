import Section from '../../../components/section'
import { useMenuStatus } from '../context'
import { MenuItemType, ServiceBlock } from '../../../types/service-menu-type'
import AllSolutionBlock from './all-solution-block'
import FirstBlock, { SolutionType } from './first-block'
import MenuItemsBlock from './menu-items-block'
import SecondBlock from './second-block'
import SolutionBlock from './solution-block'
import { useMenuContext } from '../../main-menu/menu-context'
import CustomPagesBlock from './custom-pages-block'
import { ServiceMenuCustomFieldRecord } from '../../../generated/graphql'
import { useMemo } from 'react'

type Props = {
  menuItems: MenuItemType[]
  services: ServiceBlock[][]
  customPages: ServiceMenuCustomFieldRecord[]
}

function DesktopServiceMenu({ menuItems, services, customPages }: Props) {
  const { state } = useMenuStatus()
  const { state: menuState } = useMenuContext()

  const isAllSolutionSelected = state.menuItem === 2
  const isNoSolution = state.secondBlock === -1
  const firstBlockTypes: SolutionType[] = useMemo(
    () => ['industry', 'energy', 'solutions'],
    []
  )

  // This to add type to menuItems to be used in MenuItemsBlock component to conditionally remove arrow icon
  const menuItemWithTypes = useMemo(() => {
    return menuItems.map((item, index) => ({
      ...item,
      type: firstBlockTypes[index] ?? null,
    }))
  }, [menuItems, firstBlockTypes])

  return (
    <Section>
      <MenuItemsBlock
        items={menuItemWithTypes}
        selectColor={menuState.colorConfig.serviceItemHoverColor?.hex}
      />
      <>
        {isAllSolutionSelected ? (
          <AllSolutionBlock items={services[state.menuItem]} />
        ) : (
          <>
            <>
              {isNoSolution && (
                <CustomPagesBlock
                  displayProductDemo={
                    menuItems[state.menuItem]?.displayProductDemo
                  }
                  pages={customPages}
                  underlineColor={menuState.colorConfig.textUnderlineColor?.hex}
                />
              )}
            </>
            {state.menuItem !== -1 && (
              <>
                <FirstBlock
                  type={firstBlockTypes[state.menuItem]}
                  items={services[state.menuItem]}
                  selectColor={menuState.colorConfig.menuTextColor?.hex}
                  unselectColor={menuState.colorConfig.unselectedTextColor?.hex}
                  underlineColor={menuState.colorConfig.textUnderlineColor?.hex}
                />
                <SecondBlock
                  items={
                    services[state.menuItem]?.[state.firstBlock]?.nextBlock
                  }
                  selectColor={menuState.colorConfig.menuTextColor?.hex}
                  unselectColor={menuState.colorConfig.unselectedTextColor?.hex}
                />
                <SolutionBlock
                  underlineColor={menuState.colorConfig.textUnderlineColor?.hex}
                  hoverColor={menuState.colorConfig.serviceItemHoverColor?.hex}
                  items={
                    services[state.menuItem]?.[state.firstBlock]?.nextBlock[
                      state.secondBlock
                    ]?.solutions
                  }
                />
              </>
            )}
          </>
        )}
      </>
    </Section>
  )
}

export default DesktopServiceMenu
