import Link from 'next/link'
import { Image } from 'react-datocms'
import Section from '../../components/section'
import { CareersSpotlightModuleRecord } from '../../generated/graphql'
import { isEmployeeCharacteristicRecord } from '../team-grid/team-grid'
import AreaWithCursor from '../../components/area-with-cursor'

type Props = {
  block: CareersSpotlightModuleRecord
}

function CareersSpotlightModule({ block }: Props) {
  const spotlights = block.talentSpotlights
  const employeeCharacteristics = spotlights
    .map(
      (spotlight) =>
        spotlight?.spotlightEmployeeNamePositionImage?.contentLayout[0]!
    )
    .filter(isEmployeeCharacteristicRecord)

  return (
    <Section
      backgroundColor={block.backgroundColorHex}
      classOverride={`relative`}
      id={block?.slug || ''}
    >
      {spotlights?.map((spotlight, index) => (
        <Link
          scroll={false}
          key={spotlight.id}
          href={`/spotlights/${spotlight.slug}`}
        >
          <button
            key={spotlight.id}
            className={`relative col-span-2 text-left md:col-span-4`}
          >
            <AreaWithCursor cursorLabel={'View'} isCursorHidden={false}>
              <div className="top-20 absolute z-10 gap-4 p-4 font-body text-white">
                <p className="mb-2 text-text-small md:text-text-base">
                  {spotlight.label}
                </p>
                <h2 className="mb-1 text-text-large md:text-header-2-5">
                  {employeeCharacteristics[index]?.name}
                </h2>
                <p className="text-text-base md:text-text-large">
                  {employeeCharacteristics[index]?.jobTitle}
                </p>
              </div>
              {employeeCharacteristics[index]?.profilePicture && (
                <Image
                  style={{ maxWidth: 'none' }}
                  data={
                    employeeCharacteristics[index]?.profilePicture
                      ?.responsiveImage!
                  }
                />
              )}
            </AreaWithCursor>
          </button>
        </Link>
      ))}
    </Section>
  )
}

export default CareersSpotlightModule
