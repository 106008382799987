import { createContext, useState } from 'react'

// Create a new context
const EventContext = createContext({
  events: { pastEvents: [], upcomingEvents: [] },
  setEvents: (event: any) => {},
  filterData: { salesRegions: [], industries: [], energyThemes: [] },
  setFilterData: (filterData: any) => {},
})

// Create a custom Provider component to set and manage the theme
export const EventProvider = ({
  children,
  initialFilterData,
  initialEvents,
}: any) => {
  const [events, setEvents] = useState(initialEvents)
  const [filterData, setFilterData] = useState(initialFilterData)

  return (
    <EventContext.Provider
      value={{ events, setEvents, filterData, setFilterData }}
    >
      {children}
    </EventContext.Provider>
  )
}

export default EventContext
