import { Fragment } from 'react'
import Section from '../../components/section'
import DesktopCard from './desktop-card'
import MobileCard from './mobile-card'
import { CardGridRecord } from '../../generated/graphql'

type Props = {
  block: CardGridRecord
}
function CardGrid({ block }: Props) {
  return (
    <Section id={block.slug || ''}>
      <h2 className="col-span-full col-start-1 text-left text-header-2 lg:col-span-2">
        {block.title}
      </h2>
      <p className="col-span-full text-text-manchet lg:col-span-full lg:col-start-4">
        {block.description}
      </p>
      <div className="col-span-2 grid grid-cols-1 gap-2 py-[32px] sm:col-span-8 sm:grid-cols-2 sm:gap-4 md:py-8 lg:grid-cols-3 lg:py-[104px]">
        {block.cards.map((card) => (
          <Fragment key={card.id}>
            <DesktopCard card={card} />
            <MobileCard card={card} />
          </Fragment>
        ))}
      </div>
    </Section>
  )
}

export default CardGrid
