import { ReactNode, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { gsap } from '../../lib/gsap'

type Props = {
  tabs: { label: string; content: ReactNode }[]
}

function Tabs({ tabs }: Props) {
  const [openTabIndex, setOpenTabIndex] = useState(0)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    gsap.to(ref.current, {
      xPercent: -openTabIndex * 100,
      duration: 0.8,
      ease: 'Power3.easeOut',
    })
  }, [openTabIndex])

  return (
    <div>
      <div className={'flex flex-row gap-2'}>
        {tabs.map((tab, index) => (
          <button
            className={classNames('rounded-3xl px-2 py-1', {
              'bg-greys-2': index === openTabIndex,
            })}
            onClick={() => setOpenTabIndex(index)}
            key={tab.label}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className={'overflow-hidden'}>
        <div ref={ref} className={'flex flex-row py-2'}>
          {tabs.map((tab) => (
            <div key={tab.label} className={'w-full shrink-0 pr-2'}>
              {tab.content}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Tabs
