import CtaLink from '../../components/cta-link'
import Section from '../../components/section'
import { PressReleaseOverviewNewsletterRecord } from '../../generated/graphql'

type Props = {
  block: PressReleaseOverviewNewsletterRecord
}

function PressReleaseOverviewNewsletter({ block }: Props) {
  return (
    <Section>
      <div className="z-10 col-span-2 mt-5">
        <h2
          className="text-header-2"
          style={{ color: block.textColorHex?.hex }}
        >
          {block.headline}
        </h2>
      </div>

      <div className="relative z-10 col-span-4 mt-1 mb-5 flex flex-col gap-5 sm:mt-5 sm:gap-9">
        <h2
          className="max-w-xl text-text-large"
          style={{ color: block.textColorHex?.hex }}
        >
          {block.description}
        </h2>

        <div className="z-10 flex flex-col items-start gap-4 md:flex-row md:items-center">
          {block?.newsletterCta?.map((newsLetter) => (
            <CtaLink cta={newsLetter} key={newsLetter.id} />
          ))}
        </div>
      </div>
    </Section>
  )
}

export default PressReleaseOverviewNewsletter
