import { gsap, ScrollTrigger } from '../../../lib/gsap'
import { RefObject } from 'react'
import getBBox from '../../../utils/get-b-box'

function animateTimeline(trigger: RefObject<HTMLDivElement>) {
  const selector = gsap.utils.selector(trigger)

  function animateCircles(
    scale: number,
    opacity: number,
    xFactor: number,
    yFactor: number
  ) {
    return {
      opacity: opacity,
      scale: scale,
      duration: 0.2,
      x: function (index: number, el: SVGElement) {
        const enlargedDot = selector('.enlarging-dot')
        const centerX =
          getBBox(enlargedDot[0]).x + getBBox(enlargedDot[0]).width / 2
        const elX = getBBox(el).x
        return `-=${xFactor * (centerX - elX)}`
      },
      y: function (index: number, el: SVGElement) {
        const enlargedDot = selector('.enlarging-dot')
        const centerY =
          getBBox(enlargedDot[0]).y + getBBox(enlargedDot[0]).height / 2
        const elY = getBBox(el).y
        return `-=${yFactor * (centerY - elY)}`
      },
    }
  }

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: trigger.current,
      scrub: true,
      start: 'top 80%',
      end: '400%',
      anticipatePin: 1,
    },
  })

  tl.set(selector('.inner-circle, .outer-circle'), {
    opacity: 0,
    scale: 1,
    duration: 0.5,
  })
    .fromTo(
      selector('.particle'),
      {
        y: 'random(-100, 100)',
        x: 'random(-400, 100)',
        scale: 'random(0.3, 0.7)',
        opacity: 'random(0.3, 1)',
        duration: 0.5,
      },
      {
        y: 'random(-100, 100)',
        x: 'random(-400, 100)',
        scale: 'random(0.3, 0.7)',
        opacity: 'random(0.3, 1)',
        duration: 0.5,
      }
    )
    .to(selector('.particle'), {
      y: 0,
      opacity: 1,
      x: 0,
      scale: 1,
      stagger: {
        each: 0.01,
        grid: 'auto',
        ease: 'power2.inOut',
      },
      curation: 0.5,
    })
    .addLabel('particles-aligned-to-columns')
    .to(
      selector('.labels'),
      {
        yPercent: -25,
        duration: 0.5,
      },
      'particles-aligned-to-columns-=1'
    )
    .to(selector('.label-1'), { opacity: 0, duration: 0.5 }, '<')
    .from(selector('.label-2'), { opacity: 0, duration: 0.5 }, '<')
    .addLabel('analytics-label-rolled-in')
    .to(selector('.enlarging-dot'), {
      scale: 10,
      transformOrigin: '50% 50%',
      duration: 0.2,
    })
    .addLabel('first-dot-enlarged')
    .to(
      selector('.column'),
      {
        y: 'random(-100, 100)',
        x: 'random(-400, 100)',
        scale: 'random(0.3, 0.7)',
        duration: 0.2,
      },
      '<'
    )

    .addLabel('columns-re-scattered')

    .to(
      selector('.labels'),
      {
        yPercent: -50,
        duration: 0.2,
      },
      '>-0.5'
    )
    .to(selector('.label-2'), { opacity: 0, duration: 0.2 }, '<')
    .from(selector('.label-3'), { opacity: 0, duration: 0.2 }, '<')

    .addLabel('customizing-label-rolled-in')

    .to(selector('.enlarge-layer-1'), {
      scale: 14,
      transformOrigin: '50% 50%',
      duration: 0.2,
    })
    .addLabel('second-dot-enlarged')
    .to(selector('.enlarge-layer-2'), {
      scale: 18,
      transformOrigin: '50% 50%',
      duration: 0.2,
    })
    .addLabel('third-dot-enlarged')
    .to(
      selector(
        '.particle:not(.enlarging-dot):not(.enlarge-layer-2):not(.enlarge-layer-1)'
      ),
      {
        duration: 0.2,
        x: function (index, el) {
          const enlargedDot = selector('.enlarging-dot')
          const centerX =
            getBBox(enlargedDot[0]).x + getBBox(enlargedDot[0]).width / 2
          const elX = getBBox(el).x
          return `-=${3 * (centerX - elX)}`
        },
        y: function (index, el) {
          const enlargedDot = selector('.enlarging-dot')
          const centerY =
            getBBox(enlargedDot[0]).y + getBBox(enlargedDot[0]).height / 2
          const elY = getBBox(el).y
          return `-=${3 * (centerY - elY)}`
        },
      },
      'customizing-label-rolled-in'
    )
    .to(selector('.enlarge-layer-1'), {
      scale: 0,
      transformOrigin: '50% 50%',
      duration: 0.2,
    })
    .addLabel('second-dot-enlarged')
    .to(selector('.enlarge-layer-2'), {
      scale: 0,
      transformOrigin: '50% 50%',
      duration: 0.2,
    })
    .addLabel('third-dot-enlarged')
    .to(selector('.enlarging-dot'), {
      scale: 1,
      transformOrigin: '50% 50%',
      fill: '#50509F',
      opacity: 0.5,
      duration: 0.2,
    })
    .to(
      selector('.labels'),
      {
        yPercent: -75,
        duration: 0.2,
      },
      '>-0.5'
    )
    .to(selector('.label-3'), { opacity: 0, duration: 0.2 }, '<')
    .from(selector('.label-4'), { opacity: 0, duration: 0.2 }, '<')
    .addLabel('education-label-rolled-in')
    .to(selector('.inner-circle'), animateCircles(4, 1, 3, 3))
    .to(selector('.outer-circle'), animateCircles(1.7, 1, 0.6, 0.6))

  ScrollTrigger.create({
    trigger: trigger.current,
    start: 'top top',
    end: '400%',
    pin: true,
  })
}

export default animateTimeline
