import React, { useState, useCallback, useMemo } from 'react'
import Input from '../text-input'
import DropdownSelect from '../dropdown-select'
import Button from '../button'
import { SignUpFreeTrialRecord } from '../../generated/graphql'
import FormInfo from '../../types/Form'
import useSolution from '../../hooks/use-solution'

type Props = {
  block: SignUpFreeTrialRecord
  setFormInfo: (value: any) => void
  formInfo: FormInfo
  setFormCurrentStep: (step: string) => void
}

type Solution = {
  id: number
  name: string
}

const FormFirstStep = ({
  block,
  setFormInfo,
  formInfo,
  setFormCurrentStep,
}: Props) => {
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const solutionData = useSolution()

  const solutions = useMemo(() => {
    if (!solutionData?.data) return null
    const { solutions } = solutionData.data
    return solutions.sort((a: Solution, b: Solution) =>
      a.name > b.name ? 1 : -1
    )
  }, [solutionData])

  const validate = useCallback(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setFirstNameError(formInfo.firstName ? '' : 'First name is required')
    setLastNameError(formInfo.lastName ? '' : 'Last name is required')
    setEmailError(
      !formInfo.businessEmail || !emailRegex.test(formInfo.businessEmail)
        ? 'Invalid email'
        : ''
    )
    if (
      formInfo.firstName &&
      formInfo.lastName &&
      formInfo.businessEmail &&
      emailRegex.test(formInfo.businessEmail)
    ) {
      setFormCurrentStep('secondStep')
    }
  }, [formInfo, setFormCurrentStep])

  return (
    <form className="flex flex-col gap-[15px] lg:gap-[32px]">
      <DropdownSelect
        label="Solution"
        placeholder="Choose solution"
        data={solutions ?? []}
        onSelect={(value) =>
          setFormInfo({ ...formInfo, solution: [Number(value)] })
        }
      />
      <div className="flex flex-col gap-[15px] lg:flex-row lg:gap-[35px]">
        <Input
          label="First Name *"
          placeholder="First Name"
          name="firstName"
          type="text"
          error={firstNameError}
          onChange={(e) => {
            setFormInfo({ ...formInfo, firstName: e.target.value })
            setFirstNameError('')
          }}
        />
        <Input
          label="Last Name *"
          placeholder="Last Name"
          type="text"
          error={lastNameError}
          onChange={(e) => {
            setFormInfo({ ...formInfo, lastName: e.target.value })
            setLastNameError('')
          }}
        />
      </div>
      <Input
        label="Business Email *"
        placeholder="Business Email"
        type="text"
        error={emailError}
        onChange={(e) => {
          setFormInfo({ ...formInfo, businessEmail: e.target.value })
          setEmailError('')
        }}
      />
      <Button
        className="w-fit"
        text={block.firstStepButtonText ?? ''}
        onClick={validate}
      />
    </form>
  )
}

export default FormFirstStep
