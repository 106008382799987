import { useEffect, useState } from 'react'
import usePaywallStore from '../stores/use-paywall-store'

function useIsPaywallKeyUnlocked(key: string) {
  const [isKeyAllowed, setIsKeyAllowed] = useState(false)
  const allowedKeysList = usePaywallStore((state) => state.allowedKeys)

  useEffect(() => {
    const isKeyIncluded = allowedKeysList.includes(key)
    if (isKeyIncluded) {
      setIsKeyAllowed(true)
    }
  }, [allowedKeysList, key])

  return isKeyAllowed
}

export default useIsPaywallKeyUnlocked
