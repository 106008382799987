import { gsap } from '../../lib/gsap'
import { RefObject, MouseEvent } from 'react'
import areCoordinatesFartherThan from '../../utils/are-coordinates-farther-than'
import Dot from '../../types/Dot'

const MAGNET_DISTANCE = 200
const CLOSEST_DISTANCE_FROM_CURSOR = 10
const FOLLOWING_ANIMATION_DURATION = 4

function animateCursorFollowing(
  stage: RefObject<HTMLDivElement>,
  dots: Dot[],
  e: MouseEvent<HTMLDivElement>
) {
  gsap.to('.circle', {
    duration: FOLLOWING_ANIMATION_DURATION,
    left: function (i, element) {
      const shouldAnimateToStartingPosition = areCoordinatesFartherThan(
        e.clientX,
        dots[i].x,
        e.clientY,
        dots[i].y,
        MAGNET_DISTANCE
      )
      if (shouldAnimateToStartingPosition) return dots[i].x
      return e.clientX - CLOSEST_DISTANCE_FROM_CURSOR
    },
    top: function (i, element) {
      const shouldAnimateToStartingPosition = areCoordinatesFartherThan(
        e.clientX,
        dots[i].x,
        e.clientY,
        dots[i].y,
        MAGNET_DISTANCE
      )
      if (shouldAnimateToStartingPosition) return dots[i].y
      return e.clientY - CLOSEST_DISTANCE_FROM_CURSOR
    },
  })
}

export default animateCursorFollowing
