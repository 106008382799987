import Close from '../icons/close'
import { useRef } from 'react'

type Props = {
  value: string
  setValue: (value: string) => void
}

function DeletableTextInput({ value, setValue }: Props) {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className={'relative'}>
      <input
        autoFocus
        ref={inputRef}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        type="text"
        className={
          'w-full rounded border border-greys-3 p-2 pr-5 bg-transparent text-current focus:outline-primary-rystad-blue'
        }
      />
      <button
        onClick={() => {
          setValue('')
          inputRef.current?.focus()
        }}
        className={'absolute top-1/2 right-3 -translate-y-1/2'}
      >
        <Close />
      </button>
    </div>
  )
}

export default DeletableTextInput
