import { useEffect, useRef, useState } from 'react'
import { useMenuVisibility } from '../../contexts/menu-visibility-context'
import { StickyGroupRecord } from '../../generated/graphql'
import NewsBanner from '../news-banner-record'
import SolutionSubmenu from '../solution-submenu'
import { ScrollTrigger } from '../../lib/gsap'

type Props = {
  block: StickyGroupRecord
}

const StickyGroup = ({ block }: Props) => {
  const stickyElement = useRef<HTMLDivElement | null>(null)
  const [isMenuShown] = useMenuVisibility()
  const navElement = useRef<HTMLElement | null>(null)

  useEffect(() => {
    if (stickyElement.current) {
      ScrollTrigger.create({
        trigger: stickyElement.current,
        start: 'top top',
        end: 999999,
        pin: true,
        pinSpacing: false,
      })
    }

    navElement.current = document.querySelector('nav')
  }, [])

  useEffect(() => {
    if (!stickyElement.current || !navElement.current) return

    const { top: t } = stickyElement.current.getBoundingClientRect()

    if (t > navElement.current.clientHeight) {
      stickyElement.current.style.transform = 'translateY(0px)'
      return
    }

    if (isMenuShown) {
      stickyElement.current.style.transform =
        `translateY(${navElement.current?.clientHeight - 2}px)` ||
        'translateY(0px)'
      return
    }

    stickyElement.current.style.transform = 'translateY(0px)'
  }, [isMenuShown])

  return (
    <div
      ref={stickyElement}
      className={`z-[35] transform bg-white transition-transform duration-700`}
    >
      {block.elements.map((element) => {
        switch (element.__typename) {
          case 'NewsBannerRecord':
            return <NewsBanner block={element} />

          case 'SolutionSubMenuRecord':
            return <SolutionSubmenu block={element} />

          default:
            return (
              <div className={'my-3 bg-primary-orange p-3 text-white'}>
                {block.__typename} not implemented!
              </div>
            )
        }
      })}
    </div>
  )
}

export default StickyGroup
