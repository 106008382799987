import { Maybe } from 'graphql/jsutils/Maybe'

type Props = {
  dotColor?: string
  label?: Maybe<string>
}
function DotWithLabel({ dotColor, label }: Props) {
  return (
    <div className="flex items-center gap-1 text-text-x-small">
      <div
        style={{
          backgroundColor: dotColor,
        }}
        className={`h-1 w-1 shrink-0 rounded-full`}
      ></div>
      <div>{label}</div>
    </div>
  )
}

export default DotWithLabel
