import { SolutionHowWeDeliverValueModuleRecord } from '../../generated/graphql'
import Section from '../../components/section'
import Tabs from '../../components/tabs'
import HowWeDeliverItemContent from './how-we-deliver-item-content'

type Props = {
  block: SolutionHowWeDeliverValueModuleRecord
}

function SolutionHowWeDeliverValue({ block }: Props) {
  return (
    <Section
      backgroundColor={block.backgroundColorHex}
      classOverride={'py-7 md:py-5'}
      id={block.slug || ''}
    >
      <div className={'col-span-3 mb-4 text-header-2'}>{block.headline}</div>
      <div className={'col-span-4'} id={block.slug ?? ''}>
        <Tabs
          tabs={block.tabs.map((tab) => ({
            label: tab.title ?? '',
            content: <HowWeDeliverItemContent tab={tab} />,
          }))}
        />
      </div>
    </Section>
  )
}

export default SolutionHowWeDeliverValue
