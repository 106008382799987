type Props = {
  className?: string
  size?: number | string
}

function CircledCheck({ className, size }: Props) {
  return (
    <svg
      className={className}
      width={size ?? '16'}
      height={size ?? '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.99966 7.18198L7.47453 9.65685L10.6565 6.47487"
        stroke="#292862"
      />
      <circle cx="8" cy="8" r="7.5" stroke="#292862" />
    </svg>
  )
}

export default CircledCheck
