import {
  CtaBlockModelCtaLinkField,
  CtaBlockRecord,
  CtaLinkRecord,
  FileDownloadLinkRecord,
} from '../../generated/graphql'
import Section from '../../components/section'
import Button from '../../components/button'
import CtaLink from '../../components/cta-link'
import useMediaQuery from '../../hooks/use-media-query'

type Props = {
  block: CtaBlockRecord
}

function isPdfFile(
  link: CtaBlockModelCtaLinkField
): link is FileDownloadLinkRecord {
  return link.__typename === 'FileDownloadLinkRecord'
}

function isCtaLink(link: CtaBlockModelCtaLinkField): link is CtaLinkRecord {
  return link.__typename === 'CtaLinkRecord'
}

function Cta({ block }: Props) {
  const isSmallScreen = useMediaQuery('(max-width: 768px)')
  return (
    <Section
      backgroundColor={isSmallScreen ? block.backgroundColorHex : undefined}
      id={block?.slug || ''}
    >
      <div
        style={{
          backgroundColor: !isSmallScreen
            ? block.backgroundColorHex?.hex
            : undefined,
        }}
        className={'col-span-full flex flex-col justify-center'}
      >
        <div
          className={`col-span-full col-start-1 mb-2 flex flex-col items-center text-center md:col-span-4 md:col-start-3`}
        >
          <div className={'px-[3vw] py-11'}>
            <h2 className={'mb-2 text-header-2'}>{block.headline}</h2>
            <p className={'text-text-base'}>{block.description}</p>
            <div
              className={
                'mt-10 flex flex-col items-center justify-center gap-2 md:flex-row md:flex-wrap'
              }
            >
              {block.ctaLink.map((link) => (
                <div key={link.id}>
                  {isCtaLink(link) && <CtaLink cta={link} />}
                  {isPdfFile(link) && (
                    <Button
                      text={link.label!}
                      variant={
                        link.buttonStyle === 'filled'
                          ? 'primary'
                          : 'primary-outlined'
                      }
                      to={link.pdfFile?.url!}
                      target={'_blank'}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Cta
