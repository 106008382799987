import CtaLink from '../../../components/cta-link'
import Section from '../../../components/section'
import { PreFooterBlockRecord } from '../../../generated/graphql'

type Props = {
  block: PreFooterBlockRecord
}
function PreFooterBlockRecord({ block }: Props) {
  const footerElements = block.preFooter?.preFooterElements
  return (
    <Section backgroundColor={block.backgroundColorHex} id={block?.slug || ''}>
      <div className="col-span-full mt-5 flex grid-cols-3 flex-col gap-5 lg:grid">
        {footerElements?.map((element, index) => (
          <div
            className={`flex flex-col gap-4 ${
              index != footerElements.length - 1
                ? 'border-b pr-4 lg:border-r lg:border-b-0'
                : 'border-none'
            } border-primary-rystad-blue-light-tint text-white`}
            key={element.id}
          >
            <div className="flex flex-col gap-2">
              <h2 className="text-header-3">{element.headline}</h2>
              <p>{element.text}</p>
            </div>
            <div className="flex pb-5">
              <CtaLink cta={element.ctaLink[0]} />
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default PreFooterBlockRecord
