import { useRouter } from 'next/router'
import { SolutionPageRecord } from '../../generated/graphql'

const useServicesCounter = (solutions: SolutionPageRecord[]) => {
  const router = useRouter()
  if (!solutions || solutions.length === 0) {
    return 0
  }

  const path = router.pathname.split('/')
  const slug = router.query.slug?.toString().toLowerCase() ?? ''

  if (path[2] === 'industry') {
    const solutionsList = solutions
      .filter((solution) =>
        solution.industries.some((i) => i.slug!.toLowerCase() === slug)
      )
      .map((solutions) => solutions.title)

    return [...new Set(solutionsList)].length
  }

  if (path[2] === 'energytype') {
    const solutionList = solutions
      .filter((solution) =>
        solution.energyTypes.some((e) => {
          return e.slug!.toLowerCase() === slug
        })
      )
      .map((solution) => solution.title)
    return [...new Set(solutionList)].length
  }

  if (path[1] === 'services') {
    return [...new Set(solutions.map((solution) => solution.title))].length
  }
}

export default useServicesCounter
