import Block from '../../../types/Block'
import Blocks from '../../blocks'
import Button from '../../../components/button'
import Link from 'next/link'
import RystadLogoWithTypo from '../../../icons/rystad-logo-with-typo'
import { ScrollState, useMenuContext } from '../menu-context'
import SearchWidget from '../search-widget'
import MenuItem from './menu-item'

function DesktopMenu() {
  const {
    dispatch,
    state: { scrollState, block, desktopMenuItemBlocks, colorConfig },
  } = useMenuContext()

  const setDesktopMenuItemBlocks = (payload: null | Block[]) => {
    dispatch({
      type: 'setDesktopMenuItemBlocks',
      payload,
    })
  }

  return (
    <>
      <div
        style={{
          borderColor:
            scrollState === ScrollState.white
              ? colorConfig.textUnderlineColor?.hex
              : 'transparent',
        }}
        className={`mx-auto flex max-w-fullbleed flex-row items-center justify-between gap-2 border-b px-5`}
      >
        <div className="flex flex-row items-center gap-5">
          <Link scroll={false} href="/">
            <a>
              <RystadLogoWithTypo />
            </a>
          </Link>

          <SearchWidget />
        </div>

        <div className={'flex flex-row items-center justify-between flex-1'}>
          <div className="mr-1 flex h-full flex-row xl:mr-5">
            <ul className="flex h-full gap-2 text-text-small">
              {block?.menuItems.map((item) => (
                <li
                  className="group relative flex h-full h-[80px] flex-col justify-center"
                  key={item.label}
                >
                  <div
                    style={{
                      backgroundColor: colorConfig.menuTextColor?.hex,
                    }}
                    className={
                      'absolute bottom-[-2px] h-[2px] w-full opacity-0 transition-opacity duration-[400] group-hover:opacity-100'
                    }
                  />
                  <div className="flex flex-row items-center gap-1 text-text-base">
                    <MenuItem
                      onMouseEnter={() =>
                        setDesktopMenuItemBlocks(item.menuItemContent)
                      }
                      menuItem={item}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-row items-center gap-3">
            <Link scroll={false} href="/contact">
              <a className={'text-text-small'}>Contact</a>
            </Link>
            {block?.signInButton?.map((signInButton) => (
              <Button
                key={signInButton?.id}
                isSmall
                variant={'current-outlined'}
                to={signInButton?.link ?? ''}
                text={signInButton?.ctaLabel ?? ''}
              />
            ))}
          </div>
        </div>
      </div>
      {desktopMenuItemBlocks && desktopMenuItemBlocks?.length > 0 && (
        <div onMouseLeave={() => setDesktopMenuItemBlocks(null)}>
          <Blocks blocks={desktopMenuItemBlocks} />
        </div>
      )}
    </>
  )
}

export default DesktopMenu
