import { CategoryPageIntroDescriptionRecord } from '../../../generated/graphql'
import useMediaQuery from '../../../hooks/use-media-query'
import IntroDescriptionRecord from '../intro-description-record'
import MobileIntroDescription from './mobile-intro-description'

type Props = {
  block: CategoryPageIntroDescriptionRecord
}

function OverVideoIntroDescriptionRecord({ block }: Props) {
  const isSmallScreen = useMediaQuery('(max-width: 768px)')
  if (isSmallScreen) {
    return <MobileIntroDescription block={block} />
  } else {
    return <IntroDescriptionRecord block={block} />
  }
}

export default OverVideoIntroDescriptionRecord
