import Link from 'next/link'
import ArrowRightButton from '../components/arrow-right-button'
import Section from '../components/section'
import { PressReleasePageSpotlightArticleRelatedNewsRecord } from '../generated/graphql'
import formatDate from '../utils/format-date'
import { isPressReleaseArticleRecord } from './press-release-thumbnail/press-release-thumbnail'
import Dynamic from '../components/dynamic'

type Props = {
  block: PressReleasePageSpotlightArticleRelatedNewsRecord
}

function PressReleaseRelatedNews({ block }: Props) {
  const relatedNews = block.relatedNewsOtherSpotlights

  return (
    <Section>
      <div className="col-span-2 mt-2 mb-5 overflow-hidden sm:col-span-8 sm:-mb-1">
        <h2 className="py-1 text-header-2">
          Related News
          <sup className="font-light">{relatedNews.length}</sup>
        </h2>

        <div className="no-scrollbar mt-3 flex snap-x snap-mandatory flex-col gap-6 overflow-x-scroll sm:mt-4 sm:flex-row">
          {relatedNews.map((news) => {
            const article = news.blocks.find((block) =>
              isPressReleaseArticleRecord(block)
            )

            return (
              <Link scroll={false} key={news.id} href={`/news/${news.slug}`}>
                <div
                  key={news.id}
                  className=" group relative flex shrink-0 cursor-pointer snap-start flex-row overflow-hidden sm:w-[80vw] sm:gap-2 lg:w-[30vw]"
                >
                  <div>
                    <div
                      className={
                        'absolute top-[-1px] h-[2px] w-full bg-greys-1'
                      }
                    />

                    <div
                      className={
                        'absolute top-[-1px] h-[2px] w-full -translate-x-full bg-primary-rystad-blue transition-transform duration-500 group-hover:translate-x-[0]'
                      }
                    />
                  </div>

                  <div className="mt-4 flex flex-col justify-between gap-3 md:mt-5">
                    <h2 className="text-header-3 line-clamp-3">
                      {article &&
                        isPressReleaseArticleRecord(article) &&
                        article.headline}
                    </h2>

                    <div className="flex flex-row items-center justify-start gap-2">
                      <div className="flex flex-row items-center gap-1">
                        <div
                          style={{
                            backgroundColor: news?.tag?.dotColor?.hex!,
                          }}
                          className={`h-1 w-1 shrink-0 rounded-full`}
                        ></div>
                        <h2 className="text-text-small text-greys-2">
                          {news?.energyType?.name}
                        </h2>
                      </div>
                      {news?.publicationDate && (
                        <h2 className="text-text-small text-greys-3 md:text-primary-rystad-blue">
                          <Dynamic>
                            {formatDate(news.publicationDate, 'full')}
                          </Dynamic>
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>

        <div className="mt-7 block sm:hidden">
          <ArrowRightButton link="/news" variant="primary">
            More news
          </ArrowRightButton>
        </div>
      </div>
    </Section>
  )
}

export default PressReleaseRelatedNews
