import { useRouter } from 'next/router'
import useDebouncedValue from './use-debounced-value'
import { useEffect } from 'react'

function useURLSearch(): [string, (value: string) => void] {
  const router = useRouter()

  const eventHandler = (query: string) => {
    if (!query) return

    router.push('/search?q=' + query, undefined, {
      shallow: router.pathname.includes('search'),
    })
  }

  const [inputValue, setInputValue] = useDebouncedValue(
    eventHandler,
    router.query.q?.toString(),
    router.isReady
  )

  useEffect(() => {
    if (!router.query.q) return
    setInputValue(router.query.q?.toString() ?? '')
  }, [router.query.q])

  return [inputValue, setInputValue]
}

export default useURLSearch
