import {
  ContactsSliderWithCtaRecord,
  EmployeeCharacteristicRecord,
} from '../../generated/graphql'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import ArrowRightButton from '../../components/arrow-right-button'
import EmployeeWithContactLinks from '../../components/employee-with-contact-links'
import { Draggable } from '../../lib/gsap'
import { useRef } from 'react'
import AreaWithCursor from '../../components/area-with-cursor'
import useMediaQuery from '../../hooks/use-media-query'

type Props = {
  block: ContactsSliderWithCtaRecord
}

function ContactsSliderWithCta({ block }: Props) {
  const employees = block.contacts
  const bounds = useRef<HTMLDivElement>(null)
  const dragged = useRef<HTMLDivElement>(null)
  const isSmallScreen = useMediaQuery('(max-width: 767px)')

  useIsomorphicLayoutEffect(() => {
    if (!dragged.current || !bounds.current) return
    Draggable.create(dragged.current, {
      type: 'x',
      bounds: {
        minX: 0,
        maxX: isSmallScreen
          ? bounds.current.clientWidth - dragged.current.scrollWidth
          : dragged.current.clientWidth - dragged.current.scrollWidth,
      },
      cursor: employees && employees.length < 3 ? 'auto' : 'grab',
    })
  }, [])

  return (
    <section
      ref={bounds}
      id={block?.slug || ''}
      className={
        'left-aligned-fullbleed isolate grid grid-cols-2 gap-2 overflow-x-hidden py-2 pl-2 md:grid-cols-8 md:gap-5 md:py-5 md:pl-5'
      }
    >
      <div className={'col-span-2 md:col-span-3'}>
        <h2 className={'mb-5 text-header-2 md:mb-10'}>{block.headline}</h2>
        <div className={'hidden flex-col gap-2 md:flex'}>
          {block.callToActions.map((cta) => (
            <ArrowRightButton key={cta.ctaLabel} link={cta.link!}>
              {cta.ctaLabel}
            </ArrowRightButton>
          ))}
        </div>
      </div>
      <div
        className={
          'z-50 col-span-2 cursor-none overflow-x-hidden  md:col-span-5'
        }
      >
        {employees && employees.length < 3 ? (
          <div
            ref={dragged}
            className={'flex cursor-none flex-row'}
            style={{
              cursor: 'none !important',
            }}
          >
            {employees.map((item) => (
              <div
                className={'w-5/6 shrink-0 bg-white p-3 md:w-2/5'}
                key={item.id}
              >
                <EmployeeWithContactLinks
                  employee={
                    item.contentLayout[0] as EmployeeCharacteristicRecord
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          <AreaWithCursor
            isCursorHidden
            cursorLabel={block.cursorLabel || 'Drag'}
          >
            <div
              ref={dragged}
              className={'flex cursor-none flex-row'}
              style={{
                cursor: 'none !important',
              }}
            >
              {employees.map((item) => (
                <div
                  className={'w-5/6 shrink-0 bg-white p-3 md:w-2/5'}
                  key={item.id}
                >
                  <EmployeeWithContactLinks
                    employee={
                      item.contentLayout[0] as EmployeeCharacteristicRecord
                    }
                  />
                </div>
              ))}
            </div>
          </AreaWithCursor>
        )}
      </div>
    </section>
  )
}

export default ContactsSliderWithCta
