import {
  UspProductModuleRecord,
  UspProductModuleContentRecord,
} from '../../generated/graphql'
import { Image } from 'react-datocms'

type Props = {
  block: UspProductModuleRecord
}

function UspProductModule({ block }: Props) {
  if (!block) {
    return null
  }

  return (
    <div
      className="p-2 md:p-5"
      style={{ backgroundColor: block.backgroundColorHex?.hex ?? 'white' }}
    >
      <h2 className="col-span-1 text-header-2 font-medium">{block.title}</h2>
      <div className="grid-col-1 mt-[32px] grid gap-[40px] md:grid-cols-3">
        {block.uspProductModuleContent?.map(
          (product: UspProductModuleContentRecord) => (
            <div key={product.id} className="flex flex-col text-[#292862]">
              {product.showVideo ? (
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  className="h-auto w-full object-cover md:h-[210px] lg:h-[280px] xl:h-[340px] 2xl:h-[420px]"
                >
                  <source src={product.videoLink ?? ''} type="video/mp4" />
                </video>
              ) : (
                product.image?.responsiveImage && (
                  <Image
                    className="h-auto w-full object-cover"
                    objectFit="cover"
                    style={{ maxWidth: 'none' }}
                    data={product.image?.responsiveImage}
                  />
                )
              )}
              <h3 className="mt-[32px] text-[20px] text-header-3 font-medium">
                {product.headline}
              </h3>
              <p className="text-base mt-[8px] text-[15px]">
                {product.description}
              </p>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default UspProductModule
