import { ServicesMenuRecord } from '../../generated/graphql'
import { MenuStatusProvider } from './context'
import ServiceMenuContainer from './service-menu-container'

type Props = {
  block: ServicesMenuRecord
}

function ServicesMenu({ block }: Props) {
  return (
    <MenuStatusProvider>
      <ServiceMenuContainer block={block} />
    </MenuStatusProvider>
  )
}

export default ServicesMenu
