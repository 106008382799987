import useSWR from 'swr'
import { SWRResponse } from 'swr/dist/types'

function useRoles(): SWRResponse<any, any> {
  return useSWR<any>('/api/role', () =>
    fetch('/api/role', {
      method: 'GET',
    }).then((res) => res.json())
  )
}

export default useRoles
