import { Fragment } from 'react'
import Section from '../../../components/section'
import { EnergyTypeGridRecord } from '../../../generated/graphql'
import DesktopCard from '../desktop-card'
import MobileCard from '../mobile-card'

type Props = {
  block: EnergyTypeGridRecord
}
function EnergyTypeGridRecord({ block }: Props) {
  return (
    <Section>
      <div className="col-span-2 grid grid-cols-1 gap-2 py-[104px] sm:col-span-8 sm:grid-cols-2 sm:gap-4 md:py-8 lg:grid-cols-4">
        {block.energyTypes.map((energyTypes) => (
          <Fragment key={energyTypes.id}>
            <DesktopCard item={energyTypes} />
            <MobileCard item={energyTypes} />
          </Fragment>
        ))}
      </div>
    </Section>
  )
}

export default EnergyTypeGridRecord
