import {
  EnergyTypeRecord,
  InsightsTypeTagRecord,
} from '../../../generated/graphql'

export function getAllTagLabel(
  data: EnergyTypeRecord[] | InsightsTypeTagRecord[]
) {
  let labels: string[] = []
  if (!data) return []
  data.forEach((item) => {
    if (item.__typename === 'EnergyTypeRecord') {
      labels.push(item.tagName ?? '')
    } else if (item.__typename === 'InsightsTypeTagRecord') {
      labels.push(item.tagLabel ?? '')
    }
  })
  return labels
}

export function getIdForInsightTag(
  searchValue: string,
  insights?: InsightsTypeTagRecord[]
) {
  return insights?.find((insight) => insight.tagLabel === searchValue)?.id
}

export function getIdForEnergyTag(
  searchValue: string,
  energies?: EnergyTypeRecord[]
) {
  return energies?.find((energy) => energy.tagName === searchValue)?.id
}
