import {
  TwoColumnHeroRecord,
  CtaLinkRecord,
  TwoColumnHeroBulletPointRecord,
} from '../../generated/graphql'
import { Image } from 'react-datocms'
import CheckCircle from '../../icons/check-circle'
import CtaLink from '../../components/cta-link'
import Button from '../../components/button'

type Props = {
  block: TwoColumnHeroRecord
}

function twoColumnHeroModule({ block }: Props) {
  if (!block) {
    return null
  }

  const handleClickScroll = () => {
    const element = document.getElementById('free-trial-form')
    element?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="flex justify-start gap-[150px] bg-[#292862] pt-[140px] pb-[110px] md:justify-center">
      <div className="max-w-[540px] px-2 md:min-w-[540px] md:px-0 md:pl-5">
        <h1 className="mb-[16px] text-header-1 text-white">{block.headline}</h1>
        <p className="text-base text-white">{block.headlineDescription}</p>
        <div className="mt-[40px] flex flex-col gap-[12px]">
          {block.bulletPoints?.map(
            (bulletpoint: TwoColumnHeroBulletPointRecord) => (
              <div key={bulletpoint.id} className="flex items-start gap-[16px]">
                <div className="pt-[3px]">
                  <CheckCircle />
                </div>
                <p className="text-white">{bulletpoint.bulletPoint}</p>
              </div>
            )
          )}
        </div>
        <div className="mt-[40px] flex items-center gap-[24px]">
          {block?.scrollToFormButtonText && (
            <div>
              <Button
                onClick={handleClickScroll}
                text={block.scrollToFormButtonText ?? ''}
                variant="white"
              />
            </div>
          )}
          {block.cta.map((link: CtaLinkRecord) => (
            <div key={link.id}>
              <CtaLink cta={link} />
            </div>
          ))}
        </div>
      </div>
      <div className="relative hidden md:block">
        {block.showVideo ? (
          <video
            playsInline
            autoPlay
            muted
            loop
            className="h-[450px] w-full object-cover object-left"
          >
            <source src={block.videoLink ?? ''} type="video/mp4" />
          </video>
        ) : (
          <Image
            className="h-[450px]"
            objectFit="cover"
            objectPosition="left"
            style={{ maxWidth: 'none' }}
            data={block.image?.responsiveImage!}
          />
        )}
        <div className="absolute bottom-[12%] flex aspect-square h-auto w-[164px] translate-x-[-60%] flex-col items-center justify-center overflow-hidden break-all rounded-[50%] bg-[#ffa768] text-center text-white">
          <p className="text-base whitespace-pre-line px-1">
            {block.splashStartText}
          </p>
          <p className="px-1 text-[30px] font-bold">
            {block.splashHighlightedText}
          </p>
          <p className="text-base px-1">{block.splashEndText}</p>
        </div>
      </div>
    </div>
  )
}

export default twoColumnHeroModule
