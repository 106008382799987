import { Image } from 'react-datocms'
import VideoComponent from '../../../components/video-component'
import { PeopleCareerVideoImageModuleRecord } from '../../../generated/graphql'

type Props = {
  block: PeopleCareerVideoImageModuleRecord
}

function MobilePeopleVideoModule({ block }: Props) {
  return (
    <div id={block?.slug || ''}>
      {block.externalVideoLink ? (
        <div className="h-screen">
          <VideoComponent
            src={block.externalVideoLink!}
            poster={block.image?.responsiveImage!}
            playText={block.playCircleText!}
            textPosition="bottom"
          />
        </div>
      ) : (
        <Image data={block.image?.responsiveImage!} />
      )}
    </div>
  )
}

export default MobilePeopleVideoModule
