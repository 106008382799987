import {
  EnergyThemesTagRecord,
  EnergyTypeRecord,
  IndustryRecord,
  ResponsiveImage,
  SolutionPageImageVideoHeaderRecord,
  SolutionPageModelContentLayoutField,
  SolutionPageRecord,
} from '../generated/graphql'
import {
  AllService,
  Service,
  ServiceBlock,
  Solution,
  TagType,
} from '../types/service-menu-type'

export function createServices(
  solutionPageRecord: SolutionPageRecord[]
): ServiceBlock[][] {
  let services: ServiceBlock[][] = []

  const energies = getEnergiesFromServicesMenuRecord(solutionPageRecord)
  const industries = getIndustriesFromServicesMenuRecord(solutionPageRecord)
  const solutions = getAllSolutionsFromServicesMenuRecord(solutionPageRecord)

  services.push(industries)
  services.push(energies)
  services.push(solutions)

  return services
}

function getIndustriesFromServicesMenuRecord(
  solutionPageRecord: SolutionPageRecord[]
): ServiceBlock[] {
  const industries: ServiceBlock[] = []
  solutionPageRecord.map((solution) => {
    solution.industries.map((industry) => {
      if (!industries.some((i) => i.slug === industry.slug)) {
        industries.push({
          name: industry.name!,
          slug: industry.slug!,
          type: 'industry',
          nextBlock: [],
          solutions: [],
        })
      }
    })

    solution.energyTypes.map((energy) => {
      industries
        .filter((industry) =>
          solution.industries.some((i) => i.slug === industry.slug)
        )
        .map((industry) => {
          const sol = {
            name: solution.title!,
            slug: solution.slug!,
            excerpt: solution.excerpt!,
            coverImage: getCoverImageFromSolution(solution),
          }
          const eng = industry.nextBlock.find((eng) => eng.slug === energy.slug)
          if (!eng) {
            industry.nextBlock.push({
              name: energy.name!,
              slug: energy.slug!,
              type: 'energy type',
              solutions: [sol],
              nextBlock: [],
            })
          } else {
            eng.solutions.push(sol)
          }
        })
    })
  })

  return industries
}

function getEnergiesFromServicesMenuRecord(
  solutionPageRecord: SolutionPageRecord[]
): ServiceBlock[] {
  let energies: ServiceBlock[] = []

  solutionPageRecord.map((solution) => {
    solution.energyTypes.map((energy) => {
      if (!energies.some((e) => e.slug === energy.slug)) {
        energies.push({
          name: energy.name!,
          slug: energy.slug!,
          type: 'energy type',
          nextBlock: [],
          solutions: [],
        })
      }
    })

    solution.industries.map((industry) => {
      energies
        .filter((energy) =>
          solution.energyTypes.some((e) => e.slug === energy.slug)
        )
        .map((energy) => {
          const sol = {
            name: solution.title!,
            slug: solution.slug!,
            excerpt: solution.excerpt!,
            coverImage: getCoverImageFromSolution(solution),
          }
          const ind = energy.nextBlock.find((ind) => ind.slug === industry.slug)
          if (!ind) {
            energy.nextBlock.push({
              name: industry.name!,
              slug: industry.slug!,
              type: 'industry',
              solutions: [sol],
              nextBlock: [],
            })
          } else {
            ind.solutions.push(sol)
          }
        })
    })
  })

  return energies
}

function getAllSolutionsFromServicesMenuRecord(
  solutionPageRecord: SolutionPageRecord[]
): ServiceBlock[] {
  let solutions: ServiceBlock[] = []

  solutionPageRecord.map((solution) => {
    solution.energyTheme.map((theme) => {
      const eng = solutions.find((e) => e.name === theme.tagLabel!)
      const sol = {
        name: solution.title!,
        slug: solution.slug!,
        excerpt: solution.excerpt!,
        coverImage: getCoverImageFromSolution(solution),
      }
      if (!eng) {
        solutions.push({
          name: theme.tagLabel!,
          slug: theme.tagLabel!,
          type: 'all',
          solutions: [sol],
          nextBlock: [],
        })
      } else {
        eng.solutions.push(sol)
      }
    })
  })

  solutions.map((s) =>
    s.solutions.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
  )

  return solutions
}

function getFeaturedSolutions(
  solutionPageRecord: SolutionPageRecord[]
): Solution[] {
  let featuredSolutions: Solution[] = []

  solutionPageRecord.forEach((solution) => {
    if (solution.featuredSolution && featuredSolutions.length < 8) {
      featuredSolutions.push({
        name: solution.title!,
        slug: solution.slug!,
        excerpt: solution.excerpt!,
        coverImage: getCoverImageFromSolution(solution),
      })
    }
  })

  return featuredSolutions
}

export function getAllServices(
  solutionPageRecord: SolutionPageRecord[]
): AllService {
  let services: AllService = {
    availableEnergies: [],
    availableIndustries: [],
    availableEnergyThemes: [],
    solutions: [],
  }

  services.solutions = getAllDistinctService(solutionPageRecord)

  services.availableEnergies = getAvailableEnergies(services.solutions)

  services.availableIndustries = getAvailableIndustries(services.solutions)

  services.availableEnergyThemes = getAvailableEnergyThemes(services.solutions)

  return services
}

type Filter = {
  services: Service[]
  industry?: string
  energy?: string
  energyTheme?: string
  firstSelected: string
}

export function filterServices({
  services,
  industry,
  energy,
  energyTheme,
  firstSelected,
}: Filter): AllService {
  let filteredServices: Service[] = services
  let energies: TagType[] = getAvailableEnergies(services)
  let industries: TagType[] = getAvailableIndustries(services)
  let energyThemes: TagType[] = getAvailableEnergyThemes(services)

  if (industry) {
    filteredServices = filteredServices.filter((s) =>
      s.industries.some((i) => i.slug === industry)
    )
  }

  if (energy) {
    filteredServices = filteredServices.filter((s) =>
      s.energies.some((e) => e.slug === energy)
    )
  }

  if (energyTheme) {
    filteredServices = filteredServices.filter((s) =>
      s.energyThemes.some(
        (e) => e.name.toLowerCase() === energyTheme.toLowerCase()
      )
    )
  }

  if (!firstSelected || firstSelected === 'energy') {
    energies = getAvailableEnergies(services)
    industries = getAvailableIndustries(services, energy, energyTheme)
    energyThemes = getAvailableEnergyThemes(services, industry, energy)
  }

  if (!firstSelected || firstSelected === 'industry') {
    industries = getAvailableIndustries(services)
    energies = getAvailableEnergies(services, industry, energyTheme)
    energyThemes = getAvailableEnergyThemes(services, industry, energy)
  }

  if (!firstSelected || firstSelected === 'energyTheme') {
    energyThemes = getAvailableEnergyThemes(services)
    industries = getAvailableIndustries(services, energy, energyTheme)
    energies = getAvailableEnergies(services, industry, energyTheme)
  }

  filteredServices.sort((a, b) => a.position - b.position)

  return {
    solutions: filteredServices,
    availableEnergies: energies,
    availableIndustries: industries,
    availableEnergyThemes: energyThemes,
  }
}

function getAllDistinctService(
  solutionPageRecord: SolutionPageRecord[]
): Service[] {
  let solutions: Service[] = []
  solutionPageRecord.map((solution: SolutionPageRecord) => {
    if (!solutions.find((s) => s.slug === solution.slug)) {
      solutions.push({
        position: solution.position,
        name: solution.title!,
        slug: solution.slug!,
        excerpt: solution.excerpt!,
        industries: mapTagObjectArrayToTagType(solution.industries),
        energies: mapTagObjectArrayToTagType(solution.energyTypes),
        energyThemes: mapTagObjectArrayToTagType(solution.energyTheme),
      })
    }
  })

  return solutions
}

export function mapTagObjectArrayToTagType(
  list: EnergyThemesTagRecord[] | EnergyTypeRecord[] | IndustryRecord[]
) {
  const tags: TagType[] = []
  list.forEach((item) => {
    if (item.__typename === 'EnergyThemesTagRecord') {
      tags.push({ name: item.tagLabel!, slug: item.tagLabel! })
    } else if (
      item.__typename === 'EnergyTypeRecord' ||
      item.__typename === 'IndustryRecord'
    ) {
      tags.push({ name: item.name!, slug: item.slug! })
    }
  })
  return tags
}

function getAvailableIndustries(
  services: Service[],
  energy?: string,
  energyTheme?: string
): TagType[] {
  let industries: TagType[] = []
  if (energy) {
    services = services.filter((s) => s.energies.some((e) => e.slug === energy))
  }

  if (energyTheme) {
    services = services.filter((s) =>
      s.energyThemes.some(
        (e) => e.name.toLowerCase() === energyTheme.toLowerCase()
      )
    )
  }

  services.map((s) => {
    s.industries.map((i) => {
      if (!industries.find((ind) => ind.slug === i.slug)) {
        industries.push(i)
      }
    })
  })
  industries.sort()
  return industries
}

function getAvailableEnergies(
  service: Service[],
  industry?: string,
  energyTheme?: string
): TagType[] {
  let energies: TagType[] = []
  if (industry) {
    service = service.filter((s) =>
      s.industries.some((i) => i.slug === industry)
    )
  }
  if (energyTheme) {
    service = service.filter((s) =>
      s.energyThemes.some(
        (e) => e.name.toLowerCase() === energyTheme.toLowerCase()
      )
    )
  }
  service.map((s) => {
    s.energies.map((e) => {
      if (!energies.find((eng) => eng.slug === e.slug)) {
        energies.push(e)
      }
    })
  })
  energies.sort()

  return energies
}

function getAvailableEnergyThemes(
  service: Service[],
  industry?: string,
  energy?: string
): TagType[] {
  if (industry) {
    service = service.filter((s) =>
      s.industries.some((i) => i.slug === industry)
    )
  }
  if (energy) {
    service = service.filter((s) => s.energies.some((e) => e.slug === energy))
  }

  let energyThemes: TagType[] = []
  service.map((s) => {
    s.energyThemes.map((e) => {
      if (
        !energyThemes.find(
          (eng) => eng.name.toLowerCase() === e.name.toLowerCase()
        )
      ) {
        energyThemes.push(e)
      }
    })
  })
  energyThemes.sort()

  return energyThemes
}

export function isSolutionPageImageVideoHeaderRecord(
  record: SolutionPageModelContentLayoutField
): record is SolutionPageImageVideoHeaderRecord {
  return record?.__typename === 'SolutionPageImageVideoHeaderRecord'
}

function getCoverImageFromSolution(
  solution: SolutionPageRecord
): ResponsiveImage | undefined {
  const headerRecord = solution.contentLayout?.filter(
    isSolutionPageImageVideoHeaderRecord
  )[0]
  if (headerRecord && headerRecord.backgroundImage) {
    return headerRecord.backgroundImage.responsiveImage!
  }
}
