import { ImageTextCtaModuleRecord } from '../generated/graphql'
import Section from '../components/section'
import { Image } from 'react-datocms'
import CtaLink from '../components/cta-link'

type Props = {
  block: ImageTextCtaModuleRecord
}

function ImageTextCtaModule({ block }: Props) {
  return (
    <Section id={block?.slug || ''}>
      <div className={'col-span-full lg:col-span-4'}>
        {block.image?.responsiveImage && (
          <Image
            style={{ maxWidth: 'none' }}
            data={block.image?.responsiveImage}
          />
        )}
      </div>
      <div className={'col-span-full lg:col-span-3 lg:col-start-6'}>
        <p className={'mb-3 text-text-base'}>{block.label}</p>
        <h2 className={'mb-10 text-header-1'}>{block.text}</h2>
        <div className="flex flex-row flex-wrap gap-3">
          {block.cta.map((cta) => (
            <div key={cta.id} className="inline-block">
              <CtaLink cta={cta} />
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default ImageTextCtaModule
