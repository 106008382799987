import Link from 'next/link'
import { ContactPageSingleLocationRecord } from '../../generated/graphql'

type Props = {
  block: ContactPageSingleLocationRecord[]
}
function LocationCards({ block }: Props) {
  return (
    <>
      {block.map((item) => (
        <div
          className="col-span-4 xl:col-span-2 flex flex-col gap-[130px] bg-greys-0 p-3 justify-between"
          key={item.id}
        >
          <div className="flex flex-col gap-1">
            <div className="text-header-4 font-medium">{item.cityName}</div>
            <p>{item.address}</p>
            <Link scroll={false} href={item.googleMapsLink[0]?.link! ?? ''}>
              <a className="mt-1 underline">
                {item.googleMapsLink[0]?.ctaLabel}
              </a>
            </Link>
          </div>
          <div className="flex flex-col gap-1">
            <a href={`tel:${item.phoneNumber}`}>
              <span>{item.phoneNumberLabel} </span>
              <span className="underline">{item.phoneNumber}</span>
            </a>
            <a href={`mailto:${item.eMailAddress}`}>
              <span>{item.emailAddressLabel} </span>
              <span className="underline">{item.eMailAddress}</span>
            </a>
          </div>
        </div>
      ))}
    </>
  )
}

export default LocationCards
