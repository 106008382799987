import { ReactNode } from 'react'
import { ColorField, Maybe } from '../generated/graphql'
import { twMerge } from 'tailwind-merge'

type Props = {
  children: ReactNode
  backgroundColor?: Maybe<ColorField>
  classOverride?: string
  classOverrideInnerGrid?: string
  id?: string
  innerGridBackgroundColor?: Maybe<ColorField>
}

function Section({
  children,
  backgroundColor,
  innerGridBackgroundColor,
  classOverride,
  classOverrideInnerGrid,
  id,
}: Props) {
  return (
    <section
      id={id}
      style={{
        backgroundColor: backgroundColor?.hex,
      }}
      className={twMerge('bg-local bg-center bg-no-repeat', classOverride)}
    >
      <div
        style={{
          backgroundColor: innerGridBackgroundColor?.hex,
        }}
        className={twMerge(
          'mx-auto grid h-full max-w-fullbleed grid-cols-2 gap-2 p-2 md:grid-cols-8 md:gap-5 md:p-5',
          classOverrideInnerGrid
        )}
      >
        {children}
      </div>
    </section>
  )
}

export default Section
