import Section from '../../components/section'
import { ContactPageIntroductionTopModuleRecord } from '../../generated/graphql'

type Props = {
  block: ContactPageIntroductionTopModuleRecord
}
function ContactPageIntroductionTopModule({ block }: Props) {
  return (
    <Section>
      <div className="col-span-full col-start-1 flex flex-col justify-center gap-3 md:col-span-3">
        <h4>{block.subheader}</h4>
        <h1 className="text-left text-header-1">{block.text}</h1>
      </div>
    </Section>
  )
}

export default ContactPageIntroductionTopModule
