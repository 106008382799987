import ArrowLeftButton from '../../components/arrow-left-button'
import ArrowRightButton from '../../components/arrow-right-button'

type Props = {
  left: string
  right: string
  name: string
}

function EmployeeNavigation({ left, right, name }: Props) {
  return (
    <div className="mt-4 flex-row items-center justify-center md:flex">
      <span className="text-text-base font-medium">{name}</span>
    </div>
  )
}

export default EmployeeNavigation
