import {
  isInsightRecord,
  isPressReleaseRecord,
  isDirectUploadOrExternalLinkInsightRecord,
} from '../blocks/related-insight-thumbnail/get-solution-releated-insight-type'
import { SolutionRelatedInsightModelRelatedInsightsField } from '../generated/graphql'

function getRelatedInsightUrl(
  insight: SolutionRelatedInsightModelRelatedInsightsField
) {
  let insightUrl = ''

  if (isInsightRecord(insight)) {
    insightUrl = `/insights/${insight.slug}`
  } else if (isPressReleaseRecord(insight)) {
    insightUrl = `/news/${insight.slug}`
  } else if (
    isDirectUploadOrExternalLinkInsightRecord(insight) &&
    insight.insight[0].__typename === 'DirectUploadRecord' &&
    insight.insight[0].uploadedInsight?.url
  ) {
    insightUrl = insight.insight[0].uploadedInsight?.url
  } else if (
    isDirectUploadOrExternalLinkInsightRecord(insight) &&
    insight.insight[0].__typename === 'ExternalSugarMarketLinkRecord' &&
    insight.insight[0].externalLink
  ) {
    insightUrl = insight.insight[0].externalLink
  }

  return insightUrl
}

export default getRelatedInsightUrl
