import { useRef } from 'react'
import AnimatingDots from './animating-dots'
import useIsomorphicLayoutEffect from '../../../hooks/use-isomorphic-layout-effect'
import animateTimeline from './animate-timeline'
import ServiceLabels from './service-labels'
import { FrontPageServiceScrollAnimationRecord } from '../../../generated/graphql'

type Props = {
  block: FrontPageServiceScrollAnimationRecord
}

function MobileServicesScrollAnimation({ block }: Props) {
  const trigger = useRef<HTMLDivElement>(null)

  useIsomorphicLayoutEffect(() => {
    trigger.current && animateTimeline(trigger)
  }, [trigger])

  return (
    <div ref={trigger}>
      <AnimatingDots services={block.animationSections} />
      <ServiceLabels services={block.animationSections} />
    </div>
  )
}

export default MobileServicesScrollAnimation
