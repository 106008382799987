import Link from 'next/link'
import MenuLeftArrow from '../../../icons/menu-left-arrow'
import MenuRightArrow from '../../../icons/menu-right-arrow'
import { useMenuStatus } from '../context'
import { ServiceBlock } from '../../../types/service-menu-type'

type Props = {
  items: ServiceBlock[]
  selectedText: string
}

function MobileFirstBlock({ items, selectedText }: Props) {
  const { dispatch } = useMenuStatus()

  return (
    <>
      <div
        className={
          'col-span-full mb-3 flex flex-row items-center justify-between'
        }
      >
        <button
          onClick={() => {
            dispatch({ type: 'updateMenuItemIndex', index: 0 })
            dispatch({ type: 'updateMenuItemSelected', selected: false })
          }}
          className={'flex flex-row items-center gap-1 text-text-base'}
        >
          <MenuLeftArrow />
          Back
        </button>
        <span className={'text-text-base'}>{selectedText}</span>
      </div>
      <div className="col-span-1 flex flex-col gap-2 md:col-span-3">
        {items
          ? items.map((item, index) => (
              <button
                onClick={() =>
                  dispatch({ type: 'updateFirstBlockIndex', index })
                }
                className="flex flex-row items-center gap-1 text-text-large"
                key={index}
              >
                <span className="max-w-max text-left">{item.name}</span>
                <MenuRightArrow />
              </button>
            ))
          : ''}
        {items ? (
          <Link
            scroll={false}
            href={
              items[0].type === 'energy type'
                ? '/services/energytype'
                : '/services/industry'
            }
          >
            <a className="mt-4 text-text-base font-medium">See all</a>
          </Link>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default MobileFirstBlock
