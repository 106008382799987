import classNames from 'classnames'
import { Image } from 'react-datocms'
import {
  EmployeeCharacteristicRecord,
  SolutionRelatedInsightModelRelatedInsightsField,
} from '../../../generated/graphql'
import formatDate from '../../../utils/format-date'
import getRelatedInsightUrl from '../../../utils/get-related-insight-url'
import { isPressReleaseArticleRecord } from '../../press-release-thumbnail/press-release-thumbnail'
import {
  isDirectUploadOrExternalLinkInsightRecord,
  isInsightRecord,
  isPressReleaseRecord,
} from '../get-solution-releated-insight-type'
import { displayPressReleaseHeadline } from '../related-insight-desktop-thumbnail/related-insight-desktop-thumbnail'
import SecureAnchorWrapper from '../../../components/paywall/secure-anchor-wrapper'
import getSecureInfoFromDirectUploadOrExternalLink from '../get-secure-info-from-direct-upload-or-external-link'
import usePaywallStore from '../../../stores/use-paywall-store'
import useIsPaywallKeyUnlocked from '../../../hooks/use-is-paywall-key-unlocked'
import getFileLinkFromDirectUploadOrExternalLinkField from '../get-file-link-from-direct-upload-or-external-link-field'
import { useRouter } from 'next/router'
import Dynamic from '../../../components/dynamic'

type Props = {
  insight: SolutionRelatedInsightModelRelatedInsightsField
}

function displayPressReleaseMobileImage(
  insight: SolutionRelatedInsightModelRelatedInsightsField
) {
  if (isPressReleaseRecord(insight)) {
    const article = insight?.blocks.find((block) =>
      isPressReleaseArticleRecord(block)
    )

    return (
      article &&
      isPressReleaseArticleRecord(article) &&
      article?.image?.responsiveImage && (
        <Image
          objectFit="cover"
          style={{ maxWidth: 'auto' }}
          data={article.image.responsiveImage}
        />
      )
    )
  } else {
    return null
  }
}

function RelatedInsightMobileThumbnail({ insight }: Props) {
  const router = useRouter()
  const isVisible = useIsPaywallKeyUnlocked(insight.id ?? '')
  const setCurrentPaywall = usePaywallStore((state) => state.setCurrentPaywall)
  const secureInfo = getSecureInfoFromDirectUploadOrExternalLink(insight)
  const insightUrl = getRelatedInsightUrl(insight)

  const handleClickSecureContent = () => {
    if (!isDirectUploadOrExternalLinkInsightRecord(insight)) {
      return
    }
    const headline = insight.insightTitle

    const link =
      getFileLinkFromDirectUploadOrExternalLinkField(insight.insight[0]) ?? ''

    setCurrentPaywall({
      key: insight.id,
      headline: headline ?? '',
      sugarMarketUrl: secureInfo?.sugarmarketPopupLink ?? '',
      onSuccess: () => {
        window.open(link, '_blank')
      },
    })

    router.push('/authentication-form', '/authentication-form', {
      scroll: false,
    })
  }

  const insightBlock =
    isPressReleaseRecord(insight) &&
    insight.blocks.filter((block) => block.id)[0]

  let authors: EmployeeCharacteristicRecord[] = []

  if (insightBlock) {
    if (insightBlock?.__typename === 'PressReleaseArticleRecord') {
      authors =
        insightBlock?.authorS[0]?.pressReleaseSolutionContactOrAuthor?.map(
          (author) => author.contentLayout[0] as EmployeeCharacteristicRecord
        )
    }
  }

  const target =
    isInsightRecord(insight) ||
    isDirectUploadOrExternalLinkInsightRecord(insight)
      ? '_blank'
      : '_self'

  return (
    <SecureAnchorWrapper
      isSecure={!isVisible && secureInfo && secureInfo.isSecure}
      scroll={false}
      href={insightUrl}
      target={target}
      onClick={handleClickSecureContent}
    >
      <div
        key={'solution_insight_' + insight.id}
        className="flex flex-col gap-3 md:hidden"
      >
        {isInsightRecord(insight) && insight?.image?.responsiveImage && (
          <Image
            objectFit="cover"
            style={{ maxWidth: 'auto' }}
            data={insight.image.responsiveImage}
          />
        )}

        {!isPressReleaseRecord(insight) &&
          !isInsightRecord(insight) &&
          isDirectUploadOrExternalLinkInsightRecord(insight) &&
          insight?.image?.responsiveImage && (
            <Image
              objectFit="cover"
              style={{ maxWidth: 'auto' }}
              data={insight?.image?.responsiveImage}
            />
          )}

        {isPressReleaseRecord(insight) &&
          displayPressReleaseMobileImage(insight)}
        <div className="flex flex-row items-center gap-2">
          <div className="flex flex-row items-center gap-1">
            <div
              style={{
                backgroundColor: insight?.tag?.dotColor?.hex,
              }}
              className={`h-1 w-1 shrink-0 rounded-full`}
            ></div>
            <h2 className="text-text-base transition-all duration-300 group-hover:text-primary-rystad-blue-light-tint">
              {insight?.tag?.tagLabel}
            </h2>
          </div>

          {!isPressReleaseRecord(insight) && insight?._publishedAt && (
            <h2 className="text-text-base text-greys-3">
              <Dynamic>
                {formatDate(new Date(insight?._publishedAt), 'full')}
              </Dynamic>
            </h2>
          )}

          {isPressReleaseRecord(insight) && (
            <h2 className="text-text-base text-greys-3">
              {insight?.energyType?.name}
            </h2>
          )}
        </div>

        <h2 className="text-header-2-5 line-clamp-2">
          {isInsightRecord(insight) && insight.title}
          {isPressReleaseRecord(insight) &&
            displayPressReleaseHeadline(insight)}
          {!isInsightRecord(insight) &&
            !isPressReleaseRecord(insight) &&
            isDirectUploadOrExternalLinkInsightRecord(insight) &&
            insight?.insightTitle}
        </h2>

        {!isInsightRecord(insight) && authors && (
          <div className={`relative flex-col sm:flex sm:flex-row`}>
            <div className="relative flex flex-row">
              {authors.map((author, index) => (
                <div
                  key={author.id}
                  title={`${author.name}`}
                  className={classNames('', {
                    '-ml-1': index !== 0,
                    'z-30': index === 0,
                    [`z-${index * 10}`]: index !== 0,
                  })}
                >
                  {author.profilePicture?.responsiveImage && (
                    <Image
                      className="rounded-full border-2 border-greys-0"
                      data={author.profilePicture?.responsiveImage!}
                    />
                  )}
                </div>
              ))}

              <div className="ml-2 flex flex-col justify-center">
                {authors.map((author) => (
                  <div key={author.id}>
                    <h2 className="text-text-x-small text-primary-rystad-blue">
                      {author.name}
                    </h2>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </SecureAnchorWrapper>
  )
}

export default RelatedInsightMobileThumbnail
