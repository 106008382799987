import Link from 'next/link'
import { CtaLinkRecord } from '../../../generated/graphql'
import linkedInLogo from '../../../public/svg/linkedInLogo.svg'
import LinkedIn from '../../../icons/linkedin'

type Variant = 'big' | 'small'

const variants: Record<Variant, string> = {
  small: 'text-text-small gap-1',
  big: 'text-text-large gap-3',
}

type Props = {
  items: CtaLinkRecord[]
  variant?: Variant
  linkedInLink?: string | null
}
function FooterCtaList({ items, variant, linkedInLink }: Props) {
  return (
    <div
      className={`flex flex-col justify-start ${variants[variant ?? 'big']}`}
    >
      {items.map((item) => (
        <Link scroll={false} key={item.id} href={item.link!}>
          <a>{item.ctaLabel}</a>
        </Link>
      ))}
      {linkedInLink && (
        <a
          className="mt-auto pb-2"
          href={linkedInLink}
          target="_blank"
          rel="noreferrer"
        >
          <LinkedIn color="text-white" size="20" />
        </a>
      )}
    </div>
  )
}

export default FooterCtaList
