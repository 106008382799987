function Locations() {
  return (
    <>
      <svg
        width="1358"
        height="691"
        viewBox="0 0 1358 691"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.284 97.481C666.164 97.481 661.964 101.679 661.964 106.815C661.964 112.44 666.54 116.147 671.319 116.147C673.629 116.147 675.983 115.288 677.899 113.369C683.736 107.524 679.606 97.4805 671.284 97.4805V97.481Z"
            fill="white"
          />
          <path
            d="M671.277 93.8133H671.428C682.919 93.9343 688.584 107.837 680.493 115.939L680.493 115.939C677.813 118.623 674.539 119.812 671.33 119.812C664.651 119.812 658.297 114.639 658.297 106.814C658.297 99.6612 664.151 93.8133 671.277 93.8133Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.284 154.692C680.164 154.692 675.964 158.89 675.964 164.026C675.964 169.651 680.54 173.358 685.319 173.358C687.629 173.358 689.983 172.499 691.899 170.58C697.736 164.735 693.606 154.691 685.284 154.691V154.692Z"
            fill="white"
          />
          <path
            d="M685.277 151.024H685.428C696.919 151.145 702.584 165.047 694.493 173.15L694.493 173.15C691.813 175.834 688.539 177.023 685.33 177.023C678.651 177.023 672.297 171.85 672.297 164.025C672.297 156.872 678.151 151.024 685.277 151.024Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.284 212.942C623.164 212.942 618.964 217.14 618.964 222.276C618.964 227.901 623.54 231.608 628.319 231.608C630.629 231.608 632.983 230.749 634.899 228.83C640.736 222.985 636.606 212.941 628.284 212.941V212.942Z"
            fill="white"
          />
          <path
            d="M628.277 209.274H628.428C639.919 209.395 645.584 223.297 637.493 231.4L637.493 231.4C634.813 234.084 631.539 235.273 628.33 235.273C621.651 235.273 615.297 230.1 615.297 222.275C615.297 215.122 621.151 209.274 628.277 209.274Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.354 213.551C739.235 213.551 735.034 217.749 735.034 222.885C735.034 228.511 739.611 232.217 744.389 232.217C746.699 232.217 749.053 231.359 750.97 229.44C756.807 223.594 752.676 213.551 744.354 213.551V213.551Z"
            fill="white"
          />
          <path
            d="M744.347 209.884H744.498C755.989 210.005 761.654 223.907 753.563 232.009L753.563 232.009C750.883 234.694 747.609 235.883 744.4 235.883C737.721 235.883 731.367 230.709 731.367 222.884C731.367 215.732 737.221 209.884 744.347 209.884Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.987 300.012C826.867 300.012 822.667 304.21 822.667 309.346C822.667 314.972 827.243 318.678 832.022 318.678C834.332 318.678 836.686 317.82 838.602 315.9C844.439 310.055 840.309 300.012 831.987 300.012V300.012Z"
            fill="white"
          />
          <path
            d="M831.98 296.345H832.131C843.622 296.466 849.287 310.368 841.196 318.47L841.196 318.47C838.516 321.155 835.242 322.344 832.033 322.344C825.354 322.344 819 317.17 819 309.345C819 302.192 824.854 296.345 831.98 296.345Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1179.42 228.161C1174.3 228.161 1170.1 232.358 1170.1 237.494C1170.1 243.12 1174.68 246.827 1179.46 246.827C1181.77 246.827 1184.12 245.968 1186.04 244.049C1191.88 238.204 1187.75 228.16 1179.42 228.16V228.161Z"
            fill="white"
          />
          <path
            d="M1179.42 224.493H1179.57C1191.06 224.614 1196.72 238.516 1188.63 246.619L1188.63 246.619C1185.95 249.303 1182.68 250.492 1179.47 250.492C1172.79 250.492 1166.44 245.319 1166.44 237.493C1166.44 230.341 1172.29 224.493 1179.42 224.493Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1121 212.336C1115.88 212.336 1111.68 216.534 1111.68 221.67C1111.68 227.296 1116.26 231.003 1121.04 231.003C1123.35 231.003 1125.7 230.144 1127.62 228.225C1133.46 222.38 1129.32 212.336 1121 212.336V212.336Z"
            fill="white"
          />
          <path
            d="M1121 208.669H1121.15C1132.64 208.79 1138.3 222.692 1130.21 230.794L1130.21 230.795C1127.53 233.479 1124.26 234.668 1121.05 234.668C1114.37 234.668 1108.02 229.494 1108.02 221.669C1108.02 214.517 1113.87 208.669 1121 208.669Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1061.94 226.336C1056.82 226.336 1052.62 230.534 1052.62 235.67C1052.62 241.296 1057.2 245.003 1061.97 245.003C1064.29 245.003 1066.64 244.144 1068.56 242.225C1074.39 236.38 1070.26 226.336 1061.94 226.336V226.336Z"
            fill="white"
          />
          <path
            d="M1061.93 222.669H1062.08C1073.58 222.79 1079.24 236.692 1071.15 244.794L1071.15 244.795C1068.47 247.479 1065.2 248.668 1061.99 248.668C1055.31 248.668 1048.95 243.494 1048.95 235.669C1048.95 228.517 1054.81 222.669 1061.93 222.669Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.56 284.586C1072.45 284.586 1068.24 288.784 1068.24 293.92C1068.24 299.546 1072.82 303.253 1077.6 303.253C1079.91 303.253 1082.26 302.394 1084.18 300.475C1090.02 294.63 1085.89 284.586 1077.56 284.586V284.586Z"
            fill="white"
          />
          <path
            d="M1077.56 280.919H1077.71C1089.2 281.04 1094.87 294.942 1086.77 303.044L1086.77 303.045C1084.09 305.729 1080.82 306.918 1077.61 306.918C1070.93 306.918 1064.58 301.744 1064.58 293.919C1064.58 286.767 1070.43 280.919 1077.56 280.919Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.56 530.942C1072.45 530.942 1068.24 535.14 1068.24 540.276C1068.24 545.901 1072.82 549.608 1077.6 549.608C1079.91 549.608 1082.26 548.749 1084.18 546.83C1090.02 540.985 1085.89 530.941 1077.56 530.941V530.942Z"
            fill="white"
          />
          <path
            d="M1077.56 527.274H1077.71C1089.2 527.395 1094.87 541.297 1086.77 549.4L1086.77 549.4C1084.09 552.084 1080.82 553.273 1077.61 553.273C1070.93 553.273 1064.58 548.1 1064.58 540.275C1064.58 533.122 1070.43 527.274 1077.56 527.274Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.49 386.661C999.367 386.661 995.167 390.858 995.167 395.994C995.167 401.62 999.743 405.327 1004.52 405.327C1006.83 405.327 1009.19 404.468 1011.1 402.549C1016.94 396.704 1012.81 386.66 1004.49 386.66V386.661Z"
            fill="white"
          />
          <path
            d="M1004.48 382.993H1004.63C1016.12 383.114 1021.79 397.016 1013.7 405.119L1013.7 405.119C1011.02 407.803 1007.74 408.992 1004.53 408.992C997.854 408.992 991.5 403.819 991.5 395.993C991.5 388.841 997.354 382.993 1004.48 382.993Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.94 440.168C1028.82 440.168 1024.62 444.366 1024.62 449.502C1024.62 455.128 1029.2 458.835 1033.97 458.835C1036.29 458.835 1038.64 457.976 1040.56 456.057C1046.39 450.212 1042.26 440.168 1033.94 440.168V440.168Z"
            fill="white"
          />
          <path
            d="M1033.93 436.501H1034.08C1045.58 436.622 1051.24 450.524 1043.15 458.626L1043.15 458.627C1040.47 461.311 1037.2 462.5 1033.99 462.5C1027.31 462.5 1020.95 457.326 1020.95 449.501C1020.95 442.349 1026.81 436.501 1033.93 436.501Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1076.96 473.731C1071.84 473.731 1067.64 477.929 1067.64 483.065C1067.64 488.69 1072.22 492.397 1077 492.397C1079.31 492.397 1081.66 491.538 1083.58 489.619C1089.42 483.774 1085.29 473.73 1076.96 473.73V473.731Z"
            fill="white"
          />
          <path
            d="M1076.96 470.063H1077.11C1088.6 470.184 1094.26 484.087 1086.17 492.189L1086.17 492.189C1083.49 494.873 1080.22 496.062 1077.01 496.062C1070.33 496.062 1063.98 490.889 1063.98 483.064C1063.98 475.911 1069.83 470.063 1076.96 470.063Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.057 357.84C926.938 357.84 922.737 362.038 922.737 367.174C922.737 372.8 927.314 376.507 932.092 376.507C934.402 376.507 936.757 375.648 938.673 373.729C944.51 367.884 940.379 357.84 932.057 357.84V357.84Z"
            fill="white"
          />
          <path
            d="M932.05 354.173H932.202C943.692 354.294 949.358 368.196 941.266 376.298L941.266 376.298C938.586 378.983 935.312 380.172 932.103 380.172C925.424 380.172 919.07 374.998 919.07 367.173C919.07 360.021 924.924 354.173 932.05 354.173Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M469.565 531.551C464.445 531.551 460.245 535.749 460.245 540.885C460.245 546.511 464.822 550.217 469.6 550.217C471.91 550.217 474.264 549.359 476.18 547.44C482.018 541.594 477.887 531.551 469.565 531.551V531.551Z"
            fill="white"
          />
          <path
            d="M469.558 527.884H469.709C481.2 528.005 486.865 541.907 478.774 550.009L478.774 550.009C476.094 552.694 472.82 553.883 469.611 553.883C462.932 553.883 456.578 548.709 456.578 540.884C456.578 533.732 462.432 527.884 469.558 527.884Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.01 242.368C231.891 242.368 227.69 246.565 227.69 251.701C227.69 257.327 232.267 261.034 237.045 261.034C239.356 261.034 241.71 260.175 243.626 258.256C249.463 252.411 245.332 242.367 237.01 242.367V242.368Z"
            fill="white"
          />
          <path
            d="M237.003 238.7H237.155C248.645 238.821 254.311 252.723 246.219 260.826L246.219 260.826C243.539 263.51 240.266 264.699 237.056 264.699C230.377 264.699 224.023 259.526 224.023 251.7C224.023 244.548 229.877 238.7 237.003 238.7Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.502 227.551C348.383 227.551 344.182 231.749 344.182 236.885C344.182 242.511 348.759 246.217 353.537 246.217C355.848 246.217 358.202 245.359 360.118 243.44C365.955 237.594 361.825 227.551 353.502 227.551V227.551Z"
            fill="white"
          />
          <path
            d="M353.496 223.884H353.647C365.138 224.005 370.803 237.907 362.711 246.009L362.711 246.009C360.031 248.694 356.758 249.883 353.548 249.883C346.87 249.883 340.516 244.709 340.516 236.884C340.516 229.732 346.37 223.884 353.496 223.884Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.971 285.801C260.852 285.801 256.651 289.999 256.651 295.135C256.651 300.761 261.228 304.467 266.006 304.467C268.316 304.467 270.671 303.609 272.587 301.69C278.424 295.844 274.293 285.801 265.971 285.801V285.801Z"
            fill="white"
          />
          <path
            d="M265.964 282.134H266.116C277.606 282.255 283.272 296.157 275.18 304.259L275.18 304.259C272.5 306.944 269.226 308.133 266.017 308.133C259.338 308.133 252.984 302.959 252.984 295.134C252.984 287.982 258.838 282.134 265.964 282.134Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.909 589.801C405.789 589.801 401.589 593.999 401.589 599.135C401.589 604.761 406.165 608.467 410.944 608.467C413.254 608.467 415.608 607.609 417.524 605.69C423.361 599.844 419.231 589.801 410.909 589.801V589.801Z"
            fill="white"
          />
          <path
            d="M410.902 586.134H411.053C422.544 586.255 428.209 600.157 420.118 608.259L420.118 608.259C417.438 610.944 414.164 612.133 410.955 612.133C404.276 612.133 397.922 606.959 397.922 599.134C397.922 591.982 403.776 586.134 410.902 586.134Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.776 227.551C159.656 227.551 155.456 231.749 155.456 236.885C155.456 242.511 160.032 246.217 164.811 246.217C167.121 246.217 169.475 245.359 171.391 243.44C177.228 237.594 173.098 227.551 164.776 227.551V227.551Z"
            fill="white"
          />
          <path
            d="M164.769 223.884H164.92C176.411 224.005 182.076 237.907 173.985 246.009L173.985 246.009C171.305 248.694 168.031 249.883 164.822 249.883C158.143 249.883 151.789 244.709 151.789 236.884C151.789 229.732 157.643 223.884 164.769 223.884Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.565 183.731C173.445 183.731 169.245 187.929 169.245 193.065C169.245 198.69 173.822 202.397 178.6 202.397C180.91 202.397 183.264 201.538 185.18 199.619C191.018 193.774 186.887 183.73 178.565 183.73V183.731Z"
            fill="white"
          />
          <path
            d="M178.558 180.063H178.709C190.2 180.184 195.865 194.087 187.774 202.189L187.774 202.189C185.094 204.873 181.82 206.062 178.611 206.062C171.932 206.062 165.578 200.889 165.578 193.064C165.578 185.911 171.432 180.063 178.558 180.063Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1237.06 546.372C1231.95 546.372 1227.74 550.569 1227.74 555.705C1227.74 561.331 1232.32 565.038 1237.1 565.038C1239.41 565.038 1241.76 564.179 1243.68 562.26C1249.52 556.415 1245.39 546.371 1237.06 546.371V546.372Z"
            fill="white"
          />
          <path
            d="M1237.06 542.704H1237.21C1248.7 542.825 1254.37 556.727 1246.27 564.83L1246.27 564.83C1243.59 567.514 1240.32 568.703 1237.11 568.703C1230.43 568.703 1224.08 563.53 1224.08 555.704C1224.08 548.552 1229.93 542.704 1237.06 542.704Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <g className="location">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M614.065 154.301C608.945 154.301 604.745 158.499 604.745 163.635C604.745 169.261 609.322 172.967 614.1 172.967C616.41 172.967 618.764 172.109 620.68 170.19C626.518 164.344 622.387 154.301 614.065 154.301V154.301Z"
            fill="white"
          />
          <path
            d="M614.058 150.634H614.209C625.7 150.755 631.365 164.657 623.274 172.759L623.274 172.759C620.594 175.444 617.32 176.633 614.111 176.633C607.432 176.633 601.078 171.459 601.078 163.634C601.078 156.482 606.932 150.634 614.058 150.634Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
      </svg>
    </>
  )
}

export default Locations
