function LinkIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#F6F8FA"
      />
      <path
        d="M15.4062 16.625C17.1562 14.875 20 14.875 21.75 16.625C23.4375 18.2812 23.5 20.9688 21.9688 22.75L21.7812 22.9375C21.5 23.25 21.0312 23.2812 20.7188 23.0312C20.4062 22.75 20.375 22.2812 20.6562 21.9688L20.8125 21.7812C21.875 20.5938 21.8125 18.8125 20.6875 17.6875C19.5312 16.5 17.625 16.5 16.4375 17.6875L12.9062 21.2188C11.75 22.4062 11.75 24.2812 12.9062 25.4688C14.0312 26.5625 15.8125 26.625 17 25.5938L17.1875 25.4375C17.5 25.1562 17.9688 25.1875 18.25 25.5C18.5312 25.8125 18.5 26.2812 18.1875 26.5625L18 26.7188C16.2188 28.2812 13.5312 28.1875 11.8438 26.5312C10.0938 24.7812 10.0938 21.9062 11.8438 20.1562L15.4062 16.625ZM24.5938 24.4062C22.8125 26.1562 19.9688 26.1562 18.2188 24.4062C16.5312 22.7188 16.4688 20.0625 18 18.2812L18.1562 18.0938C18.4375 17.7812 18.9062 17.75 19.2188 18.0312C19.5312 18.3125 19.5625 18.7812 19.2812 19.0938L19.1562 19.25C18.0938 20.4375 18.1562 22.2188 19.2812 23.3438C20.4375 24.5 22.3438 24.5 23.5312 23.3438L27.0625 19.8125C28.2188 18.625 28.2188 16.75 27.0625 15.5625C25.9375 14.4688 24.1562 14.4062 22.9688 15.4375L22.7812 15.5938C22.4688 15.875 22 15.8438 21.7188 15.5312C21.4375 15.2188 21.4688 14.75 21.7812 14.4688L21.9688 14.3125C23.75 12.75 26.4375 12.8438 28.125 14.5C29.875 16.25 29.875 19.0938 28.125 20.875L24.5938 24.4062Z"
        fill="#292862"
      />
    </svg>
  )
}

export default LinkIcon
