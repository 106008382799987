import MenuLeftArrow from '../../../icons/menu-left-arrow'
import MenuRightArrow from '../../../icons/menu-right-arrow'
import { useMenuContext } from '../../main-menu/menu-context'
import { useMenuStatus } from '../context'
import { MenuItemType } from '../../../types/service-menu-type'
import { useRouter } from 'next/router'

type Props = {
  items: MenuItemType[]
}
function MobileMenuItemsBlock({ items }: Props) {
  const router = useRouter()
  const { dispatch } = useMenuStatus()
  const menuContext = useMenuContext()

  return (
    <>
      <div
        className={
          'col-span-full mb-3 flex flex-row items-center justify-between'
        }
      >
        <button
          onClick={() => {
            menuContext.dispatch({
              type: 'setMobileMenuItemBlocks',
              payload: null,
            })
            dispatch({ type: 'updateMenuItemSelected', selected: false })
          }}
          className={'flex flex-row items-center gap-1 text-text-base'}
        >
          <MenuLeftArrow />
          Back
        </button>
        <span className={'text-text-base'}>Services</span>
      </div>
      <div className="col-span-1 flex flex-col gap-2 md:col-span-3">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex cursor-pointer flex-row items-center gap-1 text-text-large"
            onClick={() => {
              if (!item.displayProductDemo) {
                router.push(item.link ?? '#')
                return
              }
              dispatch({ type: 'updateMenuItemIndex', index })
              dispatch({ type: 'updateMenuItemSelected', selected: true })
            }}
          >
            <span>{item.name}</span>
            {item.displayProductDemo && <MenuRightArrow />}
          </div>
        ))}
      </div>
    </>
  )
}

export default MobileMenuItemsBlock
