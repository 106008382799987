import { SearchResultRecord } from '../../generated/graphql'
import { useRouter } from 'next/router'
import Section from '../../components/section'
import NoResultsFound from './no-results-found'
import useSiteSearch from '../../hooks/use-site-search'
import SearchResultList from './search-result-list'
import DeletableTextInput from '../../components/deletable-text-input'
import useURLSearch from '../../hooks/use-URL-search'

type Props = {
  block: SearchResultRecord
}

function SearchResult({ block }: Props) {
  const router = useRouter()
  const [inputValue, setInputValue] = useURLSearch()
  const { data, isError, isLoading } = useSiteSearch(router.query.q?.toString())

  return (
    <Section>
      <div
        className={
          'col-span-2 flex flex-col gap-5 md:col-span-6 md:col-start-2 md:gap-10'
        }
      >
        <DeletableTextInput value={inputValue} setValue={setInputValue} />

        {isLoading && <div className={'min-h-screen'} />}
        {isError && (
          <div className={'text-text-base'}>
            There was an error during search
          </div>
        )}

        {data?.length === 0 && <NoResultsFound />}

        {!!data?.length && <SearchResultList data={data} />}
      </div>
    </Section>
  )
}

export default SearchResult
