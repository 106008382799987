type Props = {
  color?: string
  size?: number | string
}

function Check({ color, size }: Props) {
  return (
    <svg
      className={color}
      width={size ?? 12}
      height={size ?? 9}
      viewBox={`0 0 ${size ?? 12} ${size ?? 9}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3125 0.914062C11.5469 1.14844 11.5469 1.5 11.3125 1.71094L5.125 7.89844C4.91406 8.13281 4.5625 8.13281 4.35156 7.89844L1.16406 4.71094C0.929688 4.5 0.929688 4.14844 1.16406 3.91406C1.375 3.70312 1.72656 3.70312 1.9375 3.91406L4.75 6.72656L10.5391 0.914062C10.75 0.703125 11.1016 0.703125 11.3125 0.914062Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Check
