import useSWR from 'swr'
import { SWRResponse } from 'swr/dist/types'

function useCity(countryId: number | null): SWRResponse<any, any> {
  return useSWR<any>(`/api/city?id=${countryId}`, () =>
    fetch(`/api/city?id=${countryId}`, {
      method: 'GET',
    }).then((res) => res.json())
  )
}

export default useCity
