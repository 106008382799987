import RystadLogoWithTypo from '../../../icons/rystad-logo-with-typo'
import SearchIcon from '../../../icons/search-icon'
import Hamburger from '../../../icons/hamburger'
import ExpandedMenu from './expanded-menu'
import Close from '../../../icons/close'
import classNames from 'classnames'
import { useMenuContext } from '../menu-context'
import Blocks from '../../blocks'
import SearchBar from './search-bar'
import { useRouter } from 'next/router'
import Link from 'next/link'
import updateBodyScroll from '../../../utils/update-body-scroll'
import { useEffect } from 'react'

function MobileMenu() {
  const {
    dispatch,
    state: { isMobileMenuOpen, mobileMenuItemBlocks, isMobileSearching },
  } = useMenuContext()

  const router = useRouter()
  const isSearchPage = router.pathname.includes('/search')

  useEffect(() => {
    updateBodyScroll(!isMobileMenuOpen)
  }, [isMobileMenuOpen])

  return (
    <div className={'h-full overflow-y-scroll'}>
      <div
        className={
          'flex max-h-screen flex-row items-center justify-between p-2'
        }
      >
        <Link scroll={false} href={'/'}>
          <a>
            <RystadLogoWithTypo />
          </a>
        </Link>
        <div className={'flex flex-row  items-center gap-2'}>
          {!isMobileMenuOpen && (
            <Link scroll={false} href="/contact">
              <a>Contact</a>
            </Link>
          )}
          <button
            onClick={() => {
              if (isSearchPage) return
              dispatch({
                type: 'toggleMobileIsSearching',
                payload: !isMobileSearching,
              })
            }}
          >
            <SearchIcon />
          </button>
          <button
            className={classNames('rounded-full p-2', {
              'bg-greys-1 text-primary-rystad-blue': isMobileMenuOpen,
            })}
            onClick={() => {
              dispatch({
                type: 'toggleMobileMenuOpen',
                payload: !isMobileMenuOpen,
              })
            }}
          >
            {isMobileMenuOpen ? <Close /> : <Hamburger />}
          </button>
        </div>
      </div>
      {isMobileMenuOpen && !mobileMenuItemBlocks && <ExpandedMenu />}
      {isMobileSearching && <SearchBar />}
      {isMobileMenuOpen && mobileMenuItemBlocks && (
        <div className={'h-full'}>
          <Blocks blocks={mobileMenuItemBlocks} />
        </div>
      )}
    </div>
  )
}

export default MobileMenu
