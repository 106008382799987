import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import SearchIcon from '../../../icons/search-icon'

type Props = {
  change: (inputValue: string) => void
  value: string
}

function SearchForNews({ change, value }: Props) {
  const [isIdle, setIsIdle] = useState(true)

  return (
    <>
      {isIdle ? (
        <div
          className="flex cursor-pointer flex-row items-center justify-between py-2"
          onClick={() => {
            setIsIdle(false)
          }}
        >
          <button>
            <span className="text-text-small">Search for news</span>
          </button>
          <div className="pr-[6px] md:pr-1">
            <SearchIcon />
          </div>
        </div>
      ) : (
        <div className="flex cursor-pointer flex-row items-center justify-between py-2">
          <input
            value={value}
            onChange={(e) => change(e.target.value)}
            autoFocus
            className={'bg-transparent outline-0'}
            type="text"
          />
          <SearchIcon />
        </div>
      )}
    </>
  )
}

export default SearchForNews
