import MenuSelectListItem from './menu-select-list-item'
import { useMenuStatus } from '../context'
import Link from 'next/link'
import { ServiceBlock } from '../../../types/service-menu-type'
import FancyUnderlinedLabel from '../../../components/fancy-underlined-label'

export type SolutionType = 'energy' | 'industry' | 'solutions' | null

type Props = {
  items: ServiceBlock[]
  type: SolutionType
  selectColor?: string
  unselectColor?: string
  underlineColor?: string
}

function FirstBlock({
  items,
  type,
  selectColor,
  unselectColor,
  underlineColor,
}: Props) {
  const { dispatch, state } = useMenuStatus()

  return (
    <div
      className={`col-span-1 col-start-3 row-start-1 flex-col items-start md:col-start-3`}
    >
      {items?.map((item, index) => (
        <div
          className={`mt-1`}
          style={{
            color: index === state.firstBlock ? selectColor : unselectColor,
          }}
          key={index}
        >
          <MenuSelectListItem
            text={item.name}
            onMouseEnter={() =>
              dispatch({
                type: 'updateFirstBlockIndex',
                index,
              })
            }
          />
        </div>
      ))}

      <div className="mt-6">
        <Link
          scroll={false}
          href={
            type === 'energy' ? '/services/energytype' : '/services/industry'
          }
        >
          <a>
            <FancyUnderlinedLabel underlineColor={underlineColor}>
              {type === 'energy' &&
                 'Explore all energies'}
              {type === 'industry' && 'Explore all industries'}
            </FancyUnderlinedLabel>
          </a>
        </Link>
      </div>
    </div>
  )
}

export default FirstBlock
