import { MenuItemRecord } from '../../../generated/graphql'
import Link from 'next/link'

type Props = {
  menuItem: MenuItemRecord
  onMouseEnter?: () => void
}

function MenuItem({ menuItem, onMouseEnter }: Props) {
  return (
    <>
      {menuItem.link ? (
        <Link scroll={false} href={menuItem.link}>
          <a onMouseEnter={onMouseEnter} className="text-text-small">{menuItem.label} </a>
        </Link>
      ) : (
        <button onMouseEnter={onMouseEnter} className="text-text-small">
          {menuItem.label}{' '}
        </button>
      )}
    </>
  )
}

export default MenuItem
