type Props = {
  type: string
  items: string[]
  selected: string
  selectItem: (type: string, value: string) => void
}
function NewsFilterItem({ type, items, selected, selectItem }: Props) {
  return (
    <div className="flex flex-col items-start gap-1">
      {items?.map((item) => (
        <button
          onClick={() => selectItem(type, item)}
          key={item}
          className={`${
            selected === item ? 'border-b border-r-greys-0' : 'border-b-0'
          }`}
        >
          {item}
        </button>
      ))}
      <div className={'h-2'} />
    </div>
  )
}

export default NewsFilterItem
