import Link from 'next/link'
import { useState } from 'react'
import Section from '../../components/section'
import { ContactPageLocationsBlockRecord } from '../../generated/graphql'
import LocationCards from './location-cards'

type Props = {
  block: ContactPageLocationsBlockRecord
}
function ContactPageLocationsBlock({ block }: Props) {
  const [selectedLocation, setSelectedLocation] = useState(0)
  return (
    <Section classOverride="bg-white">
      <h2 className="col-span-full col-start-1 text-header-2 font-medium md:col-span-1">
        {block.headline}
      </h2>
      <div className="col-span-full col-start-1 mt-5 mb-3 flex flex-row gap-4 text-text-large md:col-start-5 md:mt-[0] md:mb-[0]">
        {block.locations.map((location, index) => (
          <div
            className={`flex cursor-pointer flex-row items-center gap-1 ${
              selectedLocation === index ? 'text-current' : 'text-greys-4'
            }`}
            key={location.id}
            onClick={() => setSelectedLocation(index)}
          >
            <span>{location.regionName}</span>
            <sup>{location.locationsInsideARegion.length}</sup>
          </div>
        ))}
      </div>
      <LocationCards
        block={block.locations[selectedLocation].locationsInsideARegion}
      />
    </Section>
  )
}

export default ContactPageLocationsBlock
