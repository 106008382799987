import Link from 'next/link'
import ArrowRight from '../icons/arrow-right'
import { ReactNode } from 'react'

type Props = {
  link: string
  children?: ReactNode
  variant?: Variant
  target?: string
  classOverride?: string
}

type Variant = 'primary' | 'white' | 'current'

const variants: Record<Variant, string> = {
  primary: 'text-primary-rystad-blue',
  white: 'text-greys-1',
  current: 'text-current',
}

const underlineVariants: Record<Variant, string> = {
  primary: 'bg-primary-rystad-blue',
  white: 'bg-white',
  current: 'bg-primary-rystad-blue',
}

function ArrowRightButton({
  link,
  children,
  variant,
  target,
  classOverride,
}: Props) {
  const isAnchor = link.startsWith('#')

  return isAnchor ? (
    <a
      href={link}
      target={target}
      className={`border-none ${
        variants[variant ?? 'primary']
      } group relative inline-block w-fit pr-1 transition-all`}
    >
      <div className="relative z-30 flex items-center gap-1 md:gap-3">
        <div className="min-w-[16px] max-w-[16px] overflow-hidden">
          <div className="flex w-[32px] translate-x-[-16px] flex-row gap-1 transition-transform duration-300 group-hover:translate-x-[4px]">
            <ArrowRight size={'16px'} />
            <ArrowRight size={'16px'} />
          </div>
        </div>
        <span
          className={`${classOverride} font-medium transition-transform duration-300 group-hover:translate-x-1`}
        >
          {children}
        </span>
      </div>
      <span
        className={`${
          underlineVariants[variant ?? 'primary']
        } absolute left-0 h-[1px] w-0 origin-left transform transition-all duration-300 group-hover:w-full`}
      />
    </a>
  ) : (
    <Link scroll={false} href={link}>
      <a
        target={target}
        className={`border-none ${
          variants[variant ?? 'primary']
        } group relative inline-block w-fit pr-1 transition-all`}
      >
        <div className="relative z-30 flex items-center gap-1 md:gap-3">
          <div className="min-w-[16px] max-w-[16px] overflow-hidden">
            <div className="flex w-[32px] translate-x-[-16px] flex-row gap-1 transition-transform duration-300 group-hover:translate-x-[4px]">
              <ArrowRight size={'16px'} />
              <ArrowRight size={'16px'} />
            </div>
          </div>
          <span
            className={`${classOverride} font-medium transition-transform duration-300 group-hover:translate-x-1`}
          >
            {children}
          </span>
        </div>
        <span
          className={`${
            underlineVariants[variant ?? 'primary']
          } absolute left-0 h-[1px] w-0 origin-left transform transition-all duration-300 group-hover:w-full`}
        />
      </a>
    </Link>
  )
}

export default ArrowRightButton
