import { useRouter } from 'next/router'
import { SolutionFilterTypes } from '../../enums/solution-filter-types'
import { SolutionFilters } from '../../types/SolutionFilters'

type RouterFilters = {
  firstSelected: string
} & SolutionFilters

const useFilterRouter = (): RouterFilters => {
  const router = useRouter()

  const path = router.pathname.split('/')
  let filterIndustry = router.query.industry?.toString() ?? ''
  let filterEnergy = router.query.energy?.toString() ?? ''
  const filterEnergyTheme = router.query.energyTheme?.toString() ?? ''
  const firstQueriedItem = router.asPath.match(/\?(\w*)=/)
  let firstSelected = firstQueriedItem ? firstQueriedItem[1] : ''
  if (path[2] === 'industry') {
    filterIndustry = router.query.slug?.toString() ?? ''
    firstSelected = 'industry'
  } else if (path[2] === 'energytype') {
    filterEnergy = router.query.slug?.toString() ?? ''
    firstSelected = 'energy'
  }

  return {
    [SolutionFilterTypes.INDUSTRY]: filterIndustry,
    [SolutionFilterTypes.ENERGY]: filterEnergy,
    [SolutionFilterTypes.ENERGY_THEME]: filterEnergyTheme,
    firstSelected,
  }
}

export default useFilterRouter
