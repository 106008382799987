import { useRouter } from 'next/router'
import { useEffect } from 'react'
import usePressReleaseSearchDebouncedValue from './use-press-release-search-debounced-value'

function usePressReleaseURLSearch(): [string, (value: string) => void] {
  const router = useRouter()

  const [inputValue, setInputValue] = usePressReleaseSearchDebouncedValue(
    router.query.s?.toString(),
    router.isReady
  )

  useEffect(() => {
    if (!router.query.s) return
    setInputValue(router.query.s?.toString() ?? '')
  }, [router.query.s])

  return [inputValue, setInputValue]
}

export default usePressReleaseURLSearch
