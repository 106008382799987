import Link from 'next/link'
import ArrowRightButton from '../../components/arrow-right-button'
import DotWithLabel from '../../components/dot-with-label'
import Section from '../../components/section'
import { RelatedInsightRecord } from '../../generated/graphql'
import formatDate from '../../utils/format-date'
import Dynamic from '../../components/dynamic'

type Props = {
  block: RelatedInsightRecord
}

function RelatedInsightRecord({ block }: Props) {
  return (
    <Section>
      <div className="col-span-2 mt-2 mb-5 overflow-hidden sm:col-span-8 sm:-mb-1">
        <h2 className="py-1 text-header-2">
          Related Insights
          <sup className="font-light">{block.relatedInsights.length}</sup>
        </h2>

        <div className="no-scrollbar mt-3 flex snap-x snap-mandatory flex-col gap-6 overflow-x-scroll sm:mt-4 sm:flex-row">
          {block.relatedInsights.map((insight) => {
            return (
              <Link
                scroll={false}
                key={insight.id}
                href={`/insights/${insight.slug}`}
              >
                <div
                  key={insight.id}
                  className=" group relative flex shrink-0 cursor-pointer snap-start flex-row overflow-hidden sm:w-[80vw] sm:gap-2 lg:w-[30vw]"
                >
                  <div>
                    <div
                      className={
                        'absolute top-[-1px] h-[2px] w-full bg-greys-1'
                      }
                    />

                    <div
                      className={
                        'absolute top-[-1px] h-[2px] w-full -translate-x-full bg-primary-rystad-blue transition-transform duration-500 group-hover:translate-x-[0]'
                      }
                    />
                  </div>

                  <div className="mt-4 flex flex-col justify-between gap-3 md:mt-5">
                    <h2 className="text-header-3 line-clamp-3">
                      {insight.title}
                    </h2>

                    <div className="flex flex-row items-center justify-start gap-2">
                      <DotWithLabel
                        dotColor={insight.tag?.dotColor?.hex}
                        label={insight.tag?.tagLabel}
                      />
                      {insight?.publicationDate && (
                        <h2 className="text-text-small text-greys-3 md:text-primary-rystad-blue">
                          <Dynamic>
                            {formatDate(insight.publicationDate, 'full')}
                          </Dynamic>
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>

        <div className="mt-7 block sm:hidden">
          <ArrowRightButton link="/news" variant="primary">
            More insights
          </ArrowRightButton>
        </div>
      </div>
    </Section>
  )
}

export default RelatedInsightRecord
