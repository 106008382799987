import { Image } from 'react-datocms'
import { SolutionRelatedInsightModelRelatedInsightsField } from '../../../generated/graphql'
import formatDate from '../../../utils/format-date'
import getRelatedInsightUrl from '../../../utils/get-related-insight-url'
import { isPressReleaseArticleRecord } from '../../press-release-thumbnail/press-release-thumbnail'
import {
  isDirectUploadOrExternalLinkInsightRecord,
  isInsightRecord,
  isPressReleaseRecord,
} from '../get-solution-releated-insight-type'
import getSecureInfoFromDirectUploadOrExternalLink from '../get-secure-info-from-direct-upload-or-external-link'
import SecureAnchorWrapper from '../../../components/paywall/secure-anchor-wrapper'
import usePaywallStore from '../../../stores/use-paywall-store'
import getFileLinkFromDirectUploadOrExternalLinkField from '../get-file-link-from-direct-upload-or-external-link-field'
import useIsPaywallKeyUnlocked from '../../../hooks/use-is-paywall-key-unlocked'
import { useRouter } from 'next/router'
import Dynamic from '../../../components/dynamic'

type Props = {
  insight: SolutionRelatedInsightModelRelatedInsightsField
}

export function displayPressReleaseHeadline(
  insight: SolutionRelatedInsightModelRelatedInsightsField
) {
  if (isPressReleaseRecord(insight)) {
    const article = insight?.blocks.find((block) =>
      isPressReleaseArticleRecord(block)
    )

    return article && isPressReleaseArticleRecord(article) && article.headline
  } else {
    return ''
  }
}

function displayPressReleaseDesktopImage(
  insight: SolutionRelatedInsightModelRelatedInsightsField
) {
  if (isPressReleaseRecord(insight)) {
    const article = insight?.blocks.find((block) =>
      isPressReleaseArticleRecord(block)
    )

    return (
      article &&
      isPressReleaseArticleRecord(article) &&
      article?.image?.responsiveImage && (
        <div className="mt-5 ml-2 w-1/3 shrink-0 sm:w-1/5" key={insight.id}>
          <Image
            objectFit="cover"
            style={{ maxWidth: 'auto' }}
            data={article.image.responsiveImage}
          />
        </div>
      )
    )
  } else {
    return null
  }
}

function RelatedInsightDesktopThumbnail({ insight }: Props) {
  const router = useRouter()
  let insightUrl = getRelatedInsightUrl(insight)
  const secureInfo = getSecureInfoFromDirectUploadOrExternalLink(insight)
  const setCurrentPaywall = usePaywallStore((state) => state.setCurrentPaywall)
  const isVisible = useIsPaywallKeyUnlocked(insight.id ?? '')
  const handleClickSecureContent = () => {
    if (!isDirectUploadOrExternalLinkInsightRecord(insight)) {
      return
    }
    const headline = insight.insightTitle

    const link =
      getFileLinkFromDirectUploadOrExternalLinkField(insight.insight[0]) ?? ''

    setCurrentPaywall({
      key: insight.id,
      headline: headline ?? '',
      sugarMarketUrl: secureInfo?.sugarmarketPopupLink ?? '',
      onSuccess: () => {
        window.open(link, '_blank')
      },
    })

    router.push('/authentication-form', '/authentication-form', {
      scroll: false,
    })
  }

  const target =
    isInsightRecord(insight) ||
    isDirectUploadOrExternalLinkInsightRecord(insight)
      ? '_blank'
      : '_self'

  return (
    <SecureAnchorWrapper
      isSecure={!isVisible && secureInfo && secureInfo.isSecure}
      scroll={false}
      href={insightUrl}
      target={target}
      onClick={handleClickSecureContent}
    >
      <div
        key={'solution_insight_' + insight.id}
        className="group relative hidden flex-row justify-between overflow-hidden hover:cursor-pointer md:flex"
      >
        <div className={'absolute top-[-1px] h-[2px] w-full bg-greys-1'} />
        <div
          className={
            'absolute top-[-1px] h-[2px] w-full -translate-x-full bg-current transition-transform duration-300 group-hover:translate-x-[0]'
          }
        />

        <div className="mt-5 flex flex-col gap-5">
          <h2 className="line-clamp-2">
            <span className="font-display text-header-2-5">
              {insight?.tag?.tagLabel}:{' '}
            </span>
            <span className="text-header-2-5">
              {isInsightRecord(insight) && insight.title}
              {isPressReleaseRecord(insight) &&
                displayPressReleaseHeadline(insight)}
              {!isInsightRecord(insight) &&
                !isPressReleaseRecord(insight) &&
                isDirectUploadOrExternalLinkInsightRecord(insight) &&
                insight?.insightTitle}
            </span>
          </h2>

          <div className="flex flex-row-reverse items-center justify-end gap-2 sm:flex-row sm:justify-start">
            <div className="flex flex-row items-center gap-1">
              <div
                style={{
                  backgroundColor: insight?.tag?.dotColor?.hex,
                }}
                className={`hidden h-1 w-1 shrink-0 rounded-full sm:block`}
              ></div>
              <h2 className="text-text-base transition-all duration-300 group-hover:text-primary-rystad-blue-light-tint">
                {insight?.tag?.tagLabel}
              </h2>
            </div>

            {isPressReleaseRecord(insight) && insight?.publicationDate && (
              <h2 className="text-text-base text-greys-3">
                <Dynamic>
                  {formatDate(new Date(insight.publicationDate), 'full')}
                </Dynamic>
              </h2>
            )}

            {!isPressReleaseRecord(insight) && insight?._publishedAt && (
              <h2 className="text-text-base text-greys-3">
                <Dynamic>
                  {formatDate(new Date(insight._publishedAt), 'full')}
                </Dynamic>
              </h2>
            )}
          </div>
        </div>

        {isInsightRecord(insight) && insight?.image?.responsiveImage && (
          <div
            className="relative mt-5 ml-2 flex w-1/3 shrink-0 sm:w-1/5"
            key={`image_${insight.id}`}
          >
            <Image
              objectFit="cover"
              style={{ maxWidth: 'auto' }}
              data={insight?.image?.responsiveImage}
            />
          </div>
        )}

        {!isPressReleaseRecord(insight) &&
          !isInsightRecord(insight) &&
          isDirectUploadOrExternalLinkInsightRecord(insight) &&
          insight?.image?.responsiveImage && (
            <div className="mt-5 ml-2 w-1/5" key={`image_${insight.id}`}>
              <Image
                objectFit="cover"
                style={{ maxWidth: 'auto' }}
                data={insight?.image?.responsiveImage}
              />
            </div>
          )}

        {isPressReleaseRecord(insight) &&
          displayPressReleaseDesktopImage(insight)}
      </div>
    </SecureAnchorWrapper>
  )
}

export default RelatedInsightDesktopThumbnail
