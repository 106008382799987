import { FrontPageKeyNewsElementRecord } from '../../generated/graphql'
import { DisplayedPublicationProvider } from './context/use-displayed-publication'
import FrontPageKeyNewsElementContainer from './front-page-key-news-container'

type Props = {
  block: FrontPageKeyNewsElementRecord
}

function FrontPageKeyNewsElement({ block }: Props) {

  // KeyNewsPublicationExternalRecord
  const parsedBPublications = block.publications?.map(p => p.__typename === "KeyNewsPublicationExternalRecord" ? parseKeyNewsPublicationExternalRecord(p) : p)

  return (
    <DisplayedPublicationProvider>
      <FrontPageKeyNewsElementContainer block={{...block, publications: parsedBPublications}} />
    </DisplayedPublicationProvider>
  )
}

const parseKeyNewsPublicationExternalRecord = (externalPublication: any) => {
  const publication =  {
    __typename: externalPublication.__typename,
    publication: 
      {
        __typename: 'PressReleaseRecord',
        id: externalPublication.id,
        blocks: [
          {
            __typename: 'PressReleaseArticleRecord',
            headline: externalPublication.title,
            image: externalPublication.image,
          }
        ],
        excrept: externalPublication.excrept,
        energyType: externalPublication.energyType,
        tag: externalPublication.tag,
      }
    ,
    ctaLink: externalPublication.ctaLink,
  }
  return publication
}

export default FrontPageKeyNewsElement
