import { useEffect, useState } from 'react'

function useFullscreen() {
  const [fullscreen, setFullscreen] = useState(false)

  useEffect(() => {
    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) {
        setFullscreen(true)
      } else {
        setFullscreen(false)
      }
    })
  }, [])

  return fullscreen
}

export default useFullscreen
