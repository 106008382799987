import { HowWeDeliverValueTabRecord } from '../../generated/graphql'
import CtaLink from '../../components/cta-link'
import { Image } from 'react-datocms'

type Props = {
  tab: HowWeDeliverValueTabRecord
}

function HowWeDeliverItemContent({ tab }: Props) {
  const cta = tab.cta?.[0]
  return (
    <div className={'flex flex-col items-start gap-5'}>
      <div
        className={'html'}
        dangerouslySetInnerHTML={{ __html: tab.description ?? '' }}
      />
      {tab.image && (
        <Image objectFit="cover" data={tab.image.responsiveImage!} />
      )}
      {cta && (
        <div className="self-stretch md:self-start">
          <CtaLink cta={cta} />
        </div>
      )}
    </div>
  )
}

export default HowWeDeliverItemContent
