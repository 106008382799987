import Locations from './locations'

function RevealingMap() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="350 0 600 692"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        willChange: 'viewBox',
      }}
    >
      <g clipPath="url(#clip0_5544_91537)">
        <g className="map-base-dots">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1149.99 521.141C1147.18 521.141 1144.94 523.446 1144.94 526.256C1144.94 529.349 1147.43 531.406 1150.03 531.406C1151.28 531.406 1152.54 530.935 1153.58 529.888C1156.78 526.645 1154.5 521.141 1149.99 521.141Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.67 536.148C1147.07 536.148 1144.94 538.244 1144.94 540.886C1144.94 543.724 1147.27 545.615 1149.69 545.615C1150.85 545.615 1152.03 545.183 1152.99 544.214C1155.99 541.247 1153.89 536.148 1149.67 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.67 550.359C1147.03 550.359 1144.94 552.665 1144.94 555.475C1144.94 558.569 1147.27 560.624 1149.7 560.624C1150.86 560.624 1152.03 560.154 1152.99 559.107C1155.99 555.907 1153.9 550.359 1149.67 550.359H1149.67Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1164.22 536.148C1161.39 536.148 1159.15 538.244 1159.15 540.886C1159.15 543.724 1161.65 545.615 1164.24 545.615C1165.49 545.615 1166.75 545.183 1167.77 544.214C1170.99 541.247 1168.71 536.148 1164.22 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.22 550.359C1161.39 550.398 1159.15 552.665 1159.15 555.475C1159.15 558.569 1161.65 560.624 1164.25 560.624C1165.49 560.624 1166.75 560.154 1167.77 559.107C1170.99 555.864 1168.71 550.359 1164.22 550.359H1164.22Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 536.148C1175.64 536.148 1173.41 538.244 1173.37 540.886C1173.37 543.724 1175.85 545.615 1178.46 545.615C1179.7 545.615 1180.97 545.183 1182 544.214C1185.21 541.247 1182.93 536.148 1178.46 536.148Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 550.359C1175.64 550.359 1173.37 552.665 1173.37 555.475C1173.37 558.569 1175.86 560.624 1178.46 560.624C1179.7 560.624 1180.97 560.154 1182 559.107C1185.21 555.907 1182.93 550.359 1178.46 550.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 579.575C1175.64 579.575 1173.41 581.706 1173.37 584.303C1173.37 587.163 1175.86 589.045 1178.46 589.045C1179.7 589.045 1180.97 588.619 1182 587.66C1185.21 584.667 1182.93 579.574 1178.46 579.574V579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 536.148C1190.64 536.148 1188.37 538.244 1188.37 540.886C1188.37 543.724 1190.85 545.615 1193.45 545.615C1194.69 545.615 1195.97 545.183 1197 544.214C1200.2 541.247 1197.93 536.148 1193.41 536.148H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 550.359C1190.64 550.359 1188.37 552.665 1188.37 555.475C1188.37 558.569 1190.86 560.624 1193.46 560.624C1194.7 560.624 1195.97 560.154 1197 559.107C1200.2 555.864 1197.93 550.359 1193.41 550.359H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1193.41 593.789C1190.64 593.789 1188.37 596.057 1188.37 598.917C1188.37 602.008 1190.84 604.045 1193.44 604.045C1194.69 604.045 1195.96 603.572 1197 602.518C1200.2 599.307 1197.93 593.789 1193.41 593.789V593.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 579.575C1190.64 579.575 1188.37 581.706 1188.37 584.303C1188.37 587.163 1190.86 589.045 1193.46 589.045C1194.7 589.045 1195.97 588.619 1197 587.66C1200.2 584.667 1197.93 579.574 1193.41 579.574L1193.41 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 564.574C1190.64 564.574 1188.37 566.878 1188.37 569.697C1188.37 572.795 1190.86 574.834 1193.46 574.834C1194.7 574.834 1195.97 574.368 1197 573.333C1200.2 570.087 1197.93 564.574 1193.41 564.574H1193.41Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1222.63 536.148C1219.85 536.148 1217.62 538.244 1217.58 540.886C1217.58 543.724 1220.07 545.615 1222.67 545.615C1223.91 545.615 1225.18 545.183 1226.22 544.214C1229.42 541.247 1227.14 536.148 1222.63 536.148H1222.63Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1222.63 550.359C1219.85 550.359 1217.58 552.665 1217.58 555.475C1217.58 558.569 1220.07 560.624 1222.67 560.624C1223.91 560.624 1225.18 560.154 1226.22 559.107C1229.42 555.864 1227.14 550.359 1222.63 550.359H1222.63Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1222.63 593.789C1219.85 593.789 1217.58 596.057 1217.58 598.917C1217.58 602.008 1220.07 604.045 1222.67 604.045C1223.92 604.045 1225.19 603.572 1226.22 602.518C1229.42 599.307 1227.14 593.789 1222.63 593.789L1222.63 593.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1222.63 579.575C1219.85 579.575 1217.62 581.706 1217.58 584.303C1217.58 587.163 1220.07 589.045 1222.67 589.045C1223.91 589.045 1225.19 588.619 1226.22 587.66C1229.42 584.667 1227.14 579.574 1222.63 579.574L1222.63 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1222.63 564.574C1219.85 564.574 1217.58 566.878 1217.58 569.697C1217.58 572.795 1220.09 574.834 1222.69 574.834C1223.93 574.834 1225.2 574.368 1226.22 573.333C1229.42 570.087 1227.14 564.574 1222.63 564.574H1222.63Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1236.86 550.359C1234.08 550.359 1231.8 552.665 1231.8 555.475C1231.8 558.569 1234.3 560.624 1236.9 560.624C1238.15 560.624 1239.42 560.154 1240.46 559.107C1243.63 555.864 1241.39 550.359 1236.86 550.359H1236.86Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1236.86 579.575C1234.08 579.575 1231.8 581.706 1231.8 584.303C1231.8 587.163 1234.3 589.045 1236.91 589.045C1238.15 589.045 1239.42 588.619 1240.46 587.66C1243.63 584.667 1241.39 579.574 1236.86 579.574V579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1236.86 564.574C1234.08 564.574 1231.8 566.878 1231.8 569.697C1231.8 572.795 1234.3 574.834 1236.91 574.834C1238.15 574.834 1239.42 574.368 1240.46 573.333C1243.63 570.087 1241.39 564.574 1236.86 564.574Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 564.574C1175.64 564.574 1173.37 566.878 1173.37 569.697C1173.37 572.795 1175.88 574.834 1178.48 574.834C1179.72 574.834 1180.98 574.368 1182 573.333C1185.21 570.087 1182.93 564.574 1178.46 564.574H1178.46Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.22 564.574C1161.39 564.574 1159.15 566.878 1159.15 569.697C1159.15 572.795 1161.65 574.834 1164.25 574.834C1165.49 574.834 1166.75 574.368 1167.77 573.333C1170.99 570.087 1168.71 564.574 1164.22 564.574H1164.22Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1149.67 564.574C1147.03 564.574 1144.94 566.878 1144.94 569.697C1144.94 572.795 1147.27 574.834 1149.7 574.834C1150.86 574.834 1152.04 574.368 1152.99 573.333C1155.99 570.087 1153.9 564.574 1149.67 564.574H1149.67Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 560.624C1092.85 560.624 1094.11 560.154 1095.14 559.107C1098.35 555.864 1096.1 550.359 1091.58 550.359C1088.75 550.398 1086.51 552.665 1086.51 555.475C1086.51 558.569 1089.01 560.624 1091.6 560.624Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1091.58 564.574C1088.75 564.574 1086.51 566.878 1086.51 569.697C1086.51 572.795 1089.01 574.834 1091.62 574.834C1092.87 574.834 1094.14 574.368 1095.17 573.333C1098.35 570.087 1096.1 564.574 1091.58 564.574H1091.58Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.81 536.148C1103 536.148 1100.76 538.244 1100.72 540.886C1100.72 543.724 1103.23 545.615 1105.83 545.615C1107.07 545.615 1108.34 545.183 1109.36 544.214C1112.56 541.247 1110.28 536.148 1105.81 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.81 550.359C1103 550.359 1100.76 552.665 1100.72 555.475C1100.72 558.569 1103.23 560.624 1105.83 560.624C1107.07 560.624 1108.34 560.154 1109.36 559.107C1112.56 555.907 1110.28 550.359 1105.81 550.359H1105.81Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.81 564.574C1103 564.574 1100.76 566.878 1100.72 569.697C1100.72 572.795 1103.23 574.834 1105.83 574.834C1107.07 574.834 1108.34 574.368 1109.36 573.333C1112.56 570.087 1110.28 564.574 1105.81 564.574H1105.81Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.77 521.141C1118 521.141 1115.72 523.444 1115.72 526.29C1115.75 529.371 1118.23 531.406 1120.83 531.406C1122.08 531.406 1123.36 530.93 1124.4 529.879C1127.56 526.64 1125.29 521.141 1120.77 521.141Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.77 536.148C1118 536.148 1115.72 538.277 1115.72 540.881C1115.75 543.735 1118.23 545.615 1120.82 545.615C1122.07 545.615 1123.33 545.179 1124.36 544.206C1127.56 541.242 1125.29 536.148 1120.77 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.77 550.359C1118 550.359 1115.72 552.663 1115.72 555.509C1115.75 558.59 1118.23 560.624 1120.82 560.624C1122.06 560.624 1123.33 560.154 1124.36 559.098C1127.56 555.859 1125.29 550.359 1120.77 550.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1120.77 564.574C1118 564.574 1115.72 566.878 1115.72 569.697C1115.72 572.795 1118.23 574.834 1120.84 574.834C1122.07 574.834 1123.34 574.368 1124.36 573.333C1127.56 570.087 1125.28 564.574 1120.77 564.574H1120.77Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 506.934C1132.22 506.934 1129.93 509.233 1129.93 512.047C1129.93 515.134 1132.44 517.189 1135.04 517.189C1136.29 517.189 1137.56 516.723 1138.6 515.676C1141.77 512.436 1139.53 506.934 1135 506.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135.05 531.406C1136.29 531.406 1137.55 530.935 1138.57 529.888C1141.77 526.645 1139.5 521.141 1134.99 521.141C1132.21 521.141 1129.93 523.446 1129.93 526.256C1129.93 529.35 1132.44 531.406 1135.05 531.406Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 564.574C1132.22 564.574 1129.93 566.878 1129.93 569.697C1129.93 572.795 1132.44 574.834 1135.04 574.834C1136.29 574.834 1137.56 574.368 1138.6 573.333C1141.77 570.087 1139.53 564.574 1135 564.574Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 550.359C1132.22 550.359 1129.93 552.665 1129.93 555.475C1129.93 558.569 1132.44 560.624 1135.04 560.624C1136.29 560.624 1137.56 560.154 1138.6 559.107C1141.77 555.864 1139.53 550.359 1135 550.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 536.148C1132.22 536.148 1129.93 538.244 1129.93 540.886C1129.93 543.724 1132.43 545.615 1135.04 545.615C1136.29 545.615 1137.56 545.183 1138.6 544.214C1141.77 541.247 1139.53 536.148 1135 536.148H1135Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1323.7 521.141C1320.93 521.141 1318.65 523.446 1318.65 526.256C1318.65 529.349 1321.14 531.406 1323.74 531.406C1324.99 531.406 1326.25 530.935 1327.29 529.888C1330.49 526.645 1328.21 521.141 1323.7 521.141Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1265.28 536.148C1262.5 536.148 1260.21 538.244 1260.21 540.886C1260.21 543.724 1262.71 545.615 1265.32 545.615C1266.57 545.615 1267.85 545.183 1268.88 544.214C1272.06 541.247 1269.81 536.148 1265.28 536.148H1265.28Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1265.31 531.406C1266.55 531.406 1267.82 530.935 1268.86 529.888C1272.06 526.645 1269.78 521.141 1265.27 521.141C1262.49 521.141 1260.21 523.446 1260.21 526.256C1260.21 529.35 1262.71 531.406 1265.31 531.406Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1280.29 506.934C1277.47 506.934 1275.23 509.233 1275.23 512.047C1275.23 515.134 1277.73 517.189 1280.32 517.189C1281.56 517.189 1282.83 516.723 1283.85 515.676C1287.06 512.436 1284.82 506.934 1280.29 506.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1251.1 491.93C1248.28 491.968 1246.05 494.235 1246.01 497.084C1246.01 500.148 1248.52 502.195 1251.12 502.195C1252.36 502.195 1253.62 501.724 1254.65 500.677C1257.85 497.473 1255.57 491.968 1251.1 491.93H1251.1Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1221.89 473.76C1223.13 473.76 1224.39 473.298 1225.42 472.259C1228.63 469.013 1226.39 463.5 1221.86 463.5C1219.03 463.5 1216.79 465.77 1216.79 468.623C1216.79 471.721 1219.29 473.76 1221.89 473.76Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 458.764C1150.86 458.764 1152.04 458.297 1152.99 457.263C1155.99 454.017 1153.89 448.504 1149.67 448.504C1147.03 448.504 1144.94 450.808 1144.94 453.627C1144.94 456.72 1147.27 458.764 1149.7 458.764H1149.7Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 477.719C1190.64 477.719 1188.37 479.989 1188.37 482.851C1188.37 485.926 1190.85 487.974 1193.45 487.974C1194.69 487.974 1195.97 487.506 1197 486.456C1200.2 483.241 1197.93 477.719 1193.41 477.719H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1193.41 463.5C1190.64 463.5 1188.37 465.809 1188.37 468.623C1188.37 471.721 1190.86 473.76 1193.46 473.76C1194.7 473.76 1195.97 473.298 1197 472.259C1200.2 469.013 1197.93 463.5 1193.41 463.5H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 487.974C1179.7 487.974 1180.97 487.506 1182 486.456C1185.21 483.241 1182.93 477.719 1178.46 477.719C1175.64 477.719 1173.41 479.989 1173.37 482.851C1173.37 485.926 1175.85 487.974 1178.46 487.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 473.76C1179.7 473.76 1180.97 473.298 1182 472.259C1185.21 469.013 1182.93 463.5 1178.46 463.5C1175.64 463.5 1173.37 465.77 1173.37 468.623C1173.37 471.721 1175.86 473.76 1178.46 473.76Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.25 473.76C1165.49 473.76 1166.75 473.298 1167.77 472.259C1170.99 469.013 1168.71 463.5 1164.22 463.5C1161.39 463.5 1159.15 465.809 1159.15 468.623C1159.15 471.721 1161.65 473.76 1164.25 473.76H1164.25Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.25 458.764C1165.49 458.764 1166.75 458.297 1167.77 457.263C1170.99 454.017 1168.71 448.504 1164.22 448.504C1161.39 448.504 1159.15 450.808 1159.15 453.627C1159.15 456.72 1161.65 458.764 1164.25 458.764H1164.25Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1308.7 608C1305.92 608.038 1303.65 610.306 1303.65 613.154C1303.65 616.219 1306.13 618.265 1308.73 618.265C1309.98 618.265 1311.25 617.799 1312.29 616.747C1315.48 613.548 1313.21 608 1308.7 608Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1294.53 637.215C1291.71 637.215 1289.47 639.524 1289.43 642.338C1289.43 645.436 1291.94 647.475 1294.55 647.475C1295.79 647.475 1297.05 647.013 1298.07 645.974C1301.28 642.732 1299 637.215 1294.53 637.215Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1294.53 623.008C1291.71 623.008 1289.47 625.131 1289.43 627.728C1289.43 630.577 1291.94 632.474 1294.55 632.474C1295.79 632.474 1297.05 632.045 1298.07 631.078C1301.28 628.087 1299 623.008 1294.53 623.008Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1280.29 637.215C1277.47 637.215 1275.23 639.524 1275.23 642.338C1275.23 645.436 1277.73 647.475 1280.32 647.475C1281.56 647.475 1282.83 647.013 1283.85 645.974C1287.06 642.732 1284.82 637.215 1280.29 637.215Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1193.41 637.215C1190.64 637.215 1188.37 639.524 1188.37 642.338C1188.37 645.436 1190.86 647.475 1193.46 647.475C1194.7 647.475 1195.97 647.013 1197 645.974C1200.2 642.732 1197.93 637.215 1193.41 637.215H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 608C1204.86 608.038 1202.57 610.306 1202.57 613.154C1202.57 616.219 1205.07 618.265 1207.68 618.265C1208.93 618.265 1210.21 617.799 1211.24 616.747C1214.42 613.548 1212.17 608 1207.64 608H1207.64Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 593.789C1204.86 593.789 1202.57 596.057 1202.57 598.917C1202.57 602.008 1205.06 604.045 1207.66 604.045C1208.92 604.045 1210.2 603.572 1211.24 602.518C1214.42 599.307 1212.17 593.789 1207.64 593.789L1207.64 593.789Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 579.575C1204.86 579.575 1202.57 581.706 1202.57 584.303C1202.57 587.163 1205.07 589.045 1207.69 589.045C1208.93 589.045 1210.21 588.619 1211.24 587.66C1214.42 584.667 1212.17 579.574 1207.64 579.574L1207.64 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 564.574C1204.86 564.574 1202.57 566.878 1202.57 569.697C1202.57 572.795 1205.07 574.834 1207.69 574.834C1208.93 574.834 1210.21 574.368 1211.24 573.333C1214.42 570.087 1212.17 564.574 1207.64 564.574H1207.64Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 550.359C1204.86 550.359 1202.57 552.665 1202.57 555.475C1202.57 558.569 1205.07 560.624 1207.69 560.624C1208.93 560.624 1210.21 560.154 1211.24 559.107C1214.42 555.864 1212.17 550.359 1207.64 550.359H1207.64Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1207.64 536.148C1204.86 536.148 1202.57 538.244 1202.57 540.886C1202.57 543.724 1205.07 545.615 1207.68 545.615C1208.93 545.615 1210.21 545.183 1211.24 544.214C1214.42 541.247 1212.17 536.148 1207.64 536.148H1207.64Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.68 531.406C1208.93 531.406 1210.2 530.935 1211.22 529.888C1214.42 526.645 1212.14 521.141 1207.63 521.141C1204.85 521.141 1202.57 523.446 1202.57 526.256C1202.57 529.35 1205.08 531.406 1207.68 531.406Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 608C1190.64 608.038 1188.37 610.306 1188.37 613.154C1188.37 616.219 1190.85 618.265 1193.45 618.265C1194.69 618.265 1195.97 617.799 1197 616.747C1200.2 613.548 1197.93 608 1193.41 608H1193.41Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 521.141C1190.64 521.141 1188.37 523.446 1188.37 526.256C1188.37 529.349 1190.87 531.406 1193.48 531.406C1194.72 531.406 1195.98 530.935 1197 529.888C1200.2 526.645 1197.93 521.141 1193.41 521.141H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 506.934C1190.64 506.934 1188.37 509.233 1188.37 512.047C1188.37 515.134 1190.86 517.189 1193.46 517.189C1194.7 517.189 1195.97 516.723 1197 515.676C1200.2 512.436 1197.93 506.934 1193.41 506.934H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 608C1175.64 608.038 1173.41 610.306 1173.37 613.154C1173.37 616.219 1175.85 618.265 1178.46 618.265C1179.7 618.265 1180.97 617.799 1182 616.747C1185.21 613.548 1182.93 608 1178.46 608Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 593.789C1175.64 593.789 1173.37 596.057 1173.37 598.917C1173.37 602.008 1175.86 604.045 1178.46 604.045C1179.7 604.045 1180.98 603.572 1182 602.518C1185.21 599.307 1182.93 593.789 1178.46 593.789V593.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 521.141C1175.64 521.141 1173.37 523.446 1173.37 526.256C1173.37 529.349 1175.88 531.406 1178.49 531.406C1179.74 531.406 1181.01 530.935 1182.04 529.888C1185.21 526.645 1182.97 521.141 1178.46 521.141Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 517.189C1179.7 517.189 1180.97 516.723 1182 515.676C1185.21 512.436 1182.93 506.934 1178.46 506.934C1175.64 506.934 1173.41 509.233 1173.37 512.047C1173.37 515.134 1175.86 517.189 1178.46 517.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.22 593.793C1161.39 593.793 1159.15 596.061 1159.15 598.921C1159.15 602.012 1161.64 604.048 1164.22 604.048C1165.47 604.048 1166.75 603.576 1167.77 602.522C1170.99 599.311 1168.71 593.793 1164.22 593.793L1164.22 593.793Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.22 579.575C1161.39 579.575 1159.15 581.706 1159.15 584.303C1159.15 587.163 1161.65 589.045 1164.25 589.045C1165.49 589.045 1166.75 588.619 1167.77 587.66C1170.99 584.667 1168.71 579.574 1164.22 579.574L1164.22 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.2 521.141C1161.39 521.141 1159.15 523.446 1159.15 526.256C1159.15 529.349 1161.64 531.406 1164.24 531.406C1165.48 531.406 1166.76 530.935 1167.79 529.888C1170.99 526.645 1168.72 521.141 1164.2 521.141Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.25 517.189C1165.49 517.189 1166.75 516.723 1167.77 515.676C1170.99 512.436 1168.71 506.934 1164.22 506.934C1161.39 506.934 1159.15 509.233 1159.15 512.047C1159.15 515.134 1161.65 517.189 1164.25 517.189H1164.25Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.67 579.575C1147.07 579.575 1144.94 581.706 1144.94 584.303C1144.94 587.163 1147.27 589.045 1149.7 589.045C1150.86 589.045 1152.04 588.619 1152.99 587.66C1155.99 584.667 1153.9 579.574 1149.67 579.574L1149.67 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 517.189C1150.86 517.189 1152.03 516.723 1152.99 515.676C1155.99 512.436 1153.89 506.934 1149.67 506.934C1147.07 506.934 1144.94 509.233 1144.94 512.047C1144.94 515.134 1147.27 517.189 1149.7 517.189H1149.7Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.69 502.195C1150.85 502.195 1152.03 501.724 1152.99 500.677C1155.99 497.473 1153.89 491.93 1149.67 491.93C1147.03 491.968 1144.94 494.235 1144.94 497.084C1144.94 500.148 1147.27 502.195 1149.69 502.195Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1135 579.575C1132.22 579.575 1129.93 581.706 1129.93 584.303C1129.93 587.163 1132.44 589.045 1135.04 589.045C1136.29 589.045 1137.56 588.619 1138.6 587.66C1141.77 584.667 1139.53 579.574 1135 579.574V579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 491.93C1132.22 491.968 1129.93 494.235 1129.93 497.084C1129.93 500.148 1132.43 502.195 1135.04 502.195C1136.29 502.195 1137.56 501.724 1138.6 500.677C1141.77 497.473 1139.53 491.93 1135 491.93H1135Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.77 579.575C1118 579.575 1115.76 581.706 1115.72 584.303C1115.72 587.163 1118.21 589.045 1120.82 589.045C1122.06 589.045 1123.33 588.619 1124.36 587.66C1127.56 584.667 1125.29 579.574 1120.77 579.574V579.575Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 517.189C1122.06 517.189 1123.33 516.723 1124.36 515.676C1127.56 512.436 1125.29 506.934 1120.77 506.934C1118 506.934 1115.76 509.233 1115.72 512.047C1115.72 515.134 1118.21 517.189 1120.82 517.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.81 579.575C1103 579.575 1100.76 581.706 1100.72 584.303C1100.72 587.163 1103.23 589.045 1105.83 589.045C1107.07 589.045 1108.34 588.619 1109.36 587.66C1112.56 584.667 1110.28 579.574 1105.81 579.574L1105.81 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.81 521.141C1103 521.141 1100.76 523.446 1100.72 526.256C1100.72 529.349 1103.23 531.406 1105.84 531.406C1107.09 531.406 1108.36 530.935 1109.4 529.888C1112.56 526.645 1110.32 521.141 1105.81 521.141Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 517.189C1107.08 517.189 1108.34 516.723 1109.36 515.676C1112.56 512.436 1110.28 506.934 1105.81 506.934C1103 506.934 1100.76 509.233 1100.72 512.047C1100.72 515.134 1103.23 517.189 1105.83 517.189H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.58 579.575C1088.75 579.575 1086.51 581.706 1086.51 584.303C1086.51 587.163 1089.01 589.045 1091.6 589.045C1092.85 589.045 1094.11 588.619 1095.14 587.66C1098.35 584.667 1096.1 579.574 1091.58 579.574V579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1091.6 545.615C1092.84 545.615 1094.11 545.183 1095.14 544.214C1098.35 541.247 1096.1 536.148 1091.58 536.148C1088.75 536.148 1086.51 538.244 1086.51 540.886C1086.51 543.724 1089 545.615 1091.6 545.615Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 531.406C1092.85 531.406 1094.11 530.935 1095.15 529.888C1098.35 526.645 1096.07 521.141 1091.56 521.141C1088.74 521.141 1086.51 523.446 1086.51 526.256C1086.51 529.35 1089 531.406 1091.6 531.406Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 579.575C1074.58 579.575 1072.29 581.706 1072.29 584.303C1072.29 587.163 1074.8 589.045 1077.4 589.045C1078.65 589.045 1079.92 588.619 1080.96 587.66C1084.13 584.667 1081.89 579.574 1077.36 579.574L1077.36 579.575Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 564.574C1074.58 564.574 1072.29 566.878 1072.29 569.697C1072.29 572.795 1074.8 574.834 1077.4 574.834C1078.65 574.834 1079.92 574.368 1080.96 573.333C1084.13 570.087 1081.89 564.574 1077.36 564.574H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 550.359C1074.58 550.359 1072.29 552.665 1072.29 555.475C1072.29 558.569 1074.8 560.624 1077.4 560.624C1078.65 560.624 1079.92 560.154 1080.96 559.107C1084.13 555.907 1081.89 550.359 1077.36 550.359H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 536.148C1074.58 536.148 1072.29 538.244 1072.29 540.886C1072.29 543.724 1074.79 545.615 1077.4 545.615C1078.65 545.615 1079.92 545.183 1080.96 544.214C1084.13 541.247 1081.89 536.148 1077.36 536.148H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 536.148C814.004 536.148 811.719 538.244 811.719 540.886C811.719 543.724 814.218 545.615 816.827 545.615C818.074 545.615 819.35 545.183 820.388 544.214C823.563 541.247 821.316 536.148 816.789 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.829 531.406C818.073 531.406 819.339 530.935 820.36 529.888C823.563 526.645 821.285 521.141 816.773 521.141C813.997 521.141 811.719 523.446 811.719 526.256C811.719 529.35 814.229 531.406 816.829 531.406Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 506.934C814.004 506.934 811.719 509.233 811.719 512.047C811.719 515.134 814.218 517.189 816.831 517.189C818.079 517.189 819.35 516.723 820.388 515.676C823.563 512.436 821.316 506.934 816.789 506.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.77 550.359C798.995 550.398 796.761 552.665 796.723 555.475C796.723 558.569 799.213 560.624 801.812 560.624C803.055 560.624 804.322 560.154 805.36 559.107C808.557 555.864 806.285 550.359 801.77 550.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.823 536.148H801.776C798.997 536.148 796.761 538.244 796.723 540.886C796.723 543.724 799.211 545.615 801.814 545.615C803.058 545.615 804.331 545.183 805.371 544.214C808.562 541.255 806.311 536.148 801.823 536.148H801.823Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.77 521.141C798.995 521.141 796.723 523.446 796.723 526.256C796.723 529.349 799.227 531.406 801.841 531.406C803.088 531.406 804.364 530.935 805.398 529.888C808.557 526.645 806.285 521.141 801.77 521.141Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.959 24.4742C716.201 24.4742 717.463 24.0122 718.486 22.978C721.698 19.7314 719.456 14.2188 714.926 14.2188C712.104 14.2188 709.863 16.5229 709.863 19.3371C709.863 22.4347 712.362 24.4742 714.96 24.4742H714.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 24.4742C702.006 24.4742 703.276 24.0122 704.314 22.978C707.488 19.7314 705.242 14.2188 700.716 14.2188C697.932 14.2188 695.648 16.5229 695.648 19.3371C695.648 22.4347 698.147 24.4742 700.759 24.4742H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 38.6905C687.002 38.6905 688.272 38.2238 689.31 37.189C692.484 33.9407 690.243 28.4258 685.713 28.4258C682.929 28.4258 680.645 30.7359 680.645 33.5511C680.645 36.6498 683.148 38.6905 685.756 38.6905H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 24.4742C687.002 24.4742 688.272 24.0122 689.31 22.978C692.484 19.7314 690.242 14.2188 685.712 14.2188C682.929 14.2188 680.645 16.5229 680.645 19.3371C680.645 22.4347 683.148 24.4742 685.755 24.4742H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 24.4742C672.772 24.4742 674.033 24.0122 675.061 22.978C678.273 19.7698 676.027 14.2188 671.501 14.2188C668.718 14.2188 666.434 16.5229 666.434 19.3371C666.434 22.4347 668.932 24.4742 671.53 24.4742H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M483.574 491.93C480.789 491.968 478.504 494.235 478.504 497.084C478.504 500.148 481.003 502.195 483.612 502.195C484.859 502.195 486.135 501.724 487.173 500.677C490.348 497.473 488.101 491.93 483.574 491.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M483.609 487.974C484.853 487.974 486.121 487.502 487.152 486.448C490.343 483.208 488.097 477.719 483.609 477.719H483.562C480.783 477.719 478.504 480.025 478.504 482.847C478.533 485.937 481.021 487.974 483.609 487.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M483.599 473.765C484.848 473.765 486.121 473.288 487.152 472.233C490.343 469.035 488.097 463.5 483.609 463.5H483.562C480.783 463.5 478.504 465.812 478.504 468.63C478.504 471.723 481.002 473.765 483.6 473.765L483.599 473.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.576 536.148C465.749 536.148 463.508 538.277 463.508 540.881C463.508 543.735 465.992 545.615 468.58 545.615C469.832 545.615 471.102 545.179 472.135 544.206C475.342 541.242 473.063 536.148 468.576 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.599 521.141C465.781 521.141 463.509 523.444 463.547 526.29C463.547 529.371 466.042 531.406 468.632 531.406C469.885 531.406 471.156 530.93 472.185 529.879C475.344 526.64 473.11 521.141 468.599 521.141H468.599Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.604 517.189C469.846 517.189 471.107 516.723 472.135 515.676C475.342 512.436 473.101 506.934 468.576 506.934C465.749 506.934 463.508 509.233 463.508 512.047C463.508 515.134 466.006 517.189 468.604 517.189H468.604Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.609 502.195C469.86 502.195 471.131 501.724 472.173 500.677C475.342 497.473 473.101 491.93 468.576 491.93C465.749 491.968 463.508 494.235 463.508 497.084C463.508 500.148 466.001 502.195 468.609 502.195H468.609Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.58 487.974C469.832 487.974 471.102 487.502 472.135 486.448C475.342 483.237 473.063 477.719 468.576 477.719C465.749 477.719 463.508 480.025 463.508 482.847C463.508 485.937 465.992 487.974 468.58 487.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.604 473.76C469.846 473.76 471.107 473.298 472.135 472.259C475.342 469.013 473.101 463.5 468.576 463.5C465.749 463.5 463.508 465.809 463.508 468.623C463.508 471.721 466.006 473.76 468.604 473.76H468.604Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M454.359 536.148C451.536 536.148 449.289 538.277 449.289 540.881C449.289 543.735 451.774 545.615 454.378 545.615C455.635 545.615 456.916 545.179 457.958 544.206C461.133 541.242 458.886 536.148 454.359 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.381 531.406C455.629 531.406 456.9 530.93 457.93 529.879C461.133 526.64 458.855 521.141 454.343 521.141C451.529 521.141 449.289 523.444 449.289 526.29C449.289 529.371 451.785 531.406 454.381 531.406Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 506.934C451.574 506.934 449.289 509.233 449.289 512.047C449.289 515.134 451.788 517.189 454.402 517.189C455.649 517.189 456.92 516.723 457.958 515.676C461.133 512.436 458.886 506.934 454.359 506.934Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 491.93C451.574 491.968 449.289 494.235 449.289 497.084C449.289 500.148 451.788 502.195 454.397 502.195C455.644 502.195 456.92 501.724 457.958 500.677C461.133 497.473 458.886 491.93 454.359 491.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 477.719C451.536 477.719 449.289 480.025 449.289 482.847C449.289 485.937 451.774 487.974 454.378 487.974C455.635 487.974 456.916 487.502 457.958 486.448C461.133 483.237 458.886 477.719 454.359 477.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 463.5C451.536 463.5 449.289 465.809 449.289 468.623C449.289 471.721 451.788 473.76 454.402 473.76C455.649 473.76 456.92 473.298 457.958 472.259C461.133 469.013 458.886 463.5 454.359 463.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 550.359C437.357 550.359 435.078 552.663 435.078 555.509C435.102 558.59 437.595 560.624 440.189 560.624C441.44 560.624 442.711 560.154 443.743 559.098C446.913 555.859 444.671 550.359 440.146 550.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 536.148C437.357 536.148 435.078 538.277 435.078 540.881C435.078 543.735 437.581 545.615 440.179 545.615C441.436 545.615 442.711 545.179 443.743 544.206C446.913 541.242 444.671 536.148 440.146 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M440.187 531.406C441.434 531.406 442.71 530.93 443.754 529.879C446.913 526.64 444.641 521.141 440.13 521.141C437.35 521.141 435.078 523.444 435.078 526.29C435.102 529.371 437.587 531.406 440.187 531.406H440.187Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 506.934C437.357 506.934 435.078 509.233 435.078 512.047C435.078 515.134 437.576 517.189 440.184 517.189C441.431 517.189 442.706 516.723 443.743 515.676C446.913 512.436 444.671 506.934 440.146 506.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 491.93C437.357 491.968 435.078 494.271 435.078 497.079C435.078 500.16 437.581 502.195 440.179 502.195C441.436 502.195 442.711 501.72 443.743 500.668C446.913 497.429 444.671 491.93 440.146 491.93Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 477.719C437.357 477.719 435.078 480.025 435.078 482.847C435.078 485.937 437.581 487.974 440.179 487.974C441.436 487.974 442.711 487.502 443.743 486.448C446.913 483.237 444.671 477.719 440.146 477.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M440.146 463.5C437.357 463.5 435.078 465.809 435.078 468.623C435.078 471.721 437.576 473.76 440.189 473.76C441.431 473.76 442.706 473.298 443.743 472.259C446.913 469.013 444.671 463.5 440.146 463.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 448.504C437.357 448.504 435.078 450.808 435.078 453.627C435.078 456.72 437.576 458.764 440.189 458.764C441.431 458.764 442.706 458.297 443.743 457.263C446.913 454.017 444.671 448.504 440.146 448.504Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.128 579.575C422.352 579.575 420.112 581.706 420.074 584.303C420.074 587.163 422.566 589.045 425.171 589.045C426.414 589.045 427.681 588.619 428.715 587.66C431.918 584.667 429.641 579.574 425.128 579.574V579.575Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.128 564.574C422.352 564.574 420.074 566.878 420.074 569.697C420.074 572.795 422.584 574.834 425.19 574.834C426.428 574.834 427.695 574.368 428.715 573.333C431.918 570.087 429.641 564.574 425.128 564.574Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.144 550.359C422.359 550.359 420.074 552.663 420.074 555.509C420.103 558.59 422.597 560.624 425.192 560.624C426.439 560.624 427.71 560.154 428.743 559.098C431.918 555.859 429.672 550.359 425.144 550.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.144 536.148C422.359 536.148 420.074 538.277 420.074 540.881C420.074 543.735 422.578 545.615 425.182 545.615C426.434 545.615 427.71 545.179 428.743 544.206C431.918 541.242 429.672 536.148 425.144 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.185 531.406C426.438 531.406 427.714 530.93 428.753 529.879C431.918 526.64 429.641 521.141 425.128 521.141C422.352 521.141 420.074 523.444 420.074 526.29C420.103 529.371 422.589 531.406 425.185 531.406Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M425.171 517.189C426.414 517.189 427.681 516.723 428.715 515.676C431.918 512.436 429.641 506.934 425.128 506.934C422.352 506.934 420.112 509.233 420.074 512.047C420.074 515.134 422.566 517.189 425.171 517.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.18 502.195C426.428 502.195 427.69 501.724 428.715 500.677C431.918 497.473 429.641 491.93 425.128 491.93C422.352 491.968 420.074 494.235 420.074 497.084C420.074 500.148 422.58 502.195 425.18 502.195Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.144 477.719C422.359 477.719 420.074 480.025 420.074 482.847C420.074 485.937 422.578 487.974 425.182 487.974C426.434 487.974 427.71 487.502 428.743 486.448C431.918 483.237 429.672 477.719 425.144 477.719Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.171 473.76C426.414 473.76 427.681 473.298 428.715 472.259C431.918 469.013 429.641 463.5 425.128 463.5C422.352 463.5 420.074 465.809 420.074 468.623C420.074 471.721 422.566 473.76 425.171 473.76Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.171 458.764C426.414 458.764 427.681 458.297 428.715 457.263C431.918 454.017 429.641 448.504 425.128 448.504C422.352 448.504 420.074 450.808 420.074 453.627C420.074 456.72 422.566 458.764 425.171 458.764Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.171 444.55C426.414 444.55 427.681 444.08 428.715 443.032C431.918 439.828 429.641 434.285 425.128 434.285C422.352 434.285 420.112 436.591 420.074 439.401C420.074 442.494 422.566 444.55 425.171 444.55Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.931 608C408.105 608.038 405.863 610.306 405.863 613.154C405.863 616.219 408.357 618.265 410.955 618.265C412.197 618.265 413.463 617.799 414.491 616.747C417.698 613.548 415.419 608 410.931 608H410.931Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M410.931 593.789C408.105 593.789 405.863 596.057 405.863 598.917C405.863 602.008 408.347 604.045 410.936 604.045C412.183 604.045 413.458 603.572 414.491 602.518C417.698 599.307 415.419 593.789 410.931 593.789L410.931 593.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.931 579.575C408.105 579.575 405.863 581.706 405.863 584.303C405.863 587.163 408.361 589.045 410.96 589.045C412.202 589.045 413.463 588.619 414.491 587.66C417.698 584.667 415.419 579.574 410.931 579.574L410.931 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.931 564.574C408.105 564.574 405.863 566.878 405.863 569.697C405.863 572.795 408.361 574.834 410.96 574.834C412.202 574.834 413.463 574.368 414.491 573.333C417.698 570.087 415.419 564.574 410.931 564.574H410.931Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.953 550.359C408.135 550.359 405.863 552.663 405.863 555.509C405.887 558.59 408.373 560.624 410.958 560.624C412.2 560.624 413.472 560.154 414.501 559.098C417.698 555.859 415.426 550.359 410.953 550.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.953 536.148C408.135 536.148 405.863 538.277 405.863 540.881C405.887 543.735 408.373 545.615 410.958 545.615C412.2 545.615 413.472 545.179 414.501 544.206C417.698 541.242 415.426 536.148 410.953 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.953 521.141C408.135 521.141 405.863 523.444 405.863 526.29C405.887 529.371 408.373 531.406 410.972 531.406C412.219 531.406 413.495 530.93 414.539 529.879C417.698 526.64 415.464 521.141 410.953 521.141H410.953Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.931 506.934C408.105 506.934 405.863 509.233 405.863 512.047C405.863 515.134 408.361 517.189 410.96 517.189C412.202 517.189 413.463 516.723 414.491 515.676C417.698 512.436 415.419 506.934 410.931 506.934H410.931Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.931 491.93C408.105 491.968 405.863 494.235 405.863 497.084C405.863 500.148 408.357 502.195 410.955 502.195C412.197 502.195 413.463 501.724 414.491 500.677C417.698 497.473 415.419 491.93 410.931 491.93H410.931Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.958 487.974C412.2 487.974 413.472 487.502 414.501 486.448C417.698 483.237 415.426 477.719 410.953 477.719C408.135 477.719 405.863 480.025 405.863 482.847C405.887 485.937 408.373 487.974 410.958 487.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.972 473.76C412.215 473.76 413.477 473.298 414.501 472.259C417.698 469.013 415.426 463.5 410.953 463.5C408.135 463.5 405.901 465.809 405.863 468.623C405.863 471.721 408.373 473.76 410.972 473.76H410.972Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.958 458.764C412.196 458.764 413.467 458.297 414.501 457.263C417.698 454.017 415.426 448.504 410.953 448.504C408.135 448.504 405.901 450.808 405.863 453.627C405.863 456.72 408.354 458.764 410.958 458.764Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M410.953 444.55C412.196 444.55 413.462 444.08 414.501 443.032C417.698 439.828 415.464 434.285 410.953 434.285C408.135 434.285 405.901 436.591 405.863 439.401C405.863 442.494 408.354 444.55 410.953 444.55Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.953 430.333C412.196 430.333 413.462 429.867 414.501 428.821C417.698 425.619 415.464 420.078 410.953 420.078C408.135 420.117 405.901 422.378 405.863 425.191C405.863 428.278 408.354 430.333 410.953 430.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.718 623.008C393.895 623.008 391.648 625.131 391.648 627.728C391.648 630.577 394.148 632.474 396.747 632.474C397.989 632.474 399.256 632.045 400.279 631.078C403.492 628.087 401.245 623.008 396.718 623.008H396.718Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.718 608C393.895 608.038 391.648 610.306 391.648 613.154C391.648 616.219 394.148 618.265 396.742 618.265C397.985 618.265 399.251 617.799 400.279 616.747C403.492 613.548 401.245 608 396.718 608H396.718Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.718 593.789C393.895 593.789 391.648 596.057 391.648 598.917C391.648 602.008 394.133 604.045 396.738 604.045C397.994 604.045 399.275 603.572 400.317 602.518C403.493 599.307 401.245 593.789 396.718 593.789V593.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M400.279 587.66C403.493 584.667 401.246 579.574 396.718 579.574C393.895 579.574 391.648 581.705 391.648 584.303C391.648 587.162 394.148 589.045 396.752 589.045C397.989 589.045 399.256 588.619 400.279 587.66L400.279 587.66Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.718 564.574C393.895 564.574 391.648 566.878 391.648 569.697C391.648 572.795 394.148 574.834 396.761 574.834C398.008 574.834 399.279 574.368 400.317 573.333C403.493 570.087 401.245 564.574 396.718 564.574Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.743 550.359C393.931 550.359 391.654 552.663 391.692 555.509C391.692 558.59 394.168 560.624 396.762 560.624C398.015 560.624 399.291 560.154 400.33 559.098C403.493 555.859 401.216 550.359 396.744 550.359H396.743Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.738 545.615C397.994 545.615 399.275 545.179 400.317 544.206C403.493 541.242 401.208 536.148 396.718 536.148C393.895 536.148 391.648 538.277 391.648 540.881C391.648 543.735 394.133 545.615 396.738 545.615Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.727 521.141C393.924 521.141 391.654 523.444 391.692 526.29C391.692 529.371 394.179 531.406 396.765 531.406C398.009 531.406 399.276 530.93 400.302 529.879C403.494 526.64 401.224 521.141 396.728 521.141H396.727Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.718 506.934C393.895 506.934 391.648 509.233 391.648 512.047C391.648 515.134 394.148 517.189 396.747 517.189C397.989 517.189 399.256 516.723 400.279 515.676C403.492 512.436 401.245 506.934 396.718 506.934H396.718Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.718 491.93C393.895 491.968 391.648 494.235 391.648 497.084C391.648 500.148 394.148 502.195 396.756 502.195C398.004 502.195 399.279 501.724 400.317 500.677C403.493 497.473 401.245 491.93 396.718 491.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.738 487.974C397.994 487.974 399.275 487.502 400.317 486.448C403.493 483.237 401.208 477.719 396.718 477.719C393.895 477.719 391.648 480.025 391.648 482.847C391.648 485.937 394.133 487.974 396.738 487.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.718 463.5C393.895 463.5 391.648 465.809 391.648 468.623C391.648 471.721 394.148 473.76 396.761 473.76C398.008 473.76 399.279 473.298 400.317 472.259C403.493 469.013 401.245 463.5 396.718 463.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M396.752 458.764C397.99 458.764 399.256 458.297 400.279 457.263C403.493 454.017 401.246 448.504 396.718 448.504C393.895 448.504 391.648 450.808 391.648 453.627C391.648 456.72 394.148 458.764 396.752 458.764H396.752Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.73 434.285C393.952 434.324 391.672 436.591 391.672 439.401C391.648 442.494 394.151 444.55 396.754 444.55C397.993 444.55 399.261 444.079 400.282 443.032C403.488 439.828 401.246 434.285 396.73 434.285H396.73Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.73 420.078C393.952 420.117 391.672 422.378 391.672 425.191C391.648 428.278 394.132 430.333 396.735 430.333C397.979 430.333 399.247 429.867 400.282 428.821C403.488 425.619 401.246 420.078 396.73 420.078Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.728 415.335C397.975 415.335 399.246 414.858 400.279 413.803C403.493 410.591 401.246 405.07 396.718 405.07C393.895 405.07 391.648 407.344 391.648 410.2C391.648 413.293 394.133 415.335 396.728 415.335V415.335Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 637.215C378.929 637.215 376.645 639.524 376.645 642.338C376.645 645.436 379.147 647.475 381.755 647.475C383.002 647.475 384.272 647.013 385.31 645.974C388.484 642.732 386.243 637.215 381.755 637.215Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 623.008C378.929 623.008 376.645 625.131 376.645 627.728C376.645 630.577 379.147 632.474 381.755 632.474C383.002 632.474 384.272 632.045 385.31 631.078C388.484 628.122 386.243 623.008 381.755 623.008Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 608C378.929 608.038 376.645 610.306 376.645 613.154C376.645 616.219 379.143 618.265 381.751 618.265C382.997 618.265 384.272 617.799 385.31 616.747C388.484 613.548 386.243 608.038 381.755 608Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 593.793C378.929 593.793 376.645 596.063 376.645 598.926C376.645 602 379.143 604.048 381.751 604.048C382.997 604.048 384.272 603.581 385.31 602.53C388.484 599.315 386.243 593.793 381.755 593.793Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 579.575C378.929 579.575 376.645 581.67 376.645 584.303C376.645 587.163 379.147 589.045 381.755 589.045C383.002 589.045 384.272 588.619 385.31 587.66C388.484 584.667 386.243 579.574 381.755 579.574V579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 564.574C378.929 564.574 376.645 566.878 376.645 569.697C376.645 572.795 379.147 574.834 381.755 574.834C383.002 574.834 384.272 574.368 385.31 573.333C388.484 570.087 386.243 564.574 381.755 564.574Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 550.359C378.929 550.359 376.645 552.663 376.645 555.509C376.673 558.59 379.167 560.624 381.76 560.624C383.007 560.624 384.282 560.154 385.31 559.098C388.484 555.859 386.243 550.398 381.755 550.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 536.148C378.929 536.148 376.645 538.242 376.645 540.881C376.645 543.735 379.133 545.615 381.736 545.615C382.988 545.615 384.268 545.179 385.31 544.206C388.484 541.242 386.242 536.148 381.755 536.148H381.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.753 531.406C383.005 531.406 384.281 530.93 385.325 529.879C388.484 526.64 386.212 521.141 381.739 521.141C378.921 521.141 376.645 523.444 376.645 526.29C376.673 529.371 379.158 531.406 381.753 531.406H381.753Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 506.934C378.929 506.934 376.645 509.233 376.645 512.047C376.645 515.134 379.147 517.189 381.755 517.189C383.002 517.189 384.272 516.723 385.31 515.676C388.484 512.474 386.243 506.934 381.755 506.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M381.755 491.93C378.929 491.968 376.645 494.235 376.645 497.084C376.645 500.148 379.143 502.195 381.751 502.195C382.997 502.195 384.272 501.724 385.31 500.677C388.484 497.473 386.243 491.968 381.755 491.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 477.719C378.929 477.719 376.645 479.986 376.645 482.847C376.645 485.937 379.133 487.974 381.736 487.974C382.988 487.974 384.268 487.502 385.31 486.448C388.484 483.237 386.242 477.719 381.755 477.719H381.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 463.5C378.929 463.5 376.645 465.809 376.645 468.623C376.645 471.721 379.147 473.76 381.755 473.76C383.002 473.76 384.272 473.298 385.31 472.259C388.484 469.013 386.243 463.5 381.755 463.5Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.739 458.764C382.981 458.764 384.248 458.297 385.282 457.263C388.484 454.055 386.212 448.504 381.739 448.504C378.921 448.504 376.687 450.808 376.645 453.627C376.645 456.72 379.14 458.764 381.739 458.764Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.739 444.55C382.981 444.55 384.248 444.08 385.282 443.032C388.484 439.828 386.212 434.324 381.739 434.285C378.921 434.285 376.687 436.591 376.645 439.401C376.645 442.494 379.14 444.55 381.739 444.55Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.763 430.338C382.996 430.338 384.258 429.878 385.282 428.851C388.484 425.614 386.212 420.116 381.739 420.078C378.921 420.116 376.687 422.376 376.645 425.187C376.645 428.28 379.154 430.338 381.763 430.338Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 405.07C378.929 405.07 376.645 407.343 376.645 410.198C376.645 413.279 379.157 415.331 381.774 415.331C383.016 415.331 384.278 414.873 385.31 413.838C388.484 410.588 386.243 405.07 381.755 405.07Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 680.648C364.711 680.687 362.471 682.948 362.434 685.762C362.434 688.849 364.943 690.904 367.542 690.904C368.785 690.904 370.047 690.438 371.071 689.391C374.273 686.15 371.996 680.648 367.523 680.648H367.523Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 637.215C364.711 637.215 362.471 639.524 362.434 642.338C362.434 645.436 364.943 647.475 367.547 647.475C368.785 647.475 370.051 647.013 371.071 645.974C374.273 642.732 371.996 637.215 367.523 637.215H367.523Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 623.008C364.711 623.008 362.471 625.131 362.434 627.728C362.434 630.577 364.943 632.474 367.542 632.474C368.785 632.474 370.047 632.045 371.071 631.078C374.273 628.087 371.996 623.008 367.523 623.008H367.523Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 608C364.711 608.038 362.471 610.306 362.434 613.154C362.434 616.219 364.938 618.265 367.537 618.265C368.78 618.265 370.047 617.799 371.071 616.747C374.273 613.548 371.996 608 367.523 608H367.523Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 593.789C364.711 593.789 362.471 596.057 362.434 598.917C362.434 602.008 364.929 604.045 367.523 604.045C368.771 604.045 370.042 603.572 371.071 602.518C374.273 599.307 371.996 593.789 367.523 593.789V593.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M367.523 579.575C364.711 579.575 362.471 581.706 362.434 584.303C362.434 587.163 364.943 589.045 367.547 589.045C368.785 589.045 370.051 588.619 371.071 587.66C374.273 584.667 371.996 579.574 367.523 579.574L367.523 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 564.574C364.711 564.574 362.471 566.878 362.434 569.697C362.434 572.795 364.943 574.834 367.547 574.834C368.785 574.834 370.051 574.368 371.071 573.333C374.273 570.087 371.996 564.574 367.523 564.574H367.523Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 550.359C364.711 550.359 362.434 552.663 362.434 555.509C362.457 558.59 364.943 560.624 367.528 560.624C368.776 560.624 370.042 560.154 371.071 559.098C374.273 555.859 371.996 550.359 367.523 550.359Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 536.148C364.711 536.148 362.434 538.277 362.434 540.881C362.457 543.735 364.943 545.615 367.528 545.615C368.776 545.615 370.042 545.179 371.071 544.206C374.273 541.242 371.996 536.148 367.523 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 521.141C364.711 521.141 362.434 523.444 362.434 526.29C362.457 529.371 364.943 531.406 367.542 531.406C368.794 531.406 370.07 530.93 371.109 529.879C374.273 526.64 371.996 521.141 367.523 521.141Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.542 517.189C368.785 517.189 370.047 516.723 371.071 515.676C374.273 512.436 371.996 506.934 367.523 506.934C364.711 506.934 362.471 509.233 362.434 512.047C362.434 515.134 364.943 517.189 367.542 517.189H367.542Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.538 502.195C368.78 502.195 370.047 501.724 371.071 500.677C374.273 497.473 371.996 491.93 367.523 491.93C364.711 491.968 362.471 494.235 362.434 497.084C362.434 500.148 364.938 502.195 367.537 502.195H367.538Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.528 487.974C368.776 487.974 370.042 487.502 371.071 486.448C374.273 483.237 371.996 477.719 367.523 477.719C364.711 477.719 362.434 480.025 362.434 482.847C362.457 485.937 364.943 487.974 367.528 487.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.547 473.76C368.785 473.76 370.052 473.298 371.071 472.259C374.273 469.013 371.996 463.5 367.523 463.5C364.711 463.5 362.434 465.809 362.434 468.623C362.434 471.721 364.943 473.76 367.547 473.76H367.547Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.528 458.764C368.771 458.764 370.037 458.297 371.071 457.263C374.273 454.017 371.996 448.504 367.523 448.504C364.711 448.504 362.471 450.808 362.434 453.627C362.434 456.72 364.924 458.764 367.528 458.764Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.528 444.55C368.771 444.55 370.037 444.08 371.071 443.032C374.273 439.828 371.996 434.285 367.523 434.285C364.711 434.285 362.471 436.591 362.434 439.401C362.434 442.494 364.924 444.55 367.528 444.55Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.528 430.333C368.771 430.333 370.037 429.867 371.071 428.821C374.273 425.619 371.996 420.078 367.523 420.078C364.711 420.117 362.471 422.378 362.434 425.191C362.434 428.278 364.924 430.333 367.528 430.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 415.335C368.771 415.335 370.042 414.858 371.071 413.803C374.273 410.591 371.996 405.07 367.523 405.07C364.711 405.07 362.471 407.344 362.434 410.2C362.434 413.293 364.929 415.335 367.523 415.335V415.335Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 680.648C350.465 680.687 348.219 682.948 348.219 685.762C348.219 688.849 350.722 690.904 353.32 690.904C354.557 690.904 355.823 690.438 356.846 689.391C360.058 686.15 357.774 680.648 353.287 680.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 666.43C350.465 666.43 348.219 668.528 348.219 671.165C348.219 674.02 350.708 675.905 353.296 675.905C354.543 675.905 355.818 675.469 356.846 674.491C360.058 671.53 357.774 666.43 353.287 666.43V666.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 651.434C350.465 651.434 348.219 653.701 348.219 656.562C348.219 659.653 350.708 661.689 353.296 661.689C354.543 661.689 355.818 661.217 356.846 660.163C360.058 656.952 357.774 651.434 353.287 651.434V651.434Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 637.215C350.465 637.215 348.219 639.524 348.219 642.338C348.219 645.436 350.722 647.475 353.32 647.475C354.562 647.475 355.823 647.013 356.846 645.974C360.058 642.732 357.774 637.215 353.287 637.215Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 623.008C350.465 623.008 348.219 625.131 348.219 627.728C348.219 630.577 350.722 632.474 353.32 632.474C354.557 632.474 355.823 632.045 356.846 631.078C360.058 628.087 357.774 623.008 353.287 623.008Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 608C350.465 608.038 348.219 610.306 348.219 613.154C348.219 616.219 350.717 618.265 353.315 618.265C354.557 618.265 355.823 617.799 356.846 616.747C360.058 613.548 357.774 608 353.287 608Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 593.789C350.465 593.789 348.219 596.057 348.219 598.917C348.219 602.008 350.708 604.045 353.296 604.045C354.543 604.045 355.818 603.572 356.846 602.518C360.058 599.307 357.774 593.789 353.287 593.789V593.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 506.934C350.465 506.934 348.219 509.233 348.219 512.047C348.219 515.134 350.722 517.189 353.32 517.189C354.557 517.189 355.823 516.723 356.846 515.676C360.058 512.436 357.774 506.934 353.287 506.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 491.93C350.465 491.968 348.219 494.235 348.219 497.084C348.219 500.148 350.717 502.195 353.315 502.195C354.557 502.195 355.823 501.724 356.846 500.677C360.058 497.473 357.774 491.93 353.287 491.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 477.719C350.465 477.719 348.219 480.025 348.219 482.847C348.219 485.937 350.708 487.974 353.296 487.974C354.543 487.974 355.818 487.502 356.846 486.448C360.058 483.237 357.774 477.719 353.287 477.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.287 463.5C350.465 463.5 348.219 465.809 348.219 468.623C348.219 471.721 350.722 473.76 353.32 473.76C354.562 473.76 355.823 473.298 356.846 472.259C360.058 469.013 357.774 463.5 353.287 463.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.306 448.504C350.491 448.504 348.25 450.808 348.25 453.627C348.226 456.72 350.728 458.764 353.329 458.764C354.573 458.764 355.836 458.297 356.857 457.263C360.061 454.017 357.782 448.504 353.306 448.504Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.306 434.285C350.491 434.324 348.25 436.591 348.25 439.401C348.226 442.494 350.709 444.55 353.311 444.55C354.555 444.55 355.822 444.079 356.857 443.032C360.061 439.828 357.825 434.285 353.306 434.285Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.306 420.078C350.529 420.117 348.25 422.378 348.25 425.191C348.226 428.278 350.709 430.333 353.311 430.333C354.555 430.333 355.822 429.867 356.857 428.821C360.061 425.619 357.825 420.078 353.306 420.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.296 415.335C354.543 415.335 355.818 414.858 356.846 413.803C360.058 410.591 357.774 405.07 353.287 405.07C350.465 405.07 348.219 407.344 348.219 410.2C348.219 413.293 350.708 415.335 353.296 415.335V415.335Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 666.43C335.507 666.43 333.223 668.528 333.223 671.165C333.223 674.02 335.707 675.905 338.31 675.905C339.561 675.905 340.846 675.469 341.888 674.491C345.062 671.53 342.816 666.43 338.29 666.43V666.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M338.29 651.434C335.507 651.434 333.223 653.701 333.223 656.562C333.223 659.653 335.707 661.689 338.31 661.689C339.561 661.689 340.846 661.217 341.888 660.163C345.062 656.952 342.816 651.434 338.29 651.434V651.434Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 637.215C335.507 637.215 333.223 639.524 333.223 642.338C333.223 645.436 335.721 647.475 338.333 647.475C339.58 647.475 340.851 647.013 341.888 645.974C345.062 642.732 342.816 637.215 338.291 637.215H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 491.93C335.507 491.968 333.223 494.235 333.223 497.084C333.223 500.148 335.716 502.195 338.329 502.195C339.575 502.195 340.851 501.724 341.888 500.677C345.062 497.473 342.816 491.93 338.29 491.93Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 477.719C335.464 477.719 333.223 480.025 333.223 482.847C333.223 485.937 335.707 487.974 338.31 487.974C339.561 487.974 340.846 487.502 341.888 486.448C345.062 483.237 342.816 477.719 338.29 477.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 463.5C335.464 463.5 333.223 465.809 333.223 468.623C333.223 471.721 335.721 473.76 338.333 473.76C339.58 473.76 340.851 473.298 341.888 472.259C345.062 469.013 342.816 463.5 338.291 463.5H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 448.504C335.507 448.504 333.223 450.808 333.223 453.627C333.223 456.72 335.721 458.764 338.333 458.764C339.58 458.764 340.851 458.297 341.888 457.263C345.062 454.017 342.816 448.504 338.291 448.504H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.323 444.55C339.567 444.55 340.83 444.08 341.856 443.032C345.062 439.828 342.82 434.285 338.304 434.285C335.526 434.324 333.246 436.591 333.246 439.401C333.218 442.494 335.725 444.55 338.323 444.55Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.323 430.333C339.567 430.333 340.83 429.867 341.856 428.821C345.062 425.619 342.82 420.078 338.304 420.078C335.526 420.117 333.246 422.378 333.246 425.191C333.218 428.278 335.725 430.333 338.323 430.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 405.071C335.507 405.071 333.223 407.344 333.223 410.201C333.223 413.293 335.707 415.335 338.31 415.335C339.561 415.335 340.846 414.858 341.888 413.803C345.062 410.591 342.816 405.07 338.29 405.07V405.071Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.072 477.719C321.288 477.719 319.004 480.025 319.004 482.847C319.004 485.937 321.507 487.974 324.11 487.974C325.366 487.974 326.641 487.502 327.669 486.448C330.843 483.237 328.602 477.719 324.072 477.719H324.072Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.072 463.5C321.288 463.5 319.004 465.809 319.004 468.623C319.004 471.721 321.507 473.76 324.115 473.76C325.361 473.76 326.632 473.298 327.669 472.259C330.843 469.013 328.602 463.5 324.072 463.5H324.072Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.072 448.504C321.288 448.504 319.004 450.808 319.004 453.627C319.004 456.72 321.507 458.764 324.115 458.764C325.361 458.764 326.632 458.297 327.669 457.263C330.843 454.017 328.602 448.504 324.072 448.504H324.072Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.072 434.285C321.288 434.324 319.004 436.591 319.004 439.401C319.004 442.494 321.507 444.55 324.115 444.55C325.361 444.55 326.632 444.079 327.669 443.032C330.843 439.828 328.602 434.285 324.072 434.285H324.072Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.098 430.333C325.341 430.333 326.608 429.867 327.642 428.821C330.843 425.619 328.571 420.078 324.056 420.078C321.281 420.117 319.047 422.378 319.004 425.191C319.004 428.278 321.499 430.333 324.098 430.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.072 405.071C321.288 405.071 319.004 407.344 319.004 410.201C319.004 413.293 321.493 415.335 324.096 415.335C325.347 415.335 326.627 414.858 327.669 413.803C330.843 410.591 328.602 405.07 324.072 405.07L324.072 405.071Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.849 405.071C307.074 405.071 304.835 407.344 304.797 410.201C304.797 413.293 307.273 415.335 309.867 415.335C311.115 415.335 312.396 414.858 313.435 413.803C316.636 410.591 314.36 405.07 309.849 405.07V405.071Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M294.853 405.071C292.031 405.071 289.785 407.344 289.785 410.201C289.785 413.293 292.274 415.335 294.862 415.335C296.109 415.335 297.384 414.858 298.413 413.803C301.625 410.591 299.34 405.07 294.853 405.07V405.071Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M555.415 14.2188C552.635 14.2188 550.363 16.5229 550.363 19.3371C550.363 22.4347 552.854 24.4742 555.458 24.4742C556.7 24.4742 557.967 24.0122 559.001 22.978C562.198 19.7314 559.926 14.2188 555.415 14.2188Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M541.218 14.2188C538.434 14.2188 536.148 16.5229 536.148 19.3371C536.148 22.4347 538.652 24.4742 541.261 24.4742C542.508 24.4742 543.779 24.0122 544.817 22.978C547.993 19.7314 545.746 14.2188 541.218 14.2188Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M555.415 116.078C552.635 116.078 550.363 118.205 550.363 120.798C550.363 123.652 552.854 125.549 555.453 125.549C556.696 125.549 557.967 125.115 559.001 124.149C562.198 121.157 559.926 116.078 555.415 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M541.218 130.289C538.434 130.289 536.148 132.594 536.148 135.407C536.148 138.505 538.652 140.545 541.261 140.545C542.508 140.545 543.779 140.083 544.817 139.048C547.993 135.802 545.746 130.289 541.218 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M541.218 116.078C538.434 116.078 536.148 118.205 536.148 120.798C536.148 123.652 538.648 125.549 541.261 125.549C542.508 125.549 543.779 125.115 544.817 124.149C547.993 121.157 545.746 116.078 541.218 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M527.005 130.289C524.217 130.289 521.938 132.594 521.938 135.407C521.938 138.505 524.436 140.545 527.048 140.545C528.295 140.545 529.565 140.083 530.603 139.048C533.772 135.802 531.531 130.289 527.006 130.289L527.005 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M527.005 116.078C524.217 116.113 521.938 118.205 521.938 120.798C521.938 123.652 524.436 125.549 527.048 125.549C528.29 125.549 529.565 125.115 530.603 124.149C533.772 121.157 531.531 116.078 527.006 116.078H527.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M527.005 57.6484C524.217 57.6484 521.938 59.9529 521.938 62.7617C521.938 65.8534 524.436 67.9084 527.048 67.9084C528.29 67.9084 529.565 67.4377 530.603 66.3911C533.772 63.1504 531.531 57.6484 527.006 57.6484H527.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M527.005 43.4297C524.217 43.4729 521.938 45.7342 521.938 48.5859C521.938 51.6442 524.431 53.6896 527.039 53.6896C528.29 53.6896 529.561 53.2237 530.603 52.1724C533.772 48.9752 531.531 43.4297 527.006 43.4297H527.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M527.005 28.4258C524.217 28.4258 521.938 30.7359 521.938 33.5511C521.938 36.6498 524.436 38.6905 527.048 38.6905C528.295 38.6905 529.565 38.2238 530.603 37.189C533.772 33.9407 531.531 28.4258 527.006 28.4258H527.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M527.005 14.2188C524.217 14.2188 521.938 16.5229 521.938 19.3371C521.938 22.4347 524.436 24.4742 527.048 24.4742C528.295 24.4742 529.565 24.0122 530.603 22.978C533.772 19.7314 531.531 14.2188 527.006 14.2188H527.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M512.004 86.8594C509.219 86.8594 506.934 89.1695 506.934 91.9847C506.934 95.0837 509.433 97.1241 512.046 97.1241C513.294 97.1241 514.565 96.6573 515.603 95.6226C518.778 92.3743 516.531 86.8594 512.004 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M512.004 72.6484C509.219 72.6484 506.934 74.7752 506.934 77.3727C506.934 80.2323 509.433 82.115 512.046 82.115C513.294 82.115 514.565 81.6889 515.603 80.7341C518.778 77.7367 516.531 72.6484 512.004 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M512.004 57.6484C509.219 57.6484 506.934 59.9529 506.934 62.7617C506.934 65.8534 509.433 67.9084 512.046 67.9084C513.294 67.9084 514.565 67.4377 515.603 66.3911C518.778 63.1504 516.531 57.6484 512.004 57.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M512.004 43.4297C509.219 43.4729 506.934 45.7342 506.934 48.5859C506.934 51.6442 509.433 53.6896 512.042 53.6896C513.289 53.6896 514.565 53.2237 515.603 52.1724C518.778 48.9752 516.531 43.4297 512.004 43.4297Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M512.004 28.4258C509.219 28.4258 506.934 30.7359 506.934 33.5511C506.934 36.6498 509.433 38.6905 512.046 38.6905C513.294 38.6905 514.565 38.2238 515.603 37.189C518.778 33.9407 516.531 28.4258 512.004 28.4258Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M512.004 14.2188C509.219 14.2188 506.934 16.5229 506.934 19.3371C506.934 22.4347 509.433 24.4742 512.046 24.4742C513.294 24.4742 514.565 24.0122 515.603 22.978C518.778 19.7314 516.531 14.2188 512.004 14.2188Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M512.004 0C509.219 0 506.934 2.31008 506.934 5.12534C506.934 8.22434 509.433 10.2647 512.046 10.2647C513.294 10.2647 514.565 9.79797 515.603 8.7632C518.778 5.51489 516.531 0 512.004 0V0Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M497.844 101.078H497.797C495.005 101.078 492.723 103.348 492.723 106.211C492.723 109.28 495.219 111.333 497.83 111.333C499.083 111.333 500.355 110.866 501.399 109.81C504.562 106.61 502.342 101.078 497.844 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M497.833 97.1241C499.075 97.1241 500.35 96.6573 501.388 95.6226C504.557 92.3743 502.316 86.8594 497.79 86.8594C495.002 86.8594 492.723 89.1695 492.723 91.9847C492.723 95.0837 495.221 97.1241 497.833 97.1241H497.833Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M497.833 82.115C499.075 82.115 500.35 81.6889 501.388 80.7341C504.557 77.7367 502.316 72.6484 497.79 72.6484C495.002 72.6484 492.723 74.7752 492.723 77.3727C492.723 80.2323 495.221 82.115 497.833 82.115H497.833Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M497.829 67.9084C499.075 67.9084 500.351 67.4377 501.388 66.3911C504.557 63.1504 502.316 57.6484 497.79 57.6484C495.002 57.6484 492.723 59.9529 492.723 62.7617C492.723 65.8534 495.221 67.9084 497.829 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M497.824 53.6896C499.075 53.6896 500.346 53.2237 501.388 52.1724C504.557 48.9752 502.316 43.4297 497.79 43.4297C495.002 43.4729 492.723 45.7342 492.723 48.5859C492.723 51.6442 495.216 53.6896 497.824 53.6896H497.824Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M497.833 38.6944C499.075 38.6944 500.35 38.2277 501.388 37.1929C504.557 33.9446 502.316 28.4297 497.79 28.4297C495.002 28.4297 492.723 30.7398 492.723 33.555C492.723 36.6537 495.221 38.6944 497.833 38.6944H497.833Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M497.833 24.4742C499.075 24.4742 500.35 24.0122 501.388 22.978C504.557 19.7314 502.316 14.2188 497.79 14.2188C495.002 14.2188 492.723 16.5229 492.723 19.3371C492.723 22.4347 495.221 24.4742 497.833 24.4742H497.833Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M497.833 10.2647C499.075 10.2647 500.35 9.79797 501.388 8.7632C504.557 5.51489 502.316 0 497.79 0C495.002 0 492.723 2.31008 492.723 5.12534C492.723 8.22434 495.221 10.2647 497.833 10.2647H497.833Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M483.574 101.078C480.789 101.078 478.504 103.348 478.504 106.211C478.504 109.28 481.003 111.333 483.612 111.333C484.859 111.333 486.135 110.866 487.173 109.81C490.348 106.601 488.101 101.078 483.574 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M483.574 86.8594C480.789 86.8594 478.504 89.1695 478.504 91.9847C478.504 95.0837 481.003 97.1241 483.617 97.1241C484.864 97.1241 486.135 96.6573 487.173 95.6226C490.348 92.3743 488.101 86.8594 483.574 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M483.574 72.6484C480.789 72.6484 478.504 74.7752 478.504 77.3727C478.504 80.2323 481.003 82.115 483.617 82.115C484.864 82.115 486.135 81.6889 487.173 80.7341C490.348 77.7367 488.101 72.6484 483.574 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M483.574 57.6484C480.789 57.6484 478.504 59.9529 478.504 62.7617C478.504 65.8534 481.003 67.9084 483.617 67.9084C484.864 67.9084 486.135 67.4377 487.173 66.3911C490.348 63.1504 488.101 57.6484 483.574 57.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M483.574 43.4297C480.789 43.4729 478.504 45.7342 478.504 48.5859C478.504 51.6442 481.003 53.6896 483.612 53.6896C484.859 53.6896 486.135 53.2237 487.173 52.1724C490.348 48.9752 488.101 43.4297 483.574 43.4297Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M483.574 28.4258C480.789 28.4258 478.504 30.7359 478.504 33.5511C478.504 36.6498 481.003 38.6905 483.617 38.6905C484.864 38.6905 486.135 38.2238 487.173 37.189C490.348 33.9407 488.101 28.4258 483.574 28.4258Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M483.574 14.2188C480.789 14.2188 478.504 16.5229 478.504 19.3371C478.504 22.4347 481.003 24.4742 483.617 24.4742C484.864 24.4742 486.135 24.0122 487.173 22.978C490.348 19.7314 488.101 14.2188 483.574 14.2188Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M483.574 0C480.789 0 478.504 2.31008 478.504 5.12534C478.504 8.22434 481.003 10.2647 483.617 10.2647C484.864 10.2647 486.135 9.79797 487.173 8.7632C490.348 5.51489 488.101 0 483.574 0V0Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.599 111.333C469.841 111.333 471.107 110.866 472.135 109.81C475.342 106.601 473.101 101.078 468.576 101.078C465.749 101.078 463.508 103.348 463.508 106.211C463.508 109.28 466.001 111.333 468.599 111.333H468.599Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.604 97.1241C469.846 97.1241 471.107 96.6573 472.135 95.6226C475.342 92.3743 473.101 86.8594 468.576 86.8594C465.749 86.8594 463.508 89.1695 463.508 91.9847C463.508 95.0837 466.006 97.1241 468.604 97.1241H468.604Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.604 82.115C469.846 82.115 471.107 81.6889 472.135 80.7341C475.342 77.7367 473.101 72.6484 468.576 72.6484C465.749 72.6484 463.508 74.7752 463.508 77.3727C463.508 80.2323 466.006 82.115 468.604 82.115H468.604Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.604 67.9084C469.846 67.9084 471.107 67.4377 472.135 66.3911C475.342 63.1504 473.101 57.6484 468.576 57.6484C465.749 57.6484 463.508 59.9529 463.508 62.7617C463.508 65.8534 466.006 67.9084 468.604 67.9084H468.604Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.599 53.6896C469.841 53.6896 471.107 53.2237 472.135 52.1724C475.342 48.9752 473.101 43.4297 468.576 43.4297C465.749 43.4729 463.508 45.7342 463.508 48.5859C463.508 51.6442 466.001 53.6896 468.599 53.6896H468.599Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M468.604 38.6944C469.846 38.6944 471.107 38.2277 472.135 37.1929C475.342 33.9446 473.101 28.4297 468.576 28.4297C465.749 28.4297 463.508 30.7398 463.508 33.555C463.508 36.6537 466.006 38.6944 468.604 38.6944H468.604Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.604 24.4742C469.846 24.4742 471.107 24.0122 472.135 22.978C475.342 19.7314 473.101 14.2188 468.576 14.2188C465.749 14.2188 463.508 16.5229 463.508 19.3371C463.508 22.4347 466.006 24.4742 468.604 24.4742H468.604Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M468.604 10.2647C469.846 10.2647 471.107 9.79797 472.135 8.7632C475.342 5.51489 473.101 0 468.576 0C465.749 0 463.508 2.31008 463.508 5.12534C463.508 8.22434 466.006 10.2647 468.604 10.2647H468.604Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 116.078C451.574 116.113 449.289 118.205 449.289 120.798C449.289 123.652 451.788 125.549 454.402 125.549C455.649 125.549 456.92 125.115 457.958 124.149C461.133 121.157 458.886 116.078 454.359 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 101.078C451.574 101.078 449.289 103.348 449.289 106.211C449.289 109.28 451.788 111.333 454.397 111.333C455.644 111.333 456.92 110.866 457.958 109.81C461.133 106.601 458.886 101.078 454.359 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 86.8594C451.574 86.8594 449.289 89.1695 449.289 91.9847C449.289 95.0837 451.788 97.1241 454.402 97.1241C455.649 97.1241 456.92 96.6573 457.958 95.6226C461.133 92.3743 458.886 86.8594 454.359 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 72.6484C451.574 72.6484 449.289 74.7752 449.289 77.3727C449.289 80.2323 451.788 82.115 454.402 82.115C455.649 82.115 456.92 81.6889 457.958 80.7341C461.133 77.7367 458.886 72.6484 454.359 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 57.6484C451.574 57.6484 449.289 59.9529 449.289 62.7617C449.289 65.8534 451.788 67.9084 454.402 67.9084C455.649 67.9084 456.92 67.4377 457.958 66.3911C461.133 63.1504 458.886 57.6484 454.359 57.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 43.4297C451.574 43.4729 449.289 45.7342 449.289 48.5859C449.289 51.6442 451.788 53.6896 454.397 53.6896C455.644 53.6896 456.92 53.2237 457.958 52.1724C461.133 48.9752 458.886 43.4297 454.359 43.4297Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 28.4258C451.574 28.4258 449.289 30.7359 449.289 33.5511C449.289 36.6498 451.788 38.6905 454.402 38.6905C455.649 38.6905 456.92 38.2238 457.958 37.189C461.133 33.9407 458.886 28.4258 454.359 28.4258Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 14.2188C451.574 14.2188 449.289 16.5229 449.289 19.3371C449.289 22.4347 451.788 24.4742 454.402 24.4742C455.649 24.4742 456.92 24.0122 457.958 22.978C461.133 19.7314 458.886 14.2188 454.359 14.2188Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M454.359 0C451.574 0 449.289 2.31008 449.289 5.12534C449.289 8.22434 451.788 10.2647 454.402 10.2647C455.649 10.2647 456.92 9.79797 457.958 8.7632C461.133 5.51489 458.886 0 454.359 0V0Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 130.289C437.357 130.289 435.078 132.594 435.078 135.407C435.078 138.505 437.576 140.545 440.189 140.545C441.431 140.545 442.706 140.083 443.743 139.048C446.913 135.802 444.671 130.289 440.146 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 116.078C437.357 116.078 435.078 118.205 435.078 120.798C435.078 123.652 437.576 125.549 440.184 125.549C441.431 125.549 442.706 125.115 443.743 124.149C446.913 121.157 444.671 116.078 440.146 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 101.078C437.357 101.078 435.078 103.348 435.078 106.211C435.078 109.28 437.571 111.333 440.179 111.333C441.431 111.333 442.701 110.866 443.743 109.81C446.913 106.601 444.671 101.078 440.146 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M440.146 86.8594C437.357 86.8594 435.078 89.1695 435.078 91.9847C435.078 95.0837 437.576 97.1241 440.189 97.1241C441.431 97.1241 442.706 96.6573 443.743 95.6226C446.913 92.3743 444.671 86.8594 440.146 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 72.6484C437.357 72.6484 435.078 74.7752 435.078 77.3727C435.078 80.2323 437.576 82.115 440.189 82.115C441.431 82.115 442.706 81.6889 443.743 80.7341C446.913 77.7367 444.671 72.6484 440.146 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 57.6484C437.357 57.6484 435.078 59.9529 435.078 62.7617C435.078 65.8534 437.576 67.9084 440.184 67.9084C441.431 67.9084 442.706 67.4377 443.743 66.3911C446.913 63.1504 444.671 57.6484 440.146 57.6484Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 43.4297C437.357 43.4729 435.078 45.7342 435.078 48.5859C435.078 51.6442 437.571 53.6896 440.179 53.6896C441.431 53.6896 442.701 53.2237 443.743 52.1724C446.913 48.9752 444.671 43.4297 440.146 43.4297Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 28.4258C437.357 28.4258 435.078 30.7314 435.078 33.5416C435.078 36.6347 437.576 38.6908 440.184 38.6908C441.431 38.6908 442.706 38.2201 443.743 37.173C446.913 33.9303 444.671 28.4258 440.146 28.4258Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 14.2188C437.357 14.2188 435.078 16.5229 435.078 19.3371C435.078 22.4347 437.576 24.4742 440.189 24.4742C441.431 24.4742 442.706 24.0122 443.743 22.978C446.913 19.7314 444.671 14.2188 440.146 14.2188Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M440.146 0C437.357 0 435.078 2.31008 435.078 5.12534C435.078 8.22434 437.576 10.2647 440.189 10.2647C441.431 10.2647 442.706 9.79797 443.743 8.7632C446.913 5.51489 444.671 0 440.146 0V0Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M425.171 140.545C426.414 140.545 427.681 140.083 428.715 139.048C431.918 135.802 429.641 130.289 425.128 130.289C422.352 130.289 420.074 132.593 420.074 135.407C420.074 138.505 422.566 140.544 425.171 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.171 125.549C426.414 125.549 427.681 125.115 428.715 124.149C431.918 121.157 429.641 116.078 425.128 116.078C422.352 116.113 420.112 118.205 420.074 120.798C420.074 123.652 422.566 125.549 425.171 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.166 111.333C426.409 111.333 427.681 110.866 428.715 109.81C431.918 106.601 429.641 101.078 425.128 101.078C422.352 101.078 420.112 103.348 420.074 106.211C420.074 109.28 422.566 111.333 425.166 111.333Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.171 97.1241C426.414 97.1241 427.681 96.6573 428.715 95.6226C431.918 92.3743 429.641 86.8594 425.128 86.8594C422.352 86.8594 420.074 89.1695 420.074 91.9847C420.074 95.0837 422.566 97.1241 425.171 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.171 82.115C426.414 82.115 427.681 81.6889 428.715 80.7341C431.918 77.7367 429.641 72.6484 425.128 72.6484C422.352 72.6484 420.112 74.7752 420.074 77.3727C420.074 80.2323 422.566 82.115 425.171 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.171 67.9084C426.414 67.9084 427.681 67.4377 428.715 66.3911C431.918 63.1504 429.641 57.6484 425.128 57.6484C422.352 57.6484 420.074 59.9529 420.074 62.7617C420.074 65.8534 422.566 67.9084 425.171 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M425.166 53.6896C426.409 53.6896 427.681 53.2237 428.715 52.1724C431.918 48.9752 429.641 43.4297 425.128 43.4297C422.352 43.4729 420.112 45.7342 420.074 48.5859C420.074 51.6442 422.566 53.6896 425.166 53.6896Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.171 38.6944C426.414 38.6944 427.681 38.2277 428.715 37.1929C431.918 33.9446 429.641 28.4297 425.128 28.4297C422.352 28.4297 420.112 30.7398 420.074 33.555C420.074 36.6537 422.566 38.6944 425.171 38.6944Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.171 24.4742C426.414 24.4742 427.681 24.0122 428.715 22.978C431.918 19.7314 429.641 14.2188 425.128 14.2188C422.352 14.2188 420.074 16.5229 420.074 19.3371C420.074 22.4347 422.566 24.4742 425.171 24.4742Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M425.171 10.2647C426.414 10.2647 427.681 9.79797 428.715 8.7632C431.918 5.51489 429.641 0 425.128 0C422.352 0 420.112 2.31008 420.074 5.12534C420.074 8.22434 422.566 10.2647 425.171 10.2647Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.959 125.549C412.201 125.549 413.463 125.115 414.49 124.149C417.698 121.157 415.418 116.078 410.931 116.078C408.105 116.078 405.863 118.205 405.863 120.798C405.863 123.652 408.361 125.549 410.96 125.549H410.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.955 111.333C412.197 111.333 413.462 110.866 414.49 109.81C417.698 106.601 415.418 101.078 410.931 101.078C408.105 101.078 405.863 103.348 405.863 106.211C405.863 109.28 408.357 111.333 410.955 111.333H410.955Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.959 97.1241C412.201 97.1241 413.463 96.6573 414.49 95.6226C417.698 92.3743 415.418 86.8594 410.931 86.8594C408.105 86.8594 405.863 89.1695 405.863 91.9847C405.863 95.0837 408.361 97.1241 410.96 97.1241H410.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M410.959 82.115C412.201 82.115 413.463 81.6889 414.49 80.7341C417.698 77.7367 415.418 72.6484 410.931 72.6484C408.105 72.6484 405.863 74.7752 405.863 77.3727C405.863 80.2323 408.361 82.115 410.96 82.115H410.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.959 67.9084C412.201 67.9084 413.463 67.4377 414.49 66.3911C417.698 63.1504 415.418 57.6484 410.931 57.6484C408.105 57.6484 405.863 59.9529 405.863 62.7617C405.863 65.8534 408.361 67.9084 410.96 67.9084H410.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.955 53.6896C412.197 53.6896 413.462 53.2237 414.49 52.1724C417.698 48.9752 415.418 43.4297 410.931 43.4297C408.105 43.4729 405.863 45.7342 405.863 48.5859C405.863 51.6442 408.357 53.6896 410.955 53.6896H410.955Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.959 38.6944C412.201 38.6944 413.463 38.2277 414.49 37.1929C417.698 33.9446 415.418 28.4297 410.931 28.4297C408.105 28.4297 405.863 30.7398 405.863 33.555C405.863 36.6537 408.361 38.6944 410.96 38.6944H410.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M410.959 24.4742C412.201 24.4742 413.463 24.0122 414.49 22.978C417.698 19.7314 415.418 14.2188 410.931 14.2188C408.105 14.2188 405.863 16.5229 405.863 19.3371C405.863 22.4347 408.361 24.4742 410.96 24.4742H410.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M410.959 10.2647C412.201 10.2647 413.463 9.79797 414.49 8.7632C417.698 5.51489 415.418 0 410.931 0C408.105 0 405.863 2.31008 405.863 5.12534C405.863 8.22434 408.361 10.2647 410.96 10.2647H410.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.747 67.9084C397.99 67.9084 399.256 67.4377 400.279 66.3911C403.493 63.1504 401.246 57.6484 396.718 57.6484C393.895 57.6484 391.648 59.9529 391.648 62.7617C391.648 65.8534 394.148 67.9084 396.747 67.9084H396.747Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.742 53.6896C397.985 53.6896 399.251 53.2237 400.279 52.1724C403.493 48.9752 401.246 43.4297 396.718 43.4297C393.895 43.4729 391.648 45.7342 391.648 48.5859C391.648 51.6442 394.148 53.6896 396.742 53.6896H396.742Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.752 38.6944C397.99 38.6944 399.256 38.2277 400.279 37.1929C403.493 33.9446 401.246 28.4297 396.718 28.4297C393.895 28.4297 391.648 30.7398 391.648 33.555C391.648 36.6537 394.148 38.6944 396.752 38.6944H396.752Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.752 24.4742C397.99 24.4742 399.256 24.0122 400.279 22.978C403.493 19.7314 401.246 14.2188 396.718 14.2188C393.895 14.2188 391.648 16.5229 391.648 19.3371C391.648 22.4347 394.148 24.4742 396.752 24.4742H396.752Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 43.4297C378.929 43.4729 376.645 45.7342 376.645 48.5859C376.645 51.6442 379.143 53.6896 381.751 53.6896C382.997 53.6896 384.272 53.2237 385.31 52.1724C388.484 48.9752 386.243 43.4729 381.755 43.4297Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 28.4258C378.929 28.4258 376.645 30.7359 376.645 33.5511C376.645 36.6498 379.147 38.6905 381.755 38.6905C383.002 38.6905 384.272 38.2238 385.31 37.189C388.484 33.9794 386.243 28.4258 381.755 28.4258Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 14.2188C378.929 14.2188 376.645 16.5229 376.645 19.3371C376.645 22.4347 379.147 24.4742 381.755 24.4742C383.002 24.4742 384.272 24.0122 385.31 22.978C388.484 19.7698 386.243 14.2188 381.755 14.2188Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.538 53.6896C368.78 53.6896 370.047 53.2237 371.071 52.1724C374.273 48.9752 371.996 43.4297 367.523 43.4297C364.711 43.4729 362.471 45.7342 362.434 48.5859C362.434 51.6442 364.938 53.6896 367.537 53.6896H367.538Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M367.547 38.6944C368.785 38.6944 370.052 38.2277 371.071 37.1929C374.273 33.9446 371.996 28.4297 367.523 28.4297C364.711 28.4297 362.471 30.7398 362.434 33.555C362.434 36.6537 364.943 38.6944 367.547 38.6944H367.547Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.538 111.333C368.78 111.333 370.047 110.866 371.071 109.81C374.273 106.601 371.996 101.078 367.523 101.078C364.711 101.078 362.471 103.348 362.434 106.211C362.434 109.28 364.938 111.333 367.537 111.333H367.538Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.547 97.1241C368.785 97.1241 370.052 96.6573 371.071 95.6226C374.273 92.3743 371.996 86.8594 367.523 86.8594C364.711 86.8594 362.471 89.1695 362.434 91.9847C362.434 95.0837 364.943 97.1241 367.547 97.1241H367.547Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.547 10.2647C368.785 10.2647 370.052 9.79797 371.071 8.7632C374.273 5.51489 371.996 0 367.523 0C364.711 0 362.471 2.31008 362.434 5.12534C362.434 8.22434 364.943 10.2647 367.547 10.2647H367.547Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.32 125.549C354.557 125.549 355.823 125.115 356.846 124.149C360.058 121.157 357.774 116.078 353.287 116.078C350.465 116.113 348.219 118.205 348.219 120.798C348.219 123.652 350.722 125.549 353.32 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.315 111.333C354.557 111.333 355.823 110.866 356.846 109.81C360.058 106.601 357.774 101.078 353.287 101.078C350.465 101.078 348.219 103.348 348.219 106.211C348.219 109.28 350.717 111.333 353.315 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.32 97.1241C354.562 97.1241 355.823 96.6573 356.846 95.6226C360.058 92.3743 357.774 86.8594 353.287 86.8594C350.465 86.8594 348.219 89.1695 348.219 91.9847C348.219 95.0837 350.722 97.1241 353.32 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.32 82.115C354.562 82.115 355.823 81.6889 356.846 80.7341C360.058 77.7367 357.774 72.6484 353.287 72.6484C350.465 72.6484 348.219 74.7752 348.219 77.3727C348.219 80.2323 350.722 82.115 353.32 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.32 24.4742C354.562 24.4742 355.823 24.0122 356.846 22.978C360.058 19.7314 357.774 14.2188 353.287 14.2188C350.465 14.2188 348.219 16.5229 348.219 19.3371C348.219 22.4347 350.722 24.4742 353.32 24.4742Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.32 10.2647C354.562 10.2647 355.823 9.79797 356.846 8.7632C360.058 5.51489 357.774 0 353.287 0C350.465 0 348.219 2.31008 348.219 5.12534C348.219 8.22434 350.722 10.2647 353.32 10.2647Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 116.078C335.507 116.113 333.223 118.205 333.223 120.798C333.223 123.652 335.721 125.549 338.333 125.549C339.58 125.549 340.851 125.115 341.888 124.149C345.062 121.157 342.816 116.078 338.291 116.078H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 101.078C335.507 101.078 333.223 103.348 333.223 106.211C333.223 109.28 335.716 111.333 338.329 111.333C339.575 111.333 340.851 110.866 341.888 109.81C345.062 106.601 342.816 101.078 338.29 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 86.8594C335.507 86.8594 333.223 89.1695 333.223 91.9847C333.223 95.0837 335.721 97.1241 338.333 97.1241C339.58 97.1241 340.851 96.6573 341.888 95.6226C345.062 92.3743 342.816 86.8594 338.291 86.8594H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 72.6484C335.507 72.6484 333.223 74.7752 333.223 77.3727C333.223 80.2323 335.721 82.115 338.333 82.115C339.58 82.115 340.851 81.6889 341.888 80.7341C345.062 77.7367 342.816 72.6484 338.291 72.6484H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 14.2188C335.507 14.2188 333.223 16.5229 333.223 19.3371C333.223 22.4347 335.721 24.4742 338.333 24.4742C339.58 24.4742 340.851 24.0122 341.888 22.978C345.062 19.7314 342.816 14.2188 338.291 14.2188H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 0C335.507 0 333.223 2.31008 333.223 5.12534C333.223 8.22434 335.721 10.2647 338.333 10.2647C339.58 10.2647 340.851 9.79797 341.888 8.7632C345.062 5.51489 342.816 0 338.291 0L338.29 0Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.114 125.549C325.361 125.549 326.632 125.115 327.669 124.149C330.843 121.157 328.602 116.078 324.072 116.078C321.288 116.078 319.004 118.205 319.004 120.798C319.004 123.652 321.507 125.549 324.115 125.549H324.114Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.114 82.115C325.361 82.115 326.632 81.6889 327.669 80.7341C330.843 77.7367 328.602 72.6484 324.072 72.6484C321.288 72.6484 319.004 74.7752 319.004 77.3727C319.004 80.2323 321.507 82.115 324.115 82.115H324.114Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.114 38.6944C325.361 38.6944 326.632 38.2277 327.669 37.1929C330.843 33.9446 328.602 28.4297 324.072 28.4297C321.288 28.4297 319.004 30.7398 319.004 33.555C319.004 36.6537 321.507 38.6944 324.115 38.6944H324.114Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.114 24.4742C325.361 24.4742 326.632 24.0122 327.669 22.978C330.843 19.7314 328.602 14.2188 324.072 14.2188C321.288 14.2188 319.004 16.5229 319.004 19.3371C319.004 22.4347 321.507 24.4742 324.115 24.4742H324.114Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.891 125.549C311.134 125.549 312.401 125.115 313.435 124.149C316.636 121.157 314.36 116.078 309.849 116.078C307.074 116.113 304.835 118.205 304.797 120.798C304.797 123.652 307.287 125.549 309.891 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.887 111.333C311.129 111.333 312.4 110.866 313.435 109.81C316.636 106.601 314.36 101.078 309.849 101.078C307.074 101.078 304.835 103.348 304.797 106.211C304.797 109.28 307.282 111.333 309.887 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.891 82.115C311.134 82.115 312.401 81.6889 313.435 80.7341C316.636 77.7367 314.36 72.6484 309.849 72.6484C307.074 72.6484 304.835 74.7752 304.797 77.3727C304.797 80.2323 307.287 82.115 309.891 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.891 38.6944C311.134 38.6944 312.401 38.2277 313.435 37.1929C316.636 33.9446 314.36 28.4297 309.849 28.4297C307.074 28.4297 304.835 30.7398 304.797 33.555C304.797 36.6537 307.287 38.6944 309.891 38.6944Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.891 24.4742C311.134 24.4742 312.401 24.0122 313.435 22.978C316.636 19.7314 314.36 14.2188 309.849 14.2188C307.074 14.2188 304.797 16.5229 304.797 19.3371C304.797 22.4347 307.287 24.4742 309.891 24.4742Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.886 125.549C296.123 125.549 297.39 125.115 298.413 124.149C301.625 121.157 299.34 116.078 294.853 116.078C292.031 116.078 289.785 118.205 289.785 120.798C289.785 123.652 292.288 125.549 294.886 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.886 82.115C296.128 82.115 297.39 81.6889 298.413 80.7341C301.625 77.7367 299.34 72.6484 294.853 72.6484C292.031 72.6484 289.785 74.7752 289.785 77.3727C289.785 80.2323 292.288 82.115 294.886 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.886 38.6944C296.128 38.6944 297.39 38.2277 298.413 37.1929C301.625 33.9446 299.34 28.4297 294.853 28.4297C292.031 28.4297 289.785 30.7398 289.785 33.555C289.785 36.6537 292.288 38.6944 294.886 38.6944Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.886 24.4742C296.128 24.4742 297.39 24.0122 298.413 22.978C301.625 19.7314 299.34 14.2188 294.853 14.2188C292.031 14.2188 289.785 16.5229 289.785 19.3371C289.785 22.4347 292.288 24.4742 294.886 24.4742Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.678 125.549C281.92 125.549 283.181 125.115 284.209 124.149C287.421 121.157 285.175 116.078 280.65 116.078C277.823 116.113 275.582 118.205 275.582 120.798C275.582 123.652 278.08 125.549 280.678 125.549H280.678Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.678 67.9084C281.92 67.9084 283.181 67.4377 284.209 66.3911C287.421 63.1504 285.175 57.6484 280.65 57.6484C277.823 57.6484 275.582 59.9529 275.582 62.7617C275.582 65.8534 278.08 67.9084 280.678 67.9084H280.678Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.678 24.4742C281.92 24.4742 283.181 24.0122 284.209 22.978C287.421 19.7314 285.175 14.2188 280.65 14.2188C277.823 14.2188 275.582 16.5229 275.582 19.3371C275.582 22.4347 278.08 24.4742 280.678 24.4742H280.678Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.683 140.545C266.93 140.545 268.201 140.083 269.239 139.048C272.415 135.802 270.168 130.289 265.64 130.289C262.855 130.289 260.57 132.593 260.57 135.407C260.57 138.505 263.074 140.544 265.683 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.683 125.549C266.93 125.549 268.201 125.115 269.239 124.149C272.415 121.157 270.168 116.078 265.64 116.078C262.855 116.113 260.57 118.205 260.57 120.798C260.57 123.652 263.074 125.549 265.683 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.678 111.333C266.925 111.333 268.201 110.866 269.239 109.81C272.415 106.601 270.168 101.078 265.64 101.078C262.855 101.078 260.57 103.348 260.57 106.211C260.57 109.28 263.07 111.333 265.678 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.683 97.1241C266.93 97.1241 268.201 96.6573 269.239 95.6226C272.415 92.3743 270.168 86.8594 265.64 86.8594C262.855 86.8594 260.57 89.1695 260.57 91.9847C260.57 95.0837 263.074 97.1241 265.683 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.678 53.6896C266.925 53.6896 268.201 53.2237 269.239 52.1724C272.415 48.9752 270.168 43.4297 265.64 43.4297C262.855 43.4729 260.57 45.7342 260.57 48.5859C260.57 51.6442 263.07 53.6896 265.678 53.6896Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M265.683 24.4742C266.93 24.4742 268.201 24.0122 269.239 22.978C272.415 19.7314 270.168 14.2188 265.64 14.2188C262.855 14.2188 260.57 16.5229 260.57 19.3371C260.57 22.4347 263.074 24.4742 265.683 24.4742Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.457 67.9084C252.7 67.9084 253.967 67.4377 255.001 66.3911C258.202 63.1504 255.926 57.6484 251.415 57.6484C248.635 57.6484 246.363 59.9529 246.363 62.7617C246.363 65.8534 248.854 67.9084 251.458 67.9084H251.457Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.238 53.6896C238.481 53.6896 239.752 53.2237 240.786 52.1724C243.988 48.9752 241.711 43.4297 237.2 43.4297C234.425 43.4729 232.187 45.7342 232.148 48.5859C232.148 51.6442 234.639 53.6896 237.238 53.6896Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 72.6484C205.219 72.6484 202.934 74.7752 202.934 77.3727C202.934 80.2323 205.438 82.115 208.046 82.115C209.294 82.115 210.565 81.6889 211.602 80.7341C214.778 77.7367 212.531 72.6484 208.004 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 28.4258C205.219 28.4258 202.934 30.7359 202.934 33.5511C202.934 36.6498 205.438 38.6905 208.046 38.6905C209.294 38.6905 210.565 38.2238 211.602 37.189C214.778 33.9407 212.531 28.4258 208.004 28.4258Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.824 53.6896C195.075 53.6896 196.346 53.2237 197.388 52.1724C200.557 48.9752 198.316 43.4297 193.79 43.4297C191.002 43.4729 188.723 45.7342 188.723 48.5859C188.723 51.6442 191.216 53.6896 193.824 53.6896H193.824Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.807 67.9084C180.051 67.9084 181.318 67.4377 182.352 66.3911C185.555 63.1504 183.277 57.6484 178.765 57.6484C175.989 57.6484 173.711 59.9529 173.711 62.7617C173.711 65.8534 176.202 67.9084 178.807 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.617 82.115C165.859 82.115 167.121 81.6889 168.146 80.7341C171.343 77.7367 169.071 72.6484 164.598 72.6484C161.78 72.6484 159.546 74.7752 159.508 77.3727C159.508 80.2323 162.017 82.115 164.616 82.115H164.617Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.617 67.9084C165.859 67.9084 167.121 67.4377 168.146 66.3911C171.343 63.1504 169.071 57.6484 164.598 57.6484C161.78 57.6484 159.546 59.9529 159.508 62.7617C159.508 65.8534 162.017 67.9084 164.616 67.9084H164.617Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.612 53.6896C165.855 53.6896 167.121 53.2237 168.146 52.1724C171.343 48.9752 169.071 43.4297 164.598 43.4297C161.78 43.4729 159.546 45.7342 159.508 48.5859C159.508 51.6442 162.012 53.6896 164.612 53.6896H164.612Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.359 57.6484C147.536 57.6484 145.289 59.9529 145.289 62.7617C145.289 65.8534 147.788 67.9084 150.392 67.9084C151.63 67.9084 152.896 67.4377 153.92 66.3911C157.133 63.1504 154.886 57.6484 150.359 57.6484H150.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M396.747 198.189C397.99 198.189 399.256 197.723 400.279 196.672C403.493 193.436 401.246 187.934 396.718 187.934C393.895 187.934 391.648 190.233 391.648 193.042C391.648 196.134 394.148 198.189 396.747 198.189H396.747Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 217.148C378.929 217.148 376.645 219.414 376.645 222.272C376.645 225.364 379.147 227.404 381.755 227.404C383.002 227.404 384.272 226.942 385.31 225.908C388.484 222.661 386.243 217.148 381.755 217.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M381.755 187.934C378.929 187.934 376.645 190.233 376.645 193.042C376.645 196.134 379.147 198.189 381.755 198.189C383.002 198.189 384.272 197.723 385.31 196.672C388.484 193.474 386.243 187.934 381.755 187.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M381.755 173.719C378.929 173.757 376.645 176.023 376.645 178.87C376.645 181.933 379.143 183.979 381.751 183.979C382.997 183.979 384.272 183.508 385.31 182.461C388.484 179.259 386.243 173.757 381.755 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.547 227.404C368.785 227.404 370.052 226.942 371.071 225.908C374.273 222.661 371.996 217.148 367.523 217.148C364.711 217.148 362.471 219.453 362.434 222.272C362.434 225.364 364.943 227.404 367.547 227.404H367.547Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.542 198.189C368.785 198.189 370.047 197.723 371.071 196.672C374.273 193.436 371.996 187.934 367.523 187.934C364.711 187.934 362.471 190.233 362.434 193.042C362.434 196.134 364.943 198.189 367.542 198.189H367.542Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.538 183.979C368.78 183.979 370.047 183.508 371.071 182.461C374.273 179.259 371.996 173.719 367.523 173.719C364.711 173.757 362.471 176.023 362.434 178.87C362.434 181.933 364.938 183.979 367.537 183.979H367.538Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.523 168.979C368.771 168.979 370.042 168.543 371.071 167.565C374.273 164.603 371.996 159.508 367.523 159.508C364.711 159.508 362.471 161.602 362.434 164.243C362.434 167.094 364.929 168.979 367.523 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M367.547 154.765C368.785 154.765 370.052 154.298 371.071 153.263C374.273 150.015 371.996 144.5 367.523 144.5C364.711 144.5 362.471 146.81 362.434 149.625C362.434 152.724 364.943 154.765 367.547 154.765H367.547Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.32 401.123C354.562 401.123 355.823 400.657 356.846 399.623C360.058 396.376 357.774 390.863 353.287 390.863C350.465 390.863 348.219 393.167 348.219 395.986C348.219 399.079 350.722 401.123 353.32 401.123Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.32 371.904C354.557 371.904 355.823 371.438 356.846 370.391C360.058 367.15 357.774 361.648 353.287 361.648C350.465 361.687 348.219 363.948 348.219 366.762C348.219 369.849 350.722 371.904 353.32 371.904Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.324 231.355H353.276C350.46 231.355 348.219 233.661 348.219 236.471C348.219 239.564 350.717 241.62 353.319 241.62C354.563 241.62 355.831 241.15 356.867 240.103C360.063 236.875 357.812 231.355 353.324 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.32 227.404C354.562 227.404 355.823 226.942 356.846 225.908C360.058 222.661 357.774 217.148 353.287 217.148C350.465 217.148 348.219 219.453 348.219 222.272C348.219 225.364 350.722 227.404 353.32 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.32 212.405C354.562 212.405 355.823 211.974 356.846 211.019C360.058 208.021 357.774 202.93 353.287 202.93C350.465 202.93 348.219 205.062 348.219 207.661C348.219 210.521 350.722 212.405 353.32 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.32 198.189C354.557 198.189 355.823 197.723 356.846 196.672C360.058 193.436 357.774 187.934 353.287 187.934C350.465 187.934 348.219 190.233 348.219 193.042C348.219 196.134 350.722 198.189 353.32 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.296 168.979C354.543 168.979 355.818 168.543 356.846 167.565C360.058 164.603 357.774 159.508 353.287 159.508C350.465 159.508 348.219 161.602 348.219 164.243C348.219 167.094 350.708 168.979 353.296 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 361.648C335.507 361.687 333.223 363.948 333.223 366.762C333.223 369.849 335.721 371.904 338.333 371.904C339.58 371.904 340.851 371.438 341.888 370.391C345.062 367.15 342.816 361.648 338.291 361.648H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 347.43C335.507 347.43 333.223 349.703 333.223 352.56C333.223 355.653 335.707 357.695 338.31 357.695C339.561 357.695 340.846 357.218 341.888 356.163C345.062 352.95 342.816 347.43 338.29 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 246.363C335.507 246.363 333.223 248.663 333.223 251.477C333.223 254.563 335.721 256.618 338.333 256.618C339.58 256.618 340.851 256.153 341.888 255.106C345.062 251.865 342.816 246.363 338.291 246.363H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.335 241.62C339.579 241.62 340.847 241.15 341.867 240.103C345.057 236.875 342.807 231.355 338.326 231.355H338.278C335.459 231.355 333.223 233.661 333.223 236.471C333.223 239.564 335.734 241.62 338.335 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 217.148C335.507 217.148 333.223 219.453 333.223 222.272C333.223 225.364 335.721 227.404 338.333 227.404C339.58 227.404 340.851 226.942 341.888 225.908C345.062 222.661 342.816 217.148 338.291 217.148H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 202.93C335.507 202.93 333.223 205.062 333.223 207.661C333.223 210.521 335.721 212.405 338.333 212.405C339.58 212.405 340.851 211.974 341.888 211.019C345.062 208.021 342.816 202.93 338.291 202.93H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 187.934C335.507 187.934 333.223 190.233 333.223 193.042C333.223 196.134 335.721 198.189 338.333 198.189C339.58 198.189 340.851 197.723 341.888 196.672C345.062 193.436 342.816 187.934 338.291 187.934H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 173.719C335.507 173.757 333.223 176.023 333.223 178.87C333.223 181.933 335.716 183.979 338.329 183.979C339.575 183.979 340.851 183.508 341.888 182.461C345.062 179.259 342.816 173.719 338.29 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 159.508C335.507 159.508 333.223 161.602 333.223 164.243C333.223 167.094 335.707 168.979 338.31 168.979C339.561 168.979 340.846 168.543 341.888 167.565C345.062 164.604 342.816 159.508 338.29 159.508V159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M338.29 144.5C335.507 144.5 333.223 146.81 333.223 149.625C333.223 152.724 335.721 154.765 338.333 154.765C339.58 154.765 340.851 154.298 341.888 153.263C345.062 150.015 342.816 144.5 338.291 144.5H338.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.072 347.43C321.288 347.43 319.004 349.703 319.004 352.56C319.004 355.653 321.493 357.695 324.096 357.695C325.347 357.695 326.627 357.218 327.669 356.163C330.843 352.95 328.602 347.43 324.072 347.43H324.072Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.072 318.211C321.288 318.211 319.004 320.52 319.004 323.334C319.004 326.432 321.507 328.471 324.115 328.471C325.361 328.471 326.632 328.004 327.669 326.97C330.843 323.724 328.602 318.211 324.072 318.211H324.072Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.072 260.57C321.288 260.57 319.004 262.879 319.004 265.693C319.004 268.791 321.507 270.831 324.115 270.831C325.361 270.831 326.632 270.364 327.669 269.33C330.843 266.083 328.602 260.57 324.072 260.57H324.072Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.072 246.363C321.288 246.363 319.004 248.663 319.004 251.477C319.004 254.563 321.507 256.618 324.115 256.618C325.361 256.618 326.632 256.153 327.669 255.106C330.843 251.865 328.602 246.363 324.072 246.363H324.072Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.109 231.355H324.062C321.283 231.355 319.004 233.661 319.004 236.471C319.004 239.564 321.516 241.62 324.133 241.62C325.382 241.62 326.655 241.15 327.695 240.103C330.848 236.875 328.597 231.355 324.109 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.114 227.404C325.361 227.404 326.632 226.942 327.669 225.908C330.843 222.661 328.602 217.148 324.072 217.148C321.288 217.148 319.004 219.453 319.004 222.272C319.004 225.364 321.507 227.404 324.115 227.404H324.114Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.114 212.405C325.361 212.405 326.632 211.974 327.669 211.019C330.843 208.021 328.602 202.93 324.072 202.93C321.288 202.93 319.004 205.062 319.004 207.661C319.004 210.521 321.507 212.405 324.115 212.405H324.114Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M324.114 198.189C325.361 198.189 326.632 197.723 327.669 196.672C330.843 193.436 328.602 187.934 324.072 187.934C321.288 187.934 319.004 190.233 319.004 193.042C319.004 196.134 321.507 198.189 324.115 198.189H324.114Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.849 347.43C307.074 347.43 304.797 349.703 304.797 352.56C304.797 355.653 307.292 357.695 309.887 357.695C311.134 357.695 312.405 357.218 313.435 356.163C316.636 352.95 314.36 347.43 309.849 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.849 318.211C307.074 318.211 304.797 320.52 304.797 323.334C304.797 326.432 307.287 328.471 309.891 328.471C311.134 328.471 312.401 328.004 313.435 326.97C316.636 323.724 314.36 318.211 309.849 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.849 304.004C307.074 304.042 304.797 306.304 304.797 309.155C304.797 312.218 307.301 314.259 309.901 314.259C311.143 314.259 312.41 313.793 313.434 312.747C316.636 309.506 314.359 304.004 309.848 304.004H309.849Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.849 289.785C307.074 289.824 304.835 292.091 304.797 294.939C304.797 298.004 307.282 300.05 309.887 300.05C311.129 300.05 312.4 299.579 313.435 298.532C316.636 295.328 314.36 289.785 309.849 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.849 274.789C307.074 274.789 304.797 277.096 304.797 279.917C304.797 283.008 307.292 285.045 309.887 285.045C311.134 285.045 312.405 284.573 313.435 283.518C316.636 280.307 314.36 274.789 309.849 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.849 260.57C307.074 260.57 304.835 262.879 304.797 265.693C304.797 268.791 307.287 270.831 309.891 270.831C311.134 270.831 312.401 270.364 313.435 269.33C316.636 266.083 314.36 260.57 309.849 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.849 246.363C307.074 246.363 304.797 248.663 304.797 251.477C304.797 254.563 307.287 256.618 309.891 256.618C311.134 256.618 312.401 256.153 313.435 255.106C316.636 251.865 314.36 246.363 309.849 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.884 231.355H309.836C307.068 231.355 304.797 233.661 304.797 236.471C304.797 239.564 307.3 241.62 309.907 241.62C311.152 241.62 312.42 241.15 313.452 240.103C316.632 236.875 314.389 231.355 309.884 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.891 227.404C311.134 227.404 312.401 226.942 313.435 225.908C316.636 222.661 314.36 217.148 309.849 217.148C307.074 217.148 304.835 219.453 304.797 222.272C304.797 225.364 307.287 227.404 309.891 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.891 212.405C311.134 212.405 312.401 211.974 313.435 211.019C316.636 208.021 314.36 202.93 309.849 202.93C307.074 202.93 304.797 205.062 304.797 207.661C304.797 210.521 307.287 212.405 309.891 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.891 198.189C311.134 198.189 312.401 197.723 313.435 196.672C316.636 193.436 314.36 187.934 309.849 187.934C307.074 187.934 304.835 190.233 304.797 193.042C304.797 196.134 307.287 198.189 309.891 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M309.887 183.979C311.129 183.979 312.4 183.508 313.435 182.461C316.636 179.259 314.36 173.719 309.849 173.719C307.074 173.757 304.835 176.023 304.797 178.87C304.797 181.933 307.282 183.979 309.887 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.853 390.863C292.031 390.863 289.785 393.167 289.785 395.986C289.785 399.079 292.288 401.123 294.886 401.123C296.128 401.123 297.39 400.657 298.413 399.623C301.625 396.376 299.34 390.863 294.853 390.863Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.853 376.645C292.031 376.645 289.785 378.95 289.785 381.76C289.785 384.853 292.288 386.91 294.886 386.91C296.123 386.91 297.39 386.439 298.413 385.392C301.625 382.149 299.34 376.645 294.853 376.645Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.853 289.785C292.031 289.824 289.785 292.091 289.785 294.939C289.785 298.004 292.283 300.05 294.882 300.05C296.123 300.05 297.389 299.579 298.413 298.532C301.625 295.328 299.34 289.785 294.853 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.853 274.789C292.031 274.789 289.785 277.096 289.785 279.917C289.785 283.008 292.274 285.045 294.862 285.045C296.109 285.045 297.384 284.573 298.413 283.518C301.625 280.307 299.34 274.789 294.853 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.853 260.57C292.031 260.57 289.785 262.879 289.785 265.693C289.785 268.791 292.288 270.831 294.886 270.831C296.128 270.831 297.39 270.364 298.413 269.33C301.625 266.083 299.34 260.57 294.853 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.853 246.363C292.031 246.363 289.785 248.663 289.785 251.477C289.785 254.563 292.288 256.618 294.886 256.618C296.123 256.618 297.39 256.153 298.413 255.106C301.625 251.865 299.34 246.363 294.853 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M294.89 231.355H294.843C292.027 231.355 289.785 233.661 289.785 236.471C289.785 239.564 292.283 241.62 294.886 241.62C296.13 241.62 297.398 241.15 298.433 240.103C301.629 236.875 299.378 231.355 294.89 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.886 227.404C296.128 227.404 297.39 226.942 298.413 225.908C301.625 222.661 299.34 217.148 294.853 217.148C292.031 217.148 289.785 219.453 289.785 222.272C289.785 225.364 292.288 227.404 294.886 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.886 212.405C296.128 212.405 297.39 211.974 298.413 211.019C301.625 208.021 299.34 202.93 294.853 202.93C292.031 202.93 289.785 205.062 289.785 207.661C289.785 210.521 292.288 212.405 294.886 212.405Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.886 198.189C296.123 198.189 297.39 197.723 298.413 196.672C301.625 193.436 299.34 187.934 294.853 187.934C292.031 187.934 289.785 190.233 289.785 193.042C289.785 196.134 292.288 198.189 294.886 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M294.882 183.979C296.123 183.979 297.389 183.508 298.413 182.461C301.625 179.259 299.34 173.719 294.853 173.719C292.031 173.757 289.785 176.023 289.785 178.87C289.785 181.933 292.283 183.979 294.882 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.65 376.645C277.823 376.645 275.582 378.95 275.582 381.76C275.582 384.853 278.08 386.91 280.693 386.91C281.939 386.91 283.21 386.439 284.248 385.392C287.421 382.149 285.175 376.645 280.65 376.645H280.65Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.678 371.904C281.92 371.904 283.181 371.438 284.209 370.391C287.421 367.15 285.175 361.648 280.65 361.648C277.823 361.687 275.582 363.948 275.582 366.762C275.582 369.849 278.08 371.904 280.678 371.904H280.678Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.65 347.43C277.823 347.43 275.582 349.703 275.582 352.56C275.582 355.653 278.066 357.695 280.669 357.695C281.92 357.695 283.205 357.218 284.247 356.163C287.421 352.95 285.175 347.43 280.65 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.673 300.05C281.915 300.05 283.181 299.579 284.209 298.532C287.421 295.328 285.175 289.785 280.65 289.785C277.823 289.824 275.582 292.091 275.582 294.939C275.582 298.004 278.076 300.05 280.674 300.05H280.673Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.65 274.789C277.823 274.789 275.582 277.096 275.582 279.917C275.582 283.008 278.066 285.045 280.669 285.045C281.92 285.045 283.205 284.573 284.247 283.518C287.421 280.307 285.175 274.789 280.65 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.65 260.57C277.823 260.57 275.582 262.879 275.582 265.693C275.582 268.791 278.08 270.831 280.678 270.831C281.92 270.831 283.182 270.364 284.209 269.33C287.421 266.083 285.175 260.57 280.65 260.57H280.65Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.65 246.363C277.823 246.363 275.582 248.663 275.582 251.477C275.582 254.563 278.08 256.618 280.678 256.618C281.92 256.618 283.182 256.153 284.209 255.106C287.421 251.865 285.175 246.363 280.65 246.363H280.65Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.68 241.62C281.924 241.62 283.192 241.15 284.227 240.103C287.417 236.875 285.166 231.355 280.685 231.355H280.638C277.818 231.355 275.582 233.661 275.582 236.471C275.582 239.564 278.074 241.62 280.68 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.678 227.404C281.92 227.404 283.181 226.942 284.209 225.908C287.421 222.661 285.175 217.148 280.65 217.148C277.823 217.148 275.582 219.453 275.582 222.272C275.582 225.364 278.08 227.404 280.678 227.404H280.678Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.678 212.405C281.92 212.405 283.181 211.974 284.209 211.019C287.421 208.021 285.175 202.93 280.65 202.93C277.823 202.93 275.582 205.062 275.582 207.661C275.582 210.521 278.08 212.405 280.678 212.405H280.678Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.678 198.189C281.92 198.189 283.181 197.723 284.209 196.672C287.421 193.436 285.175 187.934 280.65 187.934C277.823 187.934 275.582 190.233 275.582 193.042C275.582 196.134 278.08 198.189 280.678 198.189H280.678Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.673 183.979C281.915 183.979 283.181 183.508 284.209 182.461C287.421 179.259 285.175 173.719 280.65 173.719C277.823 173.757 275.582 176.023 275.582 178.87C275.582 181.933 278.076 183.979 280.674 183.979H280.673Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M280.659 168.979C281.906 168.979 283.176 168.543 284.209 167.565C287.421 164.603 285.175 159.508 280.65 159.508C277.823 159.508 275.582 161.602 275.582 164.243C275.582 167.094 278.066 168.979 280.659 168.979L280.659 168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.64 361.648C262.855 361.687 260.57 363.948 260.57 366.762C260.57 369.849 263.074 371.904 265.683 371.904C266.93 371.904 268.201 371.438 269.239 370.391C272.415 367.15 270.168 361.648 265.64 361.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.64 347.43C262.855 347.43 260.57 349.703 260.57 352.56C260.57 355.653 263.06 357.695 265.659 357.695C266.916 357.695 268.197 357.218 269.239 356.163C272.415 352.95 270.168 347.43 265.64 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.64 289.785C262.855 289.824 260.57 292.091 260.57 294.939C260.57 298.004 263.07 300.05 265.678 300.05C266.925 300.05 268.201 299.579 269.239 298.532C272.415 295.328 270.168 289.785 265.64 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.64 260.57C262.855 260.57 260.57 262.879 260.57 265.693C260.57 268.791 263.074 270.831 265.683 270.831C266.93 270.831 268.201 270.364 269.239 269.33C272.415 266.083 270.168 260.57 265.64 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.64 246.363C262.855 246.363 260.57 248.663 260.57 251.477C260.57 254.563 263.074 256.618 265.683 256.618C266.93 256.618 268.201 256.153 269.239 255.106C272.415 251.865 270.168 246.363 265.64 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M266.016 231.355H265.965C263.002 231.355 260.57 233.661 260.57 236.471C260.57 239.564 263.25 241.62 266.031 241.62C267.358 241.62 268.706 241.15 269.795 240.103C273.204 236.875 270.803 231.355 266.016 231.355H266.016Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.683 227.404C266.93 227.404 268.201 226.942 269.239 225.908C272.415 222.661 270.168 217.148 265.64 217.148C262.855 217.148 260.57 219.453 260.57 222.272C260.57 225.364 263.074 227.404 265.683 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.683 212.405C266.93 212.405 268.201 211.974 269.239 211.019C272.415 208.021 270.168 202.93 265.64 202.93C262.855 202.93 260.57 205.062 260.57 207.661C260.57 210.521 263.074 212.405 265.683 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.683 198.189C266.93 198.189 268.201 197.723 269.239 196.672C272.415 193.436 270.168 187.934 265.64 187.934C262.855 187.934 260.57 190.233 260.57 193.042C260.57 196.134 263.074 198.189 265.683 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.678 183.979C266.925 183.979 268.201 183.508 269.239 182.461C272.415 179.259 270.168 173.719 265.64 173.719C262.855 173.757 260.57 176.023 260.57 178.87C260.57 181.933 263.07 183.979 265.678 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M265.659 168.979C266.916 168.979 268.197 168.543 269.239 167.565C272.415 164.603 270.168 159.508 265.64 159.508C262.855 159.508 260.57 161.602 260.57 164.243C260.57 167.094 263.06 168.979 265.659 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.415 361.648C248.635 361.687 246.363 363.948 246.363 366.762C246.363 369.849 248.854 371.904 251.458 371.904C252.7 371.904 253.967 371.438 255.001 370.391C258.203 367.15 255.926 361.648 251.415 361.648H251.415Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.415 347.43C248.635 347.43 246.363 349.703 246.363 352.56C246.363 355.653 248.839 357.695 251.434 357.695C252.681 357.695 253.957 357.218 255.001 356.163C258.202 352.95 255.926 347.43 251.415 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.415 289.785C248.635 289.824 246.363 292.091 246.363 294.939C246.363 298.004 248.849 300.05 251.448 300.05C252.696 300.05 253.962 299.579 255.001 298.532C258.203 295.328 255.926 289.785 251.415 289.785H251.415Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.415 274.789C248.635 274.789 246.363 277.096 246.363 279.917C246.363 283.008 248.839 285.045 251.434 285.045C252.681 285.045 253.957 284.573 255.001 283.518C258.202 280.307 255.926 274.789 251.415 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.415 260.57C248.635 260.57 246.363 262.879 246.363 265.693C246.363 268.791 248.854 270.831 251.458 270.831C252.7 270.831 253.967 270.364 255.001 269.33C258.203 266.083 255.926 260.57 251.415 260.57H251.415Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.415 246.363C248.635 246.363 246.363 248.663 246.363 251.477C246.363 254.563 248.854 256.618 251.458 256.618C252.7 256.618 253.967 256.153 255.001 255.106C258.203 251.865 255.926 246.363 251.415 246.363H251.415Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.45 231.355H251.403C248.63 231.355 246.363 233.661 246.363 236.471C246.363 239.564 248.866 241.62 251.474 241.62C252.718 241.62 253.986 241.15 255.018 240.103C258.198 236.875 255.955 231.355 251.45 231.355H251.45Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M251.457 227.404C252.7 227.404 253.967 226.942 255.001 225.908C258.202 222.661 255.926 217.148 251.415 217.148C248.635 217.148 246.363 219.453 246.363 222.272C246.363 225.364 248.854 227.404 251.458 227.404H251.457Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.457 212.405C252.7 212.405 253.967 211.974 255.001 211.019C258.202 208.021 255.926 202.93 251.415 202.93C248.635 202.93 246.363 205.062 246.363 207.661C246.363 210.521 248.854 212.405 251.458 212.405H251.457Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.457 198.189C252.7 198.189 253.967 197.723 255.001 196.672C258.202 193.436 255.926 187.934 251.415 187.934C248.635 187.934 246.363 190.233 246.363 193.042C246.363 196.134 248.854 198.189 251.458 198.189H251.457Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.448 183.979C252.695 183.979 253.962 183.508 255.001 182.461C258.202 179.259 255.926 173.719 251.415 173.719C248.635 173.757 246.363 176.023 246.363 178.87C246.363 181.933 248.849 183.979 251.448 183.979H251.448Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.434 168.979C252.681 168.979 253.957 168.543 255.001 167.565C258.202 164.603 255.926 159.508 251.415 159.508C248.635 159.508 246.363 161.602 246.363 164.243C246.363 167.094 248.839 168.979 251.434 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.457 154.765C252.7 154.765 253.967 154.298 255.001 153.263C258.202 150.015 255.926 144.5 251.415 144.5C248.635 144.5 246.363 146.81 246.363 149.625C246.363 152.724 248.854 154.765 251.458 154.765H251.457Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.457 140.545C252.7 140.545 253.967 140.083 255.001 139.048C258.202 135.802 255.926 130.289 251.415 130.289C248.635 130.289 246.363 132.593 246.363 135.407C246.363 138.505 248.854 140.544 251.458 140.544L251.457 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M251.457 125.549C252.7 125.549 253.967 125.115 255.001 124.149C258.202 121.157 255.926 116.078 251.415 116.078C248.635 116.078 246.363 118.205 246.363 120.798C246.363 123.652 248.854 125.549 251.458 125.549H251.457Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.2 347.43C234.425 347.43 232.148 349.703 232.148 352.56C232.148 355.653 234.643 357.695 237.238 357.695C238.486 357.695 239.762 357.218 240.786 356.163C243.988 352.95 241.711 347.43 237.2 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.2 333.219C234.425 333.219 232.187 335.523 232.148 338.342C232.148 341.435 234.643 343.479 237.243 343.479C238.486 343.479 239.752 343.012 240.786 341.978C243.988 338.731 241.711 333.219 237.2 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.2 318.211C234.425 318.211 232.148 320.52 232.148 323.334C232.148 326.432 234.643 328.471 237.243 328.471C238.486 328.471 239.752 328.004 240.786 326.97C243.988 323.724 241.711 318.211 237.2 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.2 304.004C234.425 304.042 232.148 306.304 232.148 309.155C232.148 312.218 234.653 314.259 237.257 314.259C238.5 314.259 239.766 313.793 240.786 312.747C243.988 309.506 241.711 304.004 237.2 304.004H237.2Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.2 289.785C234.425 289.824 232.187 292.091 232.148 294.939C232.148 298.004 234.639 300.05 237.238 300.05C238.481 300.05 239.752 299.579 240.786 298.532C243.988 295.328 241.711 289.785 237.2 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.2 274.789C234.425 274.789 232.148 277.096 232.148 279.917C232.148 283.008 234.643 285.045 237.238 285.045C238.486 285.045 239.762 284.573 240.786 283.518C243.988 280.307 241.711 274.789 237.2 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.2 260.57C234.425 260.57 232.187 262.879 232.148 265.693C232.148 268.791 234.643 270.831 237.243 270.831C238.486 270.831 239.752 270.364 240.786 269.33C243.988 266.083 241.711 260.57 237.2 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.2 246.363C234.425 246.363 232.148 248.663 232.148 251.477C232.148 254.563 234.639 256.618 237.243 256.618C238.486 256.618 239.752 256.153 240.786 255.106C243.988 251.865 241.711 246.363 237.2 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.238 231.355H237.19C234.421 231.355 232.148 233.661 232.148 236.471C232.148 239.564 234.653 241.62 237.261 241.62C238.506 241.62 239.774 241.15 240.806 240.103C243.992 236.875 241.749 231.355 237.237 231.355H237.238Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.243 227.404C238.486 227.404 239.752 226.942 240.786 225.908C243.988 222.661 241.711 217.148 237.2 217.148C234.425 217.148 232.187 219.453 232.148 222.272C232.148 225.364 234.643 227.404 237.243 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.243 212.405C238.486 212.405 239.752 211.974 240.786 211.019C243.988 208.021 241.711 202.93 237.2 202.93C234.425 202.93 232.148 205.062 232.148 207.661C232.148 210.521 234.643 212.405 237.243 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.243 198.189C238.486 198.189 239.752 197.723 240.786 196.672C243.988 193.436 241.711 187.934 237.2 187.934C234.425 187.934 232.187 190.233 232.148 193.042C232.148 196.134 234.639 198.189 237.243 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.238 183.979C238.481 183.979 239.752 183.508 240.786 182.461C243.988 179.259 241.711 173.719 237.2 173.719C234.425 173.757 232.187 176.023 232.148 178.87C232.148 181.933 234.639 183.979 237.238 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.219 168.979C238.471 168.979 239.747 168.543 240.786 167.565C243.988 164.603 241.711 159.508 237.2 159.508C234.425 159.508 232.148 161.602 232.148 164.243C232.148 167.094 234.624 168.979 237.219 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.243 154.765C238.486 154.765 239.752 154.298 240.786 153.263C243.988 150.015 241.711 144.5 237.2 144.5C234.425 144.5 232.187 146.81 232.148 149.625C232.148 152.724 234.643 154.765 237.243 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.243 140.545C238.486 140.545 239.752 140.083 240.786 139.048C243.988 135.802 241.711 130.289 237.2 130.289C234.425 130.289 232.148 132.593 232.148 135.407C232.148 138.505 234.643 140.544 237.243 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.243 125.549C238.486 125.549 239.752 125.115 240.786 124.149C243.988 121.157 241.711 116.078 237.2 116.078C234.425 116.113 232.187 118.205 232.148 120.798C232.148 123.652 234.639 125.549 237.243 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M237.238 111.333C238.481 111.333 239.752 110.866 240.786 109.81C243.988 106.601 241.711 101.078 237.2 101.078C234.425 101.078 232.187 103.348 232.148 106.211C232.148 109.28 234.639 111.333 237.238 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.216 333.219C219.39 333.219 217.148 335.523 217.148 338.342C217.148 341.435 219.647 343.479 222.245 343.479C223.487 343.479 224.748 343.012 225.776 341.978C228.983 338.731 226.704 333.219 222.217 333.219H222.216Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.216 318.211C219.39 318.211 217.148 320.52 217.148 323.334C217.148 326.432 219.647 328.471 222.245 328.471C223.487 328.471 224.748 328.004 225.776 326.97C228.983 323.724 226.704 318.211 222.217 318.211H222.216Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.216 304.004C219.39 304.042 217.148 306.304 217.148 309.155C217.148 312.218 219.642 314.259 222.24 314.259C223.482 314.259 224.748 313.793 225.776 312.747C228.983 309.506 226.704 304.004 222.217 304.004H222.216Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.216 289.785C219.39 289.824 217.148 292.091 217.148 294.939C217.148 298.004 219.642 300.05 222.24 300.05C223.482 300.05 224.748 299.579 225.776 298.532C228.983 295.328 226.704 289.785 222.217 289.785H222.216Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.216 274.789C219.39 274.789 217.148 277.096 217.148 279.917C217.148 283.008 219.633 285.045 222.221 285.045C223.472 285.045 224.743 284.573 225.776 283.518C228.983 280.307 226.704 274.789 222.217 274.789L222.216 274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.216 260.57C219.39 260.57 217.148 262.879 217.148 265.693C217.148 268.791 219.647 270.831 222.245 270.831C223.487 270.831 224.748 270.364 225.776 269.33C228.983 266.083 226.704 260.57 222.217 260.57H222.216Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.216 246.363C219.39 246.363 217.148 248.663 217.148 251.477C217.148 254.563 219.647 256.618 222.245 256.618C223.487 256.618 224.748 256.153 225.776 255.106C228.983 251.865 226.704 246.363 222.217 246.363H222.216Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M222.592 231.355H222.541C219.533 231.355 217.148 233.661 217.148 236.471C217.148 239.564 219.807 241.62 222.587 241.62C223.913 241.62 225.265 241.15 226.369 240.103C229.772 236.875 227.372 231.355 222.592 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.245 227.404C223.486 227.404 224.748 226.942 225.776 225.908C228.983 222.661 226.703 217.148 222.216 217.148C219.39 217.148 217.148 219.453 217.148 222.272C217.148 225.364 219.647 227.404 222.245 227.404H222.245Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.245 212.405C223.486 212.405 224.748 211.974 225.776 211.019C228.983 208.021 226.703 202.93 222.216 202.93C219.39 202.93 217.148 205.062 217.148 207.661C217.148 210.521 219.647 212.405 222.245 212.405H222.245Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.245 198.189C223.486 198.189 224.748 197.723 225.776 196.672C228.983 193.436 226.703 187.934 222.216 187.934C219.39 187.934 217.148 190.233 217.148 193.042C217.148 196.134 219.647 198.189 222.245 198.189H222.245Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.24 183.979C223.482 183.979 224.747 183.508 225.776 182.461C228.983 179.259 226.703 173.719 222.216 173.719C219.39 173.757 217.148 176.023 217.148 178.87C217.148 181.933 219.642 183.979 222.24 183.979H222.24Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.245 154.765C223.486 154.765 224.748 154.298 225.776 153.263C228.983 150.015 226.703 144.5 222.216 144.5C219.39 144.5 217.148 146.81 217.148 149.625C217.148 152.724 219.647 154.765 222.245 154.765H222.245Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.245 140.545C223.486 140.545 224.748 140.083 225.776 139.048C228.983 135.802 226.703 130.289 222.216 130.289C219.39 130.289 217.148 132.593 217.148 135.407C217.148 138.505 219.647 140.544 222.245 140.544L222.245 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.245 125.549C223.486 125.549 224.748 125.115 225.776 124.149C228.983 121.157 226.703 116.078 222.216 116.078C219.39 116.078 217.148 118.205 217.148 120.798C217.148 123.652 219.647 125.549 222.245 125.549H222.245Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.24 111.333C223.482 111.333 224.747 110.866 225.776 109.81C228.983 106.601 226.703 101.078 222.216 101.078C219.39 101.078 217.148 103.348 217.148 106.211C217.148 109.28 219.642 111.333 222.24 111.333H222.24Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M222.245 97.1241C223.486 97.1241 224.748 96.6573 225.776 95.6226C228.983 92.3743 226.703 86.8594 222.216 86.8594C219.39 86.8594 217.148 89.1695 217.148 91.9847C217.148 95.0837 219.647 97.1241 222.245 97.1241H222.245Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 289.785C205.219 289.824 202.934 292.091 202.934 294.939C202.934 298.004 205.433 300.05 208.042 300.05C209.289 300.05 210.565 299.579 211.602 298.532C214.778 295.328 212.531 289.785 208.004 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 274.789C205.219 274.789 202.934 277.096 202.934 279.917C202.934 283.008 205.423 285.045 208.022 285.045C209.279 285.045 210.56 284.573 211.602 283.518C214.778 280.307 212.531 274.789 208.003 274.789L208.004 274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 260.57C205.219 260.57 202.934 262.879 202.934 265.693C202.934 268.791 205.438 270.831 208.046 270.831C209.294 270.831 210.565 270.364 211.602 269.33C214.778 266.083 212.531 260.57 208.004 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 246.363C205.219 246.363 202.934 248.663 202.934 251.477C202.934 254.563 205.433 256.618 208.046 256.618C209.294 256.618 210.565 256.153 211.602 255.106C214.778 251.865 212.531 246.363 208.004 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.053 241.62C209.297 241.62 210.56 241.15 211.581 240.103C214.778 236.875 212.526 231.355 208.039 231.355H207.991C205.175 231.355 202.934 233.661 202.934 236.471C202.934 239.564 205.446 241.62 208.053 241.62H208.053Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 217.148C205.219 217.148 202.934 219.453 202.934 222.272C202.934 225.364 205.438 227.404 208.046 227.404C209.294 227.404 210.565 226.942 211.602 225.908C214.778 222.661 212.531 217.148 208.004 217.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 202.93C205.219 202.93 202.934 205.062 202.934 207.661C202.934 210.521 205.438 212.405 208.046 212.405C209.294 212.405 210.565 211.974 211.602 211.019C214.778 208.021 212.531 202.93 208.004 202.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 187.934C205.219 187.934 202.934 190.233 202.934 193.042C202.934 196.134 205.433 198.189 208.046 198.189C209.294 198.189 210.565 197.723 211.602 196.672C214.778 193.436 212.531 187.934 208.004 187.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 173.719C205.219 173.757 202.934 176.023 202.934 178.87C202.934 181.933 205.433 183.979 208.042 183.979C209.289 183.979 210.565 183.508 211.602 182.461C214.778 179.259 212.531 173.719 208.004 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 159.508C205.219 159.508 202.934 161.602 202.934 164.243C202.934 167.094 205.423 168.979 208.022 168.979C209.279 168.979 210.56 168.543 211.602 167.565C214.778 164.604 212.531 159.508 208.003 159.508L208.004 159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 144.5C205.219 144.5 202.934 146.81 202.934 149.625C202.934 152.724 205.438 154.765 208.046 154.765C209.294 154.765 210.565 154.298 211.602 153.263C214.778 150.015 212.531 144.5 208.004 144.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 130.289C205.219 130.289 202.934 132.594 202.934 135.407C202.934 138.505 205.438 140.545 208.046 140.545C209.294 140.545 210.565 140.083 211.602 139.048C214.778 135.802 212.531 130.289 208.004 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 116.078C205.219 116.113 202.934 118.205 202.934 120.798C202.934 123.652 205.433 125.549 208.046 125.549C209.294 125.549 210.565 125.115 211.602 124.149C214.778 121.157 212.531 116.078 208.004 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 101.078C205.219 101.078 202.934 103.348 202.934 106.211C202.934 109.28 205.433 111.333 208.042 111.333C209.289 111.333 210.565 110.866 211.602 109.81C214.778 106.601 212.531 101.078 208.004 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M208.004 86.8594C205.219 86.8594 202.934 89.1695 202.934 91.9847C202.934 95.0837 205.438 97.1241 208.046 97.1241C209.294 97.1241 210.565 96.6573 211.602 95.6226C214.778 92.3743 212.531 86.8594 208.004 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.79 318.211C191.002 318.211 188.723 320.52 188.723 323.334C188.723 326.432 191.221 328.471 193.833 328.471C195.08 328.471 196.351 328.004 197.388 326.97C200.557 323.724 198.316 318.211 193.791 318.211H193.79Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.79 304.004C191.002 304.042 188.723 306.304 188.723 309.155C188.723 312.218 191.216 314.259 193.824 314.259C195.075 314.259 196.346 313.793 197.388 312.747C200.557 309.506 198.316 304.004 193.791 304.004H193.79Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.79 289.785C191.002 289.824 188.723 292.091 188.723 294.939C188.723 298.004 191.216 300.05 193.824 300.05C195.075 300.05 196.346 299.579 197.388 298.532C200.557 295.328 198.316 289.785 193.791 289.785H193.79Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.79 274.789C191.002 274.789 188.723 277.096 188.723 279.917C188.723 283.008 191.207 285.045 193.81 285.045C195.061 285.045 196.341 284.573 197.388 283.518C200.557 280.307 198.316 274.789 193.79 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.79 260.57C191.002 260.57 188.723 262.879 188.723 265.693C188.723 268.791 191.221 270.831 193.833 270.831C195.08 270.831 196.351 270.364 197.388 269.33C200.557 266.083 198.316 260.57 193.791 260.57H193.79Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M193.79 246.363C191.002 246.363 188.723 248.663 188.723 251.477C188.723 254.563 191.221 256.618 193.833 256.618C195.075 256.618 196.351 256.153 197.388 255.106C200.557 251.865 198.316 246.363 193.791 246.363H193.79Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.826 231.355H193.778C190.997 231.355 188.723 233.661 188.723 236.471C188.723 239.564 191.234 241.62 193.85 241.62C195.098 241.62 196.371 241.15 197.405 240.103C200.557 236.875 198.307 231.355 193.826 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.833 227.404C195.08 227.404 196.35 226.942 197.388 225.908C200.557 222.661 198.316 217.148 193.79 217.148C191.002 217.148 188.723 219.453 188.723 222.272C188.723 225.364 191.221 227.404 193.833 227.404H193.833Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.833 212.405C195.08 212.405 196.35 211.974 197.388 211.019C200.557 208.021 198.316 202.93 193.79 202.93C191.002 202.93 188.723 205.062 188.723 207.661C188.723 210.521 191.221 212.405 193.833 212.405H193.833Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.833 198.189C195.075 198.189 196.35 197.723 197.388 196.672C200.557 193.436 198.316 187.934 193.79 187.934C191.002 187.934 188.723 190.233 188.723 193.042C188.723 196.134 191.221 198.189 193.833 198.189H193.833Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.824 183.979C195.075 183.979 196.346 183.508 197.388 182.461C200.557 179.259 198.316 173.719 193.79 173.719C191.002 173.757 188.723 176.023 188.723 178.87C188.723 181.933 191.216 183.979 193.824 183.979H193.824Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.81 168.979C195.061 168.979 196.341 168.543 197.388 167.565C200.557 164.603 198.316 159.508 193.79 159.508C191.002 159.508 188.723 161.602 188.723 164.243C188.723 167.094 191.207 168.979 193.81 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.833 154.765C195.08 154.765 196.35 154.298 197.388 153.263C200.557 150.015 198.316 144.5 193.79 144.5C191.002 144.5 188.723 146.81 188.723 149.625C188.723 152.724 191.221 154.765 193.833 154.765H193.833Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.833 140.545C195.08 140.545 196.35 140.083 197.388 139.048C200.557 135.802 198.316 130.289 193.79 130.289C191.002 130.289 188.723 132.593 188.723 135.407C188.723 138.505 191.221 140.544 193.833 140.544L193.833 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.833 125.549C195.075 125.549 196.35 125.115 197.388 124.149C200.557 121.157 198.316 116.078 193.79 116.078C191.002 116.113 188.723 118.205 188.723 120.798C188.723 123.652 191.221 125.549 193.833 125.549H193.833Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.824 111.333C195.075 111.333 196.346 110.866 197.388 109.81C200.557 106.601 198.316 101.078 193.79 101.078C191.002 101.078 188.723 103.348 188.723 106.211C188.723 109.28 191.216 111.333 193.824 111.333H193.824Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M193.833 97.1241C195.08 97.1241 196.35 96.6573 197.388 95.6226C200.557 92.3743 198.316 86.8594 193.79 86.8594C191.002 86.8594 188.723 89.1695 188.723 91.9847C188.723 95.0837 191.221 97.1241 193.833 97.1241H193.833Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.765 289.785C175.989 289.824 173.749 292.091 173.711 294.939C173.711 298.004 176.202 300.05 178.803 300.05C180.046 300.05 181.318 299.579 182.352 298.532C185.555 295.328 183.277 289.785 178.765 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.765 274.789C175.989 274.789 173.711 277.096 173.711 279.917C173.711 283.008 176.207 285.045 178.803 285.045C180.051 285.045 181.327 284.573 182.352 283.518C185.555 280.307 183.277 274.789 178.765 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.765 260.57C175.989 260.57 173.749 262.879 173.711 265.693C173.711 268.791 176.207 270.831 178.807 270.831C180.051 270.831 181.318 270.364 182.352 269.33C185.555 266.083 183.277 260.57 178.765 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.765 246.363C175.989 246.363 173.711 248.663 173.711 251.477C173.711 254.563 176.202 256.618 178.807 256.618C180.051 256.618 181.318 256.153 182.352 255.106C185.555 251.865 183.277 246.363 178.765 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.8 231.355H178.753C175.983 231.355 173.711 233.661 173.711 236.471C173.711 239.564 176.215 241.62 178.824 241.62C180.069 241.62 181.337 241.15 182.369 240.103C185.555 236.875 183.311 231.355 178.8 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.807 227.404C180.051 227.404 181.318 226.942 182.352 225.908C185.555 222.661 183.277 217.148 178.765 217.148C175.989 217.148 173.711 219.453 173.711 222.272C173.711 225.364 176.207 227.404 178.807 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.807 212.405C180.051 212.405 181.318 211.974 182.352 211.019C185.555 208.021 183.277 202.93 178.765 202.93C175.989 202.93 173.711 205.062 173.711 207.661C173.711 210.521 176.207 212.405 178.807 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.807 198.189C180.051 198.189 181.318 197.723 182.352 196.672C185.555 193.436 183.277 187.934 178.765 187.934C175.989 187.934 173.711 190.233 173.711 193.042C173.711 196.134 176.202 198.189 178.807 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.803 183.979C180.046 183.979 181.318 183.508 182.352 182.461C185.555 179.259 183.277 173.719 178.765 173.719C175.989 173.757 173.711 176.023 173.711 178.87C173.711 181.933 176.202 183.979 178.803 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.784 168.979C180.036 168.979 181.313 168.543 182.352 167.565C185.555 164.603 183.277 159.508 178.765 159.508C175.989 159.508 173.711 161.602 173.711 164.243C173.711 167.094 176.188 168.979 178.784 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.807 154.765C180.051 154.765 181.318 154.298 182.352 153.263C185.555 150.015 183.277 144.5 178.765 144.5C175.989 144.5 173.711 146.81 173.711 149.625C173.711 152.724 176.207 154.765 178.807 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.807 140.545C180.051 140.545 181.318 140.083 182.352 139.048C185.555 135.802 183.277 130.289 178.765 130.289C175.989 130.289 173.711 132.593 173.711 135.407C173.711 138.505 176.207 140.544 178.807 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.807 125.549C180.051 125.549 181.318 125.115 182.352 124.149C185.555 121.157 183.277 116.078 178.765 116.078C175.989 116.078 173.711 118.205 173.711 120.798C173.711 123.652 176.202 125.549 178.807 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M178.803 111.333C180.046 111.333 181.318 110.866 182.352 109.81C185.555 106.601 183.277 101.078 178.765 101.078C175.989 101.078 173.711 103.348 173.711 106.211C173.711 109.28 176.202 111.333 178.803 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.598 260.57C161.78 260.57 159.546 262.879 159.508 265.693C159.508 268.791 162.017 270.831 164.616 270.831C165.859 270.831 167.121 270.364 168.145 269.33C171.342 266.083 169.07 260.57 164.597 260.57H164.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.598 246.363C161.78 246.363 159.546 248.663 159.508 251.477C159.508 254.563 162.017 256.618 164.616 256.618C165.859 256.618 167.121 256.153 168.145 255.106C171.342 251.865 169.07 246.363 164.597 246.363H164.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.633 231.355H164.585C161.774 231.355 159.546 233.661 159.508 236.471C159.508 239.564 162.011 241.62 164.618 241.62C165.863 241.62 167.131 241.15 168.162 240.103C171.342 236.875 169.099 231.355 164.632 231.355H164.633Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.617 227.404C165.859 227.404 167.121 226.942 168.146 225.908C171.343 222.661 169.071 217.148 164.598 217.148C161.78 217.148 159.546 219.453 159.508 222.272C159.508 225.364 162.017 227.404 164.616 227.404H164.617Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.617 212.405C165.859 212.405 167.121 211.974 168.146 211.019C171.343 208.021 169.071 202.93 164.598 202.93C161.78 202.93 159.546 205.062 159.508 207.661C159.508 210.521 162.017 212.405 164.616 212.405H164.617Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.617 198.189C165.859 198.189 167.121 197.723 168.146 196.672C171.343 193.436 169.071 187.934 164.598 187.934C161.78 187.934 159.546 190.233 159.508 193.042C159.508 196.134 162.017 198.189 164.616 198.189H164.617Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.612 183.979C165.855 183.979 167.121 183.508 168.146 182.461C171.343 179.259 169.071 173.719 164.598 173.719C161.78 173.757 159.546 176.023 159.508 178.87C159.508 181.933 162.012 183.979 164.612 183.979H164.612Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.593 168.979C165.845 168.979 167.116 168.543 168.146 167.565C171.343 164.603 169.071 159.508 164.598 159.508C161.78 159.508 159.546 161.602 159.508 164.243C159.508 167.094 162.003 168.979 164.593 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.617 154.765C165.859 154.765 167.121 154.298 168.146 153.263C171.343 150.015 169.071 144.5 164.598 144.5C161.78 144.5 159.546 146.81 159.508 149.625C159.508 152.724 162.017 154.765 164.616 154.765H164.617Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.617 140.545C165.859 140.545 167.121 140.083 168.146 139.048C171.343 135.802 169.071 130.289 164.598 130.289C161.78 130.289 159.546 132.593 159.508 135.407C159.508 138.505 162.017 140.544 164.616 140.544L164.617 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.617 125.549C165.859 125.549 167.121 125.115 168.146 124.149C171.343 121.157 169.071 116.078 164.598 116.078C161.78 116.113 159.546 118.205 159.508 120.798C159.508 123.652 162.017 125.549 164.616 125.549H164.617Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M164.612 111.333C165.855 111.333 167.121 110.866 168.146 109.81C171.343 106.601 169.071 101.078 164.598 101.078C161.78 101.078 159.546 103.348 159.508 106.211C159.508 109.28 162.012 111.333 164.612 111.333H164.612Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.359 202.93C147.536 202.93 145.289 205.062 145.289 207.661C145.289 210.521 147.793 212.405 150.392 212.405C151.63 212.405 152.896 211.974 153.92 211.019C157.133 208.021 154.886 202.93 150.359 202.93H150.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.359 187.934C147.536 187.934 145.289 190.233 145.289 193.042C145.289 196.134 147.788 198.189 150.392 198.189C151.63 198.189 152.896 197.723 153.92 196.672C157.133 193.436 154.886 187.934 150.359 187.934H150.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.359 173.719C147.536 173.757 145.289 176.023 145.289 178.87C145.289 181.933 147.788 183.979 150.383 183.979C151.63 183.979 152.891 183.508 153.92 182.461C157.133 179.259 154.886 173.719 150.359 173.719H150.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.359 159.508C147.536 159.508 145.289 161.602 145.289 164.243C145.289 167.094 147.774 168.979 150.369 168.979C151.616 168.979 152.887 168.543 153.92 167.565C157.133 164.604 154.886 159.508 150.359 159.508V159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.359 144.5C147.536 144.5 145.289 146.81 145.289 149.625C145.289 152.724 147.793 154.765 150.392 154.765C151.63 154.765 152.896 154.298 153.92 153.263C157.133 150.015 154.886 144.5 150.359 144.5H150.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.359 130.289C147.536 130.289 145.289 132.594 145.289 135.407C145.289 138.505 147.793 140.545 150.392 140.545C151.63 140.545 152.896 140.083 153.92 139.048C157.133 135.802 154.886 130.289 150.359 130.289L150.359 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.359 116.078C147.536 116.078 145.289 118.205 145.289 120.798C145.289 123.652 147.788 125.549 150.392 125.549C151.63 125.549 152.896 125.115 153.92 124.149C157.133 121.157 154.886 116.078 150.359 116.078H150.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.359 101.078C147.536 101.078 145.289 103.348 145.289 106.211C145.289 109.28 147.788 111.333 150.383 111.333C151.63 111.333 152.891 110.866 153.92 109.81C157.133 106.601 154.886 101.078 150.359 101.078H150.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.357 187.934C132.568 187.934 130.289 190.233 130.289 193.042C130.289 196.134 132.787 198.189 135.4 198.189C136.642 198.189 137.917 197.723 138.955 196.672C142.124 193.436 139.882 187.934 135.357 187.934H135.357Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.357 173.719C132.568 173.757 130.289 176.023 130.289 178.87C130.289 181.933 132.783 183.979 135.39 183.979C136.642 183.979 137.912 183.508 138.954 182.461C142.123 179.259 139.882 173.719 135.357 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.357 159.504C132.568 159.504 130.289 161.595 130.289 164.233C130.289 167.088 132.787 168.97 135.4 168.97C136.646 168.97 137.917 168.544 138.955 167.59C142.124 164.592 139.882 159.504 135.357 159.504H135.357Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M135.357 144.5C132.568 144.5 130.289 146.81 130.289 149.625C130.289 152.724 132.787 154.765 135.4 154.765C136.646 154.765 137.917 154.298 138.955 153.263C142.124 150.015 139.882 144.5 135.357 144.5H135.357Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.357 130.289C132.568 130.289 130.289 132.594 130.289 135.407C130.289 138.505 132.787 140.545 135.4 140.545C136.646 140.545 137.917 140.083 138.955 139.048C142.124 135.802 139.882 130.289 135.357 130.289L135.357 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.357 116.078C132.568 116.113 130.289 118.205 130.289 120.798C130.289 123.652 132.787 125.549 135.4 125.549C136.642 125.549 137.917 125.115 138.955 124.149C142.124 121.157 139.882 116.078 135.357 116.078H135.357Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.357 101.078C132.568 101.078 130.289 103.348 130.289 106.211C130.289 109.28 132.783 111.333 135.39 111.333C136.642 111.333 137.912 110.866 138.954 109.81C142.123 106.601 139.882 101.078 135.357 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.144 173.719C118.359 173.757 116.074 176.023 116.074 178.87C116.074 181.933 118.573 183.979 121.182 183.979C122.429 183.979 123.705 183.508 124.743 182.461C127.918 179.259 125.672 173.719 121.144 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.144 159.508C118.359 159.508 116.074 161.602 116.074 164.243C116.074 167.094 118.559 168.979 121.163 168.979C122.42 168.979 123.7 168.543 124.743 167.565C127.918 164.604 125.671 159.508 121.144 159.508L121.144 159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.144 144.5C118.359 144.5 116.074 146.81 116.074 149.625C116.074 152.724 118.573 154.765 121.187 154.765C122.434 154.765 123.705 154.298 124.743 153.263C127.918 150.015 125.672 144.5 121.144 144.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.144 130.289C118.359 130.289 116.074 132.594 116.074 135.407C116.074 138.505 118.573 140.545 121.187 140.545C122.434 140.545 123.705 140.083 124.743 139.048C127.918 135.802 125.672 130.289 121.144 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.144 116.078C118.359 116.113 116.074 118.205 116.074 120.798C116.074 123.652 118.573 125.549 121.187 125.549C122.434 125.549 123.705 125.115 124.743 124.149C127.918 121.157 125.672 116.078 121.144 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M121.144 101.078C118.359 101.078 116.074 103.348 116.074 106.211C116.074 109.28 118.573 111.333 121.182 111.333C122.429 111.333 123.705 110.866 124.743 109.81C127.918 106.601 125.672 101.078 121.144 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.915 144.5C104.135 144.5 101.863 146.81 101.863 149.625C101.863 152.724 104.354 154.765 106.958 154.765C108.2 154.765 109.467 154.298 110.501 153.263C113.698 150.015 111.426 144.5 106.915 144.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.915 130.289C104.135 130.289 101.863 132.594 101.863 135.407C101.863 138.505 104.354 140.545 106.958 140.545C108.2 140.545 109.467 140.083 110.501 139.048C113.698 135.802 111.426 130.289 106.915 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.915 116.078C104.135 116.078 101.863 118.205 101.863 120.798C101.863 123.652 104.354 125.549 106.953 125.549C108.196 125.549 109.467 125.115 110.501 124.149C113.698 121.157 111.426 116.078 106.915 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.915 101.078C104.135 101.078 101.863 103.348 101.863 106.211C101.863 109.28 104.349 111.333 106.948 111.333C108.196 111.333 109.462 110.866 110.501 109.81C113.698 106.601 111.426 101.078 106.915 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.9297 144.5C89.1447 144.5 86.8596 146.772 86.8596 149.625C86.8596 152.724 89.3589 154.765 91.9628 154.765C93.2005 154.765 94.4668 154.298 95.4904 153.263C98.7036 150.015 96.4566 144.5 91.9294 144.5H91.9297Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.9294 130.289C89.1445 130.289 86.8594 132.594 86.8594 135.407C86.8594 138.505 89.3586 140.545 91.9625 140.545C93.2002 140.545 94.4666 140.083 95.4901 139.048C98.7033 135.84 96.4564 130.289 91.9291 130.289L91.9294 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.9294 116.078C89.1445 116.078 86.8594 118.205 86.8594 120.798C86.8594 123.652 89.3586 125.549 91.958 125.549C93.2005 125.549 94.4668 125.115 95.4904 124.149C98.7036 121.192 96.4567 116.113 91.9294 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.9294 101.078C89.1445 101.078 86.8594 103.348 86.8594 106.211C86.8594 109.28 89.3586 111.333 91.953 111.333C93.1955 111.333 94.4618 110.866 95.4901 109.81C98.7033 106.601 96.4564 101.078 91.9291 101.078H91.9294Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M77.7162 144.5C74.9276 144.5 72.6484 146.81 72.6484 149.625C72.6484 152.724 75.1468 154.765 77.7592 154.765C79.0011 154.765 80.2764 154.298 81.314 153.263C84.4831 150.015 82.2418 144.5 77.7165 144.5H77.7162Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M77.7162 130.289C74.9276 130.289 72.6484 132.594 72.6484 135.407C72.6484 138.505 75.1468 140.545 77.7592 140.545C79.0011 140.545 80.2764 140.083 81.314 139.048C84.4831 135.802 82.2418 130.289 77.7165 130.289L77.7162 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M77.7162 116.078C74.9276 116.113 72.6484 118.205 72.6484 120.798C72.6484 123.652 75.1468 125.549 77.7544 125.549C79.0011 125.549 80.2764 125.115 81.3137 124.149C84.4829 121.157 82.2415 116.078 77.7162 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M77.7162 101.078C74.9276 101.078 72.6484 103.348 72.6484 106.211C72.6484 109.28 75.1421 111.333 77.7496 111.333C79.0011 111.333 80.2716 110.866 81.3137 109.81C84.4829 106.601 82.2415 101.078 77.7162 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M63.4874 144.5C60.7114 144.5 58.4336 146.81 58.4336 149.625C58.4336 152.724 60.925 154.765 63.53 154.765C64.7733 154.765 66.0402 154.298 67.0747 153.263C70.2778 150.015 68 144.5 63.4874 144.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.4874 130.289C60.7114 130.289 58.4336 132.594 58.4336 135.407C58.4336 138.505 60.925 140.545 63.53 140.545C64.7733 140.545 66.0402 140.083 67.0747 139.048C70.2778 135.802 68 130.289 63.4874 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.4874 116.078C60.7114 116.078 58.4336 118.205 58.4336 120.798C58.4336 123.652 60.925 125.549 63.53 125.549C64.7733 125.549 66.0402 125.115 67.0747 124.149C70.2778 121.157 68 116.078 63.4874 116.078Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.4874 101.078C60.7114 101.078 58.4336 103.348 58.4336 106.211C58.4336 109.28 60.9202 111.333 63.5253 111.333C64.7686 111.333 66.0402 110.866 67.0747 109.81C70.2778 106.601 68 101.078 63.4874 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.5242 144.5C45.7067 144.5 43.4723 146.81 43.4297 149.625C43.4297 152.724 45.9435 154.765 48.543 154.765C49.7857 154.765 51.0475 154.298 52.0672 153.263C55.2689 150.015 52.9967 144.5 48.5239 144.5H48.5242Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.5242 130.289C45.7067 130.289 43.4723 132.594 43.4297 135.407C43.4297 138.505 45.9435 140.545 48.543 140.545C49.7857 140.545 51.0475 140.083 52.0672 139.048C55.2689 135.802 52.9967 130.289 48.5239 130.289L48.5242 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.5242 116.078C45.7067 116.113 43.4723 118.205 43.4297 120.798C43.4297 123.652 45.939 125.549 48.543 125.549C49.7857 125.549 51.0475 125.115 52.0672 124.149C55.2689 121.157 52.9967 116.078 48.5239 116.078H48.5242Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.5242 101.078C45.7067 101.078 43.4723 103.348 43.4297 106.211C43.4297 109.28 45.939 111.333 48.5382 111.333C49.7809 111.333 51.0475 110.866 52.0672 109.81C55.2689 106.601 52.9967 101.078 48.5239 101.078H48.5242Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.32 159.504H34.2725C31.4943 159.504 29.2148 161.634 29.2148 164.239C29.2148 167.09 31.6939 168.975 34.2916 168.975C35.5453 168.975 36.8229 168.539 37.863 167.561C41.0543 164.609 38.8079 159.504 34.3203 159.504L34.32 159.504Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.32 144.504H34.2725C31.4943 144.504 29.2148 146.815 29.2148 149.632C29.2148 152.723 31.713 154.759 34.3107 154.759C35.5596 154.759 36.8324 154.288 37.863 153.233C41.0543 150.036 38.8079 144.504 34.3203 144.504L34.32 144.504Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.3304 140.545C35.5689 140.545 36.8358 140.083 37.856 139.048C41.0591 135.802 38.7813 130.289 34.2686 130.289C31.4926 130.289 29.2148 132.593 29.2148 135.407C29.2148 138.505 31.725 140.544 34.3304 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.856 124.149C41.0591 121.157 38.7813 116.078 34.2686 116.078C31.4926 116.113 29.2148 118.205 29.2148 120.798C29.2148 123.652 31.7062 125.549 34.3113 125.549C35.5546 125.549 36.8215 125.115 37.856 124.149Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.32 101.078H34.2725C31.4943 101.078 29.2148 103.347 29.2148 106.209C29.2148 109.301 31.6939 111.338 34.2916 111.338C35.5453 111.338 36.8229 110.867 37.863 109.807C41.0543 106.569 38.8079 101.078 34.3203 101.078H34.32Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0747 173.719C17.2909 173.757 15.0117 176.023 15.0117 178.87C15.0117 181.933 17.5053 183.979 20.1129 183.979C21.3596 183.979 22.6349 183.508 23.677 182.461C26.8461 179.221 24.6048 173.719 20.0747 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0745 159.504C17.2907 159.504 15.0115 161.598 15.0115 164.239C15.0115 167.09 17.4956 168.975 20.0984 168.975C21.3498 168.975 22.6299 168.539 23.6767 167.561C26.8459 164.6 24.6046 159.504 20.0745 159.504V159.504Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0745 144.5C17.2907 144.5 15.0115 146.81 15.0115 149.625C15.0115 152.724 17.5096 154.765 20.1222 154.765C21.3641 154.765 22.6394 154.298 23.677 153.263C26.8462 150.015 24.6049 144.5 20.0748 144.5H20.0745Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0747 130.289C17.2909 130.289 15.0117 132.594 15.0117 135.407C15.0117 138.505 17.5098 140.545 20.1225 140.545C21.3644 140.545 22.6397 140.083 23.6772 139.048C26.8464 135.802 24.6051 130.289 20.075 130.289L20.0747 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0747 116.078C17.2909 116.113 15.0117 118.205 15.0117 120.798C15.0117 123.652 17.5098 125.549 20.1177 125.549C21.3644 125.549 22.6397 125.115 23.677 124.149C26.8461 121.157 24.6048 116.078 20.0747 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.1286 101.078H20.0809C17.2937 101.078 15.0117 103.348 15.0117 106.211C15.0117 109.28 17.5082 111.333 20.1191 111.333C21.3674 111.333 22.6442 110.866 23.6876 109.81C26.8512 106.61 24.6309 101.078 20.1286 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.07005 173.719C2.2851 173.757 0 176.023 0 178.87C0 181.933 2.49447 183.979 5.10794 183.979C6.35517 183.979 7.63103 183.508 8.6689 182.461C11.8442 179.259 9.59731 173.719 5.07005 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.07005 144.5C2.2851 144.5 0 146.81 0 149.625C0 152.724 2.49924 154.765 5.11271 154.765C6.35994 154.765 7.63103 154.298 8.6689 153.263C11.8442 150.015 9.59731 144.5 5.07005 144.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.07005 130.289C2.2851 130.289 0 132.594 0 135.407C0 138.505 2.49924 140.545 5.11271 140.545C6.35994 140.545 7.63103 140.083 8.6689 139.048C11.8442 135.802 9.59731 130.289 5.07005 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.07005 116.078C2.2851 116.113 0 118.205 0 120.798C0 123.652 2.49924 125.549 5.11271 125.549C6.35994 125.549 7.63103 125.115 8.6689 124.149C11.8442 121.157 9.59731 116.078 5.07005 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 458.764C1107.08 458.764 1108.34 458.297 1109.36 457.263C1112.56 454.017 1110.28 448.504 1105.81 448.504C1103 448.504 1100.76 450.808 1100.72 453.627C1100.72 456.72 1103.23 458.764 1105.83 458.764H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 444.55C1107.08 444.55 1108.34 444.08 1109.36 443.032C1112.56 439.828 1110.28 434.285 1105.81 434.285C1103 434.285 1100.76 436.591 1100.72 439.401C1100.72 442.494 1103.23 444.55 1105.83 444.55H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.79 415.335C1122.04 415.335 1123.32 414.858 1124.36 413.803C1127.56 410.591 1125.29 405.07 1120.77 405.07C1118 405.07 1115.76 407.344 1115.72 410.2C1115.72 413.293 1118.2 415.335 1120.79 415.335L1120.79 415.335Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 401.123C1122.06 401.123 1123.33 400.657 1124.36 399.623C1127.56 396.376 1125.29 390.863 1120.77 390.863C1118 390.863 1115.72 393.167 1115.72 395.986C1115.72 399.079 1118.21 401.123 1120.82 401.123Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 401.123C1092.85 401.123 1094.11 400.657 1095.14 399.623C1098.35 396.376 1096.1 390.863 1091.58 390.863C1088.75 390.863 1086.51 393.167 1086.51 395.986C1086.51 399.079 1089.01 401.123 1091.6 401.123Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 371.904C1092.85 371.904 1094.11 371.438 1095.14 370.391C1098.35 367.15 1096.1 361.648 1091.58 361.648C1088.75 361.687 1086.51 363.948 1086.51 366.762C1086.51 369.849 1089.01 371.904 1091.6 371.904Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 448.504C1074.58 448.504 1072.29 450.808 1072.29 453.627C1072.29 456.72 1074.8 458.764 1077.4 458.764C1078.65 458.764 1079.92 458.297 1080.96 457.263C1084.13 454.017 1081.89 448.504 1077.36 448.504H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 434.285C1074.58 434.285 1072.29 436.591 1072.29 439.401C1072.29 442.494 1074.8 444.55 1077.4 444.55C1078.65 444.55 1079.92 444.079 1080.96 443.032C1084.13 439.828 1081.89 434.285 1077.36 434.285H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 420.078C1074.58 420.117 1072.29 422.378 1072.29 425.23C1072.29 428.293 1074.79 430.333 1077.4 430.333C1078.65 430.333 1079.92 429.867 1080.96 428.821C1084.13 425.619 1081.89 420.078 1077.36 420.078H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.4 458.759C1063.64 458.759 1064.9 458.293 1065.93 457.247C1069.14 454.006 1066.86 448.504 1062.34 448.504C1059.56 448.504 1057.32 450.804 1057.32 453.617C1057.3 456.704 1059.8 458.759 1062.4 458.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.34 434.285C1059.56 434.324 1057.32 436.591 1057.32 439.401C1057.3 442.494 1059.8 444.55 1062.4 444.55C1063.64 444.55 1064.9 444.079 1065.93 443.032C1069.14 439.79 1066.86 434.285 1062.34 434.285Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 487.974C1107.07 487.974 1108.34 487.506 1109.36 486.456C1112.56 483.241 1110.28 477.719 1105.81 477.719C1103 477.719 1100.76 479.989 1100.72 482.851C1100.72 485.926 1103.23 487.974 1105.83 487.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 487.974C1092.84 487.974 1094.11 487.506 1095.14 486.456C1098.35 483.241 1096.1 477.719 1091.58 477.719C1088.75 477.719 1086.51 479.989 1086.51 482.851C1086.51 485.926 1089 487.974 1091.6 487.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 477.719C1074.58 477.719 1072.29 479.989 1072.29 482.851C1072.29 485.926 1074.79 487.974 1077.4 487.974C1078.65 487.974 1079.92 487.506 1080.96 486.456C1084.13 483.241 1081.89 477.719 1077.36 477.719H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.4 477.719H1062.35C1059.57 477.719 1057.29 480.025 1057.33 482.847C1057.33 485.937 1059.81 487.974 1062.41 487.974C1063.66 487.974 1064.94 487.502 1065.98 486.448C1069.13 483.208 1066.88 477.719 1062.4 477.719H1062.4Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.2 477.719H1048.15C1045.32 477.719 1043.07 480.025 1043.07 482.847C1043.07 485.937 1045.57 487.974 1048.17 487.974C1049.43 487.974 1050.71 487.502 1051.75 486.448C1054.92 483.208 1052.66 477.719 1048.2 477.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1048.18 473.76C1049.42 473.76 1050.68 473.298 1051.7 472.259C1054.91 469.013 1052.63 463.5 1048.14 463.5C1045.32 463.5 1043.07 465.809 1043.07 468.623C1043.07 471.721 1045.58 473.76 1048.18 473.76H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.93 463.5C1031.1 463.5 1028.86 465.809 1028.86 468.623C1028.86 471.721 1031.36 473.76 1033.96 473.76C1035.2 473.76 1036.46 473.298 1037.49 472.259C1040.7 469.013 1038.46 463.5 1033.93 463.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.94 448.504C1031.12 448.504 1028.89 450.808 1028.89 453.627C1028.86 456.72 1031.37 458.764 1033.97 458.764C1035.21 458.764 1036.47 458.297 1037.5 457.263C1040.7 454.017 1038.46 448.504 1033.94 448.504Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.95 448.504C1016.17 448.504 1013.89 450.808 1013.89 453.627C1013.87 456.72 1016.37 458.764 1018.97 458.764C1020.22 458.764 1021.48 458.297 1022.5 457.263C1025.71 454.055 1023.47 448.504 1018.95 448.504H1018.95Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.95 434.285C1016.17 434.285 1013.89 436.591 1013.89 439.401C1013.87 442.494 1016.35 444.55 1018.95 444.55C1020.2 444.55 1021.47 444.079 1022.5 443.032C1025.71 439.828 1023.46 434.285 1018.95 434.285Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.73 434.285C1001.95 434.285 999.672 436.591 999.672 439.401C999.643 442.494 1002.13 444.55 1004.73 444.55C1005.97 444.55 1007.25 444.079 1008.28 443.032C1011.48 439.828 1009.25 434.324 1004.73 434.285Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.73 420.078C1001.95 420.078 999.672 422.376 999.672 425.187C999.643 428.28 1002.15 430.338 1004.76 430.338C1005.99 430.338 1007.25 429.878 1008.28 428.851C1011.48 425.614 1009.25 420.116 1004.73 420.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.94 420.078C1031.17 420.117 1028.89 422.378 1028.89 425.191C1028.86 428.278 1031.35 430.333 1033.95 430.333C1035.19 430.333 1036.46 429.867 1037.5 428.821C1040.7 425.619 1038.46 420.078 1033.94 420.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 415.331C1020.21 415.331 1021.47 414.869 1022.49 413.83C1025.7 410.583 1023.46 405.07 1018.93 405.07C1016.11 405.07 1013.86 407.341 1013.86 410.193C1013.86 413.291 1016.36 415.331 1018.96 415.331H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 390.863C1001.93 390.863 999.648 393.167 999.648 395.986C999.648 399.079 1002.15 401.123 1004.76 401.123C1006.01 401.123 1007.28 400.657 1008.31 399.623C1011.48 396.376 1009.24 390.863 1004.72 390.863H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 405.07C784.785 405.07 782.546 407.341 782.508 410.193C782.508 413.291 785.017 415.331 787.621 415.331C788.859 415.331 790.126 414.869 791.145 413.83C794.347 410.583 792.07 405.07 787.597 405.07H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 420.078C784.785 420.117 782.546 422.378 782.508 425.23C782.508 428.293 785.012 430.333 787.612 430.333C788.854 430.333 790.121 429.867 791.145 428.821C794.347 425.619 792.07 420.078 787.597 420.078H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 333.219C712.104 333.219 709.863 335.523 709.863 338.342C709.863 341.435 712.362 343.479 714.96 343.479C716.202 343.479 717.463 343.012 718.486 341.978C721.698 338.731 719.457 333.219 714.927 333.219H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 347.43C712.104 347.43 709.863 349.703 709.863 352.56C709.863 355.653 712.347 357.695 714.936 357.695C716.183 357.695 717.458 357.218 718.486 356.163C721.698 352.95 719.456 347.43 714.926 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 361.648C712.104 361.648 709.863 363.948 709.863 366.762C709.863 369.849 712.362 371.904 714.96 371.904C716.197 371.904 717.463 371.438 718.486 370.391C721.698 367.189 719.457 361.648 714.927 361.648H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 376.645C712.104 376.645 709.863 378.95 709.863 381.76C709.863 384.853 712.362 386.91 714.96 386.91C716.197 386.91 717.463 386.439 718.486 385.392C721.698 382.149 719.457 376.645 714.927 376.645H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 390.863C712.104 390.863 709.863 393.167 709.863 395.986C709.863 399.079 712.362 401.123 714.96 401.123C716.202 401.123 717.463 400.657 718.486 399.623C721.698 396.376 719.457 390.863 714.927 390.863H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 405.07C712.104 405.07 709.863 407.341 709.863 410.193C709.863 413.291 712.362 415.331 714.96 415.331C716.202 415.331 717.463 414.869 718.486 413.83C721.698 410.583 719.457 405.07 714.927 405.07H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 420.078C712.104 420.117 709.863 422.378 709.863 425.23C709.863 428.293 712.357 430.333 714.955 430.333C716.197 430.333 717.463 429.867 718.486 428.821C721.698 425.619 719.457 420.078 714.927 420.078H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 434.285C712.104 434.285 709.863 436.591 709.863 439.401C709.863 442.494 712.362 444.55 714.96 444.55C716.197 444.55 717.463 444.079 718.486 443.032C721.698 439.828 719.457 434.285 714.927 434.285H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 448.504C712.104 448.504 709.863 450.808 709.863 453.627C709.863 456.72 712.362 458.764 714.96 458.764C716.202 458.764 717.463 458.297 718.486 457.263C721.698 454.017 719.457 448.504 714.927 448.504H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 463.5C712.104 463.5 709.863 465.77 709.863 468.623C709.863 471.721 712.362 473.76 714.96 473.76C716.202 473.76 717.463 473.298 718.486 472.259C721.698 469.013 719.457 463.5 714.927 463.5H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 477.719C712.104 477.719 709.863 479.989 709.863 482.851C709.863 485.926 712.357 487.974 714.955 487.974C716.197 487.974 717.463 487.506 718.486 486.456C721.698 483.241 719.457 477.719 714.927 477.719H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 491.93C712.104 491.968 709.863 494.235 709.863 497.084C709.863 500.148 712.357 502.195 714.955 502.195C716.197 502.195 717.463 501.724 718.486 500.677C721.698 497.473 719.457 491.93 714.927 491.93H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 506.934C712.104 506.934 709.863 509.233 709.863 512.047C709.863 515.134 712.362 517.189 714.96 517.189C716.197 517.189 717.463 516.723 718.486 515.676C721.698 512.436 719.457 506.934 714.927 506.934H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.953 531.406C716.196 531.406 717.462 530.935 718.501 529.888C721.698 526.645 719.426 521.141 714.91 521.141C712.098 521.141 709.863 523.446 709.863 526.256C709.863 529.35 712.354 531.406 714.953 531.406Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 536.148C712.104 536.148 709.863 538.244 709.863 540.886C709.863 543.724 712.357 545.615 714.955 545.615C716.197 545.615 717.463 545.183 718.486 544.214C721.698 541.247 719.457 536.148 714.927 536.148H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 564.574C712.104 564.574 709.863 566.878 709.863 569.697C709.863 572.795 712.362 574.834 714.96 574.834C716.202 574.834 717.463 574.368 718.486 573.333C721.698 570.087 719.457 564.574 714.927 564.574H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.985 343.479C731.223 343.479 732.489 343.012 733.509 341.978C736.711 338.731 734.434 333.219 729.961 333.219C727.148 333.219 724.909 335.523 724.871 338.342C724.871 341.435 727.38 343.479 729.984 343.479H729.985Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.961 357.695C731.208 357.695 732.479 357.218 733.509 356.163C736.711 352.95 734.434 347.43 729.961 347.43C727.148 347.43 724.909 349.703 724.871 352.56C724.871 355.653 727.366 357.695 729.961 357.695Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.98 371.904C731.223 371.904 732.489 371.438 733.509 370.391C736.711 367.15 734.434 361.648 729.961 361.648C727.148 361.687 724.909 363.948 724.871 366.762C724.871 369.849 727.38 371.904 729.98 371.904H729.98Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.98 386.91C731.223 386.91 732.489 386.439 733.509 385.392C736.711 382.149 734.434 376.645 729.961 376.645C727.148 376.645 724.909 378.95 724.871 381.76C724.871 384.853 727.38 386.91 729.98 386.91H729.98Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.985 401.123C731.223 401.123 732.489 400.657 733.509 399.623C736.711 396.376 734.434 390.863 729.961 390.863C727.148 390.863 724.909 393.167 724.871 395.986C724.871 399.079 727.38 401.123 729.984 401.123H729.985Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.961 415.335C731.208 415.335 732.479 414.858 733.509 413.803C736.711 410.591 734.434 405.07 729.961 405.07C727.148 405.07 724.909 407.344 724.871 410.2C724.871 413.293 727.366 415.335 729.961 415.335V415.335Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.975 430.333C731.218 430.333 732.484 429.867 733.509 428.821C736.711 425.619 734.434 420.078 729.961 420.078C727.148 420.117 724.909 422.378 724.871 425.23C724.871 428.293 727.376 430.333 729.975 430.333H729.975Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.966 444.55C731.208 444.55 732.475 444.08 733.509 443.032C736.711 439.828 734.434 434.285 729.961 434.285C727.148 434.324 724.909 436.591 724.871 439.401C724.871 442.494 727.361 444.55 729.966 444.55Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.985 458.764C731.223 458.764 732.489 458.297 733.509 457.263C736.711 454.017 734.434 448.504 729.961 448.504C727.148 448.504 724.909 450.808 724.871 453.627C724.871 456.72 727.38 458.764 729.984 458.764H729.985Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.985 473.76C731.223 473.76 732.489 473.298 733.509 472.259C736.711 469.013 734.434 463.5 729.961 463.5C727.148 463.5 724.909 465.809 724.871 468.623C724.871 471.721 727.38 473.76 729.984 473.76H729.985Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.975 487.974C731.218 487.974 732.484 487.506 733.509 486.456C736.711 483.241 734.434 477.719 729.961 477.719C727.148 477.719 724.909 479.989 724.871 482.851C724.871 485.926 727.376 487.974 729.975 487.974H729.975Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.975 502.195C731.218 502.195 732.484 501.724 733.509 500.677C736.711 497.473 734.434 491.93 729.961 491.93C727.148 491.968 724.909 494.235 724.871 497.084C724.871 500.148 727.376 502.195 729.975 502.195H729.975Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.98 517.189C731.223 517.189 732.489 516.723 733.509 515.676C736.711 512.436 734.434 506.934 729.961 506.934C727.148 506.934 724.909 509.233 724.871 512.047C724.871 515.134 727.38 517.189 729.98 517.189H729.98Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 333.219C741.351 333.219 739.074 335.523 739.074 338.342C739.074 341.435 741.569 343.479 744.169 343.479C745.411 343.479 746.683 343.012 747.712 341.978C750.914 338.731 748.642 333.219 744.126 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 347.43C741.351 347.43 739.074 349.703 739.074 352.56C739.074 355.653 741.555 357.695 744.15 357.695C745.397 357.695 746.673 357.218 747.712 356.163C750.914 352.95 748.642 347.43 744.126 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 361.648C741.351 361.648 739.074 363.948 739.074 366.762C739.074 369.849 741.569 371.904 744.169 371.904C745.411 371.904 746.678 371.438 747.712 370.391C750.914 367.189 748.642 361.648 744.126 361.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 376.645C741.351 376.645 739.074 378.95 739.074 381.76C739.074 384.853 741.569 386.91 744.169 386.91C745.411 386.91 746.678 386.439 747.712 385.392C750.914 382.149 748.642 376.645 744.126 376.645Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 390.863C741.351 390.863 739.074 393.167 739.074 395.986C739.074 399.079 741.569 401.123 744.169 401.123C745.411 401.123 746.683 400.657 747.712 399.623C750.914 396.376 748.642 390.863 744.126 390.863Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 405.07C741.351 405.07 739.074 407.341 739.074 410.193C739.074 413.291 741.569 415.331 744.169 415.331C745.411 415.331 746.683 414.869 747.712 413.83C750.914 410.583 748.642 405.07 744.126 405.07Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 420.078C741.351 420.117 739.074 422.378 739.074 425.23C739.074 428.293 741.564 430.333 744.164 430.333C745.407 430.333 746.678 429.867 747.712 428.821C750.914 425.619 748.642 420.078 744.126 420.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 434.285C741.351 434.285 739.074 436.591 739.074 439.401C739.074 442.494 741.569 444.55 744.169 444.55C745.411 444.55 746.678 444.079 747.712 443.032C750.914 439.828 748.642 434.285 744.126 434.285Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 448.504C741.351 448.504 739.074 450.808 739.074 453.627C739.074 456.72 741.569 458.764 744.169 458.764C745.411 458.764 746.683 458.297 747.712 457.263C750.914 454.017 748.642 448.504 744.126 448.504Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M744.126 463.5C741.351 463.5 739.074 465.77 739.074 468.623C739.074 471.721 741.569 473.76 744.169 473.76C745.411 473.76 746.683 473.298 747.712 472.259C750.914 469.013 748.642 463.5 744.126 463.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 477.719C741.351 477.719 739.074 479.989 739.074 482.851C739.074 485.926 741.564 487.974 744.164 487.974C745.407 487.974 746.678 487.506 747.712 486.456C750.914 483.241 748.642 477.719 744.126 477.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 491.93C741.351 491.968 739.074 494.235 739.074 497.084C739.074 500.148 741.564 502.195 744.164 502.195C745.407 502.195 746.678 501.724 747.712 500.677C750.914 497.473 748.642 491.93 744.126 491.93Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 376.645C755.574 376.645 753.289 378.95 753.289 381.76C753.289 384.853 755.788 386.91 758.402 386.91C759.649 386.91 760.92 386.439 761.958 385.392C765.133 382.149 762.886 376.645 758.359 376.645Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 390.863C755.574 390.863 753.289 393.167 753.289 395.986C753.289 399.079 755.788 401.123 758.402 401.123C759.649 401.123 760.92 400.657 761.958 399.623C765.133 396.376 762.886 390.863 758.359 390.863Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 405.071C755.574 405.071 753.289 407.344 753.289 410.201C753.289 413.293 755.774 415.335 758.378 415.335C759.635 415.335 760.915 414.858 761.958 413.803C765.133 410.591 762.886 405.07 758.359 405.07V405.071Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 420.078C755.574 420.117 753.289 422.378 753.289 425.23C753.289 428.293 755.784 430.333 758.397 430.333C759.644 430.333 760.92 429.867 761.958 428.821C765.133 425.619 762.886 420.078 758.359 420.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.369 376.645C770.542 376.645 768.301 378.95 768.301 381.76C768.301 384.853 770.799 386.91 773.407 386.91C774.653 386.91 775.929 386.439 776.966 385.392C780.135 382.149 777.894 376.645 773.369 376.645Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 401.123C774.631 401.123 775.892 400.657 776.916 399.623C780.128 396.376 777.886 390.863 773.361 390.863C770.534 390.863 768.293 393.167 768.293 395.986C768.293 399.079 770.791 401.123 773.389 401.123Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.366 415.335C774.612 415.335 775.887 414.858 776.916 413.803C780.128 410.591 777.886 405.07 773.361 405.07C770.534 405.07 768.293 407.344 768.293 410.2C768.293 413.293 770.777 415.335 773.366 415.335V415.335Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.369 420.078C770.542 420.117 768.301 422.378 768.301 425.23C768.301 428.293 770.794 430.333 773.402 430.333C774.653 430.333 775.924 429.867 776.966 428.821C780.135 425.619 777.894 420.078 773.369 420.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.393 444.55C774.637 444.55 775.9 444.08 776.922 443.032C780.127 439.828 777.891 434.285 773.374 434.285C770.591 434.324 768.317 436.591 768.317 439.401C768.288 442.494 770.791 444.55 773.393 444.55H773.393Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 434.285C755.574 434.285 753.289 436.591 753.289 439.401C753.289 442.494 755.788 444.55 758.402 444.55C759.649 444.55 760.92 444.079 761.958 443.032C765.133 439.828 762.886 434.285 758.359 434.285Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 448.504C755.574 448.504 753.289 450.808 753.289 453.627C753.289 456.72 755.788 458.764 758.402 458.764C759.649 458.764 760.92 458.297 761.958 457.263C765.133 454.017 762.886 448.504 758.359 448.504Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 463.5C755.574 463.5 753.289 465.809 753.289 468.623C753.289 471.721 755.788 473.76 758.402 473.76C759.649 473.76 760.92 473.298 761.958 472.259C765.133 469.013 762.886 463.5 758.359 463.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 477.719C755.574 477.719 753.289 479.989 753.289 482.851C753.289 485.926 755.784 487.974 758.397 487.974C759.644 487.974 760.92 487.506 761.958 486.456C765.133 483.241 762.886 477.719 758.359 477.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 491.93C755.574 491.968 753.289 494.235 753.289 497.084C753.289 500.148 755.784 502.195 758.397 502.195C759.644 502.195 760.92 501.724 761.958 500.677C765.133 497.473 762.886 491.93 758.359 491.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 506.934C755.574 506.934 753.289 509.233 753.289 512.047C753.289 515.134 755.788 517.189 758.402 517.189C759.649 517.189 760.92 516.723 761.958 515.676C765.133 512.436 762.886 506.934 758.359 506.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 506.934C741.351 506.934 739.074 509.233 739.074 512.047C739.074 515.134 741.569 517.189 744.169 517.189C745.411 517.189 746.678 516.723 747.712 515.676C750.914 512.436 748.642 506.934 744.126 506.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 521.141C741.351 521.141 739.074 523.446 739.074 526.256C739.074 529.349 741.584 531.406 744.187 531.406C745.43 531.406 746.692 530.935 747.712 529.888C750.913 526.645 748.642 521.141 744.126 521.141H744.126Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.961 521.141C727.148 521.141 724.909 523.446 724.871 526.256C724.871 529.349 727.38 531.406 729.994 531.406C731.241 531.406 732.512 530.935 733.547 529.888C736.711 526.645 734.472 521.141 729.961 521.141Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.961 536.148C727.148 536.148 724.909 538.244 724.871 540.886C724.871 543.724 727.376 545.615 729.975 545.615C731.218 545.615 732.484 545.183 733.509 544.214C736.71 541.247 734.434 536.148 729.961 536.148H729.961Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.961 564.574C727.148 564.574 724.909 566.878 724.871 569.697C724.871 572.795 727.38 574.834 729.984 574.834C731.222 574.834 732.489 574.368 733.509 573.333C736.71 570.087 734.434 564.574 729.961 564.574H729.961Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.961 550.359C727.148 550.398 724.909 552.665 724.871 555.475C724.871 558.569 727.38 560.624 729.98 560.624C731.222 560.624 732.489 560.154 733.509 559.107C736.71 555.864 734.434 550.359 729.961 550.359H729.961Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 550.359C712.104 550.359 709.863 552.665 709.863 555.475C709.863 558.569 712.362 560.624 714.96 560.624C716.197 560.624 717.463 560.154 718.486 559.107C721.698 555.907 719.457 550.359 714.927 550.359H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 304.004C639.502 304.042 637.223 306.304 637.223 309.155C637.223 312.218 639.716 314.259 642.324 314.259C643.575 314.259 644.846 313.793 645.888 312.747C649.057 309.506 646.816 304.004 642.29 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 318.211C639.502 318.211 637.223 320.52 637.223 323.334C637.223 326.432 639.721 328.471 642.333 328.471C643.58 328.471 644.851 328.004 645.888 326.97C649.057 323.724 646.816 318.211 642.291 318.211H642.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 333.219C639.502 333.219 637.223 335.523 637.223 338.342C637.223 341.435 639.721 343.479 642.333 343.479C643.58 343.479 644.851 343.012 645.888 341.978C649.057 338.731 646.816 333.219 642.291 333.219H642.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 347.43C639.502 347.43 637.223 349.703 637.223 352.56C637.223 355.653 639.707 357.695 642.31 357.695C643.561 357.695 644.841 357.218 645.888 356.163C649.057 352.95 646.816 347.43 642.29 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M642.29 361.648C639.502 361.687 637.223 363.948 637.223 366.762C637.223 369.849 639.721 371.904 642.333 371.904C643.58 371.904 644.851 371.438 645.888 370.391C649.057 367.15 646.816 361.648 642.291 361.648H642.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 376.645C639.502 376.645 637.223 378.95 637.223 381.76C637.223 384.853 639.721 386.91 642.333 386.91C643.58 386.91 644.851 386.439 645.888 385.392C649.057 382.149 646.816 376.645 642.291 376.645H642.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 390.863C639.502 390.863 637.223 393.167 637.223 395.986C637.223 399.079 639.721 401.123 642.333 401.123C643.58 401.123 644.851 400.657 645.888 399.623C649.057 396.376 646.816 390.863 642.291 390.863H642.29Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M584.65 347.43C581.861 347.43 579.582 349.703 579.582 352.56C579.582 355.653 582.066 357.695 584.669 357.695C585.92 357.695 587.2 357.218 588.247 356.163C591.416 352.95 589.175 347.43 584.65 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M584.65 361.648C581.861 361.687 579.582 363.948 579.582 366.762C579.582 369.849 582.08 371.904 584.693 371.904C585.935 371.904 587.21 371.438 588.247 370.391C591.416 367.15 589.175 361.648 584.65 361.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M584.65 376.645C581.861 376.645 579.582 378.95 579.582 381.76C579.582 384.853 582.08 386.91 584.693 386.91C585.935 386.91 587.21 386.439 588.247 385.392C591.416 382.149 589.175 376.645 584.65 376.645Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.889 343.479C600.126 343.479 601.393 343.012 602.416 341.978C605.63 338.731 603.382 333.219 598.855 333.219C596.032 333.219 593.785 335.523 593.785 338.342C593.785 341.435 596.289 343.479 598.888 343.479H598.889Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.855 347.43C596.032 347.43 593.785 349.703 593.785 352.56C593.785 355.653 596.275 357.695 598.874 357.695C600.131 357.695 601.412 357.218 602.454 356.163C605.629 352.95 603.382 347.43 598.855 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.889 371.904C600.126 371.904 601.393 371.438 602.416 370.391C605.63 367.15 603.382 361.648 598.855 361.648C596.032 361.687 593.785 363.948 593.785 366.762C593.785 369.849 596.284 371.904 598.888 371.904H598.889Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.855 376.645C596.032 376.645 593.785 378.95 593.785 381.76C593.785 384.853 596.284 386.91 598.898 386.91C600.145 386.91 601.416 386.439 602.454 385.392C605.629 382.149 603.382 376.645 598.855 376.645Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.855 390.863C596.032 390.863 593.785 393.167 593.785 395.986C593.785 399.079 596.289 401.123 598.888 401.123C600.126 401.123 601.392 400.657 602.416 399.623C605.629 396.376 603.382 390.863 598.855 390.863H598.855Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.887 304.004C611.069 304.042 608.835 306.304 608.797 309.155C608.797 312.218 611.301 314.259 613.901 314.259C615.144 314.259 616.41 313.793 617.435 312.747C620.632 309.506 618.36 304.004 613.887 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.887 318.211C611.069 318.211 608.835 320.52 608.797 323.334C608.797 326.432 611.306 328.471 613.905 328.471C615.148 328.471 616.41 328.004 617.434 326.97C620.631 323.724 618.359 318.211 613.886 318.211H613.887Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M613.887 333.219C611.069 333.219 608.835 335.523 608.797 338.342C608.797 341.435 611.306 343.479 613.905 343.479C615.148 343.479 616.41 343.012 617.434 341.978C620.631 338.731 618.359 333.219 613.886 333.219H613.887Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.887 347.43C611.069 347.43 608.835 349.703 608.797 352.56C608.797 355.653 611.292 357.695 613.882 357.695C615.134 357.695 616.405 357.218 617.435 356.163C620.632 352.95 618.36 347.43 613.887 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.887 361.645C611.069 361.683 608.835 363.944 608.797 366.758C608.797 369.845 611.306 371.9 613.905 371.9C615.148 371.9 616.41 371.434 617.434 370.387C620.631 367.147 618.359 361.645 613.886 361.645H613.887Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.887 376.645C611.069 376.645 608.835 378.95 608.797 381.76C608.797 384.853 611.306 386.91 613.905 386.91C615.148 386.91 616.41 386.439 617.434 385.392C620.631 382.149 618.359 376.645 613.886 376.645H613.887Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.887 390.863C611.069 390.863 608.835 393.167 608.797 395.986C608.797 399.079 611.306 401.123 613.905 401.123C615.148 401.123 616.41 400.657 617.434 399.623C620.631 396.376 618.359 390.863 613.886 390.863H613.887Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 304.004C625.284 304.042 623.008 306.304 623.008 309.155C623.008 312.218 625.498 314.259 628.098 314.259C629.34 314.259 630.611 313.793 631.646 312.747C634.847 309.506 632.571 304.004 628.06 304.004H628.059Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 318.211C625.284 318.211 623.008 320.52 623.008 323.334C623.008 326.432 625.503 328.471 628.102 328.471C629.345 328.471 630.612 328.004 631.646 326.97C634.847 323.724 632.571 318.211 628.06 318.211H628.059Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 333.219C625.284 333.219 623.008 335.523 623.008 338.342C623.008 341.435 625.503 343.479 628.102 343.479C629.345 343.479 630.612 343.012 631.646 341.978C634.847 338.731 632.571 333.219 628.06 333.219H628.059Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 347.43C625.284 347.43 623.008 349.703 623.008 352.56C623.008 355.653 625.484 357.695 628.078 357.695C629.331 357.695 630.607 357.218 631.646 356.163C634.847 352.95 632.571 347.43 628.06 347.43H628.059Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 361.648C625.284 361.687 623.008 363.948 623.008 366.762C623.008 369.849 625.498 371.904 628.102 371.904C629.345 371.904 630.612 371.438 631.646 370.391C634.847 367.15 632.571 361.648 628.06 361.648H628.059Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 376.645C625.284 376.645 623.008 378.95 623.008 381.76C623.008 384.853 625.498 386.91 628.102 386.91C629.345 386.91 630.612 386.439 631.646 385.392C634.847 382.149 632.571 376.645 628.06 376.645H628.059Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 390.863C625.284 390.863 623.008 393.167 623.008 395.986C623.008 399.079 625.503 401.123 628.102 401.123C629.345 401.123 630.612 400.657 631.646 399.623C634.847 396.376 632.571 390.863 628.06 390.863H628.059Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 405.071C625.284 405.071 623.008 407.344 623.008 410.201C623.008 413.293 625.484 415.335 628.078 415.335C629.331 415.335 630.607 414.858 631.646 413.803C634.847 410.591 632.571 405.07 628.06 405.07L628.059 405.071Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 405.071C639.502 405.071 637.223 407.344 637.223 410.201C637.223 413.293 639.707 415.335 642.31 415.335C643.561 415.335 644.841 414.858 645.888 413.803C649.057 410.591 646.816 405.07 642.29 405.07V405.071Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 304.004C654.504 304.042 652.219 306.304 652.219 309.155C652.219 312.218 654.718 314.259 657.327 314.259C658.574 314.259 659.85 313.793 660.888 312.747C664.063 309.506 661.816 304.004 657.289 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 318.211C654.504 318.211 652.219 320.52 652.219 323.334C652.219 326.432 654.723 328.471 657.331 328.471C658.579 328.471 659.85 328.004 660.888 326.97C664.063 323.724 661.816 318.211 657.289 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 333.219C654.504 333.219 652.219 335.523 652.219 338.342C652.219 341.435 654.723 343.479 657.331 343.479C658.579 343.479 659.85 343.012 660.888 341.978C664.063 338.731 661.816 333.219 657.289 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 361.648C654.504 361.687 652.219 363.948 652.219 366.762C652.219 369.849 654.723 371.904 657.331 371.904C658.579 371.904 659.85 371.438 660.888 370.391C664.063 367.15 661.816 361.648 657.289 361.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 376.645C654.504 376.645 652.219 378.95 652.219 381.76C652.219 384.853 654.723 386.91 657.331 386.91C658.579 386.91 659.85 386.439 660.888 385.392C664.063 382.149 661.816 376.645 657.289 376.645Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 390.863C654.504 390.863 652.219 393.167 652.219 395.986C652.219 399.079 654.723 401.123 657.331 401.123C658.579 401.123 659.85 400.657 660.888 399.623C664.063 396.376 661.816 390.863 657.289 390.863Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 405.071C654.504 405.071 652.219 407.344 652.219 410.201C652.219 413.293 654.708 415.335 657.308 415.335C658.565 415.335 659.845 414.858 660.888 413.803C664.063 410.591 661.816 405.07 657.289 405.07V405.071Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.525 314.259C672.767 314.259 674.033 313.793 675.061 312.747C678.273 309.545 676.027 304.042 671.501 304.004C668.718 304.004 666.434 306.304 666.434 309.155C666.434 312.218 668.927 314.259 671.525 314.259H671.525Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 328.471C672.772 328.471 674.033 328.004 675.061 326.97C678.273 323.767 676.027 318.211 671.501 318.211C668.718 318.211 666.434 320.52 666.434 323.334C666.434 326.432 668.932 328.471 671.53 328.471H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 343.479C672.772 343.479 674.033 343.012 675.061 341.978C678.273 338.731 676.027 333.219 671.501 333.219C668.718 333.219 666.434 335.484 666.434 338.342C666.434 341.435 668.932 343.479 671.53 343.479H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 371.904C672.772 371.904 674.033 371.438 675.061 370.391C678.273 367.189 676.027 361.687 671.501 361.648C668.718 361.648 666.434 363.948 666.434 366.762C666.434 369.849 668.932 371.904 671.53 371.904H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 386.905C672.772 386.905 674.033 386.438 675.061 385.404C678.273 382.2 676.027 376.645 671.501 376.645C668.718 376.645 666.434 378.953 666.434 381.768C666.434 384.865 668.932 386.905 671.53 386.905H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 401.123C672.772 401.123 674.033 400.657 675.061 399.623C678.273 396.376 676.027 390.863 671.501 390.863C668.718 390.863 666.434 393.167 666.434 395.986C666.434 399.079 668.932 401.123 671.53 401.123H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.549 415.331C672.781 415.331 674.037 414.873 675.061 413.838C678.273 410.588 676.027 405.07 671.501 405.07C668.718 405.07 666.434 407.343 666.434 410.198C666.434 413.279 668.941 415.331 671.549 415.331H671.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.525 430.333C672.767 430.333 674.033 429.867 675.061 428.821C678.273 425.619 676.027 420.117 671.501 420.078C668.718 420.117 666.434 422.378 666.434 425.23C666.434 428.293 668.927 430.333 671.525 430.333H671.525Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 328.471C687.002 328.471 688.272 328.004 689.31 326.97C692.484 323.724 690.242 318.211 685.712 318.211C682.929 318.211 680.645 320.52 680.645 323.334C680.645 326.432 683.148 328.471 685.755 328.471H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 343.479C687.002 343.479 688.272 343.012 689.31 341.978C692.484 338.731 690.242 333.219 685.712 333.219C682.929 333.219 680.645 335.523 680.645 338.342C680.645 341.435 683.148 343.479 685.755 343.479H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 371.9C687.002 371.9 688.272 371.434 689.31 370.387C692.484 367.147 690.243 361.645 685.713 361.645C682.929 361.645 680.645 363.944 680.645 366.758C680.645 369.845 683.148 371.9 685.756 371.9H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 386.91C687.002 386.91 688.272 386.439 689.31 385.392C692.484 382.149 690.242 376.645 685.712 376.645C682.929 376.645 680.645 378.95 680.645 381.76C680.645 384.853 683.148 386.91 685.755 386.91H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 401.123C687.002 401.123 688.272 400.657 689.31 399.623C692.484 396.376 690.243 390.863 685.713 390.863C682.929 390.863 680.645 393.167 680.645 395.986C680.645 399.079 683.148 401.123 685.756 401.123H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.736 415.335C686.987 415.335 688.267 414.858 689.31 413.803C692.484 410.591 690.242 405.07 685.712 405.07C682.929 405.07 680.645 407.344 680.645 410.2C680.645 413.293 683.133 415.335 685.736 415.335L685.736 415.335Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.75 430.333C686.997 430.333 688.272 429.867 689.31 428.821C692.484 425.619 690.242 420.078 685.712 420.078C682.929 420.117 680.645 422.378 680.645 425.23C680.645 428.293 683.143 430.333 685.751 430.333H685.75Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 444.55C687.002 444.55 688.272 444.08 689.31 443.032C692.484 439.828 690.242 434.285 685.712 434.285C682.929 434.285 680.645 436.591 680.645 439.401C680.645 442.494 683.148 444.55 685.755 444.55H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 458.764C687.002 458.764 688.272 458.297 689.31 457.263C692.484 454.017 690.243 448.504 685.713 448.504C682.929 448.504 680.645 450.808 680.645 453.627C680.645 456.72 683.148 458.764 685.756 458.764H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M686.033 521.141C683.073 521.141 680.645 523.446 680.645 526.256C680.645 529.349 683.321 531.406 686.099 531.406C687.424 531.406 688.77 530.935 689.858 529.888C693.274 526.645 690.85 521.141 686.033 521.141H686.033Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 343.479C702.006 343.479 703.276 343.012 704.314 341.978C707.488 338.731 705.242 333.219 700.716 333.219C697.932 333.219 695.648 335.523 695.648 338.342C695.648 341.435 698.147 343.479 700.759 343.479H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.716 550.359C697.932 550.359 695.648 552.665 695.648 555.475C695.648 558.569 698.147 560.624 700.759 560.624C702.006 560.624 703.276 560.154 704.314 559.107C707.488 555.864 705.242 550.359 700.717 550.359H700.716Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.768 536.148H700.72C697.934 536.148 695.648 538.244 695.648 540.886C695.648 543.724 698.144 545.615 700.759 545.615C702.006 545.615 703.283 545.183 704.321 544.214C707.483 541.255 705.264 536.148 700.768 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.743 531.406C701.986 531.406 703.252 530.935 704.286 529.888C707.488 526.645 705.211 521.141 700.7 521.141C697.925 521.141 695.648 523.446 695.648 526.256C695.648 529.35 698.139 531.406 700.743 531.406Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 517.189C702.006 517.189 703.276 516.723 704.314 515.676C707.488 512.436 705.242 506.934 700.716 506.934C697.932 506.934 695.648 509.233 695.648 512.047C695.648 515.134 698.147 517.189 700.759 517.189H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.754 502.195C702.001 502.195 703.276 501.724 704.314 500.677C707.488 497.473 705.242 491.93 700.716 491.93C697.932 491.968 695.648 494.235 695.648 497.084C695.648 500.148 698.142 502.195 700.754 502.195Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.768 477.719H700.72C697.934 477.719 695.648 479.989 695.648 482.851C695.648 485.926 698.144 487.974 700.759 487.974C702.006 487.974 703.283 487.506 704.321 486.456C707.483 483.251 705.264 477.719 700.768 477.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 473.76C702.006 473.76 703.276 473.298 704.314 472.259C707.488 469.013 705.242 463.5 700.716 463.5C697.89 463.5 695.648 465.809 695.648 468.623C695.648 471.721 698.147 473.76 700.759 473.76H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 458.764C702.006 458.764 703.276 458.297 704.314 457.263C707.488 454.017 705.242 448.504 700.716 448.504C697.932 448.504 695.648 450.808 695.648 453.627C695.648 456.72 698.147 458.764 700.759 458.764H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.73 434.285C697.952 434.324 695.672 436.591 695.672 439.401C695.643 442.494 698.151 444.55 700.754 444.55C701.993 444.55 703.257 444.079 704.282 443.032C707.488 439.828 705.246 434.285 700.73 434.285H700.73Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.754 430.333C702.001 430.333 703.276 429.867 704.314 428.821C707.488 425.619 705.242 420.078 700.716 420.078C697.932 420.117 695.648 422.378 695.648 425.23C695.648 428.293 698.142 430.333 700.754 430.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.735 415.335C701.992 415.335 703.272 414.858 704.314 413.803C707.488 410.591 705.242 405.07 700.716 405.07C697.932 405.07 695.648 407.344 695.648 410.2C695.648 413.293 698.133 415.335 700.735 415.335V415.335Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 401.123C702.006 401.123 703.276 400.657 704.314 399.623C707.488 396.376 705.242 390.863 700.716 390.863C697.932 390.863 695.648 393.167 695.648 395.986C695.648 399.079 698.147 401.123 700.759 401.123H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 386.91C702.006 386.91 703.276 386.439 704.314 385.392C707.488 382.149 705.242 376.645 700.716 376.645C697.932 376.645 695.648 378.95 695.648 381.76C695.648 384.853 698.147 386.91 700.759 386.91H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 371.904C702.006 371.904 703.276 371.438 704.314 370.391C707.488 367.15 705.242 361.648 700.716 361.648C697.932 361.687 695.648 363.948 695.648 366.762C695.648 369.849 698.147 371.904 700.759 371.904H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.735 357.695C701.992 357.695 703.272 357.218 704.314 356.163C707.488 352.95 705.242 347.43 700.716 347.43C697.932 347.43 695.648 349.703 695.648 352.56C695.648 355.653 698.133 357.695 700.735 357.695Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.736 357.695C686.987 357.695 688.267 357.218 689.31 356.163C692.484 352.95 690.242 347.43 685.712 347.43C682.929 347.43 680.645 349.703 680.645 352.56C680.645 355.653 683.133 357.695 685.736 357.695H685.736Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.525 357.695C672.767 357.695 674.033 357.222 675.061 356.171C678.273 352.955 676.027 347.43 671.501 347.43C668.718 347.43 666.434 349.705 666.434 352.565C666.434 355.641 668.927 357.695 671.525 357.695H671.525Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 347.43C654.504 347.43 652.219 349.703 652.219 352.56C652.219 355.653 654.708 357.695 657.308 357.695C658.565 357.695 659.845 357.218 660.888 356.163C664.063 352.95 661.816 347.43 657.289 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 420.078C814.004 420.117 811.719 422.378 811.719 425.23C811.719 428.293 814.218 430.333 816.827 430.333C818.074 430.333 819.35 429.867 820.388 428.821C823.563 425.619 821.316 420.078 816.789 420.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 405.07C814.004 405.07 811.719 407.341 811.719 410.193C811.719 413.291 814.218 415.331 816.831 415.331C818.079 415.331 819.35 414.869 820.388 413.83C823.563 410.583 821.316 405.07 816.789 405.07Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 390.863C814.004 390.863 811.719 393.167 811.719 395.986C811.719 399.079 814.218 401.123 816.831 401.123C818.079 401.123 819.35 400.657 820.388 399.623C823.563 396.376 821.316 390.863 816.789 390.863Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.812 444.55C803.055 444.55 804.322 444.08 805.36 443.032C808.557 439.828 806.285 434.285 801.77 434.285C798.995 434.324 796.761 436.591 796.723 439.401C796.723 442.494 799.213 444.55 801.812 444.55Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.827 430.333C803.069 430.333 804.336 429.867 805.36 428.821C808.557 425.619 806.285 420.078 801.77 420.078C798.995 420.117 796.723 422.378 796.723 425.23C796.723 428.293 799.227 430.333 801.827 430.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.794 415.335C803.041 415.335 804.317 414.858 805.36 413.803C808.557 410.591 806.285 405.07 801.77 405.07C798.995 405.07 796.761 407.344 796.723 410.2C796.723 413.293 799.199 415.335 801.793 415.335L801.794 415.335Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.817 401.123C803.055 401.123 804.326 400.657 805.36 399.623C808.557 396.376 806.285 390.863 801.77 390.863C798.995 390.863 796.723 393.167 796.723 395.986C796.723 399.079 799.213 401.123 801.817 401.123Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.831 386.91C803.074 386.91 804.336 386.439 805.36 385.392C808.557 382.149 806.285 376.645 801.77 376.645C798.995 376.645 796.723 378.95 796.723 381.76C796.723 384.853 799.227 386.91 801.831 386.91Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 448.504C784.785 448.504 782.546 450.808 782.508 453.627C782.508 456.72 785.017 458.764 787.621 458.764C788.859 458.764 790.126 458.297 791.145 457.263C794.347 454.017 792.07 448.504 787.597 448.504H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 434.285C784.785 434.285 782.546 436.591 782.508 439.401C782.508 442.494 785.017 444.55 787.616 444.55C788.859 444.55 790.126 444.079 791.145 443.032C794.347 439.828 792.07 434.285 787.597 434.285H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M787.598 390.863C784.785 390.863 782.546 393.167 782.508 395.986C782.508 399.079 785.017 401.123 787.621 401.123C788.859 401.123 790.126 400.657 791.145 399.623C794.347 396.376 792.07 390.863 787.597 390.863H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 376.645C784.785 376.645 782.546 378.95 782.508 381.76C782.508 384.853 785.017 386.91 787.616 386.91C788.859 386.91 790.126 386.439 791.145 385.392C794.347 382.149 792.07 376.645 787.597 376.645H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 361.648C784.785 361.648 782.546 363.948 782.508 366.762C782.508 369.849 785.017 371.904 787.616 371.904C788.859 371.904 790.126 371.438 791.145 370.391C794.347 367.189 792.07 361.648 787.597 361.648H787.598Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.344 521.141C770.527 521.141 768.293 523.446 768.293 526.256C768.293 529.349 770.783 531.406 773.383 531.406C774.625 531.406 775.896 530.935 776.931 529.888C780.128 526.645 777.856 521.141 773.345 521.141H773.344Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.369 506.934C770.542 506.934 768.301 509.233 768.301 512.047C768.301 515.134 770.799 517.189 773.397 517.189C774.639 517.189 775.9 516.723 776.923 515.676C780.135 512.436 777.894 506.934 773.369 506.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.369 491.93C770.542 491.968 768.301 494.235 768.301 497.084C768.301 500.148 770.794 502.195 773.402 502.195C774.653 502.195 775.924 501.724 776.966 500.677C780.135 497.473 777.894 491.93 773.369 491.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.385 487.974C774.626 487.974 775.892 487.506 776.916 486.456C780.128 483.241 777.886 477.719 773.361 477.719C770.534 477.719 768.293 479.989 768.293 482.851C768.293 485.926 770.787 487.974 773.385 487.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.369 463.5C770.542 463.5 768.301 465.809 768.301 468.623C768.301 471.721 770.799 473.76 773.412 473.76C774.653 473.76 775.929 473.298 776.966 472.259C780.135 469.013 777.894 463.5 773.369 463.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.369 448.504C770.542 448.504 768.301 450.808 768.301 453.627C768.301 456.72 770.799 458.764 773.412 458.764C774.653 458.764 775.929 458.297 776.966 457.263C780.135 454.017 777.894 448.504 773.369 448.504Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 371.904C774.631 371.904 775.892 371.438 776.916 370.391C780.128 367.15 777.886 361.648 773.361 361.648C770.534 361.687 768.293 363.948 768.293 366.762C768.293 369.849 770.791 371.904 773.389 371.904Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.369 347.43C770.542 347.43 768.301 349.703 768.301 352.56C768.301 355.653 770.785 357.695 773.388 357.695C774.639 357.695 775.919 357.218 776.966 356.163C780.135 352.95 777.894 347.43 773.369 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 536.148C755.574 536.148 753.289 538.244 753.289 540.886C753.289 543.724 755.784 545.615 758.397 545.615C759.644 545.615 760.92 545.183 761.958 544.214C765.133 541.247 762.886 536.148 758.359 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.4 531.406C759.643 531.406 760.91 530.935 761.93 529.888C765.133 526.645 762.856 521.141 758.343 521.141C755.567 521.141 753.289 523.446 753.289 526.256C753.289 529.35 755.799 531.406 758.4 531.406Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 347.43C755.574 347.43 753.289 349.703 753.289 352.56C753.289 355.653 755.774 357.695 758.378 357.695C759.635 357.695 760.915 357.218 761.958 356.163C765.133 352.95 762.886 347.43 758.359 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 333.219C755.574 333.219 753.289 335.523 753.289 338.342C753.289 341.435 755.788 343.479 758.402 343.479C759.649 343.479 760.92 343.012 761.958 341.978C765.133 338.731 762.886 333.219 758.359 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 579.575C741.351 579.575 739.074 581.706 739.074 584.303C739.074 587.163 741.569 589.045 744.169 589.045C745.411 589.045 746.683 588.619 747.712 587.66C750.914 584.667 748.642 579.574 744.126 579.574V579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 564.574C741.351 564.574 739.074 566.878 739.074 569.697C739.074 572.795 741.569 574.834 744.169 574.834C745.411 574.834 746.683 574.368 747.712 573.333C750.914 570.087 748.642 564.574 744.126 564.574Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 550.359C741.351 550.359 739.074 552.665 739.074 555.475C739.074 558.569 741.569 560.624 744.169 560.624C745.411 560.624 746.678 560.154 747.712 559.107C750.914 555.907 748.642 550.359 744.126 550.359Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 536.148C741.351 536.148 739.074 538.244 739.074 540.886C739.074 543.724 741.564 545.615 744.164 545.615C745.407 545.615 746.678 545.183 747.712 544.214C750.914 541.247 748.642 536.148 744.126 536.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 318.211C741.351 318.211 739.074 320.52 739.074 323.334C739.074 326.432 741.569 328.471 744.169 328.471C745.411 328.471 746.683 328.004 747.712 326.97C750.914 323.724 748.642 318.211 744.126 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.961 579.575C727.148 579.575 724.909 581.706 724.871 584.303C724.871 587.163 727.38 589.045 729.984 589.045C731.222 589.045 732.489 588.619 733.509 587.66C736.71 584.667 734.434 579.574 729.961 579.574L729.961 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.985 328.471C731.223 328.471 732.489 328.004 733.509 326.97C736.711 323.724 734.434 318.211 729.961 318.211C727.148 318.211 724.909 320.52 724.871 323.334C724.871 326.432 727.38 328.471 729.984 328.471H729.985Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.975 314.259C731.218 314.259 732.484 313.793 733.509 312.747C736.711 309.506 734.434 304.004 729.961 304.004C727.148 304.042 724.909 306.304 724.871 309.155C724.871 312.218 727.376 314.259 729.975 314.259H729.975Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 579.575C712.104 579.575 709.863 581.706 709.863 584.303C709.863 587.163 712.362 589.045 714.96 589.045C716.202 589.045 717.463 588.619 718.486 587.66C721.698 584.667 719.457 579.574 714.927 579.574L714.926 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 318.211C712.104 318.211 709.863 320.52 709.863 323.334C709.863 326.432 712.362 328.471 714.96 328.471C716.202 328.471 717.463 328.004 718.486 326.97C721.698 323.724 719.457 318.211 714.927 318.211H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.926 304.004C712.104 304.004 709.863 306.304 709.863 309.155C709.863 312.218 712.357 314.259 714.955 314.259C716.197 314.259 717.463 313.793 718.486 312.747C721.698 309.545 719.457 304.004 714.927 304.004H714.926Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.716 579.575C697.932 579.575 695.648 581.706 695.648 584.303C695.648 587.163 698.147 589.045 700.759 589.045C702.006 589.045 703.276 588.619 704.314 587.66C707.488 584.667 705.242 579.574 700.717 579.574L700.716 579.575Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.716 564.574C697.932 564.574 695.648 566.878 695.648 569.697C695.648 572.795 698.147 574.834 700.759 574.834C702.006 574.834 703.276 574.368 704.314 573.333C707.488 570.087 705.242 564.574 700.717 564.574H700.716Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 328.471C702.006 328.471 703.276 328.004 704.314 326.97C707.488 323.724 705.242 318.211 700.716 318.211C697.932 318.211 695.648 320.52 695.648 323.334C695.648 326.432 698.147 328.471 700.759 328.471H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.754 314.259C702.001 314.259 703.276 313.793 704.314 312.747C707.488 309.506 705.242 304.004 700.716 304.004C697.932 304.042 695.648 306.304 695.648 309.155C695.648 312.218 698.142 314.259 700.754 314.259Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.712 564.574C682.929 564.574 680.645 566.878 680.645 569.697C680.645 572.795 683.148 574.834 685.755 574.834C687.002 574.834 688.272 574.368 689.31 573.333C692.484 570.087 690.243 564.574 685.713 564.574H685.712Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M685.712 550.359C682.929 550.359 680.645 552.665 680.645 555.475C680.645 558.569 683.148 560.624 685.755 560.624C687.002 560.624 688.272 560.154 689.31 559.107C692.484 555.864 690.243 550.359 685.713 550.359H685.712Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.713 536.148C682.929 536.148 680.645 538.244 680.645 540.886C680.645 543.724 683.143 545.615 685.751 545.615C686.997 545.615 688.273 545.183 689.31 544.214C692.484 541.247 690.243 536.148 685.713 536.148H685.713Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 517.189C687.002 517.189 688.272 516.723 689.31 515.676C692.484 512.436 690.243 506.934 685.713 506.934C682.929 506.934 680.645 509.233 680.645 512.047C680.645 515.134 683.148 517.189 685.756 517.189H685.755Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.75 502.195C686.997 502.195 688.272 501.724 689.31 500.677C692.484 497.473 690.242 491.93 685.712 491.93C682.929 491.968 680.645 494.235 680.645 497.084C680.645 500.148 683.143 502.195 685.751 502.195H685.75Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.751 487.974C686.997 487.974 688.272 487.506 689.31 486.456C692.484 483.241 690.243 477.719 685.713 477.719C682.929 477.719 680.645 479.989 680.645 482.851C680.645 485.926 683.143 487.974 685.751 487.974H685.751Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 473.76C687.002 473.76 688.272 473.298 689.31 472.259C692.484 469.013 690.242 463.5 685.712 463.5C682.929 463.5 680.645 465.809 680.645 468.623C680.645 471.721 683.148 473.76 685.755 473.76H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.751 314.259C686.997 314.259 688.272 313.793 689.31 312.747C692.484 309.506 690.243 304.004 685.713 304.004C682.929 304.042 680.645 306.304 680.645 309.155C680.645 312.218 683.143 314.259 685.751 314.259H685.751Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.501 521.141C668.718 521.141 666.434 523.446 666.434 526.256C666.434 529.349 668.932 531.406 671.544 531.406C672.791 531.406 674.062 530.935 675.099 529.888C678.273 526.689 676.027 521.141 671.501 521.141Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 517.189C672.772 517.189 674.033 516.723 675.061 515.676C678.273 512.474 676.027 506.934 671.501 506.934C668.718 506.934 666.434 509.233 666.434 512.047C666.434 515.134 668.932 517.189 671.53 517.189H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 473.76C672.772 473.76 674.033 473.298 675.061 472.259C678.273 469.013 676.027 463.5 671.501 463.5C668.718 463.5 666.434 465.809 666.434 468.623C666.434 471.721 668.932 473.76 671.53 473.76H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 458.764C672.772 458.764 674.033 458.297 675.061 457.263C678.273 454.017 676.027 448.504 671.501 448.504C668.718 448.504 666.434 450.808 666.434 453.627C666.434 456.72 668.932 458.764 671.53 458.764H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.519 434.285C668.741 434.285 666.461 436.591 666.461 439.401C666.433 442.494 668.921 444.55 671.524 444.55C672.763 444.55 674.036 444.079 675.071 443.032C678.277 439.828 676.035 434.285 671.519 434.285Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.525 300.05C672.767 300.05 674.033 299.579 675.061 298.532C678.273 295.328 676.027 289.824 671.501 289.785C668.718 289.824 666.434 292.091 666.434 294.939C666.434 298.004 668.927 300.05 671.525 300.05H671.525Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.3 434.285C654.522 434.285 652.242 436.591 652.242 439.401C652.219 442.494 654.702 444.55 657.305 444.55C658.549 444.55 659.817 444.079 660.852 443.032C664.058 439.828 661.816 434.285 657.3 434.285Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 420.078C654.504 420.117 652.219 422.378 652.219 425.23C652.219 428.293 654.718 430.333 657.327 430.333C658.574 430.333 659.85 429.867 660.888 428.821C664.063 425.619 661.816 420.078 657.289 420.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 289.785C654.504 289.824 652.219 292.091 652.219 294.939C652.219 298.004 654.718 300.05 657.327 300.05C658.574 300.05 659.85 299.579 660.888 298.532C664.063 295.328 661.816 289.785 657.289 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 420.078C639.502 420.117 637.223 422.378 637.223 425.23C637.223 428.293 639.716 430.333 642.324 430.333C643.575 430.333 644.846 429.867 645.888 428.821C649.057 425.619 646.816 420.078 642.29 420.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 289.785C639.502 289.824 637.223 292.091 637.223 294.939C637.223 298.004 639.716 300.05 642.324 300.05C643.575 300.05 644.846 299.579 645.888 298.532C649.057 295.328 646.816 289.785 642.29 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 274.789C639.502 274.789 637.223 277.096 637.223 279.917C637.223 283.008 639.707 285.045 642.31 285.045C643.561 285.045 644.841 284.573 645.888 283.518C649.057 280.307 646.816 274.789 642.29 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 420.078C625.284 420.117 623.008 422.378 623.008 425.23C623.008 428.293 625.498 430.333 628.098 430.333C629.34 430.333 630.611 429.867 631.646 428.821C634.847 425.619 632.571 420.078 628.06 420.078H628.059Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 289.785C625.284 289.824 623.008 292.091 623.008 294.939C623.008 298.004 625.498 300.05 628.098 300.05C629.34 300.05 630.611 299.579 631.646 298.532C634.847 295.328 632.571 289.785 628.06 289.785H628.059Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.059 274.789C625.284 274.789 623.008 277.096 623.008 279.917C623.008 283.008 625.484 285.045 628.078 285.045C629.331 285.045 630.607 284.573 631.646 283.518C634.847 280.307 632.571 274.789 628.06 274.789L628.059 274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.887 420.078C611.069 420.117 608.835 422.378 608.797 425.23C608.797 428.293 611.301 430.333 613.901 430.333C615.144 430.333 616.41 429.867 617.435 428.821C620.632 425.619 618.36 420.078 613.887 420.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.887 405.071C611.069 405.071 608.835 407.344 608.797 410.201C608.797 413.293 611.292 415.335 613.882 415.335C615.134 415.335 616.405 414.858 617.435 413.803C620.632 410.591 618.36 405.07 613.887 405.07V405.071Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M613.887 289.785C611.069 289.824 608.835 292.091 608.797 294.939C608.797 298.004 611.301 300.05 613.901 300.05C615.144 300.05 616.41 299.579 617.435 298.532C620.632 295.328 618.36 289.785 613.887 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.887 274.789C611.069 274.789 608.835 277.096 608.797 279.917C608.797 283.008 611.292 285.045 613.882 285.045C615.134 285.045 616.405 284.573 617.435 283.518C620.632 280.307 618.36 274.789 613.887 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.855 405.071C596.032 405.071 593.785 407.344 593.785 410.201C593.785 413.293 596.275 415.335 598.865 415.335C600.112 415.335 601.388 414.858 602.416 413.803C605.63 410.591 603.382 405.07 598.855 405.07V405.071Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.889 328.471C600.126 328.471 601.393 328.004 602.416 326.97C605.63 323.724 603.382 318.211 598.855 318.211C596.032 318.211 593.785 320.52 593.785 323.334C593.785 326.432 596.289 328.471 598.888 328.471H598.889Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.855 304.004C596.032 304.042 593.785 306.304 593.785 309.155C593.785 312.218 596.284 314.259 598.893 314.259C600.14 314.259 601.416 313.793 602.454 312.747C605.629 309.506 603.382 304.004 598.855 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.879 300.05C600.126 300.05 601.388 299.579 602.416 298.532C605.63 295.328 603.382 289.785 598.855 289.785C596.032 289.824 593.785 292.091 593.785 294.939C593.785 298.004 596.284 300.05 598.879 300.05Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M584.65 405.071C581.861 405.071 579.582 407.344 579.582 410.201C579.582 413.293 582.066 415.335 584.669 415.335C585.92 415.335 587.2 414.858 588.247 413.803C591.416 410.591 589.175 405.07 584.65 405.07V405.071Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M584.65 390.863C581.861 390.863 579.582 393.167 579.582 395.986C579.582 399.079 582.08 401.123 584.693 401.123C585.939 401.123 587.21 400.657 588.247 399.623C591.416 396.376 589.175 390.863 584.65 390.863Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M584.65 333.219C581.861 333.219 579.582 335.523 579.582 338.342C579.582 341.435 582.08 343.479 584.693 343.479C585.939 343.479 587.21 343.012 588.247 341.978C591.416 338.731 589.175 333.219 584.65 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M584.65 318.211C581.861 318.211 579.582 320.52 579.582 323.334C579.582 326.432 582.08 328.471 584.693 328.471C585.939 328.471 587.21 328.004 588.247 326.97C591.416 323.724 589.175 318.211 584.65 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M570.429 390.863C567.644 390.863 565.359 393.167 565.359 395.986C565.359 399.079 567.863 401.123 570.472 401.123C571.719 401.123 572.99 400.657 574.028 399.623C577.204 396.376 574.957 390.863 570.429 390.863Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M570.429 376.645C567.644 376.645 565.359 378.95 565.359 381.76C565.359 384.853 567.859 386.91 570.472 386.91C571.719 386.91 572.99 386.439 574.028 385.392C577.204 382.149 574.957 376.645 570.429 376.645Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M570.429 361.648C567.644 361.687 565.359 363.948 565.359 366.762C565.359 369.849 567.859 371.904 570.472 371.904C571.719 371.904 572.99 371.438 574.028 370.391C577.204 367.15 574.957 361.648 570.429 361.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M570.429 347.43C567.644 347.43 565.359 349.703 565.359 352.56C565.359 355.653 567.844 357.695 570.448 357.695C571.705 357.695 572.986 357.218 574.028 356.163C577.203 352.95 574.956 347.43 570.429 347.43H570.429Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M570.429 333.219C567.644 333.219 565.359 335.523 565.359 338.342C565.359 341.435 567.863 343.479 570.472 343.479C571.719 343.479 572.99 343.012 574.028 341.978C577.204 338.731 574.957 333.219 570.429 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 361.648C755.574 361.648 753.289 363.948 753.289 366.762C753.289 369.849 755.788 371.904 758.402 371.904C759.649 371.904 760.92 371.438 761.958 370.391C765.133 367.15 762.886 361.648 758.359 361.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.812 371.904C803.055 371.904 804.322 371.438 805.36 370.391C808.557 367.15 806.285 361.648 801.77 361.648C798.995 361.687 796.761 363.948 796.723 366.762C796.723 369.849 799.213 371.904 801.812 371.904Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.617 125.549C788.859 125.549 790.126 125.115 791.146 124.149C794.347 121.192 792.071 116.078 787.598 116.078C784.785 116.078 782.546 118.205 782.508 120.798C782.508 123.652 785.017 125.549 787.616 125.549H787.617Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.621 140.545C788.859 140.545 790.126 140.083 791.146 139.048C794.347 135.802 792.071 130.289 787.598 130.289C784.785 130.289 782.546 132.593 782.508 135.407C782.508 138.505 785.017 140.544 787.621 140.544L787.621 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.621 154.765C788.859 154.765 790.126 154.298 791.146 153.263C794.347 150.015 792.071 144.5 787.598 144.5C784.785 144.5 782.546 146.81 782.508 149.625C782.508 152.724 785.017 154.765 787.621 154.765H787.621Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.621 168.974C788.859 168.974 790.126 168.548 791.146 167.594C794.347 164.596 792.071 159.508 787.598 159.508C784.785 159.508 782.546 161.599 782.508 164.237C782.508 167.092 785.017 168.974 787.621 168.974H787.621Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.612 183.979C788.855 183.979 790.121 183.508 791.146 182.461C794.347 179.259 792.071 173.719 787.598 173.719C784.785 173.757 782.546 176.023 782.508 178.87C782.508 181.933 785.012 183.979 787.612 183.979H787.612Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.617 198.189C788.859 198.189 790.126 197.723 791.146 196.672C794.347 193.436 792.071 187.934 787.598 187.934C784.785 187.934 782.546 190.233 782.508 193.042C782.508 196.134 785.017 198.189 787.616 198.189H787.617Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.621 212.405C788.859 212.405 790.126 211.974 791.146 211.019C794.347 208.021 792.071 202.93 787.598 202.93C784.785 202.93 782.546 205.062 782.508 207.661C782.508 210.521 785.017 212.405 787.621 212.405H787.621Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.959 168.974C716.201 168.974 717.463 168.548 718.486 167.594C721.698 164.596 719.456 159.508 714.926 159.508C712.104 159.508 709.863 161.599 709.863 164.237C709.863 167.092 712.362 168.974 714.96 168.974H714.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.955 183.979C716.197 183.979 717.462 183.508 718.486 182.461C721.698 179.259 719.456 173.719 714.926 173.719C712.104 173.757 709.863 176.023 709.863 178.87C709.863 181.933 712.357 183.979 714.955 183.979H714.955Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 173.719C654.504 173.757 652.219 176.023 652.219 178.87C652.219 181.933 654.718 183.979 657.327 183.979C658.574 183.979 659.85 183.508 660.888 182.461C664.063 179.259 661.816 173.719 657.289 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 198.189C672.772 198.189 674.033 197.723 675.061 196.672C678.273 193.474 676.027 187.934 671.501 187.934C668.718 187.934 666.434 190.233 666.434 193.042C666.434 196.134 668.932 198.189 671.53 198.189H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.525 183.979C672.767 183.979 674.033 183.508 675.061 182.461C678.273 179.259 676.027 173.757 671.501 173.719C668.718 173.757 666.434 176.023 666.434 178.87C666.434 181.933 668.927 183.979 671.525 183.979H671.525Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.75 183.979C686.997 183.979 688.272 183.508 689.31 182.461C692.484 179.259 690.242 173.719 685.712 173.719C682.929 173.757 680.645 176.023 680.645 178.87C680.645 181.933 683.143 183.979 685.751 183.979H685.75Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.754 183.979C702.001 183.979 703.276 183.508 704.314 182.461C707.488 179.259 705.242 173.719 700.716 173.719C697.932 173.757 695.648 176.023 695.648 178.87C695.648 181.933 698.142 183.979 700.754 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 198.189C702.006 198.189 703.276 197.723 704.314 196.672C707.488 193.436 705.242 187.934 700.716 187.934C697.932 187.934 695.648 190.233 695.648 193.042C695.648 196.134 698.147 198.189 700.759 198.189H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.959 198.189C716.197 198.189 717.463 197.723 718.486 196.672C721.698 193.436 719.456 187.934 714.926 187.934C712.104 187.934 709.863 190.233 709.863 193.042C709.863 196.134 712.362 198.189 714.96 198.189H714.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.959 212.405C716.201 212.405 717.463 211.974 718.486 211.019C721.698 208.021 719.456 202.93 714.926 202.93C712.104 202.93 709.863 205.062 709.863 207.661C709.863 210.521 712.362 212.405 714.96 212.405H714.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.961 168.979C731.208 168.979 732.479 168.543 733.509 167.565C736.711 164.603 734.434 159.508 729.961 159.508C727.148 159.508 724.909 161.602 724.871 164.243C724.871 167.094 727.366 168.979 729.961 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.975 183.979C731.218 183.979 732.484 183.508 733.509 182.461C736.711 179.259 734.434 173.719 729.961 173.719C727.148 173.757 724.909 176.023 724.871 178.87C724.871 181.933 727.376 183.979 729.975 183.979H729.975Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.98 198.189C731.223 198.189 732.489 197.723 733.509 196.672C736.711 193.436 734.434 187.934 729.961 187.934C727.148 187.934 724.909 190.233 724.871 193.042C724.871 196.134 727.38 198.189 729.98 198.189H729.98Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.985 212.405C731.223 212.405 732.489 211.974 733.509 211.019C736.711 208.021 734.434 202.93 729.961 202.93C727.148 202.93 724.909 205.062 724.871 207.661C724.871 210.521 727.38 212.405 729.984 212.405H729.985Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.169 140.545C745.411 140.545 746.683 140.083 747.712 139.048C750.914 135.802 748.642 130.289 744.126 130.289C741.351 130.289 739.074 132.593 739.074 135.407C739.074 138.505 741.569 140.544 744.169 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.169 154.765C745.411 154.765 746.683 154.298 747.712 153.263C750.914 150.015 748.642 144.5 744.126 144.5C741.351 144.5 739.074 146.81 739.074 149.625C739.074 152.724 741.569 154.765 744.169 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.169 168.974C745.411 168.974 746.683 168.548 747.712 167.594C750.914 164.596 748.642 159.508 744.126 159.508C741.351 159.508 739.074 161.599 739.074 164.237C739.074 167.092 741.569 168.974 744.169 168.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.164 183.979C745.407 183.979 746.678 183.508 747.712 182.461C750.914 179.259 748.642 173.719 744.126 173.719C741.351 173.757 739.074 176.023 739.074 178.87C739.074 181.933 741.564 183.979 744.164 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.169 198.189C745.411 198.189 746.678 197.723 747.712 196.672C750.914 193.436 748.642 187.934 744.126 187.934C741.351 187.934 739.074 190.233 739.074 193.042C739.074 196.134 741.569 198.189 744.169 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.169 212.405C745.411 212.405 746.683 211.974 747.712 211.019C750.914 208.021 748.642 202.93 744.126 202.93C741.351 202.93 739.074 205.062 739.074 207.661C739.074 210.521 741.569 212.405 744.169 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.402 140.545C759.649 140.545 760.92 140.083 761.958 139.048C765.133 135.802 762.886 130.289 758.359 130.289C755.574 130.289 753.289 132.593 753.289 135.407C753.289 138.505 755.788 140.544 758.402 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.402 154.765C759.649 154.765 760.92 154.298 761.958 153.263C765.133 150.015 762.886 144.5 758.359 144.5C755.574 144.5 753.289 146.81 753.289 149.625C753.289 152.724 755.788 154.765 758.402 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.378 168.979C759.635 168.979 760.915 168.543 761.958 167.565C765.133 164.603 762.886 159.508 758.359 159.508C755.574 159.508 753.289 161.602 753.289 164.243C753.289 167.094 755.774 168.979 758.378 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.397 183.979C759.644 183.979 760.92 183.508 761.958 182.461C765.133 179.259 762.886 173.719 758.359 173.719C755.574 173.757 753.289 176.023 753.289 178.87C753.289 181.933 755.784 183.979 758.397 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.402 198.189C759.649 198.189 760.92 197.723 761.958 196.672C765.133 193.436 762.886 187.934 758.359 187.934C755.574 187.934 753.289 190.233 753.289 193.042C753.289 196.134 755.788 198.189 758.402 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.402 212.405C759.649 212.405 760.92 211.974 761.958 211.019C765.133 208.021 762.886 202.93 758.359 202.93C755.574 202.93 753.289 205.062 753.289 207.661C753.289 210.521 755.788 212.405 758.402 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.402 227.404C759.649 227.404 760.92 226.942 761.958 225.908C765.133 222.661 762.886 217.148 758.359 217.148C755.574 217.148 753.289 219.453 753.289 222.272C753.289 225.364 755.788 227.404 758.402 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 125.549C774.631 125.549 775.892 125.115 776.916 124.149C780.128 121.157 777.886 116.078 773.361 116.078C770.534 116.113 768.293 118.205 768.293 120.798C768.293 123.652 770.791 125.549 773.389 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 140.545C774.631 140.545 775.892 140.083 776.916 139.048C780.128 135.802 777.886 130.289 773.361 130.289C770.534 130.289 768.293 132.593 768.293 135.407C768.293 138.505 770.791 140.544 773.389 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 154.765C774.631 154.765 775.892 154.298 776.916 153.263C780.128 150.015 777.886 144.5 773.361 144.5C770.534 144.5 768.293 146.81 768.293 149.625C768.293 152.724 770.791 154.765 773.389 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.366 168.979C774.612 168.979 775.887 168.543 776.916 167.565C780.128 164.603 777.886 159.508 773.361 159.508C770.534 159.508 768.293 161.602 768.293 164.243C768.293 167.094 770.777 168.979 773.366 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.385 183.979C774.626 183.979 775.892 183.508 776.916 182.461C780.128 179.259 777.886 173.719 773.361 173.719C770.534 173.757 768.293 176.023 768.293 178.87C768.293 181.933 770.787 183.979 773.385 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 198.189C774.631 198.189 775.892 197.723 776.916 196.672C780.128 193.436 777.886 187.934 773.361 187.934C770.534 187.934 768.293 190.233 768.293 193.042C768.293 196.134 770.791 198.189 773.389 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 212.405C774.631 212.405 775.892 211.974 776.916 211.019C780.128 208.021 777.886 202.93 773.361 202.93C770.534 202.93 768.293 205.062 768.293 207.661C768.293 210.521 770.791 212.405 773.389 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 227.404C774.631 227.404 775.892 226.942 776.916 225.908C780.128 222.661 777.886 217.148 773.361 217.148C770.534 217.148 768.293 219.453 768.293 222.272C768.293 225.364 770.791 227.404 773.389 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.621 227.404C788.859 227.404 790.126 226.942 791.146 225.908C794.347 222.661 792.071 217.148 787.598 217.148C784.785 217.148 782.546 219.453 782.508 222.272C782.508 225.364 785.017 227.404 787.621 227.404H787.621Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.812 125.549C803.055 125.549 804.322 125.115 805.36 124.149C808.557 121.157 806.285 116.078 801.77 116.078C798.995 116.113 796.761 118.205 796.723 120.798C796.723 123.652 799.213 125.549 801.812 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.817 140.545C803.055 140.545 804.326 140.083 805.36 139.048C808.557 135.802 806.285 130.289 801.77 130.289C798.995 130.289 796.761 132.593 796.723 135.407C796.723 138.505 799.213 140.544 801.817 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.817 154.765C803.055 154.765 804.326 154.298 805.36 153.263C808.557 150.015 806.285 144.5 801.77 144.5C798.995 144.5 796.761 146.81 796.723 149.625C796.723 152.724 799.213 154.765 801.817 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M801.808 183.979C803.05 183.979 804.322 183.508 805.36 182.461C808.557 179.259 806.285 173.719 801.77 173.719C798.995 173.757 796.761 176.023 796.723 178.87C796.723 181.933 799.208 183.979 801.808 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.812 198.189C803.055 198.189 804.322 197.723 805.36 196.672C808.557 193.436 806.285 187.934 801.77 187.934C798.995 187.934 796.761 190.233 796.723 193.042C796.723 196.134 799.213 198.189 801.812 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.817 212.405C803.055 212.405 804.326 211.974 805.36 211.019C808.557 208.021 806.285 202.93 801.77 202.93C798.995 202.93 796.761 205.062 796.723 207.661C796.723 210.521 799.213 212.405 801.817 212.405Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.817 227.404C803.055 227.404 804.326 226.942 805.36 225.908C808.557 222.661 806.285 217.148 801.77 217.148C798.995 217.148 796.761 219.453 796.723 222.272C796.723 225.364 799.213 227.404 801.817 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.813 111.333C803.058 111.333 804.33 110.866 805.37 109.81C808.562 106.61 806.311 101.078 801.823 101.078H801.776C798.997 101.078 796.761 103.348 796.723 106.211C796.723 109.28 799.211 111.333 801.814 111.333H801.813Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.612 111.333C788.855 111.333 790.121 110.866 791.146 109.81C794.347 106.601 792.071 101.078 787.598 101.078C784.785 101.078 782.546 103.348 782.508 106.211C782.508 109.28 785.012 111.333 787.612 111.333H787.612Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.385 111.333C774.626 111.333 775.892 110.866 776.916 109.81C780.128 106.601 777.886 101.078 773.361 101.078C770.534 101.078 768.293 103.348 768.293 106.211C768.293 109.28 770.787 111.333 773.385 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 82.115C774.631 82.115 775.892 81.6889 776.916 80.7341C780.128 77.7367 777.886 72.6484 773.361 72.6484C770.534 72.6484 768.293 74.7752 768.293 77.3727C768.293 80.2323 770.791 82.115 773.389 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.402 125.549C759.649 125.549 760.92 125.115 761.958 124.149C765.133 121.157 762.886 116.078 758.359 116.078C755.574 116.078 753.289 118.205 753.289 120.798C753.289 123.652 755.788 125.549 758.402 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.397 111.333C759.644 111.333 760.92 110.866 761.958 109.81C765.133 106.601 762.886 101.078 758.359 101.078C755.574 101.078 753.289 103.348 753.289 106.211C753.289 109.28 755.784 111.333 758.397 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.402 97.1241C759.649 97.1241 760.92 96.6573 761.958 95.6226C765.133 92.3743 762.886 86.8594 758.359 86.8594C755.574 86.8594 753.289 89.1695 753.289 91.9847C753.289 95.0837 755.788 97.1241 758.402 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.402 82.115C759.649 82.115 760.92 81.6889 761.958 80.7341C765.133 77.7367 762.886 72.6484 758.359 72.6484C755.574 72.6484 753.289 74.7752 753.289 77.3727C753.289 80.2323 755.788 82.115 758.402 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.169 227.404C745.411 227.404 746.683 226.942 747.712 225.908C750.914 222.661 748.642 217.148 744.126 217.148C741.351 217.148 739.074 219.453 739.074 222.272C739.074 225.364 741.569 227.404 744.169 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.169 125.549C745.411 125.549 746.678 125.115 747.712 124.149C750.914 121.192 748.642 116.078 744.126 116.078C741.351 116.078 739.074 118.205 739.074 120.798C739.074 123.652 741.569 125.549 744.169 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.164 111.333C745.407 111.333 746.678 110.866 747.712 109.81C750.914 106.601 748.642 101.078 744.126 101.078C741.351 101.078 739.074 103.348 739.074 106.211C739.074 109.28 741.564 111.333 744.164 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.169 97.1241C745.411 97.1241 746.683 96.6573 747.712 95.6226C750.914 92.3743 748.642 86.8594 744.126 86.8594C741.351 86.8594 739.074 89.1695 739.074 91.9847C739.074 95.0837 741.569 97.1241 744.169 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.169 82.115C745.411 82.115 746.683 81.6889 747.712 80.7341C750.914 77.7367 748.642 72.6484 744.126 72.6484C741.351 72.6484 739.074 74.7752 739.074 77.3727C739.074 80.2323 741.569 82.115 744.169 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.169 67.9084C745.411 67.9084 746.678 67.4377 747.712 66.3911C750.914 63.1504 748.642 57.6484 744.126 57.6484C741.351 57.6484 739.074 59.9529 739.074 62.7617C739.074 65.8534 741.569 67.9084 744.169 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M729.985 227.404C731.223 227.404 732.489 226.942 733.509 225.908C736.711 222.661 734.434 217.148 729.961 217.148C727.148 217.148 724.909 219.453 724.871 222.272C724.871 225.364 727.38 227.404 729.984 227.404H729.985Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.985 154.765C731.223 154.765 732.489 154.298 733.509 153.263C736.711 150.015 734.434 144.5 729.961 144.5C727.148 144.5 724.909 146.81 724.871 149.625C724.871 152.724 727.38 154.765 729.984 154.765H729.985Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.985 140.545C731.223 140.545 732.489 140.083 733.509 139.048C736.711 135.802 734.434 130.289 729.961 130.289C727.148 130.289 724.909 132.593 724.871 135.407C724.871 138.505 727.38 140.544 729.984 140.544L729.985 140.545Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.98 125.549C731.223 125.549 732.489 125.115 733.509 124.149C736.711 121.157 734.434 116.078 729.961 116.078C727.148 116.113 724.909 118.205 724.871 120.798C724.871 123.652 727.38 125.549 729.98 125.549H729.98Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.975 111.333C731.218 111.333 732.484 110.866 733.509 109.81C736.711 106.601 734.434 101.078 729.961 101.078C727.148 101.078 724.909 103.348 724.871 106.211C724.871 109.28 727.376 111.333 729.975 111.333H729.975Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.985 97.1241C731.223 97.1241 732.489 96.6573 733.509 95.6226C736.711 92.3743 734.434 86.8594 729.961 86.8594C727.148 86.8594 724.909 89.1695 724.871 91.9847C724.871 95.0837 727.38 97.1241 729.984 97.1241H729.985Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.985 82.115C731.223 82.115 732.489 81.6889 733.509 80.7341C736.711 77.7367 734.434 72.6484 729.961 72.6484C727.148 72.6484 724.909 74.7752 724.871 77.3727C724.871 80.2323 727.38 82.115 729.984 82.115H729.985Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M729.98 67.9084C731.223 67.9084 732.489 67.4377 733.509 66.3911C736.711 63.1504 734.434 57.6484 729.961 57.6484C727.148 57.6484 724.909 59.9529 724.871 62.7617C724.871 65.8534 727.38 67.9084 729.98 67.9084H729.98Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.964 231.355H714.916C712.1 231.355 709.863 233.661 709.863 236.471C709.863 239.564 712.357 241.62 714.959 241.62C716.203 241.62 717.471 241.15 718.511 240.103C721.703 236.875 719.452 231.355 714.964 231.355H714.964Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.959 227.404C716.201 227.404 717.463 226.942 718.486 225.908C721.698 222.661 719.456 217.148 714.926 217.148C712.104 217.148 709.863 219.453 709.863 222.272C709.863 225.364 712.362 227.404 714.96 227.404H714.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.959 154.765C716.201 154.765 717.463 154.298 718.486 153.263C721.698 150.015 719.456 144.5 714.926 144.5C712.104 144.5 709.863 146.81 709.863 149.625C709.863 152.724 712.362 154.765 714.96 154.765H714.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.959 125.549C716.197 125.549 717.463 125.115 718.486 124.149C721.698 121.192 719.456 116.078 714.926 116.078C712.104 116.078 709.863 118.205 709.863 120.798C709.863 123.652 712.362 125.549 714.96 125.549H714.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.955 111.333C716.197 111.333 717.462 110.866 718.486 109.81C721.698 106.601 719.456 101.078 714.926 101.078C712.104 101.078 709.863 103.348 709.863 106.211C709.863 109.28 712.357 111.333 714.955 111.333H714.955Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.959 97.1241C716.201 97.1241 717.463 96.6573 718.486 95.6226C721.698 92.3743 719.456 86.8594 714.926 86.8594C712.104 86.8594 709.863 89.1695 709.863 91.9847C709.863 95.0837 712.362 97.1241 714.96 97.1241H714.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.959 82.115C716.201 82.115 717.463 81.6889 718.486 80.7341C721.698 77.7367 719.456 72.6484 714.926 72.6484C712.104 72.6484 709.863 74.7752 709.863 77.3727C709.863 80.2323 712.362 82.115 714.96 82.115H714.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M714.959 67.9084C716.197 67.9084 717.463 67.4377 718.486 66.3911C721.698 63.1504 719.456 57.6484 714.926 57.6484C712.104 57.6484 709.863 59.9529 709.863 62.7617C709.863 65.8534 712.362 67.9084 714.96 67.9084H714.959Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 212.405C702.006 212.405 703.276 211.974 704.314 211.019C707.488 208.021 705.242 202.93 700.716 202.93C697.932 202.93 695.648 205.062 695.648 207.661C695.648 210.521 698.147 212.405 700.759 212.405H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.735 168.979C701.992 168.979 703.272 168.543 704.314 167.565C707.488 164.603 705.242 159.508 700.716 159.508C697.932 159.508 695.648 161.602 695.648 164.243C695.648 167.094 698.133 168.979 700.735 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 154.765C702.006 154.765 703.276 154.298 704.314 153.263C707.488 150.015 705.242 144.5 700.716 144.5C697.932 144.5 695.648 146.81 695.648 149.625C695.648 152.724 698.147 154.765 700.759 154.765H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 140.545C702.006 140.545 703.276 140.083 704.314 139.048C707.488 135.802 705.242 130.289 700.716 130.289C697.932 130.289 695.648 132.593 695.648 135.407C695.648 138.505 698.147 140.544 700.759 140.544L700.759 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M704.314 124.149C707.488 121.157 705.242 116.078 700.716 116.078C697.932 116.113 695.648 118.205 695.648 120.798C695.648 123.652 698.147 125.549 700.759 125.549C702.006 125.549 703.276 125.115 704.314 124.149H704.314Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.768 101.078H700.72C697.934 101.078 695.648 103.348 695.648 106.211C695.648 109.28 698.144 111.333 700.759 111.333C702.006 111.333 703.283 110.866 704.321 109.81C707.483 106.61 705.264 101.078 700.768 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 97.1241C702.006 97.1241 703.276 96.6573 704.314 95.6226C707.488 92.3743 705.242 86.8594 700.716 86.8594C697.932 86.8594 695.648 89.1695 695.648 91.9847C695.648 95.0837 698.147 97.1241 700.759 97.1241H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 82.115C702.006 82.115 703.276 81.6889 704.314 80.7341C707.488 77.7367 705.242 72.6484 700.716 72.6484C697.932 72.6484 695.648 74.7752 695.648 77.3727C695.648 80.2323 698.147 82.115 700.759 82.115H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M700.759 67.9084C702.006 67.9084 703.276 67.4377 704.314 66.3911C707.488 63.1504 705.242 57.6484 700.716 57.6484C697.932 57.6484 695.648 59.9529 695.648 62.7617C695.648 65.8534 698.147 67.9084 700.759 67.9084H700.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M686.09 231.355H686.04C683.076 231.355 680.645 233.661 680.645 236.471C680.645 239.564 683.324 241.62 686.116 241.62C687.448 241.62 688.81 241.15 689.915 240.103C693.279 236.875 690.877 231.355 686.09 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 227.404C687.002 227.404 688.272 226.942 689.31 225.908C692.484 222.661 690.243 217.148 685.713 217.148C682.929 217.148 680.645 219.453 680.645 222.272C680.645 225.364 683.148 227.404 685.756 227.404H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 198.189C687.002 198.189 688.272 197.723 689.31 196.672C692.484 193.436 690.242 187.934 685.712 187.934C682.929 187.934 680.645 190.233 680.645 193.042C680.645 196.134 683.148 198.189 685.755 198.189H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.736 168.979C686.987 168.979 688.267 168.543 689.31 167.565C692.484 164.603 690.242 159.508 685.712 159.508C682.929 159.508 680.645 161.602 680.645 164.243C680.645 167.094 683.133 168.979 685.736 168.979L685.736 168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 140.545C687.002 140.545 688.272 140.083 689.31 139.048C692.484 135.802 690.242 130.289 685.712 130.289C682.929 130.289 680.645 132.593 680.645 135.407C680.645 138.505 683.148 140.544 685.755 140.544L685.755 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 125.549C687.002 125.549 688.272 125.115 689.31 124.149C692.484 121.157 690.242 116.078 685.712 116.078C682.929 116.078 680.645 118.205 680.645 120.798C680.645 123.652 683.148 125.549 685.755 125.549H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.75 111.333C686.997 111.333 688.272 110.866 689.31 109.81C692.484 106.601 690.242 101.078 685.712 101.078C682.929 101.078 680.645 103.348 680.645 106.211C680.645 109.28 683.143 111.333 685.751 111.333H685.75Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 97.1241C687.002 97.1241 688.272 96.6573 689.31 95.6226C692.484 92.3743 690.243 86.8594 685.713 86.8594C682.929 86.8594 680.645 89.1695 680.645 91.9847C680.645 95.0837 683.148 97.1241 685.756 97.1241H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M685.755 82.115C687.002 82.115 688.272 81.6889 689.31 80.7341C692.484 77.7367 690.242 72.6484 685.712 72.6484C682.929 72.6484 680.645 74.7752 680.645 77.3727C680.645 80.2323 683.148 82.115 685.755 82.115H685.755Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 227.404C672.772 227.404 674.033 226.942 675.061 225.908C678.273 222.661 676.027 217.148 671.501 217.148C668.718 217.148 666.434 219.414 666.434 222.272C666.434 225.364 668.932 227.404 671.53 227.404H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 212.405C672.772 212.405 674.033 211.974 675.061 211.019C678.273 208.056 676.027 202.93 671.501 202.93C668.718 202.93 666.434 205.062 666.434 207.661C666.434 210.521 668.932 212.405 671.53 212.405H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.549 168.979C672.781 168.979 674.037 168.552 675.061 167.601C678.273 164.601 676.027 159.508 671.501 159.508C668.718 159.508 666.434 161.601 666.434 164.241C666.434 167.081 668.941 168.979 671.549 168.979L671.549 168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 154.765C672.772 154.765 674.033 154.298 675.061 153.263C678.273 150.015 676.027 144.5 671.501 144.5C668.718 144.5 666.434 146.772 666.434 149.625C666.434 152.724 668.932 154.765 671.53 154.765H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M671.53 140.545C672.772 140.545 674.033 140.083 675.061 139.048C678.273 135.84 676.027 130.289 671.501 130.289C668.718 130.289 666.434 132.593 666.434 135.407C666.434 138.505 668.932 140.544 671.53 140.544L671.53 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 125.549C672.772 125.549 674.033 125.115 675.061 124.149C678.273 121.192 676.027 116.113 671.501 116.078C668.718 116.078 666.434 118.205 666.434 120.798C666.434 123.652 668.932 125.549 671.53 125.549H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.525 111.333C672.767 111.333 674.033 110.866 675.061 109.81C678.273 106.601 676.027 101.078 671.501 101.078C668.718 101.078 666.434 103.348 666.434 106.211C666.434 109.28 668.927 111.333 671.525 111.333H671.525Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M671.53 97.1241C672.772 97.1241 674.033 96.6573 675.061 95.6226C678.273 92.3743 676.027 86.8594 671.501 86.8594C668.718 86.8594 666.434 89.131 666.434 91.9847C666.434 95.0837 668.932 97.1241 671.53 97.1241H671.53Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 202.93C654.504 202.93 652.219 205.062 652.219 207.661C652.219 210.521 654.723 212.405 657.331 212.405C658.579 212.405 659.85 211.974 660.888 211.019C664.063 208.021 661.816 202.93 657.289 202.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 159.508C654.504 159.508 652.219 161.602 652.219 164.243C652.219 167.094 654.708 168.979 657.308 168.979C658.565 168.979 659.845 168.543 660.888 167.565C664.063 164.604 661.816 159.508 657.289 159.508V159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.289 116.078C654.504 116.078 652.219 118.205 652.219 120.798C652.219 123.652 654.723 125.549 657.331 125.549C658.579 125.549 659.85 125.115 660.888 124.149C664.063 121.157 661.816 116.078 657.289 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M657.34 101.078H657.293C654.505 101.078 652.219 103.348 652.219 106.211C652.219 109.28 654.72 111.333 657.331 111.333C658.579 111.333 659.856 110.866 660.895 109.81C664.063 106.61 661.843 101.078 657.34 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 217.148C639.502 217.148 637.223 219.453 637.223 222.272C637.223 225.364 639.721 227.404 642.333 227.404C643.58 227.404 644.851 226.942 645.888 225.908C649.057 222.661 646.816 217.148 642.291 217.148H642.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 202.93C639.502 202.93 637.223 205.062 637.223 207.661C637.223 210.521 639.721 212.405 642.333 212.405C643.58 212.405 644.851 211.974 645.888 211.019C649.057 208.021 646.816 202.93 642.291 202.93H642.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 187.934C639.502 187.934 637.223 190.233 637.223 193.042C637.223 196.134 639.721 198.189 642.333 198.189C643.58 198.189 644.851 197.723 645.888 196.672C649.057 193.436 646.816 187.934 642.291 187.934H642.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 173.719C639.502 173.757 637.223 176.023 637.223 178.87C637.223 181.933 639.716 183.979 642.324 183.979C643.575 183.979 644.846 183.508 645.888 182.461C649.057 179.259 646.816 173.719 642.29 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.29 116.078C639.502 116.113 637.223 118.205 637.223 120.798C637.223 123.652 639.721 125.549 642.333 125.549C643.58 125.549 644.851 125.115 645.888 124.149C649.057 121.157 646.816 116.078 642.291 116.078H642.29Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.089 231.355H628.042C625.273 231.355 623 233.661 623 236.471C623 239.564 625.504 241.62 628.112 241.62C629.357 241.62 630.626 241.15 631.658 240.103C634.844 236.875 632.6 231.355 628.089 231.355H628.089Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.102 227.404C629.345 227.404 630.611 226.942 631.645 225.908C634.847 222.661 632.57 217.148 628.059 217.148C625.284 217.148 623.008 219.453 623.008 222.272C623.008 225.364 625.503 227.404 628.102 227.404H628.102Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.102 212.405C629.345 212.405 630.611 211.974 631.645 211.019C634.847 208.021 632.57 202.93 628.059 202.93C625.284 202.93 623.008 205.062 623.008 207.661C623.008 210.521 625.503 212.405 628.102 212.405H628.102Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.102 198.189C629.345 198.189 630.611 197.723 631.645 196.672C634.847 193.436 632.57 187.934 628.059 187.934C625.284 187.934 623.008 190.233 623.008 193.042C623.008 196.134 625.498 198.189 628.102 198.189H628.102Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.097 183.979C629.34 183.979 630.611 183.508 631.645 182.461C634.847 179.259 632.57 173.719 628.059 173.719C625.284 173.757 623.008 176.023 623.008 178.87C623.008 181.933 625.498 183.979 628.098 183.979H628.097Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.078 168.979C629.33 168.979 630.606 168.543 631.645 167.565C634.847 164.603 632.57 159.508 628.059 159.508C625.284 159.508 623.008 161.602 623.008 164.243C623.008 167.094 625.484 168.979 628.078 168.979L628.078 168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M628.102 154.765C629.345 154.765 630.611 154.298 631.645 153.263C634.847 150.015 632.57 144.5 628.059 144.5C625.284 144.5 623.008 146.81 623.008 149.625C623.008 152.724 625.503 154.765 628.102 154.765H628.102Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.887 246.363C611.069 246.363 608.835 248.663 608.797 251.477C608.797 254.563 611.306 256.618 613.905 256.618C615.148 256.618 616.41 256.153 617.434 255.106C620.631 251.865 618.359 246.363 613.886 246.363H613.887Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.921 231.355H613.874C611.063 231.355 608.835 233.661 608.797 236.471C608.797 239.564 611.3 241.62 613.907 241.62C615.152 241.62 616.42 241.15 617.452 240.103C620.631 236.875 618.389 231.355 613.921 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.906 227.404C615.148 227.404 616.41 226.942 617.435 225.908C620.632 222.661 618.36 217.148 613.887 217.148C611.069 217.148 608.835 219.453 608.797 222.272C608.797 225.364 611.306 227.404 613.905 227.404H613.906Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.906 198.189C615.148 198.189 616.41 197.723 617.435 196.672C620.632 193.436 618.36 187.934 613.887 187.934C611.069 187.934 608.835 190.233 608.797 193.042C608.797 196.134 611.306 198.189 613.905 198.189H613.906Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.882 168.979C615.134 168.979 616.405 168.543 617.435 167.565C620.632 164.603 618.36 159.508 613.887 159.508C611.069 159.508 608.835 161.602 608.797 164.243C608.797 167.094 611.292 168.979 613.882 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.906 154.765C615.148 154.765 616.41 154.298 617.435 153.263C620.632 150.015 618.36 144.5 613.887 144.5C611.069 144.5 608.835 146.81 608.797 149.625C608.797 152.724 611.306 154.765 613.905 154.765H613.906Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M613.906 140.545C615.148 140.545 616.41 140.083 617.435 139.048C620.632 135.802 618.36 130.289 613.887 130.289C611.069 130.289 608.835 132.593 608.797 135.407C608.797 138.505 611.306 140.544 613.905 140.544L613.906 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.889 256.618C600.126 256.618 601.393 256.153 602.416 255.106C605.63 251.865 603.382 246.363 598.855 246.363C596.032 246.363 593.785 248.663 593.785 251.477C593.785 254.563 596.284 256.618 598.888 256.618H598.889Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.886 241.62C600.13 241.62 601.398 241.15 602.433 240.103C605.629 236.875 603.378 231.355 598.89 231.355H598.843C596.027 231.355 593.785 233.661 593.785 236.471C593.785 239.564 596.279 241.62 598.886 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.889 227.404C600.126 227.404 601.393 226.942 602.416 225.908C605.63 222.661 603.382 217.148 598.855 217.148C596.032 217.148 593.785 219.453 593.785 222.272C593.785 225.364 596.289 227.404 598.888 227.404H598.889Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M598.889 154.765C600.126 154.765 601.393 154.298 602.416 153.263C605.63 150.015 603.382 144.5 598.855 144.5C596.032 144.5 593.785 146.81 593.785 149.625C593.785 152.724 596.289 154.765 598.888 154.765H598.889Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M584.65 159.508C581.861 159.508 579.582 161.602 579.582 164.243C579.582 167.094 582.066 168.979 584.669 168.979C585.92 168.979 587.2 168.543 588.247 167.565C591.416 164.604 589.175 159.508 584.65 159.508V159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.827 314.259C803.069 314.259 804.336 313.793 805.36 312.747C808.557 309.506 806.285 304.004 801.77 304.004C798.995 304.042 796.723 306.304 796.723 309.155C796.723 312.218 799.227 314.259 801.827 314.259Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.817 328.471C803.055 328.471 804.326 328.004 805.36 326.97C808.557 323.724 806.285 318.211 801.77 318.211C798.995 318.211 796.723 320.52 796.723 323.334C796.723 326.432 799.213 328.471 801.817 328.471Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 318.211C814.004 318.211 811.719 320.52 811.719 323.334C811.719 326.432 814.218 328.471 816.831 328.471C818.079 328.471 819.35 328.004 820.388 326.97C823.563 323.724 821.316 318.211 816.789 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 347.43C814.004 347.43 811.719 349.703 811.719 352.56C811.719 355.653 814.204 357.695 816.808 357.695C818.064 357.695 819.345 357.218 820.388 356.163C823.563 352.95 821.316 347.43 816.789 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 333.219C828.217 333.219 825.938 335.523 825.938 338.342C825.938 341.435 828.436 343.479 831.048 343.479C832.29 343.479 833.565 343.012 834.603 341.978C837.772 338.731 835.531 333.219 831.006 333.219H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 333.219C814.004 333.219 811.719 335.523 811.719 338.342C811.719 341.435 814.218 343.479 816.831 343.479C818.079 343.479 819.35 343.012 820.388 341.978C823.563 338.731 821.316 333.219 816.789 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.096 97.1241C933.34 97.1241 934.607 96.6573 935.641 95.6226C938.844 92.3743 936.566 86.8594 932.092 86.8594C929.278 86.8594 927.038 89.1695 927 91.9847C927 95.0837 929.491 97.1241 932.096 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 111.333C933.335 111.333 934.607 110.866 935.641 109.81C938.844 106.601 936.566 101.078 932.092 101.078C929.278 101.078 927.038 103.348 927 106.211C927 109.28 929.491 111.333 932.092 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.096 125.549C933.34 125.549 934.607 125.115 935.641 124.149C938.844 121.192 936.566 116.078 932.092 116.078C929.278 116.078 927.038 118.205 927 120.798C927 123.652 929.491 125.549 932.096 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.096 140.545C933.34 140.545 934.607 140.083 935.641 139.048C938.844 135.802 936.566 130.289 932.092 130.289C929.278 130.289 927.038 132.593 927 135.407C927 138.505 929.491 140.544 932.096 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.096 154.765C933.34 154.765 934.607 154.298 935.641 153.263C938.844 150.015 936.566 144.5 932.092 144.5C929.278 144.5 927.038 146.81 927 149.625C927 152.724 929.491 154.765 932.096 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.096 168.974C933.34 168.974 934.607 168.548 935.641 167.594C938.844 164.596 936.566 159.508 932.092 159.508C929.278 159.508 927.038 161.599 927 164.237C927 167.092 929.491 168.974 932.096 168.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 183.979C933.335 183.979 934.607 183.508 935.641 182.461C938.844 179.259 936.566 173.719 932.092 173.719C929.278 173.757 927.038 176.023 927 178.87C927 181.933 929.491 183.979 932.092 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.096 198.189C933.34 198.189 934.607 197.723 935.641 196.672C938.844 193.436 936.566 187.934 932.092 187.934C929.278 187.934 927.038 190.233 927 193.042C927 196.134 929.491 198.189 932.096 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.096 212.405C933.34 212.405 934.607 211.974 935.641 211.019C938.844 208.021 936.566 202.93 932.092 202.93C929.278 202.93 927.038 205.062 927 207.661C927 210.521 929.491 212.405 932.096 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.096 227.404C933.34 227.404 934.607 226.942 935.641 225.908C938.844 222.661 936.566 217.148 932.092 217.148C929.278 217.148 927.038 219.453 927 222.272C927 225.364 929.491 227.404 932.096 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.127 231.355H932.079C929.272 231.355 927 233.661 927 236.471C927 239.564 929.504 241.62 932.113 241.62C933.358 241.62 934.627 241.15 935.659 240.103C938.845 236.875 936.601 231.355 932.127 231.355H932.127Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 246.363C929.278 246.363 927 248.663 927 251.477C927 254.563 929.491 256.618 932.096 256.618C933.34 256.618 934.607 256.153 935.641 255.106C938.844 251.865 936.566 246.363 932.092 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 260.57C929.278 260.57 927.038 262.879 927 265.693C927 268.791 929.491 270.831 932.096 270.831C933.34 270.831 934.607 270.364 935.641 269.33C938.844 266.083 936.566 260.57 932.092 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 274.789C929.278 274.789 927 277.057 927 279.917C927 283.008 929.496 285.045 932.092 285.045C933.34 285.045 934.611 284.573 935.641 283.518C938.844 280.307 936.566 274.789 932.092 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.247 97.1241C861.485 97.1241 862.756 96.6573 863.79 95.6226C866.987 92.3743 864.715 86.8594 860.242 86.8594C857.425 86.8594 855.19 89.1695 855.152 91.9847C855.152 95.0837 857.643 97.1241 860.247 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.237 111.333C861.48 111.333 862.751 110.866 863.79 109.81C866.987 106.601 864.715 101.078 860.242 101.078C857.425 101.078 855.19 103.348 855.152 106.211C855.152 109.28 857.638 111.333 860.237 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.242 125.549C861.485 125.549 862.756 125.115 863.79 124.149C866.987 121.192 864.715 116.078 860.242 116.078C857.425 116.078 855.19 118.205 855.152 120.798C855.152 123.652 857.643 125.549 860.242 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.247 140.545C861.485 140.545 862.756 140.083 863.79 139.048C866.987 135.802 864.715 130.289 860.242 130.289C857.425 130.289 855.19 132.593 855.152 135.407C855.152 138.505 857.643 140.544 860.247 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.247 154.765C861.485 154.765 862.756 154.298 863.79 153.263C866.987 150.015 864.715 144.5 860.242 144.5C857.425 144.5 855.19 146.81 855.152 149.625C855.152 152.724 857.643 154.765 860.247 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.247 168.97C861.485 168.97 862.756 168.544 863.79 167.59C866.987 164.592 864.715 159.504 860.242 159.504C857.425 159.504 855.19 161.595 855.152 164.233C855.152 167.088 857.643 168.97 860.247 168.97Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.237 183.979C861.48 183.979 862.751 183.508 863.79 182.461C866.987 179.259 864.715 173.719 860.242 173.719C857.425 173.757 855.19 176.023 855.152 178.87C855.152 181.933 857.638 183.979 860.237 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M860.242 198.189C861.485 198.189 862.756 197.723 863.79 196.672C866.987 193.436 864.715 187.934 860.242 187.934C857.425 187.934 855.19 190.233 855.152 193.042C855.152 196.134 857.643 198.189 860.242 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.247 212.405C861.485 212.405 862.756 211.974 863.79 211.019C866.987 208.021 864.715 202.93 860.242 202.93C857.425 202.93 855.19 205.062 855.152 207.661C855.152 210.521 857.643 212.405 860.247 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.247 227.404C861.485 227.404 862.756 226.942 863.79 225.908C866.987 222.661 864.715 217.148 860.242 217.148C857.425 217.148 855.19 219.453 855.152 222.272C855.152 225.364 857.643 227.404 860.247 227.404Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.277 231.355H860.23C857.419 231.355 855.152 233.661 855.152 236.471C855.152 239.564 857.651 241.62 860.258 241.62C861.507 241.62 862.775 241.15 863.807 240.103C866.987 236.875 864.744 231.355 860.277 231.355H860.277Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.242 246.363C857.425 246.363 855.152 248.663 855.152 251.477C855.152 254.563 857.643 256.618 860.242 256.618C861.485 256.618 862.756 256.153 863.79 255.106C866.987 251.865 864.715 246.363 860.242 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.632 231.355H787.585C784.779 231.355 782.545 233.661 782.508 236.471C782.508 239.564 785.011 241.62 787.618 241.62C788.863 241.62 790.131 241.15 791.162 240.103C794.342 236.875 792.104 231.355 787.632 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 274.789C784.785 274.789 782.546 277.057 782.508 279.917C782.508 283.008 785.003 285.045 787.598 285.045C788.845 285.045 790.116 284.573 791.146 283.518C794.347 280.307 792.071 274.789 787.598 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 260.57C784.785 260.57 782.546 262.879 782.508 265.693C782.508 268.791 785.017 270.831 787.621 270.831C788.859 270.831 790.126 270.364 791.145 269.33C794.347 266.083 792.07 260.57 787.597 260.57H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 246.363C784.785 246.363 782.546 248.663 782.508 251.477C782.508 254.563 785.017 256.618 787.616 256.618C788.859 256.618 790.126 256.153 791.145 255.106C794.347 251.865 792.07 246.363 787.597 246.363H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.81 231.355H801.758C798.989 231.355 796.723 233.661 796.723 236.471C796.723 239.564 799.221 241.62 801.829 241.62C803.073 241.62 804.346 241.15 805.377 240.103C808.557 236.875 806.314 231.355 801.81 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.812 256.618C803.055 256.618 804.322 256.153 805.36 255.106C808.557 251.865 806.285 246.363 801.77 246.363C798.995 246.363 796.723 248.663 796.723 251.477C796.723 254.563 799.213 256.618 801.812 256.618Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.827 111.333C818.074 111.333 819.35 110.866 820.388 109.81C823.563 106.601 821.316 101.078 816.789 101.078C814.004 101.078 811.719 103.348 811.719 106.211C811.719 109.28 814.218 111.333 816.827 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.831 125.549C818.079 125.549 819.35 125.115 820.388 124.149C823.563 121.192 821.316 116.078 816.789 116.078C814.004 116.078 811.719 118.205 811.719 120.798C811.719 123.652 814.218 125.549 816.831 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.831 140.545C818.079 140.545 819.35 140.083 820.388 139.048C823.563 135.802 821.316 130.289 816.789 130.289C814.004 130.289 811.719 132.593 811.719 135.407C811.719 138.505 814.218 140.544 816.831 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.831 154.765C818.079 154.765 819.35 154.298 820.388 153.263C823.563 150.015 821.316 144.5 816.789 144.5C814.004 144.5 811.719 146.81 811.719 149.625C811.719 152.724 814.218 154.765 816.831 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.831 168.974C818.079 168.974 819.35 168.548 820.388 167.594C823.563 164.596 821.316 159.508 816.789 159.508C814.004 159.508 811.719 161.599 811.719 164.237C811.719 167.092 814.218 168.974 816.831 168.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.827 183.979C818.074 183.979 819.35 183.508 820.388 182.461C823.563 179.259 821.316 173.719 816.789 173.719C814.004 173.757 811.719 176.023 811.719 178.87C811.719 181.933 814.218 183.979 816.827 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.831 198.189C818.079 198.189 819.35 197.723 820.388 196.672C823.563 193.436 821.316 187.934 816.789 187.934C814.004 187.934 811.719 190.233 811.719 193.042C811.719 196.134 814.218 198.189 816.831 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.831 212.405C818.079 212.405 819.35 211.974 820.388 211.019C823.563 208.021 821.316 202.93 816.789 202.93C814.004 202.93 811.719 205.062 811.719 207.661C811.719 210.521 814.218 212.405 816.831 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.831 227.404C818.079 227.404 819.35 226.942 820.388 225.908C823.563 222.661 821.316 217.148 816.789 217.148C814.004 217.148 811.719 219.453 811.719 222.272C811.719 225.364 814.218 227.404 816.831 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.824 231.355H816.776C813.998 231.355 811.719 233.661 811.719 236.471C811.719 239.564 814.231 241.62 816.848 241.62C818.097 241.62 819.369 241.15 820.405 240.103C823.558 236.875 821.312 231.355 816.824 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 246.363C814.004 246.363 811.719 248.663 811.719 251.477C811.719 254.563 814.218 256.618 816.831 256.618C818.079 256.618 819.35 256.153 820.388 255.106C823.563 251.865 821.316 246.363 816.789 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 260.57C814.004 260.57 811.719 262.879 811.719 265.693C811.719 268.791 814.218 270.831 816.831 270.831C818.079 270.831 819.35 270.364 820.388 269.33C823.563 266.083 821.316 260.57 816.789 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 101.078C828.217 101.078 825.938 103.348 825.938 106.211C825.938 109.28 828.431 111.333 831.039 111.333C832.29 111.333 833.561 110.866 834.603 109.81C837.772 106.601 835.531 101.078 831.006 101.078H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 116.078C828.217 116.078 825.938 118.205 825.938 120.798C825.938 123.652 828.436 125.549 831.044 125.549C832.29 125.549 833.565 125.115 834.603 124.149C837.772 121.157 835.531 116.078 831.005 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 130.289C828.217 130.289 825.938 132.594 825.938 135.407C825.938 138.505 828.436 140.545 831.048 140.545C832.29 140.545 833.565 140.083 834.603 139.048C837.772 135.802 835.531 130.289 831.006 130.289L831.005 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 144.5C828.217 144.5 825.938 146.81 825.938 149.625C825.938 152.724 828.436 154.765 831.048 154.765C832.29 154.765 833.565 154.298 834.603 153.263C837.772 150.015 835.531 144.5 831.006 144.5H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 159.508C828.217 159.508 825.938 161.602 825.938 164.243C825.938 167.094 828.422 168.979 831.024 168.979C832.276 168.979 833.556 168.543 834.603 167.565C837.772 164.604 835.531 159.508 831.005 159.508V159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 173.719C828.217 173.757 825.938 176.023 825.938 178.87C825.938 181.933 828.431 183.979 831.039 183.979C832.29 183.979 833.561 183.508 834.603 182.461C837.772 179.259 835.531 173.719 831.006 173.719H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 187.934C828.217 187.934 825.938 190.233 825.938 193.042C825.938 196.134 828.436 198.189 831.044 198.189C832.29 198.189 833.565 197.723 834.603 196.672C837.772 193.436 835.531 187.934 831.005 187.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 202.93C828.217 202.93 825.938 205.062 825.938 207.661C825.938 210.521 828.436 212.405 831.048 212.405C832.29 212.405 833.565 211.974 834.603 211.019C837.772 208.021 835.531 202.93 831.006 202.93H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 217.148C828.217 217.148 825.938 219.453 825.938 222.272C825.938 225.364 828.436 227.404 831.048 227.404C832.29 227.404 833.565 226.942 834.603 225.908C837.772 222.661 835.531 217.148 831.006 217.148H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.052 241.62C832.296 241.62 833.56 241.15 834.585 240.103C837.777 236.875 835.526 231.355 831.043 231.355H830.995C828.212 231.355 825.938 233.661 825.938 236.471C825.938 239.564 828.445 241.62 831.052 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 246.363C828.217 246.363 825.938 248.663 825.938 251.477C825.938 254.563 828.436 256.618 831.044 256.618C832.29 256.618 833.565 256.153 834.603 255.106C837.772 251.865 835.531 246.363 831.005 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 260.57C828.217 260.57 825.938 262.879 825.938 265.693C825.938 268.791 828.436 270.831 831.048 270.831C832.29 270.831 833.565 270.364 834.603 269.33C837.772 266.083 835.531 260.57 831.006 260.57H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.242 111.333C846.485 111.333 847.751 110.866 848.779 109.81C851.992 106.601 849.746 101.078 845.218 101.078C842.395 101.078 840.148 103.348 840.148 106.211C840.148 109.28 842.648 111.333 845.242 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.247 125.549C846.489 125.549 847.756 125.115 848.779 124.149C851.992 121.157 849.746 116.078 845.218 116.078C842.395 116.113 840.148 118.205 840.148 120.798C840.148 123.652 842.648 125.549 845.247 125.549H845.247Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.252 140.545C846.489 140.545 847.756 140.083 848.779 139.048C851.992 135.802 849.746 130.289 845.218 130.289C842.395 130.289 840.148 132.593 840.148 135.407C840.148 138.505 842.648 140.544 845.252 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.252 154.765C846.489 154.765 847.756 154.298 848.779 153.263C851.992 150.015 849.746 144.5 845.218 144.5C842.395 144.5 840.148 146.81 840.148 149.625C840.148 152.724 842.648 154.765 845.252 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.228 168.979C846.475 168.979 847.746 168.543 848.779 167.565C851.992 164.603 849.746 159.508 845.218 159.508C842.395 159.508 840.148 161.602 840.148 164.243C840.148 167.094 842.633 168.979 845.228 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.242 183.979C846.485 183.979 847.751 183.508 848.779 182.461C851.992 179.259 849.746 173.719 845.218 173.719C842.395 173.757 840.148 176.023 840.148 178.87C840.148 181.933 842.648 183.979 845.242 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.247 198.189C846.489 198.189 847.756 197.723 848.779 196.672C851.992 193.436 849.746 187.934 845.218 187.934C842.395 187.934 840.148 190.233 840.148 193.042C840.148 196.134 842.648 198.189 845.247 198.189H845.247Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.252 212.405C846.489 212.405 847.756 211.974 848.779 211.019C851.992 208.021 849.746 202.93 845.218 202.93C842.395 202.93 840.148 205.062 840.148 207.661C840.148 210.521 842.648 212.405 845.252 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.252 227.404C846.489 227.404 847.756 226.942 848.779 225.908C851.992 222.661 849.746 217.148 845.218 217.148C842.395 217.148 840.148 219.453 840.148 222.272C840.148 225.364 842.648 227.404 845.252 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.249 241.62C846.493 241.62 847.761 241.15 848.796 240.103C851.992 236.875 849.742 231.355 845.254 231.355H845.206C842.39 231.355 840.148 233.661 840.148 236.471C840.148 239.564 842.642 241.62 845.249 241.62H845.249Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.247 256.618C846.489 256.618 847.756 256.153 848.779 255.106C851.992 251.865 849.746 246.363 845.218 246.363C842.395 246.363 840.148 248.663 840.148 251.477C840.148 254.563 842.648 256.618 845.247 256.618H845.247Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.252 270.831C846.489 270.831 847.756 270.364 848.779 269.33C851.992 266.083 849.746 260.57 845.218 260.57C842.395 260.57 840.148 262.879 840.148 265.693C840.148 268.791 842.648 270.831 845.252 270.831Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.242 260.57C857.425 260.57 855.19 262.879 855.152 265.693C855.152 268.791 857.643 270.831 860.247 270.831C861.485 270.831 862.756 270.364 863.79 269.33C866.987 266.083 864.715 260.57 860.242 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M860.242 274.789C857.425 274.789 855.152 277.057 855.152 279.917C855.152 283.008 857.643 285.045 860.237 285.045C861.49 285.045 862.761 284.573 863.79 283.518C866.987 280.307 864.715 274.789 860.242 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 97.1241C875.703 97.1241 876.97 96.6573 878.004 95.6226C881.207 92.3743 878.93 86.8594 874.417 86.8594C871.641 86.8594 869.363 89.1695 869.363 91.9847C869.363 95.0837 871.855 97.1241 874.46 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.455 111.333C875.698 111.333 876.97 110.866 878.004 109.81C881.207 106.601 878.93 101.078 874.417 101.078C871.641 101.078 869.363 103.348 869.363 106.211C869.363 109.28 871.855 111.333 874.455 111.333Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 125.549C875.703 125.549 876.97 125.115 878.004 124.149C881.207 121.157 878.93 116.078 874.417 116.078C871.641 116.113 869.363 118.205 869.363 120.798C869.363 123.652 871.855 125.549 874.46 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 140.545C875.703 140.545 876.97 140.083 878.004 139.048C881.207 135.802 878.93 130.289 874.417 130.289C871.641 130.289 869.363 132.593 869.363 135.407C869.363 138.505 871.855 140.544 874.46 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 154.765C875.703 154.765 876.97 154.298 878.004 153.263C881.207 150.015 878.93 144.5 874.417 144.5C871.641 144.5 869.363 146.81 869.363 149.625C869.363 152.724 871.855 154.765 874.46 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.436 168.979C875.689 168.979 876.965 168.543 878.004 167.565C881.207 164.603 878.93 159.508 874.417 159.508C871.641 159.508 869.363 161.602 869.363 164.243C869.363 167.094 871.84 168.979 874.436 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.455 183.979C875.698 183.979 876.97 183.508 878.004 182.461C881.207 179.259 878.93 173.719 874.417 173.719C871.641 173.757 869.363 176.023 869.363 178.87C869.363 181.933 871.855 183.979 874.455 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 198.189C875.703 198.189 876.97 197.723 878.004 196.672C881.207 193.436 878.93 187.934 874.417 187.934C871.641 187.934 869.363 190.233 869.363 193.042C869.363 196.134 871.855 198.189 874.46 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 212.405C875.703 212.405 876.97 211.974 878.004 211.019C881.207 208.021 878.93 202.93 874.417 202.93C871.641 202.93 869.363 205.062 869.363 207.661C869.363 210.521 871.855 212.405 874.46 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 227.404C875.703 227.404 876.97 226.942 878.004 225.908C881.207 222.661 878.93 217.148 874.417 217.148C871.641 217.148 869.363 219.453 869.363 222.272C869.363 225.364 871.855 227.404 874.46 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.452 231.355H874.405C871.636 231.355 869.363 233.661 869.363 236.471C869.363 239.564 871.868 241.62 874.476 241.62C875.721 241.62 876.99 241.15 878.021 240.103C881.207 236.875 878.964 231.355 874.452 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.417 246.363C871.641 246.363 869.363 248.663 869.363 251.477C869.363 254.563 871.855 256.618 874.46 256.618C875.703 256.618 876.97 256.153 878.004 255.106C881.207 251.865 878.93 246.363 874.417 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.417 260.57C871.641 260.57 869.363 262.879 869.363 265.693C869.363 268.791 871.855 270.831 874.46 270.831C875.703 270.831 876.97 270.364 878.004 269.33C881.207 266.083 878.93 260.57 874.417 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.417 274.789C871.641 274.789 869.363 277.096 869.363 279.917C869.363 283.008 871.84 285.045 874.436 285.045C875.689 285.045 876.965 284.573 878.004 283.518C881.207 280.307 878.93 274.789 874.417 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 86.8594C885.857 86.8594 883.578 89.1695 883.578 91.9847C883.578 95.0837 886.076 97.1241 888.689 97.1241C889.931 97.1241 891.206 96.6573 892.244 95.6226C895.413 92.3743 893.171 86.8594 888.646 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 101.078C885.857 101.078 883.578 103.348 883.578 106.211C883.578 109.28 886.072 111.333 888.679 111.333C889.931 111.333 891.201 110.866 892.244 109.81C895.413 106.601 893.171 101.078 888.646 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 116.078C885.857 116.078 883.578 118.205 883.578 120.798C883.578 123.652 886.076 125.549 888.684 125.549C889.931 125.549 891.206 125.115 892.244 124.149C895.413 121.192 893.171 116.078 888.646 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 130.289C885.857 130.289 883.578 132.594 883.578 135.407C883.578 138.505 886.076 140.545 888.689 140.545C889.931 140.545 891.206 140.083 892.244 139.048C895.413 135.802 893.171 130.289 888.646 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 144.5C885.857 144.5 883.578 146.81 883.578 149.625C883.578 152.724 886.076 154.765 888.689 154.765C889.931 154.765 891.206 154.298 892.244 153.263C895.413 150.015 893.171 144.5 888.646 144.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 159.508C885.857 159.508 883.578 161.599 883.578 164.237C883.578 167.092 886.076 168.974 888.689 168.974C889.931 168.974 891.206 168.548 892.244 167.594C895.413 164.596 893.171 159.508 888.646 159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 173.719C885.857 173.757 883.578 176.023 883.578 178.87C883.578 181.933 886.072 183.979 888.679 183.979C889.931 183.979 891.201 183.508 892.244 182.461C895.413 179.259 893.171 173.719 888.646 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 187.934C885.857 187.934 883.578 190.233 883.578 193.042C883.578 196.134 886.076 198.189 888.684 198.189C889.931 198.189 891.206 197.723 892.244 196.672C895.413 193.436 893.171 187.934 888.646 187.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 202.93C885.857 202.93 883.578 205.062 883.578 207.661C883.578 210.521 886.076 212.405 888.689 212.405C889.931 212.405 891.206 211.974 892.244 211.019C895.413 208.021 893.171 202.93 888.646 202.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 217.148C885.857 217.148 883.578 219.453 883.578 222.272C883.578 225.364 886.076 227.404 888.689 227.404C889.931 227.404 891.206 226.942 892.244 225.908C895.413 222.661 893.171 217.148 888.646 217.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.691 241.62C889.935 241.62 891.197 241.15 892.223 240.103C895.413 236.875 893.163 231.355 888.681 231.355H888.634C885.852 231.355 883.578 233.661 883.578 236.471C883.578 239.564 886.089 241.62 888.691 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 246.363C885.857 246.363 883.578 248.663 883.578 251.477C883.578 254.563 886.076 256.618 888.684 256.618C889.931 256.618 891.206 256.153 892.244 255.106C895.413 251.865 893.171 246.363 888.646 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 260.57C885.857 260.57 883.578 262.879 883.578 265.693C883.578 268.791 886.076 270.831 888.689 270.831C889.931 270.831 891.206 270.364 892.244 269.33C895.413 266.083 893.171 260.57 888.646 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 274.789C885.857 274.789 883.578 277.057 883.578 279.917C883.578 283.008 886.062 285.045 888.665 285.045C889.916 285.045 891.197 284.573 892.244 283.518C895.413 280.307 893.171 274.789 888.646 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 289.785C885.857 289.824 883.578 292.091 883.578 294.939C883.578 298.004 886.072 300.05 888.679 300.05C889.931 300.05 891.201 299.579 892.244 298.532C895.413 295.328 893.171 289.785 888.646 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 101.078C900.859 101.078 898.574 103.348 898.574 106.211C898.574 109.28 901.073 111.333 903.682 111.333C904.929 111.333 906.205 110.866 907.243 109.81C910.418 106.601 908.172 101.078 903.644 101.078H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 116.078C900.859 116.078 898.574 118.205 898.574 120.798C898.574 123.652 901.073 125.549 903.687 125.549C904.934 125.549 906.205 125.115 907.243 124.149C910.418 121.157 908.172 116.078 903.644 116.078H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 130.289C900.859 130.289 898.574 132.594 898.574 135.407C898.574 138.505 901.073 140.545 903.687 140.545C904.934 140.545 906.205 140.083 907.243 139.048C910.418 135.802 908.172 130.289 903.644 130.289L903.644 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 144.5C900.859 144.5 898.574 146.81 898.574 149.625C898.574 152.724 901.073 154.765 903.687 154.765C904.934 154.765 906.205 154.298 907.243 153.263C910.418 150.015 908.172 144.5 903.644 144.5H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 159.508C900.859 159.508 898.574 161.602 898.574 164.243C898.574 167.094 901.059 168.979 903.663 168.979C904.92 168.979 906.2 168.543 907.243 167.565C910.418 164.604 908.172 159.508 903.644 159.508L903.644 159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 173.719C900.859 173.757 898.574 176.023 898.574 178.87C898.574 181.933 901.073 183.979 903.682 183.979C904.929 183.979 906.205 183.508 907.243 182.461C910.418 179.259 908.172 173.719 903.644 173.719H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 187.934C900.859 187.934 898.574 190.233 898.574 193.042C898.574 196.134 901.073 198.189 903.687 198.189C904.934 198.189 906.205 197.723 907.243 196.672C910.418 193.436 908.172 187.934 903.644 187.934H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 202.93C900.859 202.93 898.574 205.062 898.574 207.661C898.574 210.521 901.073 212.405 903.687 212.405C904.934 212.405 906.205 211.974 907.243 211.019C910.418 208.021 908.172 202.93 903.644 202.93H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 217.148C900.859 217.148 898.574 219.453 898.574 222.272C898.574 225.364 901.073 227.404 903.687 227.404C904.934 227.404 906.205 226.942 907.243 225.908C910.418 222.661 908.172 217.148 903.644 217.148H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.694 241.62C904.933 241.62 906.201 241.15 907.222 240.103C910.418 236.875 908.167 231.355 903.679 231.355H903.632C900.816 231.355 898.574 233.661 898.574 236.471C898.574 239.564 901.086 241.62 903.694 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 246.363C900.859 246.363 898.574 248.663 898.574 251.477C898.574 254.563 901.073 256.618 903.687 256.618C904.934 256.618 906.205 256.153 907.243 255.106C910.418 251.865 908.172 246.363 903.644 246.363H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 260.57C900.859 260.57 898.574 262.879 898.574 265.693C898.574 268.791 901.073 270.831 903.687 270.831C904.934 270.831 906.205 270.364 907.243 269.33C910.418 266.083 908.172 260.57 903.644 260.57H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 274.789C900.821 274.789 898.574 277.057 898.574 279.917C898.574 283.008 901.059 285.045 903.663 285.045C904.92 285.045 906.2 284.573 907.243 283.518C910.418 280.307 908.172 274.789 903.644 274.789L903.644 274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 289.785C900.859 289.824 898.574 292.091 898.574 294.939C898.574 298.004 901.073 300.05 903.682 300.05C904.929 300.05 906.205 299.579 907.243 298.532C910.418 295.328 908.172 289.785 903.644 289.785H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.881 111.333C919.123 111.333 920.388 110.866 921.416 109.81C924.624 106.601 922.344 101.078 917.857 101.078C915.03 101.078 912.789 103.348 912.789 106.211C912.789 109.28 915.283 111.333 917.881 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.886 125.549C919.127 125.549 920.388 125.115 921.416 124.149C924.624 121.157 922.344 116.078 917.857 116.078C915.03 116.113 912.789 118.205 912.789 120.798C912.789 123.652 915.287 125.549 917.886 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.886 140.545C919.127 140.545 920.388 140.083 921.416 139.048C924.624 135.802 922.344 130.289 917.857 130.289C915.03 130.289 912.789 132.593 912.789 135.407C912.789 138.505 915.287 140.544 917.886 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.886 154.765C919.127 154.765 920.388 154.298 921.416 153.263C924.624 150.015 922.344 144.5 917.857 144.5C915.03 144.5 912.789 146.81 912.789 149.625C912.789 152.724 915.287 154.765 917.886 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.869 168.979C919.121 168.979 920.391 168.543 921.424 167.565C924.632 164.603 922.352 159.508 917.865 159.508C915.038 159.508 912.797 161.602 912.797 164.243C912.797 167.094 915.281 168.979 917.869 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.881 183.979C919.123 183.979 920.388 183.508 921.416 182.461C924.624 179.259 922.344 173.719 917.857 173.719C915.03 173.757 912.789 176.023 912.789 178.87C912.789 181.933 915.283 183.979 917.881 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.886 198.189C919.127 198.189 920.388 197.723 921.416 196.672C924.624 193.436 922.344 187.934 917.857 187.934C915.03 187.934 912.789 190.233 912.789 193.042C912.789 196.134 915.287 198.189 917.886 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.886 212.405C919.127 212.405 920.388 211.974 921.416 211.019C924.624 208.021 922.344 202.93 917.857 202.93C915.03 202.93 912.789 205.062 912.789 207.661C912.789 210.521 915.287 212.405 917.886 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.886 227.404C919.127 227.404 920.388 226.942 921.416 225.908C924.624 222.661 922.344 217.148 917.857 217.148C915.03 217.148 912.789 219.453 912.789 222.272C912.789 225.364 915.287 227.404 917.886 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.892 231.355H917.845C915.025 231.355 912.789 233.661 912.789 236.471C912.789 239.564 915.281 241.62 917.887 241.62C919.127 241.62 920.399 241.15 921.434 240.103C924.624 236.875 922.373 231.355 917.892 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.857 246.363C915.03 246.363 912.789 248.663 912.789 251.477C912.789 254.563 915.287 256.618 917.886 256.618C919.127 256.618 920.388 256.153 921.416 255.106C924.624 251.865 922.344 246.363 917.857 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.857 260.57C915.03 260.57 912.789 262.879 912.789 265.693C912.789 268.791 915.287 270.831 917.886 270.831C919.127 270.831 920.388 270.364 921.416 269.33C924.624 266.083 922.344 260.57 917.857 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.857 274.789C915.03 274.789 912.789 277.096 912.789 279.917C912.789 283.008 915.273 285.045 917.862 285.045C919.113 285.045 920.384 284.573 921.416 283.518C924.624 280.307 922.344 274.789 917.857 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.857 289.785C915.03 289.824 912.789 292.091 912.789 294.939C912.789 298.004 915.283 300.05 917.881 300.05C919.123 300.05 920.388 299.579 921.416 298.532C924.624 295.328 922.344 289.785 917.857 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 289.785C929.278 289.824 927.038 292.091 927 294.939C927 298.004 929.491 300.05 932.092 300.05C933.335 300.05 934.607 299.579 935.641 298.532C938.844 295.328 936.566 289.785 932.092 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 304.004C929.278 304.004 927 306.304 927 309.155C927 312.218 929.505 314.259 932.106 314.259C933.354 314.259 934.616 313.793 935.641 312.747C938.844 309.545 936.566 304.004 932.092 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 72.6484C944.291 72.6484 942.012 74.7752 942.012 77.3727C942.012 80.2323 944.51 82.115 947.108 82.115C948.35 82.115 949.611 81.6889 950.639 80.7341C953.846 77.7724 951.605 72.6484 947.079 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 86.8594C944.291 86.8594 942.012 89.131 942.012 91.9847C942.012 95.0837 944.491 97.1241 947.094 97.1241C948.331 97.1241 949.602 96.6573 950.639 95.6226C953.846 92.413 951.605 86.8594 947.079 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 101.075C947.056 101.075 947.032 101.07 947.008 101.07C944.253 101.07 942.012 103.367 942.012 106.203C942.012 109.279 944.501 111.335 947.108 111.335C948.345 111.335 949.607 110.873 950.639 109.843C953.846 106.593 951.605 101.075 947.079 101.075Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 116.078C944.291 116.078 942.012 118.205 942.012 120.798C942.012 123.652 944.491 125.549 947.089 125.549C948.331 125.549 949.602 125.115 950.639 124.149C953.846 121.192 951.605 116.113 947.079 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 130.289C944.291 130.289 942.012 132.594 942.012 135.407C942.012 138.505 944.51 140.545 947.108 140.545C948.35 140.545 949.611 140.083 950.639 139.048C953.846 135.84 951.605 130.328 947.079 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 144.5C944.291 144.5 942.012 146.772 942.012 149.625C942.012 152.724 944.51 154.765 947.108 154.765C948.35 154.765 949.611 154.298 950.639 153.263C953.846 150.015 951.605 144.5 947.079 144.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 159.508C944.291 159.508 942.012 161.601 942.012 164.241C942.012 167.081 944.501 168.979 947.108 168.979C948.345 168.979 949.607 168.552 950.639 167.601C953.846 164.601 951.605 159.508 947.079 159.508V159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 173.719C944.291 173.719 942.012 176.023 942.012 178.87C942.012 181.933 944.505 183.979 947.103 183.979C948.345 183.979 949.611 183.508 950.639 182.461C953.846 179.259 951.605 173.757 947.079 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 187.934C944.291 187.934 942.012 190.238 942.012 193.052C942.012 196.15 944.51 198.189 947.108 198.189C948.35 198.189 949.611 197.727 950.639 196.688C953.846 193.485 951.605 187.972 947.079 187.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 202.93C944.291 202.93 942.012 205.062 942.012 207.661C942.012 210.521 944.491 212.405 947.094 212.405C948.331 212.405 949.602 211.974 950.639 211.019C953.846 208.056 951.605 202.965 947.079 202.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 217.148C944.291 217.148 942.012 219.414 942.012 222.272C942.012 225.364 944.491 227.404 947.094 227.404C948.331 227.404 949.602 226.942 950.639 225.908C953.846 222.7 951.605 217.148 947.079 217.148Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.101 241.62C948.344 241.62 949.615 241.15 950.649 240.103C953.846 236.86 951.575 231.355 947.064 231.355C944.284 231.355 942.012 233.623 942.012 236.471C942.012 239.564 944.502 241.62 947.101 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 246.364C944.291 246.364 942.012 248.49 942.012 251.092C942.012 253.947 944.491 255.834 947.094 255.834C948.331 255.834 949.602 255.404 950.639 254.449C953.846 251.487 951.605 246.399 947.079 246.363V246.364Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 260.57C944.291 260.57 942.012 262.841 942.012 265.693C942.012 268.791 944.51 270.831 947.108 270.831C948.35 270.831 949.611 270.364 950.639 269.33C953.846 266.083 951.605 260.57 947.079 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.083 285.045C948.33 285.045 949.606 284.573 950.649 283.518C953.846 280.307 951.575 274.789 947.064 274.789C944.284 274.789 942.012 277.095 942.012 279.917C942.012 283.008 944.488 285.044 947.082 285.044L947.083 285.045Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 289.785C944.291 289.785 942.012 292.091 942.012 294.939C942.012 298.004 944.486 300.05 947.084 300.05C948.326 300.05 949.597 299.579 950.639 298.532C953.846 295.328 951.605 289.824 947.079 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 318.211C944.291 318.211 942.012 320.52 942.012 323.334C942.012 326.432 944.491 328.471 947.094 328.471C948.331 328.471 949.602 328.004 950.639 326.97C953.846 323.767 951.605 318.249 947.079 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.097 314.259C948.344 314.259 949.611 313.793 950.649 312.747C953.846 309.506 951.575 304.004 947.064 304.004C944.284 304.042 942.012 306.304 942.012 309.155C942.012 312.218 944.497 314.259 947.097 314.259Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 67.9087C962.546 67.9087 963.812 67.4419 964.85 66.4077C968.063 63.1995 965.816 57.6869 961.289 57.6484C958.504 57.6484 956.219 59.9574 956.219 62.7715C956.219 65.8691 958.704 67.9087 961.303 67.9087Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 82.115C962.546 82.115 963.812 81.6889 964.85 80.7341C968.063 77.7724 965.816 72.6841 961.289 72.6484C958.504 72.6484 956.219 74.7752 956.219 77.3727C956.219 80.2323 958.704 82.115 961.303 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 97.1241C962.546 97.1241 963.812 96.6573 964.85 95.6226C968.063 92.413 965.816 86.8594 961.289 86.8594C958.504 86.8594 956.219 89.131 956.219 91.9847C956.219 95.0837 958.704 97.1241 961.303 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.322 111.335C962.555 111.335 963.816 110.873 964.849 109.843C968.063 106.593 965.816 101.075 961.289 101.075C961.265 101.075 961.241 101.07 961.222 101.07C958.466 101.07 956.219 103.367 956.219 106.203C956.219 109.279 958.713 111.335 961.322 111.335Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 125.549C962.546 125.549 963.812 125.115 964.85 124.149C968.063 121.192 965.816 116.113 961.289 116.078C958.504 116.078 956.219 118.205 956.219 120.798C956.219 123.652 958.704 125.549 961.303 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 140.545C962.546 140.545 963.812 140.083 964.85 139.048C968.063 135.84 965.816 130.328 961.289 130.289C958.504 130.289 956.219 132.593 956.219 135.407C956.219 138.505 958.704 140.544 961.303 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 154.765C962.546 154.765 963.812 154.298 964.85 153.263C968.063 150.053 965.816 144.5 961.289 144.5C958.504 144.5 956.219 146.772 956.219 149.625C956.219 152.724 958.704 154.765 961.303 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.322 168.979C962.555 168.979 963.817 168.552 964.85 167.601C968.063 164.601 965.816 159.508 961.289 159.508C958.504 159.508 956.219 161.601 956.219 164.241C956.219 167.081 958.713 168.979 961.322 168.979L961.322 168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.298 183.979C962.541 183.979 963.812 183.508 964.85 182.461C968.063 179.259 965.816 173.757 961.289 173.719C958.504 173.719 956.219 176.023 956.219 178.87C956.219 181.933 958.699 183.979 961.298 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 198.189C962.546 198.189 963.812 197.727 964.85 196.688C968.063 193.485 965.816 187.972 961.289 187.934C958.504 187.934 956.219 190.238 956.219 193.052C956.219 196.15 958.704 198.189 961.303 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 212.405C962.546 212.405 963.812 211.974 964.85 211.019C968.063 208.056 965.816 202.965 961.289 202.93C958.504 202.93 956.219 205.062 956.219 207.661C956.219 210.521 958.704 212.405 961.303 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 227.404C962.546 227.404 963.812 226.942 964.85 225.908C968.063 222.7 965.816 217.148 961.289 217.148C958.504 217.148 956.219 219.414 956.219 222.272C956.219 225.364 958.704 227.404 961.303 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.315 241.62C962.558 241.62 963.825 241.15 964.86 240.103C968.063 236.898 965.785 231.355 961.272 231.355C958.497 231.355 956.219 233.623 956.219 236.471C956.219 239.564 958.71 241.62 961.315 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 255.834C962.546 255.834 963.812 255.404 964.85 254.449C968.063 251.487 965.816 246.399 961.289 246.363C958.504 246.363 956.219 248.49 956.219 251.092C956.219 253.947 958.704 255.834 961.303 255.834V255.834Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.303 270.831C962.546 270.831 963.812 270.364 964.85 269.33C968.063 266.126 965.816 260.57 961.289 260.57C958.504 260.57 956.219 262.841 956.219 265.693C956.219 268.791 958.704 270.831 961.303 270.831Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.289 274.789C958.504 274.789 956.219 277.055 956.219 279.912C956.219 283.01 958.723 285.049 961.331 285.049C962.579 285.049 963.85 284.583 964.888 283.548C968.063 280.302 965.816 274.789 961.289 274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.298 300.05C962.541 300.05 963.812 299.579 964.85 298.532C968.063 295.328 965.816 289.824 961.289 289.785C958.504 289.785 956.219 292.091 956.219 294.939C956.219 298.004 958.699 300.05 961.298 300.05Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.289 304.004C958.504 304.004 956.219 306.304 956.219 309.155C956.219 312.218 958.718 314.259 961.327 314.259C962.574 314.259 963.85 313.793 964.888 312.747C968.063 309.545 965.816 304.004 961.289 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M975.546 67.9084C976.789 67.9084 978.051 67.4377 979.075 66.3911C982.277 63.1504 980 57.6484 975.527 57.6484C972.709 57.6484 970.475 59.9529 970.438 62.7617C970.438 65.8534 972.947 67.9084 975.546 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.546 82.115C976.789 82.115 978.051 81.6889 979.075 80.7341C982.277 77.7367 980 72.6484 975.527 72.6484C972.709 72.6484 970.475 74.7752 970.438 77.3727C970.438 80.2323 972.947 82.115 975.546 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.546 97.1241C976.789 97.1241 978.051 96.6573 979.075 95.6226C982.277 92.3743 980 86.8594 975.527 86.8594C972.709 86.8594 970.475 89.1695 970.438 91.9847C970.438 95.0837 972.947 97.1241 975.546 97.1241Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.545 111.333C976.789 111.333 978.057 110.866 979.082 109.81C982.272 106.61 980.022 101.078 975.579 101.078H975.531C972.711 101.078 970.475 103.348 970.438 106.211C970.438 109.28 972.944 111.333 975.545 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.546 125.549C976.789 125.549 978.051 125.115 979.075 124.149C982.277 121.157 980 116.078 975.527 116.078C972.709 116.113 970.475 118.205 970.438 120.798C970.438 123.652 972.947 125.549 975.546 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.546 140.545C976.789 140.545 978.051 140.083 979.075 139.048C982.277 135.802 980 130.289 975.527 130.289C972.709 130.289 970.475 132.593 970.438 135.407C970.438 138.505 972.947 140.544 975.546 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.546 154.765C976.789 154.765 978.051 154.298 979.075 153.263C982.277 150.015 980 144.5 975.527 144.5C972.709 144.5 970.475 146.81 970.438 149.625C970.438 152.724 972.947 154.765 975.546 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.527 168.979C976.775 168.979 978.046 168.543 979.075 167.565C982.277 164.603 980 159.508 975.527 159.508C972.709 159.508 970.475 161.602 970.438 164.243C970.438 167.094 972.933 168.979 975.527 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.542 183.979C976.784 183.979 978.051 183.508 979.075 182.461C982.277 179.259 980 173.719 975.527 173.719C972.709 173.757 970.475 176.023 970.438 178.87C970.438 181.933 972.942 183.979 975.541 183.979H975.542Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.546 198.189C976.789 198.189 978.051 197.723 979.075 196.672C982.277 193.436 980 187.934 975.527 187.934C972.709 187.934 970.475 190.233 970.438 193.042C970.438 196.134 972.947 198.189 975.546 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.546 212.405C976.789 212.405 978.051 211.974 979.075 211.019C982.277 208.021 980 202.93 975.527 202.93C972.709 202.93 970.475 205.062 970.438 207.661C970.438 210.521 972.947 212.405 975.546 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.546 227.404C976.789 227.404 978.051 226.942 979.075 225.908C982.277 222.661 980 217.148 975.527 217.148C972.709 217.148 970.475 219.453 970.438 222.272C970.438 225.364 972.947 227.404 975.546 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.562 231.355H975.515C972.704 231.355 970.475 233.661 970.438 236.471C970.438 239.564 972.941 241.62 975.548 241.62C976.792 241.62 978.061 241.15 979.092 240.103C982.272 236.875 980.029 231.355 975.562 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.527 246.363C972.709 246.363 970.475 248.663 970.438 251.477C970.438 254.563 972.947 256.618 975.546 256.618C976.789 256.618 978.051 256.153 979.075 255.106C982.277 251.865 980 246.363 975.527 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.527 274.789C972.709 274.789 970.475 277.096 970.438 279.917C970.438 283.008 972.933 285.045 975.527 285.045C976.775 285.045 978.046 284.573 979.075 283.518C982.277 280.307 980 274.789 975.527 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.527 289.785C972.709 289.824 970.475 292.091 970.438 294.939C970.438 298.004 972.942 300.05 975.541 300.05C976.784 300.05 978.051 299.579 979.075 298.532C982.277 295.328 980 289.785 975.527 289.785H975.527Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.527 304.004C972.709 304.042 970.475 306.304 970.438 309.155C970.438 312.218 972.942 314.259 975.541 314.259C976.784 314.259 978.051 313.793 979.075 312.747C982.277 309.506 980 304.004 975.527 304.004H975.527Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.528 67.9084C991.771 67.9084 993.037 67.4377 994.071 66.3911C997.273 63.1504 994.996 57.6484 990.485 57.6484C987.71 57.6484 985.434 59.9529 985.434 62.7617C985.434 65.8534 987.924 67.9084 990.528 67.9084H990.528Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.528 82.115C991.771 82.115 993.037 81.6889 994.071 80.7341C997.273 77.7367 994.996 72.6484 990.485 72.6484C987.71 72.6484 985.434 74.7752 985.434 77.3727C985.434 80.2323 987.929 82.115 990.528 82.115H990.528Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.528 97.1241C991.771 97.1241 993.037 96.6573 994.071 95.6226C997.273 92.3743 994.996 86.8594 990.485 86.8594C987.71 86.8594 985.434 89.1695 985.434 91.9847C985.434 95.0837 987.929 97.1241 990.528 97.1241H990.528Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.523 111.333C991.766 111.333 993.037 110.866 994.071 109.81C997.273 106.601 994.996 101.078 990.485 101.078C987.71 101.078 985.434 103.348 985.434 106.211C985.434 109.28 987.924 111.333 990.523 111.333H990.523Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.528 125.549C991.771 125.549 993.037 125.115 994.071 124.149C997.273 121.157 994.996 116.078 990.485 116.078C987.71 116.078 985.434 118.205 985.434 120.798C985.434 123.652 987.924 125.549 990.528 125.549H990.528Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.528 140.545C991.771 140.545 993.037 140.083 994.071 139.048C997.273 135.802 994.996 130.289 990.485 130.289C987.71 130.289 985.434 132.593 985.434 135.407C985.434 138.505 987.929 140.544 990.528 140.544L990.528 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.528 154.765C991.771 154.765 993.037 154.298 994.071 153.263C997.273 150.015 994.996 144.5 990.485 144.5C987.71 144.5 985.434 146.81 985.434 149.625C985.434 152.724 987.929 154.765 990.528 154.765H990.528Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.504 168.979C991.756 168.979 993.032 168.543 994.071 167.565C997.273 164.603 994.996 159.508 990.485 159.508C987.71 159.508 985.434 161.602 985.434 164.243C985.434 167.094 987.91 168.979 990.504 168.979L990.504 168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.523 183.979C991.766 183.979 993.037 183.508 994.071 182.461C997.273 179.259 994.996 173.719 990.485 173.719C987.71 173.757 985.434 176.023 985.434 178.87C985.434 181.933 987.924 183.979 990.523 183.979H990.523Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.528 198.189C991.771 198.189 993.037 197.723 994.071 196.672C997.273 193.436 994.996 187.934 990.485 187.934C987.71 187.934 985.434 190.233 985.434 193.042C985.434 196.134 987.924 198.189 990.528 198.189H990.528Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.528 212.405C991.771 212.405 993.037 211.974 994.071 211.019C997.273 208.021 994.996 202.93 990.485 202.93C987.71 202.93 985.434 205.062 985.434 207.661C985.434 210.521 987.929 212.405 990.528 212.405H990.528Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.528 227.404C991.771 227.404 993.037 226.942 994.071 225.908C997.273 222.661 994.996 217.148 990.485 217.148C987.71 217.148 985.434 219.453 985.434 222.272C985.434 225.364 987.929 227.404 990.528 227.404H990.528Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.523 231.355H990.475C987.706 231.355 985.434 233.661 985.434 236.471C985.434 239.564 987.938 241.62 990.546 241.62C991.791 241.62 993.06 241.15 994.092 240.103C997.278 236.875 995.034 231.355 990.522 231.355H990.523Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.485 246.363C987.71 246.363 985.434 248.663 985.434 251.477C985.434 254.563 987.924 256.618 990.528 256.618C991.771 256.618 993.037 256.153 994.071 255.106C997.273 251.865 994.996 246.363 990.485 246.363H990.485Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.485 274.789C987.71 274.789 985.434 277.057 985.434 279.917C985.434 283.008 987.929 285.045 990.523 285.045C991.771 285.045 993.047 284.573 994.071 283.518C997.273 280.307 994.996 274.789 990.485 274.789L990.485 274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.485 289.785C987.71 289.824 985.434 292.091 985.434 294.939C985.434 298.004 987.924 300.05 990.523 300.05C991.766 300.05 993.037 299.579 994.071 298.532C997.273 295.328 994.996 289.785 990.485 289.785H990.485Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.485 304.004C987.71 304.042 985.434 306.304 985.434 309.155C985.434 312.218 987.938 314.259 990.542 314.259C991.785 314.259 993.051 313.793 994.071 312.747C997.273 309.506 994.996 304.004 990.485 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.485 318.211C987.71 318.211 985.434 320.52 985.434 323.334C985.434 326.432 987.929 328.471 990.528 328.471C991.771 328.471 993.037 328.004 994.071 326.97C997.273 323.724 994.996 318.211 990.485 318.211H990.485Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 43.4297C1001.93 43.4729 999.648 45.7342 999.648 48.5859C999.648 51.6442 1002.14 53.6896 1004.75 53.6896C1006 53.6896 1007.28 53.2237 1008.31 52.1724C1011.48 48.9752 1009.24 43.4297 1004.72 43.4297Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 57.6484C1001.93 57.6484 999.648 59.9529 999.648 62.7617C999.648 65.8534 1002.15 67.9084 1004.76 67.9084C1006.01 67.9084 1007.28 67.4377 1008.31 66.3911C1011.48 63.1504 1009.24 57.6484 1004.72 57.6484H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 72.6484C1001.93 72.6484 999.648 74.7752 999.648 77.3727C999.648 80.2323 1002.15 82.115 1004.76 82.115C1006.01 82.115 1007.28 81.6889 1008.31 80.7341C1011.48 77.7367 1009.24 72.6484 1004.72 72.6484H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 86.8594C1001.93 86.8594 999.648 89.1695 999.648 91.9847C999.648 95.0837 1002.15 97.1241 1004.76 97.1241C1006.01 97.1241 1007.28 96.6573 1008.31 95.6226C1011.48 92.3743 1009.24 86.8594 1004.72 86.8594H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 101.078C1001.93 101.078 999.648 103.348 999.648 106.211C999.648 109.28 1002.14 111.333 1004.75 111.333C1006 111.333 1007.28 110.866 1008.31 109.81C1011.48 106.601 1009.24 101.078 1004.72 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.25 82.115C1165.49 82.115 1166.75 81.6889 1167.77 80.7341C1170.99 77.7367 1168.71 72.6484 1164.22 72.6484C1161.39 72.6484 1159.15 74.7752 1159.15 77.3727C1159.15 80.2323 1161.65 82.115 1164.25 82.115H1164.25Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.25 97.1241C1165.49 97.1241 1166.75 96.6573 1167.77 95.6226C1170.99 92.3743 1168.71 86.8594 1164.22 86.8594C1161.39 86.8594 1159.15 89.1695 1159.15 91.9847C1159.15 95.0837 1161.65 97.1241 1164.25 97.1241H1164.25Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 82.115C1179.7 82.115 1180.97 81.6889 1182 80.7341C1185.21 77.7367 1182.93 72.6484 1178.46 72.6484C1175.64 72.6484 1173.41 74.7752 1173.37 77.3727C1173.37 80.2323 1175.86 82.115 1178.46 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 97.1241C1179.7 97.1241 1180.97 96.6573 1182 95.6226C1185.21 92.3743 1182.93 86.8594 1178.46 86.8594C1175.64 86.8594 1173.41 89.1695 1173.37 91.9847C1173.37 95.0837 1175.86 97.1241 1178.46 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 57.6484C1190.64 57.6484 1188.37 59.9529 1188.37 62.7617C1188.37 65.8534 1190.86 67.9084 1193.46 67.9084C1194.7 67.9084 1195.97 67.4377 1197 66.3911C1200.2 63.1504 1197.93 57.6484 1193.41 57.6484H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 72.6484C1190.64 72.6484 1188.37 74.7752 1188.37 77.3727C1188.37 80.2323 1190.86 82.115 1193.46 82.115C1194.7 82.115 1195.97 81.6889 1197 80.7341C1200.2 77.7367 1197.93 72.6484 1193.41 72.6484H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 86.8594C1190.64 86.8594 1188.37 89.1695 1188.37 91.9847C1188.37 95.0837 1190.86 97.1241 1193.46 97.1241C1194.7 97.1241 1195.97 96.6573 1197 95.6226C1200.2 92.3743 1197.93 86.8594 1193.41 86.8594H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 72.6484C1204.86 72.6484 1202.57 74.7752 1202.57 77.3727C1202.57 80.2323 1205.07 82.115 1207.69 82.115C1208.93 82.115 1210.21 81.6889 1211.24 80.7341C1214.42 77.7367 1212.17 72.6484 1207.64 72.6484H1207.64Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 86.8594C1204.86 86.8594 1202.57 89.1695 1202.57 91.9847C1202.57 95.0837 1205.07 97.1241 1207.69 97.1241C1208.93 97.1241 1210.21 96.6573 1211.24 95.6226C1214.42 92.3743 1212.17 86.8594 1207.64 86.8594H1207.64Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1221.89 82.115C1223.13 82.115 1224.39 81.6889 1225.42 80.7341C1228.63 77.7367 1226.39 72.6484 1221.86 72.6484C1219.07 72.6484 1216.79 74.7752 1216.79 77.3727C1216.79 80.2323 1219.29 82.115 1221.89 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1308.7 86.8594C1305.92 86.8594 1303.65 89.1695 1303.65 91.9847C1303.65 95.0837 1306.14 97.1241 1308.74 97.1241C1309.99 97.1241 1311.25 96.6573 1312.29 95.6226C1315.48 92.3743 1313.21 86.8594 1308.7 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1236.89 82.115C1238.13 82.115 1239.4 81.6889 1240.42 80.7341C1243.63 77.7367 1241.35 72.6484 1236.86 72.6484C1234.04 72.6484 1231.79 74.7752 1231.79 77.3727C1231.79 80.2323 1234.29 82.115 1236.89 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1251.1 72.6484C1248.28 72.6484 1246.05 74.7752 1246.01 77.3727C1246.01 80.2323 1248.5 82.115 1251.11 82.115C1252.34 82.115 1253.62 81.6889 1254.65 80.7341C1257.85 77.7367 1255.61 72.6484 1251.1 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1265.28 72.6484C1262.5 72.6484 1260.21 74.7752 1260.21 77.3727C1260.21 80.2323 1262.72 82.115 1265.33 82.115C1266.57 82.115 1267.85 81.6889 1268.88 80.7341C1272.06 77.7367 1269.81 72.6484 1265.28 72.6484H1265.28Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1294.53 86.8594C1291.71 86.8594 1289.47 89.1695 1289.43 91.9847C1289.43 95.0837 1291.94 97.1241 1294.55 97.1241C1295.79 97.1241 1297.05 96.6573 1298.07 95.6226C1301.28 92.3743 1299 86.8594 1294.53 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1280.29 86.8594C1277.47 86.8594 1275.23 89.1695 1275.23 91.9847C1275.23 95.0837 1277.73 97.1241 1280.32 97.1241C1281.56 97.1241 1282.83 96.6573 1283.85 95.6226C1287.06 92.3743 1284.82 86.8594 1280.29 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1265.28 86.8594C1262.5 86.8594 1260.21 89.1695 1260.21 91.9847C1260.21 95.0837 1262.72 97.1241 1265.33 97.1241C1266.57 97.1241 1267.85 96.6573 1268.88 95.6226C1272.06 92.3743 1269.81 86.8594 1265.28 86.8594H1265.28Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1251.1 86.8594C1248.28 86.8594 1246.05 89.1695 1246.01 91.9847C1246.01 95.0837 1248.5 97.1241 1251.11 97.1241C1252.34 97.1241 1253.62 96.6573 1254.65 95.6226C1257.85 92.3743 1255.61 86.8594 1251.1 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1236.89 97.1241C1238.13 97.1241 1239.4 96.6573 1240.42 95.6226C1243.63 92.3743 1241.35 86.8594 1236.86 86.8594C1234.04 86.8594 1231.79 89.1695 1231.79 91.9847C1231.79 95.0837 1234.29 97.1241 1236.89 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1221.89 97.1241C1223.13 97.1241 1224.39 96.6573 1225.42 95.6226C1228.63 92.3743 1226.39 86.8594 1221.86 86.8594C1219.07 86.8594 1216.79 89.1695 1216.79 91.9847C1216.79 95.0837 1219.29 97.1241 1221.89 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1221.88 111.333C1223.13 111.333 1224.39 110.866 1225.42 109.81C1228.63 106.601 1226.39 101.078 1221.86 101.078C1219.07 101.078 1216.79 103.348 1216.79 106.211C1216.79 109.28 1219.29 111.333 1221.88 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 101.078C1204.86 101.078 1202.57 103.348 1202.57 106.211C1202.57 109.28 1205.07 111.333 1207.68 111.333C1208.93 111.333 1210.21 110.866 1211.24 109.81C1214.42 106.601 1212.17 101.078 1207.64 101.078H1207.64Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 101.078C1190.64 101.078 1188.37 103.348 1188.37 106.211C1188.37 109.28 1190.85 111.333 1193.45 111.333C1194.69 111.333 1195.97 110.866 1197 109.81C1200.2 106.601 1197.93 101.078 1193.41 101.078H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1178.46 111.333C1179.7 111.333 1180.97 110.866 1182 109.81C1185.21 106.601 1182.93 101.078 1178.46 101.078C1175.64 101.078 1173.41 103.348 1173.37 106.211C1173.37 109.28 1175.85 111.333 1178.46 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.24 111.333C1165.49 111.333 1166.75 110.866 1167.77 109.81C1170.99 106.601 1168.71 101.078 1164.22 101.078C1161.39 101.078 1159.15 103.348 1159.15 106.211C1159.15 109.28 1161.65 111.333 1164.24 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 217.148C1074.58 217.148 1072.29 219.453 1072.29 222.272C1072.29 225.364 1074.8 227.404 1077.4 227.404C1078.65 227.404 1079.92 226.942 1080.96 225.908C1084.13 222.661 1081.89 217.148 1077.36 217.148H1077.36Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 260.57C1074.58 260.57 1072.29 262.879 1072.29 265.693C1072.29 268.791 1074.8 270.831 1077.4 270.831C1078.65 270.831 1079.92 270.364 1080.96 269.33C1084.13 266.083 1081.89 260.57 1077.36 260.57H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 82.115C1092.85 82.115 1094.11 81.6889 1095.14 80.7341C1098.35 77.7367 1096.1 72.6484 1091.58 72.6484C1088.75 72.6484 1086.51 74.7752 1086.51 77.3727C1086.51 80.2323 1089.01 82.115 1091.6 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 97.1241C1092.85 97.1241 1094.11 96.6573 1095.14 95.6226C1098.35 92.3743 1096.1 86.8594 1091.58 86.8594C1088.75 86.8594 1086.51 89.1695 1086.51 91.9847C1086.51 95.0837 1089.01 97.1241 1091.6 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 111.333C1092.84 111.333 1094.11 110.866 1095.14 109.81C1098.35 106.601 1096.1 101.078 1091.58 101.078C1088.75 101.078 1086.51 103.348 1086.51 106.211C1086.51 109.28 1089 111.333 1091.6 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 125.549C1092.85 125.549 1094.11 125.115 1095.14 124.149C1098.35 121.157 1096.1 116.078 1091.58 116.078C1088.75 116.113 1086.51 118.205 1086.51 120.798C1086.51 123.652 1089.01 125.549 1091.6 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 140.545C1092.85 140.545 1094.11 140.083 1095.14 139.048C1098.35 135.802 1096.1 130.289 1091.58 130.289C1088.75 130.289 1086.51 132.593 1086.51 135.407C1086.51 138.505 1089.01 140.544 1091.6 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 154.765C1092.85 154.765 1094.11 154.298 1095.14 153.263C1098.35 150.015 1096.1 144.5 1091.58 144.5C1088.75 144.5 1086.51 146.81 1086.51 149.625C1086.51 152.724 1089.01 154.765 1091.6 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.59 168.979C1092.83 168.979 1094.1 168.543 1095.14 167.565C1098.35 164.603 1096.1 159.508 1091.58 159.508C1088.75 159.508 1086.51 161.602 1086.51 164.243C1086.51 167.094 1088.99 168.979 1091.59 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 183.979C1092.84 183.979 1094.11 183.508 1095.14 182.461C1098.35 179.259 1096.1 173.719 1091.58 173.719C1088.75 173.757 1086.51 176.023 1086.51 178.87C1086.51 181.933 1089 183.979 1091.6 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 198.189C1092.85 198.189 1094.11 197.723 1095.14 196.672C1098.35 193.436 1096.1 187.934 1091.58 187.934C1088.75 187.934 1086.51 190.233 1086.51 193.042C1086.51 196.134 1089.01 198.189 1091.6 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 82.115C1107.08 82.115 1108.34 81.6889 1109.36 80.7341C1112.56 77.7367 1110.28 72.6484 1105.81 72.6484C1103 72.6484 1100.76 74.7752 1100.72 77.3727C1100.72 80.2323 1103.23 82.115 1105.83 82.115H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 97.1241C1107.08 97.1241 1108.34 96.6573 1109.36 95.6226C1112.56 92.3743 1110.28 86.8594 1105.81 86.8594C1103 86.8594 1100.76 89.1695 1100.72 91.9847C1100.72 95.0837 1103.23 97.1241 1105.83 97.1241H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 111.333C1107.07 111.333 1108.34 110.866 1109.36 109.81C1112.56 106.601 1110.28 101.078 1105.81 101.078C1103 101.078 1100.76 103.348 1100.72 106.211C1100.72 109.28 1103.23 111.333 1105.83 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 125.549C1107.08 125.549 1108.34 125.115 1109.36 124.149C1112.56 121.192 1110.28 116.078 1105.81 116.078C1103 116.078 1100.76 118.205 1100.72 120.798C1100.72 123.652 1103.23 125.549 1105.83 125.549H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 140.545C1107.08 140.545 1108.34 140.083 1109.36 139.048C1112.56 135.802 1110.28 130.289 1105.81 130.289C1103 130.289 1100.76 132.593 1100.72 135.407C1100.72 138.505 1103.23 140.544 1105.83 140.544L1105.83 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 154.765C1107.08 154.765 1108.34 154.298 1109.36 153.263C1112.56 150.015 1110.28 144.5 1105.81 144.5C1103 144.5 1100.76 146.81 1100.72 149.625C1100.72 152.724 1103.23 154.765 1105.83 154.765H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 168.974C1107.08 168.974 1108.34 168.548 1109.36 167.594C1112.56 164.596 1110.28 159.508 1105.81 159.508C1103 159.508 1100.76 161.599 1100.72 164.237C1100.72 167.092 1103.23 168.974 1105.83 168.974H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 183.979C1107.07 183.979 1108.34 183.508 1109.36 182.461C1112.56 179.259 1110.28 173.719 1105.81 173.719C1103 173.757 1100.76 176.023 1100.72 178.87C1100.72 181.933 1103.23 183.979 1105.83 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 82.115C1122.06 82.115 1123.33 81.6889 1124.36 80.7341C1127.56 77.7367 1125.29 72.6484 1120.77 72.6484C1118 72.6484 1115.76 74.7752 1115.72 77.3727C1115.72 80.2323 1118.21 82.115 1120.82 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 97.1241C1122.06 97.1241 1123.33 96.6573 1124.36 95.6226C1127.56 92.3743 1125.29 86.8594 1120.77 86.8594C1118 86.8594 1115.76 89.1695 1115.72 91.9847C1115.72 95.0837 1118.21 97.1241 1120.82 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.81 111.333C1122.06 111.333 1123.33 110.866 1124.36 109.81C1127.56 106.601 1125.29 101.078 1120.77 101.078C1118 101.078 1115.76 103.348 1115.72 106.211C1115.72 109.28 1118.21 111.333 1120.81 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 125.549C1122.06 125.549 1123.33 125.115 1124.36 124.149C1127.56 121.157 1125.29 116.078 1120.77 116.078C1118 116.113 1115.76 118.205 1115.72 120.798C1115.72 123.652 1118.21 125.549 1120.82 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 140.545C1122.06 140.545 1123.33 140.083 1124.36 139.048C1127.56 135.802 1125.29 130.289 1120.77 130.289C1118 130.289 1115.76 132.593 1115.72 135.407C1115.72 138.505 1118.21 140.544 1120.82 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 154.765C1122.06 154.765 1123.33 154.298 1124.36 153.263C1127.56 150.015 1125.29 144.5 1120.77 144.5C1118 144.5 1115.76 146.81 1115.72 149.625C1115.72 152.724 1118.21 154.765 1120.82 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.79 168.979C1122.04 168.979 1123.32 168.543 1124.36 167.565C1127.56 164.603 1125.29 159.508 1120.77 159.508C1118 159.508 1115.76 161.602 1115.72 164.243C1115.72 167.094 1118.2 168.979 1120.79 168.979L1120.79 168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.81 183.979C1122.06 183.979 1123.33 183.508 1124.36 182.461C1127.56 179.259 1125.29 173.719 1120.77 173.719C1118 173.757 1115.76 176.023 1115.72 178.87C1115.72 181.933 1118.21 183.979 1120.81 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1135 72.6484C1132.22 72.6484 1129.93 74.7752 1129.93 77.3727C1129.93 80.2323 1132.44 82.115 1135.04 82.115C1136.29 82.115 1137.56 81.6889 1138.6 80.7341C1141.77 77.7367 1139.53 72.6484 1135 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 86.8594C1132.22 86.8594 1129.93 89.1695 1129.93 91.9847C1129.93 95.0837 1132.44 97.1241 1135.04 97.1241C1136.29 97.1241 1137.56 96.6573 1138.6 95.6226C1141.77 92.3743 1139.53 86.8594 1135 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 101.078C1132.22 101.078 1129.93 103.348 1129.93 106.211C1129.93 109.28 1132.43 111.333 1135.04 111.333C1136.29 111.333 1137.56 110.866 1138.6 109.81C1141.77 106.601 1139.53 101.078 1135 101.078H1135Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 82.115C1150.86 82.115 1152.04 81.6889 1152.99 80.7341C1155.99 77.7367 1153.89 72.6484 1149.67 72.6484C1147.07 72.6484 1144.94 74.7752 1144.94 77.3727C1144.94 80.2323 1147.27 82.115 1149.7 82.115H1149.7Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 97.1241C1150.86 97.1241 1152.04 96.6573 1152.99 95.6226C1155.99 92.3743 1153.89 86.8594 1149.67 86.8594C1147.07 86.8594 1144.94 89.1695 1144.94 91.9847C1144.94 95.0837 1147.27 97.1241 1149.7 97.1241H1149.7Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.69 111.333C1150.85 111.333 1152.03 110.866 1152.99 109.81C1155.99 106.601 1153.89 101.078 1149.67 101.078C1147.07 101.078 1144.94 103.348 1144.94 106.211C1144.94 109.28 1147.27 111.333 1149.69 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 125.549C1150.86 125.549 1152.03 125.115 1152.99 124.149C1155.99 121.192 1153.89 116.078 1149.67 116.078C1147.07 116.078 1144.94 118.205 1144.94 120.798C1144.94 123.652 1147.27 125.549 1149.7 125.549H1149.7Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 116.078C1132.22 116.078 1129.93 118.205 1129.93 120.798C1129.93 123.652 1132.44 125.549 1135.04 125.549C1136.29 125.549 1137.56 125.115 1138.6 124.149C1141.77 121.157 1139.53 116.078 1135 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 130.289C1132.22 130.289 1129.93 132.594 1129.93 135.407C1129.93 138.505 1132.44 140.545 1135.04 140.545C1136.29 140.545 1137.56 140.083 1138.6 139.048C1141.77 135.802 1139.53 130.289 1135 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 144.5C1132.22 144.5 1129.93 146.81 1129.93 149.625C1129.93 152.724 1132.44 154.765 1135.04 154.765C1136.29 154.765 1137.56 154.298 1138.6 153.263C1141.77 150.015 1139.53 144.5 1135 144.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 159.508C1132.22 159.508 1129.93 161.602 1129.93 164.243C1129.93 167.094 1132.42 168.979 1135.03 168.979C1136.28 168.979 1137.56 168.543 1138.6 167.565C1141.77 164.604 1139.53 159.508 1135 159.508V159.508Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 173.719C1132.22 173.757 1129.93 176.023 1129.93 178.87C1129.93 181.933 1132.43 183.979 1135.04 183.979C1136.29 183.979 1137.56 183.508 1138.6 182.461C1141.77 179.259 1139.53 173.719 1135 173.719H1135Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 187.934C1132.22 187.934 1129.93 190.233 1129.93 193.042C1129.93 196.134 1132.44 198.189 1135.04 198.189C1136.29 198.189 1137.56 197.723 1138.6 196.672C1141.77 193.436 1139.53 187.934 1135 187.934Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 198.189C1122.06 198.189 1123.33 197.723 1124.36 196.672C1127.56 193.436 1125.29 187.934 1120.77 187.934C1118 187.934 1115.76 190.233 1115.72 193.042C1115.72 196.134 1118.21 198.189 1120.82 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 198.189C1107.08 198.189 1108.34 197.723 1109.36 196.672C1112.56 193.436 1110.28 187.934 1105.81 187.934C1103 187.934 1100.76 190.233 1100.72 193.042C1100.72 196.134 1103.23 198.189 1105.83 198.189H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 212.405C1107.08 212.405 1108.34 211.974 1109.36 211.019C1112.56 208.021 1110.28 202.93 1105.81 202.93C1103 202.93 1100.76 205.062 1100.72 207.661C1100.72 210.521 1103.23 212.405 1105.83 212.405H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 212.405C1092.85 212.405 1094.11 211.974 1095.14 211.019C1098.35 208.021 1096.1 202.93 1091.58 202.93C1088.75 202.93 1086.51 205.062 1086.51 207.661C1086.51 210.521 1089.01 212.405 1091.6 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 116.078C1001.93 116.078 999.648 118.205 999.648 120.798C999.648 123.652 1002.15 125.549 1004.76 125.549C1006.01 125.549 1007.28 125.115 1008.31 124.149C1011.48 121.192 1009.24 116.078 1004.72 116.078H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 130.289C1001.93 130.289 999.648 132.594 999.648 135.407C999.648 138.505 1002.15 140.545 1004.76 140.545C1006.01 140.545 1007.28 140.083 1008.31 139.048C1011.48 135.802 1009.24 130.289 1004.72 130.289L1004.72 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 144.5C1001.93 144.5 999.648 146.81 999.648 149.625C999.648 152.724 1002.15 154.765 1004.76 154.765C1006.01 154.765 1007.28 154.298 1008.31 153.263C1011.48 150.015 1009.24 144.5 1004.72 144.5H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1008.28 167.601C1011.48 164.601 1009.24 159.508 1004.72 159.508C1001.93 159.508 999.648 161.601 999.648 164.241C999.648 167.081 1002.14 168.979 1004.74 168.979C1005.98 168.979 1007.24 168.552 1008.28 167.601V167.601Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 173.719C1001.93 173.757 999.648 176.023 999.648 178.87C999.648 181.933 1002.14 183.979 1004.75 183.979C1006 183.979 1007.28 183.508 1008.31 182.461C1011.48 179.259 1009.24 173.719 1004.72 173.719Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 187.934C1001.93 187.934 999.648 190.233 999.648 193.042C999.648 196.134 1002.15 198.189 1004.76 198.189C1006.01 198.189 1007.28 197.723 1008.31 196.672C1011.48 193.436 1009.24 187.934 1004.72 187.934H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 202.93C1001.93 202.93 999.648 205.062 999.648 207.661C999.648 210.521 1002.15 212.405 1004.76 212.405C1006.01 212.405 1007.28 211.974 1008.31 211.019C1011.48 208.021 1009.24 202.93 1004.72 202.93H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 217.148C1001.93 217.148 999.648 219.453 999.648 222.272C999.648 225.364 1002.15 227.404 1004.76 227.404C1006.01 227.404 1007.28 226.942 1008.31 225.908C1011.48 222.661 1009.24 217.148 1004.72 217.148H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.76 241.62C1006.01 241.62 1007.27 241.15 1008.29 240.103C1011.48 236.875 1009.23 231.355 1004.75 231.355H1004.7C1001.92 231.355 999.648 233.661 999.648 236.471C999.648 239.564 1002.16 241.62 1004.76 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 246.363C1001.93 246.363 999.648 248.663 999.648 251.477C999.648 254.563 1002.15 256.618 1004.76 256.618C1006.01 256.618 1007.28 256.153 1008.31 255.106C1011.48 251.865 1009.24 246.363 1004.72 246.363H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 274.789C1001.93 274.789 999.648 277.057 999.648 279.917C999.648 283.008 1002.13 285.045 1004.74 285.045C1005.99 285.045 1007.27 284.573 1008.31 283.518C1011.48 280.307 1009.24 274.789 1004.72 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 300.05C1005.97 300.05 1007.23 299.579 1008.28 298.532C1011.48 295.328 1009.24 289.824 1004.72 289.785C1001.93 289.785 999.648 292.091 999.648 294.939C999.648 298.004 1002.13 300.05 1004.72 300.05Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 304.004C1001.93 304.004 999.648 306.304 999.648 309.155C999.648 312.218 1002.14 314.259 1004.75 314.259C1006 314.259 1007.28 313.793 1008.31 312.747C1011.48 309.545 1009.24 304.004 1004.72 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 318.211C1001.93 318.211 999.648 320.52 999.648 323.334C999.648 326.432 1002.15 328.471 1004.76 328.471C1006.01 328.471 1007.28 328.004 1008.31 326.97C1011.48 323.724 1009.24 318.211 1004.72 318.211H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 333.219C1001.93 333.219 999.648 335.523 999.648 338.342C999.648 341.435 1002.15 343.479 1004.76 343.479C1006.01 343.479 1007.28 343.012 1008.31 341.978C1011.48 338.731 1009.24 333.219 1004.72 333.219H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 53.6896C1020.2 53.6896 1021.47 53.2237 1022.49 52.1724C1025.7 48.9752 1023.46 43.4729 1018.93 43.4297C1016.11 43.4729 1013.86 45.7342 1013.86 48.5859C1013.86 51.6442 1016.36 53.6896 1018.96 53.6896Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 67.9084C1020.2 67.9084 1021.47 67.4377 1022.49 66.3911C1025.7 63.1891 1023.46 57.6484 1018.93 57.6484C1016.11 57.6484 1013.86 59.9529 1013.86 62.7617C1013.86 65.8534 1016.36 67.9084 1018.96 67.9084H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 82.115C1020.21 82.115 1021.47 81.6889 1022.49 80.7341C1025.7 77.7367 1023.46 72.6484 1018.93 72.6484C1016.11 72.6484 1013.86 74.7752 1013.86 77.3727C1013.86 80.2323 1016.36 82.115 1018.96 82.115H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 97.1241C1020.21 97.1241 1021.47 96.6573 1022.49 95.6226C1025.7 92.3743 1023.46 86.8594 1018.93 86.8594C1016.11 86.8594 1013.86 89.131 1013.86 91.9847C1013.86 95.0837 1016.36 97.1241 1018.96 97.1241H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 111.333C1020.2 111.333 1021.47 110.866 1022.49 109.81C1025.7 106.601 1023.46 101.078 1018.93 101.078C1016.11 101.078 1013.86 103.348 1013.86 106.211C1013.86 109.28 1016.36 111.333 1018.96 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 125.549C1020.2 125.549 1021.47 125.115 1022.49 124.149C1025.7 121.192 1023.46 116.078 1018.93 116.078C1016.11 116.078 1013.86 118.205 1013.86 120.798C1013.86 123.652 1016.36 125.549 1018.96 125.549H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 140.545C1020.21 140.545 1021.47 140.083 1022.49 139.048C1025.7 135.802 1023.46 130.289 1018.93 130.289C1016.11 130.289 1013.86 132.593 1013.86 135.407C1013.86 138.505 1016.36 140.544 1018.96 140.544L1018.96 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 154.765C1020.21 154.765 1021.47 154.298 1022.49 153.263C1025.7 150.015 1023.46 144.5 1018.93 144.5C1016.11 144.5 1013.86 146.81 1013.86 149.625C1013.86 152.724 1016.36 154.765 1018.96 154.765H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 168.974C1020.21 168.974 1021.47 168.548 1022.49 167.594C1025.7 164.596 1023.46 159.508 1018.93 159.508C1016.11 159.508 1013.86 161.599 1013.86 164.237C1013.86 167.092 1016.36 168.974 1018.96 168.974H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 183.979C1020.2 183.979 1021.47 183.508 1022.49 182.461C1025.7 179.259 1023.46 173.757 1018.93 173.719C1016.11 173.757 1013.86 176.023 1013.86 178.87C1013.86 181.933 1016.36 183.979 1018.96 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 198.189C1020.2 198.189 1021.47 197.723 1022.49 196.672C1025.7 193.474 1023.46 187.934 1018.93 187.934C1016.11 187.934 1013.86 190.233 1013.86 193.042C1013.86 196.134 1016.36 198.189 1018.96 198.189H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 212.405C1020.21 212.405 1021.47 211.974 1022.49 211.019C1025.7 208.021 1023.46 202.93 1018.93 202.93C1016.11 202.93 1013.86 205.062 1013.86 207.661C1013.86 210.521 1016.36 212.405 1018.96 212.405H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 227.404C1020.21 227.404 1021.47 226.942 1022.49 225.908C1025.7 222.661 1023.46 217.148 1018.93 217.148C1016.11 217.148 1013.86 219.414 1013.86 222.272C1013.86 225.364 1016.36 227.404 1018.96 227.404H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.95 241.62C1020.2 241.62 1021.46 241.15 1022.5 240.103C1025.7 236.86 1023.42 231.355 1018.91 231.355C1016.1 231.355 1013.86 233.661 1013.86 236.471C1013.86 239.564 1016.35 241.62 1018.95 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 256.618C1020.2 256.618 1021.47 256.153 1022.49 255.106C1025.7 251.904 1023.46 246.363 1018.93 246.363C1016.11 246.363 1013.86 248.663 1013.86 251.477C1013.86 254.563 1016.36 256.618 1018.96 256.618H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.93 274.789C1016.14 274.789 1013.86 277.096 1013.86 279.917C1013.86 283.008 1016.35 285.045 1018.95 285.045C1020.21 285.045 1021.49 284.573 1022.53 283.518C1025.7 280.307 1023.46 274.789 1018.93 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 300.05C1020.2 300.05 1021.47 299.579 1022.49 298.532C1025.7 295.328 1023.46 289.824 1018.93 289.785C1016.11 289.824 1013.86 292.091 1013.86 294.939C1013.86 298.004 1016.36 300.05 1018.96 300.05Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.93 304.004C1016.14 304.042 1013.86 306.304 1013.86 309.155C1013.86 312.218 1016.36 314.259 1018.97 314.259C1020.21 314.259 1021.49 313.793 1022.53 312.747C1025.7 309.506 1023.46 304.004 1018.93 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.93 347.43C1016.14 347.43 1013.86 349.703 1013.86 352.56C1013.86 355.653 1016.35 357.695 1018.95 357.695C1020.21 357.695 1021.49 357.218 1022.53 356.163C1025.7 352.95 1023.46 347.43 1018.93 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 343.479C1020.21 343.479 1021.47 343.012 1022.49 341.978C1025.7 338.731 1023.46 333.219 1018.93 333.219C1016.11 333.219 1013.86 335.484 1013.86 338.342C1013.86 341.435 1016.36 343.479 1018.96 343.479H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 328.471C1020.21 328.471 1021.47 328.004 1022.49 326.97C1025.7 323.724 1023.46 318.211 1018.93 318.211C1016.11 318.211 1013.86 320.52 1013.86 323.334C1013.86 326.432 1016.36 328.471 1018.96 328.471H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 82.115C1035.2 82.115 1036.46 81.6889 1037.49 80.7341C1040.7 77.7367 1038.46 72.6484 1033.93 72.6484C1031.1 72.6484 1028.86 74.7752 1028.86 77.3727C1028.86 80.2323 1031.36 82.115 1033.96 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 97.1241C1035.2 97.1241 1036.46 96.6573 1037.49 95.6226C1040.7 92.3743 1038.46 86.8594 1033.93 86.8594C1031.1 86.8594 1028.86 89.1695 1028.86 91.9847C1028.86 95.0837 1031.36 97.1241 1033.96 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.95 111.333C1035.2 111.333 1036.46 110.866 1037.49 109.81C1040.7 106.601 1038.46 101.078 1033.93 101.078C1031.1 101.078 1028.86 103.348 1028.86 106.211C1028.86 109.28 1031.36 111.333 1033.95 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 125.549C1035.2 125.549 1036.46 125.115 1037.49 124.149C1040.7 121.192 1038.46 116.078 1033.93 116.078C1031.1 116.078 1028.86 118.205 1028.86 120.798C1028.86 123.652 1031.36 125.549 1033.96 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 140.545C1035.2 140.545 1036.46 140.083 1037.49 139.048C1040.7 135.802 1038.46 130.289 1033.93 130.289C1031.1 130.289 1028.86 132.593 1028.86 135.407C1028.86 138.505 1031.36 140.544 1033.96 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 154.765C1035.2 154.765 1036.46 154.298 1037.49 153.263C1040.7 150.015 1038.46 144.5 1033.93 144.5C1031.1 144.5 1028.86 146.81 1028.86 149.625C1028.86 152.724 1031.36 154.765 1033.96 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 168.974C1035.2 168.974 1036.46 168.548 1037.49 167.594C1040.7 164.596 1038.46 159.508 1033.93 159.508C1031.1 159.508 1028.86 161.599 1028.86 164.237C1028.86 167.092 1031.36 168.974 1033.96 168.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.95 183.979C1035.2 183.979 1036.46 183.508 1037.49 182.461C1040.7 179.259 1038.46 173.719 1033.93 173.719C1031.1 173.757 1028.86 176.023 1028.86 178.87C1028.86 181.933 1031.36 183.979 1033.95 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 198.189C1035.2 198.189 1036.46 197.723 1037.49 196.672C1040.7 193.436 1038.46 187.934 1033.93 187.934C1031.1 187.934 1028.86 190.233 1028.86 193.042C1028.86 196.134 1031.36 198.189 1033.96 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 212.405C1035.2 212.405 1036.46 211.974 1037.49 211.019C1040.7 208.021 1038.46 202.93 1033.93 202.93C1031.1 202.93 1028.86 205.062 1028.86 207.661C1028.86 210.521 1031.36 212.405 1033.96 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 227.404C1035.2 227.404 1036.46 226.942 1037.49 225.908C1040.7 222.661 1038.46 217.148 1033.93 217.148C1031.1 217.148 1028.86 219.453 1028.86 222.272C1028.86 225.364 1031.36 227.404 1033.96 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 241.62C1035.21 241.62 1036.47 241.15 1037.51 240.103C1040.7 236.875 1038.45 231.355 1033.97 231.355H1033.92C1031.1 231.355 1028.86 233.661 1028.86 236.471C1028.86 239.564 1031.36 241.62 1033.96 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 256.618C1035.2 256.618 1036.46 256.153 1037.49 255.106C1040.7 251.865 1038.46 246.363 1033.93 246.363C1031.1 246.363 1028.86 248.663 1028.86 251.477C1028.86 254.563 1031.36 256.618 1033.96 256.618Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.94 285.045C1035.19 285.045 1036.46 284.573 1037.49 283.518C1040.7 280.307 1038.46 274.789 1033.93 274.789C1031.1 274.789 1028.86 277.056 1028.86 279.917C1028.86 283.008 1031.35 285.044 1033.94 285.044V285.045Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.95 300.05C1035.2 300.05 1036.46 299.579 1037.49 298.532C1040.7 295.328 1038.46 289.785 1033.93 289.785C1031.1 289.824 1028.86 292.091 1028.86 294.939C1028.86 298.004 1031.36 300.05 1033.95 300.05Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 328.471C1035.2 328.471 1036.46 328.004 1037.49 326.97C1040.7 323.724 1038.46 318.211 1033.93 318.211C1031.1 318.211 1028.86 320.52 1028.86 323.334C1028.86 326.432 1031.36 328.471 1033.96 328.471Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.95 314.259C1035.2 314.259 1036.46 313.793 1037.49 312.747C1040.7 309.545 1038.46 304.004 1033.93 304.004C1031.1 304.004 1028.86 306.304 1028.86 309.155C1028.86 312.218 1031.36 314.259 1033.95 314.259Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 82.115C1049.42 82.115 1050.68 81.6889 1051.7 80.7341C1054.91 77.7367 1052.63 72.6484 1048.14 72.6484C1045.32 72.6484 1043.07 74.7752 1043.07 77.3727C1043.07 80.2323 1045.58 82.115 1048.18 82.115H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 97.1241C1049.42 97.1241 1050.68 96.6573 1051.7 95.6226C1054.91 92.3743 1052.63 86.8594 1048.14 86.8594C1045.32 86.8594 1043.07 89.1695 1043.07 91.9847C1043.07 95.0837 1045.58 97.1241 1048.18 97.1241H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 111.333C1049.42 111.333 1050.69 110.866 1051.71 109.81C1054.92 106.61 1052.66 101.078 1048.2 101.078H1048.15C1045.32 101.078 1043.07 103.348 1043.07 106.211C1043.07 109.28 1045.58 111.333 1048.18 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 125.549C1049.41 125.549 1050.68 125.115 1051.7 124.149C1054.91 121.157 1052.63 116.078 1048.14 116.078C1045.32 116.113 1043.07 118.205 1043.07 120.798C1043.07 123.652 1045.58 125.549 1048.18 125.549H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 140.545C1049.42 140.545 1050.68 140.083 1051.7 139.048C1054.91 135.802 1052.63 130.289 1048.14 130.289C1045.32 130.289 1043.07 132.593 1043.07 135.407C1043.07 138.505 1045.58 140.544 1048.18 140.544L1048.18 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 154.765C1049.42 154.765 1050.68 154.298 1051.7 153.263C1054.91 150.015 1052.63 144.5 1048.14 144.5C1045.32 144.5 1043.07 146.81 1043.07 149.625C1043.07 152.724 1045.58 154.765 1048.18 154.765H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.15 168.979C1049.4 168.979 1050.67 168.543 1051.7 167.565C1054.91 164.603 1052.63 159.508 1048.14 159.508C1045.32 159.508 1043.07 161.602 1043.07 164.243C1043.07 167.094 1045.56 168.979 1048.15 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.17 183.979C1049.41 183.979 1050.68 183.508 1051.7 182.461C1054.91 179.221 1052.63 173.719 1048.14 173.719C1045.32 173.757 1043.07 176.023 1043.07 178.87C1043.07 181.933 1045.57 183.979 1048.17 183.979H1048.17Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 212.405C1049.42 212.405 1050.68 211.974 1051.7 211.019C1054.91 208.021 1052.63 202.93 1048.14 202.93C1045.32 202.93 1043.07 205.062 1043.07 207.661C1043.07 210.521 1045.58 212.405 1048.18 212.405H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 227.404C1049.42 227.404 1050.68 226.942 1051.7 225.908C1054.91 222.661 1052.63 217.148 1048.14 217.148C1045.32 217.148 1043.07 219.453 1043.07 222.272C1043.07 225.364 1045.58 227.404 1048.18 227.404H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.17 241.62C1049.42 241.62 1050.69 241.15 1051.72 240.103C1054.92 236.875 1052.67 231.355 1048.18 231.355H1048.13C1045.32 231.355 1043.07 233.661 1043.07 236.471C1043.07 239.564 1045.57 241.62 1048.17 241.62H1048.17Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 256.618C1049.41 256.618 1050.68 256.153 1051.7 255.106C1054.91 251.865 1052.63 246.363 1048.14 246.363C1045.32 246.363 1043.07 248.663 1043.07 251.477C1043.07 254.563 1045.58 256.618 1048.18 256.618H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.16 285.045C1049.41 285.045 1050.69 284.573 1051.71 283.518C1054.91 280.307 1052.64 274.789 1048.16 274.789C1045.35 274.789 1043.11 277.095 1043.07 279.917C1043.07 283.008 1045.57 285.044 1048.16 285.044V285.045Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.17 300.05C1049.41 300.05 1050.68 299.579 1051.7 298.532C1054.91 295.328 1052.63 289.785 1048.14 289.785C1045.32 289.824 1043.07 292.091 1043.07 294.939C1043.07 298.004 1045.57 300.05 1048.17 300.05H1048.17Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 314.259C1049.43 314.259 1050.69 313.793 1051.71 312.747C1054.91 309.506 1052.64 304.004 1048.16 304.004C1045.35 304.042 1043.11 306.304 1043.07 309.155C1043.07 312.218 1045.58 314.259 1048.18 314.259Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.4 82.115C1063.65 82.115 1064.92 81.6889 1065.96 80.7341C1069.13 77.7367 1066.85 72.6484 1062.32 72.6484C1059.54 72.6484 1057.29 74.7752 1057.29 77.3727C1057.29 80.2323 1059.79 82.115 1062.4 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.37 97.1196C1063.62 97.1196 1064.89 96.6478 1065.93 95.5925C1069.13 92.3942 1066.87 86.8594 1062.37 86.8594H1062.32C1059.54 86.8594 1057.29 89.1714 1057.29 91.9898C1057.29 95.0823 1059.78 97.1196 1062.37 97.1196Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.37 111.338C1063.62 111.338 1064.89 110.867 1065.93 109.807C1069.13 106.569 1066.87 101.078 1062.37 101.078H1062.32C1059.54 101.078 1057.29 103.347 1057.29 106.209C1057.29 109.301 1059.78 111.338 1062.37 111.338Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.39 125.549C1063.63 125.549 1064.89 125.115 1065.92 124.149C1069.13 121.157 1066.85 116.078 1062.32 116.078C1059.54 116.113 1057.29 118.205 1057.29 120.798C1057.29 123.652 1059.79 125.549 1062.39 125.549H1062.39Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.4 140.545C1063.65 140.545 1064.92 140.083 1065.96 139.048C1069.13 135.802 1066.85 130.289 1062.32 130.289C1059.54 130.289 1057.29 132.593 1057.29 135.407C1057.29 138.505 1059.79 140.544 1062.4 140.544V140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.38 154.759C1063.64 154.759 1064.92 154.288 1065.96 153.233C1069.13 150.036 1066.87 144.504 1062.37 144.504H1062.32C1059.54 144.504 1057.29 146.815 1057.29 149.632C1057.29 152.723 1059.78 154.759 1062.38 154.759V154.759Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.37 168.979C1063.62 168.979 1064.89 168.543 1065.93 167.565C1069.13 164.612 1066.87 159.508 1062.37 159.508H1062.32C1059.54 159.508 1057.29 161.638 1057.29 164.243C1057.29 167.094 1059.78 168.979 1062.37 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.38 183.974C1063.63 183.974 1064.92 183.504 1065.96 182.454C1069.13 179.216 1066.85 173.719 1062.32 173.719C1059.54 173.757 1057.29 176.021 1057.29 178.865C1057.29 181.945 1059.77 183.974 1062.38 183.974L1062.38 183.974Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.4 198.189C1063.65 198.189 1064.92 197.723 1065.96 196.672C1069.13 193.436 1066.85 187.934 1062.32 187.934C1059.54 187.972 1057.29 190.233 1057.29 193.042C1057.29 196.134 1059.79 198.189 1062.4 198.189Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 72.6484C1074.58 72.6484 1072.29 74.7752 1072.29 77.3727C1072.29 80.2323 1074.8 82.115 1077.4 82.115C1078.65 82.115 1079.92 81.6889 1080.96 80.7341C1084.13 77.7367 1081.89 72.6484 1077.36 72.6484H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 86.8594C1074.58 86.8594 1072.29 89.1695 1072.29 91.9847C1072.29 95.0837 1074.8 97.1241 1077.4 97.1241C1078.65 97.1241 1079.92 96.6573 1080.96 95.6226C1084.13 92.3743 1081.89 86.8594 1077.36 86.8594H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 101.078C1074.58 101.078 1072.29 103.348 1072.29 106.211C1072.29 109.28 1074.79 111.333 1077.4 111.333C1078.65 111.333 1079.92 110.866 1080.96 109.81C1084.13 106.601 1081.89 101.078 1077.36 101.078H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 116.078C1074.58 116.078 1072.29 118.205 1072.29 120.798C1072.29 123.652 1074.8 125.549 1077.4 125.549C1078.65 125.549 1079.92 125.115 1080.96 124.149C1084.13 121.192 1081.89 116.078 1077.36 116.078H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 130.289C1074.58 130.289 1072.29 132.594 1072.29 135.407C1072.29 138.505 1074.8 140.545 1077.4 140.545C1078.65 140.545 1079.92 140.083 1080.96 139.048C1084.13 135.802 1081.89 130.289 1077.36 130.289L1077.36 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1077.36 144.5C1074.58 144.5 1072.29 146.81 1072.29 149.625C1072.29 152.724 1074.8 154.765 1077.4 154.765C1078.65 154.765 1079.92 154.298 1080.96 153.263C1084.13 150.015 1081.89 144.5 1077.36 144.5H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 159.508C1074.58 159.508 1072.29 161.599 1072.29 164.237C1072.29 167.092 1074.8 168.974 1077.4 168.974C1078.65 168.974 1079.92 168.548 1080.96 167.594C1084.13 164.596 1081.89 159.508 1077.36 159.508H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 173.719C1074.58 173.757 1072.29 176.023 1072.29 178.87C1072.29 181.933 1074.79 183.979 1077.4 183.979C1078.65 183.979 1079.92 183.508 1080.96 182.461C1084.13 179.259 1081.89 173.719 1077.36 173.719H1077.36Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 187.934C1074.58 187.934 1072.29 190.233 1072.29 193.042C1072.29 196.134 1074.8 198.189 1077.4 198.189C1078.65 198.189 1079.92 197.723 1080.96 196.672C1084.13 193.436 1081.89 187.934 1077.36 187.934H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 202.93C1074.58 202.93 1072.29 205.062 1072.29 207.661C1072.29 210.521 1074.8 212.405 1077.4 212.405C1078.65 212.405 1079.92 211.974 1080.96 211.019C1084.13 208.021 1081.89 202.93 1077.36 202.93H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.39 212.405C1063.63 212.405 1064.9 211.974 1065.92 211.019C1069.13 208.021 1066.85 202.93 1062.32 202.93C1059.54 202.93 1057.29 205.062 1057.29 207.661C1057.29 210.521 1059.79 212.405 1062.39 212.405H1062.39Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.37 227.409C1063.62 227.409 1064.89 226.937 1065.93 225.877C1069.13 222.678 1066.87 217.148 1062.37 217.148H1062.32C1059.54 217.148 1057.29 219.456 1057.29 222.279C1057.29 225.366 1059.78 227.409 1062.37 227.409Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.69 231.355H1062.64C1059.68 231.355 1057.29 233.661 1057.29 236.471C1057.29 239.564 1059.97 241.62 1062.74 241.62C1064.07 241.62 1065.42 241.15 1066.51 240.103C1069.92 236.875 1067.52 231.355 1062.69 231.355Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.39 256.618C1063.63 256.618 1064.89 256.153 1065.92 255.106C1069.13 251.865 1066.85 246.363 1062.32 246.363C1059.54 246.402 1057.29 248.663 1057.29 251.477C1057.29 254.563 1059.79 256.618 1062.39 256.618H1062.39Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.34 304.004C1059.57 304.042 1057.29 306.304 1057.29 309.155C1057.29 312.218 1059.78 314.259 1062.38 314.259C1063.63 314.259 1064.9 313.793 1065.93 312.747C1069.13 309.506 1066.86 304.004 1062.34 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.38 300.05C1063.63 300.05 1064.89 299.579 1065.92 298.532C1069.13 295.29 1066.85 289.785 1062.32 289.785C1059.54 289.824 1057.29 292.091 1057.29 294.939C1057.29 298.004 1059.78 300.05 1062.38 300.05Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.34 274.789C1059.57 274.789 1057.29 277.057 1057.29 279.917C1057.29 283.008 1059.77 285.045 1062.36 285.045C1063.61 285.045 1064.89 284.573 1065.93 283.518C1069.13 280.307 1066.86 274.789 1062.34 274.789L1062.34 274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.38 270.835C1063.64 270.835 1064.92 270.358 1065.96 269.303C1069.13 266.105 1066.87 260.57 1062.37 260.57H1062.32C1059.54 260.57 1057.29 262.882 1057.29 265.7C1057.29 268.793 1059.78 270.835 1062.38 270.835V270.835Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 270.831C1049.42 270.831 1050.68 270.364 1051.7 269.33C1054.91 266.083 1052.63 260.57 1048.14 260.57C1045.32 260.57 1043.07 262.879 1043.07 265.693C1043.07 268.791 1045.58 270.831 1048.18 270.831H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 270.831C1035.2 270.831 1036.46 270.364 1037.49 269.33C1040.7 266.083 1038.46 260.57 1033.93 260.57C1031.1 260.57 1028.86 262.879 1028.86 265.693C1028.86 268.791 1031.36 270.831 1033.96 270.831Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 270.831C1020.21 270.831 1021.47 270.364 1022.49 269.33C1025.7 266.083 1023.46 260.57 1018.93 260.57C1016.11 260.57 1013.86 262.879 1013.86 265.693C1013.86 268.791 1016.36 270.831 1018.96 270.831H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 260.57C1001.93 260.57 999.648 262.879 999.648 265.693C999.648 268.791 1002.15 270.831 1004.76 270.831C1006.01 270.831 1007.28 270.364 1008.31 269.33C1011.48 266.083 1009.24 260.57 1004.72 260.57H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.485 260.57C987.71 260.57 985.434 262.879 985.434 265.693C985.434 268.791 987.929 270.831 990.528 270.831C991.771 270.831 993.037 270.364 994.071 269.33C997.273 266.083 994.996 260.57 990.485 260.57H990.485Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.527 260.57C972.709 260.57 970.475 262.879 970.438 265.693C970.438 268.791 972.947 270.831 975.546 270.831C976.789 270.831 978.051 270.364 979.075 269.33C982.277 266.083 980 260.57 975.527 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1352.17 86.8594C1349.35 86.8594 1347.11 89.1695 1347.07 91.9847C1347.07 95.0837 1349.58 97.1241 1352.19 97.1241C1353.43 97.1241 1354.69 96.6573 1355.72 95.6226C1358.92 92.3743 1356.64 86.8594 1352.17 86.8594H1352.17Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1337.93 86.8594C1335.1 86.8594 1332.86 89.1695 1332.86 91.9847C1332.86 95.0837 1335.36 97.1241 1337.96 97.1241C1339.2 97.1241 1340.46 96.6573 1341.49 95.6226C1344.7 92.3743 1342.46 86.8594 1337.93 86.8594Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1337.93 72.6484C1335.1 72.6484 1332.86 74.7752 1332.86 77.3727C1332.86 80.2323 1335.36 82.115 1337.96 82.115C1339.2 82.115 1340.46 81.6889 1341.49 80.7341C1344.7 77.7367 1342.46 72.6484 1337.93 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1323.72 86.8594C1320.93 86.8594 1318.65 89.1695 1318.65 91.9847C1318.65 95.0837 1321.15 97.1241 1323.76 97.1241C1325.01 97.1241 1326.28 96.6573 1327.32 95.6226C1330.49 92.3743 1328.25 86.8594 1323.72 86.8594H1323.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1323.72 72.6484C1320.93 72.6484 1318.65 74.7752 1318.65 77.3727C1318.65 80.2323 1321.15 82.115 1323.76 82.115C1325.01 82.115 1326.28 81.6889 1327.32 80.7341C1330.49 77.7367 1328.25 72.6484 1323.72 72.6484H1323.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1308.7 101.078C1305.92 101.078 1303.65 103.348 1303.65 106.211C1303.65 109.28 1306.13 111.333 1308.73 111.333C1309.98 111.333 1311.25 110.866 1312.29 109.81C1315.48 106.601 1313.21 101.078 1308.7 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1308.7 72.6484C1305.92 72.6484 1303.65 74.7752 1303.65 77.3727C1303.65 80.2323 1306.14 82.115 1308.74 82.115C1309.99 82.115 1311.25 81.6889 1312.29 80.7341C1315.48 77.7367 1313.21 72.6484 1308.7 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1294.53 101.078C1291.71 101.078 1289.47 103.348 1289.43 106.211C1289.43 109.28 1291.94 111.333 1294.54 111.333C1295.79 111.333 1297.05 110.866 1298.07 109.81C1301.28 106.601 1299 101.078 1294.53 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1294.53 72.6484C1291.71 72.6484 1289.47 74.7752 1289.43 77.3727C1289.43 80.2323 1291.94 82.115 1294.55 82.115C1295.79 82.115 1297.05 81.6889 1298.07 80.7341C1301.28 77.7367 1299 72.6484 1294.53 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1280.29 116.078C1277.47 116.078 1275.23 118.205 1275.23 120.798C1275.23 123.652 1277.73 125.549 1280.32 125.549C1281.56 125.549 1282.83 125.115 1283.85 124.149C1287.06 121.192 1284.82 116.078 1280.29 116.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1280.29 101.078C1277.47 101.078 1275.23 103.348 1275.23 106.211C1275.23 109.28 1277.72 111.333 1280.32 111.333C1281.56 111.333 1282.83 110.866 1283.85 109.81C1287.06 106.601 1284.82 101.078 1280.29 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1280.29 72.6484C1277.47 72.6484 1275.23 74.7752 1275.23 77.3727C1275.23 80.2323 1277.73 82.115 1280.32 82.115C1281.56 82.115 1282.83 81.6889 1283.85 80.7341C1287.06 77.7367 1284.82 72.6484 1280.29 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1280.29 57.6484C1277.47 57.6484 1275.23 59.9529 1275.23 62.7617C1275.23 65.8534 1277.73 67.9084 1280.32 67.9084C1281.56 67.9084 1282.83 67.4377 1283.85 66.3911C1287.06 63.1504 1284.82 57.6484 1280.29 57.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1265.28 130.289C1262.5 130.289 1260.21 132.594 1260.21 135.407C1260.21 138.505 1262.72 140.545 1265.33 140.545C1266.57 140.545 1267.85 140.083 1268.88 139.048C1272.06 135.802 1269.81 130.289 1265.28 130.289L1265.28 130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1265.28 116.078C1262.5 116.113 1260.21 118.205 1260.21 120.798C1260.21 123.652 1262.71 125.549 1265.33 125.549C1266.57 125.549 1267.85 125.115 1268.88 124.149C1272.06 121.157 1269.81 116.078 1265.28 116.078H1265.28Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1265.28 101.078C1262.5 101.078 1260.21 103.348 1260.21 106.211C1260.21 109.28 1262.71 111.333 1265.32 111.333C1266.57 111.333 1267.85 110.866 1268.88 109.81C1272.06 106.601 1269.81 101.078 1265.28 101.078H1265.28Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1265.28 57.6484C1262.5 57.6484 1260.21 59.9529 1260.21 62.7617C1260.21 65.8534 1262.71 67.9084 1265.33 67.9084C1266.57 67.9084 1267.85 67.4377 1268.88 66.3911C1272.06 63.1504 1269.81 57.6484 1265.28 57.6484H1265.28Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1251.1 144.5C1248.28 144.5 1246.05 146.81 1246.01 149.625C1246.01 152.724 1248.5 154.765 1251.11 154.765C1252.34 154.765 1253.62 154.298 1254.65 153.263C1257.85 150.015 1255.61 144.5 1251.1 144.5Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1251.1 130.289C1248.28 130.289 1246.05 132.594 1246.01 135.407C1246.01 138.505 1248.5 140.545 1251.11 140.545C1252.34 140.545 1253.62 140.083 1254.65 139.048C1257.85 135.802 1255.61 130.289 1251.1 130.289V130.289Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1251.1 101.078C1248.28 101.078 1246.05 103.348 1246.01 106.211C1246.01 109.28 1248.5 111.333 1251.1 111.333C1252.34 111.333 1253.61 110.866 1254.65 109.81C1257.85 106.601 1255.61 101.078 1251.1 101.078Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1251.1 57.6484C1248.28 57.6484 1246.05 59.9529 1246.01 62.7617C1246.01 65.8534 1248.5 67.9084 1251.1 67.9084C1252.34 67.9084 1253.62 67.4377 1254.65 66.3911C1257.85 63.1891 1255.61 57.6484 1251.1 57.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1236.87 168.979C1238.12 168.979 1239.39 168.543 1240.42 167.565C1243.63 164.603 1241.35 159.508 1236.86 159.508C1234.04 159.508 1231.79 161.602 1231.79 164.243C1231.79 167.094 1234.27 168.979 1236.87 168.979V168.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1236.89 154.765C1238.13 154.765 1239.4 154.298 1240.42 153.263C1243.63 150.015 1241.35 144.5 1236.86 144.5C1234.04 144.5 1231.79 146.81 1231.79 149.625C1231.79 152.724 1234.29 154.765 1236.89 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1236.89 125.549C1238.13 125.549 1239.4 125.115 1240.42 124.149C1243.63 121.157 1241.35 116.078 1236.86 116.078C1234.04 116.113 1231.79 118.205 1231.79 120.798C1231.79 123.652 1234.29 125.549 1236.89 125.549H1236.89Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1236.88 111.333C1238.13 111.333 1239.39 110.866 1240.42 109.81C1243.63 106.601 1241.35 101.078 1236.86 101.078C1234.04 101.078 1231.79 103.348 1231.79 106.211C1231.79 109.28 1234.29 111.333 1236.88 111.333Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1236.89 67.9084C1238.13 67.9084 1239.4 67.4377 1240.42 66.3911C1243.63 63.1504 1241.35 57.6484 1236.86 57.6484C1234.04 57.6484 1231.79 59.9529 1231.79 62.7617C1231.79 65.8534 1234.29 67.9084 1236.89 67.9084H1236.89Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1221.89 125.549C1223.13 125.549 1224.39 125.115 1225.42 124.149C1228.63 121.192 1226.39 116.078 1221.86 116.078C1219.07 116.078 1216.79 118.205 1216.79 120.798C1216.79 123.652 1219.29 125.549 1221.89 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1221.89 67.9084C1223.13 67.9084 1224.39 67.4377 1225.42 66.3911C1228.63 63.1504 1226.39 57.6484 1221.86 57.6484C1219.07 57.6484 1216.79 59.9529 1216.79 62.7617C1216.79 65.8534 1219.29 67.9084 1221.89 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 116.078C1204.86 116.078 1202.57 118.205 1202.57 120.798C1202.57 123.652 1205.07 125.549 1207.69 125.549C1208.93 125.549 1210.21 125.115 1211.24 124.149C1214.42 121.157 1212.17 116.078 1207.64 116.078H1207.64Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 57.6484C1204.86 57.6484 1202.57 59.9529 1202.57 62.7617C1202.57 65.8534 1205.07 67.9084 1207.69 67.9084C1208.93 67.9084 1210.21 67.4377 1211.24 66.3911C1214.42 63.1504 1212.17 57.6484 1207.64 57.6484H1207.64Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1207.64 43.4297C1204.86 43.4729 1202.57 45.7342 1202.57 48.5859C1202.57 51.6442 1205.07 53.6896 1207.68 53.6896C1208.93 53.6896 1210.21 53.2237 1211.24 52.1724C1214.42 48.9752 1212.17 43.4297 1207.64 43.4297H1207.64Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 116.078C1190.64 116.113 1188.37 118.205 1188.37 120.798C1188.37 123.652 1190.86 125.549 1193.46 125.549C1194.7 125.549 1195.97 125.115 1197 124.149C1200.2 121.157 1197.93 116.078 1193.41 116.078H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1193.41 43.4297C1190.64 43.4729 1188.37 45.7342 1188.37 48.5859C1188.37 51.6442 1190.85 53.6896 1193.45 53.6896C1194.69 53.6896 1195.97 53.2237 1197 52.1724C1200.2 48.9752 1197.93 43.4297 1193.41 43.4297H1193.41Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 256.618C1179.7 256.618 1180.97 256.153 1182 255.106C1185.21 251.865 1182.93 246.363 1178.46 246.363C1175.64 246.363 1173.37 248.663 1173.37 251.477C1173.37 254.563 1175.86 256.618 1178.46 256.618Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.48 241.62C1179.72 241.62 1180.99 241.15 1182.02 240.103C1185.2 236.875 1182.96 231.355 1178.49 231.355H1178.44C1175.64 231.355 1173.37 233.661 1173.37 236.471C1173.37 239.564 1175.87 241.62 1178.48 241.62H1178.48Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 212.405C1179.7 212.405 1180.97 211.974 1182 211.019C1185.21 208.021 1182.93 202.93 1178.46 202.93C1175.64 202.93 1173.41 205.062 1173.37 207.661C1173.37 210.521 1175.86 212.405 1178.46 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 154.765C1179.7 154.765 1180.97 154.298 1182 153.263C1185.21 150.015 1182.93 144.5 1178.46 144.5C1175.64 144.5 1173.41 146.81 1173.37 149.625C1173.37 152.724 1175.86 154.765 1178.46 154.765Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 125.549C1179.7 125.549 1180.97 125.115 1182 124.149C1185.21 121.192 1182.93 116.078 1178.46 116.078C1175.64 116.078 1173.41 118.205 1173.37 120.798C1173.37 123.652 1175.86 125.549 1178.46 125.549Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 67.9084C1179.7 67.9084 1180.97 67.4377 1182 66.3911C1185.21 63.1504 1182.93 57.6484 1178.46 57.6484C1175.64 57.6484 1173.41 59.9529 1173.37 62.7617C1173.37 65.8534 1175.86 67.9084 1178.46 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1178.46 53.6896C1179.7 53.6896 1180.97 53.2237 1182 52.1724C1185.21 48.9752 1182.93 43.4297 1178.46 43.4297C1175.64 43.4729 1173.41 45.7342 1173.37 48.5859C1173.37 51.6442 1175.85 53.6896 1178.46 53.6896Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.25 270.831C1165.49 270.831 1166.75 270.364 1167.77 269.33C1170.99 266.083 1168.71 260.57 1164.22 260.57C1161.39 260.57 1159.15 262.879 1159.15 265.693C1159.15 268.791 1161.65 270.831 1164.25 270.831H1164.25Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1164.25 256.618C1165.49 256.618 1166.75 256.153 1167.77 255.106C1170.99 251.865 1168.71 246.363 1164.22 246.363C1161.39 246.363 1159.15 248.663 1159.15 251.477C1159.15 254.563 1161.65 256.618 1164.25 256.618H1164.25Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.24 183.979C1165.49 183.979 1166.75 183.508 1167.77 182.461C1170.99 179.259 1168.71 173.719 1164.22 173.719C1161.39 173.757 1159.15 176.023 1159.15 178.87C1159.15 181.933 1161.65 183.979 1164.24 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.23 168.979C1165.47 168.979 1166.75 168.543 1167.77 167.565C1170.99 164.603 1168.71 159.508 1164.22 159.508C1161.39 159.508 1159.15 161.602 1159.15 164.243C1159.15 167.094 1161.64 168.979 1164.22 168.979L1164.23 168.979Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.25 140.545C1165.49 140.545 1166.75 140.083 1167.77 139.048C1170.99 135.802 1168.71 130.289 1164.22 130.289C1161.39 130.289 1159.15 132.593 1159.15 135.407C1159.15 138.505 1161.65 140.544 1164.25 140.544L1164.25 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.25 125.549C1165.49 125.549 1166.75 125.115 1167.77 124.149C1170.99 121.157 1168.71 116.078 1164.22 116.078C1161.39 116.113 1159.15 118.205 1159.15 120.798C1159.15 123.652 1161.65 125.549 1164.25 125.549H1164.25Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1164.25 67.9084C1165.49 67.9084 1166.75 67.4377 1167.77 66.3911C1170.99 63.1504 1168.71 57.6484 1164.22 57.6484C1161.39 57.6484 1159.15 59.9529 1159.15 62.7617C1159.15 65.8534 1161.65 67.9084 1164.25 67.9084H1164.25Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.68 285.045C1150.84 285.045 1152.03 284.573 1152.99 283.518C1155.99 280.307 1153.89 274.789 1149.67 274.789C1147.03 274.789 1144.94 277.056 1144.94 279.917C1144.94 283.008 1147.26 285.044 1149.68 285.044V285.045Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 270.831C1150.86 270.831 1152.04 270.364 1152.99 269.33C1155.99 266.083 1153.89 260.57 1149.67 260.57C1147.07 260.57 1144.94 262.879 1144.94 265.693C1144.94 268.791 1147.27 270.831 1149.7 270.831H1149.7Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 198.189C1150.86 198.189 1152.03 197.723 1152.99 196.672C1155.99 193.436 1153.89 187.934 1149.67 187.934C1147.07 187.934 1144.94 190.233 1144.94 193.042C1144.94 196.134 1147.27 198.189 1149.7 198.189H1149.7Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.69 183.979C1150.85 183.979 1152.03 183.508 1152.99 182.461C1155.99 179.259 1153.89 173.719 1149.67 173.719C1147.07 173.757 1144.94 176.023 1144.94 178.87C1144.94 181.933 1147.27 183.979 1149.69 183.979Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 168.97C1150.86 168.97 1152.04 168.544 1152.99 167.59C1155.99 164.592 1153.89 159.504 1149.67 159.504C1147.07 159.504 1144.94 161.595 1144.94 164.233C1144.94 167.088 1147.27 168.97 1149.7 168.97H1149.7Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 154.765C1150.86 154.765 1152.04 154.298 1152.99 153.263C1155.99 150.015 1153.89 144.5 1149.67 144.5C1147.07 144.5 1144.94 146.81 1144.94 149.625C1144.94 152.724 1147.27 154.765 1149.7 154.765H1149.7Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 140.545C1150.86 140.545 1152.04 140.083 1152.99 139.048C1155.99 135.802 1153.89 130.289 1149.67 130.289C1147.07 130.289 1144.94 132.593 1144.94 135.407C1144.94 138.505 1147.27 140.544 1149.7 140.544L1149.7 140.545Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1149.7 67.9084C1150.86 67.9084 1152.03 67.4377 1152.99 66.3911C1155.99 63.1504 1153.89 57.6484 1149.67 57.6484C1147.07 57.6484 1144.94 59.9529 1144.94 62.7617C1144.94 65.8534 1147.27 67.9084 1149.7 67.9084H1149.7Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 274.789C1132.22 274.789 1129.93 277.057 1129.93 279.917C1129.93 283.008 1132.42 285.045 1135.03 285.045C1136.28 285.045 1137.56 284.573 1138.6 283.518C1141.77 280.307 1139.53 274.789 1135 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 260.57C1132.22 260.57 1129.93 262.879 1129.93 265.693C1129.93 268.791 1132.44 270.831 1135.04 270.831C1136.29 270.831 1137.56 270.364 1138.6 269.33C1141.77 266.083 1139.53 260.57 1135 260.57Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 202.93C1132.22 202.93 1129.93 205.062 1129.93 207.661C1129.93 210.521 1132.44 212.405 1135.04 212.405C1136.29 212.405 1137.56 211.974 1138.6 211.019C1141.77 208.021 1139.53 202.93 1135 202.93Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1135 57.6484C1132.22 57.6484 1129.93 59.9529 1129.93 62.7617C1129.93 65.8534 1132.44 67.9084 1135.04 67.9084C1136.29 67.9084 1137.56 67.4377 1138.6 66.3911C1141.77 63.1504 1139.53 57.6484 1135 57.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 256.618C1122.06 256.618 1123.33 256.153 1124.36 255.106C1127.56 251.865 1125.29 246.363 1120.77 246.363C1118 246.363 1115.72 248.663 1115.72 251.477C1115.72 254.563 1118.21 256.618 1120.82 256.618Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.83 241.62C1122.08 241.62 1123.35 241.15 1124.38 240.103C1127.56 236.875 1125.32 231.355 1120.81 231.355H1120.76C1117.99 231.355 1115.72 233.661 1115.72 236.471C1115.72 239.564 1118.23 241.62 1120.83 241.62H1120.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 227.404C1122.06 227.404 1123.33 226.942 1124.36 225.908C1127.56 222.661 1125.29 217.148 1120.77 217.148C1118 217.148 1115.76 219.453 1115.72 222.272C1115.72 225.364 1118.21 227.404 1120.82 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 212.405C1122.06 212.405 1123.33 211.974 1124.36 211.019C1127.56 208.021 1125.29 202.93 1120.77 202.93C1118 202.93 1115.76 205.062 1115.72 207.661C1115.72 210.521 1118.21 212.405 1120.82 212.405Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1120.82 67.9084C1122.06 67.9084 1123.33 67.4377 1124.36 66.3911C1127.56 63.1504 1125.29 57.6484 1120.77 57.6484C1118 57.6484 1115.76 59.9529 1115.72 62.7617C1115.72 65.8534 1118.21 67.9084 1120.82 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 227.404C1107.08 227.404 1108.34 226.942 1109.36 225.908C1112.56 222.661 1110.28 217.148 1105.81 217.148C1103 217.148 1100.76 219.453 1100.72 222.272C1100.72 225.364 1103.23 227.404 1105.83 227.404H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1105.83 67.9084C1107.08 67.9084 1108.34 67.4377 1109.36 66.3911C1112.56 63.1504 1110.28 57.6484 1105.81 57.6484C1103 57.6484 1100.76 59.9529 1100.72 62.7617C1100.72 65.8534 1103.23 67.9084 1105.83 67.9084H1105.83Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.58 274.789C1088.75 274.789 1086.51 277.096 1086.51 279.917C1086.51 283.008 1088.99 285.045 1091.59 285.045C1092.85 285.045 1094.13 284.573 1095.17 283.518C1098.35 280.307 1096.1 274.789 1091.58 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 270.831C1092.85 270.831 1094.11 270.364 1095.14 269.33C1098.35 266.083 1096.1 260.57 1091.58 260.57C1088.75 260.57 1086.51 262.879 1086.51 265.693C1086.51 268.791 1089.01 270.831 1091.6 270.831Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 256.618C1092.85 256.618 1094.11 256.153 1095.14 255.106C1098.35 251.865 1096.1 246.363 1091.58 246.363C1088.75 246.363 1086.51 248.663 1086.51 251.477C1086.51 254.563 1089.01 256.618 1091.6 256.618Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 227.404C1092.85 227.404 1094.11 226.942 1095.14 225.908C1098.35 222.661 1096.1 217.148 1091.58 217.148C1088.75 217.148 1086.51 219.453 1086.51 222.272C1086.51 225.364 1089.01 227.404 1091.6 227.404Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1091.6 67.9084C1092.85 67.9084 1094.11 67.4377 1095.14 66.3911C1098.35 63.1504 1096.1 57.6484 1091.58 57.6484C1088.75 57.6484 1086.51 59.9529 1086.51 62.7617C1086.51 65.8534 1089.01 67.9084 1091.6 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 318.211C1074.58 318.211 1072.29 320.52 1072.29 323.334C1072.29 326.432 1074.8 328.471 1077.4 328.471C1078.65 328.471 1079.92 328.004 1080.96 326.97C1084.13 323.724 1081.89 318.211 1077.36 318.211H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 304.004C1074.58 304.004 1072.29 306.304 1072.29 309.155C1072.29 312.218 1074.79 314.259 1077.4 314.259C1078.65 314.259 1079.92 313.793 1080.96 312.747C1084.13 309.545 1081.89 304.004 1077.36 304.004H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 289.785C1074.58 289.824 1072.29 292.091 1072.29 294.939C1072.29 298.004 1074.79 300.05 1077.4 300.05C1078.65 300.05 1079.92 299.579 1080.96 298.532C1084.13 295.328 1081.89 289.785 1077.36 289.785H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 274.789C1074.58 274.789 1072.29 277.057 1072.29 279.917C1072.29 283.008 1074.78 285.045 1077.38 285.045C1078.64 285.045 1079.92 284.573 1080.96 283.518C1084.13 280.307 1081.89 274.789 1077.36 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 246.363C1074.58 246.363 1072.29 248.663 1072.29 251.477C1072.29 254.563 1074.8 256.618 1077.4 256.618C1078.65 256.618 1079.92 256.153 1080.96 255.106C1084.13 251.865 1081.89 246.363 1077.36 246.363H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.41 241.62C1078.66 241.62 1079.92 241.15 1080.94 240.103C1084.14 236.875 1081.89 231.355 1077.4 231.355H1077.35C1074.57 231.355 1072.29 233.661 1072.29 236.471C1072.29 239.564 1074.81 241.62 1077.41 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1077.36 57.6484C1074.58 57.6484 1072.29 59.9529 1072.29 62.7617C1072.29 65.8534 1074.8 67.9084 1077.4 67.9084C1078.65 67.9084 1079.92 67.4377 1080.96 66.3911C1084.13 63.1504 1081.89 57.6484 1077.36 57.6484H1077.36Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.39 328.471C1063.63 328.471 1064.9 328.004 1065.92 326.97C1069.13 323.724 1066.85 318.211 1062.32 318.211C1059.54 318.211 1057.29 320.52 1057.29 323.334C1057.29 326.432 1059.79 328.471 1062.39 328.471H1062.39Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.39 67.9084C1063.63 67.9084 1064.89 67.4377 1065.92 66.3911C1069.13 63.1504 1066.85 57.6484 1062.32 57.6484C1059.54 57.6869 1057.29 59.9529 1057.29 62.7617C1057.29 65.8534 1059.79 67.9084 1062.39 67.9084H1062.39Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1062.4 38.6908C1063.64 38.6908 1064.9 38.2201 1065.93 37.173C1069.14 33.9303 1066.86 28.4258 1062.34 28.4258C1059.56 28.4258 1057.32 30.7314 1057.32 33.5416C1057.3 36.6347 1059.8 38.6908 1062.4 38.6908Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 401.123C1049.42 401.123 1050.68 400.657 1051.7 399.623C1054.91 396.376 1052.63 390.863 1048.14 390.863C1045.32 390.863 1043.07 393.167 1043.07 395.986C1043.07 399.079 1045.58 401.123 1048.18 401.123H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 386.91C1049.41 386.91 1050.68 386.439 1051.7 385.392C1054.91 382.149 1052.63 376.645 1048.14 376.645C1045.32 376.645 1043.07 378.95 1043.07 381.76C1043.07 384.853 1045.58 386.91 1048.18 386.91H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.17 371.904C1049.41 371.904 1050.68 371.438 1051.7 370.391C1054.91 367.15 1052.63 361.648 1048.14 361.648C1045.32 361.687 1043.07 363.948 1043.07 366.8C1043.07 369.863 1045.57 371.904 1048.17 371.904H1048.17Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 328.471C1049.42 328.471 1050.68 328.004 1051.7 326.97C1054.91 323.724 1052.63 318.211 1048.14 318.211C1045.32 318.211 1043.07 320.52 1043.07 323.334C1043.07 326.432 1045.58 328.471 1048.18 328.471H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.18 67.9084C1049.41 67.9084 1050.68 67.4377 1051.7 66.3911C1054.91 63.1504 1052.63 57.6484 1048.14 57.6484C1045.32 57.6484 1043.07 59.9529 1043.07 62.7617C1043.07 65.8534 1045.58 67.9084 1048.18 67.9084H1048.18Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1048.16 38.6908C1049.41 38.6908 1050.67 38.2201 1051.71 37.173C1054.91 33.9303 1052.63 28.4258 1048.16 28.4258C1045.34 28.4258 1043.1 30.7314 1043.1 33.5416C1043.08 36.6347 1045.56 38.6908 1048.16 38.6908Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 386.91C1035.2 386.91 1036.46 386.439 1037.49 385.392C1040.7 382.149 1038.46 376.645 1033.93 376.645C1031.1 376.645 1028.86 378.95 1028.86 381.76C1028.86 384.853 1031.36 386.91 1033.96 386.91Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.94 357.695C1035.19 357.695 1036.46 357.218 1037.49 356.163C1040.7 352.95 1038.46 347.43 1033.93 347.43C1031.1 347.43 1028.86 349.703 1028.86 352.56C1028.86 355.653 1031.35 357.695 1033.94 357.695Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 343.479C1035.2 343.479 1036.46 343.012 1037.49 341.978C1040.7 338.731 1038.46 333.219 1033.93 333.219C1031.1 333.219 1028.86 335.523 1028.86 338.342C1028.86 341.435 1031.36 343.479 1033.96 343.479Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.96 67.9084C1035.2 67.9084 1036.46 67.4377 1037.49 66.3911C1040.7 63.1504 1038.46 57.6484 1033.93 57.6484C1031.1 57.6484 1028.86 59.9529 1028.86 62.7617C1028.86 65.8534 1031.36 67.9084 1033.96 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M1033.95 53.6896C1035.2 53.6896 1036.46 53.2237 1037.49 52.1724C1040.7 48.9752 1038.46 43.4297 1033.93 43.4297C1031.1 43.4729 1028.86 45.7342 1028.86 48.5859C1028.86 51.6442 1031.36 53.6896 1033.95 53.6896Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1033.94 28.4258C1031.17 28.4258 1028.89 30.7314 1028.89 33.5416C1028.86 36.6347 1031.35 38.6908 1033.95 38.6908C1035.19 38.6908 1036.46 38.2201 1037.5 37.173C1040.7 33.9687 1038.46 28.4258 1033.94 28.4258Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 386.91C1020.2 386.91 1021.47 386.439 1022.49 385.392C1025.7 382.193 1023.46 376.645 1018.93 376.645C1016.11 376.645 1013.86 378.95 1013.86 381.76C1013.86 384.853 1016.36 386.91 1018.96 386.91H1018.96Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 371.904C1020.2 371.904 1021.47 371.438 1022.49 370.391C1025.7 367.189 1023.46 361.648 1018.93 361.648C1016.11 361.648 1013.86 363.948 1013.86 366.8C1013.86 369.863 1016.36 371.904 1018.96 371.904Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1018.96 38.6944C1020.2 38.6944 1021.47 38.2277 1022.5 37.1929C1025.71 33.9833 1023.46 28.4297 1018.95 28.4297C1016.17 28.4297 1013.89 30.7013 1013.89 33.555C1013.87 36.6537 1016.35 38.6944 1018.96 38.6944H1018.96Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 361.648C1001.93 361.648 999.648 363.948 999.648 366.762C999.648 369.849 1002.15 371.904 1004.76 371.904C1006.01 371.904 1007.28 371.438 1008.31 370.391C1011.48 367.189 1009.24 361.648 1004.72 361.648H1004.72Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.72 347.43C1001.93 347.43 999.648 349.703 999.648 352.56C999.648 355.653 1002.13 357.695 1004.74 357.695C1005.99 357.695 1007.27 357.218 1008.31 356.163C1011.48 352.95 1009.24 347.43 1004.72 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1004.73 38.6944C1005.98 38.6944 1007.25 38.2277 1008.28 37.1929C1011.48 33.9833 1009.25 28.4297 1004.73 28.4297C1001.95 28.4297 999.672 30.7013 999.672 33.555C999.643 36.6537 1002.13 38.6944 1004.73 38.6944Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.485 347.43C987.71 347.43 985.434 349.703 985.434 352.56C985.434 355.653 987.929 357.695 990.523 357.695C991.771 357.695 993.047 357.218 994.071 356.163C997.273 352.95 994.996 347.43 990.485 347.43H990.485Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.485 333.219C987.71 333.219 985.434 335.523 985.434 338.342C985.434 341.435 987.929 343.479 990.528 343.479C991.771 343.479 993.037 343.012 994.071 341.978C997.273 338.731 994.996 333.219 990.485 333.219H990.485Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.523 53.6896C991.766 53.6896 993.037 53.2237 994.071 52.1724C997.273 48.9752 994.996 43.4297 990.485 43.4297C987.71 43.4729 985.434 45.7342 985.434 48.5859C985.434 51.6442 987.924 53.6896 990.523 53.6896H990.523Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M990.528 38.6944C991.771 38.6944 993.037 38.2277 994.071 37.1929C997.273 33.9446 994.996 28.4297 990.485 28.4297C987.71 28.4297 985.434 30.7398 985.434 33.555C985.434 36.6537 987.929 38.6944 990.528 38.6944H990.528Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.527 318.211C972.709 318.211 970.475 320.52 970.438 323.334C970.438 326.432 972.947 328.471 975.546 328.471C976.789 328.471 978.051 328.004 979.075 326.97C982.277 323.724 980 318.211 975.527 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M975.542 53.6896C976.784 53.6896 978.051 53.2237 979.075 52.1724C982.277 48.9752 980 43.4297 975.527 43.4297C972.709 43.4729 970.475 45.7342 970.438 48.5859C970.438 51.6442 972.942 53.6896 975.541 53.6896H975.542Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.289 333.219C958.504 333.219 956.219 335.484 956.219 338.342C956.219 341.435 958.704 343.479 961.303 343.479C962.546 343.479 963.812 343.012 964.85 341.978C968.063 338.77 965.816 333.219 961.289 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.289 318.211C958.504 318.211 956.219 320.52 956.219 323.334C956.219 326.432 958.704 328.471 961.303 328.471C962.546 328.471 963.812 328.004 964.85 326.97C968.063 323.767 965.816 318.249 961.289 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M961.298 53.6896C962.541 53.6896 963.812 53.2237 964.85 52.1724C968.063 48.9752 965.816 43.4729 961.289 43.4297C958.504 43.4297 956.219 45.7342 956.219 48.5859C956.219 51.6442 958.699 53.6896 961.298 53.6896Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.064 347.43C944.284 347.43 942.012 349.703 942.012 352.56C942.012 355.653 944.488 357.695 947.082 357.695C948.33 357.695 949.606 357.218 950.649 356.163C953.846 352.95 951.574 347.43 947.063 347.43H947.064Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 333.219C944.291 333.219 942.012 335.484 942.012 338.342C942.012 341.435 944.491 343.479 947.094 343.479C948.331 343.479 949.602 343.012 950.639 341.978C953.846 338.77 951.605 333.219 947.079 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M947.079 57.6484C944.291 57.6484 942.012 59.9574 942.012 62.7715C942.012 65.8691 944.491 67.9087 947.094 67.9087C948.331 67.9087 949.602 67.4419 950.639 66.4077C953.846 63.1995 951.605 57.6869 947.079 57.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 390.863C929.278 390.863 927 393.167 927 395.986C927 399.079 929.491 401.123 932.096 401.123C933.34 401.123 934.607 400.657 935.641 399.623C938.844 396.376 936.566 390.863 932.092 390.863Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 376.645C929.278 376.645 927 378.95 927 381.76C927 384.853 929.51 386.91 932.116 386.91C933.354 386.91 934.621 386.439 935.641 385.392C938.844 382.149 936.566 376.645 932.092 376.645Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 361.648C929.278 361.648 927.038 363.948 927 366.762C927 369.849 929.491 371.904 932.096 371.904C933.34 371.904 934.607 371.438 935.641 370.391C938.844 367.189 936.566 361.648 932.092 361.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 347.43C929.278 347.43 927 349.703 927 352.56C927 355.653 929.496 357.695 932.092 357.695C933.34 357.695 934.611 357.218 935.641 356.163C938.844 352.95 936.566 347.43 932.092 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 333.219C929.278 333.219 927.038 335.523 927 338.342C927 341.435 929.491 343.479 932.096 343.479C933.34 343.479 934.607 343.012 935.641 341.978C938.844 338.731 936.566 333.219 932.092 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.092 318.211C929.278 318.211 927 320.52 927 323.334C927 326.432 929.491 328.471 932.096 328.471C933.34 328.471 934.607 328.004 935.641 326.97C938.844 323.724 936.566 318.211 932.092 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.096 82.115C933.34 82.115 934.607 81.6889 935.641 80.7341C938.844 77.7367 936.566 72.6484 932.092 72.6484C929.278 72.6484 927.038 74.7752 927 77.3727C927 80.2323 929.491 82.115 932.096 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M932.096 67.9084C933.34 67.9084 934.607 67.4377 935.641 66.3911C938.844 63.1504 936.566 57.6484 932.092 57.6484C929.278 57.6484 927.038 59.9529 927 62.7617C927 65.8534 929.491 67.9084 932.096 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.857 376.645C915.03 376.645 912.789 378.95 912.789 381.76C912.789 384.853 915.287 386.91 917.886 386.91C919.127 386.91 920.388 386.439 921.416 385.392C924.624 382.149 922.344 376.645 917.857 376.645Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.857 361.648C915.03 361.687 912.789 363.948 912.789 366.762C912.789 369.849 915.287 371.904 917.886 371.904C919.127 371.904 920.388 371.438 921.416 370.391C924.624 367.15 922.344 361.648 917.857 361.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.857 347.43C915.03 347.43 912.789 349.703 912.789 352.56C912.789 355.653 915.273 357.695 917.862 357.695C919.113 357.695 920.384 357.218 921.416 356.163C924.624 352.95 922.344 347.43 917.857 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.857 333.219C915.03 333.219 912.789 335.523 912.789 338.342C912.789 341.435 915.287 343.479 917.886 343.479C919.127 343.479 920.388 343.012 921.416 341.978C924.624 338.731 922.344 333.219 917.857 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.857 318.211C915.03 318.211 912.789 320.52 912.789 323.334C912.789 326.432 915.287 328.471 917.886 328.471C919.127 328.471 920.388 328.004 921.416 326.97C924.624 323.724 922.344 318.211 917.857 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.857 304.004C915.03 304.042 912.789 306.304 912.789 309.155C912.789 312.218 915.283 314.259 917.881 314.259C919.123 314.259 920.388 313.793 921.416 312.747C924.624 309.506 922.344 304.004 917.857 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M917.886 97.1241C919.127 97.1241 920.388 96.6573 921.416 95.6226C924.624 92.3743 922.344 86.8594 917.857 86.8594C915.03 86.8594 912.789 89.1695 912.789 91.9847C912.789 95.0837 915.287 97.1241 917.886 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 318.211C900.859 318.211 898.574 320.52 898.574 323.334C898.574 326.432 901.073 328.471 903.687 328.471C904.934 328.471 906.205 328.004 907.243 326.97C910.418 323.724 908.172 318.211 903.644 318.211H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 304.004C900.859 304.042 898.574 306.304 898.574 309.155C898.574 312.218 901.073 314.259 903.682 314.259C904.929 314.259 906.205 313.793 907.243 312.747C910.418 309.506 908.172 304.004 903.644 304.004H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 86.8594C900.859 86.8594 898.574 89.1695 898.574 91.9847C898.574 95.0837 901.073 97.1241 903.687 97.1241C904.934 97.1241 906.205 96.6573 907.243 95.6226C910.418 92.3743 908.172 86.8594 903.644 86.8594H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 72.6484C900.859 72.6484 898.574 74.7752 898.574 77.3727C898.574 80.2323 901.073 82.115 903.687 82.115C904.934 82.115 906.205 81.6889 907.243 80.7341C910.418 77.7367 908.172 72.6484 903.644 72.6484H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 57.6484C900.859 57.6484 898.574 59.9529 898.574 62.7617C898.574 65.8534 901.073 67.9084 903.687 67.9084C904.934 67.9084 906.205 67.4377 907.243 66.3911C910.418 63.1504 908.172 57.6484 903.644 57.6484H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M903.644 43.4297C900.859 43.4729 898.574 45.7342 898.574 48.5859C898.574 51.6442 901.073 53.6896 903.682 53.6896C904.929 53.6896 906.205 53.2237 907.243 52.1724C910.418 48.9752 908.172 43.4297 903.644 43.4297H903.644Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 304.004C885.857 304.004 883.578 306.304 883.578 309.155C883.578 312.218 886.072 314.259 888.679 314.259C889.931 314.259 891.201 313.793 892.244 312.747C895.413 309.545 893.171 304.004 888.646 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 72.6484C885.857 72.6484 883.578 74.7752 883.578 77.3727C883.578 80.2323 886.076 82.115 888.689 82.115C889.931 82.115 891.206 81.6889 892.244 80.7341C895.413 77.7367 893.171 72.6484 888.646 72.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 57.6484C885.857 57.6484 883.578 59.9529 883.578 62.7617C883.578 65.8534 886.076 67.9084 888.684 67.9084C889.931 67.9084 891.206 67.4377 892.244 66.3911C895.413 63.1504 893.171 57.6484 888.646 57.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M888.646 28.4258C885.857 28.4258 883.578 30.7359 883.578 33.5511C883.578 36.6498 886.076 38.6905 888.689 38.6905C889.931 38.6905 891.206 38.2238 892.244 37.189C895.413 33.9407 893.171 28.4258 888.646 28.4258Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.417 304.004C871.641 304.042 869.363 306.304 869.363 309.155C869.363 312.218 871.855 314.259 874.455 314.259C875.698 314.259 876.97 313.793 878.004 312.747C881.207 309.506 878.93 304.004 874.417 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.417 289.785C871.641 289.824 869.363 292.091 869.363 294.939C869.363 298.004 871.855 300.05 874.455 300.05C875.698 300.05 876.97 299.579 878.004 298.532C881.207 295.328 878.93 289.785 874.417 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 82.115C875.703 82.115 876.97 81.6889 878.004 80.7341C881.207 77.7367 878.93 72.6484 874.417 72.6484C871.641 72.6484 869.363 74.7752 869.363 77.3727C869.363 80.2323 871.855 82.115 874.46 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 67.9084C875.703 67.9084 876.97 67.4377 878.004 66.3911C881.207 63.1504 878.93 57.6484 874.417 57.6484C871.641 57.6484 869.363 59.9529 869.363 62.7617C869.363 65.8534 871.855 67.9084 874.46 67.9084Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 38.6944C875.703 38.6944 876.97 38.2277 878.004 37.1929C881.207 33.9446 878.93 28.4297 874.417 28.4297C871.641 28.4297 869.363 30.7398 869.363 33.555C869.363 36.6537 871.855 38.6944 874.46 38.6944Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M874.46 10.2647C875.703 10.2647 876.97 9.79797 878.004 8.7632C881.207 5.51489 878.93 0 874.417 0C871.641 0 869.363 2.31008 869.363 5.12534C869.363 8.22434 871.855 10.2647 874.46 10.2647Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.242 333.219C857.425 333.219 855.19 335.523 855.152 338.342C855.152 341.435 857.643 343.479 860.247 343.479C861.485 343.479 862.756 343.012 863.79 341.978C866.987 338.731 864.715 333.219 860.242 333.219Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.242 318.211C857.425 318.211 855.152 320.52 855.152 323.334C855.152 326.432 857.643 328.471 860.247 328.471C861.485 328.471 862.756 328.004 863.79 326.97C866.987 323.724 864.715 318.211 860.242 318.211Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.242 289.785C857.425 289.824 855.19 292.091 855.152 294.939C855.152 298.004 857.638 300.05 860.237 300.05C861.48 300.05 862.751 299.579 863.79 298.532C866.987 295.328 864.715 289.785 860.242 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.247 82.115C861.485 82.115 862.756 81.6889 863.79 80.7341C866.987 77.7367 864.715 72.6484 860.242 72.6484C857.425 72.6484 855.19 74.7752 855.152 77.3727C855.152 80.2323 857.643 82.115 860.247 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M860.247 38.6905C861.485 38.6905 862.756 38.2238 863.79 37.189C866.987 33.9407 864.715 28.4258 860.242 28.4258C857.425 28.4258 855.19 30.7359 855.152 33.5511C855.152 36.6498 857.643 38.6905 860.247 38.6905Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.218 347.43C842.395 347.43 840.148 349.703 840.148 352.56C840.148 355.653 842.633 357.695 845.237 357.695C846.494 357.695 847.775 357.218 848.817 356.163C851.992 352.95 849.746 347.43 845.218 347.43Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.252 343.479C846.489 343.479 847.756 343.012 848.779 341.978C851.992 338.731 849.746 333.219 845.218 333.219C842.395 333.219 840.148 335.523 840.148 338.342C840.148 341.435 842.648 343.479 845.252 343.479Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.252 328.471C846.489 328.471 847.756 328.004 848.779 326.97C851.992 323.724 849.746 318.211 845.218 318.211C842.395 318.211 840.148 320.52 840.148 323.334C840.148 326.432 842.648 328.471 845.252 328.471Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.218 274.789C842.395 274.789 840.148 277.096 840.148 279.917C840.148 283.008 842.633 285.045 845.237 285.045C846.494 285.045 847.775 284.573 848.817 283.518C851.992 280.307 849.746 274.789 845.218 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.252 97.1241C846.489 97.1241 847.756 96.6573 848.779 95.6226C851.992 92.3743 849.746 86.8594 845.218 86.8594C842.395 86.8594 840.148 89.1695 840.148 91.9847C840.148 95.0837 842.648 97.1241 845.252 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.252 82.115C846.489 82.115 847.756 81.6889 848.779 80.7341C851.992 77.7367 849.746 72.6484 845.218 72.6484C842.395 72.6484 840.148 74.7752 840.148 77.3727C840.148 80.2323 842.648 82.115 845.252 82.115Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M845.242 53.6896C846.485 53.6896 847.751 53.2237 848.779 52.1724C851.992 48.9752 849.746 43.4297 845.218 43.4297C842.395 43.4729 840.148 45.7342 840.148 48.5859C840.148 51.6442 842.648 53.6896 845.242 53.6896Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M845.252 10.2647C846.489 10.2647 847.756 9.79797 848.779 8.7632C851.992 5.51489 849.746 0 845.218 0C842.395 0 840.148 2.31008 840.148 5.12534C840.148 8.22434 842.648 10.2647 845.252 10.2647Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 361.648C828.217 361.648 825.938 363.948 825.938 366.762C825.938 369.849 828.436 371.904 831.044 371.904C832.29 371.904 833.565 371.438 834.603 370.391C837.772 367.15 835.531 361.648 831.005 361.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 347.43C828.217 347.43 825.938 349.703 825.938 352.56C825.938 355.653 828.422 357.695 831.024 357.695C832.276 357.695 833.556 357.218 834.603 356.163C837.772 352.95 835.531 347.43 831.005 347.43Z"
            fill="#FFC796"
          />
          <path
            className="map-grp map-grp-2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 318.211C828.217 318.211 825.938 320.52 825.938 323.334C825.938 326.432 828.436 328.471 831.048 328.471C832.29 328.471 833.565 328.004 834.603 326.97C837.772 323.724 835.531 318.211 831.006 318.211H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 304.004C828.217 304.042 825.938 306.304 825.938 309.155C825.938 312.218 828.431 314.259 831.039 314.259C832.29 314.259 833.561 313.793 834.603 312.747C837.772 309.506 835.531 304.004 831.006 304.004H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 274.789C828.217 274.789 825.938 277.057 825.938 279.917C825.938 283.008 828.422 285.045 831.024 285.045C832.276 285.045 833.556 284.573 834.603 283.518C837.772 280.307 835.531 274.789 831.005 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 86.8594C828.217 86.8594 825.938 89.1695 825.938 91.9847C825.938 95.0837 828.436 97.1241 831.048 97.1241C832.29 97.1241 833.565 96.6573 834.603 95.6226C837.772 92.3743 835.531 86.8594 831.006 86.8594H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 57.6484C828.217 57.6484 825.938 59.9529 825.938 62.7617C825.938 65.8534 828.436 67.9084 831.044 67.9084C832.29 67.9084 833.565 67.4377 834.603 66.3911C837.772 63.1504 835.531 57.6484 831.005 57.6484Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M831.005 43.4297C828.217 43.4729 825.938 45.7342 825.938 48.5859C825.938 51.6442 828.431 53.6896 831.039 53.6896C832.29 53.6896 833.561 53.2237 834.603 52.1724C837.772 48.9752 835.531 43.4297 831.006 43.4297H831.005Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 361.648C814.004 361.648 811.719 363.948 811.719 366.762C811.719 369.849 814.218 371.904 816.831 371.904C818.079 371.904 819.35 371.438 820.388 370.391C823.563 367.189 821.316 361.648 816.789 361.648Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 304.004C814.004 304.004 811.719 306.304 811.719 309.155C811.719 312.218 814.218 314.259 816.827 314.259C818.074 314.259 819.35 313.793 820.388 312.747C823.563 309.545 821.316 304.004 816.789 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 289.785C814.004 289.824 811.719 292.091 811.719 294.939C811.719 298.004 814.218 300.05 816.827 300.05C818.074 300.05 819.35 299.579 820.388 298.532C823.563 295.328 821.316 289.785 816.789 289.785Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.789 274.789C814.004 274.789 811.719 277.057 811.719 279.917C811.719 283.008 814.204 285.045 816.808 285.045C818.064 285.045 819.345 284.573 820.388 283.518C823.563 280.307 821.316 274.789 816.789 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.831 97.1241C818.079 97.1241 819.35 96.6573 820.388 95.6226C823.563 92.3743 821.316 86.8594 816.789 86.8594C814.004 86.8594 811.719 89.1695 811.719 91.9847C811.719 95.0837 814.218 97.1241 816.831 97.1241Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M816.831 24.4742C818.079 24.4742 819.35 24.0122 820.388 22.978C823.563 19.7314 821.316 14.2188 816.789 14.2188C814.004 14.2188 811.719 16.5229 811.719 19.3371C811.719 22.4347 814.218 24.4742 816.831 24.4742Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.808 357.695C803.06 357.695 804.331 357.218 805.36 356.163C808.557 352.95 806.285 347.43 801.77 347.43C798.995 347.43 796.723 349.703 796.723 352.56C796.723 355.653 799.213 357.695 801.808 357.695Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.817 343.479C803.055 343.479 804.326 343.012 805.36 341.978C808.557 338.731 806.285 333.219 801.77 333.219C798.995 333.219 796.761 335.523 796.723 338.342C796.723 341.435 799.213 343.479 801.817 343.479Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.808 300.05C803.05 300.05 804.322 299.579 805.36 298.532C808.557 295.328 806.285 289.785 801.77 289.785C798.995 289.824 796.761 292.091 796.723 294.939C796.723 298.004 799.208 300.05 801.808 300.05Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.808 285.045C803.06 285.045 804.331 284.573 805.36 283.518C808.557 280.307 806.285 274.789 801.77 274.789C798.995 274.789 796.723 277.095 796.723 279.917C796.723 283.008 799.213 285.044 801.808 285.044V285.045Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M801.817 270.831C803.055 270.831 804.326 270.364 805.36 269.33C808.557 266.083 806.285 260.57 801.77 260.57C798.995 260.57 796.761 262.879 796.723 265.693C796.723 268.791 799.213 270.831 801.817 270.831Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 333.219C784.785 333.219 782.546 335.523 782.508 338.342C782.508 341.435 785.017 343.479 787.621 343.479C788.859 343.479 790.126 343.012 791.145 341.978C794.347 338.731 792.07 333.219 787.597 333.219H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 318.211C784.785 318.211 782.546 320.52 782.508 323.334C782.508 326.432 785.017 328.471 787.621 328.471C788.859 328.471 790.126 328.004 791.145 326.97C794.347 323.724 792.07 318.211 787.597 318.211H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 304.004C784.785 304.004 782.546 306.304 782.508 309.155C782.508 312.218 785.012 314.259 787.612 314.259C788.854 314.259 790.121 313.793 791.145 312.747C794.347 309.545 792.07 304.004 787.597 304.004H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M787.598 289.785C784.785 289.824 782.546 292.091 782.508 294.939C782.508 298.004 785.012 300.05 787.612 300.05C788.854 300.05 790.121 299.579 791.145 298.532C794.347 295.328 792.07 289.785 787.597 289.785H787.598Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.369 304.004C770.542 304.042 768.301 306.304 768.301 309.155C768.301 312.218 770.794 314.259 773.402 314.259C774.653 314.259 775.924 313.793 776.966 312.747C780.135 309.506 777.894 304.004 773.369 304.004Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.385 300.05C774.626 300.05 775.892 299.579 776.916 298.532C780.128 295.328 777.886 289.785 773.361 289.785C770.534 289.824 768.293 292.091 768.293 294.939C768.293 298.004 770.787 300.05 773.385 300.05Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.369 274.789C770.542 274.789 768.301 277.096 768.301 279.917C768.301 283.008 770.785 285.045 773.388 285.045C774.639 285.045 775.919 284.573 776.966 283.518C780.135 280.307 777.894 274.789 773.369 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 270.831C774.631 270.831 775.892 270.364 776.916 269.33C780.128 266.083 777.886 260.57 773.361 260.57C770.534 260.57 768.293 262.879 768.293 265.693C768.293 268.791 770.791 270.831 773.389 270.831Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.389 256.618C774.631 256.618 775.892 256.153 776.916 255.106C780.128 251.865 777.886 246.363 773.361 246.363C770.534 246.363 768.293 248.663 768.293 251.477C768.293 254.563 770.791 256.618 773.389 256.618Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M773.396 241.62C774.641 241.62 775.913 241.15 776.949 240.103C780.14 236.875 777.889 231.355 773.406 231.355H773.358C770.538 231.355 768.301 233.661 768.301 236.471C768.301 239.564 770.794 241.62 773.396 241.62Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 274.789C755.574 274.789 753.289 277.057 753.289 279.917C753.289 283.008 755.774 285.045 758.378 285.045C759.635 285.045 760.915 284.573 761.958 283.518C765.133 280.307 762.886 274.789 758.359 274.789V274.789Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.359 246.363C755.574 246.363 753.289 248.663 753.289 251.477C753.289 254.563 755.788 256.618 758.402 256.618C759.649 256.618 760.92 256.153 761.958 255.106C765.133 251.865 762.886 246.363 758.359 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M758.394 231.355H758.347C755.569 231.355 753.289 233.661 753.289 236.471C753.289 239.564 755.801 241.62 758.418 241.62C759.667 241.62 760.94 241.15 761.975 240.103C765.129 236.875 762.877 231.355 758.395 231.355H758.394Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M744.126 246.363C741.351 246.363 739.074 248.663 739.074 251.477C739.074 254.563 741.569 256.618 744.169 256.618C745.411 256.618 746.678 256.153 747.712 255.106C750.914 251.865 748.642 246.363 744.126 246.363Z"
            fill="#FFC796"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            className="map-grp map-grp-1"
            d="M744.184 231.355H744.132C741.354 231.355 739.074 233.661 739.074 236.471C739.074 239.564 741.586 241.62 744.203 241.62C745.452 241.62 746.73 241.15 747.765 240.103C750.918 236.875 748.705 231.355 744.184 231.355Z"
            fill="#FFC796"
          />
        </g>
        <Locations />
      </g>
      <defs>
        <clipPath id="clip0_5544_91537">
          <rect width="1357.21" height="690.903" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RevealingMap
