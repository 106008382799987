import { ReactNode } from 'react'
import PreviewAlert from './preview-alert'
import { ColorField, Maybe } from '../generated/graphql'
import dynamic from 'next/dynamic'

const CookieConsent = dynamic(() => import('./cookie-consent'), {
  ssr: false,
})

type Props = {
  preview: boolean
  children: ReactNode
  backgroundColor?: Maybe<ColorField>
  textColor?: Maybe<ColorField>
  rystadDotPatternURL?: string
}

function Layout({
  preview,
  children,
  backgroundColor,
  textColor,
  rystadDotPatternURL,
}: Props) {
  return (
    <div
      className="min-h-screen bg-local bg-top bg-no-repeat"
      style={{
        backgroundColor: backgroundColor?.hex,
        color: textColor?.hex,
        backgroundImage: rystadDotPatternURL,
      }}
    >
      <PreviewAlert preview={preview} />
      <main>
        <CookieConsent />
        {children}
      </main>
    </div>
  )
}

export default Layout
