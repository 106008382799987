import { gsap, ScrollTrigger } from '../../lib/gsap'
import { RefObject } from 'react'

function imagesCollapsingAnimation(
  stage: RefObject<HTMLDivElement>,
  imagesRef: RefObject<HTMLImageElement | null>[],
  backgroundRef: any
) {
  ScrollTrigger.matchMedia({
    '(max-width: 768px)': function () {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: imagesRef[0].current,
          start: 'top 70%',
        },
      })

      tl.fromTo(
        imagesRef[0].current,
        {
          y: 150,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
        }
      )

      const tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: imagesRef[1].current,
          start: 'top 70%',
        },
      })

      tl2.fromTo(
        imagesRef[1].current,
        {
          y: 150,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
        }
      )

      const tl3 = gsap.timeline({
        scrollTrigger: {
          trigger: imagesRef[2].current,
          start: 'top 70%',
        },
      })

      tl3.fromTo(
        imagesRef[2].current,
        {
          y: 150,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1,
        }
      )
    },
    '(min-width: 768px)': function () {
      const t2 = gsap.timeline({
        scrollTrigger: {
          trigger: backgroundRef,
          start: 'top top',
          end: 'bottom 70%',
          scrub: 0.5,
        },
      })

      t2.to(backgroundRef, {
        backgroundColor: '#ffffff',
      })

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: stage.current,
          start: 'top 10%',
          end: '100%',
          scrub: 1,
          pin: stage.current,
          pinSpacing: true,
        },
      })

      tl.to(
        imagesRef[0].current,
        {
          translateX: -351,
          translateY: -307,
        },
        0
      )

      tl.to(
        imagesRef[1].current,
        {
          translateX: 372,
          translateY: -156,
        },
        0
      )
      tl.to(
        imagesRef[2].current,
        {
          translateX: -397,
          translateY: 197,
        },
        0
      )
      tl.to(
        imagesRef[3].current,
        {
          translateX: 490,
          translateY: 446,
        },
        0
      )
      tl.to(
        imagesRef[4].current,
        {
          translateX: -190,
          translateY: 635,
        },
        0
      )
    },
  })
}

export default imagesCollapsingAnimation
