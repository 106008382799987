import { SolutionFilterTypes } from '../../enums/solution-filter-types'
import { TagType } from '../../types/service-menu-type'

type Props = {
  type: SolutionFilterTypes
  items: TagType[]
  selected: string
  selectItem: (type: SolutionFilterTypes, value: string) => void
}
function FilterItem({ type, items, selected, selectItem }: Props) {
  return (
    <div className="flex flex-col items-start gap-1">
      {items?.map((item) => (
        <button
          onClick={() => selectItem(type, item.slug)}
          key={item.slug}
          className={`text-left ${
            selected === item.slug
              ? 'border-b border-r-primary-rystad-blue'
              : 'border-b-0'
          }`}
        >
          {item.name}
        </button>
      ))}
      <div className={'h-2'} />
    </div>
  )
}

export default FilterItem
