import { useMenuContext } from '../menu-context'
import Section from '../../../components/section'
import Link from 'next/link'
import MenuRightArrow from '../../../icons/menu-right-arrow'
import CtaLink from '../../../components/cta-link'

function ExpandedMenu() {
  const {
    dispatch,
    state: { block },
  } = useMenuContext()

  return (
    <Section classOverride={'h-screen'}>
      <div className={'col-span-full flex flex-col gap-2'}>
        {block?.menuItems.map((menuItem) => (
          <div key={menuItem.id}>
            {menuItem.link && menuItem.link !== '/events' ? (
              <Link scroll={false} href={menuItem.link}>
                <a
                  onClick={() =>
                    dispatch({
                      type: 'toggleMobileMenuOpen',
                      payload: false,
                    })
                  }
                  className={'flex items-center gap-1 text-text-base'}
                >
                  {menuItem.label}
                </a>
              </Link>
            ) : (
              <button
                className={'flex items-center gap-1 text-left text-text-base'}
                onClick={() => {
                  dispatch({
                    type: 'setMobileMenuItemBlocks',
                    payload: menuItem.menuItemContent,
                  })
                }}
              >
                {menuItem.label}
                <MenuRightArrow />
              </button>
            )}
          </div>
        ))}
        <div className="my-auto justify-center">
          {block?.signInButton?.map((signInButton) => (
            <CtaLink key={signInButton?.id} cta={signInButton} />
          ))}
        </div>
      </div>
    </Section>
  )
}

export default ExpandedMenu
