import { gql } from '@apollo/client'
import pressReleaseOverviewArticle from '../block-queries/press-release-overview-article'
import responsiveImageFragment from '../fragments/responsive-image'
import tag from '../fragments/tag'

const ALL_NEWS = gql`
  query AllPressReleases($limit: IntType, $offset: IntType, $filter: PressReleaseModelFilter) {
    allPressReleases(first: $limit, skip: $offset, filter: $filter, orderBy: publicationDate_DESC) {
      id
      position
      _publishedAt
      slug
      publicationDate
      energyType {
        id
        __typename
        name
        tagName
        tagDotColor {
          hex
        }
      }
      ${tag}

      blocks {
        __typename
        ${pressReleaseOverviewArticle}
      }
    }

    _allPressReleasesMeta {
      count
    }
  }
  ${responsiveImageFragment}
`

export default ALL_NEWS
