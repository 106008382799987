import useSWR from 'swr'
import { WorkableResponse } from '../types/WorkableTypes'
import { SWRResponse } from 'swr/dist/types'

interface IWorkableResponse {
  data: WorkableResponse
}

function useWorkable() : SWRResponse<WorkableResponse, any> {
  return useSWR<WorkableResponse>('/api/workable', () =>
    fetch('/api/workable', {
      method: 'GET',
    }).then((res) => res.json())
  )
}

export default useWorkable
