import { Maybe } from 'graphql/jsutils/Maybe'
import { Image, ResponsiveImageType } from 'react-datocms'

type Props = {
  videoLink: Maybe<string> | undefined
  image?: Maybe<ResponsiveImageType>
}
function ArticleThumbnail({ videoLink, image }: Props) {
  return (
    <div className="col-span-full md:col-start-1 md:inline-block lg:col-span-4">
      {videoLink && (
        <video poster={image?.src ?? '#'}>
          <source type="video/mp4" src={videoLink} />
        </video>
      )}
      {!videoLink && image && (
        <Image
          className="aspect-[1.5]"
          pictureClassName="object-cover"
          style={{ maxWidth: 'none' }}
          data={image}
        />
      )}
    </div>
  )
}

export default ArticleThumbnail
