
const monthlyNewsLetter = `
<!-- Google Fonts begins here -->

<link href='https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,500i,700,700i,900|Montserrat:400,700|'
      rel='stylesheet'>

<!-- Google Fonts end here -->

<!-- CSS begins here -->

<style>#user-form-template-369 br {
    display: none
}

#user-form-template-369 input[type="checkbox"] {
    opacity: 0;
    position: absolute
}

#user-form-template-369 input[type="radio"] {
    opacity: 0;
}

#user-form-template-369 input[type="checkbox"]:checked + .checkbox:before {
    content: "";
    position: absolute;
    top: 3px;
    right: 5px;
    width: 2px;
    height: 10px;
    z-index: 1;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
}

#user-form-template-369 input[type="checkbox"]:checked + .checkbox:after {
    content: "";
    position: absolute;
    top: 7px;
    left: 5px;
    width: 2px;
    height: 6px;
    z-index: 1;
    -webkit-transform: rotate(-44deg);
    -moz-transform: rotate(-44deg);
    transform: rotate(-44deg);
}

#user-form-template-369 .checkbox {
    box-sizing: initial;
    width: 17px;
    height: 17px;
    border-radius: 3px;
    border: 2px solid #cbcaca;
    display: inline-block;
    cursor: pointer;
    -webkit-filter: drop-shadow(11px 10px 14px rgba(0, 0, 0, 0.25));
    -moz-filter: drop-shadow(11px 10px 14px rgba(0, 0, 0, 0.25));
    filter: drop-shadow(11px 10px 14px rgba(0, 0, 0, 0.25));
    position: relative;
}

#user-form-template-369 input[type="radio"]:checked + .radiobtn:after {
    content: "";
    position: absolute;
    top: 4px;
    right: 4px;
    width: 9px;
    height: 9px;
    background: #95a5a6;
    z-index: 1;
    border-radius: 30px;
}

#user-form-template-369 input[type="radio"] + .radiobtn {
    border: 2px solid #cbcaca;
    border-radius: 17px;
}

#user-form-template-369 .radiobtn {
    box-sizing: initial;
    width: 17px;
    height: 17px;
    display: inline-block;
    cursor: pointer;
    position: relative;
}

#user-form-template-369 input[type="radio"]:invalid {
    border: none !important
}

#user-form-template-369 input[type="checkbox"]:invalid {
    border: none !important
}

#user-form-template-369 input[type="radio"] ~ .check {
    visibility: hidden;
}

#user-form-template-369 input[type="radio"]:checked ~ .check {
    visibility: visible;
}

#user-form-template-369 .invalid {
    outline: 1px solid #f44336
}

#user-form-template-369 .space-between {
    justify-content: space-between
}

#user-form-template-369 .flex-start {
    justify-content: flex-start
}

#user-form-template-369 .flex-end {
    justify-content: flex-end
}

#user-form-template-369 .end {
    justify-content: flex-end
}

#user-form-template-369 .rating-component .txt-center {
    text-align: center
}

#user-form-template-369 .rating-component .hide {
}

#user-form-template-369 .rating-component .clear {
    float: none;
    clear: both;
}

#user-form-template-369 .rating-component .rating {
    unicode-bidi: bidi-override;
    direction: rtl;
    text-align: center;
    position: relative;
    justify-content: flex-end
}

#user-form-template-369 .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 18px;
}

#user-form-template-369 .switch input {
    width: 100%;
    height: 100%;
    z-index: 1;
    margin: 0px;
    cursor: pointer
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

#user-form-template-369 .slider:before {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    left: 0px;
    top: -2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    box-shadow: rgb(151, 145, 145) 0px 2px 8px 0px;
}

#user-form-template-369 input:checked + .slider {
    background-color: #2196F3;
}

#user-form-template-369 input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

#user-form-template-369 .slider.round {
    border-radius: 34px;
}

#user-form-template-369 input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

#user-form-template-369 div, button:focus {
    outline: none;
}</style>
<style>#user-form-template-369 .form-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}

#user-form-template-369 .user-form-body {
    width: 100%
}

#user-form-template-369 .hidden-field {
    display: none
}

#user-form-template-369 .layout-row {
    flex-direction: row;
    display: flex
}

#user-form-template-369 .layout-wrap {
    flex-wrap: wrap;
}

#user-form-template-369 .layout-column {
    flex-direction: column;
    display: flex
}

#user-form-template-369 .label-left-right {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    display: flex
}

#user-form-template-369 .label-top-bottom {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    display: flex
}

#user-form-template-369 .radio-check {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    display: flex;
    margin: 5px;
}

#user-form-template-369 .phone-area-code {
    width: 50px;
}

.phone-line-code {
    width: 80px;
}

.phone-dash {
    margin-right: 5px;
    display: flex;
    align-items: center
}

#user-form-template-369 .date-range-text {
    margin-top: auto;
    margin-bottom: auto
}

#user-form-template-369 .date-margin {
    margin-right: 5px !important;
}

#user-form-template-369 .date-margin-to {
    margin-right: 5px !important;
    margin-left: 5px !important;
}

@media (min-width: 960px) {
    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field0 {
        width: 50%
    }

    #user-form-template-369 #field0 .element-container {
        opacity: 1;
        margin-bottom: 0px;
        padding-top: 0px;
        border-color: #E9E7E7;
        font-weight: normal;
        margin-top: 0px;
        text-align: left;
        color: rgba(81, 80, 80, 1);
        font-family: Roboto;
        padding-left: 15px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 13px;
        border-width: 1px;
        placeholderColor: rgba(200, 200, 200, 1);
        border-radius: 0px 0px 0px 0px;
        font-style: normal;
        box-shadow: none;
        margin-right: 0px;
        padding-right: 0px;
        margin-left: 0px;
        width: 100%;
        padding-bottom: 0px;
        height: 35px;
        border-style: solid;
        box-sizing: border-box;
    }

    #user-form-template-369 #field0 .component-container {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
        margin-left: 40px;
    }

    #user-form-template-369 #field0 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field1 {
        width: 50%
    }

    #user-form-template-369 #field1 .element-container {
        opacity: 1;
        margin-bottom: 0px;
        padding-top: 0px;
        border-color: #E9E7E7;
        font-weight: normal;
        margin-top: 0px;
        text-align: left;
        color: rgba(81, 80, 80, 1);
        font-family: Roboto;
        padding-left: 15px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 13px;
        border-width: 1px;
        placeholderColor: rgba(200, 200, 200, 1);
        border-radius: 0px 0px 0px 0px;
        box-shadow: none;
        margin-right: 0px;
        padding-right: 0px;
        margin-left: 0px;
        width: 100%;
        padding-bottom: 0px;
        height: 35px;
        border-style: solid;
        box-sizing: border-box;
    }

    #user-form-template-369 #field1 .component-container {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 40px;
        margin-left: 5px;
    }

    #user-form-template-369 #field1 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field2 {
        width: 50%
    }

    #user-form-template-369 #field2 .element-container {
        opacity: 1;
        margin-bottom: 0px;
        padding-top: 0px;
        border-color: #E9E7E7;
        font-weight: normal;
        margin-top: 0px;
        text-align: left;
        color: rgba(81, 80, 80, 1);
        font-family: Roboto;
        padding-left: 15px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 13px;
        border-width: 1px;
        placeholderColor: rgba(200, 200, 200, 1);
        border-radius: 0px 0px 0px 0px;
        font-style: normal;
        box-shadow: none;
        margin-right: 0px;
        padding-right: 0px;
        margin-left: 0px;
        width: 100%;
        padding-bottom: 0px;
        height: 35px;
        border-style: solid;
        box-sizing: border-box;
    }

    #user-form-template-369 #field2 .component-container {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
        margin-left: 40px;
    }

    #user-form-template-369 #field2 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field4 {
        width: 100%
    }

    #user-form-template-369 #field4 .element-container {
        opacity: 1;
        margin-bottom: 0px;
        padding-top: 0px;
        border-color: #C8C8C8;
        font-weight: normal;
        margin-top: 0px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-family: Trebuchet MS;
        padding-left: 0px;
        background-color: rgba(188, 71, 0, 1);
        font-size: 16px;
        border-width: 1px;
        border-radius: 5px 5px 5px 5px;
        font-style: normal;
        box-shadow: none;
        margin-right: 0px;
        padding-right: 0px;
        width: 200px;
        padding-bottom: 0px;
        submit_box: undefined;
        height: 35px;
        border-style: none;
        margin-left: 0px;
    }

    #user-form-template-369 #field4 .component-container {
        margin-top: 25px;
        margin-bottom: 35px;
        margin-right: 55px;
        margin-left: 55px;
    }

    #user-form-template-369 #field4 .submit-box-container {
        flex-direction: row;
        justify-content: center;
        display: flex;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field13 {
        width: 100%
    }

    #user-form-template-369 #field13 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        placeholderColor: rgba(200, 200, 200, 1);
        box-sizing: border-box;
    }

    #user-form-template-369 #field13 .component-container {
        margin-right: 40px;
        margin-left: 40px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field13 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field14 {
        width: 100%
    }

    #user-form-template-369 #field14 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 30px;
        padding-left: 30px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        box-sizing: border-box;
        placeholderColor: rgba(200, 200, 200, 1);
    }

    #user-form-template-369 #field14 .component-container {
        margin-right: 40px;
        margin-left: 40px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field14 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field15 {
        width: 50%
    }

    #user-form-template-369 #field15 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        box-sizing: border-box;
        placeholderColor: rgba(200, 200, 200, 1);
    }

    #user-form-template-369 #field15 .component-container {
        margin-right: 5px;
        margin-left: 40px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field15 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field16 {
        width: 50%
    }

    #user-form-template-369 #field16 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        placeholderColor: rgba(200, 200, 200, 1);
        box-sizing: border-box;
    }

    #user-form-template-369 #field16 .component-container {
        margin-right: 40px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field16 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field18 {
        width: 100%
    }

    #user-form-template-369 #field18 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height:;
        font-family: Montserrat;
        font-weight: normal;
        font-size: 20px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(0, 0, 0, 0);
        border-width: 1px;
        border-style: none;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        box-sizing: border-box;
    }

    #user-form-template-369 #field18 .component-container {
        margin-right: 40px;
        margin-left: 40px;
        margin-top: 15px;
        margin-bottom: 5px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field19 {
        width: 100%
    }

    #user-form-template-369 #field19 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: Montserrat;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: inherit;
        border-width: 1px;
        border-style: none;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        checkbox: undefined;
    }

    #user-form-template-369 #field19 .component-container {
        margin-right: 40px;
        margin-left: 40px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field19 input[type="checkbox"]:checked + .checkbox:before, input[type="checkbox"]:checked + .checkbox:after {
        background-color: #000000
    }

    #user-form-template-369 #field19 .checkbox {
        border-color: #C8C8C8
    }

    #user-form-template-369 #field19 .radio-check-label {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field20 {
        width: 100%
    }

    #user-form-template-369 #field20 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height:;
        font-family: Montserrat;
        font-weight: normal;
        font-size: px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(0, 0, 0, 0);
        border-width: 1px;
        border-style: none;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: center;
        box-sizing: border-box;
    }

    #user-form-template-369 #field20 .component-container {
        margin-right: 40px;
        margin-left: 40px;
        margin-top: 5px;
        margin-bottom: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field21 {
        width: 100%
    }

    #user-form-template-369 #field21 .element-container {
        display: none;
    }

    #user-form-template-369 #field21 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field22 {
        width: 100%
    }

    #user-form-template-369 #field22 .element-container {
        display: none;
    }

    #user-form-template-369 #field22 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field23 {
        width: 50%
    }

    #user-form-template-369 #field23 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        placeholderColor: rgba(200, 200, 200, 1);
        box-sizing: border-box;
    }

    #user-form-template-369 #field23 .component-container {
        margin-right: 40px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field23 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field24 {
        width: 100%
    }

    #user-form-template-369 #field24 .element-container {
        background-color: rgba(140, 140, 140, 1);
        width: 100%;
        height: 1px;
    }

    #user-form-template-369 #field24 .component-container {
        margin-right: 250px;
        margin-left: 250px;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field26 {
        width: 100%
    }

    #user-form-template-369 #field26 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: Montserrat;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: inherit;
        border-width: 1px;
        border-style: none;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        checkbox: undefined;
    }

    #user-form-template-369 #field26 .component-container {
        margin-right: 40px;
        margin-left: 40px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field26 input[type="checkbox"]:checked + .checkbox:before, input[type="checkbox"]:checked + .checkbox:after {
        background-color: #000000
    }

    #user-form-template-369 #field26 .checkbox {
        border-color: #C8C8C8
    }

    #user-form-template-369 #field26 .radio-check-label {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field27 {
        width: 100%
    }

    #user-form-template-369 #field27 .element-container {
        border: none;
        width: 100%;
        height: 10px;
    }

    #user-form-template-369 #field27 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field28 {
        width: 100%
    }

    #user-form-template-369 #field28 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 30px;
        padding-left: 30px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        box-sizing: border-box;
        placeholderColor: rgba(200, 200, 200, 1);
    }

    #user-form-template-369 #field28 .component-container {
        margin-right: 40px;
        margin-left: 40px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field28 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field29 {
        width: 100%
    }

    #user-form-template-369 #field29 .element-container {
        display: none;
    }

    #user-form-template-369 #field29 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field11 {
        width: 100%
    }

    #user-form-template-369 #field11 .element-container {
        display: none;
    }

    #user-form-template-369 #field11 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field12 {
        width: 100%
    }

    #user-form-template-369 #field12 .element-container {
        display: none;
    }

    #user-form-template-369 #field12 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field8 {
        width: 100%
    }

    #user-form-template-369 #field8 .element-container {
        display: none;
    }

    #user-form-template-369 #field8 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field10 {
        width: 100%
    }

    #user-form-template-369 #field10 .element-container {
        display: none;
    }

    #user-form-template-369 #field10 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field9 {
        width: 100%
    }

    #user-form-template-369 #field9 .element-container {
        display: none;
    }

    #user-form-template-369 #field9 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 {
        max-width: 900px;
        background-color: rgba(255, 255, 255, 1);
        margin: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        opacity: 1;
        border-radius: 3px 3px 3px 3px;
        box-shadow: #E4E4E4 0px 2px 14px 0px;
        border-width: 1px;
        border-style: none;
        border-color: #C8C8C8;
        overflow: hidden;
    }
}

@media (max-width: 959px) {
    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field0 {
        width: 100%
    }

    #user-form-template-369 #field0 .element-container {
        opacity: 1;
        margin-bottom: 0px;
        padding-top: 0px;
        border-color: #E9E7E7;
        font-weight: normal;
        margin-top: 0px;
        text-align: left;
        color: rgba(81, 80, 80, 1);
        font-family: Roboto;
        padding-left: 15px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 13px;
        border-width: 1px;
        placeholderColor: rgba(200, 200, 200, 1);
        border-radius: 0px 0px 0px 0px;
        font-style: normal;
        box-shadow: none;
        margin-right: 0px;
        padding-right: 0px;
        margin-left: 0px;
        width: 100%;
        padding-bottom: 0px;
        height: 35px;
        border-style: solid;
        box-sizing: border-box;
    }

    #user-form-template-369 #field0 .component-container {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
        margin-left: 5px;
    }

    #user-form-template-369 #field0 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field1 {
        width: 100%
    }

    #user-form-template-369 #field1 .element-container {
        opacity: 1;
        margin-bottom: 0px;
        padding-top: 0px;
        border-color: #E9E7E7;
        font-weight: normal;
        margin-top: 0px;
        text-align: left;
        color: rgba(81, 80, 80, 1);
        font-family: Roboto;
        padding-left: 15px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 13px;
        border-width: 1px;
        placeholderColor: rgba(200, 200, 200, 1);
        border-radius: 0px 0px 0px 0px;
        box-shadow: none;
        margin-right: 0px;
        padding-right: 0px;
        margin-left: 0px;
        width: 100%;
        padding-bottom: 0px;
        height: 35px;
        border-style: solid;
        box-sizing: border-box;
    }

    #user-form-template-369 #field1 .component-container {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
        margin-left: 5px;
    }

    #user-form-template-369 #field1 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field2 {
        width: 100%
    }

    #user-form-template-369 #field2 .element-container {
        opacity: 1;
        margin-bottom: 0px;
        padding-top: 0px;
        border-color: #E9E7E7;
        font-weight: normal;
        margin-top: 0px;
        text-align: left;
        color: rgba(81, 80, 80, 1);
        font-family: Roboto;
        padding-left: 15px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 13px;
        border-width: 1px;
        placeholderColor: rgba(200, 200, 200, 1);
        border-radius: 0px 0px 0px 0px;
        font-style: normal;
        box-shadow: none;
        margin-right: 0px;
        padding-right: 0px;
        margin-left: 0px;
        width: 100%;
        padding-bottom: 0px;
        height: 35px;
        border-style: solid;
        box-sizing: border-box;
    }

    #user-form-template-369 #field2 .component-container {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
        margin-left: 5px;
    }

    #user-form-template-369 #field2 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field4 {
        width: 100%
    }

    #user-form-template-369 #field4 .element-container {
        opacity: 1;
        margin-bottom: 0px;
        padding-top: 0px;
        border-color: #C8C8C8;
        font-weight: normal;
        margin-top: 0px;
        text-align: center;
        color: rgba(255, 255, 255, 1);
        font-family: Trebuchet MS;
        padding-left: 0px;
        background-color: rgba(188, 71, 0, 1);
        font-size: 16px;
        border-width: 1px;
        border-radius: 5px 5px 5px 5px;
        font-style: normal;
        box-shadow: none;
        margin-right: 0px;
        padding-right: 0px;
        width: 200px;
        padding-bottom: 0px;
        submit_box: undefined;
        height: 35px;
        border-style: none;
        margin-left: 0px;
    }

    #user-form-template-369 #field4 .component-container {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-right: 5px;
        margin-left: 5px;
    }

    #user-form-template-369 #field4 .submit-box-container {
        flex-direction: row;
        justify-content: center;
        display: flex;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field13 {
        width: 100%
    }

    #user-form-template-369 #field13 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        placeholderColor: rgba(200, 200, 200, 1);
        box-sizing: border-box;
    }

    #user-form-template-369 #field13 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field13 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field14 {
        width: 100%
    }

    #user-form-template-369 #field14 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 30px;
        padding-left: 30px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        box-sizing: border-box;
        placeholderColor: rgba(200, 200, 200, 1);
    }

    #user-form-template-369 #field14 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field14 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field15 {
        width: 100%
    }

    #user-form-template-369 #field15 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        box-sizing: border-box;
        placeholderColor: rgba(200, 200, 200, 1);
    }

    #user-form-template-369 #field15 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field15 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field16 {
        width: 100%
    }

    #user-form-template-369 #field16 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        placeholderColor: rgba(200, 200, 200, 1);
        box-sizing: border-box;
    }

    #user-form-template-369 #field16 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field16 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field18 {
        width: 100%
    }

    #user-form-template-369 #field18 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height:;
        font-family: Montserrat;
        font-weight: normal;
        font-size: 20px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(0, 0, 0, 0);
        border-width: 1px;
        border-style: none;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        box-sizing: border-box;
    }

    #user-form-template-369 #field18 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field19 {
        width: 100%
    }

    #user-form-template-369 #field19 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: Montserrat;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: inherit;
        border-width: 1px;
        border-style: none;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        checkbox: undefined;
    }

    #user-form-template-369 #field19 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field19 input[type="checkbox"]:checked + .checkbox:before, input[type="checkbox"]:checked + .checkbox:after {
        background-color: #000000
    }

    #user-form-template-369 #field19 .checkbox {
        border-color: #C8C8C8
    }

    #user-form-template-369 #field19 .radio-check-label {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field20 {
        width: 100%
    }

    #user-form-template-369 #field20 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height:;
        font-family: Montserrat;
        font-weight: normal;
        font-size: px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(0, 0, 0, 0);
        border-width: 1px;
        border-style: none;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: center;
        box-sizing: border-box;
    }

    #user-form-template-369 #field20 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field21 {
        width: 100%
    }

    #user-form-template-369 #field21 .element-container {
        display: none;
    }

    #user-form-template-369 #field21 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field22 {
        width: 100%
    }

    #user-form-template-369 #field22 .element-container {
        display: none;
    }

    #user-form-template-369 #field22 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field23 {
        width: 100%
    }

    #user-form-template-369 #field23 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        placeholderColor: rgba(200, 200, 200, 1);
        box-sizing: border-box;
    }

    #user-form-template-369 #field23 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field23 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field24 {
        width: 100%
    }

    #user-form-template-369 #field24 .element-container {
        background-color: rgba(140, 140, 140, 1);
        width: 100%;
        height: 1px;
    }

    #user-form-template-369 #field24 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field26 {
        width: 100%
    }

    #user-form-template-369 #field26 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: Montserrat;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: inherit;
        border-width: 1px;
        border-style: none;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        checkbox: undefined;
    }

    #user-form-template-369 #field26 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field26 input[type="checkbox"]:checked + .checkbox:before, input[type="checkbox"]:checked + .checkbox:after {
        background-color: #000000
    }

    #user-form-template-369 #field26 .checkbox {
        border-color: #C8C8C8
    }

    #user-form-template-369 #field26 .radio-check-label {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field27 {
        width: 100%
    }

    #user-form-template-369 #field27 .element-container {
        border: none;
        width: 100%;
        height: 10px;
    }

    #user-form-template-369 #field27 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field28 {
        width: 100%
    }

    #user-form-template-369 #field28 .element-container {
        width: 100%;
        opacity: 1;
        box-shadow: none;
        padding-right: 30px;
        padding-left: 30px;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 35px;
        font-family: Roboto;
        font-weight: normal;
        font-size: 13px;
        font-style: normal;
        color: rgba(0, 0, 0, 1);
        background-color: rgba(255, 255, 255, 1);
        border-width: 1px;
        border-style: solid;
        border-color: #C8C8C8;
        border-radius: 0px 0px 0px 0px;
        text-align: left;
        box-sizing: border-box;
        placeholderColor: rgba(200, 200, 200, 1);
    }

    #user-form-template-369 #field28 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 #field28 input::-webkit-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    ::-moz-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-ms-input-placeholder {
        color: rgba(200, 200, 200, 1);
    }

    :-moz-placeholder {
        color: rgba(200, 200, 200, 1)
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field29 {
        width: 100%
    }

    #user-form-template-369 #field29 .element-container {
        display: none;
    }

    #user-form-template-369 #field29 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field11 {
        width: 100%
    }

    #user-form-template-369 #field11 .element-container {
        display: none;
    }

    #user-form-template-369 #field11 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field12 {
        width: 100%
    }

    #user-form-template-369 #field12 .element-container {
        display: none;
    }

    #user-form-template-369 #field12 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field8 {
        width: 100%
    }

    #user-form-template-369 #field8 .element-container {
        display: none;
    }

    #user-form-template-369 #field8 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field10 {
        width: 100%
    }

    #user-form-template-369 #field10 .element-container {
        display: none;
    }

    #user-form-template-369 #field10 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .field-label {
        color: rgba(81, 80, 80, 1);
        margin-bottom: 5px;
        margin-top: 13px;
        margin-left: 0px;
        margin-right: 0px;
        font-size: 14px;
        font-family: Montserrat;
        font-weight: normal;
        font-style: normal;
        opacity: 1;
        text-align: left;
    }

    .required-field {
        margin-top: 13px !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        box-sizing: border-box;
        align-items: flex-end;
        justify-content: flex-end;
        flex-direction: row;
        display: flex;
    }

    #user-form-template-369 #field9 {
        width: 100%
    }

    #user-form-template-369 #field9 .element-container {
        display: none;
    }

    #user-form-template-369 #field9 .component-container {
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #user-form-template-369 {
        max-width: 900px;
        background-color: rgba(255, 255, 255, 1);
        margin: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        box-sizing: border-box;
        display: flex;
        border-radius: 3px 3px 3px 3px;
        box-shadow: #E4E4E4 0px 2px 14px 0px;
        border-width: 1px;
        border-style: none;
        border-color: #C8C8C8;
        overflow: hidden;
        opacity: 1;
    }

    .g-recaptcha {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    }
}</style>

<!-- CSS ends here -->

<!-- Form HTML begins here-->

<meta name='viewport' content='width=device-width, initial-scale=1.0'>
<form class='user-form-template' id='user-form-template-369' action='https://emails.rystadenergy.com/api/forms/submit'
      method='POST'>
  <div class='user-form-body'>
    <input type='hidden' name='customer' value='ch000097637eArad'>
    <input type='hidden' name='rurl' value='https://www.rystadenergy.com/landing-pages/newsletter-thank-you/'>
    <input type='hidden' name='dialogid' value='1927'>
    <input type='hidden' name='cke' value='1'>
    <input type='hidden' name='leadsource' value='Newsletter' field-map-id='21' class='element-container'><input
    type='hidden' name='status' value='Assigned' field-map-id='22' class='element-container'><input type='hidden'
                                                                                                    name='campaign_c'
                                                                                                    value='Monthly newsletter'
                                                                                                    field-map-id='29'
                                                                                                    class='element-container'><input
    type='hidden' name='utm_newslettersignup' data-url-param='utm_newslettersignup' value=''
    class='element-container'><input type='hidden' name='utm_term' data-url-param='utm_term' value=''
                                     class='element-container'><input type='hidden' name='utm_source'
                                                                      data-url-param='utm_source' value=''
                                                                      class='element-container'><input type='hidden'
                                                                                                       name='utm_campaign'
                                                                                                       data-url-param='utm_campaign'
                                                                                                       value=''
                                                                                                       class='element-container'><input
    type='hidden' name='utm_medium' data-url-param='utm_medium' value='' class='element-container'>
    <div class='form-row'>
      <div id='field0' field_map_id='0'>
        <div class='component-container'>
          <div class='layout-row space-between'>
            <div class='field-label'>First Name</div>
            <div class='required-field'
                 style='color:rgba(0, 0, 0, 1);font-size:11px;font-family:Roboto;font-weight:normal;font-style:normal;opacity:1;text-align:right;type:text;value:required;'>
              required
            </div>
          </div>
          <input type='text' name='firstname' value='' class='element-container' maxlength='' required='required'></div>
      </div>
      <div id='field1' field_map_id='1'>
        <div class='component-container'>
          <div class='layout-row space-between'>
            <div class='field-label'>Last Name</div>
            <div class='required-field'
                 style='color:rgba(0, 0, 0, 1);font-size:11px;font-family:Roboto;font-weight:normal;font-style:normal;opacity:1;text-align:right;type:text;value:required;'>
              required
            </div>
          </div>
          <input type='text' name='lastname' value='' class='element-container' maxlength='' required='required'></div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field2' field_map_id='2'>
        <div class='component-container'>
          <div class='layout-row space-between'>
            <div class='field-label'>Business Email</div>
            <div class='required-field'
                 style='color:rgba(0, 0, 0, 1);font-size:11px;font-family:Roboto;font-weight:normal;font-style:normal;opacity:1;text-align:right;type:text;value:required;'>
              required
            </div>
          </div>
          <input name='email' value='' class='element-container' type='email'
                 pattern='[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$' required='required'></div>
      </div>
      <div id='field23' field_map_id='23'>
        <div class='component-container'>
          <div class='layout-row flex-start'>
            <div class='field-label'>Phone</div>
          </div>
          <div class='layout-row'><input class='element-container' name='phone_work' value='' placeholder='' /></div>
        </div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field28' field_map_id='28'>
        <div class='component-container'>
          <div class='layout-row space-between'>
            <div class='field-label'>Job Title</div>
            <div class='required-field'
                 style='color:rgba(0, 0, 0, 1);font-size:11px;font-family:Roboto;font-weight:normal;font-style:normal;opacity:1;text-align:right;type:text;value:required;'>
              required
            </div>
          </div>
          <input type='text' name='title' value='' class='element-container' maxlength='' required='required'></div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field14' field_map_id='14'>
        <div class='component-container'>
          <div class='layout-row space-between'>
            <div class='field-label'>Company</div>
            <div class='required-field'
                 style='color:rgba(0, 0, 0, 1);font-size:11px;font-family:Roboto;font-weight:normal;font-style:normal;opacity:1;text-align:right;type:text;value:required;'>
              required
            </div>
          </div>
          <input type='text' name='account_name' value='' class='element-container' maxlength='' required='required'>
        </div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field13' field_map_id='13'>
        <div class='component-container'>
          <div class='layout-row space-between'>
            <div class='field-label'>Industry</div>
            <div class='required-field'
                 style='color:rgba(0, 0, 0, 1);font-size:11px;font-family:Roboto;font-weight:normal;font-style:normal;opacity:1;text-align:right;type:text;value:required;'>
              required
            </div>
          </div>
          <select name='sugar_market_industry_c' class='element-container' required='required'>
            <option value='' selected>Please select</option>
            <option value='Bank - Investment Bank'>Bank - Investment Bank</option>
            <option value='Bank - Equity Research'>Bank - Equity Research</option>
            <option value='Bank - Restructuring'>Bank - Restructuring</option>
            <option value='Bank - Sustainability'>Bank - Sustainability</option>
            <option value='Energy Company - E&amp;P'>Energy Company - E&amp;P</option>
            <option value='Energy Company - Integrated'>Energy Company - Integrated</option>
            <option value='Energy Company - Utility'>Energy Company - Utility</option>
            <option value='Energy Company - Renewable'>Energy Company - Renewable</option>
            <option value='Investor - Private Equity'>Investor - Private Equity</option>
            <option value='Investor - Asset Management'>Investor - Asset Management</option>
            <option value='Investor - Institutional'>Investor - Institutional</option>
            <option value='Investor - Hedge Funds'>Investor - Hedge Funds</option>
            <option value='Trading'>Trading</option>
            <option value='Consulting'>Consulting</option>
            <option value='Government'>Government</option>
            <option value='NGO'>NGO</option>
            <option value='Research Institute'>Research Institute</option>
            <option value='Law Firm'>Law Firm</option>
            <option value='Media'>Media</option>
            <option value='Industrial'>Industrial</option>
            <option value='Transportation'>Transportation</option>
            <option value='Supply Chain - Oil &amp; Gas'>Supply Chain - Oil &amp; Gas</option>
            <option value='Supply Chain - Integrated'>Supply Chain - Integrated</option>
            <option value='Supply Chain - Renewables'>Supply Chain - Renewables</option>
            <option value='Supply Chain - Infrastructure'>Supply Chain - Infrastructure</option>
            <option value='Other'>Other</option>
            <option value='Technology'>Technology</option>
          </select></div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field15' field_map_id='15'>
        <div class='component-container'>
          <div class='layout-row space-between'>
            <div class='field-label'>City</div>
            <div class='required-field'
                 style='color:rgba(0, 0, 0, 1);font-size:11px;font-family:Roboto;font-weight:normal;font-style:normal;opacity:1;text-align:right;type:text;value:required;'>
              required
            </div>
          </div>
          <input type='text' name='primary_address_city' value='' class='element-container' maxlength=''
                 required='required'></div>
      </div>
      <div id='field16' field_map_id='16'>
        <div class='component-container'>
          <div class='layout-row space-between'>
            <div class='field-label'>Country</div>
            <div class='required-field'
                 style='color:rgba(0, 0, 0, 1);font-size:11px;font-family:Roboto;font-weight:normal;font-style:normal;opacity:1;text-align:right;type:text;value:required;'>
              required
            </div>
          </div>
          <select name='primary_address_country' class='element-container' required='required'>
            <option value='' selected>Please select</option>
            <option value='Afghanistan'>Afghanistan</option>
            <option value='Albania'>Albania</option>
            <option value='Algeria'>Algeria</option>
            <option value='American Samoa'>American Samoa</option>
            <option value='Andorra'>Andorra</option>
            <option value='Angola'>Angola</option>
            <option value='Anguilla'>Anguilla</option>
            <option value='Antarctica'>Antarctica</option>
            <option value='Antigua and Barbuda'>Antigua and Barbuda</option>
            <option value='Argentina'>Argentina</option>
            <option value='Armenia'>Armenia</option>
            <option value='Aruba'>Aruba</option>
            <option value='Australia'>Australia</option>
            <option value='Austria'>Austria</option>
            <option value='Azerbaijan'>Azerbaijan</option>
            <option value='Bahamas'>Bahamas</option>
            <option value='Bahrain'>Bahrain</option>
            <option value='Bangladesh'>Bangladesh</option>
            <option value='Barbados'>Barbados</option>
            <option value='Belarus'>Belarus</option>
            <option value='Belgium'>Belgium</option>
            <option value='Belize'>Belize</option>
            <option value='Benin'>Benin</option>
            <option value='Bermuda'>Bermuda</option>
            <option value='Bhutan'>Bhutan</option>
            <option value='Bolivia'>Bolivia</option>
            <option value='Bosnia and Herzegovina'>Bosnia and Herzegovina</option>
            <option value='Botswana'>Botswana</option>
            <option value='Bouvet Island'>Bouvet Island</option>
            <option value='Brazil'>Brazil</option>
            <option value='British Indian Ocean Territory'>British Indian Ocean Territory</option>
            <option value='Brunei'>Brunei</option>
            <option value='Bulgaria'>Bulgaria</option>
            <option value='Burkina Faso'>Burkina Faso</option>
            <option value='Burundi'>Burundi</option>
            <option value='Cambodia'>Cambodia</option>
            <option value='Cameroon'>Cameroon</option>
            <option value='Canada'>Canada</option>
            <option value='Cape verde'>Cape Verde</option>
            <option value='Cayman Islands'>Cayman Islands</option>
            <option value='Central African Republic'>Central African Republic</option>
            <option value='Chad'>Chad</option>
            <option value='Chile'>Chile</option>
            <option value='China'>China</option>
            <option value='Christmas Island'>Christmas Island</option>
            <option value='Cocos Islands'>Cocos Islands</option>
            <option value='Colombia'>Colombia</option>
            <option value='Comoros'>Comoros</option>
            <option value='Congo'>Congo</option>
            <option value='Cook Islands'>Cook Islands</option>
            <option value='Costa Rica'>Costa Rica</option>
            <option value='Cote d Ivoire'>Cote d'Ivoire</option>
            <option value='Croatia'>Croatia</option>
            <option value='Cuba'>Cuba</option>
            <option value='Cyprus'>Cyprus</option>
            <option value='Czech Republic'>Czech Republic</option>
            <option value='Democratic Republic of Congo'>Democratic Republic of Congo</option>
            <option value='Denmark'>Denmark</option>
            <option value='Djibouti'>Djibouti</option>
            <option value='Dominica'>Dominica</option>
            <option value='Dominican Republic'>Dominican Republic</option>
            <option value='East Timor'>East Timor</option>
            <option value='Easter Island (CL)'>Easter Island (CL)</option>
            <option value='Ecuador'>Ecuador</option>
            <option value='Egypt'>Egypt</option>
            <option value='El Salvador'>El Salvador</option>
            <option value='Equatorial Guinea'>Equatorial Guinea</option>
            <option value='Eritrea'>Eritrea</option>
            <option value='Estonia'>Estonia</option>
            <option value='Ethiopia'>Ethiopia</option>
            <option value='Falkland Islands'>Falkland Islands</option>
            <option value='Faroe Islands'>Faroe Islands</option>
            <option value='Fiji'>Fiji</option>
            <option value='Finland'>Finland</option>
            <option value='France'>France</option>
            <option value='French Guiana'>French Guiana</option>
            <option value='French Polynesia'>French Polynesia</option>
            <option value='French Southern Territories'>French Southern Territories</option>
            <option value='Gabon'>Gabon</option>
            <option value='Gambia'>Gambia</option>
            <option value='Georgia'>Georgia</option>
            <option value='Germany'>Germany</option>
            <option value='Ghana'>Ghana</option>
            <option value='Gibraltar'>Gibraltar</option>
            <option value='Greece'>Greece</option>
            <option value='Greenland'>Greenland</option>
            <option value='Grenada'>Grenada</option>
            <option value='Guadeloupe'>Guadeloupe</option>
            <option value='Guam (US)'>Guam (US)</option>
            <option value='Guatemala'>Guatemala</option>
            <option value='Guernsey (GB)'>Guernsey (GB)</option>
            <option value='Guinea'>Guinea</option>
            <option value='Guinea-Bissau'>Guinea-Bissau</option>
            <option value='Guyana'>Guyana</option>
            <option value='Haiti'>Haiti</option>
            <option value='Heard Island and Mc Donald Islands'>Heard Island and Mc Donald Islands</option>
            <option value='Holy See (Vatican City State)'>Holy See (Vatican City State)</option>
            <option value='Honduras'>Honduras</option>
            <option value='Hong Kong'>Hong Kong</option>
            <option value='Hungary'>Hungary</option>
            <option value='Iceland'>Iceland</option>
            <option value='India'>India</option>
            <option value='Indonesia'>Indonesia</option>
            <option value='Iran'>Iran</option>
            <option value='Iraq'>Iraq</option>
            <option value='Ireland'>Ireland</option>
            <option value='Isle of Man'>Isle of Man</option>
            <option value='Israel'>Israel</option>
            <option value='Italy'>Italy</option>
            <option value='Jamaica'>Jamaica</option>
            <option value='Japan'>Japan</option>
            <option value='Jersey (GB)'>Jersey (GB)</option>
            <option value='Jordan'>Jordan</option>
            <option value='Kazakhstan'>Kazakhstan</option>
            <option value='Kenya'>Kenya</option>
            <option value='Kiribati'>Kiribati</option>
            <option value='Kuwait'>Kuwait</option>
            <option value='Kyrgyzstan'>Kyrgyzstan</option>
            <option value='Laos'>Laos</option>
            <option value='Latvia'>Latvia</option>
            <option value='Lebanon'>Lebanon</option>
            <option value='Lesotho'>Lesotho</option>
            <option value='Liberia'>Liberia</option>
            <option value='Libya'>Libya</option>
            <option value='Liechtenstein'>Liechtenstein</option>
            <option value='Lithuania'>Lithuania</option>
            <option value='Luxembourg'>Luxembourg</option>
            <option value='Macau'>Macau</option>
            <option value='Macedonia'>Macedonia</option>
            <option value='Madagascar'>Madagascar</option>
            <option value='Malawi'>Malawi</option>
            <option value='Malaysia'>Malaysia</option>
            <option value='Malaysia/Thailand JDA'>Malaysia/Thailand JDA</option>
            <option value='Maldives'>Maldives</option>
            <option value='Mali'>Mali</option>
            <option value='Malta'>Malta</option>
            <option value='Marshall Islands'>Marshall Islands</option>
            <option value='Martinique'>Martinique</option>
            <option value='Mauritania'>Mauritania</option>
            <option value='Mauritius'>Mauritius</option>
            <option value='Mayotte'>Mayotte</option>
            <option value='Mexico'>Mexico</option>
            <option value='Micronesia'>Micronesia</option>
            <option value='Moldova'>Moldova</option>
            <option value='Monaco'>Monaco</option>
            <option value='Mongolia'>Mongolia</option>
            <option value='Montenegro'>Montenegro</option>
            <option value='Montserrat'>Montserrat</option>
            <option value='Morocco'>Morocco</option>
            <option value='Mozambique'>Mozambique</option>
            <option value='Myanmar'>Myanmar</option>
            <option value='Namibia'>Namibia</option>
            <option value='Nauru'>Nauru</option>
            <option value='Nepal'>Nepal</option>
            <option value='Netherlands'>Netherlands</option>
            <option value='Netherlands Antilles'>Netherlands Antilles</option>
            <option value='New Caledonia'>New Caledonia</option>
            <option value='New Zealand'>New Zealand</option>
            <option value='Nicaragua'>Nicaragua</option>
            <option value='Niger'>Niger</option>
            <option value='Nigeria'>Nigeria</option>
            <option value='Nigeria/Sao Tome JDZ'>Nigeria/Sao Tome JDZ</option>
            <option value='Niue'>Niue</option>
            <option value='Norfolk Island'>Norfolk Island</option>
            <option value='North Korea'>North Korea</option>
            <option value='Northern Mariana Islands'>Northern Mariana Islands</option>
            <option value='Norway'>Norway</option>
            <option value='Oman'>Oman</option>
            <option value='Pakistan'>Pakistan</option>
            <option value='Palau'>Palau</option>
            <option value='Palestine'>Palestine</option>
            <option value='Panama'>Panama</option>
            <option value='Papua New Guinea'>Papua New Guinea</option>
            <option value='Paracel Islands'>Paracel Islands</option>
            <option value='Paraguay'>Paraguay</option>
            <option value='Peru'>Peru</option>
            <option value='Philippines'>Philippines</option>
            <option value='Pitcairn'>Pitcairn</option>
            <option value='Poland'>Poland</option>
            <option value='Portugal'>Portugal</option>
            <option value='Prince Edward Islands (ZA)'>Prince Edward Islands (ZA)</option>
            <option value='Puerto Rico'>Puerto Rico</option>
            <option value='Qatar'>Qatar</option>
            <option value='Reunion'>Reunion</option>
            <option value='Romania'>Romania</option>
            <option value='Russia'>Russia</option>
            <option value='Rwanda'>Rwanda</option>
            <option value='Saint Kitts and Nevis'>Saint Kitts and Nevis</option>
            <option value='Saint Lucia'>Saint Lucia</option>
            <option value='Saint Martin (NL)'>Saint Martin (NL)</option>
            <option value='Saint Vincent and the Grenadines'>Saint Vincent and the Grenadines</option>
            <option value='Samoa'>Samoa</option>
            <option value='San Marino'>San Marino</option>
            <option value='Sao Tome and Principe'>Sao Tome and Principe</option>
            <option value='Saudi Arabia'>Saudi Arabia</option>
            <option value='Senegal'>Senegal</option>
            <option value='Serbia'>Serbia</option>
            <option value='Seychelles'>Seychelles</option>
            <option value='Sierra Leone'>Sierra Leone</option>
            <option value='Singapore'>Singapore</option>
            <option value='Slovakia'>Slovakia</option>
            <option value='Slovenia'>Slovenia</option>
            <option value='Solomon Islands'>Solomon Islands</option>
            <option value='Somalia'>Somalia</option>
            <option value='Somaliland'>Somaliland</option>
            <option value='South Africa'>South Africa</option>
            <option value='South Georgia and the South Sandwich Islands'>South Georgia and the South Sandwich Islands
            </option>
            <option value='South Korea'>South Korea</option>
            <option value='South Sudan'>South Sudan</option>
            <option value='Spain'>Spain</option>
            <option value='Sri Lanka'>Sri Lanka</option>
            <option value='St Pierre &amp; Miquelon'>St Pierre &amp; Miquelon</option>
            <option value='Sudan'>Sudan</option>
            <option value='Suriname'>Suriname</option>
            <option value='Svalbard'>Svalbard</option>
            <option value='Swaziland'>Swaziland</option>
            <option value='Sweden'>Sweden</option>
            <option value='Switzerland'>Switzerland</option>
            <option value='Syria'>Syria</option>
            <option value='Taiwan'>Taiwan</option>
            <option value='Tajikistan'>Tajikistan</option>
            <option value='Tanzania'>Tanzania</option>
            <option value='Thailand'>Thailand</option>
            <option value='Timor Sea JPDA'>Timor Sea JPDA</option>
            <option value='Togo'>Togo</option>
            <option value='Tokelau'>Tokelau</option>
            <option value='Tonga'>Tonga</option>
            <option value='Trinidad and Tobago'>Trinidad and Tobago</option>
            <option value='Tunisia'>Tunisia</option>
            <option value='Turkey'>Turkey</option>
            <option value='Turkmenistan'>Turkmenistan</option>
            <option value='Turks and Caicos Islands'>Turks and Caicos Islands</option>
            <option value='Tuvalu'>Tuvalu</option>
            <option value='UAE'>UAE</option>
            <option value='Uganda'>Uganda</option>
            <option value='Ukraine'>Ukraine</option>
            <option value='United Kingdom'>United Kingdom</option>
            <option value='United States'>United States</option>
            <option value='United States Minor Outlying Islands'>United States Minor Outlying Islands</option>
            <option value='Uruguay'>Uruguay</option>
            <option value='Uzbekistan'>Uzbekistan</option>
            <option value='Vanuatu'>Vanuatu</option>
            <option value='Venezuela'>Venezuela</option>
            <option value='Vietnam'>Vietnam</option>
            <option value='Virgin Islands (UK)'>Virgin Islands (UK)</option>
            <option value='Virgin Islands (US)'>Virgin Islands (US)</option>
            <option value='Wallis and Futuna'>Wallis and Futuna</option>
            <option value='Western Sahara'>Western Sahara</option>
            <option value='Yemen'>Yemen</option>
            <option value='Yugoslavia'>Yugoslavia</option>
            <option value='Zambia'>Zambia</option>
            <option value='Zimbabwe'>Zimbabwe</option>
          </select></div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field27' field_map_id='27'>
        <div class='component-container'>
          <div class='element-container'></div>
        </div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field26' field_map_id='26'>
        <div class='component-container'>
          <div class='layout-row flex-start'>
            <div class='field-label'>Newsletters</div>
          </div>
          <div class='element-container layout-column layout-wrap'>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c0'
                                              name='sugarmarket_subscriptions_c' value='^Rystad Energy Insights^'><label
                for='sugarmarket_subscriptions_c0' class='checkbox'></label></div>
              <label class='radio-check-label'>Rystad Energy Insights</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c1'
                                              name='sugarmarket_subscriptions_c' value='^E&amp;P Newsletter^'><label
                for='sugarmarket_subscriptions_c1' class='checkbox'></label></div>
              <label class='radio-check-label'>E&amp;P Insights</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c2'
                                              name='sugarmarket_subscriptions_c' value='^Shale Newsletter^'><label
                for='sugarmarket_subscriptions_c2' class='checkbox'></label></div>
              <label class='radio-check-label'>Shale Insights</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c3'
                                              name='sugarmarket_subscriptions_c'
                                              value='^Gas &amp; LNG Markets Newsletter^'><label
                for='sugarmarket_subscriptions_c3' class='checkbox'></label></div>
              <label class='radio-check-label'>Gas &amp; LNG Markets Insights</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c4'
                                              name='sugarmarket_subscriptions_c'
                                              value='^Supply Chain Newsletter^'><label
                for='sugarmarket_subscriptions_c4' class='checkbox'></label></div>
              <label class='radio-check-label'>Supply Chain Insights</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c5'
                                              name='sugarmarket_subscriptions_c'
                                              value='^Renewable Energy Newsletter^'><label
                for='sugarmarket_subscriptions_c5' class='checkbox'></label></div>
              <label class='radio-check-label'>Renewable Energy Insights</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c6'
                                              name='sugarmarket_subscriptions_c' value='^Investor Newsletter^'><label
                for='sugarmarket_subscriptions_c6' class='checkbox'></label></div>
              <label class='radio-check-label'>Institutional Investor Insights</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c7'
                                              name='sugarmarket_subscriptions_c' value='^Americas Regional Newsletter^'><label
                for='sugarmarket_subscriptions_c7' class='checkbox'></label></div>
              <label class='radio-check-label'>Americas Regional Newsletter</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c8'
                                              name='sugarmarket_subscriptions_c'
                                              value='^Latin America Regional Newsletter^'><label
                for='sugarmarket_subscriptions_c8' class='checkbox'></label></div>
              <label class='radio-check-label'>Latin America Regional Newsletter</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c9'
                                              name='sugarmarket_subscriptions_c'
                                              value='^APAC Regional Newsletter^'><label
                for='sugarmarket_subscriptions_c9' class='checkbox'></label></div>
              <label class='radio-check-label'>APAC Regional Newsletter</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c10'
                                              name='sugarmarket_subscriptions_c'
                                              value='^Australasia Upstream Solutions^'><label
                for='sugarmarket_subscriptions_c10' class='checkbox'></label></div>
              <label class='radio-check-label'>Australasia Upstream Solutions</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c11'
                                              name='sugarmarket_subscriptions_c' value='^Japan Monthly Insights^'><label
                for='sugarmarket_subscriptions_c11' class='checkbox'></label></div>
              <label class='radio-check-label'>Japan Monthly Insights (in Japanese)</label></div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c12'
                                              name='sugarmarket_subscriptions_c'
                                              value='^MENA Regional Newsletter^'><label
                for='sugarmarket_subscriptions_c12' class='checkbox'></label></div>
              <label class='radio-check-label'>Middle East and North Africa (MENA) Regional Quarterly Newsletter</label>
            </div>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='sugarmarket_subscriptions_c13'
                                              name='sugarmarket_subscriptions_c'
                                              value='^Africa Regional Newsletter^'><label
                for='sugarmarket_subscriptions_c13' class='checkbox'></label></div>
              <label class='radio-check-label'>Africa Regional Quarterly Newsletter</label></div>
          </div>
        </div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field24' field_map_id='24'>
        <div class='component-container'>
          <div class='element-container'></div>
        </div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field18' field_map_id='18'>
        <div class='component-container'>
          <div class='element-container'><span
            style='font-family: verdana, geneva, tahoma, sans-serif; font-size: 13.3333px;'>By submitting this form you agree to our&nbsp;</span><a
            href='https://www.rystadenergy.com/newsevents/news/press-releases/hidden-prs/corporate-privacy-policy/'
            target='_blank' rel='noopener noreferrer'
            style='font-family: verdana, geneva, tahoma, sans-serif; font-size: 13.3333px;'>Privacy Policy</a><span
            style='font-family: verdana, geneva, tahoma, sans-serif; font-size: 13.3333px;'>&nbsp;and allow us to keep you updated on Rystad Energy products, events and webinars.</span>
          </div>
        </div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field19' field_map_id='19'>
        <div class='component-container'>
          <div class='layout-row flex-end'>
            <div class='field-label'></div>
            <div class='required-field'
                 style='color:rgba(0, 0, 0, 1);font-size:11px;font-family:Roboto;font-weight:normal;font-style:normal;opacity:1;text-align:right;type:text;value:required;'>
              required
            </div>
          </div>
          <div class='element-container layout-row layout-wrap'>
            <div class='label-left-right'>
              <div class='radio-check'><input type='checkbox' id='gdpr_allowed_c0' name='gdpr_allowed_c'
                                              value='Agreed to policy' required='required'><label for='gdpr_allowed_c0'
                                                                                                  class='checkbox'></label>
              </div>
              <label class='radio-check-label'>I agree to Privacy Policy (*required)</label></div>
          </div>
        </div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field4' field_map_id='4'>
        <div class='component-container'>
          <div class='submit-box-container'><input class='element-container' type='submit' value='Submit' /></div>
        </div>
      </div>
    </div>
    <div class='form-row'>
      <div id='field20' field_map_id='20'>
        <div class='component-container'>
          <div class='element-container'><br></div>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Form HTML ends here -->

<!-- Script begins here -->

<script>
  checkFields = function() {
    var inputs = document.getElementById('user-form-template-369').getElementsByTagName('input')
    var textareas = document.getElementById('user-form-template-369').getElementsByTagName('textarea')
    var selects = document.getElementById('user-form-template-369').getElementsByTagName('select')
    for (var i = 0; i < inputs.length; i++) {
      if (!inputs[i].checkValidity() && !inputs[i].classList.contains('invalid')) {
        inputs[i].classList.add('invalid')
        if (inputs[i].type === 'radio' || inputs[i].type === 'checkbox') {
          if (inputs[i].nextSibling.localName === 'label' && !inputs[i].nextSibling.classList.contains('invalid')) {
            inputs[i].nextSibling.classList.add('invalid')
          }
        }
      } else if (inputs[i].checkValidity() && inputs[i].classList.contains('invalid')) {
        inputs[i].classList.remove('invalid')
        if (inputs[i].type === 'radio' || inputs[i].type === 'checkbox') {
          if (inputs[i].nextSibling.localName === 'label') {
            inputs[i].nextSibling.classList.remove('invalid')
          }
        }
      }
    }
    for (var j = 0; j < textareas.length; j++) {
      if (!textareas[j].checkValidity() && !textareas[j].classList.contains('invalid')) {
        textareas[j].classList.add('invalid')
      } else if (textareas[j].checkValidity() && textareas[j].classList.contains('invalid')) {
        textareas[j].classList.remove('invalid')
      }
    }
    for (var k = 0; k < selects.length; k++) {
      if (!selects[k].checkValidity() && !selects[k].classList.contains('invalid')) {
        selects[k].classList.add('invalid')
      } else if (selects[k].checkValidity() && !selects[k].classList.contains('invalid')) {
        selects[k].classList.remove('invalid')
      }
    }
  }
  var userFormTemplate = document.getElementById('user-form-template-369')
  userFormTemplate.addEventListener('submit', function(event) {
    if (!event.target.checkValidity()) {
      checkFields()
    }
    if (!event.target.checkValidity()) {
      event.preventDefault()
    }
  }, false)
  getRequiredCheckboxes = function() {
    for (var a = document.getElementById('user-form-template-369').getElementsByTagName('input'), b = [], c = 0; c < a.length; c++) 'checkbox' === a[c].type && !0 === a[c].required && b.push(a[c])
    return b
  }, setAllCheckboxesRequiredOrNot = function(a, b) {
    for (var c = document.getElementById('user-form-template-369').getElementsByTagName('input'), d = 0; d < c.length; d++) 'checkbox' === c[d].type && c[d].name === b && (a ? c[d].removeAttribute('required') : c[d].setAttribute('required', 'required'))
  }, checkIfCheckboxesAreChecked = function(a) {
    for (var b = [], c = document.getElementsByTagName('input'), d = 0; d < c.length; d++) 'checkbox' === c[d].type && c[d].name === a && b.push(c[d])
    return b.filter(function(a) {
      return !0 === a.checked
    }).length > 0
  }, checkRequiredCheckboxes = function(a) {
    if (a.length > 0) for (var b = 0; b < a.length; b++) a[b].addEventListener('change', function(a) {
      var b = a.target
      checkIfCheckboxesAreChecked(b.name) ? setAllCheckboxesRequiredOrNot(!0, b.name) : setAllCheckboxesRequiredOrNot(!1, b.name)
    })
  }
  var requiredCheckboxes = getRequiredCheckboxes()
  checkRequiredCheckboxes(requiredCheckboxes)</script>

<!-- Script ends here -->`

export default monthlyNewsLetter
