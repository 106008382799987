import Section from '../../components/section'
import { RichTextWidgetWithoutCtaRecord } from '../../generated/graphql'
import useMediaQuery from '../../hooks/use-media-query'

type Props = {
  block: RichTextWidgetWithoutCtaRecord
}

function RichTextWidgetWithoutCta({ block }: Props) {
  const isSmallScreen = useMediaQuery('(max-width: 768px)')
  return (
    <Section
      classOverride={`${
        !block.appearsOnMobile && isSmallScreen ? 'hidden' : 'grid'
      } py-9`}
      backgroundColor={block.backgroundColorHex}
      id={block?.slug || ''}
    >
      <div className="col-span-full col-start-1 flex  flex-col gap-4 md:col-span-6 md:col-start-2 md:px-3">
        <span
          id={block.slug || ''}
          className="text-text-small md:text-text-base"
          style={{ scrollMarginTop: 120 }}
        >
          {block.label}
        </span>
        <p className="text-header-2 font-medium md:text-header-1">
          {block.text}
        </p>
      </div>
    </Section>
  )
}

export default RichTextWidgetWithoutCta
