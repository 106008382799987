import { CtaLinkRecord } from '../../generated/graphql'
import ArrowRightButton from '../../components/arrow-right-button'

type Props = {
  description?: string | null
  cta: CtaLinkRecord
}

function EnergyTypeContent({ description, cta }: Props) {
  return (
    <div className="pl-[calc(3ch+8px)]">
      <div className={'flex flex-col gap-2 bg-white'}>
        {description}
        {cta && (
          <ArrowRightButton link={cta?.link ?? ''}>
            {cta?.ctaLabel}
          </ArrowRightButton>
        )}
      </div>
      <div className={'h-2'} />
    </div>
  )
}

export default EnergyTypeContent
