import { FrontPageServiceScrollAnimationRecord } from '../../generated/graphql'
import DesktopServicesScrollAnimation from './desktop-services-scroll-animation'
import MobileServicesScrollAnimation from './mobile-services-scroll-animation'

type Props = {
  block: FrontPageServiceScrollAnimationRecord
}

function FrontPageServiceScrollAnimation({ block }: Props) {
  return (
    <div className={'bg-primary-rystad-blue'}>
      <div className={'invisible h-1 overflow-hidden md:visible md:h-auto'}>
        <DesktopServicesScrollAnimation block={block} />
      </div>
      <div className={'overflow-hidden md:invisible md:h-1'}>
        <MobileServicesScrollAnimation block={block} />
      </div>
    </div>
  )
}

export default FrontPageServiceScrollAnimation
