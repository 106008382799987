import Link from 'next/link'
import { Image } from 'react-datocms'
import { EnergyTypeRecord, IndustryRecord } from '../../../generated/graphql'
import MenuRightArrow from '../../../icons/menu-right-arrow'

type Props = {
  item: IndustryRecord | EnergyTypeRecord
}

function MobileCard({ item }: Props) {
  return (
    <Link
      scroll={false}
      key={item.id}
      href={`${
        item.__typename === 'EnergyTypeRecord'
          ? '/services/energytype/'
          : '/services/industry/'
      }${item.slug}`}
    >
      <div className="flex snap-start flex-row items-center gap-1 hover:cursor-pointer sm:hidden">
        <div className="w-2/6 shrink-0">
          <Image
            className="rounded-lg"
            objectFit="cover"
            data={item.thumbnail?.responsiveImage!}
          />
        </div>

        <div className="flex flex-col gap-1 px-2 py-1">
          <div className="flex flex-row items-center gap-1">
            <h2 className="text-text- font-medium">{item.name}</h2>
            <MenuRightArrow />
          </div>
          <h2 className="text-text-small">{item.excerpt}</h2>
        </div>
      </div>
    </Link>
  )
}

export default MobileCard
