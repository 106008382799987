import React from 'react'
import { HeroBannerWithNavigationalAnchorRecord } from '../generated/graphql'
import Image from 'next/image'
import AutoplayVideo from '../components/autoplay-video'
import CtaLink from '../components/cta-link'

type Props = {
  block: HeroBannerWithNavigationalAnchorRecord
}

const HeroBannerWithNavigationalAnchor = ({ block }: Props) => {
  return (
    <section className="relative h-screen" id={block?.slug || ''}>
      <div className="absolute left-1/2 grid h-full w-full max-w-fullbleed -translate-x-1/2 grid-cols-2 gap-2 px-2 md:grid-cols-8 md:gap-5 md:px-5">
        <div className="col-span-full col-start-1 flex h-full w-full flex-col justify-center gap-10 p-2 md:col-span-6">
          <div className="mt-auto flex flex-col gap-3">
            <div className="flex gap-3">
              <h2 className="text-text-small text-greys-0">
                {block?.firstKicker}
              </h2>
              <h2 className="text-text-small text-greys-3">
                {block?.secondKicker}
              </h2>
            </div>
            <h1
              dangerouslySetInnerHTML={{ __html: block?.description ?? '' }}
              className="text-display-2 text-greys-0"
            />
          </div>
          <div className="mb-auto flex max-w-[70%] flex-col">
            {block.cta.map((cta) => (
              <div key={cta.id} className={'w-fit'}>
                <CtaLink cta={cta} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="absolute bottom-1 right-1 flex flex-col gap-[16px] p-4 text-white">
        {block.anchorPoints?.map((anchor) => {
          return (
            <a
              key={anchor.id}
              className={
                'group flex shrink-0 snap-start items-center justify-end gap-[20px] text-right text-text-x-small'
              }
              href={anchor.link ?? ''}
            >
              <span className="transition duration-300 ease-in-out">
                {anchor.ctaLabel}
              </span>
              <span className="all h-1 w-1 rounded border text-right transition duration-300 ease-in-out group-hover:scale-150 group-hover:bg-white" />
            </a>
          )
        })}
      </div>

      {block.backgroundVideoLink && (
        <div className="relative z-[-1] h-screen w-auto min-w-full object-cover">
          <AutoplayVideo
            videoSrc={block.backgroundVideoLink!}
            fallBackImage={block.backgroundImage?.responsiveImage!}
          />
        </div>
      )}
      {block.backgroundImage && (
        <div className="relative z-[-1] col-span-full h-screen">
          <Image
            className="h-full w-full object-cover"
            src={block.backgroundImage.responsiveImage?.src!}
            layout="fill"
            priority
          />
        </div>
      )}
      <div
        className={'absolute top-0 left-0 z-[-1] h-full w-full bg-overlay'}
      />
    </section>
  )
}

export default HeroBannerWithNavigationalAnchor
