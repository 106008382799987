import Link from 'next/link'
import Section from '../../components/section'
import { EmployeePublicationRecord } from '../../generated/graphql'
import EmployeePublication from './employee-publication'

type Props = {
  block: EmployeePublicationRecord
}

function EmployeePublicationRecord({ block }: Props) {
  return (
    <Section>
      <div className="col-span-full border-t border-greys-1 md:col-start-5">
        <h2 className="pt-9 text-header-2">{block.title}</h2>
        {block.pressRealeasesOfTheAuthor.map((pressRelease) => (
          <Link
            scroll={false}
            href={`/news/${pressRelease.slug}`}
            key={pressRelease.id}
          >
            <a>
              <EmployeePublication pressRelease={pressRelease} />
            </a>
          </Link>
        ))}
      </div>
    </Section>
  )
}

export default EmployeePublicationRecord
