import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  underlineColor?: string
  hoverUnderlineColor?: string
}
function FancyUnderlinedLabel({
  children,
  underlineColor,
  hoverUnderlineColor,
}: Props) {
  const hoverColor = hoverUnderlineColor ?? 'currentColor'
  return (
    <span
      style={{
        backgroundImage: `linear-gradient(${underlineColor}, ${underlineColor})`,
      }}
      className="bg-[length:100%_1px] bg-left-bottom bg-no-repeat pb-[5px]"
    >
      <span
        style={{
          backgroundImage: `linear-gradient(${hoverColor}, ${hoverColor})`,
        }}
        className="h-full w-full bg-[length:0%_1px] bg-left-bottom bg-no-repeat pb-[5px] text-current transition-all duration-700 ease-in-out hover:bg-[length:100%_1px]"
      >
        {children}
      </span>
    </span>
  )
}

export default FancyUnderlinedLabel
