import { format } from 'date-fns'
import _ from 'lodash'
import { InsightRecord } from '../../../generated/graphql'

export function groupInsightsByMonths(data?: {
  allInsights: InsightRecord[]
}): Record<string, InsightRecord[]> {
  const _data: Record<string, InsightRecord[]> = {}

  const mappedData = data?.allInsights.map((insight) => {
    const formattedDate = format(new Date(insight.publicationDate), 'MMMM YYY')
    return { formattedDate: formattedDate, ...insight }
  })

  _.chain(mappedData)
    .groupBy('formattedDate')
    .map((key: InsightRecord[], value: string) => ({
      date: value,
      items: key,
    }))
    .value()
    .forEach((insight) => (_data[insight.date] = insight.items))

  return _data
}
