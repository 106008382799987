import Link from 'next/link'
import { Image } from 'react-datocms'
import { EnergyTypeRecord, IndustryRecord } from '../../../generated/graphql'

type Props = {
  item: IndustryRecord | EnergyTypeRecord
}

function DesktopCard({ item }: Props) {
  return (
    <Link
      scroll={false}
      key={item.id}
      href={`${
        item.__typename === 'EnergyTypeRecord'
          ? '/services/energytype/'
          : '/services/industry/'
      }${item.slug}`}
    >
      <div className="group isolate hidden h-full cursor-pointer flex-col overflow-hidden rounded-bl-lg rounded-br-lg rounded-tl-lg rounded-tr-lg shadow-2xl sm:flex">
        <div className="relative flex flex-col items-center justify-center overflow-hidden group-hover:bg-primary-rystad-blue">
          <div className="absolute isolate z-10 overflow-hidden">
            <h2 className="translate-y-[2em] text-text-small text-white transition-all duration-500 group-hover:translate-y-[0]">
              Explore
            </h2>
          </div>
          <div className="transition-all duration-500 group-hover:scale-110 group-hover:opacity-90">
            <Image
              className="rounded-tl-lg rounded-tr-lg"
              data={item.thumbnail?.responsiveImage!}
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-[4px] bg-white p-3">
          <h2 className="text-text-base font-medium">{item.name}</h2>
          <h2 className="text-text-small">{item.excerpt}</h2>
        </div>
      </div>
    </Link>
  )
}

export default DesktopCard
