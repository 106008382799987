import { StructuredText } from 'react-datocms'
import { TextContentRecord } from '../../generated/graphql'

type Props = {
  block: TextContentRecord
}

const TextContentRecord = ({ block }: Props) => {
  const { title, label, content } = block
  return (
    <div className="w-full">
      <div className="mx-auto grid w-full max-w-fullbleed grid-cols-12 px-2 pt-[40%] md:pt-[20%] lg:px-5 xl:pt-[15%]">
        <div className="col-span-12 md:col-span-8 xl:col-span-5">
          <div className="mb-3 text-text-small">{label}</div>
          <div className="mb-3 text-display-2">{title}</div>
          <div
            className="text-text-manchet"
            dangerouslySetInnerHTML={{ __html: content || '' }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default TextContentRecord
