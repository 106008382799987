import Link from 'next/link'
import { Image } from 'react-datocms'
import ArrowRightButton from '../components/arrow-right-button'
import AutoplayVideo from '../components/autoplay-video'
import FilledButton from '../components/button'
import {
  FrontPageTopModuleModelLatestInsightsField,
  FrontPageTopModuleRecord,
} from '../generated/graphql'
import formatDate from '../utils/format-date'
import { isPressReleaseArticleRecord } from './press-release-thumbnail/press-release-thumbnail'
import Dynamic from '../components/dynamic'
import { Mousewheel, Navigation } from 'swiper'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useRef, useState, useCallback } from 'react'
import ArrowLeft from '../icons/arrow-left'
import ArrowRight from '../icons/arrow-right'

type Props = {
  block: FrontPageTopModuleRecord
}

function displayInsightPublicationDate(
  insight: FrontPageTopModuleModelLatestInsightsField
) {
  if (insight.__typename === 'InsightRecord') {
    return formatDate(insight.updatedAt, 'month-year')
  } else if (insight.__typename === 'PressReleaseRecord') {
    return formatDate(insight?.publicationDate, 'month-year')
  }
}

function displayInsightTitle(
  insight: FrontPageTopModuleModelLatestInsightsField
) {
  if (insight.__typename === 'InsightRecord') {
    return insight.title
  } else if (insight.__typename === 'PressReleaseRecord') {
    const article = insight.blocks.find((block) =>
      isPressReleaseArticleRecord(block)
    )

    return article && isPressReleaseArticleRecord(article) && article.headline
  }
}

function displayPressReleaseArticleImage(
  insight: FrontPageTopModuleModelLatestInsightsField
) {
  if (insight.__typename === 'PressReleaseRecord') {
    const article = insight.blocks.find((block) =>
      isPressReleaseArticleRecord(block)
    )

    return (
      article &&
      isPressReleaseArticleRecord(article) &&
      article?.image?.responsiveImage && (
        <Image
          style={{ maxWidth: 'auto' }}
          className="rounded-md"
          layout="fill"
          objectFit="cover"
          data={article?.image?.responsiveImage!}
        />
      )
    )
  }
}

function getInsightUrl(insight: FrontPageTopModuleModelLatestInsightsField) {
  if (insight.__typename === 'InsightRecord') {
    return `/insights/${insight.slug}`
  } else if (insight.__typename === 'PressReleaseRecord') {
    return `/news/${insight.slug}`
  }
}

function FrontPageTopModule({ block }: Props) {
  const insightsArray = block.latestInsights
  const prevButton = useRef(null)
  const nextButton = useRef(null)
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev()
  }, [swiperRef])

  const handleNext = useCallback(() => {
    swiperRef?.slideNext()
  }, [swiperRef])

  return (
    <div className={'relative h-screen w-full overflow-x-hidden'}>
      <section className="absolute left-1/2 mx-auto grid h-full w-full max-w-fullbleed -translate-x-1/2 transform grid-cols-8 gap-3 overflow-hidden p-2 md:p-5">
        <div className="col-span-8"></div>

        <div className="md:-translate-y-4 col-span-8 sm:col-span-6 lg:col-span-5">
          <div className="flex flex-col gap-3">
            <h2 className="text-display-1 text-greys-0">{block?.headline}</h2>

            <div className="flex flex-col gap-2">
              <h1 className="text-text-base text-greys-0 md:text-text-large">
                {block?.description}
              </h1>

              <div className="flex items-center gap-3">
                <div className="hidden sm:block">
                  <ArrowRightButton
                    variant={'white'}
                    link={block?.cta[0]?.link ?? ''}
                  >
                    {block?.cta[0]?.ctaLabel}
                  </ArrowRightButton>
                </div>

                <div className="block sm:hidden">
                  <FilledButton
                    text={block?.cta[0]?.ctaLabel ?? ''}
                    to={block?.cta[0]?.link ?? ''}
                    variant="white"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="left-aligned-fullbleed absolute bottom-2 -mt-6 flex flex-col gap-4 sm:mt-auto md:bottom-0">
        <h2 className="relative col-span-8 text-text-tagline text-greys-0">
          {block.insightsLabel}
        </h2>
        <Swiper
          onSwiper={setSwiperRef}
          modules={[Mousewheel]}
          className={'w-full'}
          spaceBetween={36}
          slidesPerView="auto"
          mousewheel={{ forceToAxis: true }}
          direction={'horizontal'}
          breakpoints={{
            768: {
              spaceBetween: 56,
            },
            1024: {
              spaceBetween: 72,
            },
          }}
        >
          {insightsArray.map((item) =>
            item.__typename === 'CustomPageItemRecord' ? (
              <SwiperSlide
                key={item.id}
                className={'w-[85vw] md:w-[50vw] lg:w-[43vw] xl:w-[26vw]'}
              >
                <Link
                  scroll={false}
                  key={item.id}
                  href={item?.page?.slug ?? ''}
                >
                  <div
                    key={item.id}
                    className={
                      'flex w-[85vw] shrink-0 snap-start flex-row gap-3 hover:cursor-pointer md:w-[50vw] lg:w-[43vw] xl:w-[26vw]'
                    }
                  >
                    <div
                      className={
                        'relative aspect-[1.5] w-full max-w-[96px] shrink-0'
                      }
                    >
                      <Image
                        style={{ maxWidth: 'auto' }}
                        className="rounded-md"
                        layout="fill"
                        objectFit="cover"
                        data={item?.image?.responsiveImage!}
                      />
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="flex flex-row items-center gap-[6px] text-text-x-small lg:gap-1">
                        <div className="flex flex-row items-center gap-1">
                          <div
                            style={{
                              backgroundColor: item?.dotColor?.hex,
                            }}
                            className={`h-1 w-1 shrink-0 rounded-full`}
                          ></div>
                          <h2 className="text-greys-0">{item?.subtitle}</h2>
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-text-small text-greys-0 line-clamp-2">
                          {item?.title}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ) : (
              <SwiperSlide
                key={item.id}
                className={'w-[85vw] md:w-[50vw] lg:w-[43vw] xl:w-[26vw]'}
              >
                <Link
                  scroll={false}
                  key={item.id}
                  href={`${getInsightUrl(item)}`}
                >
                  <div
                    key={item.id}
                    className={
                      'flex w-[85vw] shrink-0 snap-start flex-row gap-3 hover:cursor-pointer md:w-[50vw] lg:w-[43vw] xl:w-[26vw]'
                    }
                  >
                    {item?.__typename === 'InsightRecord' && item?.image && (
                      <div
                        className={
                          'relative aspect-[1.5] w-full max-w-[96px] shrink-0'
                        }
                      >
                        <Image
                          style={{ maxWidth: 'auto' }}
                          className="rounded-md"
                          layout="fill"
                          objectFit="cover"
                          data={item?.image?.responsiveImage!}
                        />
                      </div>
                    )}

                    {item?.__typename === 'PressReleaseRecord' && (
                      <div
                        className={
                          'relative aspect-[1.5] w-full max-w-[96px] shrink-0'
                        }
                      >
                        {displayPressReleaseArticleImage(item)}
                      </div>
                    )}

                    <div className="flex flex-col gap-1">
                      <div className="flex flex-row items-center gap-[6px] text-text-x-small lg:gap-1">
                        <div className="flex flex-row items-center gap-1">
                          {item?.__typename === 'PressReleaseRecord' ||
                            (item?.__typename === 'InsightRecord' && (
                              <>
                                <div
                                  style={{
                                    backgroundColor: item?.tag?.dotColor?.hex,
                                  }}
                                  className={`h-1 w-1 shrink-0 rounded-full`}
                                ></div>
                                <h2 className="text-greys-0">
                                  {item?.tag?.tagLabel}
                                  <span className="font-bold text-greys-0">
                                    &nbsp;-&nbsp;
                                  </span>
                                  <span className="text-greys-0">
                                    <Dynamic>
                                      {displayInsightPublicationDate(item)}
                                    </Dynamic>
                                  </span>
                                </h2>
                              </>
                            ))}
                        </div>
                      </div>

                      <div className="flex flex-col">
                        <span className="text-text-small text-greys-0 line-clamp-2">
                          {displayInsightTitle(item)}
                        </span>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            )
          )}
        </Swiper>
        <div className="mr-[16px] mb-2 hidden justify-start gap-[47px] md:mr-[48px] md:flex">
          <button
            ref={prevButton}
            className="cursor-pointer text-white disabled:opacity-20"
            onClick={handlePrevious}
          >
            <ArrowLeft />
          </button>
          <button
            ref={nextButton}
            className="cursor-pointer text-white disabled:opacity-20"
            onClick={handleNext}
          >
            <ArrowRight />
          </button>
        </div>
      </div>
      <div className="absolute z-[-1] h-full w-auto min-w-full object-cover">
        <AutoplayVideo
          videoSrc={block?.externalVideoLink!}
          fallBackImage={block.backgroundImage?.responsiveImage!}
        />
      </div>
      <div className={'top absolute z-[-1] h-full w-full bg-overlay'} />
    </div>
  )
}

export default FrontPageTopModule
