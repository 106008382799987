const Logo = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 237 237"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M102.105 112.946C97.803 129.03 77.4861 132.244 70.1355 118.004C63.2883 104.738 73.5106 86.9331 90.0932 90.4973C102.242 94.4941 103.932 106.113 102.102 112.946"
        fill="#D2875E"
      />
      <path
        d="M73.4697 146.913C71.879 158.201 60.189 161.163 53.2969 152.023C45.7691 142.042 51.2737 127.586 61.9569 129.276C62.3375 129.335 62.8338 129.468 63.2096 129.551C72.1036 132.597 74.2189 141.602 73.4697 146.913Z"
        fill="#D2875E"
      />
      <path
        d="M126.847 78.1841C123.704 89.9687 106.363 92.0793 100.711 81.3654C95.722 71.9042 104.278 59.4318 117.202 62.1428C126.63 65.0901 128.136 73.3507 126.847 78.1841Z"
        fill="#D2875E"
      />
      <path
        d="M146.023 53.3031C144.942 61.1122 134.065 62.9865 128.906 56.2505C123.935 49.7602 129.412 40.7386 137.526 42.0551C137.797 42.1 138.152 42.1874 138.419 42.2441C144.838 44.2555 146.483 49.9611 146.02 53.3031"
        fill="#D2875E"
      />
      <path
        d="M56.0644 172.542C56.7593 179.765 51.621 182.563 46.2463 177.886C39.4251 171.951 38.5152 160.691 44.7927 159.901C45.672 159.79 46.485 159.972 47.1373 160.119C53.0084 162.175 55.6934 168.675 56.0644 172.542Z"
        fill="#D2875E"
      />
      <path
        d="M49.0623 191.711C50.5395 195.282 50.2535 198.223 47.2164 196.965C41.8394 194.741 36.2945 184.609 39.6105 183.068C40.1849 182.801 40.7261 182.926 41.3005 183.058C45.1932 184.479 48.0791 189.338 49.0623 191.711Z"
        fill="#D2875E"
      />
      <path
        d="M157.878 35.6936C158.381 40.5271 153.179 42.3068 148.901 38.7662C144.134 34.8215 145.533 28.2981 149.967 28.0902C150.589 28.0618 151.144 28.1823 151.588 28.2792C155.774 29.6099 157.644 33.4436 157.88 35.696"
        fill="#D2875E"
      />
      <path
        d="M162.933 23.3668C164.11 25.8556 162.874 27.3376 160.388 26.4158C156.462 24.9622 154.02 19.4457 156.14 18.5145C156.62 18.3041 157.187 18.4294 157.528 18.505C160.194 19.3559 162.257 21.9392 162.933 23.3668Z"
        fill="#D2875E"
      />
      <path
        d="M50.0528 205.958C51.7191 208.026 52.8772 210.035 51.7096 209.862C48.8616 209.444 41.3266 201.432 42.5462 200.12C42.7353 199.917 43.0378 199.99 43.3569 200.068C46.0986 201.117 49.6557 205.464 50.0504 205.955"
        fill="#D2875E"
      />
      <path
        d="M56.8837 216.586C59.7932 218.862 59.8452 219.134 59.8641 219.236C58.8242 219.505 50.7551 213.625 50.8709 212.682C50.9441 212.634 50.9796 212.639 51.1663 212.686C52.4497 213.195 55.2978 215.348 56.8813 216.586"
        fill="#D2875E"
      />
      <path
        d="M162.43 14.8085C163.85 16.2195 163.895 17.0373 163.12 17.0089C161.054 16.938 156.497 12.4639 157.081 11.743C157.199 11.5988 157.504 11.6674 157.705 11.7146C159.182 12.1779 161.267 13.6527 162.432 14.8085"
        fill="#D2875E"
      />
      <path
        d="M66.8009 224.289C68.8336 225.37 69.9106 225.952 70.0319 226.036C69.5261 226.107 62.6695 222.538 61.8777 221.791L61.9651 221.781C62.2724 221.903 63.8835 222.738 66.7986 224.289"
        fill="#D2875E"
      />
      <path
        d="M157.701 8.927C158.013 9.10191 159.576 9.99296 159.599 10.312C159.257 10.6358 153.631 7.43561 153.35 7.03854L153.386 7.00781C153.435 7.01254 153.499 7.02672 153.549 7.03617C154.338 7.2678 156.149 8.05486 157.701 8.92464"
        fill="#D2875E"
      />
      <path
        d="M64.7995 102.621C60.8098 117.041 46.163 122.125 39.5191 111.392C31.8967 99.0784 41.3745 77.6293 56.3877 81.0186C65.9671 84.545 66.6667 95.8687 64.7995 102.621Z"
        fill="#292862"
      />
      <path
        d="M41.0423 137.764C40.4655 148.156 33.1504 152.396 27.3077 145.724C19.7964 137.142 21.9803 120.179 30.6002 120.16C31.1792 120.16 31.9426 120.33 32.5052 120.465C39.3216 123.188 41.3448 132.302 41.0423 137.764Z"
        fill="#292862"
      />
      <path
        d="M89.8427 68.3979C85.4323 79.2678 71.0549 82.1537 66.8833 73.0068C62.9007 64.2759 72.6834 50.8179 84.0827 53.2854C91.7453 55.9019 91.724 63.7583 89.8427 68.3979Z"
        fill="#292862"
      />
      <path
        d="M113.181 44.7148C109.957 52.5287 98.097 53.9539 95.5538 46.8302C93.4385 40.9048 100.65 32.7766 108.631 34.4642C114.311 36.3054 114.464 41.6021 113.181 44.7125"
        fill="#292862"
      />
      <path
        d="M30.3833 164.999C31.6856 170.744 30.388 175.181 26.3842 172.626C19.9152 168.499 15.7459 153.732 20.6644 152.368C21.2459 152.207 21.8249 152.354 22.2905 152.469C26.89 154.429 29.5962 161.519 30.3833 164.996"
        fill="#292862"
      />
      <path
        d="M30.1072 185.769C31.9579 189.135 33.1184 192.453 31.2914 191.784C27.5546 190.413 19.9771 178.208 22.0783 176.943C22.3052 176.806 22.6148 176.894 22.8653 176.962C25.4298 178.18 28.6253 183.07 30.1072 185.767"
        fill="#292862"
      />
      <path
        d="M131.264 28.6541C129.799 33.8468 122.084 34.3053 119.636 30.4291C117.232 26.6215 121.543 20.715 127.277 21.9109C131.25 23.1471 131.838 26.6215 131.264 28.6541Z"
        fill="#292862"
      />
      <path
        d="M142.56 17.8877C142.603 21.0572 138.509 21.5678 136.325 19.3626C133.985 16.999 135.474 13.5979 138.138 13.5057C138.523 13.4916 138.857 13.5625 139.121 13.6192C141.596 14.385 142.544 16.6445 142.56 17.8901"
        fill="#292862"
      />
      <path
        d="M36.9071 201.458C40.4973 205.409 40.3721 205.7 40.3319 205.795C39.013 205.934 29.8449 196.296 30.4216 195.376C30.4925 195.369 30.587 195.393 30.6556 195.416C32.0359 196.18 35.489 199.895 36.9095 201.458"
        fill="#292862"
      />
      <path
        d="M48.0938 213.286C49.4032 214.284 50.8284 215.13 52.0267 216.276C51.3767 216.139 43.5889 210.223 42.7664 209.242L42.8207 209.254C44.6808 210.464 46.3353 211.944 48.0938 213.284"
        fill="#292862"
      />
      <path
        d="M147.502 10.7676C148.279 12.2496 147.306 12.8759 145.98 12.4079C143.727 11.6138 142.368 8.65701 143.6 8.17012C143.867 8.06376 144.183 8.13231 144.372 8.17012C145.821 8.60974 147.074 9.95223 147.502 10.7653"
        fill="#292862"
      />
      <path
        d="M147.25 6.1506C148.049 6.71784 148.392 7.18582 148.047 7.23782C147.959 7.252 147.449 7.27091 145.997 6.32077C145.248 5.83152 143.929 4.80574 144.104 4.66157C144.144 4.62848 144.246 4.64975 144.355 4.67339C145.102 4.88138 146.399 5.54317 147.25 6.14823"
        fill="#292862"
      />
      <path
        d="M86.9596 180.856C86.1867 188.323 77.5385 190.941 71.7029 183.388C66.1226 176.162 69.5781 167.271 77.4794 168.521C77.7748 168.568 78.1625 168.667 78.4556 168.729C85.3854 170.948 87.3377 177.2 86.9596 180.859"
        fill="#292862"
      />
      <path
        d="M142.468 123.931C138.211 139.833 117.053 142.683 108.677 128.483C100.858 115.231 111.702 97.1849 129.67 101.056C143.442 105.516 144.064 117.97 142.468 123.931Z"
        fill="#292862"
      />
      <path
        d="M111.042 156.877C107.821 168.936 91.6542 170.273 85.6059 158.983C80.366 149.198 87.5629 136.924 100.484 139.668C110.733 142.934 112.413 151.75 111.042 156.879"
        fill="#292862"
      />
      <path
        d="M163.946 88.261C162.268 99.9534 147.862 102.804 140.587 92.8841C133.296 82.9383 140.479 69.5039 152.06 71.4279C152.448 71.4917 152.954 71.624 153.336 71.7068C162.436 74.6825 164.667 83.2361 163.946 88.261Z"
        fill="#292862"
      />
      <path
        d="M177.254 61.9336C177.776 69.4378 170.409 72.2268 164.332 66.8285C157.426 60.6927 159.461 50.4208 165.897 50.0332C166.816 49.9788 167.634 50.1632 168.291 50.3097C174.417 52.3613 177.008 58.3859 177.254 61.9336Z"
        fill="#292862"
      />
      <path
        d="M73.0357 198.34C73.7779 202.448 71.2631 204.991 66.9071 202.119C61.4284 198.503 59.4265 190.985 63.6596 189.931C64.5104 189.718 65.1864 189.865 65.9026 190.018C70.6344 191.559 72.6363 196.137 73.0357 198.34Z"
        fill="#292862"
      />
      <path
        d="M182.747 42.7588C184.326 47.0794 181.964 49.476 178.067 47.5048C172.156 44.5149 169.66 35.6919 173.295 34.711C173.95 34.5337 174.505 34.6637 175.091 34.8032C179.048 36.1646 181.886 40.4071 182.747 42.7612"
        fill="#292862"
      />
      <path
        d="M181.879 28.9124C183.657 31.283 183.739 32.9658 182.061 32.6656C178.393 32.0086 172.834 24.3436 174.38 23.5448C174.637 23.41 174.959 23.4904 175.299 23.5731C177.71 24.4193 180.598 27.2059 181.877 28.9124"
        fill="#292862"
      />
      <path
        d="M67.8317 211.046C69.0253 213.11 69.2356 214.927 67.3353 214.41C63.9531 213.488 58.2664 207.064 60.2778 205.556C60.6962 205.242 61.3225 205.379 61.6959 205.461C64.5393 206.404 66.891 209.416 67.8317 211.044"
        fill="#292862"
      />
      <path
        d="M69.7121 220.357C70.2982 220.891 72.1583 222.64 71.4398 222.685C69.8231 222.784 63.08 217.502 63.8458 216.736C63.9639 216.618 64.1885 216.67 64.4272 216.722C66.37 217.369 69.1708 219.863 69.7121 220.357Z"
        fill="#292862"
      />
      <path
        d="M176.351 18.9745C178.315 20.5534 178.941 21.5461 178.395 21.5602C177.133 21.5933 169.608 15.8688 170.343 15.4363C170.407 15.3985 170.575 15.4434 170.697 15.4741C172.123 15.9752 174.739 17.6817 176.349 18.9745"
        fill="#292862"
      />
      <path
        d="M75.8966 226.966C78.2932 228.29 78.3594 228.389 78.4067 228.462C77.6763 228.604 71.1388 225.196 71.1199 224.664C71.1695 224.64 71.1955 224.643 71.335 224.676C71.654 224.78 72.3182 224.993 75.8942 226.966"
        fill="#292862"
      />
      <path
        d="M167.68 11.8942C168.968 12.5749 170.327 13.3785 170.431 13.6006C169.899 13.6715 162.773 9.70553 162.729 9.66299C162.769 9.65589 162.823 9.66299 162.861 9.6748C163.674 9.94661 165.803 10.9038 167.68 11.8942Z"
        fill="#292862"
      />
      <path
        d="M178.291 134.405C174.11 149.156 156.582 152.916 148.985 140.689C141.075 127.959 151.252 107.862 167.627 111.646C179.033 115.702 180.253 127.48 178.291 134.405Z"
        fill="#292862"
      />
      <path
        d="M147.443 166.774C142.768 178.358 127.244 179.587 122.652 168.738C118.632 159.239 127.209 147.421 139.662 150.17C149.022 153.309 149.445 161.82 147.443 166.774Z"
        fill="#292862"
      />
      <path
        d="M196.263 97.9105C195.61 108.683 185.693 112.789 178.574 105.233C170.316 96.4664 173.92 80.1792 184.005 80.6874C184.667 80.7205 185.535 80.919 186.18 81.0727C194.147 83.9798 196.575 92.7367 196.263 97.9128"
        fill="#292862"
      />
      <path
        d="M203.878 70.177C205.315 77.0785 201.212 80.6168 196.036 76.9391C189.099 72.0064 186.894 58.702 192.89 57.9575C193.599 57.8701 194.275 58.0403 194.817 58.1773C200.047 60.1793 203.101 66.4497 203.876 70.177"
        fill="#292862"
      />
      <path
        d="M119.902 189.501C116.874 197.752 105.245 198.057 101.868 189.976C99.1167 183.394 104.657 176.046 113.478 177.918C120.493 180.142 121.161 186.062 119.899 189.499"
        fill="#292862"
      />
      <path
        d="M99.5821 205.32C98.7265 210.293 92.449 211.664 88.7808 206.301C85.5805 201.623 88.2159 196.513 93.4015 197.338C93.6071 197.371 93.8765 197.438 94.0774 197.48C98.8471 198.969 99.9839 202.982 99.5821 205.318"
        fill="#292862"
      />
      <path
        d="M203.329 49.4728C205.404 53.4411 204.986 56.1545 202.433 55.28C197.439 53.5688 191.747 42.4436 194.123 41.1177C194.522 40.8955 194.955 41.0113 195.413 41.1366C198.703 42.472 202.015 46.958 203.329 49.4728Z"
        fill="#292862"
      />
      <path
        d="M197.019 34.1766C199.082 36.4999 200.056 38.3648 199.236 38.4191C198.465 38.4711 196.284 36.7835 193.932 34.316C191.495 31.7587 189.198 28.7877 189.737 28.4497C189.84 28.3835 190.063 28.4497 190.223 28.4994C192.003 29.2675 195.076 31.9903 197.019 34.1766Z"
        fill="#292862"
      />
      <path
        d="M87.9201 216.401C88.4141 218.999 86.6178 220.462 83.6468 218.384C80.2339 215.999 79.1041 211.761 81.7276 211.187C82.2925 211.064 82.7439 211.159 83.219 211.26C86.353 212.244 87.6648 215.054 87.9201 216.401Z"
        fill="#292862"
      />
      <path
        d="M84.3498 224.285C85.1156 225.348 85.4536 226.353 84.4893 226.204C82.4968 225.896 78.103 221.964 79.3486 221.07C79.5967 220.893 80.0175 220.983 80.242 221.03C82.0383 221.59 83.6691 223.339 84.3475 224.285"
        fill="#292862"
      />
      <path
        d="M187.06 22.9429C188.54 24.0679 190.187 25.4624 190.303 25.8571C189.556 26.0557 181.187 19.225 181.139 19.1447C181.189 19.1281 181.262 19.14 181.312 19.1565C182.231 19.5725 184.692 21.1371 187.06 22.9405"
        fill="#292862"
      />
      <path
        d="M86.7202 230.828C88.344 231.553 88.4196 231.695 88.448 231.747C88.2258 231.929 86.0112 231.258 85.1556 230.943C82.5793 229.996 82.4848 229.57 82.4824 229.551C82.4635 229.436 82.6715 229.445 82.8086 229.454C83.7942 229.587 85.4794 230.275 86.7202 230.828Z"
        fill="#292862"
      />
      <path
        d="M206.558 143.907C202.235 157.748 188.896 162.359 183.159 151.995C176.459 139.894 185.809 118.033 199.562 121.619C208.61 125.441 208.704 137.041 206.561 143.907"
        fill="#292862"
      />
      <path
        d="M177.447 175.822C171.635 186.392 158.846 188.325 156.064 179.055C153.341 169.984 163.001 157.478 173.549 160.017C181.181 162.936 180.061 171.069 177.447 175.822Z"
        fill="#292862"
      />
      <path
        d="M220.859 106.685C220.736 116.432 214.967 121.464 209.635 116.475C201.913 109.249 203.088 89.3223 211.226 89.4854C211.72 89.4948 212.365 89.6674 212.838 89.7997C218.843 92.5107 220.926 101.383 220.859 106.685Z"
        fill="#292862"
      />
      <path
        d="M222.983 77.5813C224.782 83.8801 223.501 87.8627 220.296 85.9364C214.952 82.722 209.989 66.5105 213.974 65.2862C214.401 65.1562 214.855 65.298 215.219 65.4138C218.871 67.2574 221.917 73.854 222.981 77.5813"
        fill="#292862"
      />
      <path
        d="M148.461 197.553C144.065 204.247 133.765 206.112 132.191 198.825C130.832 192.54 137.847 184.982 145.71 186.771C151.562 188.844 150.562 194.353 148.461 197.553Z"
        fill="#292862"
      />
      <path
        d="M124.766 212.092C121.892 216.712 114.758 217.667 113.643 212.392C112.775 208.3 117.004 203.798 122.35 204.97C126.508 206.362 126.073 209.993 124.766 212.094"
        fill="#292862"
      />
      <path
        d="M215.088 55.426C217.328 59.21 218.255 62.1857 217.111 61.921C214.783 61.3845 206.369 48.0045 207.633 46.8511C207.756 46.74 207.966 46.8227 208.151 46.8937C209.971 47.9714 213.107 52.0769 215.088 55.4237"
        fill="#292862"
      />
      <path
        d="M205.725 38.7659C207.777 41.0159 209.476 43.1148 209.516 43.4409C208.608 43.0769 199.698 32.935 199.674 32.8594L199.795 32.9066C200.665 33.5472 203.255 36.0549 205.725 38.7659Z"
        fill="#292862"
      />
      <path
        d="M108.054 221.698C106.848 224.631 102.714 225.236 101.247 221.904C100.086 219.271 102.093 216.38 105.655 217.139C108.64 218.091 108.529 220.537 108.051 221.698"
        fill="#292862"
      />
      <path
        d="M100.753 228.599C101.391 230.062 100.19 231.151 98.0888 230.263C96.1366 229.438 95.5173 227.739 96.9236 227.072C97.1552 226.963 97.3892 226.921 97.5783 226.888C99.4337 226.739 100.467 227.942 100.753 228.599Z"
        fill="#292862"
      />
      <path
        d="M96.4717 234.062C96.7057 234.206 97.4407 234.688 97.0484 234.802C96.2448 235.036 93.0517 233.967 93.4865 233.358C93.5693 233.242 93.7891 233.223 93.9096 233.211C95.0252 233.204 96.2401 233.918 96.4717 234.062Z"
        fill="#292862"
      />
      <path
        d="M224.078 151.847C220.268 162.98 212.638 168.213 209.499 161.843C205.15 153.025 213.482 129.236 222.267 132.176C227.427 135.286 226.115 145.891 224.08 151.845"
        fill="#292862"
      />
      <path
        d="M198.855 183.668C193.192 191.912 183.284 196.152 181.89 189.033C180.413 181.494 190.651 166.291 199.467 168.79C204.731 171.39 201.985 179.111 198.855 183.668Z"
        fill="#292862"
      />
      <path
        d="M232.596 113.688C232.66 122.289 230.355 127.914 227.763 125.492C223.024 121.065 222.631 97.1837 227.292 96.7796C227.555 96.7583 227.897 96.893 228.141 96.9947C231.336 99.37 232.556 108.281 232.596 113.688Z"
        fill="#292862"
      />
      <path
        d="M231.594 83.6968C232.568 86.8521 234.059 92.3993 233.466 93.1698C231.944 93.1745 225.269 72.9072 226.326 71.4844C226.411 71.4844 226.52 71.5411 226.59 71.5907C227.86 73.0372 230.117 78.9082 231.594 83.6991"
        fill="#292862"
      />
      <path
        d="M170.063 204.598C164.797 210.1 156.215 212.52 156.047 207.25C155.881 202.038 164.518 192.955 171.289 194.695C175.567 196.55 172.899 201.634 170.063 204.598Z"
        fill="#292862"
      />
      <path
        d="M146.828 220.186C142.281 223.374 135.883 223.937 136.686 220.268C137.428 216.884 143.959 212.369 148.518 214C151.503 215.577 149.142 218.564 146.828 220.186Z"
        fill="#292862"
      />
      <path
        d="M126.353 228.061C123.71 229.609 119.357 230.027 119.886 227.699C120.354 225.65 124.542 223.14 127.483 224.324C129.236 225.314 128.241 226.952 126.353 228.059"
        fill="#292862"
      />
      <path
        d="M111.527 231.966C109.851 232.429 107.549 231.952 108.232 230.664C108.795 229.603 111.09 228.901 112.541 230.028C113.397 230.879 112.678 231.647 111.527 231.966Z"
        fill="#292862"
      />
      <path
        d="M230.605 160.047C227.546 168.629 224.188 174.243 223.417 172.057C221.869 167.667 231.24 140.938 233.973 141.95C234.051 141.978 234.126 142.073 234.192 142.125C235.369 144.585 232.692 154.191 230.605 160.045"
        fill="#292862"
      />
      <path
        d="M210.639 191.284C205.831 197.389 200.452 200.762 200.176 197.845C199.743 193.279 211.187 177.658 215.281 179.405C217.243 181.272 213.475 187.682 210.637 191.284"
        fill="#292862"
      />
      <path
        d="M236.135 132.13C236.651 127.19 236.58 108.932 236.031 104.219C236.327 107.785 236.774 112.543 236.887 116.119C236.793 120.938 236.551 127.37 236.131 132.17L236.135 132.127"
        fill="#292862"
      />
      <path
        d="M185.93 213.501C180.765 217.718 173.89 220.601 174.192 217.666C174.585 213.86 186.45 202.965 191.092 204.494C193.179 205.902 189.988 210.185 185.93 213.501Z"
        fill="#292862"
      />
      <path
        d="M160.276 227.056C155.884 229.258 150.264 230.516 150.746 228.739C151.287 226.739 161.235 220.473 164.953 221.816C166.579 222.879 163.757 225.309 160.276 227.056Z"
        fill="#292862"
      />
      <path
        d="M136.35 234.913C133.096 235.698 128.643 235.757 129.208 234.842C129.879 233.758 137.355 231.184 139.747 232.297C140.756 233.063 138.589 234.19 135.911 234.835"
        fill="#292862"
      />
      <path
        d="M119.036 236.34C117.18 236.591 114.63 236.572 114.885 236.225C115.439 235.468 121.154 234.925 121.709 235.168C122.262 235.57 120.934 236.043 119.4 236.286C119.291 236.303 119.145 236.324 119.033 236.34"
        fill="#292862"
      />
      <path
        d="M106.251 236.061C105.27 236.037 104.228 235.9 104.107 235.839C104.19 235.751 107.475 235.543 108.005 235.784C108.184 235.933 107.579 236.009 107.39 236.028C107.173 236.049 106.799 236.075 106.251 236.061Z"
        fill="#292862"
      />
      <path
        d="M34.8851 93.5647C30.7773 107.2 20.6188 113.203 16.4613 104.451C11.2805 93.5458 20.2265 70.1445 31.2524 72.9736C38.0688 76.1738 36.6105 87.8402 34.8851 93.5624"
        fill="#292862"
      />
      <path
        d="M16.4974 129.796C16.4383 138.785 13.4012 143.926 10 140.799C3.82174 135.121 3.72248 113.216 9.8724 112.561C10.2175 112.524 10.6831 112.651 11.0163 112.751C15.1053 115.067 16.5352 124.214 16.4974 129.796Z"
        fill="#292862"
      />
      <path
        d="M59.5656 59.8244C54.068 69.8292 42.957 73.3698 40.6951 65.8396C38.3528 58.0376 49.3433 43.6058 58.3838 45.7306C63.7254 47.9287 62.0165 55.3644 59.5656 59.8244Z"
        fill="#292862"
      />
      <path
        d="M85.0488 37.0683C79.816 44.4615 70.2767 45.388 69.5535 40.07C68.7735 34.3314 78.49 26.397 84.621 27.7702C88.6792 29.3018 87.1004 34.1707 85.0488 37.0707"
        fill="#292862"
      />
      <path
        d="M12.6594 158.589C14.1579 163.64 14.8409 168.324 13.2503 167.499C9.86095 165.746 3.67794 146.913 6.20928 146.062C6.35582 146.012 6.57091 146.095 6.71508 146.15C8.97698 147.759 11.5934 154.989 12.6594 158.589Z"
        fill="#292862"
      />
      <path
        d="M18.8621 180.881C21.9701 186.055 22.5657 187.109 22.6792 187.492C22.587 187.471 22.4641 187.44 21.0413 185.377C19.5759 183.051 17.5361 180.002 16.1629 177.622L14.291 174.254C13.5087 172.777 13.3172 172.328 13.2605 172.082L13.3527 172.103C13.8372 172.666 14.8961 174.282 18.8621 180.884"
        fill="#292862"
      />
      <path
        d="M106.802 22.1919C103.202 26.8788 96.2531 27.3728 95.7047 23.9315C95.121 20.2703 102.011 15.3471 106.39 16.2996C109.238 17.3088 108.196 20.3767 106.802 22.1919Z"
        fill="#292862"
      />
      <path
        d="M123.059 12.8007C121.073 15.6771 116.531 15.9324 115.975 13.7839C115.398 11.5598 119.532 8.62197 122.385 9.2294C124.477 9.93137 123.581 12.0444 123.059 12.8007Z"
        fill="#292862"
      />
      <path
        d="M133.427 7.10628C132.798 8.79621 130.174 8.91202 129.543 7.64989C128.917 6.39249 130.782 4.74038 132.434 5.08546C133.58 5.44471 133.654 6.49176 133.424 7.10628"
        fill="#292862"
      />
      <path
        d="M138.013 3.81057C138.282 4.418 137.758 4.69453 137.153 4.48417C136.162 4.14146 135.935 3.05424 136.453 2.82261C136.59 2.76116 136.725 2.78952 136.822 2.80843C137.358 2.96442 137.847 3.43949 138.013 3.8082"
        fill="#292862"
      />
      <path
        d="M14.0937 85.7454C10.3215 97.1234 5.51638 104.013 4.01554 100.198C1.61182 94.0934 10.7634 64.0316 16.4123 66.3455C18.8467 68.8177 15.9608 80.1154 14.0937 85.7454Z"
        fill="#292862"
      />
      <path
        d="M2.15623 123.071C2.19405 125.692 2.27913 134.359 1.80879 135.775C1.64807 135.572 1.52595 135.199 1.44244 134.657C1.16591 131.788 0.690837 127.971 0.53248 125.094C0.461574 119.84 0.570297 112.828 0.889374 107.581C0.974462 106.85 0.983916 106.756 1.08555 106.441L1.23209 106.484C1.80406 108.375 2.08532 118.162 2.15623 123.071Z"
        fill="#292862"
      />
      <path
        d="M37.2198 52.3797C31.3606 60.8837 24.0194 65.1948 23.4403 60.4748C22.5918 53.5614 35.8229 37.7966 41.1361 39.3897C43.8589 41.219 39.7653 48.6878 37.2198 52.382"
        fill="#292862"
      />
      <path
        d="M63.5129 30.4229C57.0723 36.7454 50.4756 38.3951 50.6884 34.7931C50.9649 30.0873 63.0118 20.964 67.4269 22.1151C69.7432 23.3749 65.8788 28.0996 63.5129 30.4229Z"
        fill="#292862"
      />
      <path
        d="M87.7711 16.6201C82.621 20.4561 77.4472 21.3448 77.6883 19.0782C78.0003 16.1498 87.6081 10.768 91.0399 11.5976C93.1245 12.6021 87.8255 16.5799 87.7711 16.6178"
        fill="#292862"
      />
      <path
        d="M107.59 8.44121C103.936 10.6133 100.355 11.0529 100.594 9.72698C100.922 7.89051 107.902 5.13463 110.039 5.6286C111.327 6.20767 107.746 8.34904 107.592 8.44121"
        fill="#292862"
      />
      <path
        d="M122.085 4.01841C119.554 5.22854 117.644 5.24509 117.836 4.61166C118.117 3.67334 122.459 2.36158 123.726 2.64993C124.347 2.92173 122.319 3.90733 122.085 4.01841Z"
        fill="#292862"
      />
      <path
        d="M131.13 2.14404C129.613 2.33785 128.877 2.09913 129.055 1.89587C129.376 1.52952 131.685 1.68551 132.142 1.89587L132.16 1.91005V1.93841C132.028 2.02822 131.165 2.13931 131.13 2.14404Z"
        fill="#292862"
      />
      <path
        d="M26.8742 47.0422C23.9694 50.7624 18.9398 57.04 17.8998 57.3094C17.8762 56.9502 17.8644 56.7516 19.299 54.4992L22.2771 50.1219C25.3473 46.1535 29.5899 40.9727 32.9319 37.2312C34.4422 35.6712 35.104 35.0875 35.4538 35L35.4987 35.0733C35.1867 36.3354 28.817 44.5558 26.8742 47.0446"
        fill="#292862"
      />
      <path
        d="M52.0225 25.5953C45.9766 30.3626 41.4788 32.8656 41.7837 31.2915C42.3438 28.3914 57.5839 17.2308 59.704 18.1691C60.1034 19.1571 53.3768 24.5294 52.0249 25.5953"
        fill="#292862"
      />
      <path
        d="M76.9506 12.5758C70.9449 15.7429 67.7659 16.5536 67.825 15.7075C67.9503 13.8804 81.1317 7.59816 83.3227 8.31904L83.3842 8.3994C83.2636 9.19119 78.4302 11.7958 76.953 12.5758"
        fill="#292862"
      />
      <path
        d="M98.7836 5.36763C93.0355 7.35537 91.4519 7.3081 91.7923 6.79048C92.6998 5.40309 102.402 2.79847 103.837 3.21681L103.849 3.31845C103.357 3.7557 100.143 4.89492 98.7836 5.36527"
        fill="#292862"
      />
      <path
        d="M116.175 2.01488C113.88 2.44977 111.616 2.8563 111.337 2.62704C111.833 1.85889 119.295 1.00565 120.027 1.21601C119.972 1.27116 118.688 1.53745 116.175 2.01488Z"
        fill="#292862"
      />
      <path
        d="M73.6398 9.76307C68.4322 11.9753 65.7937 13.0862 65.7244 13.0957C66.7312 12.0084 81.4253 5.92941 82.4511 6.17522C79.6125 7.60989 76.5588 8.52221 73.6398 9.76307Z"
        fill="#292862"
      />
      <path
        d="M95.7592 3.2934C91.4386 4.29396 89.1602 4.79188 88.9238 4.78715C89.8314 3.83228 101.417 1.67201 102.831 1.70746L102.84 1.72164C100.526 2.45198 98.1156 2.74742 95.7592 3.2934Z"
        fill="#292862"
      />
      <path
        d="M114.764 0.841722C113.01 0.971717 111.276 1.35225 109.503 1.23643C110.351 0.784997 119.399 0.383196 120.354 0.543916C118.494 0.704637 116.627 0.702273 114.764 0.839359"
        fill="#292862"
      />
    </svg>
  )
}

export default Logo
