import shareButton from './share-button'

const pressReleaseOverviewArticle = `
... on PressReleaseArticleRecord {
  id
  __typename
  headline
  slug
  _publishedAt
  image {
    responsiveImage (imgixParams: { fm: jpg, w: 120, h: 144, ar: "120:144", fit: crop }) {
      ...responsiveImageFragment
    }
  }
  authorS {
    pressReleaseSolutionContactOrAuthor {
      contentLayout {
        ... on EmployeeCharacteristicRecord {
          id
          name
          __typename
          profilePicture {
            responsiveImage (imgixParams:{fm:jpg, h:42 w:42 ar:"42:42" fit: crop}) {
              ...responsiveImageFragment
            }
          }
        }
      }
    }
  }
  ${shareButton}
  articleText {
    __typename
    blocks {
      __typename
      id
      image {
        responsiveImage {
          ...responsiveImageFragment
        }
      }
    }
    value
  }
}
`

export default pressReleaseOverviewArticle
