import Link from 'next/link'

function BrandBottom() {
  return (
    <div className=" flex flex-row items-center justify-center bg-white text-text-x-small text-dusty-dark-blue">
      <Link scroll={false} href="/">
        <a className="py-2">rystadenergy.com</a>
      </Link>
    </div>
  )
}

export default BrandBottom
