import React from 'react'

export type Action = { type: 'updateDisplayedPublicationIndex'; index: number }
type Dispatch = (action: Action) => void
type State = { index: number }
type Props = { children: React.ReactNode }

const DisplayedPublicationContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

function updateDisplayedPublicationIndex(state: State, action: Action) {
  switch (action.type) {
    case 'updateDisplayedPublicationIndex': {
      return {
        index: action.index,
      }
    }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

function DisplayedPublicationProvider({ children }: Props) {
  const [state, dispatch] = React.useReducer(updateDisplayedPublicationIndex, {
    index: 0,
  })
  const value = { state, dispatch }
  return (
    <DisplayedPublicationContext.Provider value={value}>
      {children}
    </DisplayedPublicationContext.Provider>
  )
}

function useDisplayedPublication() {
  const context = React.useContext(DisplayedPublicationContext)
  if (context === undefined) {
    throw new Error(
      'useDisplayedPublication must be used within a DisplayedPublicationProvider'
    )
  }
  return context
}

export { DisplayedPublicationProvider, useDisplayedPublication }
