type Props = {
  color?: string
  size?: number | string
}

function ChevronDown({ color, size }: Props) {
  return (
    <svg
      className={color}
      width={size ?? 11}
      height={size ?? 7}
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.125 1.92188L5.64844 6.21094C5.50781 6.32812 5.36719 6.375 5.25 6.375C5.10938 6.375 4.96875 6.32812 4.85156 6.23438L0.351562 1.92188C0.117188 1.71094 0.117188 1.33594 0.328125 1.125C0.539062 0.890625 0.914062 0.890625 1.125 1.10156L5.25 5.03906L9.35156 1.10156C9.5625 0.890625 9.9375 0.890625 10.1484 1.125C10.3594 1.33594 10.3594 1.71094 10.125 1.92188Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ChevronDown
