import { Fragment } from 'react'
import CtaLink from '../../components/cta-link'
import Section from '../../components/section'
import { SolutionRelatedInsightRecord } from '../../generated/graphql'
import RelatedInsightDesktopThumbnail from '../related-insight-thumbnail/related-insight-desktop-thumbnail'
import RelatedInsightMobileThumbnail from '../related-insight-thumbnail/related-insight-mobile-thumbnail'

type Props = {
  block: SolutionRelatedInsightRecord
}

function SolutionRelatedInsightRecord({ block }: Props) {
  const relatedInsights = block.relatedInsights

  return (
    <Section id={block?.slug || ''}>
      <h2 className="col-span-8 text-header-2 md:col-span-2">
        {block.headline}
      </h2>

      <div className="col-span-8 flex flex-col gap-5 md:col-span-5 md:col-start-4 md:gap-0">
        {relatedInsights?.map((insight) => (
          <Fragment key={insight.id}>
            <RelatedInsightDesktopThumbnail
              key={insight.id}
              insight={insight}
            />
            <RelatedInsightMobileThumbnail key={insight.id} insight={insight} />
          </Fragment>
        ))}
      </div>

      {block?.cta?.length > 0 && (
        <div className="col-span-8 mt-4 flex flex-col gap-5 md:col-span-5 md:col-start-4">
          {block.cta.map((cta) => (
            <CtaLink cta={cta} key={cta.id} />
          ))}
        </div>
      )}
    </Section>
  )
}

export default SolutionRelatedInsightRecord
