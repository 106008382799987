import Link from 'next/link'
import Employee from '../../components/employee'
import Section from '../../components/section'
import {
  EmployeeCharacteristicRecord,
  EmployeePageModelContentLayoutField,
  PeopleTeamGridRecord,
} from '../../generated/graphql'

type Props = {
  block: PeopleTeamGridRecord
}

export function isEmployeeCharacteristicRecord(
  record: EmployeePageModelContentLayoutField
): record is EmployeeCharacteristicRecord {
  return record?.__typename === 'EmployeeCharacteristicRecord'
}

function TeamGrid({ block }: Props) {
  const employees = block.teamGrid
    .map((a) => a.contentLayout[0])
    .filter(isEmployeeCharacteristicRecord)

  return (
    <Section id={block?.slug || ''}>
      <div className="col-span-full grid grid-cols-2 gap-x-4 gap-y-9 md:col-start-3 md:grid-cols-3">
        {employees.map((employee, index) => (
          <Link
            scroll={false}
            href={`/people/${employee.slug}`}
            key={employee.id}
          >
            <a>
              <Employee person={employee} />
            </a>
          </Link>
        ))}
      </div>
    </Section>
  )
}

export default TeamGrid
