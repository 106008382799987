import SearchIcon from '../../icons/search-icon'
import { useState } from 'react'
import useURLSearch from '../../hooks/use-URL-search'
import { useRouter } from 'next/router'

function SearchWidget() {
  const router = useRouter()
  const [isIdle, setIsIdle] = useState(true)
  const [inputValue, setInputValue] = useURLSearch()

  return (
    <>
      {isIdle ? (
        <button
          onClick={() => {
            setIsIdle(false)
            router.prefetch('/search')
          }}
          className="flex cursor-pointer flex-row items-center gap-2"
        >
          <SearchIcon />
          <span>Search</span>
        </button>
      ) : (
        <div className="flex cursor-pointer flex-row items-center gap-2">
          <SearchIcon />
          <input
            value={inputValue}
            onBlur={() => {
              setIsIdle(true)
            }}
            onChange={(e) => setInputValue(e.target.value)}
            autoFocus
            className={'bg-transparent outline-0'}
            type="text"
          />
        </div>
      )}
    </>
  )
}

export default SearchWidget
