import { gsap } from '../lib/gsap'
import { RefObject } from 'react'

function toggleVisibility(
  refToOpen: RefObject<HTMLDivElement>,
  refToClose: RefObject<HTMLDivElement>,
  onComplete: () => void
) {
  const tl = gsap.timeline()

  tl.to(refToClose.current, {
    opacity: 0,
    duration: 0.4,
  })
    .set(
      refToClose.current,
      {
        display: 'none',
      },
      0
    )
    .set(refToOpen.current, { display: 'flex' }, 0)
    .fromTo(
      refToOpen.current,
      {
        opacity: 0,
        duration: 0.4,
        onComplete() {
          onComplete()
        },
      },
      {
        opacity: 1,
      },
      0
    )
}

export default toggleVisibility
