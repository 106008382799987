import { useRef } from 'react'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import imagesCollapsingAnimation from './images-collapsing-animation'
import { CollapsingPicturesModuleRecord } from '../../generated/graphql'
import { Image } from 'react-datocms'

type Props = {
  block: CollapsingPicturesModuleRecord
}

const CollapsingPicturesModuleRecord = ({ block }: Props) => {
  const stage = useRef<HTMLDivElement>(null)
  const backgroundRef = useRef<HTMLDivElement>(null)
  const pic1 = useRef<HTMLImageElement>(null)
  const pic2 = useRef<HTMLImageElement>(null)
  const pic3 = useRef<HTMLImageElement>(null)
  const pic4 = useRef<HTMLImageElement>(null)
  const pic5 = useRef<HTMLImageElement>(null)

  useIsomorphicLayoutEffect(() => {
    imagesCollapsingAnimation(
      stage,
      [pic1, pic2, pic3, pic4, pic5],
      backgroundRef.current
    )
  }, [])

  return (
    <div
      ref={backgroundRef}
      className="w-full overflow-hidden bg-greys-1 pb-6 pt-6 md:pt-0 md:pb-[250px] xl:pb-[380px]"
    >
      <div
        className="relative flex min-h-screen w-full flex-col-reverse justify-center gap-6 px-2 md:scale-50 md:flex-col md:items-center md:gap-0 md:px-0 xl:scale-75"
        ref={stage}
      >
        {block?.image1?.responsiveImage && (
          <div
            ref={pic4}
            className="hidden md:absolute md:block md:translate-x-[33px] md:translate-y-[-46px]"
          >
            <Image data={block?.image1?.responsiveImage} />
          </div>
        )}

        {block?.image2?.responsiveImage && (
          <div
            ref={pic3}
            className="pr-6 md:absolute md:translate-x-[-129px] md:translate-y-[-91px] md:pr-0"
          >
            <Image data={block?.image2?.responsiveImage} />
          </div>
        )}

        {block?.image3?.responsiveImage && (
          <div
            ref={pic5}
            className="hidden md:absolute md:block md:translate-x-[-95px] md:translate-y-[157px]"
          >
            <Image data={block?.image3?.responsiveImage} />
          </div>
        )}

        {block?.image4?.responsiveImage && (
          <div
            ref={pic2}
            className="pl-3 md:block md:translate-x-[160px] md:translate-y-[-170px] md:pl-0"
          >
            <Image data={block?.image4?.responsiveImage} />
          </div>
        )}

        {block?.image5?.responsiveImage && (
          <div ref={pic1} className="pr-5 md:absolute md:pr-0">
            <Image data={block?.image5?.responsiveImage} />
          </div>
        )}
      </div>
    </div>
  )
}

export default CollapsingPicturesModuleRecord
