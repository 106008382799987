import { Image } from 'react-datocms'
import { PressReleaseRecord } from '../../generated/graphql'
import formatDate from '../../utils/format-date'
import { isPressReleaseArticleRecord } from '../press-release-thumbnail/press-release-thumbnail'
import Dynamic from '../../components/dynamic'

type Props = {
  pressRelease: PressReleaseRecord
}

function EmployeePublication({ pressRelease }: Props) {
  const article = pressRelease?.blocks.find((block) =>
    isPressReleaseArticleRecord(block)
  )

  return (
    <div
      key={pressRelease.id}
      className="grid grid-cols-[auto] grid-rows-2 gap-x-2 border-t-[1px] border-greys-1 py-2"
    >
      <div>
        <h3 className="col-span-1 col-start-1 text-header-3 line-clamp-2 md:line-clamp-3">
          {article && isPressReleaseArticleRecord(article) && article.headline!}
        </h3>
      </div>
      {article &&
        isPressReleaseArticleRecord(article) &&
        article?.image?.responsiveImage && (
          <Image
            style={{ maxHeight: 'none' }}
            className="col-span-full col-start-2 row-span-full row-start-1 ml-2 aspect-[120/144] justify-self-end"
            data={article.image?.responsiveImage!}
          />
        )}
      <div className="col-start-1 row-start-2 mt-2 flex flex-row items-start gap-2 text-text-x-small">
        <div className="flex flex-row items-center gap-1 text-greys-5">
          <div
            style={{
              backgroundColor: pressRelease?.tag?.dotColor?.hex,
            }}
            className={`h-1 w-1 shrink-0 rounded-full`}
          ></div>
          {pressRelease?.energyType?.name}
        </div>
        <p>
          <Dynamic>{formatDate(pressRelease.createdAt, 'full')}</Dynamic>
        </p>
      </div>
    </div>
  )
}

export default EmployeePublication
