type Props = {
  color?: string
  size?: number | string
}

function ArrowLeft({ color, size }: Props) {
  return (
    <svg
      className={color}
      width={size ?? 15}
      height={size ?? 15}
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        d="M6.55671e-07 7.5L7.76163 15L9.24419 13.5728L3.92442 8.56343L15 8.56343L15 6.43657L3.92442 6.43657L9.24419 1.42724L7.76163 -6.32799e-07L6.55671e-07 7.5Z"
        fill="currentcolor"
      />
    </svg>
  )
}

export default ArrowLeft
