import Link from 'next/link'
import { ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  isSecure: boolean
  scroll: boolean
  href: string
  target: string
  children: ReactElement
  className?: string
  onClick?: () => void
}

function SecureAnchorWrapper({
  isSecure,
  scroll,
  href,
  target,
  className,
  onClick,
  children,
}: Props) {
  if (isSecure) {
    return (
      <div className={twMerge('cursor-pointer', className)} onClick={onClick}>
        {children}
      </div>
    )
  }

  return (
    <Link scroll={scroll} href={href}>
      <a className={className} target={target}>
        {children}
      </a>
    </Link>
  )
}

export default SecureAnchorWrapper
