type Props = {
  color?: string
  size?: number | string
}

function FilledCheck({ color, size }: Props) {
  return (
    <svg
      className={color}
      width={size ?? 23}
      height={size ?? 23}
      viewBox={`0 0 ${size ?? 23} ${size ?? 23}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="22"
        height="22"
        fill="#292862"
        stroke="#292862"
      />
      <path
        d="M5.24609 10.5377L10.3789 15.6699L17.7557 7.33008"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  )
}

export default FilledCheck
