import { useMenuContext } from './menu-context'
import { useEffect, useRef } from 'react'
import { gsap } from '../../lib/gsap'

function MenuLayover() {
  const layover = useRef<HTMLDivElement>(null)
  const {
    state: { desktopMenuItemBlocks },
  } = useMenuContext()

  useEffect(() => {
    const tl = gsap.timeline()
    if (desktopMenuItemBlocks?.length) {
      tl.set(layover.current, {
        display: 'block',
      }).to(layover.current, {
        opacity: 0.1,
      })
    } else {
      tl.to(layover.current, {
        opacity: 0,
      }).set(layover.current, {
        display: 'none',
      })
    }
  }, [desktopMenuItemBlocks])

  return (
    <div
      ref={layover}
      className={'top fixed inset-[0px] z-40 h-screen w-screen bg-[#062338]'}
    />
  )
}

export default MenuLayover
