import { gsap, ScrollTrigger } from '../../lib/gsap'
import { RefObject } from 'react'

function paragraphScrollingAnimation(
  stage: RefObject<HTMLDivElement>,
  paragraphRefs: RefObject<(HTMLDivElement | null)[]>
) {
  const paragraphsCount = paragraphRefs.current?.length

  ScrollTrigger.matchMedia({
    '(min-width: 0px)': function () {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: stage.current,
          start: 'top top',
          end: '100%',
          scrub: 1,
          pin: true,
          pinSpacing: true,
        },
      })

      if (paragraphsCount) {
        paragraphRefs.current?.forEach((paragraph, i) => {
          if (i === 0) {
            tl.to(paragraph, {
              y: -200,
              opacity: 0,
              delay: 1.5,
              duration: 2,
            })
          } else if (i === paragraphsCount - 1) {
            tl.fromTo(
              paragraph,
              {
                opacity: 0,
                y: 200,
              },
              {
                y: 0,
                opacity: 1,
                duration: 2,
              },
              '<+1'
            ).to(paragraph, {
              delay: 1.5,
            })
          } else {
            tl.fromTo(
              paragraph,
              {
                opacity: 0,
                y: 200,
              },
              {
                y: 0,
                opacity: 1,
                duration: 2,
              },
              '<+1'
            ).to(paragraph, {
              opacity: 0,
              y: -200,
            })
          }
        })
      }
    },
  })
}

export default paragraphScrollingAnimation
