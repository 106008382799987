import ArrowRightButton from '../../../components/arrow-right-button'
import Section from '../../../components/section'
import { CategoryPageIntroDescriptionRecord } from '../../../generated/graphql'
import useMediaQuery from '../../../hooks/use-media-query'

type Props = {
  block: CategoryPageIntroDescriptionRecord
}
function MobileIntroDescription({ block }: Props) {
  const isSmallScreen = useMediaQuery('(max-width: 768px)')
  return (
    <Section classOverride="w-full h-full absolute z-30">
      <div className="col-span-full flex h-full w-full flex-col justify-center gap-3">
        <h3
          id={block.slug ?? ''}
          className={`mt-auto text-text-small text-white`}
        >
          {block.label}
        </h3>
        <p className={`text-header-2 text-white`}>{block.description}</p>
        <div className="mt-auto flex flex-row items-center gap-3 border-t border-white pb-1 pt-3">
          <ArrowRightButton
            variant={isSmallScreen ? 'white' : 'primary'}
            link={block.cta[0].link!}
          >
            {block.cta[0].ctaLabel}
          </ArrowRightButton>
        </div>
      </div>
    </Section>
  )
}

export default MobileIntroDescription
